import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "disclosureDate",
})
export class DisclosureDatePipe implements PipeTransform {
  transform(value: any[], args?: any): any {
    if (value) {
      let disclosureDate = value.filter((item) => item.field.id === 67);
      if (disclosureDate.length > 0) {
        return true;
      }
      return false;
    }
  }
}
