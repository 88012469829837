<div class="alert alert-success mt-4" id="tour" role="alert">
  <a class="introducao" title="Introdução" (click)="startSteps()">
    <mat-icon class="mat-icon-flex" color="link">info</mat-icon>
    Clique aqui para uma Introdução ao Portal de Comissionamento.
  </a>
</div>

<p class="text-muted mt-4 mb-1">
  Olá {{ user.firstName }}, seja bem vindo.
  <!-- <span class="text-primary">Portal de Comissionamento.</span> -->
</p>

<h1 class="mt-0 mb-0 text-muted">
  {{ titulo }}
</h1>

<!-- <div class="mt-4">
  <div class="row">
    <div class="col-sm-12">
      <div class="alert alert-primary alert-dismissible fade show" role="alert">
        <div class="row">
          <div class="col-sm-8 text-left">
            <h4 class="alert-heading">
              <strong> Portal de Insumos </strong>
            </h4>

            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eum cum
              quidem, accusantium quo repudiandae numquam laudantium architecto
              sit velit sunt iusto vero tempora sequi ipsa eius, quos nesciunt
              odit veniam!
            </p>
          </div>
          <div class="col-sm-4 text-right">
            <img
              src="../../assets/imagens/man-boas-vindas.svg"
              class="img-fluid img-boas-vindas"
              alt=""
            />
          </div>
        </div>
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <div class="alert alert-primary alert-dismissible fade show" role="alert">
      <div class="row">
        <div class="col-sm-8 text-left">
          <h4 class="alert-heading">
            <strong> Portal de Insumos </strong>
          </h4>

          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eum cum
            quidem, accusantium quo repudiandae numquam laudantium architecto
            sit velit sunt iusto vero tempora sequi ipsa eius, quos nesciunt
            odit veniam!
          </p>
        </div>
        <div class="col-sm-4 text-right">
          <img
            src="../../assets/imagens/woman-boas-vindas.svg"
            class="img-fluid img-boas-vindas"
            alt=""
          />
        </div>
      </div>
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</div> -->

<div class="row">
  <div class="col-sm-4">
    <!-- <apx-chart [series]="series" [chart]="chart" [title]="title"></apx-chart> -->
    <!-- <div id="chart" class="bg-white mt-4 pb-4">
      <p class="text-center pt-4">Dashboard Geral</p>
      <apx-chart
        [series]="chartOptions.series"
        [stroke]="chartOptions.stroke"
        [colors]="chartOptions.colors"
        [fill]="chartOptions.fill"
        [dataLabels]="chartOptions.dataLabels"
        [legend]="chartOptions.legend"
        [chart]="chartOptions.chart"
        [labels]="chartOptions.labels"
        [title]="chartOptions.title"
        [theme]="chartOptions.theme"
        [responsive]="chartOptions.responsive"
      ></apx-chart>
    </div> -->

    <!-- <div style="text-align:center">
            <apx-chart
                [series]="chartOptions.series"
                [stroke]="chartOptions.stroke"
                [fill]="chartOptions.fill"
            ddataLabelabelll]="chartOptions.fill"
                [chart]="chartOptions.chart"
                [xaxis]="chartOptions.xaxis"
                [title]="chartOptions.title"
                /apx-chart>
            </v> -->
  </div>

  <div class="col-sm-4">
    <!-- <apx-chart [series]="series" [chart]="chart" [title]="title"></apx-chart> -->
    <!-- <div id="chart" class="bg-white mt-4 pb-4">
      <p class="text-center pt-4">Dashboard Meus Tickets</p>
      <apx-chart
        [series]="MyTickets_chartOptions.series"
        [stroke]="MyTickets_chartOptions.stroke"
        [fill]="MyTickets_chartOptions.fill"
        [colors]="MyTickets_chartOptions.colors"
        [dataLabels]="MyTickets_chartOptions.dataLabels"
        [legend]="MyTickets_chartOptions.legend"
        [chart]="MyTickets_chartOptions.chart"
        [labels]="MyTickets_chartOptions.labels"
        [title]="MyTickets_chartOptions.title"
        [theme]="MyTickets_chartOptions.theme"
        [responsive]="MyTickets_chartOptions.responsive"
      ></apx-chart>
    </div> -->

    <!-- <div style="text-align:center">
            <apx-chart
                [series]="chartOptions.series"
                [stroke]="chartOptions.stroke"
                [fill]="chartOptions.fill"
            ddataLabelabelll]="chartOptions.fill"
                [chart]="chartOptions.chart"
                [xaxis]="chartOptions.xaxis"
                [title]="chartOptions.title"
                /apx-chart>
            </v> -->
  </div>

  <div class="col-sm-4">
    <!-- <apx-chart [series]="series" [chart]="chart" [title]="title"></apx-chart> -->
    <!-- <div id="chart" class="bg-white mt-4 pb-4">
      <p class="text-center pt-4">Dashboard Version 1.0</p>
      <apx-chart
        [series]="chartOptions.series"
        [stroke]="chartOptions.stroke"
        [fill]="chartOptions.fill"
        [dataLabels]="chartOptions.dataLabels"
        [legend]="chartOptions.legend"
        [chart]="chartOptions.chart"
        [labels]="chartOptions.labels"
        [title]="chartOptions.title"
        [theme]="chartOptions.theme"
        [responsive]="chartOptions.responsive"
      ></apx-chart>
    </div> -->

    <!-- <div style="text-align:center">
            <apx-chart
                [series]="chartOptions.series"
                [stroke]="chartOptions.stroke"
                [fill]="chartOptions.fill"
            ddataLabelabelll]="chartOptions.fill"
                [chart]="chartOptions.chart"
                [xaxis]="chartOptions.xaxis"
                [title]="chartOptions.title"
                /apx-chart>
            </v> -->
  </div>
</div>

<div class="row">
  <!-- <div class="col-lg-4 col-md-8 offset-md-2 col-sm-12"> -->
  <div class="col-lg-4 col-md-12">
    <div class="bg-white h-100">
      <!-- max-width: 600px;  -->
      <div style="margin: 0 auto;">
        <app-todos-tickets></app-todos-tickets>
      </div>
    </div>
  </div>

  <div class="col-lg-4 col-md-12">
    <div class="bg-white h-100">
      <!-- max-width: 600px;  -->
      <div style="margin: 0 auto;">
        <app-seus-tickets></app-seus-tickets>
      </div>
    </div>
  </div>

  <div class="col-lg-4 col-md-12">
    <div class="bg-white h-100">
      <!-- max-width: 600px;  -->
      <div style="margin: 0 auto;">
        <app-tickets-para-atender></app-tickets-para-atender>
      </div>
    </div>
  </div>
</div>

<div class="row mt-4">
  <div class="col-lg-4 col-md-12">
    <div class="bg-white h-100">
      <!-- max-width: 600px;  -->
      <div style="margin: 0 auto;">
        <app-todos-insumos></app-todos-insumos>
      </div>
    </div>
  </div>

  <div class="col-lg-8 col-md-12">
    <div class="bg-white h-100">
      <div style="margin: 0 auto;">
        <app-todos-insumos-tempo></app-todos-insumos-tempo>
      </div>
    </div>
  </div>
</div>