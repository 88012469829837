export function isHoliday(day: string, month: string): boolean {
  if (day === "01" && month === "01") {
    return true;
  } else if (day === "25" && month === "01") {
    return true;
  } else if (day === "21" && month === "04") {
    return true;
  } else if (day === "01" && month === "05") {
    return true;
  } else if (day === "08" && month === "06") {
    return true;
  } else if (day === "09" && month === "07") {
    return true;
  } else if (day === "07" && month === "09") {
    return true;
  } else if (day === "12" && month === "10") {
    return true;
  } else if (day === "02" && month === "11") {
    return true;
  } else if (day === "15" && month === "11") {
    return true;
  } else if (day === "20" && month === "12") {
    return true;
  } else if (day === "25" && month === "12") {
    return true;
  } else {
    return false;
  }
}
