import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PedidoPortalService {

  constructor(
    private http: HttpClient
  ) { }


  getAllPedidoPortal(page, count,sort,order) {
    return this.http.get<any>(`${environment.apiUrl}/pedido-portal/search/${page}/${count}?sort=${sort}&order=${order}`).pipe(tap(console.log));
  }

  getAllPedidoPortalFilter(filter, empresaId,tipoSolicitacao, page, count,sort,order) {
    return this.http.get<any>(`${environment.apiUrl}/pedido-portal/search/${filter}/${empresaId}/${page}/${count}?tipoSolicitacao=${tipoSolicitacao}&sort=${sort}&order=${order}`).pipe(tap(console.log));
  }

  getPedidoPortalById(id,status){
    return this.http.get<any>(`${environment.apiUrl}/pedido-portal/search-by-id/${id}/${status}`).pipe(tap(console.log));
  }

  getPedidoById(id){
    return this.http.get<any>(`${environment.apiUrl}/pedido-portal/search-by-id/${id}`).pipe(tap(console.log));
  }

  recepcionarPedido(id, userId){
    return this.http.get<any>(`${environment.apiUrl}/pedido-portal/receive/${id}/${userId}`).pipe(tap(console.log));
  }

  cancelarPedido(id,motivoCancelamento){
    return this.http.post<any>(`${environment.apiUrl}/pedido-portal/cancel/${id}`,motivoCancelamento).pipe(tap(console.log));
  }

  concluirPedido(id, userId){
    return this.http.get<any>(`${environment.apiUrl}/pedido-portal/conclude/${id}/${userId}`).pipe(tap(console.log));
  }

  getAnexosPedido(id){
    return this.http.get<any>(`${environment.apiUrl}/pedido-portal/search-anexos/${id}`).pipe(tap(console.log));
  }

  deleteAnexosPedido(anexoId){
    return this.http.delete<any>(`${environment.apiUrl}/pedido-portal/remove-anexo/${anexoId}`).pipe(tap(console.log));
  }

  addObservacaoConclusao(pedidoId, observacaoConclusao){
    return this.http.post<any>(`${environment.apiUrl}/pedido-portal/add-observacao-conclusao/${pedidoId}`,observacaoConclusao).pipe(tap(console.log));
  }
  
}
