import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { AccountService } from "src/app/_services";
import { DashboardService } from "src/app/_services/dashboard.service";
import { User } from "src/app/_models";
import { MatChipInputEvent } from "@angular/material/chips";
import { ENTER, COMMA } from "@angular/cdk/keycodes";

@Component({
  selector: "app-editar",
  templateUrl: "./editar.component.html",
  styleUrls: ["./editar.component.css"],
})
export class EditarDashBoardComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  user: User;
  searchUserCtrl1 = new FormControl();
  searchUserCtrl2 = new FormControl();
  errorMsg: string;

  @ViewChild("validatorInput") validatorInput: ElementRef<HTMLInputElement>;

  constructor(
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private dashBoardService: DashboardService,
    private accountService: AccountService
  ) {
    this.user = this.accountService.userValue.user;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      title: [""],
      link: [""],
      areaPermissions: new FormGroup({
        EQUIPE_INTERNA: new FormControl(false),
        OPERACAO_VIVO: new FormControl(false),
        OPERACAO_BPO: new FormControl(false),
        TODOS: new FormControl(false),
      }),
      areaDashboard: [""],
    });

    this.loadDashboardData();
  }

  loadDashboardData() {
    const dashboardId = this.route.snapshot.params.id;
  
    this.dashBoardService.getDashboardById(dashboardId).subscribe(
      (dashboardData) => {
        const areaPermissionsArray = dashboardData.areaPermissions.split(',');
          const areaPermissionsObj = {
          EQUIPE_INTERNA: areaPermissionsArray.includes('EQUIPE_INTERNA'),
          OPERACAO_VIVO: areaPermissionsArray.includes('OPERACAO_VIVO'),
          OPERACAO_BPO: areaPermissionsArray.includes('OPERACAO_BPO'),
          TODOS: areaPermissionsArray.includes('TODOS'),
        };
  
        this.form.patchValue({
          title: dashboardData.title,
          link: dashboardData.link,
          areaDashboard: dashboardData.areaDashboard,
          areaPermissions: areaPermissionsObj,
        });
      },
      (error) => {
        console.error("Error loading dashboard data:", error);
      }
    );
  }

  updateDashboard() {
    this.submitted = true;
    const formValues = this.form.value;
    const areaPermissions = [];
    if (this.form.invalid) {
      return;
    }
    for (const key in this.form.value.areaPermissions) {
      if (this.form.value.areaPermissions[key] === true) {
        areaPermissions.push(key);
      }
    }
    const stringAreaPermissions = areaPermissions.join(",");
  
    const dashboardId = this.route.snapshot.params.id;
    const updatedDashboardData = this.form.value;
  
    const formData = new FormData();
    Object.keys(updatedDashboardData).forEach(key => {
      formData.append(key, updatedDashboardData[key]);
    });
    const data = {
      ...formValues,
      areaPermissions: stringAreaPermissions,
      userId: this.user.id,
    };
    console.log(data);
    const jsonBlob = new Blob([JSON.stringify(data)], {
      type: "application/json",
    });
    formData.append("dashboardDto", jsonBlob);
    console.log("teste");
    console.log("formValues:", formValues);
    console.log("areaPermissions:", areaPermissions);
    this.dashBoardService.updateDashboard(dashboardId, formData).subscribe(
      (response) => {
        console.log("Dashboard updated successfully:", response);
      },
      (error) => {
        console.error("Error updating dashboard:", error);
      }
    );
  }
  redirectToDashs(){
    window.location.href = '/dashboards';
  }
  redirectToMain(){
    window.location.href = '/';
  }
  
}
