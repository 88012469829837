<!-- test vídeo -->

<!-- Button trigger modal -->
<!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#myModal">
          Launch demo modal
        </button> -->


<!-- DESATIVADO ENQUANTO FAZ TOUR >>> LEMBRAR DE REATIVAR <<< -->
<button id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#myModal">Open Modal</button>

<!-- Modal -->
<!-- <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="modalVideoLabel" -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="modalVideoLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <div class="modal-body">

                <!-- autoplay -->
                <video controlsList="nodownload" oncontextmenu="return false;" class="img-fluid" controls preload="auto"
                    loop #myVideo tabindex="0">
                    <source src="../../assets/vídeo/portal-governança-documental-mod.mp4" type="video/mp4">
                    <object data="">
                        <embed src="portal-governança-documental-mod.mp4">
                    </object>
                </video>

                <!-- <video controls #myVideo>
                    <source src="../../assets/vídeo/portal-governança-documental-mod.mp4" type="video/mp4" />
                    Browser not supported
                </video> -->
                <!-- <a (click)="playVideo()">Play video</a> -->
                <!-- <a (click)="pauseVideo()">Pause video</a> -->



            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal"
                    (click)="pauseVideo()">Fechar</button>
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
        </div>
    </div>
</div>

<!--  -->

<!-- <div class="balao-texto-1">
    <small>header</small>
    <p>tentativa 1</p>
    <small>footer</small>
    <a href="#">Voltar</a>
    <a href="saçlkslajsjasaksasasasa"></a>
</div> -->

<!--  -->

<!-- <div class="row noticias mt-5 mb-5 fw-300">
    <div class="col-lg-12">
        <h1>
            Bem vindo ao Powerdoc
        </h1>

        <h3>
            Gestão Inteligente de Documentos
        </h3>

        <ul>
            <li>
                Inicio Rápido
            </li>
            <li>
                Abrir no novo lote
            </li>
        </ul>
    </div>
</div> -->