<ul class="breadcrumb">
  <li>
    <a routerLink="" class="button-flex">
      <mat-icon class="material-symbols-outlined">home</mat-icon>
    </a>
  </li>

  <!-- <li>
    <a> Menu Principal </a>
  </li> -->

  <li>
    <a> Solicitação de Alteração de Insumos </a>
  </li>
</ul>

<!-- <p class="text-muted mt-4 mb-1">
  <span class="text-primary">Portal de Comissionamento</span>
</p> -->

<h1 class="text-muted">Consulta Jurídica: Tipo de Solicitação</h1>

<div class="row">
  <div class="col-sm-6 mb-4">
    <div class="d-flex mb-2">
      <p class="text-muted mr-auto">Formulário</p>
    </div>

    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>CNPJ:</mat-label>
        <input
          mask="00.000.000/0000-00"
          matInput
          type="text"
          formControlName="cnpj"
          [ngClass]="{ 'is-invalid': submitted && f.cnpj.errors }"
          maxlength="300"
          required
        />

        <div *ngIf="submitted && f.cnpj.errors" class="invalid-feedback">
          <div *ngIf="f.cnpj.errors.required">É necessário adicionar CNPJ</div>
          <div *ngIf="f.cnpj.errors.minlength">
            O CNPJ deve ter pelo menos 14 caracteres
          </div>
        </div>
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Selecione Período de Início e Fim</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
          <input
            matStartDate
            formControlName="start"
            placeholder="Data Inicial"
          />
          <input matEndDate formControlName="end" placeholder="Data Final" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')"
          >Invalid start date</mat-error
        >
        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')"
          >Invalid end date</mat-error
        >
      </mat-form-field>

      <!-- <p>Selected range: {{range.value | json}}</p> -->

      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Tipo de Contrato:</mat-label>
        <input
          matInput
          type="text"
          formControlName="typeContract"
          [ngClass]="{ 'is-invalid': submitted && f.typeContract.errors }"
          maxlength="300"
          required
        />

        <div
          *ngIf="submitted && f.typeContract.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.typeContract.errors.required">
            É necessário adicionar Tipo de Contrato
          </div>
          <div *ngIf="f.typeContract.errors.minlength">
            O Tipo de Contrato deve ter pelo menos 3 caracteres
          </div>
          <div *ngIf="f.typeContract.errors.maxlength">
            O Tipo de Contrato não deve exceder 300 caracteres
          </div>
        </div>
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-100" required>
        <mat-label> SLA:</mat-label>
        <mat-select
          formControlName="sla"
          [ngClass]="{ 'is-invalid': submitted && f.sla.errors }"
        >
          <mat-option value="Ordinária"> Ordinária </mat-option>
          <mat-option value="Perícia"> Perícia </mat-option>
          <mat-option value="Urgente"> Urgente </mat-option>
        </mat-select>

        <div *ngIf="submitted && f.sla.errors" class="invalid-feedback">
          <div *ngIf="f.sla.errors.required">É necessário adicionar o SLA</div>
        </div>
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Detalhes da Solicitação:</mat-label>
        <textarea
          matInput
          type="text"
          formControlName="detailsSolicitation"
          name="detailsSolicitation"
          placeholder="Espaço para detalhar o que deseja"
          id="detailsSolicitation"
          [ngClass]="{
            'is-invalid': submitted && f.detailsSolicitation.errors
          }"
          required
        ></textarea>

        <div
          *ngIf="submitted && f.detailsSolicitation.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.detailsSolicitation.errors.required">
            É necessário adicionar Detalhes da Solicitação
          </div>
          <div *ngIf="f.detailsSolicitation.errors.minlength">
            O Detalhes da Solicitação deve ter pelo menos 3 caracteres
          </div>
        </div>
      </mat-form-field>

      <div class="mb-2 mt-2">
        <!-- <ul>
              <li *ngFor="let attachment of attachments">
                  {{attachment.originalFileName}}
              </li>
          </ul> -->
        <mat-card-content>
          <div class="row">
            <div class="col-lg-6" style="align-self: left">
              <ul>
                <li
                  *ngFor="let attachment of attachments"
                  style="
                    line-height: 16px;
                    padding: 20px 10px;
                    white-space: nowrap;
                    overflow: hidden;
                    height: 60px;
                  "
                >
                  {{ attachment.originalFileName }}
                </li>
              </ul>
            </div>
            <div class="col-lg-3" style="align-self: right">
              <ul>
                <li
                  *ngFor="let file of files"
                  style="padding: 20px 10px; line-height: 16px; height: 60px"
                >
                  {{ file.progress }}
                </li>
              </ul>
            </div>
            <div class="col-lg-3" style="align-self: right">
              <ul>
                <li
                  *ngFor="let attachment of attachments"
                  style="padding: 13px 10px; height: 60px"
                >
                  <span
                    class="btn btn-sm mr-2 btn-danger"
                    (click)="removeFile(attachment)"
                  >
                    <mat-icon>close</mat-icon>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-12" style="align-self: center">
            <ul>
              <li *ngIf="files.length > 0" style="margin: 30xp 0">
                <mat-progress-bar [value]="somArray(files)"></mat-progress-bar>
                <span id="file-label"></span>
              </li>
            </ul>
          </div>
        </mat-card-content>

        <div class="form-group">
          <div class="input-group">
            <div class="custom-file">
              <input
                type="file"
                #fileUpload
                id="fileUpload"
                name="fileUpload"
                multiple="multiple"
                accept="image/*,
                            application/pdf,
                            application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                            application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                            application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation,
                            .txt"
                class="custom-file-input"
                (click)="onUploadButtonClick()"
              />
              <label class="custom-file-label" for="anexarDocumento"
                >Upload</label
              >
            </div>
            <!-- <div class="input-group-append">
                  <button class="btn btn-outline-secondary" type="button" >Enviar</button>
              </div> -->
          </div>
        </div>
      </div>

      <div class="d-flex">
        <button
          routerLink="../"
          type="button"
          class="btn btn-dark btn-sm button-flex mr-2"
          data-dismiss="modal"
        >
          <mat-icon>highlight_off</mat-icon>
          Cancelar
        </button>

        <button
          type="button"
          (click)="onReset()"
          class="btn btn-secondary button-flex btn-sm mr-2"
        >
          <mat-icon>backspace</mat-icon>
          Limpar
        </button>

        <button type="submit" class="btn btn-success btn-sm button-flex">
          <mat-icon>task_alt</mat-icon>
          Enviar
        </button>
      </div>
    </form>
  </div>
</div>

<button
  type="button"
  #openModal
  data-toggle="modal"
  data-target="#confirmed"
  [hidden]="true"
></button>

<div class="modal fade" id="confirmed">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title mb-0 text-muted" id="TituloModalCentralizado">
          Criação de Ticket
        </p>
        <button
          type="button"
          class="close"
          aria-label="Fechar"
          data-dismiss="modal"
          (click)="navigateToMain()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="mt-4 pb-4">
          <div class="w-100 d-flex justify-content-center">
            <mat-icon class="text-success" style="font-size: 30pt !important"
              >check_circle</mat-icon
            >
          </div>
          <h3 class="text-center text-muted mt-4">
            Solicitação criada com sucesso!
          </h3>
          <p class="text-center text-muted mt-4">
            O ID da sua solicitação é {{ ticketId }}
          </p>
        </div>
      </div>
      <div class="modal-footer">
        <button
          data-dismiss="modal"
          type="button"
          class="btn-sm btn btn-secondary mr-2 btn-mat-icon"
          (click)="navigateToMain()"
        >
          <mat-icon class="modal-icon">arrow_back</mat-icon>
          Ir para o Menu
        </button>
        <button
          data-dismiss="modal"
          type="button"
          class="btn-sm btn btn-success mr-2 btn-mat-icon"
          (click)="navigateToTickets()"
        >
          <mat-icon class="modal-icon">arrow_outward</mat-icon>
          Meus Tickets
        </button>
      </div>
    </div>
  </div>
</div>
