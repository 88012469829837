import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: "changeStatus",
})
export class ChangeStatusPipe implements PipeTransform {
  transform(value: boolean): string {
    if (value) return "Ativo";

    return "Inativo";
  }
}
