import { Component, OnInit, ViewChild } from "@angular/core";
import { ChartOptions } from "../../_models/tickets";
import { chartPartialOptions } from "../../_helpers/chartPartialOptions";
import { ChartComponent } from "ng-apexcharts";
import { InsumoService } from "src/app/_services/insumos.service";

@Component({
  selector: "app-todos-insumos",
  templateUrl: "./todos-insumos.component.html",
  styleUrls: ["./todos-insumos.component.css"],
})
export class TodosInsumosComponent implements OnInit {
  chartOptions: Partial<ChartOptions>;
  @ViewChild("chart") chart: ChartComponent;

  constructor(private insumoService: InsumoService) {
    this.chartOptions = {
      ...chartPartialOptions,
      series: [0, 0, 0],
      labels: ["Vigentes Entregues", "Vigentes Não Entregues"],
      legend: {
        labels: {
          colors: ["#660099", "#e83e8c"],
        },
        markers: {
          fillColors: ["#660099", "#e83e8c"],
        },
      },
      colors: ["#660099", "#e83e8c"],
      fill: {
        colors: ["#660099", "#e83e8c"],
      },
    };
  }

  ngOnInit(): void {
    this.getAllINsumosCharts();
  }

  getAllINsumosCharts() {
    this.insumoService
      .getAllInsumosChart()
      .subscribe(({ vigentesEntregues, vigentesNaoEntregues }) => {
        this.chartOptions.series = [vigentesEntregues, vigentesNaoEntregues];
      });
  }
}
