import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-atender-tickets-operacao-vivo",
  templateUrl: "./atender-tickets-operacao-vivo.component.html",
  styleUrls: ["./atender-tickets-operacao-vivo.component.css"],
})
export class AtenderTicketsOperacaoVivoComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
