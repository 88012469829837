import { User } from "src/app/_models";
import { AccountService } from "src/app/_services";

import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

import { DashboardService } from "src/app/_services/dashboard.service";

@Component({
  selector: "app-criar",
  templateUrl: "./criar.component.html",
  styleUrls: ["./criar.component.css"],
})
export class CriarDashBoardComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  user: User;
  dashboardCategories;
  searchUserCtrl1 = new FormControl();
  searchUserCtrl2 = new FormControl();
  errorMsg: string;

  @ViewChild("validatorInput") validatorInput: ElementRef<HTMLInputElement>;

  constructor(
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService,
    private accountService: AccountService
  ) {
    this.user = this.accountService.userValue.user;
  }

  ngOnInit(): void {
    this.getDashboardCategories();
    this.form = this.formBuilder.group({
      title: ["", [Validators.required, Validators.minLength(1)]],
      link: [""],
      areaPermissions: new FormGroup({
        EQUIPE_INTERNA: new FormControl(false, Validators.required),
        OPERACAO_VIVO: new FormControl(false, Validators.required),
        OPERACAO_BPO: new FormControl(false, Validators.required),
        TODOS: new FormControl(false, Validators.required),
      }),
      areaDashboard: ["", [Validators.required]],
      categoryId: ["", [Validators.required]],
    });
  }

  getDashboardCategories() {
    this.dashboardService
      .getDashboardCategories()
      .subscribe((res) => (this.dashboardCategories = res));
  }

  createDashBoard() {
    const formValues = this.form.value;
    const areaPermissions = [];
    for (const key in this.form.value.areaPermissions) {
      if (this.form.value.areaPermissions[key] === true) {
        areaPermissions.push(key);
      }
    }
    const stringAreaPermissions = areaPermissions.join(",");

    const data = {
      ...formValues,
      areaPermissions: stringAreaPermissions,
      userId: this.user.id,
    };

    console.log(data);

    this.dashboardService.newDashBoard(data).subscribe(
      (res) => {
        console.log(res);
        // window.location.href = "/dashboards";
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
