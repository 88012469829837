<p class="text-muted mt-4 mb-1">
  <span class="text-primary">Portal de Comissionamento</span>
</p>

<h1 class="text-muted">Configuração de Campos Do Insumo</h1>

<div class="row mb-5 mt-2">
  <div class="col-sm-6">
    <div class="d-flex justify-content-between align-items-end">
      <p>Lista de Areas:</p>

      <button
        type="button"
        class="btn btn-success btn-sm mr-2 mb-2"
        data-toggle="modal"
        data-target="#add"
        (click)="modalAddSelectedType = 'Area'"
      >
        <div class="button-flex">
          <mat-icon> add_box </mat-icon>
          Adicionar Area
        </div>
      </button>
    </div>

    <div>
      <table mat-table [dataSource]="dataSource_Area">
        <ng-container matColumnDef="fieldDescription">
          <mat-header-cell *matHeaderCellDef>Descrição</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.fieldDescription }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <label
              class="m-0 btn btn-sm btn-mat-icon"
              [ngClass]="{
                'btn-success': row.status === true,
                'btn-danger': row.status === false || row.status === null
              }"
            >
              {{ row.status | changeStatus }}
            </label>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="creationDt">
          <mat-header-cell *matHeaderCellDef> Data de Criação </mat-header-cell>
          <mat-cell *matCellDef="let row">{{
            row.creationDt | date: "dd/MM/YYYY"
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>Ações</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <a
              matSuffix
              matTooltip="Editar"
              class="btn btn-sm btn-info text-white mr-2 button-flex-table"
              data-toggle="modal"
              data-target="#editar"
              (click)="populateEditForm(row.id); modalAddSelectedType = 'Area'"
            >
              <mat-icon> edit </mat-icon>
            </a>

            <a
              matSuffix
              matTooltip="Mudar Status da  Campo"
              class="btn btn-primary btn-sm text-white mr-2 button-flex-table"
              (click)="disable(row.id); modalAddSelectedType = 'Area'"
            >
              <mat-icon>task_alt</mat-icon>
            </a>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </table>
    </div>

    <mat-paginator
      #areaPaginator
      [length]="resultsLength_Area"
      [pageSize]="5"
      aria-label="Select page of GitHub search results"
    ></mat-paginator>
  </div>

  <div class="col-sm-6">
    <div class="d-flex justify-content-between align-items-end">
      <p>Lista de Cadastro de Publico:</p>

      <button
        type="button"
        class="btn btn-success btn-sm mr-2 mb-2"
        data-toggle="modal"
        data-target="#add"
        (click)="modalAddSelectedType = 'Cadastro de Publico'"
      >
        <div class="button-flex">
          <mat-icon> add_box </mat-icon>
          Adicionar Cadastro de Publicos
        </div>
      </button>
    </div>

    <div>
      <table mat-table [dataSource]="dataSource_CadastroPublico">
        <ng-container matColumnDef="fieldDescription">
          <mat-header-cell *matHeaderCellDef>Descrição</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.fieldDescription }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <label
              class="m-0 btn btn-sm btn-mat-icon"
              [ngClass]="{
                'btn-success': row.status === true,
                'btn-danger': row.status === false || row.status === null
              }"
            >
              {{ row.status | changeStatus }}
            </label>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="creationDt">
          <mat-header-cell *matHeaderCellDef> Data de Criação </mat-header-cell>
          <mat-cell *matCellDef="let row">{{
            row.creationDt | date: "dd/MM/YYYY"
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>Ações</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <a
              matSuffix
              matTooltip="Editar"
              class="btn btn-sm btn-info text-white mr-2 button-flex-table"
              data-toggle="modal"
              data-target="#editar"
              (click)="
                populateEditForm(row.id);
                modalAddSelectedType = 'Cadastro de Publico'
              "
            >
              <mat-icon> edit </mat-icon>
            </a>

            <a
              matSuffix
              matTooltip="Mudar Status da  Campo"
              class="btn btn-primary btn-sm text-white mr-2 button-flex-table"
              (click)="
                disable(row.id); modalAddSelectedType = 'Cadastro de Publico'
              "
            >
              <mat-icon>task_alt</mat-icon>
            </a>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </table>
    </div>

    <mat-paginator
      #cadastroPublicoPaginator
      [length]="resultsLength_CadastroPublico"
      [pageSize]="5"
      aria-label="Select page of GitHub search results"
    ></mat-paginator>
  </div>
</div>

<div class="row mb-5">
  <div class="col-sm-6">
    <div class="d-flex justify-content-between align-items-end">
      <p>Lista de Segmentos:</p>

      <button
        type="button"
        class="btn btn-success btn-sm mr-2 mb-2"
        data-toggle="modal"
        data-target="#add"
        (click)="modalAddSelectedType = 'Segmento'"
      >
        <div class="button-flex">
          <mat-icon> add_box </mat-icon>
          Adicionar Segmento
        </div>
      </button>
    </div>

    <div>
      <table mat-table [dataSource]="dataSource_Segmento">
        <ng-container matColumnDef="fieldDescription">
          <mat-header-cell *matHeaderCellDef>Descrição</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.fieldDescription }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <label
              class="m-0 btn btn-sm btn-mat-icon"
              [ngClass]="{
                'btn-success': row.status === true,
                'btn-danger': row.status === false || row.status === null
              }"
            >
              {{ row.status | changeStatus }}
            </label>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="creationDt">
          <mat-header-cell *matHeaderCellDef> Data de Criação </mat-header-cell>
          <mat-cell *matCellDef="let row">{{
            row.creationDt | date: "dd/MM/YYYY"
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>Ações</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <a
              matSuffix
              matTooltip="Editar"
              class="btn btn-sm btn-info text-white mr-2 button-flex-table"
              data-toggle="modal"
              data-target="#editar"
              (click)="
                populateEditForm(row.id); modalAddSelectedType = 'Segmento'
              "
            >
              <mat-icon> edit </mat-icon>
            </a>

            <a
              matSuffix
              matTooltip="Mudar Status da  Campo"
              class="btn btn-primary btn-sm text-white mr-2 button-flex-table"
              (click)="disable(row.id); modalAddSelectedType = 'Segmento'"
            >
              <mat-icon>task_alt</mat-icon>
            </a>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </table>
    </div>

    <mat-paginator
      #segmentoPaginator
      [length]="resultsLength_Segmento"
      [pageSize]="5"
      aria-label="Select page of GitHub search results"
    ></mat-paginator>
  </div>

  <div class="col-sm-6">
    <div class="d-flex justify-content-between align-items-end">
      <p>Lista de Tipo de Insumo:</p>

      <button
        type="button"
        class="btn btn-success btn-sm mr-2 mb-2"
        data-toggle="modal"
        data-target="#add"
        (click)="modalAddSelectedType = 'Tipo de Insumo'"
      >
        <div class="button-flex">
          <mat-icon> add_box </mat-icon>
          Adicionar Tipo de Insumo
        </div>
      </button>
    </div>

    <div>
      <table mat-table [dataSource]="dataSource_TipoDeInsumo">
        <ng-container matColumnDef="fieldDescription">
          <mat-header-cell *matHeaderCellDef>Descrição</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.fieldDescription }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <label
              class="m-0 btn btn-sm btn-mat-icon"
              [ngClass]="{
                'btn-success': row.status === true,
                'btn-danger': row.status === false || row.status === null
              }"
            >
              {{ row.status | changeStatus }}
            </label>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="creationDt">
          <mat-header-cell *matHeaderCellDef> Data de Criação </mat-header-cell>
          <mat-cell *matCellDef="let row">{{
            row.creationDt | date: "dd/MM/YYYY"
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>Ações</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <a
              matSuffix
              matTooltip="Editar"
              class="btn btn-sm btn-info text-white mr-2 button-flex-table"
              data-toggle="modal"
              data-target="#editar"
              (click)="
                populateEditForm(row.id);
                modalAddSelectedType = 'Tipo de Insumo'
              "
            >
              <mat-icon> edit </mat-icon>
            </a>

            <a
              matSuffix
              matTooltip="Mudar Status da  Campo"
              class="btn btn-primary btn-sm text-white mr-2 button-flex-table"
              (click)="disable(row.id); modalAddSelectedType = 'Tipo de Insumo'"
            >
              <mat-icon>task_alt</mat-icon>
            </a>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </table>
    </div>

    <mat-paginator
      #tipoDeInsumoPaginator
      [length]="resultsLength_TipoDeInsumo"
      [pageSize]="5"
      aria-label="Select page of GitHub search results"
    ></mat-paginator>
  </div>
</div>

<div class="row mb-5">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between align-items-end">
      <p>Lista de Periodicidade:</p>

      <button
        type="button"
        class="btn btn-success btn-sm mr-2 mb-2"
        data-toggle="modal"
        data-target="#add"
        (click)="modalAddSelectedType = 'Periodicidade'"
      >
        <div class="button-flex">
          <mat-icon> add_box </mat-icon>
          Adicionar Periodicidade
        </div>
      </button>
    </div>

    <div>
      <table mat-table [dataSource]="dataSource_Periodicidade">
        <ng-container matColumnDef="fieldDescription">
          <mat-header-cell *matHeaderCellDef>Descrição</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.fieldDescription }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="quantity">
          <mat-header-cell *matHeaderCellDef
            >Quantidade de Meses</mat-header-cell
          >
          <mat-cell *matCellDef="let row">{{ row.quantity }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <label
              class="m-0 btn btn-sm btn-mat-icon"
              [ngClass]="{
                'btn-success': row.status === true,
                'btn-danger': row.status === false || row.status === null
              }"
            >
              {{ row.status | changeStatus }}
            </label>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="creationDt">
          <mat-header-cell *matHeaderCellDef> Data de Criação </mat-header-cell>
          <mat-cell *matCellDef="let row">{{
            row.creationDt | date: "dd/MM/YYYY"
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>Ações</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <a
              matSuffix
              matTooltip="Editar"
              class="btn btn-sm btn-info text-white mr-2 button-flex-table"
              data-toggle="modal"
              data-target="#editar"
              (click)="
                populateEditForm(row.id); modalAddSelectedType = 'Periodicidade'
              "
            >
              <mat-icon> edit </mat-icon>
            </a>

            <a
              matSuffix
              matTooltip="Mudar Status da  Campo"
              class="btn btn-primary btn-sm text-white mr-2 button-flex-table"
              (click)="disable(row.id); modalAddSelectedType = 'Periodicidade'"
            >
              <mat-icon>task_alt</mat-icon>
            </a>
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumnsPeriodicidade"
        ></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumnsPeriodicidade"
        ></mat-row>
      </table>
    </div>

    <mat-paginator
      #periodicidadePaginator
      [length]="resultsLength_Periodicidade"
      [pageSize]="5"
      aria-label="Select page of GitHub search results"
    ></mat-paginator>
  </div>
</div>

<form [formGroup]="formAdd" (ngSubmit)="submit()">
  <div class="modal fade" id="add">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title mb-0" id="TituloModalCentralizado">
            Adicionar {{ modalAddSelectedType }}
          </p>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Fechar"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Descrição do Campo:</mat-label>
            <input
              matInput
              placeholder="Insira a Descrição"
              formControlName="fieldDescription"
              [ngClass]="{
                'is-invalid': submitted && f.fieldDescription.errors
              }"
              required
            />
            <div
              *ngIf="submitted && f.fieldDescription.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.fieldDescription.errors.required">
                É necessário adicionar a Descrição
              </div>
            </div>
          </mat-form-field>

          <mat-form-field
            appearance="fill"
            class="w-100"
            *ngIf="modalAddSelectedType == 'Periodicidade'"
          >
            <mat-label>Quantidade de Meses:</mat-label>
            <input
              matInput
              placeholder="Insira a Quantidade de Meses"
              formControlName="quantity"
              [ngClass]="{
                'is-invalid': submitted && f.quantity.errors
              }"
              required
            />
            <div
              *ngIf="submitted && f.quantity.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.quantity.errors.required">
                É necessário adicionar a Quantidade de Meses
              </div>
            </div>
          </mat-form-field>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-success btn-sm button-flex">
            <mat-icon>task_alt</mat-icon>
            Enviar
          </button>
          <button
            #closeModalAdd
            [hidden]="true"
            type="button"
            data-dismiss="modal"
          ></button>
        </div>
      </div>
    </div>
  </div>
</form>

<form [formGroup]="formEdit" (ngSubmit)="submitEdit()">
  <div class="modal fade" id="editar">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title mb-0" id="TituloModalCentralizado">
            Editar {{ modalAddSelectedType }}
          </p>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Fechar"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Descrição do Campo:</mat-label>
            <input
              matInput
              placeholder="Insira a Descrição"
              formControlName="fieldDescription"
              [ngClass]="{
                'is-invalid': submitted && edit.fieldDescription.errors
              }"
              required
            />
            <div
              *ngIf="submitted && edit.fieldDescription.errors"
              class="invalid-feedback"
            >
              <div *ngIf="edit.fieldDescription.errors.required">
                É necessário adicionar a Descrição
              </div>
            </div>
          </mat-form-field>

          <mat-form-field
            appearance="fill"
            class="w-100"
            *ngIf="modalAddSelectedType == 'Periodicidade'"
          >
            <mat-label>Quantidade de Meses:</mat-label>
            <input
              matInput
              placeholder="Insira a Quantidade de Meses"
              formControlName="quantity"
              [ngClass]="{
                'is-invalid': submitted && edit.quantity.errors
              }"
              required
            />
            <div
              *ngIf="submitted && edit.quantity.errors"
              class="invalid-feedback"
            >
              <div *ngIf="edit.quantity.errors.required">
                É necessário adicionar a Quantidade de Meses
              </div>
            </div>
          </mat-form-field>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-success btn-sm button-flex">
            <mat-icon>task_alt</mat-icon>
            Enviar
          </button>
          <button
            #closeModalEdit
            [hidden]="true"
            type="button"
            data-dismiss="modal"
          ></button>
        </div>
      </div>
    </div>
  </div>
</form>
