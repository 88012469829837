import { ChartOptions } from "../_models/tickets";

export const chartPartialOptions: Partial<ChartOptions> = {
  series: [0, 0, 0, 0, 0, 0],
  labels: [
    "Em Aberto",
    "Em Andamento",
    "Concluído",
    "Cancelado",
    "Reaberto",
    "Devolvido",
  ],
  chart: {
    type: "donut",
  },
  legend: {
    fontWeight: 500,
    show: true,
    labels: {
      colors: ['#660099', '#eb3c7d', '#5cb85c', '#dc3545', '#17a2b8', '#ffc107'],
    },
    markers: {
      // Set the legend colors as the ones passed in
      fillColors: ['#660099', '#eb3c7d', '#5cb85c', '#dc3545', '#17a2b8', '#ffc107'],
    }
  },
  tooltip: {
    // enabled: true,
    // enabledOnSeries: undefined,
    // shared: true,
    // followCursor: false,
    // intersect: false,
    // inverseOrder: false,
    // custom: undefined,
    fillSeriesColor: true,
    // theme: dark,
    style: {
      fontSize: '12px',
      fontFamily: undefined,
      
    },
    // onDatasetHover: {
    //     highlightDataSeries: false,
    // },
    // x: {
    //     show: true,
    //     format: 'dd MMM',
    //     formatter: undefined,
    // },
    // y: {
    //     formatter: undefined,
    //     title: {
    //         formatter: (seriesName) => seriesName,
    //     },
    // },
    // z: {
    //     formatter: undefined,
    //     title: 'Size: '
    // },
    // marker: {
    //     show: true,
    // },
    // fixed: {
    //     enabled: false,
    //     position: 'topRight',
    //     offsetX: 0,
    //     offsetY: 0,
    // },
  },
  dataLabels: {
    dropShadow: {
      enabled: false,
      top: 1,
      left: 1,
      blur: 1,
      opacity: 0.45,
    },
    style: {
      colors: ["#f5f5f5"],
    },
  },
  colors: ['#660099', '#eb3c7d', '#5cb85c', '#dc3545', '#17a2b8', '#ffc107'],
  fill: {
    colors: ['#660099', '#eb3c7d', '#5cb85c', '#dc3545', '#17a2b8', '#ffc107']
  },
  plotOptions: {
    pie: {
      donut: {
        labels: {
          show: true,
          total: {
            showAlways: true,
            show: true,
          },
        },
      },
    },
  },
};
