import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "deliveryDate",
})
export class DeliveryDatePipe implements PipeTransform {
  transform(value: any[]): any {
    const insumoLength = value?.length || 0;

    if (insumoLength <= 0) {
      return "Sem Data de Entrega";
    }

    return value[insumoLength - 1].createdAt;
  }
}
