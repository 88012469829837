export class Pendencia{

    constructor(
        public id: number,
        public idPedidoPortal: number,
        public pendencias: [],
        public message: string
    ){}

        public equals(obj: Pendencia) : boolean {
            return this.id === obj.id;
        }


    }
