<loading></loading>

<div *ngIf="user$ | async as user; else noUser">
  <div class="d-flex" id="wrapper" *ngIf="user">
    <app-sidebar class="app-sidebar" [data]="user"></app-sidebar>
    <div id="page-content-wrapper">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <app-menu [data]="user"></app-menu>
          </div>
        </div>
      </div>

      <div class="container-fluid mb-chat">
        <div class="app-container c-alert">
          <alert> </alert>
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>

  <div class="footer" *ngIf="user">
    <footer
      class="page-footer font-small blue bg-primary text-center text-white p-3"
    >
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <p class="m-0">
              Copyright ©️ 2020 - {{ actualYear }} Portal de Comissionamento |
              Desenvolvido por MEDIA4ALL - Todos os Direitos Reservados.
            </p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</div>

<ng-template #noUser>
  <div class="d-flex" id="wrapper">
    <div id="page-content-wrapper">
      <div class="container-fluid mb-chat">
        <div class="app-container c-alert">
          <alert> </alert>
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div
  bsModal
  #childModal="bs-modal"
  class="modal fade in"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
  [config]="{ ignoreBackdropClick: true, keyboard: false }"
>
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h1 id="dialog-child-name" class="modal-title pull-left text-primary">
          Aviso de Inatividade!
        </h1>
      </div>
      <div class="modal-body">
        <div class="text-danger" [innerHtml]="idleState"></div>
        <div class="row mrgbtm mt-3">
          <div class="col-md-6">
            <button
              type="button"
              (click)="logout()"
              class="btn btn-success"
              style="width: 100%"
            >
              Logout
            </button>
          </div>
          <div class="col-md-6">
            <button
              type="button"
              (click)="stay()"
              class="btn btn-primary"
              style="width: 100%"
            >
              Continuar Logado
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
