import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class loginService {
  private loginTwoAtuh: UserLoginTwoAuthFactor;

  constructor() {
    this.loginTwoAtuh = null;
  }

  setLoginData(data: UserLoginTwoAuthFactor) {
    this.loginTwoAtuh = data;
  }

  getLoginData() {
    return this.loginTwoAtuh;
  }
}

export interface UserLoginTwoAuthFactor {
  email: string;
  mask: string;
  password: string;
}
