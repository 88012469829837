import { Component, ViewChild } from "@angular/core";
import { ChartComponent } from "ng-apexcharts";
import { TicketService } from "src/app/_services/ticket.service";
import { chartPartialOptions } from "../../_helpers/chartPartialOptions";
import { ChartOptions } from "../../_models/tickets";

@Component({
  selector: "app-todos-tickets",
  templateUrl: "./todos-tickets.component.html",
  styleUrls: ["./todos-tickets.component.css"],
})
export class TodosTicketsComponent {
  chartOptions: Partial<ChartOptions>;
  @ViewChild("chart") chart: ChartComponent;

  constructor(private ticketService: TicketService) {
    this.chartOptions = {
      ...chartPartialOptions,
    };
  }

  ngOnInit(): void {
    this.getAllTickets();
  }

  getAllTickets() {
    this.ticketService
      .getAllTicketChart()
      .subscribe(
        ({
          cancelado,
          concluido,
          devolvido,
          emAberto,
          emAndamento,
          reaberto,
        }) => {
          this.chartOptions.series = [
            emAberto,
            emAndamento,
            concluido,
            cancelado,
            devolvido,
            reaberto,
          ];
        }
      );
  }
}
