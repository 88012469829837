import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";

import { AlertService } from "../../_services";
import { InsumosFieldsService } from "../../_services/insumosFields.service";

@Component({
  selector: "app-insumo-field-config",
  templateUrl: "./insumo-field-config.component.html",
  styleUrls: ["./insumo-field-config.component.css"],
})
export class InsumoFieldConfigComponent implements OnInit {
  @ViewChild("areaPaginator") areaPaginator: MatPaginator;
  @ViewChild("segmentoPaginator") segmentoPaginator: MatPaginator;
  @ViewChild("tipoDeInsumoPaginator") tipoDeInsumoPaginator: MatPaginator;
  @ViewChild("periodicidadePaginator") periodicidadePaginator: MatPaginator;
  @ViewChild("cadastroPublicoPaginator") cadastroPublicoPaginator: MatPaginator;
  @ViewChild("closeModalAdd") closeModalAdd: ElementRef;
  @ViewChild("closeModalEdit") closeModalEdit: ElementRef;

  constructor(
    private insumoFieldService: InsumosFieldsService,
    private alertService: AlertService,
    private formBuilder: FormBuilder
  ) {}

  dataSource_Area = new MatTableDataSource<TablePeriodicElement>();
  dataSource_Segmento = new MatTableDataSource<TablePeriodicElement>();
  dataSource_TipoDeInsumo = new MatTableDataSource<TablePeriodicElement>();
  dataSource_Periodicidade = new MatTableDataSource<TablePeriodicElement>();
  dataSource_CadastroPublico = new MatTableDataSource<TablePeriodicElement>();

  formData_Area: MatTableDataSource<any>;
  formData_Segmento: MatTableDataSource<any>;
  formData_TipoDeInsumo: MatTableDataSource<any>;
  formData_Periodicidade: MatTableDataSource<any>;
  formData_CadastroPublico: MatTableDataSource<any>;

  resultsLength_Area: Number = 0;
  resultsLength_Segmento: Number = 0;
  resultsLength_TipoDeInsumo: Number = 0;
  resultsLength_Periodicidade: Number = 0;
  resultsLength_CadastroPublico: Number = 0;

  modalAddSelectedType: string;

  formAdd: FormGroup;
  formEdit: FormGroup;
  formEditId: string;
  submitted: boolean = false;
  submittedEdit: boolean = false;

  startScreen: boolean = false;

  ngOnInit(): void {
    this.formAdd = this.formBuilder.group({
      fieldDescription: [""],
      quantity: [""],
    });

    this.formEdit = this.formBuilder.group({
      fieldDescription: [""],
      quantity: [""],
    });
  }

  ngAfterViewInit(): void {
    this.getAllTableContent();
  }

  getAllTableContent() {
    this.getInsumosFieldsArea();
    this.getInsumosFieldsSegmento();
    this.getInsumosFieldsTipoDeInsumo();
    this.getInsumosFieldsPeriodicidade();
    this.getInsumosFieldsCadastroPublico();
    this.startScreen = true;
  }

  disable(id: string) {
    this.insumoFieldService.changeStatus(id).subscribe(() => {
      this.getSelectType();
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formAdd.controls;
  }

  get edit(): { [key: string]: AbstractControl } {
    return this.formAdd.controls;
  }

  submit() {
    this.submitted = true;
    if (this.formAdd.invalid) {
      return;
    }

    let quantity = this.formAdd.get("quantity").value;

    if (quantity == null || quantity == undefined || quantity == "") {
      quantity = 0;
    }

    const createField = {
      fieldType: this.validateSelectedType(),
      fieldDescription: this.formAdd.get("fieldDescription").value,
      quantity: quantity,
    };

    this.insumoFieldService.save(createField).subscribe(() => {
      this.closeModalAdd.nativeElement.click();
      this.formAdd.reset();
      this.getSelectType();
      this.submitted = false;
      this.alertService.success("Campo do Insumo Criado Com Sucesso!", {
        keepAfterRouteChange: true,
        autoClose: true,
      });
    });
  }

  submitEdit() {
    this.submittedEdit = true;
    if (this.formEdit.invalid) {
      return;
    }

    let quantity = this.formEdit.get("quantity").value;

    if (quantity == null || quantity == undefined || quantity == "") {
      quantity = 0;
    }

    const editField = {
      fieldType: this.validateSelectedType(),
      fieldDescription: this.formEdit.get("fieldDescription").value,
      quantity: quantity,
    };

    this.insumoFieldService.update(this.formEditId, editField).subscribe(() => {
      this.closeModalEdit.nativeElement.click();
      this.formEditId = "";
      this.formEdit.reset();
      this.getSelectType();
      this.submittedEdit = false;
      this.alertService.success("Campo do Insumo Editado Com sucesso!", {
        keepAfterRouteChange: true,
        autoClose: true,
      });
    });
  }

  populateEditForm(id: string) {
    this.formEditId = id;
    this.insumoFieldService.getById(id).subscribe((data) => {
      this.formEdit.get("fieldDescription").setValue(data.fieldDescription);
      this.formEdit.get("quantity").setValue(data.quantity);
    });
  }

  getInsumosFieldsArea() {
    this.insumoFieldService.getByType("AREA").subscribe((data) => {
      this.dataSource_Area = new MatTableDataSource(data.reverse());
      this.formData_Area = data;
      this.resultsLength_Area = data.length;
      this.dataSource_Area.paginator = this.areaPaginator;
    });
  }

  getInsumosFieldsCadastroPublico() {
    this.insumoFieldService.getByType("CADASTRO_PUBLICO").subscribe((data) => {
      this.dataSource_CadastroPublico = new MatTableDataSource(data.reverse());
      this.formData_CadastroPublico = data;
      this.resultsLength_CadastroPublico = data.length;
      this.dataSource_CadastroPublico.paginator = this.cadastroPublicoPaginator;
    });
  }

  getInsumosFieldsSegmento() {
    this.insumoFieldService.getByType("SEGMENTO").subscribe((data) => {
      this.dataSource_Segmento = new MatTableDataSource(data.reverse());
      this.formData_Segmento = data;
      this.resultsLength_Segmento = data.length;
      this.dataSource_Segmento.paginator = this.segmentoPaginator;
    });
  }

  getInsumosFieldsPeriodicidade() {
    this.insumoFieldService.getByType("PERIODICIDADE").subscribe((data) => {
      this.dataSource_Periodicidade = new MatTableDataSource(data.reverse());
      this.formData_Periodicidade = data;
      this.resultsLength_Periodicidade = data.length;
      this.dataSource_Periodicidade.paginator = this.periodicidadePaginator;
    });
  }

  getInsumosFieldsTipoDeInsumo() {
    this.insumoFieldService.getByType("TIPO_DE_INSUMO").subscribe((data) => {
      this.dataSource_TipoDeInsumo = new MatTableDataSource(data.reverse());
      this.formData_TipoDeInsumo = data;
      this.resultsLength_TipoDeInsumo = data.length;
      this.dataSource_TipoDeInsumo.paginator = this.tipoDeInsumoPaginator;
    });
  }

  validateSelectedType(): String {
    if (this.modalAddSelectedType == "Area") {
      return "AREA";
    }

    if (this.modalAddSelectedType == "Segmento") {
      return "SEGMENTO";
    }

    if (this.modalAddSelectedType == "Periodicidade") {
      return "PERIODICIDADE";
    }

    if (this.modalAddSelectedType == "Tipo de Insumo") {
      return "TIPO_DE_INSUMO";
    }

    if (this.modalAddSelectedType == "Cadastro de Publico") {
      return "CADASTRO_PUBLICO";
    }
  }

  getSelectType() {
    if (this.modalAddSelectedType == "Area") {
      this.getInsumosFieldsArea();
    }

    if (this.modalAddSelectedType == "Segmento") {
      this.getInsumosFieldsSegmento();
    }

    if (this.modalAddSelectedType == "Periodicidade") {
      this.getInsumosFieldsPeriodicidade();
    }

    if (this.modalAddSelectedType == "Tipo de Insumo") {
      this.getInsumosFieldsTipoDeInsumo();
    }

    if (this.modalAddSelectedType == "Cadastro de Publico") {
      this.getInsumosFieldsCadastroPublico();
    }
  }

  displayedColumns: string[] = [
    "fieldDescription",
    "status",
    "creationDt",
    "actions",
  ];

  displayedColumnsPeriodicidade: string[] = [
    "fieldDescription",
    "quantity",
    "status",
    "creationDt",
    "actions",
  ];
}

export interface TablePeriodicElement {
  id: string;
  fieldType: string;
  fieldDescription: string;
  quantity: string;
  status: string;
  creationDt: string;
  updatedDt: string;
  acoes: string;
}
