<nav class="bg-primary-topo border-bottom">
  <ul class="novo-menu-flex-left">
    <li id="step-expandir-recolher" class="ml-1">
      <button
        matTooltip="Encolher ou expandir menu"
        [matTooltipPosition]="position.value"
        class="btn btn-primary bg-movement"
        id="menu-toggle"
        (click)="logoVivo('logo-vivo')"
      >
        <mat-icon class="display-icon-desktop" color="link" matListIcon>
          zoom_out_map
        </mat-icon>
        <mat-icon class="display-icon-mobile" color="link" matListIcon>
          menu_open
        </mat-icon>
      </button>
    </li>

    <li id="step-name-plat btn-block" class="ml-2 w-100">
      <span class="btn btn-block btn-primary w-100 bg-movement">
        Portal de Comissionamento
      </span>
    </li>
  </ul>

  <ul class="novo-menu-flex">
    <li id="step-perfil-usuario" class="cursor-pointer area-perfil mr-3">
      <div class="dropdown">
        <a
          class=""
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div class="menu-flex">
            <img
              src="https://portaldeassinaturas.vivo.com.br/usoproprioui/assets/perfil/user.svg"
              class="img-fluid perfil-lg mr-2"
              alt=""
            />

            <p class="text-muted text-left m-0 name-cargo">
              <span class="name">
                {{ this.data.user.firstName }} {{ this.data.user.lastName }}
              </span>
              <br />
              <span class="cargo text-success">
                {{ this.data.user.userType | cap }}
              </span>
            </p>
          </div>
        </a>

        <div
          class="dropdown-menu dropdown-menu-right"
          aria-labelledby="dropdownMenuButton"
        >
          <button class="dropdown-item p-2" type="button" (click)="logout()">
            <mat-icon>logout</mat-icon>
            Logout
          </button>
        </div>
      </div>
    </li>
  </ul>
</nav>
