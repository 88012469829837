import { ErrorDialogService } from "src/app/_services/error.service";

import { NgZone, Injectable, ErrorHandler } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private errorDialogService: ErrorDialogService,
    private zone: NgZone
  ) {}

  handleError(error: any) {
    if (!(error instanceof HttpErrorResponse)) {
      error = error.rejection;
    }

    if (!(error?.message.includes("S3") || error?.message.includes("Denied"))) {
      return true;
    }

    this.zone.run(() =>
      this.errorDialogService.openDialog(
        error?.message || "Undefined client error"
      )
    );

    console.error("Error from global error handler", error);
  }
}
