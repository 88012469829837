import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MapeamentodocumentalService {

  constructor(private http: HttpClient) { } 


    getMapeamentoById(id){
      return this.http.get<any>(`${environment.apiUrl}/mapeamento-documental/${id}`);
  }

  cancelMapeamento(mapeamentoId){
    return this.http.get<any>(`${environment.apiUrl}/mapeamento-documental/cancel/${mapeamentoId}`);
  }

  reabrirMapeamento(mapeamentoId){
    return this.http.get<any>(`${environment.apiUrl}/mapeamento-documental/open/${mapeamentoId}`);
  }

  deleteMapeamento(mapeamentoId){
    return this.http.delete<any>(`${environment.apiUrl}/mapeamento-documental/${mapeamentoId}`);
  }
}
