import { Component, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";

import { TableUtil } from "../_helpers/tableUtil";
import { User } from "../_models";
import { CaptalizePipe } from "../_pipes/captalize.pipe";
import { AccountService, AlertService } from "../_services";
import { SolicitacaoService } from "../_services/solicitacao.service";

@Component({
  selector: "app-solicitacao-de-acesso",
  templateUrl: "./solicitacao-de-acesso.component.html",
  styleUrls: ["./solicitacao-de-acesso.component.css"],
})
export class SolicitacaoAcessoComponent implements OnInit {
  private paginator: MatPaginator;

  negarDto: NegarDto = {};

  formNegar: FormGroup;
  formView: FormGroup;
  formEdit: FormGroup;

  submitted = false;
  user: User;
  isLoadingData: boolean = true;

  dataSource = new MatTableDataSource<PeriodicElement>();
  solicitationData: MatTableDataSource<any>;

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  constructor(
    private alertService: AlertService,
    private accountService: AccountService,
    private solicitacaoService: SolicitacaoService,
    private formBuilder: FormBuilder
  ) {
    this.user = this.accountService.userValue.user;
  }

  ngOnInit(): void {
    this.formNegar = this.formBuilder.group({
      justificativa: [""],
    });

    this.formView = this.formBuilder.group({
      area: [""],
      email: [""],
      firstName: [""],
      id: [""],
      justificativa: [""],
      lastName: [""],
      solicitacaoAcessoStatusEnum: [""],
      userAceite: [""],
      userTypeEnum: [""],
    });

    this.formEdit = this.formBuilder.group({
      area: [""],
      email: [""],
      firstName: [""],
      id: [""],
      justificativa: [""],
      lastName: [""],
      userAceite: [""],
      userTypeEnum: [""],
    });

    this.formView.disable();
  }

  ngAfterViewInit() {
    this.getAllSolicitation();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formNegar.controls;
  }

  get v(): { [key: string]: AbstractControl } {
    return this.formView.controls;
  }

  get e(): { [key: string]: AbstractControl } {
    return this.formEdit.controls;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.formNegar.invalid) {
      return;
    }

    this.negarDto.justificativa = this.formNegar.get("justificativa").value;
    this.sendToNegarDto();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
  }

  negarDtoPopulate(id) {
    this.negarDto.solicitacaoAcessoId = id;
    this.negarDto.userId = this.user.id;
  }

  populateView(element) {
    this.formView.setValue({
      id: element.id,
      firstName: element.firstName,
      lastName: element.lastName,
      email: element.email,
      area: element.area,
      justificativa: element.justificativa,
      solicitacaoAcessoStatusEnum: element.solicitacaoAcessoStatusEnum,
      userAceite: element.userAceite,
      userTypeEnum: element.userTypeEnum,
    });
  }

  populateEdit(element) {
    this.formEdit.setValue({
      id: element.id,
      firstName: element.firstName,
      lastName: element.lastName,
      email: element.email,
      area: element.area,
      justificativa: element.justificativa,
      userAceite: element.userAceite,
      userTypeEnum: element.userTypeEnum,
    });
  }

  sendToNegarDto() {
    this.solicitacaoService
      .negarSolicitacaoAcesso(this.negarDto)
      .subscribe(() => {
        this.alertService.success("Solicitação de acesso negada!");
        this.getAllSolicitation();
      });
    this.negarDto = {};
  }

  aproveRequest() {
    let solicitacaoDto = {
      solicitacaoAcessoId: this.formEdit.get("id").value,
      userId: this.user.id,
    };

    this.solicitacaoService
      .aprovarSolicitacaoAcesso(solicitacaoDto)
      .subscribe(() => {
        this.alertService.success("Solicitação de acesso aprovada!");
        this.getAllSolicitation();
      });
  }

  getAllSolicitation() {
    this.isLoadingData = true;
    this.solicitacaoService
      .getAccessSolicitationEmEspera()
      .subscribe((data) => {
        this.dataSource = new MatTableDataSource(data);
        this.isLoadingData = false;
      });
  }

  async getAllSolicitationForDownload() {
    await this.solicitacaoService
      .getAccessSolicitationAll()
      .toPromise()
      .then((data) => {
        this.solicitationData = new MatTableDataSource(data);
      });
  }

  async downloadSolicitations() {
    this.isLoadingData = true;

    await this.getAllSolicitationForDownload();
    TableUtil.exportJsonToExcel(this.createJsonForXls());

    this.isLoadingData = false;
  }

  createJsonForXls() {
    const cap = new CaptalizePipe();
    const content = [];
    let data = new Object();

    this.columnsForXls.forEach((column) => {
      data[column] = column;
    });

    let clean = data;

    this.solicitationData.filteredData.forEach((element) => {
      data["id"] = element.id;
      data["Usuário"] = element.firstName + " " + element.lastName;
      data["Email"] = element.email;
      data["Tipo de Usuário"] = cap.transform(element.userType);
      data["Área"] = element.area;
      data["Data Solicitação Cadastro"] =
        element.creationDate === null
          ? "Sem data ded Criação"
          : this.dateTransform(element.creationDate);

      if (element.approvalDate == null || element.approvalDate == undefined) {
        if (element.solicitacaoAcessoStatusEnum == "NEGADO") {
          data["Data Aprovação Cadastro"] = "Negado";
        } else if (element.solicitacaoAcessoStatusEnum == "APROVADO") {
          data["Data Aprovação Cadastro"] = "Sem data de Aprovação";
        } else {
          data["Data Aprovação Cadastro"] = "Pendente";
        }
      } else {
        data["Data Aprovação Cadastro"] = this.dateTransform(
          element.approvalDate
        );
      }

      clean = { ...data };

      content.push(clean);

      Object.keys(data).forEach((key) => {
        data[key] = [];
      });
    });

    return content;
  }

  dateTransform(date: number): string {
    function formatZero(numero) {
      return numero <= 9 ? "0" + numero : numero;
    }

    let timeStamp_date = new Date(date);
    let year = timeStamp_date.getFullYear();
    let month = formatZero(timeStamp_date.getMonth() + 1);
    let day = formatZero(timeStamp_date.getDate());
    let hour = formatZero(timeStamp_date.getHours());
    let min = formatZero(timeStamp_date.getMinutes());

    return `${day}/${month}/${year} - ${hour}:${min}`;
  }

  displayedColumns: string[] = [
    "id",
    "name",
    "email",
    "area",
    "userType",
    "solicitacaoAcessoStatusEnum",
    "acoes",
  ];

  columnsForXls: string[] = [
    "id",
    "Usuário",
    "Email",
    "Tipo de Usuário",
    "Área",
    "Data Solicitação Cadastro",
    "Data Aprovação Cadastro",
  ];
}

interface NegarDto {
  justificativa?: string;
  solicitacaoAcessoId?: number;
  userId?: number;
}

export interface PeriodicElement {
  id: number;
  firstName: String;
  lastName: String;
  email: String;
  area: String;
  userType: String;
  solicitacaoAcessoStatusEnum: String;
  acoes: string;
}

const test: PeriodicElement[] = [
  {
    id: 1,
    firstName: "João",
    lastName: "Silva",
    email: "carlos@gmail.com",
    area: "TI",
    userType: "ADMIN",
    solicitacaoAcessoStatusEnum: "PENDENTE",
    acoes: "",
  },
];
