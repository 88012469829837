<h1 class="text-primary mt-4">O que fazemos?</h1>
<p class="mb-4">
    A área de Gestão Documental da Telefônica Brasil é responsável pelas seguintes atividades:
</p>

<ul>
    <li>
        Efetivar a transformação digital dos processos documentais (assinaturas digitais de documentos; digitalização de
        documentos; automação dos processos e fluxos documentais e implementação de plataformas digitais de
        armazenamento,
        acesso e preservação a longo prazo dos documentos);
    </li>
    <!-- <br> -->

    <li>
        Gestão integral do processo de Gestão Documental em todos os seus aspectos e interfaces;
    </li>
    <!-- <br> -->

    <li>
        Desenvolvimento e implementação de metodologias e melhorias em Gestão Documental;
    </li>
    <!-- <br> -->


    <li>
        Implementar e gerenciar medidas de segurança e confidencialidade da informação;
    </li>
    <!-- <br> -->

    <li>
        Apoiar as áreas na adoção de procedimentos e metodologias para assegurar a integridade física e digital de todos
        os
        documentos produzidos;
    </li>
    <!-- <br> -->

    <li>
        Mapear o cenário de Gestão de Documentos da Telefônica Brasil e propor melhorias contínuas (produção,
        organização,
        armazenamento, acesso e uso de documentos);
    </li>
    <!-- <br> -->

    <li>
        Gerenciamento do ciclo de vida dos documentos através do Plano de Classificação e Tabela de Temporalidade de
        Documentos;
    </li>
    <!-- <br> -->

    <li>
        Identificar junto com a área responsável pelo documento o seu ciclo de vida e prazo de permanência em arquivo,
        digital ou físico, efetuando com frequência o expurgo dos documentos fora de seu prazo de guarda, com a
        concordância
        da área responsável pelo documento;
    </li>
    <!-- <br> -->

    <li>
        Melhoria contínua dos processos documentais .
    </li>
</ul>