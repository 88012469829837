<div class="row mt-4 mb-4 inputSearch">
  <div class="col-12">
    <div class="search-section w-100">
      <h1 class="text-white m-0">Wiki Comissionamento</h1>
      <p class="text-white mb-3">
        Conheça a base de conhecimento do Portal de Comissionamento
      </p>

      <div class="row mt-1 ksa">
        <div class="col-lg-12">
          <form action="" class="">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>O que você está procurando?</mat-label>
              <input
                matInput
                type="text"
                (value)="(searchInput)"
                (change)="setValue($event)"
              />
              <button
                mat-button
                matSuffix
                (click)="search({ title: searchInput })"
              >
                <div class="button-flex">
                  <mat-icon>search</mat-icon>
                  Explorar
                </div>
              </button>
            </mat-form-field>
          </form>
        </div>
      </div>
      <div class="row mais-acessados mt-4">
        <div
          class="col-lg-2 mt-2 mb-2 text-center"
          (click)="search({ area: 'projetos-gestao-regras' })"
        >
          <div class="img-circle">
            <img src="../../../assets/imagens/vivo-white.svg" class="" alt="" />
          </div>
          <p class="text-center m-0 text-white mt-2" style="cursor: pointer">
            Proj. e Gestão de Regras
          </p>
        </div>
        <div
          class="col-lg-2 mt-2 mb-2 text-center"
          (click)="
            search({
              title: undefined,
              categoryId: undefined,
              area: 'apuracao-resultados'
            })
          "
        >
          <div class="img-circle">
            <img src="../../../assets/imagens/vivo-white.svg" class="" alt="" />
          </div>
          <p class="text-center m-0 text-white mt-2" style="cursor: pointer">
            Apuração de Resultados
          </p>
        </div>
        <div
          class="col-lg-2 mt-2 mb-2 text-center"
          (click)="
            search({
              title: undefined,
              categoryId: undefined,
              area: 'estrategia-comissionamento'
            })
          "
        >
          <div class="img-circle">
            <img src="../../../assets/imagens/vivo-white.svg" class="" alt="" />
          </div>
          <p class="text-center m-0 text-white mt-2" style="cursor: pointer">
            Estratégia de Comissionamento
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row mt-4 mb-4 cards">
  <div class="col-lg-8 mb-2">
    <div class="wiki-list divReturnSearh">
      <div class="wiki-list_title">
        <h2>Wiki</h2>
        <span>{{ activeFilter }}</span>
      </div>

      <div class="card-wiki" *ngFor="let element of wikis">
        <div class="bg-thumb" *ngIf="element.thumbnail != 0">
          <img [src]="element.thumbnail" class="img-thumb" alt="" />
        </div>
        <div class="card-wiki_body">
          <div>
            <h2 class="m-0">
              {{ element.title }}
            </h2>

            <div class="card-wiki_body__details">
              <div class="flex-icon-btn">
                <mat-icon class="material-symbols-outlined">
                  calendar_month
                </mat-icon>
                <p class="m-0">
                  {{ element.creationDate | date : "dd/MM/yyyy" }}
                </p>
              </div>

              <div class="flex-icon-btn">
                <mat-icon class="material-symbols-outlined"> folder </mat-icon>
                <p class="m-0">
                  {{ element.fileType }}
                </p>
              </div>
            </div>
          </div>

          <p class="m-0 text-muted">
            {{ element.description.slice(0, 100) }}
            {{ element.description.length > 100 ? "..." : "" }}
          </p>

          <div class="">
            <a
              href="/arquivos/visualizar/{{ element.id }}"
              class="btn btn-outline-primary m-0 mr-3 btn-sb"
            >
              <div class="flex-icon-btn">
                <mat-icon class="material-symbols-outlined">
                  open_in_new
                </mat-icon>
                Saiba Mais
              </div>
            </a>
          </div>
        </div>
      </div>

      <div *ngIf="hasNoContent" class="no-content">
        <h2 class="m-0">Ops! Nada por aqui...</h2>
        <p class="text-muted">
          Parece que ainda não temos nenhum arquivo da Wiki disponível. Que tal
          voltar mais tarde ou tentar outra pesquisa? Estamos sempre atualizando
          nosso conteúdo para melhor atendê-lo!
        </p>
      </div>
    </div>
  </div>

  <div class="col-lg-4 aside">
    <div class="bg-white p-3">
      <div class="row mb-4">
        <div class="col-lg-12">
          <h2>Pesquisar</h2>
        </div>
        <div class="col-lg-12">
          <form action="" class="">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>O que você está procurando?</mat-label>
              <input
                matInput
                type="text"
                (value)="(searchInput)"
                (change)="setValue($event)"
              />
              <button
                mat-button
                matSuffix
                (click)="search({ title: searchInput })"
              >
                <div class="button-flex">
                  <mat-icon>search</mat-icon>
                  Explorar
                </div>
              </button>
            </mat-form-field>
          </form>
        </div>
      </div>

      <div class="categories">
        <h2>Categorias</h2>

        <div class="categories_list">
          <button
            (click)="search({ area: 'projetos-gestao-regras' })"
            class="categorySearch"
          >
            <div class="text-left flex-icon-btn">
              <mat-icon class="material-symbols-outlined">category</mat-icon>
              <p class="m-0">Projetos e Gestão de Regras</p>
            </div>
            <p class="text-muted">
              ({{ wikiCount["projetos-gestao-regras"] ?? 0 }})
            </p>
          </button>

          <button
            (click)="
              search({
                title: undefined,
                categoryId: undefined,
                area: 'apuracao-resultados'
              })
            "
            class="categorySearch"
          >
            <div class="text-left flex-icon-btn">
              <mat-icon class="material-symbols-outlined">category</mat-icon>
              <p class="m-0">Apuração de Resultados</p>
            </div>
            <p class="text-muted">
              ({{ wikiCount["apuracao-resultados"] ?? 0 }})
            </p>
          </button>

          <button
            (click)="
              search({
                title: undefined,
                categoryId: undefined,
                area: 'estrategia-comissionamento'
              })
            "
            class="categorySearch"
          >
            <div class="text-left flex-icon-btn">
              <mat-icon class="material-symbols-outlined">category</mat-icon>
              <p class="m-0">Estratégia de Comissionamento</p>
            </div>

            <p class="text-muted">
              ({{ wikiCount["estrategia-comissionamento"] ?? 0 }})
            </p>
          </button>
        </div>
      </div>
    </div>
    <!-- <div class="bg-white p-3 mt-4">
      <div class="row mb-2">
        <div class="col-lg-12">
          <h2 class="m-0">Itens Relevantes</h2>
        </div>

        <div class="col-lg-12">
          <ul class="list-category">
            <li>
              <a href="">
                <div class="text-left flex-icon-btn">
                  <mat-icon class="text-dark material-symbols-outlined"
                    >folder</mat-icon
                  >
                  <h3 class="text-dark m-0">Lorem Ipsum</h3>
                </div>
                <p class="m-0 text-muted">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis
                  expedita eum magni dolore impedit, nesciunt repudiandae
                  asperiores perspiciatis vel omnis illum dolorum alias eos
                  delectus suscipit magnam reprehenderit officiis aut.
                </p>
              </a>
            </li>

            <li>
              <a href="">
                <div class="text-left flex-icon-btn">
                  <mat-icon class="text-dark material-symbols-outlined"
                    >folder</mat-icon
                  >
                  <h3 class="text-dark m-0">Lorem Ipsum</h3>
                </div>
                <p class="m-0 text-muted">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis
                  expedita eum magni dolore impedit, nesciunt repudiandae
                  asperiores perspiciatis vel omnis illum dolorum alias eos
                  delectus suscipit magnam reprehenderit officiis aut.
                </p>
              </a>
            </li>

            <li>
              <a href="">
                <div class="text-left flex-icon-btn">
                  <mat-icon class="text-dark material-symbols-outlined"
                    >folder</mat-icon
                  >
                  <h3 class="text-dark m-0">Lorem Ipsum</h3>
                </div>
                <p class="m-0 text-muted">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis
                  expedita eum magni dolore impedit, nesciunt repudiandae
                  asperiores perspiciatis vel omnis illum dolorum alias eos
                  delectus suscipit magnam reprehenderit officiis aut.
                </p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div> -->
  </div>
</div>
