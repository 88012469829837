import { Component, OnInit } from '@angular/core';

@Component({
  selector: "app-comissionamento",
  templateUrl: "./comissionamento.component.html",
  styleUrls: ["./comissionamento.component.css"],
})
export class ComissionamentoComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
