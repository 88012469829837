import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LayoutImportacaoService {

  constructor(private http: HttpClient) { }


  getAllLayoutImportacao(codigoPerfil, page, count,sort,order) {
    return this.http.get<any>(`${environment.apiUrl}/vinculo-perfil-docto/search/${codigoPerfil}/${page}/${count}?sort=${sort}&order=${order}`).pipe(tap(console.log));
  }
}
