<h1 class="text-muted">Layout de Importação</h1>

<form [formGroup]="formEmpresa">
    <div class="row">
        <div class="col-sm-6">
            <div class="form-group">
                <mat-form-field appearance="fill" class="selects100vw">
                    <mat-label> Por Empresa:</mat-label>
                    <mat-select formControlName="idEmpresa">
                        <mat-option *ngFor="let empresa of empresas" [value]="empresa.codigo"
                            (click)="selectEmpresaFilter()">{{empresa.descricao}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <mat-form-field appearance="fill" class="selects100vw">
                    <mat-label> Por Departamento:</mat-label>
                    <mat-select formControlName="codigoPerfil">
                        <mat-option *ngFor="let departamento of departamentos" [value]="departamento.codigoPerfil"
                            (click)="selectDepartamentoFilter()">{{departamento.departamento}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</form>



<div class="mat-elevation-z8">
    <table class="table-striped" mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="id">
            <th class="id" mat-header-cell *matHeaderCellDef> Id. </th>
            <td class="id" mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <ng-container matColumnDef="descricao">
            <th mat-header-cell *matHeaderCellDef> Descrição </th>
            <td class="descricao" mat-cell *matCellDef="let element"> {{element.descricao}} </td>
        </ng-container>

        <ng-container matColumnDef="expurgo">
            <th mat-header-cell *matHeaderCellDef> Expurgo </th>
            <td class="expurgo" mat-cell *matCellDef="let element"> {{element.expurgo}} </td>
        </ng-container>

        <ng-container matColumnDef="prazo">
            <th mat-header-cell *matHeaderCellDef> Prazo </th>
            <td class="prazo" mat-cell *matCellDef="let element"> {{element.prazo}} </td>
        </ng-container>

        <ng-container matColumnDef="periodo">
            <th mat-header-cell *matHeaderCellDef> Período </th>
            <td class="periodo" mat-cell *matCellDef="let element"> {{element.periodo}} </td>
        </ng-container>

        <ng-container matColumnDef="digitalizar">
            <th mat-header-cell *matHeaderCellDef> Digitalizar </th>
            <td class="digitalizar" mat-cell *matCellDef="let element"> {{element.digitalizar}} </td>
        </ng-container>

        <ng-container matColumnDef="guarda">
            <th mat-header-cell *matHeaderCellDef> Guarda </th>
            <td class="guarda" mat-cell *matCellDef="let element"> {{element.guarda}} </td>
        </ng-container>

        <ng-container matColumnDef="ativo">
            <th mat-header-cell *matHeaderCellDef> Ativo </th>
            <td class="ativo" mat-cell *matCellDef="let element"> {{element.ativo}} </td>
        </ng-container>

        <!-- ações -->
        <ng-container matColumnDef="acoes">
            <th mat-header-cell *matHeaderCellDef> Ações </th>
            <td class="acoes" mat-cell *matCellDef="let element">
                <a routerLink="definir-layout-importacao" matTooltip="Definir Layout de Importação"
                    class="btn btn-sm btn-success text-white mr-2 a-acoes">
                    <div class="mat-icon-flex-button">
                        <mat-icon>edit </mat-icon>
                    </div>
                </a>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="pageEvent = $event"></mat-paginator>
</div>