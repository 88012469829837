<p class="text-muted mt-4 mb-1">
  <span class="text-primary">Portal de Comissionamento.</span>
</p>

<h1 class="mt-0 mb-0 text-muted">Recepcionar Pedidos</h1>

<div class="row mt-4">
  <div class="col-sm-8">
    <div class="form-group">
      <mat-form-field appearance="fill">
        <mat-label>Data do pedido</mat-label>
        <input matInput [matDatepicker]="picker" [(ngModel)]="criadoEm" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <mat-form-field appearance="fill">
            <mat-label>Empresa:</mat-label>
            <input
              matInput
              placeholder="Ex. "
              value=""
              [(ngModel)]="razaoSocial"
              disabled
            />
          </mat-form-field>
        </div>
      </div>

      <div class="col-sm-6">
        <div class="form-group">
          <mat-form-field appearance="fill" class="inputs">
            <mat-label>Solicitante:</mat-label>
            <input
              matInput
              placeholder="Ex. "
              value=""
              [(ngModel)]="solicitante"
              disabled
            />
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="form-group">
      <mat-form-field appearance="fill" class="inputs">
        <mat-label>Departamento:</mat-label>
        <input
          matInput
          placeholder="Ex. "
          value=""
          [(ngModel)]="departamento"
          disabled
        />
      </mat-form-field>
    </div>

    <div class="form-group">
      <mat-form-field appearance="fill" class="example-full-width">
        <mat-label>Observações</mat-label>
        <textarea matInput [(ngModel)]="observacao" disabled></textarea>
      </mat-form-field>
    </div>

    <div class="form-group">
      <mat-label>Opções</mat-label>
      <br />
      <mat-slide-toggle class="mr-3" [(ngModel)]="isChecked"
        >Urgente</mat-slide-toggle
      >
    </div>

    <div class="form-group mt-4" *ngIf="showAnexos">
      <p>Anexos do pedido:</p>

      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSourceAnexos" class="table-striped">
          <!-- <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef> Id. </th>
                        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                    </ng-container> -->

          <ng-container matColumnDef="anexo">
            <th mat-header-cell *matHeaderCellDef>Anexo</th>
            <td mat-cell *matCellDef="let element">{{ element }}</td>
          </ng-container>

          <ng-container matColumnDef="download">
            <th mat-header-cell *matHeaderCellDef>Download</th>
            <td mat-cell *matCellDef="let element">
              <a
                (click)="baixarAnexo(element)"
                target="_blank"
                class="cursorHand text-center ml-1"
              >
                <mat-icon color="link" class="text-primary" matListIcon
                  >get_app</mat-icon
                >
              </a>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsAnexos"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumnsAnexos"
          ></tr>
        </table>

        <mat-paginator
          [pageSizeOptions]="[5, 10, 20]"
          showFirstLastButtons
        ></mat-paginator>
      </div>
    </div>

    <div class="form-group mt-4">
      <p>Itens do pedido:</p>

      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" class="table-striped">
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>Id.</th>
            <td mat-cell *matCellDef="let element">{{ element.id }}</td>
          </ng-container>

          <ng-container matColumnDef="campo">
            <th mat-header-cell *matHeaderCellDef>Campo</th>
            <td mat-cell *matCellDef="let element">{{ element.descricao }}</td>
          </ng-container>

          <ng-container matColumnDef="conteudo">
            <th mat-header-cell *matHeaderCellDef>Conteúdo</th>
            <td mat-cell *matCellDef="let element">{{ element.valor }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <mat-paginator
          [pageSizeOptions]="[5, 10, 20]"
          showFirstLastButtons
        ></mat-paginator>
      </div>
    </div>
  </div>

  <div class="col-sm-4">
    <div class="form-group bg-white-mod">
      <div class="container">
        <div class="row">
          <!-- <div class="col-sm-3 border-right">
                        <p>
                            Responsável:
                        </p>
                    </div> -->
          <div class="col-sm-12">
            <p>
              <strong> Informações do Responsável </strong>
            </p>
            <hr />
            <p>
              Nome: <br />
              <span class="text-primary">
                {{ responsavel.nome }}
              </span>
            </p>
            <p>
              Endereço: <br />
              <span class="text-primary">
                {{ responsavel.endereco }}
              </span>
            </p>
            <p>
              Andar: <br />
              <span class="text-primary">
                {{ responsavel.andar }}
              </span>
            </p>
            <p>
              Telefone: <br />
              <span class="text-primary">
                +55 {{ responsavel.telefone1 }}
              </span>
            </p>
            <p>
              E-mail: <br />
              <span class="text-primary">
                {{ responsavel.email }}
              </span>
            </p>
            <!-- <p>
                            Contato de custo: <br>
                            <span class="text-primary">
                                +55 (83) 98877-6655
                            </span>
                        </p> -->
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-sm-12">
        <button
          data-toggle="modal"
          data-target="#acaoRecepcionar"
          class="btn btn-success btn-block mr-2"
        >
          Repecionar Pedido
        </button>

        <button
          data-toggle="modal"
          data-target="#acaoCancelarPedido"
          class="btn btn-danger btn-block mr-2"
        >
          Cancelar este Pedido
        </button>

        <button
          routerLink="/pedidos-online"
          class="btn btn-dark btn-block mr-2"
        >
          Voltar
        </button>
      </div>

      <!-- Modal recepcionar pedido -->
      <div
        class="modal fade text-center"
        id="acaoRecepcionar"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-body">
              <h3 class="text-muted mt-4">
                Deseja confirmar a recepção deste pedido?
              </h3>
              <div class="mb-4">
                <button
                  type="button"
                  class="btn btn-secondary mr-3"
                  data-dismiss="modal"
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  class="btn btn-success"
                  (click)="recepcionarPedido()"
                  data-dismiss="modal"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal cancelar pedido -->
      <div
        class="modal fade text-center"
        id="acaoCancelarPedido"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-body">
              <h3 class="text-muted mt-4">
                Deseja confirmar o cancelamento deste pedido?
              </h3>
              <div class="row">
                <div class="col-sm-12 mt-4">
                  <div class="form-group">
                    <mat-form-field
                      appearance="fill"
                      class="example-full-width"
                    >
                      <mat-label>Motivo do Cancelamento</mat-label>
                      <textarea
                        matInput
                        [(ngModel)]="motivoCancelamento"
                        (keyup)="checkMotivo()"
                      ></textarea>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="mb-4">
                <button
                  type="button"
                  class="btn btn-secondary mr-3"
                  data-dismiss="modal"
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  class="btn btn-danger"
                  (click)="cancelarPedido()"
                  data-dismiss="modal"
                  [disabled]="diabledCancelarPedido"
                >
                  Confimar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row mt-3">

            <div class="col">
                <div class="btn-group btn-group-toggle" data-toggle="buttons">
                    <label class="btn btn-secondary">
                        <input type="radio" name="aguardando">
                        Voltar
                    </label>
                    <label class="btn btn-success">
                        <input type="radio" name="andamento">
                        Repecionar Pedido
                    </label>
                    <label class="btn btn-danger">
                        <input type="radio" name="concluidos">
                        Cancelar este Pedido
                    </label>
                </div>
            </div>


        </div> -->
  </div>
</div>
