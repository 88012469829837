import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "lastAttendant",
})
export class LastAttendantPipe implements PipeTransform {
  transform(value: any[], args?: any): any {
    if (value) {
      const tamanho = value.length;

      const lastAttendant = value[tamanho - 1];

      if (args == "name") {
        if (tamanho === 0 || lastAttendant.ticketHolder === null) {
          return "Aguardando Atendimento";
        }
        let name =
          lastAttendant.ticketHolder.firstName +
          " " +
          lastAttendant.ticketHolder.lastName;

        return name;
      }

      if (args == "email") {
        if (tamanho === 0 || lastAttendant.ticketHolder === null) {
          return "";
        }
        return lastAttendant.ticketHolder.email;
      }

      if (args == "id") {
        if (tamanho === 0 || lastAttendant.ticketHolder === null) {
          return "";
        }
        return lastAttendant.ticketHolder.id;
      }

      if (args == "area") {
        if (tamanho === 0 || lastAttendant.ticketHolder === null) {
          return "";
        }
        return lastAttendant.ticketHolder.userType;
      }

      if (args == "assignmentDt") {
        if (tamanho === 0 || lastAttendant.ticketHolder === null) {
          return "";
        }
        return lastAttendant.assignmentAt;
      }

      if (args == "isNull") {
        return value.length !== 0 && lastAttendant.ticketHolder === null;
      }
      if (args == "isNotNull") {
        return value.length !== 0 && lastAttendant.ticketHolder !== null;
      }
    }
  }
}
