<div class="pageNotFound">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center text-primary mt-3">
                <h1>404 Page Not Found</h1>
                <figure class="figure-block">
                    <img src="../assets/pageNotFound/pageNotFound.svg" class="img-fluid imgNotFound"
                        alt="404 Page Not Found">
                </figure>
                <p>Você tentou acessar uma página inexistente ou sem permissão! <br> Por favor, selecionar uma das
                    opções abaixo!</p>
                <a mat-flat-button class="btn btn-primary mb-1 mr-2">Voltar para o início <mat-icon color="link" matListIcon>sentiment_very_satisfied</mat-icon> </a>
                <a mat-flat-button class="btn btn-dark mb-1">Reportar susposto erro <mat-icon color="link" matListIcon>sentiment_very_dissatisfied</mat-icon> </a>
            </div>
        </div>
    </div>
</div>