import { data } from "jquery";
import { first, map, startWith, tap } from "rxjs/operators";
import { Cartonagem } from "src/app/_models/cartonagem";
import { AlertService } from "src/app/_services";
import { CartonagemService } from "src/app/_services/cartonagem.service";
import { GrupoEmpresaService } from "src/app/_services/grupo-empresa.service";

import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-cartonagem",
  templateUrl: "./cartonagem.component.html",
  styleUrls: ["./cartonagem.component.css"],
})
export class CartonagemComponent implements OnInit {
  // select
  // porEmpresas: string[] = [
  //   'Empresa X', 'Empresa Y', 'Empresa Z'
  // ];
  porEmpresas2: string[] = [
    "Grupo Demonstração",
    "Telefonica Brasil S.A",
    "Hospital e maternidade Therezina de Jesus",
  ];
  caixas: string[] = [
    "CAIXA 2KG",
    "CAIXA 5KG",
    "CAIXA 10KG",
    "CAIXA 20KG",
    "DEMO",
  ];

  loading = false;
  selectedPorEmpresa: any;
  selectedPorEmpresa2: any;
  selectedCaixa: any;
  selectedDate: any;
  // end select

  currentCartonagem: any = {};
  formEmpresaValue = "";
  pedidos: any[] = [];
  empresas: any[] = [];
  empresaId: number = 0;
  formEmpresa: FormGroup;
  formCartonagem: FormGroup;
  formEditCartonagem: FormGroup;
  cartonagem: Cartonagem = new Cartonagem();
  // descricao: any = "";
  // ativo: any = false;
  // dataCadastro: any ="";
  // successMsg: any = false;
  // failMsg: any = false;
  // failMsg2: any = false;
  // pegarValorEmpresa: any = false;
  // pegarValorDescricao: any = false;
  // pegarValorDataCadastro: any = false;
  // pegarValorOpcao: any = false;
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = [
    "id",
    "descricao",
    "criadoEm",
    "ativo",
    "acoes",
  ];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  length = 100;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  pageable: any;
  token: string;
  // MatPaginator Output
  pageEvent: PageEvent;

  constructor(
    private cartonagemService: CartonagemService,
    private formBuilder: FormBuilder,
    private grupoEmpresaService: GrupoEmpresaService,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.dateAdapter.setLocale("pt-BR");
  }

  inserirCartonagem() {
    this.cartonagem = this.formCartonagem.value;
    this.cartonagem.ativo = this.cartonagem.ativo ? "S" : "N";
    this.loading = true;
    this.cartonagemService.insertCartonagem(this.cartonagem).subscribe(
      (response) => {
        //this.alertService.success('Cartonagem adicionada com sucesso!', { keepAfterRouteChange: true });
        this.getAllpedidos();
        this.router.navigate(["/tabelas/cartonagem"], {
          relativeTo: this.route,
        });
      },
      (error) => {
        //this.alertService.error(error);
        this.loading = false;
      }
    );

    // if(this.descricao=="" || this.dataCadastro =="" || this.cartonagem.idEmpresa == 0){
    //   this.failMsg = true;
    // }
    // else{
    //   this.failMsg = false;
    //   this.cartonagem.descricao = this.descricao;
    //   if(this.ativo){
    //     this.cartonagem.ativo = "S";
    //   }
    //   else{
    //     this.cartonagem.ativo = "N";
    //   }
    //   this.cartonagem.dataCadastro = this.dataCadastro;

    //   this.successMsg = true;
    // }
  }
  editarCartonagem() {
    this.cartonagem = this.formEditCartonagem.value;
    this.cartonagem.ativo = this.cartonagem.ativo ? "S" : "N";
    this.loading = true;
    this.cartonagemService.editCartonagem(this.cartonagem).subscribe(
      (response) => {
        //this.alertService.success('Cartonagem adicionada com sucesso!', { keepAfterRouteChange: true });
        this.getAllpedidos();
        this.router.navigate(["/tabelas/cartonagem"], {
          relativeTo: this.route,
        });
      },
      (error) => {
        //this.alertService.error(error);
        this.loading = false;
      }
    );
    //   if(this.pegarValorDataCadastro == false && this.pegarValorDescricao == false && this.pegarValorEmpresa == false && this.pegarValorOpcao == false){
    //     this.failMsg2 = true;
    //     this.failMsg = false;
    //   }else{
    //     if(this.pegarValorEmpresa==false){
    //       this.cartonagem.idEmpresa = this.currentCartonagem.idEmpresa;
    //     }else{
    //       this.cartonagem.idEmpresa = this.formEmpresa.get("idEmpresa").value;
    //     }
    //     if(this.pegarValorDescricao==false){
    //       this.descricao = this.currentCartonagem.descricao;
    //     }
    //     if(this.pegarValorDataCadastro==false){
    //       this.dataCadastro = this.currentCartonagem.dataCadastro;
    //     }if(this.pegarValorOpcao==false){
    //       this.cartonagem.ativo = this.currentCartonagem.ativo;
    //     }else{
    //       if(this.ativo){
    //         this.cartonagem.ativo = "S";
    //       }else{
    //         this.cartonagem.ativo = "N";
    //       }
    //     }
    //     if(this.descricao == "" || this.dataCadastro == "" || this.cartonagem.idEmpresa == 0){
    //       this.failMsg = true;
    //       this.failMsg2 = false;
    //     }
    //     else{
    //     this.cartonagem.id = this.currentCartonagem.id;
    //     this.cartonagem.descricao = this.descricao;
    //     this.cartonagem.dataCadastro = this.dataCadastro;;
    //     this.failMsg = false;
    //     this.failMsg2 = false;
    //     this.cartonagemService.insertCartonagem(this.cartonagem).subscribe(Data =>{
    //       window.setTimeout(function(){window.location.reload()}, 2000);
    //     })
    //     this.successMsg = true;
    //   }
    // }
  }
  deletarCartonagem() {
    this.cartonagem = this.formEditCartonagem.value;
    this.cartonagemService
      .deleteCartonagem(this.cartonagem)
      .subscribe((data) => {
        this.getAllpedidos();
        this.router.navigate(["/tabelas/cartonagem"], {
          relativeTo: this.route,
        });
      });
  }
  // limparCampos(){
  //   this.failMsg = false;
  //   this.failMsg2 = false;
  //   this.descricao = "";
  //   this.ativo = false;
  //   this.dataCadastro = "";
  // }
  ngOnInit() {
    this.formEmpresa = this.formBuilder.group({
      idEmpresa: [""],
    });

    this.formCartonagem = this.formBuilder.group({
      idEmpresa: ["", Validators.required],
      descricao: ["", Validators.required],
      dataCadastro: ["", Validators.required],
      ativo: ["", Validators.required],
    });
    this.formEditCartonagem = this.formBuilder.group({
      id: [""],
      idEmpresa: ["", Validators.required],
      descricao: ["", Validators.required],
      dataCadastro: ["", Validators.required],
      ativo: ["", Validators.required],
    });

    this.getAllEmpresas();
    this.getAllpedidos();
  }

  selectFilter() {
    this.formEmpresaValue = this.formEmpresa.get("idEmpresa").value;

    this.empresaId = Number(this.formEmpresaValue);
    this.getAllpedidos();
  }

  get f() {
    return this.formEmpresa.controls;
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.onPageController())).subscribe();
  }

  RegisterUser() {}

  onPageController() {
    this.pageSize = this.pageEvent.pageSize;
    this.pageIndex = this.pageEvent.pageIndex;

    this.alertService.clear();

    //this.loading = true;

    this.cartonagemService
      .getAllCartonagem(
        this.empresaId,
        this.pageIndex,
        this.pageSize,
        "id",
        "asc"
      )
      .pipe(first())
      .subscribe(
        (data) => {
          this.dataSource = new MatTableDataSource(data["content"]);

          this.pageable = data["pageable"];
          //this.carregandoTabela = true;

          // this.length = this.pageable.totalRecordCount;
          this.dataSource.sort = this.sort;
        },
        (error) => {
          this.alertService.error(error);
          // this.carregandoTabela = true;
          // this.loading = false;
        }
      );
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  getAllpedidos() {
    this.cartonagemService
      .getAllCartonagem(
        this.empresaId,
        this.pageIndex,
        this.pageSize,
        "id",
        "asc"
      )
      .subscribe((response) => {
        this.dataSource = new MatTableDataSource(response["content"]);
      });
  }

  getAllEmpresas() {
    this.grupoEmpresaService.getAllGrupoEmpresa().subscribe((response) => {
      this.empresas = response;
    });
  }

  setCurrentCartonagem(cartonagem) {
    this.formEditCartonagem.get("id").setValue(cartonagem.id);
    this.formEditCartonagem.get("descricao").setValue(cartonagem.descricao);
    this.formEditCartonagem
      .get("ativo")
      .setValue(cartonagem.ativo == "S" ? true : false);
    this.formEditCartonagem
      .get("dataCadastro")
      .setValue(new Date(cartonagem.dataCadastro));
    this.formEditCartonagem.get("idEmpresa").setValue(cartonagem.idEmpresa);
  }
}
