import { User } from "src/app/_models";
import { AccountService } from "src/app/_services";
import { environment } from "src/environments/environment";

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { SelectionModel } from "@angular/cdk/collections";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

import { WikiService } from "src/app/_services/wiki.service";
import { InsumoList, filterParams } from "../../_models/insumos";
import { CompetenceDatePipe } from "../../_pipes/competence-date.pipe";
import { DeliveryDatePipe } from "../../_pipes/deliveryDate.pipe";

@Component({
  selector: "app-visualizer-minha",
  templateUrl: "./visualizer-minha.component.html",
  styleUrls: ["./visualizer-minha.component.css"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
  providers: [CompetenceDatePipe, DeliveryDatePipe],
})
export class VisualizerMinhaComponent implements OnInit {
  selection = new SelectionModel<InsumoList>(true, []);

  submitted = false;
  fields: any[] = [];
  isChecked = true;
  filesImport = [];

  anexoInsumo: FormGroup;
  formUpdate: FormGroup;
  formDeadLine: FormGroup;
  formGroup: FormGroup;
  formFiltro: FormGroup;
  formChangeVersion: FormGroup;
  formAddNumeroSd: FormGroup;

  actualInsumoId: any;
  insumoVersionList: string[] = [];
  insumoSegmentList: string[] = [];
  tempInsumoId: any;
  start = false;
  user: User;
  userToken: string;
  insumo: InsumoList = {} as InsumoList;

  searchUserCtrl1 = new FormControl();
  searchUserCtrl2 = new FormControl();
  searchUserCtrlFiltro = new FormControl();

  errorMsg: string;
  filteredUsers1: any;
  filteredUsers2: any;
  filteredUsersFilter: any;
  isLoading = false;
  actualInsumoLength: number = 0;
  messageErrorUpload = "";
  files = [];
  attachments: any[] = [];
  anexoDownload: string = environment.apiUrl + "/download/anexo-insumo/";

  formData: MatTableDataSource<InsumoList>;
  dataSourceForDownload: MatTableDataSource<any>;
  dataSource = new MatTableDataSource<InsumoList>();
  dataSourceLength: number = 0;
  isDownloadProcess: boolean = false;
  expandedElement: InsumoList;
  expandedElement2: InsumoList;

  filterParams: filterParams;

  @ViewChild("fileUpload", { static: false }) fileUpload: ElementRef;
  @ViewChild("fileUploadInsumo", { static: false })
  fileUploadInsumo: ElementRef;

  @ViewChild("closeModalAlterarResponsavel")
  closeModalAlterarResponsavel: ElementRef;
  @ViewChild("closeModalAlterarDeadLine")
  closeModalAlterarDeadLine: ElementRef;

  @ViewChild("closeModalExcluir") closeModalExcluir: ElementRef;
  @ViewChild("closeModalEncerrar") closeModalEncerrar: ElementRef;
  @ViewChild("closeConfirmDoc") closeConfirmDoc: ElementRef;
  @ViewChild("encerradosNada") encerradosNada: ElementRef;
  @ViewChild("closeModalVersion") closeModalVersion: ElementRef;
  @ViewChild("exportModal") exportModal: ElementRef;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private accountService: AccountService,
    private wikiService: WikiService
  ) {
    this.user = this.accountService.userValue.user;
    this.userToken = this.accountService.userValue.token;
  }

  ngOnInit(): void {
    this.OnInitializeDataSource();
  }

  OnInitializeDataSource() {
    this.wikiService.getWikis(this.user.id.toString()).subscribe((data) => {
      console.log(data);
      this.dataSource = new MatTableDataSource(data);
      this.dataSourceLength = data.totalElements;
      this.formData = this.dataSource;
    });
  }

  isOperador() {
    if (this.user.userType === "OPERACAO_VIVO") {
      return false;
    } else if (this.user.userType === "OPERADOR_VIVO_JURIDICO") {
      return false;
    } else if (this.user.userType === "OPERADOR_VIVO_AUDITORIA") {
      return false;
    } else {
      return true;
    }
  }

  columnsToDisplay = [
    "id",
    "name",
    "publico",
    "segment",
    "competence",
    "deadline",
    "actions",
  ];

  displayedColumnsInterno: string[] = [
    "idArquivo",
    "nomeArquivo",
    "Justificativa",
    "dataEHoraEnvio",
    "versao",
    "acoes",
  ];
}
