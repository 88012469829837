import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { SelectionModel } from "@angular/cdk/collections";
import { HttpClient, HttpEventType } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { forkJoin, merge, of } from "rxjs";
import {
  catchError,
  debounceTime,
  filter,
  finalize,
  map,
  switchMap,
  tap,
} from "rxjs/operators";
import { User } from "src/app/_models";
import { AccountService } from "src/app/_services";
import { InsumoService } from "src/app/_services/insumos.service";
import { UploadService } from "src/app/_services/upload.service";
import { environment } from "src/environments/environment";
import * as uuid from "uuid";
import {
  filterParams,
  AttachmentModelInsumo,
  Insumo,
  InsumoList,
} from "../../_models/insumos";
import { CompetenceDatePipe } from "../../_pipes/competence-date.pipe";
import { DeliveryDatePipe } from "../../_pipes/deliveryDate.pipe";
import { InsumosFieldsService } from "../../_services/insumosFields.service";
import { TablePeriodicElement } from "../insumo-field-config/insumo-field-config.component";
import * as moment from "moment";
import { isHoliday } from "src/app/_helpers/saoPauloHolidays";

@Component({
  selector: "app-todos-insumos-visualizar",
  templateUrl: "./todos-insumos-visualizar.component.html",
  styleUrls: ["./todos-insumos-visualizar.component.css"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
  providers: [CompetenceDatePipe, DeliveryDatePipe],
})
export class TodosInsumosVisualizarComponent implements OnInit {
  selection = new SelectionModel<InsumoList>(true, []);

  submitted = false;
  fields: any[] = [];
  isChecked = true;
  filesImport = [];

  anexoInsumo: FormGroup;
  formUpdate: FormGroup;
  formDeadLine: FormGroup;
  formDeadLineSon: FormGroup;
  formGroup: FormGroup;
  formFiltro: FormGroup;
  formChangeVersion: FormGroup;
  formAddNumeroSd: FormGroup;
  frequency = new FormControl();
  insumoUpdate: FormGroup;

  actualInsumoId: any;
  insumoVersionList: string[] = [];
  insumoSegmentList: string[] = [];
  tempInsumoId: any;
  start = false;
  user: User;
  userToken: string;
  insumo: InsumoList = {} as InsumoList;
  insumos: Insumo = {} as Insumo;
  insumoCompetenceDate = moment();

  searchUserCtrl1 = new FormControl();
  searchUserCtrl2 = new FormControl();
  searchUserCtrlFiltro = new FormControl();
  segmentoOptions: string[] = [];

  errorMsg: string;
  filteredUsers1: any;
  filteredUsers2: any;
  filteredUsersFilter: any;
  isLoading = false;
  actualInsumoLength: number = 0;
  messageErrorUpload = "";
  files = [];
  attachments: any[] = [];
  anexoDownload: string = environment.apiUrl + "/download/anexo-insumo/";
  anexoModelFileDownload: string =
    environment.apiUrl + "/download/anexo-model-file-insumo/";

  formData: MatTableDataSource<InsumoList>;
  dataSourceForDownload: MatTableDataSource<any>;
  dataSource = new MatTableDataSource<InsumoList>();
  dataSourceLength: number = 0;
  isDownloadProcess: boolean = false;
  expandedElement: InsumoList;
  expandedElement2: InsumoList;

  frequencyOptions: string[] = [];
  filterParams: filterParams;
  isInsumoSon: boolean = false;

  simulationsDate: string[] = [];
  dataSourceSimulation: MatTableDataSource<SimulationsDeadLine>;
  insumoName: string[] = [];
  insumoCompetence: string[] = [];

  @ViewChild("fileUpload", { static: false }) fileUpload: ElementRef;
  @ViewChild("fileUploadInsumo", { static: false })
  fileUploadInsumo: ElementRef;

  @ViewChild("closeModalAlterarResponsavel")
  closeModalAlterarResponsavel: ElementRef;
  @ViewChild("closeModalAlterarDeadLine")
  closeModalAlterarDeadLine: ElementRef;
  @ViewChild("closeModalAlterarDeadLineSon")
  closeModalAlterarDeadLineSon: ElementRef;

  @ViewChild("closeModalHasInsumos") closeModalHasInsumos: ElementRef;
  @ViewChild("closeFrequencyModal") closeFrequencyModal: ElementRef;
  @ViewChild("openModal") openModal: ElementRef;
  @ViewChild("closeModalExcluir") closeModalExcluir: ElementRef;
  @ViewChild("confirmUploadModal") confirmUploadModal: ElementRef;
  @ViewChild("closeModalEncerrar") closeModalEncerrar: ElementRef;
  @ViewChild("closeConfirmDoc") closeConfirmDoc: ElementRef;
  @ViewChild("encerradosNada") encerradosNada: ElementRef;
  @ViewChild("closeModalVersion") closeModalVersion: ElementRef;
  @ViewChild("exportModal") exportModal: ElementRef;
  @ViewChild("closeModalUpdateInsumo") closeModalUpdateInsumo: ElementRef;
  @ViewChild("errorModalDate") errorModalDate: ElementRef;

  @ViewChild("anexoUpload", { static: false }) anexoUpload: ElementRef;
  @ViewChild("closeModalAnexoModel") closeModalAnexoModel: ElementRef;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  areaOptions: any[] = [];
  publicoOptions: any[] = [];
  tipoDeInsumoOptions: any[] = [];
  isLoadingResults: boolean;

  holydays: string[] = [];
  arquivosModelo = [];
  anexos: any[] = [];
  isLoadingAnexo: boolean = false;
  uploadLoading: boolean = false;
  dataSourceModelFile = new MatTableDataSource<AttachmentModelInsumo>();
  simulation: boolean;
  isForThisCompetence: any;

  constructor(
    private insumosService: InsumoService,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private http: HttpClient,
    private uploadService: UploadService,
    private insumoFieldService: InsumosFieldsService
  ) {
    this.user = this.accountService.userValue.user;
    this.userToken = this.accountService.userValue.token;
  }

  ngOnInit(): void {
    this.formChangeVersion = this.formBuilder.group({
      version: ["", Validators.required],
      justificativa: ["", Validators.required],
    });

    this.frequency.setValidators([Validators.required]);
    this.getInsumoFields();
    this.getHolidays();

    this.formAddNumeroSd = this.formBuilder.group({
      numeroSd: ["", Validators.required],
    });

    this.anexoInsumo = this.formBuilder.group({
      justify: ["", [Validators.minLength(3), Validators.maxLength(300)]],
    });

    this.formUpdate = this.formBuilder.group({
      responsibleUpload: ["", [Validators.required]],
      analysisUpload: ["", [Validators.required]],
      justify: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(300),
        ],
      ],
    });

    this.formDeadLine = this.formBuilder.group({
      deadline: [moment(), Validators.required],
      utilDay: [false],
      dayOnMonth: [true],
      lastDayUtil: [false],
      limitDate: [""],
      day: [""],
      month: [""],
      year: [""],
      justify: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(300),
        ],
      ],
    });

    this.formDeadLineSon = this.formBuilder.group({
      deadline: [moment(), Validators.required],
      utilDay: [false],
      dayOnMonth: [true],
      lastDayUtil: [false],
      limitDate: [""],
      day: [""],
      month: [""],
      year: [""],
      justify: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(300),
        ],
      ],
    });

    this.insumoUpdate = this.formBuilder.group({
      name: [],
      segment: [],
      publico: [],
      area: [],
      frequency: [],
      competence: [],
      deadline: [],
      observations: [],
      insumoType: [],
      docTypeCheckboxGroup: new FormGroup({
        docTypeCheck1: new FormControl(""),
        docTypeCheck2: new FormControl(""),
        docTypeCheck3: new FormControl(""),
        docTypeCheck4: new FormControl(""),
        docTypeCheck5: new FormControl(""),
      }),
      validators: [],
      insumoAmount: [],
    });

    this.formFiltro = this.formBuilder.group({
      email: [""],
      segment: [""],
      insumoName: [""],
      status: [""],
      competenceStatus: ["VIGENTE"],
      competence: [""],
      insumoId: [""],
    });

    this.searchUserCtrl1.valueChanges
      .pipe(
        filter((value) => value?.length > 3),
        debounceTime(500),
        tap(() => {
          this.errorMsg = "";
          this.filteredUsers1 = [];
          this.isLoading = true;
        }),
        switchMap((value) =>
          this.http
            .get(`${environment.apiUrl}/api/user/search?email=${value}`)
            .pipe(
              finalize(() => {
                this.isLoading = false;
              })
            )
        )
      )
      .subscribe((data) => {
        if (data == undefined) {
          this.errorMsg = data["Error"];
          this.filteredUsers1 = [];
        } else {
          this.errorMsg = "";
          this.filteredUsers1 = data;
        }
      });

    this.searchUserCtrl2.valueChanges
      .pipe(
        filter((value) => value?.length > 3),
        debounceTime(500),
        tap(() => {
          this.errorMsg = "";
          this.filteredUsers2 = [];
          this.isLoading = true;
        }),
        switchMap((value) =>
          this.http
            .get(`${environment.apiUrl}/api/user/search?email=${value}`)
            .pipe(
              finalize(() => {
                this.isLoading = false;
              })
            )
        )
      )
      .subscribe((data) => {
        if (data == undefined) {
          this.errorMsg = data["Error"];
          this.filteredUsers2 = [];
        } else {
          this.errorMsg = "";
          this.filteredUsers2 = data;
        }
      });

    this.searchUserCtrlFiltro.valueChanges
      .pipe(
        filter((value) => value?.length > 3),
        debounceTime(500),
        tap(() => {
          this.errorMsg = "";
          this.filteredUsersFilter = [];
          this.isLoading = true;
        }),
        switchMap((value) =>
          this.http
            .get(`${environment.apiUrl}/api/user/search?email=${value}`)
            .pipe(
              finalize(() => {
                this.isLoading = false;
              })
            )
        )
      )
      .subscribe((data) => {
        if (data == undefined) {
          this.errorMsg = data["Error"];
          this.filteredUsersFilter = [];
        } else {
          this.errorMsg = "";
          this.filteredUsersFilter = data;
        }
      });

    this.getInsumosFieldsSegmento();
    // this.getDeadLineActual(this.actualInsumoId);
    this.resetFilterParams();
    this.OnInitializeDataSource();
  }

  ngAfterViewInit() {
    this.paginator.pageIndex = 0;
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.filter()))
      .subscribe();
  }

  filter() {
    this.validationFilterRequisition();
    this.insumosService
      .getAllInsumoHasParentPageable(
        this.paginator.pageIndex,
        this.paginator.pageSize,
        this.sort.active,
        this.sort.direction,
        this.filterParams.name,
        this.filterParams.userId,
        this.filterParams.email,
        this.filterParams.segment,
        this.filterParams.status,
        this.filterParams.competence,
        this.filterParams.competenceStatus,
        this.filterParams.id
      )
      .subscribe((data) => {
        this.dataSource = new MatTableDataSource(data["content"]);
        this.dataSourceLength = data.totalElements;
        this.formData = this.dataSource;
      });
  }

  onSubmitConfig(): void {
    this.submitted = true;
    const tempAlterarInsumo: any = {
      insumoId: this.actualInsumoId,
      userId: this.user.id,
      name: this.insumoUpdate.get("name").value,
      segment: this.insumoUpdate.get("segment").value,
      publico: this.insumoUpdate.get("publico").value,
      competence: this.insumoUpdate.get("competence").value,
      area: this.insumoUpdate.get("area").value,
      insumoType: this.insumoUpdate.get("insumoType").value,
      observations: this.insumoUpdate.get("observations").value,
      acceptedDocs: this.acceptDocs(),
      insumoAmount: this.insumoUpdate.get("insumoAmount").value,
    };

    this.insumosService.updateInsumo(tempAlterarInsumo).subscribe(() => {
      this.submitted = false;
      this.actualInsumoId = null;
      this.insumoUpdate.reset();
      this.closeModalUpdateInsumo.nativeElement.click();
      this.getInsumosById(this.insumos.id);
    });
  }

  onSubmitAnexoForm(): void {
    if (this.insumos.competenceStatus === "ENCERRADO") {
      return;
    }
    this.submitted = true;
    let updateModelFileAnexosDto: any = {};
    this.uploadLoading = true;

    updateModelFileAnexosDto = {
      userId: this.user.id,
      anexoModelFileInsumoList: this.anexos.map((att) => ({
        ...att,
        userUpload: null,
      })),
      insumoId: this.actualInsumoId,
    };

    this.insumosService
      .updateAnexosModelFile(updateModelFileAnexosDto)
      .subscribe(() => {
        this.arquivosModelo = [];
        this.anexos = [];
        this.closeModalAnexoModel.nativeElement.click();
        this.uploadLoading = false;
        this.submitted = false;
        this.getInsumoById(this.actualInsumoId);
        this.getAttachmentModels(this.actualInsumoId);
      });
  }

  acceptDocs() {
    let docTypeString = "";

    if (this.insumoUpdate.get("docTypeCheckboxGroup.docTypeCheck5").value) {
      return "todos";
    }

    if (this.insumoUpdate.get("docTypeCheckboxGroup.docTypeCheck1").value) {
      docTypeString += ".xls,";
    }

    if (this.insumoUpdate.get("docTypeCheckboxGroup.docTypeCheck2").value) {
      docTypeString += ".xlsx,";
    }

    if (this.insumoUpdate.get("docTypeCheckboxGroup.docTypeCheck3").value) {
      docTypeString += ".csv,";
    }

    if (this.insumoUpdate.get("docTypeCheckboxGroup.docTypeCheck4").value) {
      docTypeString += ".pdf";
    }

    return docTypeString;
  }

  getHolidays() {
    this.http
      .get<any[]>(
        `https://date.nager.at/api/v3/PublicHolidays/${new Date().getFullYear()}/BR`
      )
      .subscribe((data) => {
        data.forEach((element) => {
          this.holydays.push(element.date);
        });
      });
  }

  populateInsumoUpdateModal() {
    this.insumosService.getInsumoById(this.actualInsumoId).subscribe((data) => {
      this.insumos = data;

      this.insumoUpdate.get("name").setValue(this.insumos.name);
      this.insumoUpdate.get("segment").setValue(this.insumos.segment.trim());
      this.insumoUpdate.get("publico").setValue(this.insumos.publico.trim());
      this.insumoUpdate.get("area").setValue(this.insumos.area);
      this.insumoUpdate.get("frequency").setValue(this.insumos.frequency);
      this.insumoUpdate.get("observations").setValue(this.insumos.observations);
      this.insumoUpdate.get("insumoType").setValue(this.insumos.type);
      this.insumoUpdate.get("insumoAmount").setValue(this.insumos.insumoAmount);
      this.insumoUpdate
        .get("competence")
        .setValue(this.insumoCompetenceDate.toISOString());

      this.insumos.acceptedDocs.split(",").forEach((doc) => {
        const checkboxGroup = this.insumoUpdate.get("docTypeCheckboxGroup");
        if (doc === "todos") {
          [
            "docTypeCheck1",
            "docTypeCheck2",
            "docTypeCheck3",
            "docTypeCheck4",
            "docTypeCheck5",
          ].forEach((check) => {
            checkboxGroup.get(check).setValue(true);
          });
        } else {
          if (doc === ".xls") checkboxGroup.get("docTypeCheck1").setValue(true);
          if (doc === ".xlsx")
            checkboxGroup.get("docTypeCheck2").setValue(true);
          if (doc === ".csv") checkboxGroup.get("docTypeCheck3").setValue(true);
          if (doc === ".pdf") checkboxGroup.get("docTypeCheck4").setValue(true);
        }
      });
    });
  }

  getInsumosFieldsPeriodicidade() {
    this.insumoFieldService
      .getByType("PERIODICIDADE")
      .subscribe((data: any[]) => {
        const localeFrequencyOptions: string[] = [];

        data.forEach((freq) =>
          localeFrequencyOptions.push(freq.fieldDescription)
        );

        this.frequencyOptions = [
          ...new Set([...localeFrequencyOptions, ...this.frequencyOptions]),
        ];
      });
  }

  setCompetenceFormValue($event: EventEmitterValue) {
    this.insumoUpdate.get("competence").setValue($event.competence);
  }

  onChangeInsumoDeadline() {
    if (
      this.user.userType === "OPERACAO_BPO" &&
      this.user.profile === "ROLE_ADMIN" &&
      this.insumos.competenceStatus === "VIGENTE"
    ) {
      return false;
    }

    return true;
  }

  onChangeInsumoSon() {
    this.isInsumoSon = !this.isInsumoSon;
    this.isForThisCompetence = !this.isForThisCompetence;
  }

  updateFrequency() {
    const data = {
      frequency: this.frequency.value,
      ticketId: this.insumos.id,
      userId: this.user.id,
    };

    this.insumosService.updateFrequency(data).subscribe((data) => {
      this.frequency.reset();
      this.closeFrequencyModal.nativeElement.click();
      this.getInsumosById(this.insumos.id);
    });
  }

  OnInitializeDataSource() {
    this.insumosService
      .getAllInsumoHasParentPageable(
        0,
        5,
        "id",
        "desc",
        this.filterParams.name,
        this.filterParams.userId,
        this.filterParams.email,
        this.filterParams.segment,
        this.filterParams.status,
        this.filterParams.competence,
        this.filterParams.competenceStatus,
        this.filterParams.id
      )
      .subscribe((data) => {
        this.dataSource = new MatTableDataSource(data["content"]);
        this.dataSourceLength = data.totalElements;
        this.formData = this.dataSource;
      });
  }

  resetFilterParams() {
    this.filterParams = {
      id: 0,
      name: "",
      email: "",
      segment: "",
      status: "",
      competence: "",
      competenceStatus: "VIGENTE",
      userId: this.user.id,
    };
  }

  onReset() {
    const statusValue = this.formFiltro.get("competenceStatus")?.value;
    this.formFiltro.reset();
    this.formFiltro.get("competenceStatus")?.setValue(statusValue);
    this.searchUserCtrlFiltro.reset();
    this.resetFilterParams();
    this.OnInitializeDataSource();
  }

  validationFilterRequisition() {
    const filtro = this.formFiltro.value;

    if (filtro.insumoName === "" || filtro.insumoName === null) {
      filtro.insumoName = "";
    }

    if (filtro.userId === "" || filtro.userId === null) {
      filtro.userId = this.user.id;
    }

    if (filtro.email === "" || filtro.email === null) {
      filtro.email = "";
    }

    if (filtro.segment === "" || filtro.segment === null) {
      filtro.segment = "";
    }

    if (filtro.status === "" || filtro.status === null) {
      filtro.status = "";
    }
    if (filtro.competenceStatus === "" || filtro.competenceStatus === null) {
      filtro.competenceStatus = "VIGENTE";
    }

    if (filtro.competence === "" || filtro.competence === null) {
      filtro.competence = "";
    }

    if (filtro.insumoId === "" || filtro.insumoId === null) {
      filtro.insumoId = "0";
    }

    this.filterParams = {
      email: filtro.email,
      segment: filtro.segment,
      name: filtro.insumoName,
      status: filtro.status,
      competence: filtro.competence,
      competenceStatus: filtro.competenceStatus,
      id: filtro.insumoId,
      userId: this.user.id,
    };
  }

  getInsumosFieldsSegmento() {
    this.insumoFieldService.getByType("SEGMENTO").subscribe((data) => {
      this.insumoSegmentList = data
        .map((item: TablePeriodicElement) => item.fieldDescription)
        .filter((value) => value !== "Todos");
    });
  }

  getInsumoFields() {
    forkJoin([
      this.getInsumosFieldsArea(),
      this.getInsumoFieldsSegmento(),
      this.getInsumosFieldsCadastroPublico(),
      this.getInsumosFieldsTipoDeInsumo(),
    ]).subscribe((resultados) => {
      this.areaOptions = resultados[0];
      this.segmentoOptions = resultados[1];
      this.publicoOptions = resultados[2];
      this.tipoDeInsumoOptions = resultados[3];
    });
  }

  getInsumosFieldsArea() {
    return this.insumoFieldService.getByType("AREA").subscribe((data) => {
      this.areaOptions = data;
    });
  }

  getInsumoFieldsSegmento() {
    return this.insumoFieldService.getByType("SEGMENTO").subscribe((data) => {
      this.segmentoOptions = data;
    });
  }

  getInsumosFieldsCadastroPublico() {
    return this.insumoFieldService
      .getByType("CADASTRO_PUBLICO")
      .subscribe((data) => {
        this.publicoOptions = data;
      });
  }

  getInsumosFieldsTipoDeInsumo() {
    return this.insumoFieldService
      .getByType("TIPO_DE_INSUMO")
      .subscribe((data) => {
        this.tipoDeInsumoOptions = data;
      });
  }

  populateInsumo(data: InsumoList) {
    this.insumo = data;
  }

  async getInsumosForDownload() {
    const filtro = this.formFiltro.value;
    this.validationFilterRequisition();

    await this.insumosService
      .getAllInsumoHasParentPageable(
        0,
        -1,
        this.sort.active,
        this.sort.direction,
        filtro.insumoName,
        this.user.id,
        filtro.email,
        filtro.segment,
        filtro.status,
        filtro.competence,
        filtro.competenceStatus,
        filtro.insumoId
      )
      .toPromise()
      .then((data) => {
        this.dataSourceForDownload = new MatTableDataSource(data["content"]);
      });
  }

  uploadAnexoInsumo(insumo: any) {
    this.insumo = insumo;
    this.actualInsumoLength = insumo.attachments.length;
  }

  selectInsumoVersion(insumo: any) {
    this.insumo = insumo;
    this.insumo["insumoAnexos"].forEach((element) => {
      this.insumoVersionList.push("v" + element.version);
    });

    return;
  }

  uploadFileForInsumoCsv(file) {
    const formData = new FormData();
    formData.append("file", file.data);
    file.inProgress = true;
    this.uploadService
      .uploadAnexoInsumoCsv(formData)
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round((event.loaded * 100) / event.total);
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        catchError(() => {
          file.inProgress = false;
          return of(`${file.data.name} upload failed.`);
        })
      )
      .subscribe((event: any) => {
        console.log(event);
      });
  }

  private uploadFilesCsv() {
    this.fileUploadInsumo.nativeElement.value = "";
    this.filesImport.forEach((file) => {
      this.uploadFileForInsumoCsv(file);
    });
  }

  insumoAtraso(element) {
    const date = new Date().getTime();
    const deadline = new Date(element.deadline).getTime();

    if (date > deadline) {
      return true;
    }

    return false;
  }

  uploadImport() {
    this.filesImport = [];
    const fileUpload = this.fileUploadInsumo.nativeElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.filesImport.push({ data: file, inProgress: false, progress: 0 });
      }
      this.uploadFilesCsv();
    };
  }

  clearModal() {
    this.fileUpload.nativeElement.value = "";
    this.files = [];
    this.messageErrorUpload = "";
  }

  editPermission(responsibleUser?: string) {
    if (
      (responsibleUser === this.user.email &&
        this.user.userType !== "OPERACAO_VIVO") ||
      this.user.userType == "EQUIPE_INTERNA" ||
      this.user.userType == "OPERACAO_BPO"
    ) {
      return false;
    }

    return true;
  }

  isOperador() {
    if (this.user.userType === "OPERACAO_VIVO") {
      return false;
    } else if (this.user.userType === "OPERADOR_VIVO_JURIDICO") {
      return false;
    } else if (this.user.userType === "OPERADOR_VIVO_AUDITORIA") {
      return false;
    } else {
      return true;
    }
  }

  onSubmit(): void {
    let updateAnexosInsumoDto: any = {};

    if (this.actualInsumoLength > 0) {
      if (this.anexoInsumo.invalid) {
        return;
      }

      updateAnexosInsumoDto = {
        userId: this.user.id,
        anexos: this.attachments,
        justificativa: this.anexoInsumo.get("justify").value,
        insumoId: this.insumo.id,
      };
      this.insumosService
        .updateAnexos(updateAnexosInsumoDto)
        .subscribe(() => this.OnInitializeDataSource());

      this.anexoInsumo.reset();
    } else {
      updateAnexosInsumoDto = {
        userId: this.user.id,
        anexos: this.attachments,
        justificativa: "",
        insumoId: this.insumo.id,
      };

      this.insumosService
        .updateAnexos(updateAnexosInsumoDto)
        .subscribe(() => this.OnInitializeDataSource());
    }
    this.attachments = [];
    this.files = [];
    this.closeConfirmDoc.nativeElement.click();
  }

  get formUp(): { [key: string]: AbstractControl } {
    return this.formUpdate.controls;
  }

  desativarInsumo(insumoId: number) {
    const insumoDto = {
      insumoId: +insumoId,
      userId: this.user.id,
    };
    this.insumosService.inativarInsumo(insumoDto).subscribe(() => {
      this.OnInitializeDataSource();
    });
  }

  validatorCheck(insumo) {
    const hasValidator = insumo.validators.some(
      (validator) => validator.email === this.user.email
    );
    return hasValidator;
  }

  getInsumoById(id) {
    return this.insumosService.getInsumoById(id).subscribe((data) => {
      this.insumo = data as unknown as InsumoList;
      this.actualInsumoLength = data.attachments.length;
    });
  }

  getAttachmentModels(insumoId) {
    this.insumosService.getInsumoById(insumoId).subscribe((data) => {
      this.dataSourceModelFile = new MatTableDataSource<AttachmentModelInsumo>(
        data["attachmentsModel"] || []
      );
    });
  }

  getInsumosById(id) {
    this.isLoadingResults = true;
    this.insumosService.getInsumoById(id).subscribe((data: Insumo) => {
      this.insumos = data;
      this.actualInsumoLength = data.attachments.length;
      this.isLoadingResults = false;
      this.setDeadlineForInsumoChange();
      this.setDeadlineForInsumoSonChange();
      this.getInsumosFieldsPeriodicidade();
      this.setDefaultInsumoCompetenceDate();
      this.getDeadLineActual(id)
    });
  }

  getInsumoHistory(id) {
    this.insumosService.getInsumoById(id).subscribe((data) => {
      this.insumos = data;
    });
  }

  setDefaultInsumoCompetenceDate() {
    const month = Number(this.insumos.competence.split("/")[0]);
    const year = Number(this.insumos.competence.split("/")[1]);

    this.insumoCompetenceDate.date(1);
    this.insumoCompetenceDate.month(month - 1);
    this.insumoCompetenceDate.year(year);
    this.insumoCompetenceDate.hour(0);
    this.insumoCompetenceDate.minute(0);
    this.insumoCompetenceDate.second(0);
    this.insumoCompetenceDate.millisecond(0);
  }

  setDeadlineForInsumoChange() {
    const persistedDeadLine = moment();

    const insumoDeadlineSplit = this.insumos.deadline.split("/");

    persistedDeadLine.date(Number(insumoDeadlineSplit[0]));
    persistedDeadLine.month(Number(insumoDeadlineSplit[1]) - 1);
    persistedDeadLine.year(Number(insumoDeadlineSplit[2]));

    this.formDeadLine.get("deadline").setValue(persistedDeadLine.toDate());
  }

  setDeadlineForInsumoSonChange() {
    const persistedDeadLine = moment();

    const insumoDeadlineSplit = this.insumos.actualDeadline.split("/");

    persistedDeadLine.date(Number(insumoDeadlineSplit[0]));
    persistedDeadLine.month(Number(insumoDeadlineSplit[1]) - 1);
    persistedDeadLine.year(Number(insumoDeadlineSplit[2]));

    this.formDeadLine.get("deadline").setValue(persistedDeadLine.toDate());
  }

  changeHasInsumo(id) {
    this.insumosService
      .updateHasInsumo(id)
      .subscribe((res) => console.log(res));
  }

  excluirInsumo() {
    this.insumosService.deleteInsumo(this.tempInsumoId).subscribe((data) => {
      this.OnInitializeDataSource();
      this.tempInsumoId = null;
      this.closeModalExcluir.nativeElement.click();
    });
  }

  addNumeroSd() {
    const formAddNumeroSd = this.formAddNumeroSd.value;

    const insumoAddNumeroSdDto = {
      insumoId: this.actualInsumoId,
      userId: this.user.id,
      numeroSd: formAddNumeroSd.numeroSd,
    };

    this.insumosService
      .insumoAddNumeroSd(insumoAddNumeroSdDto)
      .subscribe((data) => {
        this.tempInsumoId = null;
        location.reload();
      });
  }

  encerrarInsumo() {
    const closeInsumoDto = {
      insumoId: this.tempInsumoId,
      userId: this.user.id,
    };
    this.insumosService.closeInsumo(closeInsumoDto).subscribe(() => {
      this.OnInitializeDataSource();
      this.tempInsumoId = null;
      this.closeModalExcluir.nativeElement.click();
    });
  }

  encerrarInsumos() {
    const userType = this.user.userType;

    if (this.selection.selected.length <= 0) {
      this.closeModalEncerrar.nativeElement.click();
      this.encerradosNada.nativeElement.click();
      return;
    }

    this.selection.selected.forEach((element, index) => {
      if (userType == "EQUIPE_INTERNA" || userType == "OPERACAO_BPO") {
        const closeInsumoDto = {
          insumoId: element.id,
          userId: this.user.id,
        };

        this.insumosService.closeInsumo(closeInsumoDto).subscribe(() => {
          if (index === this.selection.selected.length - 1) {
            this.OnInitializeDataSource();
            this.selection.clear();
            this.closeModalEncerrar.nativeElement.click();
          }
        });
      }
    });
  }

  onSubmitUpdate(): void {
    this.submitted = true;

    if (this.formUpdate.invalid) {
      return;
    }

    let tempAlterarResponsavel: any = {
      insumosId: this.actualInsumoId,
      responsibleUser: this.user.email,
      responsibleUpload: this.formUpdate.get("responsibleUpload").value,
      analysisUpload: this.formUpdate.get("analysisUpload").value,
      justify: this.formUpdate.get("justify").value,
    };

    this.submitted = false;
    this.updatedInsumoResponsavel(tempAlterarResponsavel);
  }

  showModalDeadlineError() {
    const modalElement = this.errorModalDate.nativeElement;
    ($(modalElement) as any).modal("show");
  }

  onSubmitDeadLine(): void {
    this.submitted = true;

    if (
      this.formDeadLineSon.get("day").value &&
      this.formDeadLineSon.get("month").value &&
      this.formDeadLineSon.get("year").value
    ) {
      const dia = this.formDeadLineSon.get("day").value;
      const mes = this.formDeadLineSon.get("month").value;
      const ano = this.formDeadLineSon.get("year").value;

      const formattedDate = this.formatDateDeadLine(dia, mes, ano);
      const dateObject = moment(formattedDate, "DD/MM/YYYY").toDate();
      this.formDeadLineSon.get("deadline").setValue(dateObject);
    }

    if (!this.validationDeadLine()) {
      this.showModalDeadlineError();
      return;
    }

    if (this.formDeadLine.invalid) {
      return;
    }

    const deadlineObj = this.validadeDatesForUtilDays();

    const tempAlterarDeadLine: any = {
      insumosId: this.actualInsumoId,
      responsibleUser: this.user.email,
      deadline: deadlineObj.deadline,
      utilDays: deadlineObj.utilDays,
      justify: this.formDeadLine.get("justify").value,
    };

    this.insumosService.updateDeadLine(tempAlterarDeadLine).subscribe(() => {
      this.closeModalAlterarDeadLine.nativeElement.click();
      this.simulation = false;
      this.formDeadLine.reset();
      this.submitted = false;
      this.actualInsumoId = null;
      window.location.reload();
    });
  }

  onSubmitDeadLineSon(): void {
    this.submitted = true;

    if (
      this.formDeadLineSon.get("day").value &&
      this.formDeadLineSon.get("month").value &&
      this.formDeadLineSon.get("year").value
    ) {
      const dia = this.formDeadLineSon.get("day").value;
      const mes = this.formDeadLineSon.get("month").value;
      const ano = this.formDeadLineSon.get("year").value;

      const formattedDate = this.formatDateDeadLine(dia, mes, ano);
      const dateObject = moment(formattedDate, "DD/MM/YYYY").toDate();
      this.formDeadLineSon.get("deadline").setValue(dateObject);
    }

    if (!this.validationDeadLineSon()) {
      this.showModalDeadlineError();
      return;
    }

    if (this.formDeadLineSon.invalid) {
      return;
    }

    const deadlineObj = this.validadeDatesForUtilDaysForSon();

    const tempAlterarDeadLine: any = {
      insumosId: this.actualInsumoId,
      responsibleUser: this.user.email,
      deadline: deadlineObj.deadline,
      utilDays: deadlineObj.utilDays,
      justify: this.formDeadLineSon.get("justify").value,
    };

    this.insumosService
      .updateDeadLineForActual(tempAlterarDeadLine)
      .subscribe(() => {
        this.closeModalAlterarDeadLine.nativeElement.click();
        this.simulation = false;
        this.formDeadLineSon.reset();
        this.submitted = false;
        this.actualInsumoId = null;
        window.location.reload();
      });
  }

  formatDateDeadLine(day: string, month: string, year: string): string {
    const monthMap: Record<string, string> = {
      Janeiro: "01",
      Fevereiro: "02",
      Março: "03",
      Abril: "04",
      Maio: "05",
      Junho: "06",
      Julho: "07",
      Agosto: "08",
      Setembro: "09",
      Outubro: "10",
      Novembro: "11",
      Dezembro: "12",
    };

    const formattedDay = String(day).padStart(2, "0");
    const formattedMonth = monthMap[month];

    return `${formattedDay}/${formattedMonth}/${year}`;
  }

  openSimulation() {
    this.simulation = true;

    let deadlineDto = {
      day: "",
      competence: "",
    };

    if (this.formDeadLine.get("dayOnMonth").value) {
      const dia = this.formDeadLine.get("day").value;
      const mes = this.formDeadLine.get("month").value;
      const ano = this.formDeadLine.get("year").value;

      const formattedDate = this.formatDateDeadLine(dia, mes, ano);
      const date = moment(formattedDate, "DD/MM/YYYY").toDate();

      const comp =
        "0" + (date.getMonth() + 1).toString() + "/" + date.getFullYear();

      deadlineDto.day = "d" + dia;
      deadlineDto.competence = comp;
    } else if (this.formDeadLine.get("utilDay").value) {
      const date = moment(this.formDeadLine.get("deadline").value).toDate();
      const comp =
        "0" + (date.getMonth() + 1).toString() + "/" + date.getFullYear();

      deadlineDto.day = this.formDeadLine.get("limitDate").value;
      deadlineDto.competence = comp;
    } else if (this.formDeadLine.get("lastDayUtil").value) {
      const lastUtilDay = this.getLastUtilDay(
        moment(this.formDeadLine.get("deadline").value).toDate()
      );
      const date = moment(this.formDeadLine.get("deadline").value).toDate();
      const comp =
        "0" + (date.getMonth() + 1).toString() + "/" + date.getFullYear();

      deadlineDto.day = "-1";
      deadlineDto.competence = comp;
    }

    if (this.simulationsDate.length >= 3) {
      this.simulationsDate = [];
      this.insumoName = [];
      this.insumoCompetence = [];
    }

    this.insumosService
      .simulationDeadLine(this.actualInsumoId, deadlineDto)
      .subscribe((data) => {
        for (let i = 0; i < data.length; i++) {
          this.simulationsDate.push(
            moment(data[i].deadline).format("DD/MM/YYYY")
          );
          this.insumoName.push(data[i].nome);
          this.insumoCompetence.push(data[i].competence);
        }

        this.dataSourceSimulation = new MatTableDataSource<SimulationsDeadLine>(
          this.simulationsDate.map((date, i) => ({
            deadline: date.toString(),
            nome: this.insumoName[i] + " #" + (i + 1),
            competence: this.insumoCompetence[i],
          }))
        );

        if (this.isForThisCompetence) {
          this.dataSourceSimulation.data.splice(1, 2);
        }
      });
  }

  openSimulationSon() {
    this.simulation = true;

    let deadlineDto = {
      day: "",
      competence: "",
    };

    if (this.formDeadLineSon.get("dayOnMonth").value) {
      const dia = this.formDeadLineSon.get("day").value;
      const mes = this.formDeadLineSon.get("month").value;
      const ano = this.formDeadLineSon.get("year").value;

      const formattedDate = this.formatDateDeadLine(dia, mes, ano);
      const date = moment(formattedDate, "DD/MM/YYYY").toDate();

      const comp =
        "0" + (date.getMonth() + 1).toString() + "/" + date.getFullYear();

      deadlineDto.day = "d" + dia;
      deadlineDto.competence = comp;
    } else if (this.formDeadLineSon.get("utilDay").value) {
      const date = moment(this.formDeadLineSon.get("deadline").value).toDate();
      const comp =
        "0" + (date.getMonth() + 1).toString() + "/" + date.getFullYear();

      deadlineDto.day = this.formDeadLineSon.get("limitDate").value;
      deadlineDto.competence = comp;
    } else if (this.formDeadLineSon.get("lastDayUtil").value) {
      const lastUtilDay = this.getLastUtilDay(
        moment(this.formDeadLineSon.get("deadline").value).toDate()
      );
      const date = moment(this.formDeadLineSon.get("deadline").value).toDate();
      const comp =
        "0" + (date.getMonth() + 1).toString() + "/" + date.getFullYear();

      deadlineDto.day = "-1";
      deadlineDto.competence = comp;
    }

    if (this.simulationsDate.length >= 3) {
      this.simulationsDate = [];
      this.insumoName = [];
      this.insumoCompetence = [];
    }

    this.insumosService
      .simulationDeadLine(this.actualInsumoId, deadlineDto)
      .subscribe((data) => {
        for (let i = 0; i < data.length; i++) {
          this.simulationsDate.push(
            moment(data[i].deadline).format("DD/MM/YYYY")
          );
          this.insumoName.push(data[i].nome);
          this.insumoCompetence.push(data[i].competence);
        }

        this.dataSourceSimulation = new MatTableDataSource<SimulationsDeadLine>(
          this.simulationsDate.map((date, i) => ({
            deadline: date.toString(),
            nome: this.insumoName[i] + " #" + (i + 1),
            competence: this.insumoCompetence[i],
          }))
        );

        if (this.isForThisCompetence) {
          this.dataSourceSimulation.data.splice(1, 2);
        }
      });
  }

  validadeDatesForUtilDays() {
    let returnDate = {
      deadline: "",
      utilDays: "",
    };

    const date = {
      deadline: moment(this.formDeadLine.get("deadline").value),
      utilDay: this.formDeadLine.get("utilDay")?.value,
      lastDayUtil: this.formDeadLine.get("lastDayUtil")?.value,
      limitDate: this.formDeadLine.get("limitDate")?.value,
    };

    if (date.utilDay && date.lastDayUtil) {
      returnDate.utilDays = "-1";
      returnDate.deadline = this.getLastUtilDay(date.deadline.toDate());
    } else if (date.utilDay && !date.lastDayUtil) {
      returnDate.utilDays = date.limitDate;
      returnDate.deadline = this.getDateWithAddedDays(
        date.limitDate,
        date.deadline.toDate()
      );
    } else {
      returnDate.utilDays = "d" + date.deadline.date();
      returnDate.deadline = date.deadline.toISOString();
    }

    return returnDate;
  }

  validadeDatesForUtilDaysFor() {
    let returnDate = {
      deadline: "",
      utilDays: "",
    };

    const date = {
      deadline: moment(this.formDeadLineSon.get("deadline").value),
      utilDay: this.formDeadLineSon.get("utilDay")?.value,
      lastDayUtil: this.formDeadLineSon.get("lastDayUtil")?.value,
      limitDate: this.formDeadLineSon.get("limitDate")?.value,
    };

    if (date.utilDay && date.lastDayUtil) {
      returnDate.utilDays = "-1";
      returnDate.deadline = moment(
        this.getLastUtilDay(date.deadline.toDate())
      ).format("YYYY-MM-DD");
    } else if (date.utilDay && !date.lastDayUtil) {
      returnDate.utilDays = date.limitDate;
      returnDate.deadline = moment(
        this.getDateWithAddedDays(date.limitDate, date.deadline.toDate())
      ).format("YYYY-MM-DD");
    } else {
      returnDate.utilDays = "d" + date.deadline.date();
      returnDate.deadline = date.deadline.format("YYYY-MM-DD");
    }

    return returnDate;
  }

  validationDeadLine(): boolean {
    let deadline = this.formDeadLine.get("deadline").value;
    deadline = this.formatData(deadline);

    let isInvalid = false;

    this.holydays.forEach((element) => {
      if (element == deadline) {
        this.formDeadLine.get("deadline")?.setErrors({ invalid: "holiday" });
        isInvalid = true;
      }
    });

    if (new Date(deadline).getDay() == 5 || new Date(deadline).getDay() == 6) {
      this.formDeadLine.get("deadline")?.setErrors({ invalid: "notWorkDay" });
      isInvalid = true;
    }

    const day = deadline.split("-")[2];
    const month = deadline.split("-")[1];

    if (isHoliday(day, month)) {
      this.formDeadLine.get("deadline")?.setErrors({ invalid: "holiday" });
      isInvalid = true;
    }

    return !isInvalid;
  }

  validationDeadLineSon(): boolean {
    let deadline = this.formDeadLineSon.get("deadline").value;
    deadline = this.formatData(deadline);

    let isInvalid = false;

    this.holydays.forEach((element) => {
      if (element == deadline) {
        this.formDeadLineSon.get("deadline")?.setErrors({ invalid: "holiday" });
        isInvalid = true;
      }
    });

    if (new Date(deadline).getDay() == 5 || new Date(deadline).getDay() == 6) {
      this.formDeadLineSon.get("deadline")?.setErrors({ invalid: "notWorkDay" });
      isInvalid = true;
    }

    const day = deadline.split("-")[2];
    const month = deadline.split("-")[1];

    if (isHoliday(day, month)) {
      this.formDeadLineSon.get("deadline")?.setErrors({ invalid: "holiday" });
      isInvalid = true;
    }

    return !isInvalid;
  }

  formatData(date: any) {
    let timeStamp_date = new Date(date);
    let year = timeStamp_date.getFullYear();
    let month = formatZero(timeStamp_date.getMonth() + 1);
    let day = formatZero(timeStamp_date.getDate());

    function formatZero(numero) {
      return numero <= 9 ? "0" + numero : numero;
    }
    return `${year}-${month}-${day}`;
  }

  validadeDatesForUtilDaysForSon() {
    let returnDate = {
      deadline: "",
      utilDays: "",
    };

    const date = {
      deadline: moment(this.formDeadLineSon.get("deadline").value),
      utilDay: this.formDeadLineSon.get("utilDay")?.value,
      lastDayUtil: this.formDeadLineSon.get("lastDayUtil")?.value,
      limitDate: this.formDeadLineSon.get("limitDate")?.value,
    };

    if (date.utilDay && date.lastDayUtil) {
      returnDate.utilDays = "-1";
      returnDate.deadline = moment(
        this.getLastUtilDay(date.deadline.toDate())
      ).format("YYYY-MM-DD");
    } else if (date.utilDay && !date.lastDayUtil) {
      returnDate.utilDays = date.limitDate;
      returnDate.deadline = moment(
        this.getDateWithAddedDays(date.limitDate, date.deadline.toDate())
      ).format("YYYY-MM-DD");
    } else {
      returnDate.utilDays = "d" + date.deadline.date();
      returnDate.deadline = date.deadline.format("YYYY-MM-DD");
    }

    return returnDate;
  }

  getDeadLineActual(insumoId) {
    this.insumosService.getInsumoById(insumoId).subscribe((data) => {
      let actualDeadLine = data.actualDeadline;
      const dia = parseInt(actualDeadLine.split("/")[0], 10);
      const mes = parseInt(actualDeadLine.split("/")[1], 10);
      const ano = actualDeadLine.split("/")[2];

      this.formDeadLineSon.get("day").setValue(this.days[dia - 1]);
      this.formDeadLineSon.get("month").setValue(this.months[mes - 1]);
      this.formDeadLineSon.get("year").setValue(ano);

      this.formDeadLine.get("day").setValue(this.days[dia - 1]);
      this.formDeadLine.get("month").setValue(this.months[mes - 1]);
      this.formDeadLine.get("year").setValue(ano);
    });
  }

  onChangeLimitDateSon(index: number) {
    switch (index) {
      case 1:
        this.formDeadLineSon.get("dayOnMonth").setValue(true);
        this.formDeadLineSon.get("utilDay").setValue(false);
        break;
      case 2:
        this.formDeadLineSon.get("dayOnMonth").setValue(false);
        this.formDeadLineSon.get("utilDay").setValue(true);
        break;
      case 3:
        this.formDeadLineSon.get("limitDate").reset();
        if (this.formDeadLineSon.get("lastDayUtil").value == true) {
          this.formDeadLineSon.get("limitDate").disable();
        } else {
          this.formDeadLineSon.get("limitDate").enable();
        }
        break;
    }
  }

  onChangeLimitDate(index: number) {
    switch (index) {
      case 1:
        this.formDeadLine.get("dayOnMonth").setValue(true);
        this.formDeadLine.get("utilDay").setValue(false);
        break;
      case 2:
        this.formDeadLine.get("dayOnMonth").setValue(false);
        this.formDeadLine.get("utilDay").setValue(true);
        break;
      case 3:
        this.formDeadLine.get("limitDate").reset();
        if (this.formDeadLine.get("lastDayUtil").value == true) {
          this.formDeadLine.get("limitDate").disable();
        } else {
          this.formDeadLine.get("limitDate").enable();
        }
        break;
    }
  }

  getLastUtilDay(date: Date) {
    let lastDay = moment(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    let validate = true;

    while (validate) {
      if (lastDay.date() === 0 || lastDay.date() === 6) {
        lastDay.add(1, "days");
      } else {
        validate = false;
      }
    }

    return lastDay.toDate().toISOString();
  }

  getDateWithAddedDays(days: number, date: Date) {
    let index = days;
    let lastDay = moment(new Date(date.getFullYear(), date.getMonth(), 0));

    while (index > 0) {
      lastDay.add(1, "days");

      let isHoliday = false;

      this.holydays.forEach((element) => {
        const elementDay = Number(element.split("-")[2]);
        const elementMonth = Number(element.split("-")[1]);
        if (elementDay == lastDay.date() && elementMonth == lastDay.month()) {
          isHoliday = true;
        }
      });

      if ((lastDay.day() !== 0 && lastDay.day() !== 6) || isHoliday) {
        index--;
      }
    }

    return lastDay.toDate().toISOString();
  }

  setEmailResponsibleUpload(user) {
    this.formUpdate.get("responsibleUpload").setValue(user.email);
  }

  setEmailAnalysisUpload(user) {
    this.formUpdate.get("analysisUpload").setValue(user.email);
  }

  setEmailResponsavel(user) {
    this.formFiltro.get("email").setValue(user.email);
  }

  resetField(form) {
    if (form === 1) {
      this.formUpdate.get("responsibleUpload").reset();
      this.searchUserCtrl1.reset();
    } else if (form === 2) {
      this.formUpdate.get("analysisUpload").reset();
      this.searchUserCtrl2.reset();
    } else if (form === 3) {
      this.formUpdate.get("analysisUpload").reset();
      this.searchUserCtrl2.reset();
      this.formUpdate.get("responsibleUpload").reset();
      this.searchUserCtrl1.reset();
    } else {
      this.formFiltro.get("analysisUpload").reset();
      this.searchUserCtrlFiltro.reset();
    }
  }

  updatedInsumoResponsavel(insumoResponsavelDto) {
    this.insumosService
      .updateResponsavel(insumoResponsavelDto)
      .subscribe(() => {
        this.closeModalAlterarResponsavel.nativeElement.click();
        this.formUpdate.reset();
        this.resetField(3);
        this.actualInsumoId = null;
        this.OnInitializeDataSource();
      });
  }

  //uploads

  removeFile(file: any) {
    this.attachments = this.attachments.filter(
      (item) => item.originalFileName !== file.originalFileName
    );
    this.files = this.files.filter(
      (item) => item.data.name !== file.originalFileName
    );
  }

  uploadFile(file) {
    const fileName = file.data.name;
    if (this.insumo.acceptedDocs) {
      if (this.insumo.acceptedDocs !== "todos") {
        const fileNameParts = fileName.split(".");
        const extension = `.${fileNameParts[fileNameParts.length - 1]}`;
        const acceptedDocs = this.insumo.acceptedDocs.split(",");
        if (!acceptedDocs.includes(extension)) {
          this.messageErrorUpload = `"${fileName}" extensão de arquivo incorreta. Verifique os tipos de arquivos permitidos no insumo cadastrado.`;
          return;
        }
      }
    }
    this.messageErrorUpload = "";
    const formData = new FormData();
    formData.append("file", file.data);
    file.inProgress = true;
    const fileBytes = file.data.size;

    if (fileBytes > 10000000) {
      this.uploadService.validationAws().subscribe((keys) => {
        const splitFileName = String(file.data.name).split(".");
        const mimeType = splitFileName[splitFileName.length - 1];
        const fileKey = uuid.v4();
        const fileName = String(file.data.name);

        this.uploadService
          .uploadFileInS3(file.data, fileKey, mimeType, keys)
          .on("httpUploadProgress", function (progress) {
            let progressPercentage = Math.round(
              (progress.loaded / progress.total) * 100
            );
            file.progress = progressPercentage;
          })
          .promise()
          .then(() => {
            this.uploadService
              .uploadInsumoAnexoS3(this.user.id, fileKey, fileName)
              .subscribe((body) => {
                this.attachments.push(body);
              });
          });
      });
    } else {
      this.uploadService
        .uploadAnexoInsumo(formData, this.user.id)
        .pipe(
          map((event) => {
            switch (event.type) {
              case HttpEventType.UploadProgress:
                file.progress = Math.round((event.loaded * 100) / event.total);
                break;
              case HttpEventType.Response:
                return event;
            }
          }),
          catchError(() => {
            file.inProgress = false;
            return of(`${file.data.name} upload failed.`);
          })
        )
        .subscribe((event: any) => {
          if (typeof event === "object") {
            this.attachments.push(event.body);
          }
        });
    }
  }

  private uploadFiles() {
    this.fileUpload.nativeElement.value = "";
    this.files.forEach((file) => {
      this.uploadFile(file);
    });
  }

  onUploadButtonClick() {
    this.files = [];
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({ data: file, inProgress: false, progress: 0 });
      }
      this.uploadFiles();
    };
    // fileUpload.click();
  }

  onUploadAnexoButtonClick() {
    this.uploadLoading = true;
    this.arquivosModelo = [];
    const anexoUpload = this.anexoUpload.nativeElement;
    anexoUpload.onchange = () => {
      for (let index = 0; index < anexoUpload.files.length; index++) {
        const file = anexoUpload.files[index];
        this.arquivosModelo.push({
          data: file,
          inProgress: false,
          progress: 0,
        });
      }
      this.uploadAnexosForm();
    };
  }

  //uploads
  downloadFileNotification(fileId, insumoid) {
    this.insumosService
      .downloadNotification(this.user.id, insumoid, fileId)
      .subscribe(() => {
        console.log("Send notification");
      });
    this.downloadFile(fileId);
  }

  downloadFile(fileId: any) {
    this.accountService.getTokenWithLowExpiration().subscribe((data) => {
      const urlToDownloadFile: string = `${this.anexoDownload}${fileId}?token=${data.token}`;

      window.open(urlToDownloadFile);
    });
  }

  downloadFileInsumoContent(data: any) {
    const insumoVersion: number = Number(data.version.replace("v", ""));
    const file = data.attachments.find(
      (insumo) => insumo.version === insumoVersion
    );

    this.insumosService
      .downloadNotification(this.user.id, data.id, file.id)
      .subscribe(() => console.log("Send notification"));

    this.downloadFile(file.id);
  }

  private uploadAnexosForm() {
    this.anexoUpload.nativeElement.value = "";
    this.arquivosModelo.forEach((file) => {
      this.uploadAnexoForm(file);
    });
  }

  changeVersion() {
    if (this.formChangeVersion.invalid) {
      return;
    }

    const insumoVersionDto = {
      insumosId: this.actualInsumoId,
      userId: this.user.id,
      version: this.formChangeVersion.get("version").value,
      justificativa: this.formChangeVersion.get("justificativa").value,
    };

    this.insumosService.updateVersion(insumoVersionDto).subscribe(() => {
      this.closeModalVersion.nativeElement.click();
      this.formChangeVersion.reset();
      this.actualInsumoId = null;
      this.OnInitializeDataSource();
    });
  }

  getInsumoWithVersion(insumoAnexos: any[], version: string) {
    return insumoAnexos.find((item) => item.version == version);
  }

  uploadAnexoForm(file) {
    const fileName = file.data.name;

    if (file.data.size > 300000000) {
      this.messageErrorUpload = `O tamanho do arquivo ${fileName} é maior que o limite de 300MB.`;
      this.uploadLoading = false;
      return;
    }
    this.messageErrorUpload = "";
    const formData = new FormData();
    formData.append("file", file.data);
    file.inProgress = true;

    this.isLoadingAnexo = true;

    this.uploadService
      .uploadAnexoInsumoModel(formData, this.user.id)
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round((event.loaded * 100) / event.total);
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        catchError(() => {
          file.inProgress = false;
          this.uploadLoading = false;
          this.isLoadingAnexo = false;
          return of(`${file.data.name} upload failed.`);
        })
      )
      .subscribe((event: any) => {
        if (typeof event === "object") {
          this.anexos.push(event.body);
          this.isLoadingAnexo = false;
          this.uploadLoading = false;
        }
      });
  }

  downloadAnexoModel(fileId: any) {
    this.accountService.getTokenWithLowExpiration().subscribe((data) => {
      const urlToDownloadFile: string = `${this.anexoModelFileDownload}${fileId}?token=${data.token}`;

      window.open(urlToDownloadFile);
    });
  }

  downloadAnexoModelFileNotification(fileId) {
    this.downloadAnexoModel(fileId);

    this.insumosService
      .downloadAnexoModelFileNotification(
        this.user.id,
        this.actualInsumoId,
        fileId
      )
      .subscribe();
  }

  //table Content
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;

    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  checkboxLabel(row?: InsumoList): string {
    if (!row) {
      return `${this.isAllSelected() ? "deselect" : "select"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.id + 1
    }`;
  }

  somArray = (list: any[]) => {
    let total = 0;
    list.map((item) => (total += item.progress));

    return total / list.length;
  };

  removeAnexoFormPreUpload(file: any) {
    this.attachments = this.attachments.filter(
      (item) => item.originalFileName !== file.originalFileName
    );
    this.files = this.files.filter(
      (item) => item.data.name !== file.originalFileName
    );
  }

  removeAnexoModelFilePreUpload(file: any) {
    this.arquivosModelo = this.files.filter(
      (item) => item.data.name !== file.originalFileName
    );

    this.anexos = this.anexos.filter(
      (item) => item.originalFileName !== file.originalFileName
    );
  }

  removeAnexoForm(anexoId) {
    let insumoRemoveAnexoModelDto = {
      insumoId: this.actualInsumoId,
      anexoModelId: anexoId,
      userId: this.user.id,
    };

    this.insumosService
      .removeAnexoModelFileInsumo(insumoRemoveAnexoModelDto)
      .subscribe((data) => {
        this.getInsumoById(insumoRemoveAnexoModelDto.insumoId);
        this.getAttachmentModels(insumoRemoveAnexoModelDto.insumoId);
      });
  }

  removeAllAnexosForm() {
    this.insumosService
      .removeAllAnexoModelFileInsumo(this.actualInsumoId)
      .subscribe((data) => {
        this.getInsumoById(this.actualInsumoId);
        this.closeModalAnexoModel.nativeElement.click();
        this.getAttachmentModels(this.actualInsumoId);
      });
  }

  exportToExcel(model: string) {
    this.validationFilterRequisition();

    this.accountService.getTokenWithLowExpiration().subscribe((data) => {
      const link = `${environment.apiUrl}/insumo/export?&name=${this.filterParams.name}&userId=${this.filterParams.userId}&email=${this.filterParams.email}&segment=${this.filterParams.segment}&status=${this.filterParams.status}&competence=${this.filterParams.competence}&competenceStatus=${this.filterParams.competenceStatus}&insumoId=${this.filterParams.id}&model=${model}&token=${data.token}`;

      window.open(link, "_blank");
    });
  }

  columnsToDisplay = [
    "select",
    "id",
    "name",
    "publico",
    "segment",
    "competence",
    "deadline",
    "version",
    "dataDeUpload",
    "responsavel",
    "actions",
  ];

  displayedColumnsInterno: string[] = [
    "idArquivo",
    "nomeArquivo",
    "Justificativa",
    "dataEHoraEnvio",
    "versao",
    "acoes",
  ];

  displayedColumns: string[] = ["fileName", "inclusionDate", "download"];

  displayedColumnsSimulation: string[] = ["nome", "competencia", "deadline"];

  days: string[] = Array.from({ length: 31 }, (_, i) => (i + 1).toString());

  months: string[] = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  years: string[] = Array.from({ length: 7 }, (_, i) => (i + 2024).toString());
}

interface EventEmitterValue {
  competence: string;
}

interface SimulationsDeadLine {
  deadline: string;
  nome: string;
  competence: string;
}
