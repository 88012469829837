<p class="text-muted mt-4 mb-1">
    <span class="text-primary">Portal de Comissionamento.</span>
</p>


<div class="d-flex align-items-center">
    <h1 class="mt-0 mb-0 text-muted">
        Mapeamento Documental
    </h1>
    <button class="btn btn-primary float-right ml-auto" routerLink="/monitoramento">
        <mat-icon>arrow_back</mat-icon>
    </button>
</div>
<form [formGroup]="formMatriz">
    <mat-card class="mt-5 mb-5">
        <!-- <mat-expansion-panel  hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Cabeçalho
                </mat-panel-title>
                <mat-panel-description>
                   
                    <mat-icon>connect_without_contact</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <form>
                <div class="form-group">
                    <mat-form-field class="inputs">
                        <mat-label>Área</mat-label>
                        <input matInput placeholder="Ex. arquivo" value="">
                    </mat-form-field>
                </div>

                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <mat-form-field class="inputs">
                                <mat-label>Entrevistado</mat-label>
                                <input matInput placeholder="Ex. arquivo" value="">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <mat-form-field class="inputs">
                                <mat-label>Revisão</mat-label>
                                <input matInput placeholder="Ex. arquivo" value="">
                            </mat-form-field>
                        </div>
                    </div>
                </div>


                <div class="form-group">
             

                    <mat-form-field appearance="fill">
                        <mat-label>Selecione Data:</mat-label>
                        <input matInput [matDatepicker]="picker">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>

                </div>




            </form>


            <mat-action-row>
                <button mat-button color="primary" >Próximo</button>
            </mat-action-row>


        </mat-expansion-panel> -->



        <mat-card-title class="text-center d-flex mt-3 mb-3 text-primary">
            <label class="text-primary mr-3" style="font-size: 1.5rem;">Preenchimento da Entrevista</label>
            <mat-card-subtitle>
                <mat-icon class="text-primary">how_to_reg</mat-icon>
            </mat-card-subtitle>
        </mat-card-title>

        <!-- Type the country name -->




        <mat-accordion>
            <mat-expansion-panel class="mt-1">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Produção documental
                    </mat-panel-title>
                    <mat-panel-title>
                        <mat-icon>assignment</mat-icon>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <!-- <div class="form-group">
                                <mat-form-field appearance="fill" class="selects100vw">
                                    <mat-label>Processo</mat-label>
                                    <mat-select [(value)]="selectedProcesso" #selectProcesso formControlName="q2">
                                        
                                        <mat-option *ngFor="let processo of processos" [value]="processo" >{{processo}}</mat-option>
                                        <mat-option value="outraOpcaoProcesso" (click)="formMatriz.get('q2').setValue('')">Outra opção</mat-option>
                                    </mat-select>
                                </mat-form-field>
            
                                <div class="form-group" *ngIf="selectProcesso.value == 'outraOpcaoProcesso'" >
                                <ng-container >
                                    <mat-form-field class="inputs">
                                        <mat-label>Adicione uma nova opção</mat-label>
                                        <input matInput placeholder="Ex. arquivo" formControlName="q2">
                                    </mat-form-field>
                                </ng-container>
                            </div>

                            </div> -->
                <!-- <div class="form-group">
                                <mat-form-field appearance="fill" class="w-100">
                                    <mat-label>Área:</mat-label>
                                    <input type="text" matInput placeholder="Área do documento" formControlName="q42">
                                </mat-form-field>
                            </div> -->
                <div class="form-group">
                    <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('AREA')">
                        <mat-label>Área:</mat-label>
                        <input type="text" matInput placeholder="Digite para buscar opções"
                            autocomplete="new-responsavel" [matAutocomplete]="area" [formControl]="search"
                            autocomplete="off" (keyup)="setVar('q42')">
                        <mat-autocomplete #area="matAutocomplete">
                            <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                            <ng-container *ngIf="!isLoading">
                                <mat-option (click)="formMatriz.get('q42').setValue(area.name)"
                                    *ngFor="let area of filtered" [value]="area.name">
                                    <span>{{area.name}}</span>
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <!-- <div class="form-group">
                                <mat-form-field appearance="fill" class="w-100">
                                    <mat-label>Macroprocesso:</mat-label>
                                    <input type="text" matInput placeholder="Tipo do macroprocesso" formControlName="q43">
                                </mat-form-field>
                            </div> -->
                <div class="form-group">
                    <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('MACROPROCESSO')">
                        <mat-label>Macroprocessos:</mat-label>
                        <input type="text" matInput placeholder="Digite para buscar opções"
                            autocomplete="new-responsavel" [matAutocomplete]="macroProcesso" [formControl]="search"
                            autocomplete="off" (keyup)="setVar('q43')">
                        <mat-autocomplete #macroProcesso="matAutocomplete">
                            <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                            <ng-container *ngIf="!isLoading">
                                <mat-option (click)="formMatriz.get('q43').setValue(macroProcesso.name)"
                                    *ngFor="let macroProcesso of filtered" [value]="macroProcesso.name">
                                    <span>{{macroProcesso.name}}</span>
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('PROCESSOS')">
                        <mat-label>Processos:</mat-label>
                        <input type="text" matInput placeholder="Digite para buscar opções"
                            autocomplete="new-responsavel" [matAutocomplete]="processo" [formControl]="search"
                            autocomplete="off" (keyup)="setVar('q2')">
                        <mat-autocomplete #processo="matAutocomplete">
                            <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                            <ng-container *ngIf="!isLoading">
                                <mat-option (click)="formMatriz.get('q2').setValue(processo.name)"
                                    *ngFor="let processo of filtered" [value]="processo.name">
                                    <span>{{processo.name}}</span>
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <!-- <div class="form-group">
                            <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('ACORDOS')">
                                <mat-label>Acordos:</mat-label>
                                <input type="text" matInput placeholder="Tipo do processo" autocomplete="new-responsavel"
                                     [matAutocomplete]="auto3" [formControl]="searchProcessos" autocomplete="off" >
                                <mat-autocomplete #auto3="matAutocomplete">
                                    <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                                    <ng-container *ngIf="!isLoading">
                                        <mat-option *ngFor="let processo of filteredProcessos" [value]="processo.name">
                                            <span><b>{{processo.name}}</b></span>
                                        </mat-option>
                                    </ng-container>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div> -->
                <!-- <div class="form-group">
                            <mat-form-field appearance="fill" class="selects100vw">
                                <mat-label>Espécie Documental</mat-label>
                                <mat-select formControlName="q4"> 
                                        <mat-option *ngFor="let especieDocumental of especiesDocumentais" [value]="especieDocumental.descricao" (click)="setAtividadeList(especieDocumental.atividades)">
                                            {{especieDocumental.descricao}}
                                        </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div> -->

                <!-- <div class="form-group " >
                            <mat-form-field class="inputs">
                                <mat-label>Espécie Documental</mat-label>
                            <input type="text" matInput [matAutocomplete]="especie"  [formControl]="formMatriz.get('q4')" >
                            <mat-autocomplete #especie="matAutocomplete" >
                                <mat-option  *ngFor="let especieDocumental of filteredEspecie | async" [value]="especieDocumental" (click)="setAtividadeList(especieDocumental)">
                                    {{especieDocumental}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    
                        </div>  -->
                <div class="form-group">
                    <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('ESPECIE')">
                        <mat-label>Espécie documental:</mat-label>
                        <input type="text" matInput placeholder="Digite para buscar opções"
                            autocomplete="new-responsavel" [matAutocomplete]="especie" [formControl]="search"
                            autocomplete="off" (keyup)="setVar('q4')">
                        <mat-autocomplete #especie="matAutocomplete">
                            <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                            <ng-container *ngIf="!isLoading">
                                <mat-option (click)="formMatriz.get('q4').setValue(processo.name)"
                                    (click)="setTipologia()" *ngFor="let processo of filtered" [value]="processo.name">
                                    <span>{{processo.name}}</span>
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <!-- <div class="form-group">
                            <mat-form-field *ngIf="outraOpcaoEspecie" class="inputs">
                                <mat-label>Adicione uma nova opção</mat-label>
                                <input matInput placeholder="Ex. arquivo" formControlName="q4">
                            </mat-form-field>
                        </div>
                        <div class="form-group" *ngIf="!outraOpcaoEspecie">
                            <mat-form-field appearance="fill"  class="selects100vw" >
                                <mat-label>Atividade</mat-label>
                                <mat-select formControlName="q3">
                                        <mat-option  *ngFor="let atividades of atividadesList" [value]="atividades">
                                            {{atividades}}
                                        </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="form-group" *ngIf="outraOpcaoEspecie">
                            <mat-form-field appearance="fill"  class="selects100vw" >
                                <mat-label>Atividade</mat-label>
                                <input matInput [value]="formMatriz.get('q4').value + ' de ' " formControlName="q3">
                            </mat-form-field>
                        </div> -->
                <div class="form-group">
                    <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('ATIVIDADE')">
                        <mat-label>Atividade:</mat-label>
                        <input type="text" matInput placeholder="Digite para buscar opções"
                            autocomplete="new-responsavel" [matAutocomplete]="atividade" [formControl]="search"
                            autocomplete="off" (keyup)="setVar('q3')">
                        <mat-autocomplete #atividade="matAutocomplete">
                            <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                            <ng-container *ngIf="!isLoading">
                                <mat-option (click)="formMatriz.get('q3').setValue(atividade.name)"
                                    (click)="setTipologia()" *ngFor="let atividade of filtered"
                                    [value]="atividade.name">
                                    <span>{{atividade.name}}</span>
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <!-- <div class="form-group">
                    <mat-form-field appearance="fill" class="selects100vw">
                        <mat-label>Espécie Documental</mat-label>
                        <mat-select [(value)]="selectedEspecieDocumental" #selectEspecieDocumental formControlName="q4">
                            
                            <mat-option *ngFor="let especieDocumental of especiesDocumentais"
                                [value]="especieDocumental" >{{especieDocumental}}
                            </mat-option>
                            <mat-option value="outraOpcaoEspecieDocumental">Outra opção</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <ng-container *ngIf="selectEspecieDocumental.value == 'outraOpcaoEspecieDocumental'">
                        <mat-form-field class="inputs">
                            <mat-label>Adicione uma nova opção</mat-label>
                            <input matInput placeholder="Ex. arquivo" value="" formControlName="q4">
                        </mat-form-field>
                    </ng-container>
                </div>

                            <div class="form-group">
                                <mat-form-field appearance="fill" class="selects100vw">
                                    <mat-label>Atividade</mat-label>
                                    <mat-select [(value)]="selectedAtividade" #selectAtividade formControlName="q3">
                                       
                                        <mat-option *ngFor="let atividade of atividades" [value]="atividade" >{{atividade}}
                                        </mat-option>
                                        <mat-option value="outraOpcaoAtividade">Outra opção</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <ng-container *ngIf="selectAtividade.value == 'outraOpcaoAtividade'">
                                    <mat-form-field class="inputs">
                                        <mat-label>Adicione uma nova opção</mat-label>
                                        <input matInput placeholder="Ex. arquivo" value="" formControlName="q3">
                                    </mat-form-field>
                                </ng-container>
                            </div> -->

                <div class="form-group" style="pointer-events: none;opacity: 0.6;">
                    <mat-form-field appearance="fill" class="selects100vw">
                        <mat-label>Tipologia Documental (Espécie + Atividade)</mat-label>
                        <input matInput placeholder="Ex. arquivo" value="" formControlName="q1" readonly="readonly">
                    </mat-form-field>

                    <!-- <mat-form-field appearance="fill" class="selects100vw">
                                    <mat-label>Tipologia Documental</mat-label>
                                    <mat-select [(value)]="selectedTipologiaDocumental" #selectTipologiaDocumental formControlName="q1">
                                        <mat-option *ngFor="let state of states" [value]="state" >{{state}}</mat-option>
                                        <mat-option value="outraOpcaoTipologia" (click)="formMatriz.get('q1').setValue('')">Outra opção</mat-option>
                                    </mat-select>
                                </mat-form-field>
            
                                <ng-container *ngIf="selectTipologiaDocumental.value == 'outraOpcaoTipologia'">
                                    <mat-form-field class="inputs">
                                        <mat-label>Adicione uma nova opção</mat-label>
                                        <input matInput placeholder="Ex. arquivo" value="" formControlName="q1">
                                    </mat-form-field>
                                </ng-container> -->


                    <!-- funcionando somente com button -->
                    <!-- <button class="outraOpcao">
                                    click-me
                                </button>
            
                                <mat-form-field class="hidden inputOutraOpcao">
                                    <mat-label>Outras Formas de Nomear o Arquivo</mat-label>
                                    <input matInput placeholder="Ex. arquivo" value="">
                                </mat-form-field> -->

                </div>

                <div class="form-group">
                    <mat-form-field class="inputs">
                        <mat-label>Documentos Vinculados/Anexos</mat-label>
                        <input matInput placeholder="Ex. arquivo" value="" formControlName="q35">
                    </mat-form-field>
                </div>

            </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion>
            <mat-expansion-panel class="mt-1">
                <mat-expansion-panel-header>
                    <mat-panel-title>Caraceristicas do documento</mat-panel-title>
                    <mat-panel-description>
                        <mat-icon>description</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <!-- <div class="form-group">
                                <div class="form-group">
                                    <mat-form-field appearance="fill" class="selects100vw">
                                        <mat-label>Suporte/Midia</mat-label>
                                        <mat-select [(value)]="selectedSuporteMidia" #selectSuporteMidia formControlName="q8">
                                            
                                            <mat-option *ngFor="let suporteMidia of suportesMidia" [value]="suporteMidia">
                                                {{suporteMidia}}</mat-option>
                                            <mat-option value="outraOpcaoSuporteMidia" (click)="formMatriz.get('q8').setValue('')">Outra opção</mat-option>
            
                                        </mat-select>
                                    </mat-form-field>
                                    <ng-container *ngIf="selectSuporteMidia.value == 'outraOpcaoSuporteMidia'">
                                        <mat-form-field class="inputs">
                                            <mat-label>Adicione uma nova opção</mat-label>
                                            <input matInput placeholder="Ex. arquivo" value="" formControlName="q8">
                                        </mat-form-field>
                                    </ng-container>
                                </div>
                            </div> -->
                <div class="form-group">
                    <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('SUPORTE')">
                        <mat-label>Suporte mídia:</mat-label>
                        <input type="text" matInput placeholder="Digite para buscar opções"
                            autocomplete="new-responsavel" [matAutocomplete]="suporte" [formControl]="search"
                            autocomplete="off" (keyup)="setVar('q8')">
                        <mat-autocomplete #suporte="matAutocomplete">
                            <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                            <ng-container *ngIf="!isLoading">
                                <mat-option (click)="formMatriz.get('q8').setValue(suporte.name)"
                                    *ngFor="let suporte of filtered" [value]="suporte.name">
                                    <span>{{suporte.name}}</span>
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <!-- <div class="form-group">
                                <mat-form-field appearance="fill" class="selects100vw">
                                    <mat-label>Via</mat-label>
                                    <mat-select [(value)]="selectedVia" #selectVia formControlName="q9">
                                        <mat-option *ngFor="let via of vias" [value]="via">
                                            {{via}}</mat-option>
                                        <mat-option value="outraOpcaoVias" (click)="formMatriz.get('q9').setValue('')">Outra opção</mat-option>
            
                                    </mat-select>
                                </mat-form-field>
                                <ng-container *ngIf="selectVia.value == 'outraOpcaoVias'">
                                    <mat-form-field class="inputs">
                                        <mat-label>Adicione uma nova opção</mat-label>
                                        <input matInput placeholder="Ex. arquivo" value="" formControlName="q9">
                                    </mat-form-field>
                                </ng-container>
                            </div> -->
                <div class="form-group">
                    <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('VIA')">
                        <mat-label>Via:</mat-label>
                        <input type="text" matInput placeholder="Digite para buscar opções"
                            autocomplete="new-responsavel" [matAutocomplete]="via" [formControl]="search"
                            autocomplete="off" (keyup)="setVar('q9')">
                        <mat-autocomplete #via="matAutocomplete">
                            <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                            <ng-container *ngIf="!isLoading">
                                <mat-option (click)="formMatriz.get('q9').setValue(via.name)"
                                    *ngFor="let via of filtered" [value]="via.name">
                                    <span>{{via.name}}</span>
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <!-- 

                            <div class="form-group">
                                <mat-form-field appearance="fill" class="selects100vw">
                                    <mat-label>Valor do documento</mat-label>
                                    <mat-select [(value)]="selectedValor" #selectValor formControlName="q6">
                                        <mat-option *ngFor="let valor of valores" [value]="valor" >
                                            {{valor}}</mat-option>
                                        <mat-option value="outraOpcaoValor" (click)="formMatriz.get('q6').setValue('')">Outra opção</mat-option>
            
                                    </mat-select>
            
                                </mat-form-field>
                                <ng-container *ngIf="selectValor.value == 'outraOpcaoValor'">
                                    <mat-form-field class="inputs">
                                        <mat-label>Adicione uma nova opção</mat-label>
                                        <input matInput placeholder="Ex. arquivo" value="" formControlName="q6">
                                    </mat-form-field>
                                </ng-container>
                            </div> -->
                <div class="form-group">
                    <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('VALOR')">
                        <mat-label>Valor do documento:</mat-label>
                        <input type="text" matInput placeholder="Digite para buscar opções"
                            autocomplete="new-responsavel" [matAutocomplete]="valor" [formControl]="search"
                            autocomplete="off" (keyup)="setVar('q6')">
                        <mat-autocomplete #valor="matAutocomplete">
                            <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                            <ng-container *ngIf="!isLoading">
                                <mat-option (click)="formMatriz.get('q6').setValue(valor.name)"
                                    *ngFor="let valor of filtered" [value]="valor.name">
                                    <span>{{valor.name}}</span>
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>


            </mat-expansion-panel>
        </mat-accordion>


        <mat-accordion>
            <mat-expansion-panel class="mt-1">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Fluxo, Compartilhamento & Consultas
                    </mat-panel-title>
                    <mat-panel-title>
                        <mat-icon>share</mat-icon>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <!-- <div class="form-group">
                            <mat-form-field appearance="fill" class="selects100vw">
                                <mat-label>Consultas</mat-label>
                                <mat-select [(value)]="selectedConsulta" #selectConsulta formControlName="q14">
                                    <mat-option *ngFor="let consulta of consultas" [value]="consulta">
                                        {{consulta}}</mat-option>
                                    <mat-option value="outraOpcaoConsulta" (click)="formMatriz.get('q14').setValue('')">Outra opção</mat-option>
        
                                </mat-select>
                            </mat-form-field>
                            <ng-container *ngIf="selectConsulta.value == 'outraOpcaoConsulta'">
                                <mat-form-field class="inputs">
                                    <mat-label>Adicione uma nova opção</mat-label>
                                    <input matInput placeholder="Ex. arquivo" value="" formControlName="q14">
                                </mat-form-field>
                            </ng-container>
                        </div> -->

                <div class="form-group">
                    <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('CONSULTAS')">
                        <mat-label>Consultas:</mat-label>
                        <input type="text" matInput placeholder="Digite para buscar opções"
                            autocomplete="new-responsavel" [matAutocomplete]="consultas" [formControl]="search"
                            autocomplete="off" (keyup)="setVar('q14')">
                        <mat-autocomplete #consultas="matAutocomplete">
                            <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                            <ng-container *ngIf="!isLoading">
                                <mat-option (click)="formMatriz.get('q14').setValue(consultas.name)"
                                    *ngFor="let consultas of filtered" [value]="consultas.name">
                                    <span>{{consultas.name}}</span>
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <!-- 
                        <div class="form-group">
                            <mat-form-field appearance="fill" class="selects100vw">
                                <mat-label>Quantidade de pessoas que Compartilham o Documento</mat-label>
                                <mat-select [(value)]="selectedQuantidadePessoa" #selectQuantidadePessoa formControlName="q11">
                                    <mat-option *ngFor="let quantidadePessoa of quantidadePessoas" [value]="quantidadePessoa">
                                        {{quantidadePessoa}}</mat-option>
                                    <mat-option value="outraOpcaoQuantidadePessoa" (click)="formMatriz.get('q11').setValue('')">Outra opção</mat-option>
        
                                </mat-select>
                            </mat-form-field>
                            <ng-container *ngIf="selectQuantidadePessoa.value == 'outraOpcaoQuantidadePessoa'">
                                <mat-form-field class="inputs">
                                    <mat-label>Adicione uma nova opção</mat-label>
                                    <input matInput placeholder="Ex. arquivo" value="" formControlName="q11">
                                </mat-form-field>
                            </ng-container>
                        </div> -->
                <div class="form-group">
                    <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('QUANTIDADE_PESSOAS')">
                        <mat-label>Quantidade de pessoas que compartilham o documento:</mat-label>
                        <input type="text" matInput placeholder="Digite para buscar opções"
                            autocomplete="new-responsavel" [matAutocomplete]="qPessoas" [formControl]="search"
                            autocomplete="off" (keyup)="setVar('q11')">
                        <mat-autocomplete #qPessoas="matAutocomplete">
                            <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                            <ng-container *ngIf="!isLoading">
                                <mat-option (click)="formMatriz.get('q11').setValue(qPessoas.name)"
                                    *ngFor="let qPessoas of filtered" [value]="qPessoas.name">
                                    <span>{{qPessoas.name}}</span>
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>



                <!--         
                        <div class="form-group">
                            <mat-form-field appearance="fill" class="selects100vw">
                                <mat-label>Formas de Envio do Documento</mat-label>
                                <mat-select [(value)]="selectedFormaEnvioDoc" #selectFormaEnvioDoc formControlName="q12">
                                    <mat-option *ngFor="let formaEnvioDoc of formasEnvioDocs" [value]="formaEnvioDoc">
                                        {{formaEnvioDoc}}</mat-option>
                                    <mat-option value="outraOpcaoFormaEnvioDoc" (click)="formMatriz.get('q12').setValue('')">Outra opção</mat-option>
        
                                </mat-select>
                            </mat-form-field>
                            <ng-container *ngIf="selectFormaEnvioDoc.value == 'outraOpcaoFormaEnvioDoc'">
                                <mat-form-field class="inputs">
                                    <mat-label>Adicione uma nova opção</mat-label>
                                    <input matInput placeholder="Ex. arquivo" value="" formControlName="q12">
                                </mat-form-field>
                            </ng-container>
                        </div> -->
                <div class="form-group">
                    <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('FORMAS_ENVIO')">
                        <mat-label>Formas de envio do documento:</mat-label>
                        <input type="text" matInput placeholder="Digite para buscar opções"
                            autocomplete="new-responsavel" [matAutocomplete]="formasEnvio" [formControl]="search"
                            autocomplete="off" (keyup)="setVar('q12')">
                        <mat-autocomplete #formasEnvio="matAutocomplete">
                            <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                            <ng-container *ngIf="!isLoading">
                                <mat-option (click)="formMatriz.get('q12').setValue(formasEnvio.name)"
                                    *ngFor="let formasEnvio of filtered" [value]="formasEnvio.name">
                                    <span>{{formasEnvio.name}}</span>
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <!-- <div class="form-group">
                            <mat-form-field appearance="fill" class="selects100vw">
                                <mat-label>Possui que Tipo de Assinatura</mat-label>
                                <mat-select [(value)]="selectedTipoAssinatura" #selectTipoAssinatura formControlName="q13">
                                    <mat-option *ngFor="let tipoAssinatura of tipoAssinaturas" [value]="tipoAssinatura">
                                        {{tipoAssinatura}}</mat-option>
                                    <mat-option value="outraOpcaoTipoAssinatura" (click)="formMatriz.get('q13').setValue('')">Outra opção</mat-option>
        
                                </mat-select>
                            </mat-form-field>
                            <ng-container *ngIf="selectTipoAssinatura.value == 'outraOpcaoTipoAssinatura'">
                                <mat-form-field class="inputs">
                                    <mat-label>Adicione uma nova opção</mat-label>
                                    <input matInput placeholder="Ex. arquivo" value="" formControlName="q13">
                                </mat-form-field>
                            </ng-container>
                        </div> -->
                <div class="form-group">
                    <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('ASSINATURA')">
                        <mat-label>Tipo de assinatura:</mat-label>
                        <input type="text" matInput placeholder="Digite para buscar opções"
                            autocomplete="new-responsavel" [matAutocomplete]="assinatura" [formControl]="search"
                            autocomplete="off" (keyup)="setVar('q13')">
                        <mat-autocomplete #assinatura="matAutocomplete">
                            <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                            <ng-container *ngIf="!isLoading">
                                <mat-option (click)="formMatriz.get('q13').setValue(assinatura.name)"
                                    *ngFor="let assinatura of filtered" [value]="assinatura.name">
                                    <span>{{assinatura.name}}</span>
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <div class="form-group">
                    <mat-form-field class="inputs">
                        <mat-label>Compartilhado com qual Área(as)</mat-label>
                        <input matInput placeholder="Ex. arquivo" value="" formControlName="q37">
                    </mat-form-field>
                </div>
                <div class="form-group d-flex">
                    <mat-checkbox formControlName="q30" color="warn" class="mr-2"></mat-checkbox>
                    <mat-label>Possui emails em anexo?</mat-label>
                </div>
                <div class="form-group">
                    <mat-label>Nome da área/Setor/Departamento</mat-label> <br>
                    <mat-slide-toggle formControlName="q24" color="warn">Serviços Recursos Humanos Regionais
                    </mat-slide-toggle>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion>
            <mat-expansion-panel class="mt-1">
                <mat-expansion-panel-header>
                    <mat-panel-title>ROI - Retorno e Investimento</mat-panel-title>
                    <mat-panel-description>
                        <mat-icon>sell</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <!-- <div class="form-group">
                                <mat-form-field appearance="fill" class="selects100vw">
                                    <mat-label>Atualizações do documento</mat-label>
                                    <mat-select [(value)]="selectedAtualizacao" #selectAtualizacao formControlName="q15">
                                        
                                        <mat-option *ngFor="let atualizacao of atualizacoes" [value]="atualizacao" >
                                            {{atualizacao}}</mat-option>
                                        <mat-option value="outraOpcaoAtualizacao" (click)="formMatriz.get('q15').setValue('')">Outra opção</mat-option>
            
                                    </mat-select>
                                </mat-form-field>
                                <ng-container *ngIf="selectAtualizacao.value == 'outraOpcaoAtualizacao'" >
                                    <mat-form-field class="inputs">
                                        <mat-label>Adicione uma nova opção</mat-label>
                                        <input matInput placeholder="Ex. arquivo" value="" formControlName="q15">
                                    </mat-form-field>
                                </ng-container>
                            </div> -->
                <div class="form-group">
                    <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('ATUALIZACOES_DOCUMENTO')">
                        <mat-label>Atualizações do documento:</mat-label>
                        <input type="text" matInput placeholder="Digite para buscar opções"
                            autocomplete="new-responsavel" [matAutocomplete]="atDocumento" [formControl]="search"
                            autocomplete="off" (keyup)="setVar('q15')">
                        <mat-autocomplete #atDocumento="matAutocomplete">
                            <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                            <ng-container *ngIf="!isLoading">
                                <mat-option (click)="formMatriz.get('q15').setValue(atDocumento.name)"
                                    *ngFor="let atDocumento of filtered" [value]="atDocumento.name">
                                    <span>{{atDocumento.name}}</span>
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>


                <!-- <div class="form-group">
                    <mat-form-field appearance="fill" class="selects100vw">
                        <mat-label>Tempo Mensal Gasto nesta Atividade em Horas</mat-label>
                        <mat-select [(value)]="selectedTempoMensalGasto" #selectTempoMensalGasto formControlName="q16">
                            
                            <mat-option *ngFor="let tempoMensalGasto of tempoMensalGastos" [value]="tempoMensalGasto">
                                {{tempoMensalGasto}}</mat-option>
                            <mat-option value="outraOpcaoTempoMensal" (click)="formMatriz.get('q16').setValue('')">Outra opção</mat-option>

                        </mat-select>
                    </mat-form-field>
                    <ng-container *ngIf="selectTempoMensalGasto.value == 'outraOpcaoTempoMensal'">
                        <mat-form-field class="inputs">
                            <mat-label>Adicione uma nova opção</mat-label>
                            <input matInput placeholder="Ex. arquivo" value="" formControlName="q16">
                        </mat-form-field>
                    </ng-container>
                </div> -->
                <div class="form-group">
                    <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('TEMPO_GASTO')">
                        <mat-label>Tempo mensal gasto nesta atividade em horas:</mat-label>
                        <input type="text" matInput placeholder="Digite para buscar opções"
                            autocomplete="new-responsavel" [matAutocomplete]="tempoGasto" [formControl]="search"
                            autocomplete="off" (keyup)="setVar('q16')">
                        <mat-autocomplete #tempoGasto="matAutocomplete">
                            <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                            <ng-container *ngIf="!isLoading">
                                <mat-option (click)="formMatriz.get('q16').setValue(tempoGasto.name)"
                                    *ngFor="let tempoGasto of filtered" [value]="tempoGasto.name">
                                    <span>{{tempoGasto.name}}</span>
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

            </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion>
            <mat-expansion-panel class="mt-1">
                <mat-expansion-panel-header>
                    <mat-panel-title>Atendimento ao regulatório e auditorias</mat-panel-title>
                    <mat-panel-description>
                        <mat-icon>face</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div class="form-group d-flex">
                    <mat-checkbox class="mr-2" color="warn" formControlName="q31"></mat-checkbox>
                    <mat-label>É utilizado para Auditoria Interna/Externa?</mat-label>
                </div>

            </mat-expansion-panel>

        </mat-accordion>

        <mat-accordion>
            <mat-expansion-panel class="mt-1">
                <mat-expansion-panel-header>
                    <mat-panel-title>Armazenamento Digital & Não Digital</mat-panel-title>
                    <mat-panel-description>
                        <mat-icon>folder_shared</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="form-group">
                    <mat-form-field class="inputs">
                        <mat-label>Nome da Plataforma de Produção e/ou Armazenamento final do Documento</mat-label>
                        <input matInput placeholder="Ex. arquivo" value="" formControlName="q39">
                    </mat-form-field>
                </div>


                <div class="form-group">
                    <mat-form-field class="inputs">
                        <mat-label>Nome do Diretório</mat-label>
                        <input matInput placeholder="Ex. arquivo" value="" formControlName="q40">
                    </mat-form-field>
                </div>

                <!-- 
                <div class="form-group">
                  
                    <mat-form-field appearance="fill" class="selects100vw">
                        <mat-label>Formatos</mat-label>
                        <mat-select [(value)]="selectedFormato" #selectFormato formControlName="q10">
                
                            <mat-option *ngFor="let formato of formatos" [value]="formato">
                                {{formato}}</mat-option>
                            <mat-option value="outraOpcaoFormato" (click)="formMatriz.get('q10').setValue('')">Outra opção</mat-option>

                        </mat-select>
                    </mat-form-field>
                    <ng-container *ngIf="selectFormato.value == 'outraOpcaoFormato'">
                        <mat-form-field class="inputs">
                            <mat-label>Adicione uma nova opção</mat-label>
                            <input matInput placeholder="Ex. arquivo" value="" formControlName="q10">
                        </mat-form-field>
                    </ng-container>
                </div> -->
                <div class="form-group">
                    <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('FORMATOS')">
                        <mat-label>Formatos:</mat-label>
                        <input type="text" matInput placeholder="Digite para buscar opções"
                            autocomplete="new-responsavel" [matAutocomplete]="formatos" [formControl]="search"
                            autocomplete="off" (keyup)="setVar('q10')">
                        <mat-autocomplete #formatos="matAutocomplete">
                            <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                            <ng-container *ngIf="!isLoading">
                                <mat-option (click)="formMatriz.get('q10').setValue(formatos.name)"
                                    *ngFor="let formatos of filtered" [value]="formatos.name">
                                    <span>{{formatos.name}}</span>
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <!-- <div class="form-group">

                    <mat-form-field appearance="fill" class="selects100vw">
                        <mat-label>Estimativa de Quantidade de Folhas a Digitalizar</mat-label>
                        <mat-select [(value)]="selectedQuantidadeFolha" #selectQuantidadeFolha formControlName="q23">
                          
                            <mat-option *ngFor="let quantidadeFolha of quantidadeFolhas" [value]="quantidadeFolha">
                                {{quantidadeFolha}}</mat-option>
                            <mat-option value="outraOpcaoQuantidadeFolha" (click)="formMatriz.get('q23').setValue('')">Outra opção</mat-option>

                        </mat-select>
                    </mat-form-field>
                    <ng-container *ngIf="selectQuantidadeFolha.value == 'outraOpcaoQuantidadeFolha'">
                        <mat-form-field class="inputs">
                            <mat-label>Adicione uma nova opção</mat-label>
                            <input matInput placeholder="Ex. arquivo" value="" formControlName="q23">
                        </mat-form-field>
                    </ng-container>
                </div> -->
                <div class="form-group">
                    <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('QUANTIDADE_FOLHAS')">
                        <mat-label>Estimativa de quantidade de folhas a digitalizar:</mat-label>
                        <input type="text" matInput placeholder="Digite para buscar opções"
                            autocomplete="new-responsavel" [matAutocomplete]="qFolhas" [formControl]="search"
                            autocomplete="off" (keyup)="setVar('q23')">
                        <mat-autocomplete #qFolhas="matAutocomplete">
                            <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                            <ng-container *ngIf="!isLoading">
                                <mat-option (click)="formMatriz.get('q23').setValue(qFolhas.name)"
                                    *ngFor="let qFolhas of filtered" [value]="qFolhas.name">
                                    <span>{{qFolhas.name}}</span>
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field class="inputs">
                        <mat-label>Outras Formas de Nomear o Arquivo</mat-label>
                        <input matInput placeholder="Ex. arquivo" value="" formControlName="q34">
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field class="inputs">
                        <mat-label>Documentos Vinculados/Anexos</mat-label>
                        <input matInput placeholder="Ex. arquivo" value="" formControlName="q35">
                    </mat-form-field>
                </div>


                <div class="form-group">
                    <mat-form-field class="inputs">
                        <mat-label>Área que Criou o Documento</mat-label>
                        <input matInput placeholder="Ex. arquivo" value="" formControlName="q36">
                    </mat-form-field>
                </div>
                <div class="form-group d-flex">
                    <mat-checkbox color="warn" formControlName="q25" class="mr-2"></mat-checkbox>
                    <mat-label>Existe Documentos Arquivados na Área?</mat-label>
                </div>

                <div class="form-group">
                    <mat-form-field class="inputs">
                        <mat-label>Quantidade de Documentos Armazenados no Diretório</mat-label>
                        <input matInput placeholder="Ex. arquivo" value="" formControlName="q41">
                    </mat-form-field>
                </div>
                <div class="form-group d-flex">
                    <mat-checkbox color="warn" formControlName="q28" class="mr-2"></mat-checkbox>
                    <mat-label>Aplicar cor?</mat-label>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion>
            <mat-expansion-panel class="mt-1">
                <mat-expansion-panel-header>
                    <mat-panel-title>Paperless & Impressão de Documentos</mat-panel-title>
                    <mat-panel-description>
                        <mat-icon>local_printshop</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div class="form-group d-flex">
                    <mat-checkbox color="warn" formControlName="q26" class="mr-2"></mat-checkbox>
                    <mat-label>Esse Documento é Impresso?</mat-label>
                </div>


                <!-- <div class="form-group">

                                    <div class="form-group">
                                        <mat-form-field appearance="fill" class="selects100vw">
                                            <mat-label>Quantidade de Impressões/Cópias Mensal</mat-label>
                                            <mat-select [(value)]="selectedQuantidadeImpressao" #selectQuantidadeImpressao formControlName="q20">
                                       
                                                <mat-option *ngFor="let quantidadeImpresao of quantidadeImpressoes"
                                                    [value]="quantidadeImpresao">
                                                    {{quantidadeImpresao}}</mat-option>
                                                <mat-option value="outraOpcaoQuantidadeImpressao" (click)="formMatriz.get('q20').setValue('')">Outra opção</mat-option>
                
                                            </mat-select>
                                        </mat-form-field>
                                        <ng-container *ngIf="selectQuantidadeImpressao.value == 'outraOpcaoQuantidadeImpressao'">
                                            <mat-form-field class="inputs">
                                                <mat-label>Adicione uma nova opção</mat-label>
                                                <input matInput placeholder="Ex. arquivo" value="" formControlName="q20">
                                            </mat-form-field>
                                        </ng-container>
                                    </div>
                                </div> -->
                <div class="form-group">
                    <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('QUANTIDADE_COPIAS')">
                        <mat-label>Quantidade de Impressões/Cópias Mensal:</mat-label>
                        <input type="text" matInput placeholder="Digite para buscar opções"
                            autocomplete="new-responsavel" [matAutocomplete]="qCopias" [formControl]="search"
                            autocomplete="off" (keyup)="setVar('q20')">
                        <mat-autocomplete #qCopias="matAutocomplete">
                            <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                            <ng-container *ngIf="!isLoading">
                                <mat-option (click)="formMatriz.get('q20').setValue(qCopias.name)"
                                    *ngFor="let qCopias of filtered" [value]="qCopias.name">
                                    <span>{{qCopias.name}}</span>
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <!-- <div class="form-group">

                                    <mat-form-field appearance="fill" class="selects100vw">
                                        <mat-label>O Documento foi Digitalizado a partir de qual período?</mat-label>
                                        <mat-select [(value)]="selectedDocDigitalizado" #selectDocDigitalizado formControlName="q22">
                                        
                                            <mat-option *ngFor="let docDigitalizado of docsDigitalizado" [value]="docDigitalizado">
                                                {{docDigitalizado}}</mat-option>
                                            <mat-option value="outraOpcaoDocDigitalizado" (click)="formMatriz.get('q22').setValue('')">Outra opção</mat-option>
                
                                        </mat-select>
                                    </mat-form-field>
                                    <ng-container *ngIf="selectDocDigitalizado.value == 'outraOpcaoDocDigitalizado'">
                                        <mat-form-field class="inputs">
                                            <mat-label>Adicione uma nova opção</mat-label>
                                            <input matInput placeholder="Ex. arquivo" value="" formControlName="q22">
                                        </mat-form-field>
                                    </ng-container>
                                </div> -->
                <div class="form-group">
                    <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('PERIODO_DIGITALIZACAO')">
                        <mat-label>O documento foi digitalizado a partir de qual período?:</mat-label>
                        <input type="text" matInput placeholder="Digite para buscar opções"
                            autocomplete="new-responsavel" [matAutocomplete]="periodo" [formControl]="search"
                            autocomplete="off" (keyup)="setVar('q22')">
                        <mat-autocomplete #periodo="matAutocomplete">
                            <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                            <ng-container *ngIf="!isLoading">
                                <mat-option (click)="formMatriz.get('q22').setValue(periodo.name)"
                                    *ngFor="let periodo of filtered" [value]="periodo.name">
                                    <span>{{periodo.name}}</span>
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="form-group d-flex">
                    <mat-checkbox color="warn" formControlName="q27" class="mr-2"></mat-checkbox>
                    <mat-label>Sugere Digitalizar?</mat-label>
                </div>


            </mat-expansion-panel>
        </mat-accordion>


        <mat-accordion>
            <mat-expansion-panel class="mt-1">
                <mat-expansion-panel-header>
                    <mat-panel-title>Forma de Pesquisa/Recuperação</mat-panel-title>
                    <mat-panel-description>
                        <mat-icon>find_replace</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <!-- <div class="form-group">


                                <mat-form-field appearance="fill" class="selects100vw">
                                    <mat-label>Metadados/Indexadores</mat-label>
                                    <mat-select [(value)]="selectedMetadadosIndexador" #selectMetadadosIndexador formControlName="q21">
                                    
                                        <mat-option *ngFor="let metadadosIndexador of metadadosIndexadores"
                                            [value]="metadadosIndexador">
                                            {{metadadosIndexador}}</mat-option>
                                        <mat-option value="outraOpcaoMetadadosIndexador" (click)="formMatriz.get('q21').setValue('')">Outra opção</mat-option>
            
                                    </mat-select>
                                </mat-form-field>
                                <ng-container *ngIf="selectMetadadosIndexador.value == 'outraOpcaoMetadadosIndexador'">
                                    <mat-form-field class="inputs">
                                        <mat-label>Adicione uma nova opção</mat-label>
                                        <input matInput placeholder="Ex. arquivo" value="" formControlName="q21">
                                    </mat-form-field>
                                </ng-container>
                            </div> -->
                <div class="form-group">
                    <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('INDEXADORES')">
                        <mat-label>Metadados/Indexadores:</mat-label>
                        <input type="text" matInput placeholder="Digite para buscar opções"
                            autocomplete="new-responsavel" [matAutocomplete]="indexadores" [formControl]="search"
                            autocomplete="off" (keyup)="setVar('q21')">
                        <mat-autocomplete #indexadores="matAutocomplete">
                            <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                            <ng-container *ngIf="!isLoading">
                                <mat-option (click)="formMatriz.get('q21').setValue(indexadores.name)"
                                    *ngFor="let indexadores of filtered" [value]="indexadores.name">
                                    <span>{{indexadores.name}}</span>
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

            </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion>
            <mat-expansion-panel class="mt-1">
                <mat-expansion-panel-header>
                    <mat-panel-title>Tempo de Guarda, Descarte e Destinação Final</mat-panel-title>
                    <mat-panel-description>
                        <mat-icon>inventory_2</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <!-- 
                            <div class="form-group">

                                <mat-form-field appearance="fill" class="selects100vw">
                                    <mat-label>Tempo de Arquivamento na área</mat-label>
                                    <mat-select [(value)]="selectedTempoArquivoArea" #selectTempoArquivoArea formControlName="q17">
                                     
                                        <mat-option *ngFor="let tempoArquivadoArea of tempoArquivadosArea"
                                            [value]="tempoArquivadoArea">
                                            {{tempoArquivadoArea}}</mat-option>
                                        <mat-option value="outraOpcaoTempoArquivadoArea" (click)="formMatriz.get('q17').setValue('')">Outra opção</mat-option>
            
                                    </mat-select>
                                </mat-form-field>

                                <ng-container *ngIf="selectTempoArquivoArea.value == 'outraOpcaoTempoArquivadoArea'">
                                    <mat-form-field class="inputs">
                                        <mat-label>Adicione uma nova opção</mat-label>
                                        <input matInput placeholder="Ex. arquivo" value="" formControlName="q17">
                                    </mat-form-field>
                                </ng-container>
                            </div> -->
                <div class="form-group">
                    <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('TEMPO_AREA')">
                        <mat-label>Tempo de arquivamento na área:</mat-label>
                        <input type="text" matInput placeholder="Digite para buscar opções"
                            autocomplete="new-responsavel" [matAutocomplete]="tempoArea" [formControl]="search"
                            autocomplete="off" (keyup)="setVar('q17')">
                        <mat-autocomplete #tempoArea="matAutocomplete">
                            <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                            <ng-container *ngIf="!isLoading">
                                <mat-option (click)="formMatriz.get('q17').setValue(tempoArea.name)"
                                    *ngFor="let tempoArea of filtered" [value]="tempoArea.name">
                                    <span>{{tempoArea.name}}</span>
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <!-- <div class="form-group">
                    <mat-form-field appearance="fill" class="selects100vw">
                        <mat-label>Tempo de Arquivamento na Empresa de Guarda</mat-label>
                        <mat-select [(value)]="selectedTempoArquivo" #selectTempoArquivo formControlName="q18"> 
                            
                            <mat-option *ngFor="let tempoArquivado of tempoArquivados" [value]="tempoArquivado">
                                {{tempoArquivado}}</mat-option>
                            <mat-option value="outraOpcaoTempoArquivado" (click)="formMatriz.get('q18').setValue('')">Outra opção</mat-option>

                        </mat-select>
                    </mat-form-field>
                    <ng-container *ngIf="selectTempoArquivo.value == 'outraOpcaoTempoArquivado'">
                        <mat-form-field class="inputs">
                            <mat-label>Adicione uma nova opção</mat-label>
                            <input matInput placeholder="Ex. arquivo" value="" formControlName="q18">
                        </mat-form-field>
                    </ng-container>
                </div> -->
                <div class="form-group">
                    <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('TEMPO_GUARDA')">
                        <mat-label>Tempo de arquivamento na empresa de guarda:</mat-label>
                        <input type="text" matInput placeholder="Digite para buscar opções"
                            autocomplete="new-responsavel" [matAutocomplete]="tempoGuarda" [formControl]="search"
                            autocomplete="off" (keyup)="setVar('q18')">
                        <mat-autocomplete #tempoGuarda="matAutocomplete">
                            <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                            <ng-container *ngIf="!isLoading">
                                <mat-option (click)="formMatriz.get('q18').setValue(tempoGuarda.name)"
                                    *ngFor="let tempoGuarda of filtered" [value]="tempoGuarda.name">
                                    <span>{{tempoGuarda.name}}</span>
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <div class="form-group d-flex">
                    <mat-checkbox color="warn" formControlName="q29" class="mr-2"></mat-checkbox>
                    <mat-label>Possui planilha de Controle do Conteúdo das Caixas Enviadas para Empresa de
                        Guarda?</mat-label>
                </div>
                <!-- <div class="form-group">
                    <mat-form-field appearance="fill" class="selects100vw">
                        <mat-label>Destinação Final</mat-label>
                        <mat-select [(value)]="selectedDestinacaoFinal" #selectDestinacaoFinal formControlName="q19">
                            
                            <mat-option *ngFor="let destinacaoFinal of destinacoesFinais" [value]="destinacaoFinal">
                                {{destinacaoFinal}}</mat-option>
                            <mat-option value="outraOpcaoDestinacaoFinal" (click)="formMatriz.get('q19').setValue('')">Outra opção</mat-option>

                        </mat-select>
                    </mat-form-field>
                    <ng-container *ngIf="selectDestinacaoFinal.value == 'outraOpcaoDestinacaoFinal'">
                        <mat-form-field class="inputs">
                            <mat-label>Adicione uma nova opção</mat-label>
                            <input matInput placeholder="Ex. arquivo" value="" formControlName="q19">
                        </mat-form-field>
                    </ng-container>

                </div> -->
                <div class="form-group">
                    <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('DESTINACAO')">
                        <mat-label>Destinação final:</mat-label>
                        <input type="text" matInput placeholder="Digite para buscar opções"
                            autocomplete="new-responsavel" [matAutocomplete]="destinacao" [formControl]="search"
                            autocomplete="off" (keyup)="setVar('q19')">
                        <mat-autocomplete #destinacao="matAutocomplete">
                            <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                            <ng-container *ngIf="!isLoading">
                                <mat-option (click)="formMatriz.get('q19').setValue(destinacao.name)"
                                    *ngFor="let destinacao of filtered" [value]="destinacao.name">
                                    <span>{{destinacao.name}}</span>
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <!-- <div class="form-group">
                    <mat-form-field appearance="fill" class="selects100vw">
                        <mat-label>Se houver midias Anexos nos documentos (legado) arquivados na empresa de guarda
                            pode descartar?</mat-label>
                        <mat-select [(value)]="selectedMidiaAnexadoDocumento" #selectMidiaAnexadoDocumento formControlName="q5">
                       
                            <mat-option *ngFor="let midiaAnexadoDocumento of midiaAnexadoDocumentos"
                                [value]="midiaAnexadoDocumento" >
                                {{midiaAnexadoDocumento}}</mat-option>
                            <mat-option value="outraOpcaoMidiaAnexadoDocumento" (click)="formMatriz.get('q5').setValue('')">Outra opção</mat-option>

                        </mat-select>
                    </mat-form-field>
                    <ng-container *ngIf="selectMidiaAnexadoDocumento.value == 'outraOpcaoMidiaAnexadoDocumento'">
                        <mat-form-field class="inputs">
                            <mat-label>Adicione uma nova opção</mat-label>
                            <input matInput placeholder="Ex. arquivo" value="" formControlName="q5">
                        </mat-form-field>
                    </ng-container>
                </div> -->
                <div class="form-group">
                    <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('DESCARTE')">
                        <mat-label>Se houver midias anexos dos documentos (legado) arquivadas na empresa de guarda pode
                            descartar?:</mat-label>
                        <input type="text" matInput placeholder="Digite para buscar opções"
                            autocomplete="new-responsavel" [matAutocomplete]="descarte" [formControl]="search"
                            autocomplete="off" (keyup)="setVar('q5')">
                        <mat-autocomplete #descarte="matAutocomplete">
                            <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                            <ng-container *ngIf="!isLoading">
                                <mat-option (click)="formMatriz.get('q5').setValue(descarte.name)"
                                    *ngFor="let descarte of filtered" [value]="descarte.name">
                                    <span>{{descarte.name}}</span>
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field class="inputs">
                        <mat-label>Legislação Federal, Estadual e Municipal</mat-label>
                        <input matInput placeholder="Ex. arquivo" value="" formControlName="q38">
                    </mat-form-field>
                </div>
            </mat-expansion-panel>
        </mat-accordion>


        <mat-accordion>
            <mat-expansion-panel class="mt-1">
                <mat-expansion-panel-header>
                    <mat-panel-title>Compliance LGPD e Segurança da Informação</mat-panel-title>
                    <mat-panel-description>
                        <mat-icon>vpn_lock</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <!-- <div class="form-group">
                                <mat-form-field appearance="fill" class="selects100vw">
                                    <mat-label>Acesso</mat-label>
                                    <mat-select [(value)]="selectedAcesso" #selectAcesso formControlName="q7">
                                        
                                        <mat-option *ngFor="let acesso of acessos" [value]="acesso">
                                            {{acesso}}</mat-option>
                                        <mat-option value="outraOpcaoAcesso" (click)="formMatriz.get('q7').setValue('')">Outra opção</mat-option>
            
                                    </mat-select>
                                </mat-form-field>
                                <ng-container *ngIf="selectAcesso.value == 'outraOpcaoAcesso'">
                                    <mat-form-field class="inputs">
                                        <mat-label>Adicione uma nova opção</mat-label>
                                        <input matInput placeholder="Ex. arquivo" value="" formControlName="q7">
                                    </mat-form-field>
                                </ng-container>
                            </div> -->
                <div class="form-group">
                    <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('ACESSO')">
                        <mat-label>Acesso:</mat-label>
                        <input type="text" matInput placeholder="Digite para buscar opções"
                            autocomplete="new-responsavel" [matAutocomplete]="acesso" [formControl]="search"
                            autocomplete="off" (keyup)="setVar('q7')">
                        <mat-autocomplete #acesso="matAutocomplete">
                            <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                            <ng-container *ngIf="!isLoading">
                                <mat-option (click)="formMatriz.get('q7').setValue(acesso.name)"
                                    *ngFor="let acesso of filtered" [value]="acesso.name">
                                    <span>{{acesso.name}}</span>
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <div class="form-group d-flex">
                    <mat-checkbox color="warn" formControlName="q33" class="mr-2"></mat-checkbox>
                    <mat-label>É Utilizado como prova para Apoiar Contencioso?</mat-label>
                </div>
                <div class="form-group d-flex">
                    <mat-checkbox color="warn" formControlName="q32" class="mr-2"></mat-checkbox>
                    <mat-label>Atende Normativa de Agências regulatórias</mat-label>
                </div>
            </mat-expansion-panel>
        </mat-accordion>


        <!-- funcionando somente com button -->
        <!-- <button class="outraOpcao">
                        click-me
                    </button>

                    <mat-form-field class="hidden inputOutraOpcao">
                        <mat-label>Outras Formas de Nomear o Arquivo</mat-label>
                        <input matInput placeholder="Ex. arquivo" value="">
                    </mat-form-field> -->





        <div class="modal-footer mt-5">
            <button mat-button color="primary" (click)="inserirMatrizGD()"
                [disabled]="formMatriz.invalid">Enviar</button>
        </div>


    </mat-card>
</form>