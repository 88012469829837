import * as introJs from "intro.js/intro.js";
import { first } from "rxjs/operators";
import { AlertService } from "src/app/_services";

import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";

import { User } from "../_models";
import { AccountService } from "../_services";

// import * as $ from '../../assets/jquery-3.5.1.min';
// function hello() {
//   alert('Hello!!!');
// }
@Component({
  selector: "app-noticias",
  templateUrl: "./noticias.component.html",
  styleUrls: ["./noticias.component.css"],
})
export class NoticiasComponent implements OnInit {
  introJS = introJs();

  user: User;

  @ViewChild("myVideo") myVideo: ElementRef; // Prior to Angular 8
  // @ViewChild('myVideo', { static: true }) myVideo: ElementRef; => Angular 8
  constructor(
    private accountService: AccountService,
    private alertService: AlertService
  ) {
    // this.accountService.userValue;
    console.log(this.accountService.userValue.user.id);

    this.accountService.userObservable.subscribe((x) => (this.user = x.user));
  }

  ngOnInit(): void {
    // hello()
    // logica para carregar modal com vídeo antes de carregar sistema completo
    // let situacao = 'primeiroAcesso';
    // if (situacao == 'primeiroAcesso') {
    //   console.log('primeiro acesso');
    //   document.getElementById("openModalButton").click();
    // }
    // else {
    //   console.log('já acessou outras vezes');
    // }
    // exemplo feito pegando ID do user
    // obs: o document.getElementById("openModalButton").click(); ele abre o modal (vídeo) que fica "oculto" no HTML
    // if (this.user.firstAccess) {
    //   document.getElementById("openModalButton").click();
    //   console.log("Primeiro acesso");
    //   console.log(this.user);
    //   this.accountService
    //     .firstAccess(this.accountService.userValue.token, this.user.id, false)
    //     .pipe(first())
    //     .subscribe(
    //       (data) => {},
    //       (error) => {
    //         this.alertService.error(error);
    //         //this.loading = false;
    //       }
    //     );
    //   //@GetMapping(value = "/update-first-access/{userId}/{value}")
    //   //passando false;
    // } else {
    //   console.log("Mais de um acesso");
    // }
  }

  playVideo() {
    /**
     * You are accessing a dom element directly here,
     * so you need to call "nativeElement" first.
     */
    this.myVideo.nativeElement.play();
  }

  pauseVideo() {
    /**
     * You are accessing a dom element directly here,
     * so you need to call "nativeElement" first.
     */
    this.myVideo.nativeElement.pause();
  }

  // carrega a uma ação de um botão
  startSteps(): void {
    this.introJS
      .setOptions({
        steps: [
          {
            // As you can see, thanks to the element ID
            // I can set a step in an element of an other component
            element: "#step-conteudos",
            intro: "Acessando área de conteúdos",
          },
          {
            element: "#step1",
            intro: "Titulo",
            position: "bottom",
          },
          {
            element: "#step-inicio",
            intro: "Acessando ancora do sidebar menu inicio",
            position: "right",
          },
          {
            element: "#step-acompanhar-solicitacoes",
            intro: "Acompnhando solicitações",
            position: "top",
          },
          // {
          //   element: '#step4-li',
          //   intro: 'More features, more fun.',
          //   position: 'right'
          // }
        ],
        hidePrev: true,
        hideNext: false,
      })
      .start();
  }
}
