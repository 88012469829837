import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EscolhaAgendamento } from '../_models/escolha-agendamento';
import { OpcoesAgendamento } from '../_models/opcoes-agendamento';

@Injectable({
    providedIn: 'root'
  })

export class OpcoesAgendamentoService{

    constructor(private http: HttpClient) { } 


    sendDataList(opcoesDataList:OpcoesAgendamento, id){
        return this.http.post<OpcoesAgendamento>(`${environment.apiUrl}/agendamento-entrevista/add-opcao-date/${id}`, opcoesDataList);
    }
    saveDefinicaoData(definicaoData, id){
        return this.http.post<OpcoesAgendamento>(`${environment.apiUrl}/agendamento-entrevista/definicao-data/${id}`, definicaoData);
    }
    sendDataEscolhida(dataEscolhida, id){
        return this.http.get<any>(`${environment.apiUrl}/agendamento-entrevista/escolha-data/${id}/${dataEscolhida}`);
    }
    // getAllAvailableDate(dateRange){
    //     return this.http.post<any>(`${environment.apiUrl}/agendamento-entrevista`,dateRange)
    // }

}