<!-- <div class="alert alert-danger alert-dismissible fade show mt-4" role="alert">
  <strong>O usuário declarou</strong>
  que não há insumo nesta competência
  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div> -->

<h1 class="text-muted mt-4 d-flex align-items-center">
  Visualizar Insumo <span class="text-muted"> - {{ insumo.id }} </span>
  <mat-icon
    class="text-primary ml-1"
    [matTooltip]="getTooltipForInsumo(insumo)"
    matTooltipPosition="right"
  >
    info
  </mat-icon>
</h1>

<div
  class="loading-modal"
  *ngIf="isLoadingAnexo"
  (click)="cancelarRequisicao()"
>
  <div class="loading-modal-content">
    <div class="loading-modal-description">
      <div class="spinner"></div>
      <span> Aguarde... </span>
      <p class="text-muted">O seu documento está sendo enviado.</p>
    </div>
    <div class="loading-modal-footer">
      <button (click)="(cancelarRequisicao)">Cancelar</button>
    </div>
  </div>
</div>

<div class="example-loading-shade" *ngIf="isLoadingResults">
  <mat-spinner class="loading-shade"></mat-spinner>
</div>
<div *ngIf="!isLoadingResults">
  <div class="col-sm-12 mb-4">
    <div class="row">
      <div class="col-sm-4">
        <div class="row">
          <div class="col-sm-11 bg-white p-4">
            <div class="row">
              <div class="col-sm-12">
                <p class="text-muted">Criador do Insumo</p>
              </div>

              <div class="col-sm-2 icon-user-flex">
                <mat-icon class="mat-user-icon">account_circle</mat-icon>
              </div>

              <div class="col-sm-10">
                <p class="text-muted mb-0">
                  {{ insumo.creator.fullName }} -
                  <span class="text-primary">
                    {{ insumo.creator.userType | cap }}
                  </span>
                </p>
                <p class="text-muted m-0">{{ insumo.creator.email }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="row">
          <div class="col-sm-11 bg-white p-4">
            <div class="row">
              <div class="col-sm-12">
                <p class="text-muted">Responsável pelo Insumo</p>
              </div>

              <div class="col-sm-2 icon-user-flex">
                <mat-icon class="mat-user-icon">account_circle</mat-icon>
              </div>

              <div class="col-sm-10">
                <p class="text-muted mb-0">
                  {{ insumo.responsible.fullName }} -
                  <span class="text-primary">{{
                    insumo.responsible.userType | cap
                  }}</span>
                </p>
                <p class="text-muted m-0">
                  {{ insumo.responsible.email }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="row">
          <div class="col-sm-12 bg-white p-4">
            <div class="row">
              <div class="col-sm-12">
                <p class="text-muted">Responsável pela Análise</p>
              </div>
              <div class="col-sm-2 icon-user-flex">
                <mat-icon class="mat-user-icon">account_circle</mat-icon>
              </div>
              <div class="col-sm-10">
                <p class="text-muted mb-0">
                  {{ insumo.analysis.fullName }} -
                  <span class="text-primary">{{
                    insumo.analysis.userType | cap
                  }}</span>
                </p>
                <p class="text-muted m-0">{{ insumo.analysis.email }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-12 mb-4">
    <div class="row">
      <h1 class="text-muted w-100">Detalhes do Insumo</h1>
      <div class="col-sm-12 bg-white">
        <div class="row">
          <div class="col-sm-3 p-4">
            <div class="mb-2">
              <div class="button-flex">
                <mat-icon class="text-primary">post_add</mat-icon>
                <p class="txt-title">Nome do Insumo</p>
              </div>
              <p class="txt-req">{{ insumo.name }}</p>
            </div>
            <div class="mb-2">
              <div class="button-flex">
                <mat-icon class="text-primary">group</mat-icon>
                <p class="txt-title">Público</p>
              </div>
              <p class="txt-req">{{ insumo.publico }}</p>
            </div>
            <div class="mb-2">
              <div class="button-flex">
                <mat-icon class="text-primary">category</mat-icon>
                <p class="txt-title">Segmento</p>
              </div>
              <p class="txt-req">{{ insumo.segment }}</p>
            </div>
            <div class="mb-2">
              <div class="button-flex">
                <mat-icon class="text-primary">format_quote</mat-icon>
                <p class="txt-title">Observações</p>
              </div>
              <p class="txt-req">{{ insumo.observations }}</p>
            </div>
          </div>
          <div class="col-sm-3 p-4">
            <div class="mb-2">
              <div class="button-flex">
                <mat-icon class="text-primary">calendar_month</mat-icon>
                <p class="txt-title">Competência</p>
              </div>
              <p class="txt-req">{{ insumo.competence | competenceDate }}</p>
            </div>
            <div class="mb-2">
              <div class="button-flex">
                <mat-icon class="text-primary">calendar_month</mat-icon>
                <p class="txt-title">
                  Data Limite do Insumo atual ({{ utilDaysFormat(insumo) }})
                </p>
              </div>
              <p class="txt-req">{{ insumo.deadline }}</p>
            </div>
            <!-- <div class="mb-2">
              <div class="button-flex">
                <mat-icon class="text-primary">calendar_month</mat-icon>
                <p class="txt-title">
                  Data Limite dos Insumos Filhos ({{
                    utilDaysFormatSon(insumo)
                  }})
                </p>
              </div>
              <p class="txt-req">{{ insumo.actualDeadline }}</p>
            </div> -->
            <div class="mb-2">
              <div class="button-flex">
                <mat-icon class="text-primary">loop</mat-icon>
                <p class="txt-title">Periodicidade</p>
              </div>
              <p class="txt-req">
                {{ insumo.frequency | cap }}
              </p>
            </div>
            <div class="mb-2">
              <div class="button-flex">
                <mat-icon class="text-primary">calendar_month</mat-icon>
                <p class="txt-title">Data de Entrega</p>
              </div>
              <p class="txt-req">
                {{ insumo.attachments | deliveryDate }}
              </p>
            </div>
          </div>
          <div class="col-sm-3 p-4">
            <div class="mb-2">
              <div class="button-flex">
                <mat-icon class="text-primary">format_list_bulleted</mat-icon>
                <p class="txt-title">Tipo do Insumo</p>
              </div>
              <p class="txt-req">{{ insumo.type }}</p>
            </div>
            <div class="mb-2">
              <div class="button-flex">
                <mat-icon class="text-primary">area_chart</mat-icon>
                <p class="txt-title">Área do Insumo</p>
              </div>
              <p class="txt-req">{{ insumo.area }}</p>
            </div>
            <div *ngIf="insumo.acceptedDocs" class="mb-2">
              <div class="button-flex">
                <mat-icon class="text-primary">content_copy</mat-icon>
                <p class="txt-title">Documentos Aceitos</p>
              </div>
              <p class="txt-req">
                {{ insumo.acceptedDocs }}
              </p>
            </div>
            <div *ngIf="insumo.numberSd" class="mb-2">
              <p class="txt-title">Número SD</p>
              <p class="txt-req">{{ insumo.numberSd }}</p>
            </div>
          </div>
          <div class="col-sm-3 p-4">
            <div class="mb-2">
              <div class="button-flex">
                <mat-icon class="text-primary">task_alt</mat-icon>
                <p class="txt-title">Status da Competência</p>
              </div>
              <p class="txt-req">{{ insumo.competenceStatus }}</p>
            </div>
            <div class="mb-2">
              <div class="button-flex">
                <mat-icon class="text-primary">task_alt</mat-icon>
                <p class="txt-title">Status</p>
              </div>
              <p class="txt-req">{{ insumo.status }}</p>
            </div>
            <div class="mb-2">
              <div class="button-flex">
                <mat-icon class="text-primary">copy_all</mat-icon>
                <p class="txt-title">Versão</p>
              </div>
              <p class="txt-req">{{ insumo.version }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="col-sm-12 mb-4"
    *ngIf="
      user.userType !== 'EQUIPE_INTERNA' ||
      user.email === insumo.responsible.email ||
      user.email === insumo.analysis.email ||
      validatorCheck(insumo)
    "
  >
    <div class="row">
      <h1 class="text-muted w-100">Ações</h1>
      <button
        matSuffix
        matTooltip="Histórico"
        data-toggle="modal"
        data-target="#historico"
        class="btn btn-sm btn-dark mr-2"
      >
        <div class="btn-mat-icon">
          <mat-icon class="mr-1">history</mat-icon>
          Histórico
        </div>
      </button>

      <button
        [hidden]="editPermission()"
        matTooltip="Alterar Responsavel"
        data-toggle="modal"
        data-target="#update"
        class="btn btn-sm btn-success mr-2"
        (click)="actualInsumoId = insumo.id"
      >
        <div class="btn-mat-icon">
          <mat-icon class="mr-1">account_circle</mat-icon>
          Alterar Responsável
        </div>
      </button>

      <button
        [hidden]="editPermission()"
        matTooltip="Alterar Periodicidade"
        data-toggle="modal"
        data-target="#frequency"
        class="btn btn-sm btn-primary mr-2"
        (click)="actualInsumoId = insumo.id"
      >
        <div class="btn-mat-icon">
          <mat-icon class="mr-1">loop</mat-icon>
          Alterar Periodicidade
        </div>
      </button>

      <button
        [hidden]="editPermission()"
        matTooltip="Atualizar Insumo"
        data-toggle="modal"
        data-target="#updateInsumo"
        class="btn btn-sm btn-secondary mr-2"
        (click)="populateInsumoUpdateModal(); actualInsumoId = insumo.id"
      >
        <div class="btn-mat-icon">
          <mat-icon class="mr-1">settings</mat-icon>
          Mais configurações
        </div>
      </button>

      <button
        *ngIf="
          user.profile === 'ROLE_ADMIN' ||
          user.userType === 'OPERACAO_BPO' ||
          user.userType === 'EQUIPE_INTERNA'
        "
        matSuffix
        matTooltip="Histórico"
        (click)="actualInsumoId = insumo.id"
        data-toggle="modal"
        data-target="#numerosd"
        class="btn btn-sm btn-info mr-2"
        [disabled]="insumo.numberSd"
      >
        <div class="btn-mat-icon">
          <mat-icon class="mr-1">pin</mat-icon>
          Adicionar Número SD
        </div>
      </button>

      <button
        [hidden]="onChangeInsumoDeadline()"
        matTooltip="Data Limite do Insumo"
        data-toggle="modal"
        data-target="#dataLimite"
        class="btn btn-sm btn-warning mr-2"
        (click)="actualInsumoId = insumo.id"
      >
        <div class="btn-mat-icon">
          <mat-icon class="mr-1">calendar_month</mat-icon>
          Data Limite do Insumo
        </div>
      </button>

      <button
        [hidden]="insumo.competenceStatus === 'ENCERRADO'"
        *ngIf="
          insumo.creator.email === user.email ||
          insumo.analysis.email === user.email ||
          user.userType === 'OPERACAO_BPO' ||
          user.userType === 'EQUIPE_INTERNA'
        "
        [disabled]="insumo.status === 'INATIVO'"
        matTooltip="Encerrar"
        data-toggle="modal"
        data-target="#excluir"
        class="btn btn-sm btn-danger mr-2"
        (click)="tempInsumoId = insumo.id"
      >
        <div class="btn-mat-icon">
          <mat-icon class="mr-1">highlight_off</mat-icon>
          Encerrar
        </div>
      </button>

      <button
        [disabled]="insumo.competenceStatus === 'ENCERRADO'"
        [hidden]="editPermission(insumo.responsible.email)"
        class="btn btn-sm mr-2"
        [ngClass]="insumo.status !== 'INATIVO' ? 'btn-danger' : 'btn-success'"
        (click)="desativarInsumo(insumo.id)"
      >
        <div class="btn-mat-icon">
          <mat-icon class="mr-1">
            {{ insumo.status !== "INATIVO" ? "cancel" : "check_circle" }}
          </mat-icon>
          {{ insumo.status !== "INATIVO" ? "Inativar" : "Ativar" }}
        </div>
      </button>
    </div>
  </div>

  <div class="col-sm-12 mb-4">
    <div class="row">
      <h1 class="text-muted w-100">Anexos</h1>
      <div class="col-sm-12 bg-white py-4">
        <div
          class="alert alert-danger alert-dismissible fade show"
          role="alert"
          *ngIf="!checkBox.checked"
        >
          <strong>O usuário declarou</strong>
          que não há insumo nesta competência
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="w-100 mb-4">
          <table
            mat-table
            [dataSource]="insumo.attachments.reverse()"
            multiTemplateDataRows
            class="mat-elevation-z8"
          >
            <ng-container matColumnDef="idArquivo">
              <th mat-header-cell *matHeaderCellDef>Id. Arquivo</th>
              <td mat-cell *matCellDef="let element">
                {{ element.id }}
              </td>
            </ng-container>

            <ng-container matColumnDef="Justificativa">
              <th mat-header-cell *matHeaderCellDef>Justificativa:</th>
              <td mat-cell *matCellDef="let element">
                {{ element.justify }}
              </td>
            </ng-container>

            <ng-container matColumnDef="nomeArquivo">
              <th mat-header-cell *matHeaderCellDef>Nome Arquivo</th>
              <td mat-cell *matCellDef="let element">
                {{ element.originalFileName }}
              </td>
            </ng-container>

            <ng-container matColumnDef="dataEHoraEnvio">
              <th mat-header-cell *matHeaderCellDef>Data e Hora de Envio</th>
              <td mat-cell *matCellDef="let element">
                {{ element.createdAt }}
              </td>
            </ng-container>

            <ng-container matColumnDef="versao">
              <th mat-header-cell *matHeaderCellDef>Versão</th>
              <td mat-cell *matCellDef="let element">v{{ element.version }}</td>
            </ng-container>

            <ng-container matColumnDef="acoes">
              <th mat-header-cell *matHeaderCellDef>Ações</th>
              <td mat-cell *matCellDef="let element">
                <button
                  class="btn btn-sm btn-warning mr-2"
                  (click)="downloadFileNotification(element.id)"
                >
                  <mat-icon>download</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsInterno"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumnsInterno"
            ></tr>
          </table>
        </div>

        <div class="row mb-2">
          <div *ngIf="!insumo.hasInsumo" class="col-sm-12">
            <div class="mb-2">
              <strong class="text-muted text-small">
                Justificativa de Não Possuir Insumos:
              </strong>
              <h4 class="m-0">{{ insumo.hasInsumoJustify }}</h4>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="row">
              <mat-slide-toggle
                [(ngModel)]="insumo.hasInsumo"
                (change)="openHasInsumoModal($event)"
                class="col-sm-12"
                #checkBox
              >
                {{
                  insumo.hasInsumo ? "Possui Insumos." : "Não Possui Insumos."
                }}
              </mat-slide-toggle>
            </div>
            <div class="row">
              <mat-slide-toggle
                *ngIf="
                  user.userType === 'OPERACAO_BPO' &&
                  insumo.competenceStatus === 'ENCERRADO'
                "
                [(ngModel)]="insumo.allowNewUpload"
                (change)="changeAllowNewUpload()"
                class="col-sm-12"
              >
                Permitir Upload depois de Encerrado.
              </mat-slide-toggle>
            </div>

            <button
              [hidden]="
                user.email !== insumo.responsible.email &&
                !validatorCheck(insumo)
              "
              [disabled]="disableUploadButton(insumo)"
              (click)="uploadAnexoInsumo(insumo)"
              class="btn btn-sm btn-primary mr-3 mt-3"
              data-toggle="modal"
              data-target="#confirmDoc"
            >
              <div class="button-flex">
                <mat-icon>attach_file</mat-icon>
                Anexar Insumo
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-sm-12 mb-4">
    <div class="row">
      <h1 class="text-muted w-100">Validadores</h1>
      <div class="col-sm-12 bg-white py-4">
        <div class="w-100 mb-4">
          <table
            mat-table
            [dataSource]="insumo.validators"
            multiTemplateDataRows
            class="mat-elevation-z8"
          >
            <ng-container matColumnDef="userName">
              <th mat-header-cell *matHeaderCellDef>Nome do Usuário</th>
              <td mat-cell *matCellDef="let element">
                {{ element | fullName }}
              </td>
            </ng-container>

            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef>Email:</th>
              <td mat-cell *matCellDef="let element">
                {{ element.email }}
              </td>
            </ng-container>

            <ng-container matColumnDef="userType">
              <th mat-header-cell *matHeaderCellDef>Tipo de Usuário</th>
              <td mat-cell *matCellDef="let element">
                {{ element.userType | cap }}
              </td>
            </ng-container>

            <ng-container matColumnDef="acoes">
              <th mat-header-cell *matHeaderCellDef>Ações</th>
              <td mat-cell *matCellDef="let element">
                <button
                  class="btn btn-sm btn-danger mr-2"
                  *ngIf="isOperador()"
                  (click)="removeValidators(element.email)"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumnsValidators"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumnsValidators"
            ></tr>
          </table>
        </div>

        <div class="row mb-2">
          <div class="col-sm-12">
            <button
              [disabled]="
                insumo.status === 'INATIVO' || insumo.status === 'ENCERRADO'
              "
              *ngIf="isOperador()"
              (click)="uploadAnexoInsumo(insumo)"
              class="btn btn-sm btn-success mr-3 mt-3"
              data-toggle="modal"
              data-target="#validators"
            >
              <div class="button-flex">
                <mat-icon>verified_user</mat-icon>
                Adicionar Validador
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="confirmDoc">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title mb-0 text-muted" id="TituloModalCentralizado">
            Formulário de envio de Insumo
          </p>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Fechar"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form [formGroup]="anexoInsumo" *ngIf="!(actualInsumoLength <= 0)">
          <div class="modal-body">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Justificativa:</mat-label>
              <input
                matInput
                type="text"
                formControlName="justify"
                maxlength="300"
                [ngClass]="{
                  'is-invalid': submitted && anexoInsumo.controls.justify.errors
                }"
              />
              <div
                *ngIf="submitted && anexoInsumo.controls.justify.errors"
                class="invalid-feedback"
              >
                <div *ngIf="anexoInsumo.controls.justify.errors.required">
                  É necessário adicionar a Justificativa
                </div>
                <div *ngIf="anexoInsumo.controls.justify.errors.minlength">
                  Digite ao menos 3 caracteres
                </div>
              </div>
            </mat-form-field>
          </div>
        </form>

        <mat-card-content>
          <div class="row">
            <div class="col-lg-6" style="align-self: left">
              <ul>
                <li
                  *ngFor="let anexo of attachments"
                  style="
                    line-height: 16px;
                    padding: 20px 10px;
                    white-space: nowrap;
                    overflow: hidden;
                    height: 60px;
                  "
                >
                  {{ anexo.originalFileName }}
                </li>
              </ul>
            </div>
            <div class="col-lg-3" style="align-self: right">
              <ul>
                <li
                  *ngFor="let arquivo of files"
                  style="padding: 20px 10px; line-height: 16px; height: 60px"
                >
                  {{ arquivo.progress }}
                </li>
              </ul>
            </div>
            <div class="col-lg-3" style="align-self: right">
              <ul>
                <li
                  *ngFor="let anexo of attachments"
                  style="padding: 13px 10px; height: 60px"
                >
                  <span
                    class="btn btn-sm mr-2 btn-danger"
                    (click)="removeAnexoFormPreUpload(anexo)"
                  >
                    <mat-icon>close</mat-icon>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-12" style="align-self: center">
            <ul>
              <li *ngIf="files.length > 0" style="margin: 30xp 0">
                <mat-progress-bar [value]="somArray(files)"></mat-progress-bar>
                <span id="file-label"></span>
              </li>
            </ul>
          </div>
        </mat-card-content>

        <div class="d-flex flex-column">
          <div class="mt-4 pb-4 pl-3 pr-3">
            <div class="form-group">
              <div class="input-group">
                <div class="custom-file">
                  <input
                    type="file"
                    #fileUpload
                    id="fileUpload"
                    name="fileUpload"
                    multiple="multiple"
                    [accept]="insumo.acceptedDocs"
                    class="custom-file-input"
                    (click)="onUploadButtonClick()"
                  />
                  <label class="custom-file-label" for="anexarDocumento">{{
                    attachments[0]?.originalFileName == null
                      ? "Anexar arquivo"
                      : attachments[0]?.originalFileName
                  }}</label>
                </div>
              </div>
              <span
                style="
                  color: #dc3545;
                  display: block;
                  margin-top: 10px;
                  margin-left: 3px;
                "
              >
                {{ messageErrorUpload }}
              </span>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="submit"
            (click)="onSubmit()"
            [disabled]="uploadLoading || attachments.length <= 0"
            class="mb-2 btn btn-success text-white mr-2 button-flex-table"
          >
            {{ uploadLoading ? "Aguarde..." : "Enviar" }}
          </button>
          <button
            #closeConfirmDoc
            [hidden]="true"
            type="button"
            data-dismiss="modal"
          ></button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="confirmUploadModal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title mb-0" id="TituloModalCentralizado">
            Upload de Insumo
          </p>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Fechar"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div class="mt-4 pb-4">
            <h3 class="text-center text-muted mt-4">
              Upload de insumo realizado com sucesso!
            </h3>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-dark btn-sm"
            data-dismiss="modal"
          >
            Fechar
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="excluir">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title mb-0" id="TituloModalCentralizado">
            Encerrar Insumo
          </p>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Fechar"
            #closeModalExcluir
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Deseja Realmente Encerrar o Insumo?</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn-sm btn btn-dark mr-2 btn-mat-icon"
            data-dismiss="modal"
          >
            <mat-icon class="">close</mat-icon>
            Cancelar
          </button>
          <button
            type="submit"
            class="btn-sm btn btn-success btn-mat-icon"
            (click)="encerrarInsumo()"
          >
            <mat-icon class="">done</mat-icon>
            Sim
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="frequency">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title mb-0" id="TituloModalCentralizado">
            Alterar a Periodicidade
          </p>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Fechar"
            #closeFrequencyModal
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="mt-4 pb-4">
            <div class="w-100 d-flex justify-content-center">
              <mat-icon class="text-muted" style="font-size: 30pt !important">
                loop
              </mat-icon>
            </div>
            <h3 class="text-center text-muted mt-4">
              Alterar Periodicidade do Insumo
              {{ insumo.id }}?
            </h3>
            <p class="text-center text-muted"></p>
          </div>
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Periodicidade:</mat-label>
            <mat-select [formControl]="frequency" required>
              <mat-option
                *ngFor="let frequencyField of frequencyOptions"
                [value]="frequencyField"
              >
                {{ frequencyField }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- <mat-form-field appearance="fill" class="w-100">
              <mat-label>Data Limite:</mat-label>
              <input matInput formControlName="deadline" [matDatepicker]="pickerDataLimite" />
              <mat-datepicker-toggle matSuffix [for]="pickerDataLimite"></mat-datepicker-toggle>
              <mat-datepicker #pickerDataLimite></mat-datepicker>
            </mat-form-field> -->
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn-sm btn btn-dark mr-2 btn-mat-icon"
            data-dismiss="modal"
          >
            <mat-icon class="">close</mat-icon>
            Cancelar
          </button>
          <button
            type="button"
            class="btn-sm btn btn-primary btn-mat-icon"
            (click)="updateFrequency()"
            [disabled]="frequency.invalid"
          >
            <mat-icon class="">done</mat-icon>
            Atualizar
          </button>
        </div>
      </div>
    </div>
  </div>

  <form [formGroup]="formUpdate" (ngSubmit)="onSubmitUpdate()">
    <div class="modal fade" id="update">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <p class="modal-title mb-0" id="TituloModalCentralizado">
              Atualizar Responsavel
            </p>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Fechar"
              #closeModalAlterarResponsavel
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Email Responsável do upload do insumo: </mat-label>
              <input
                type="text"
                matInput
                autocomplete="new-responsavel"
                aria-label="Email do(a) Responsável"
                [matAutocomplete]="auto1"
                [formControl]="searchUserCtrl1"
                required
                autocomplete="off"
                (click)="resetField(1)"
              />
              <mat-autocomplete #auto1="matAutocomplete">
                <mat-option *ngIf="isLoading" class="is-loading">
                  Carregando...
                </mat-option>
                <ng-container *ngIf="!isLoading">
                  <mat-option
                    *ngFor="let user of filteredUsers1"
                    [value]="user.email"
                    (click)="setEmailResponsibleUpload(user)"
                  >
                    <span>{{ user.email }}</span>
                  </mat-option>
                </ng-container>
              </mat-autocomplete>
            </mat-form-field>

            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Email Responsável pela análise do insumo: </mat-label>
              <input
                type="text"
                matInput
                autocomplete="new-responsavel"
                aria-label="Email do(a) Responsável"
                [matAutocomplete]="auto2"
                [formControl]="searchUserCtrl2"
                required
                autocomplete="off"
                (click)="resetField(2)"
              />
              <mat-autocomplete #auto2="matAutocomplete">
                <mat-option *ngIf="isLoading" class="is-loading"
                  >Carregando...</mat-option
                >
                <ng-container *ngIf="!isLoading">
                  <mat-option
                    *ngFor="let user of filteredUsers2"
                    [value]="user.email"
                    (click)="setEmailAnalysisUpload(user)"
                  >
                    <span>{{ user.email }}</span>
                  </mat-option>
                </ng-container>
              </mat-autocomplete>
              <!-- <input matInput type="email" placeholder="Insira o Email" formControlName="analysisUpload" [ngClass]="{
                  'is-invalid': submitted && formUp.analysisUpload.errors
                }" required />
              <div *ngIf="submitted && formUp.analysisUpload.errors" class="invalid-feedback">
                <div *ngIf="formUp.analysisUpload.errors.required">
                  É necessário adicionar o Email
                </div>
              </div> -->
            </mat-form-field>

            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Justificativa:</mat-label>
              <input
                matInput
                type="text"
                formControlName="justify"
                maxlength="300"
                required
              />
            </mat-form-field>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn-sm btn btn-dark mr-2 btn-mat-icon"
              data-dismiss="modal"
            >
              <mat-icon class="">close</mat-icon>
              Cancelar
            </button>
            <button
              type="submit"
              class="btn-sm btn btn-success btn-mat-icon"
              [disabled]="formUpdate.invalid"
            >
              <mat-icon class="">done</mat-icon>
              Atualizar
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="modal fade" id="updateInsumo">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title mb-0" id="TituloModalCentralizado">
            Mais configurações
          </p>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            #closeModalUpdateInsumo
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form [formGroup]="insumoUpdate" (ngSubmit)="onSubmitConfig()">
          <div class="modal-body">
            <mat-form-field class="w-100" appearance="fill">
              <mat-label>Nome do Insumo:</mat-label>
              <input matInput type="text" formControlName="name" required />
            </mat-form-field>

            <mat-form-field class="w-100" appearance="fill">
              <mat-label>Segmento:</mat-label>
              <mat-select formControlName="segment" required>
                <mat-option
                  *ngFor="let segment of segmentoOptions"
                  [value]="segment.fieldDescription"
                >
                  {{ segment.fieldDescription }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="w-100" appearance="fill">
              <mat-label>Público:</mat-label>
              <mat-select formControlName="publico" required>
                <mat-option
                  *ngFor="let publico of publicoOptions"
                  [value]="publico.fieldDescription"
                >
                  {{ publico.fieldDescription }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="w-100" appearance="fill">
              <mat-label>Área:</mat-label>
              <mat-select formControlName="area" required>
                <mat-option
                  *ngFor="let area of areaOptions"
                  [value]="area.fieldDescription"
                >
                  {{ area.fieldDescription }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="w-100" appearance="fill">
              <mat-label>Tipo de Insumo: </mat-label>
              <mat-select formControlName="insumoType" required>
                <mat-option
                  *ngFor="let insumoType of tipoDeInsumoOptions"
                  [value]="insumoType.fieldDescription"
                >
                  {{ insumoType.fieldDescription }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="w-100" appearance="fill">
              <mat-label>Observações:</mat-label>
              <textarea
                matInput
                type="text"
                formControlName="observations"
                required
              ></textarea>
            </mat-form-field>

            <competence-input
              [competenceValue]="this.insumoCompetenceDate"
              (competenceValueConverted)="setCompetenceFormValue($event)"
            ></competence-input>

            <mat-label class="text-muted mr-auto">
              Documentos Aceitos:
            </mat-label>
            <div style="margin: 10px 0" formGroupName="docTypeCheckboxGroup">
              <mat-checkbox class="w-100" formControlName="docTypeCheck1">
                XLS
              </mat-checkbox>
              <mat-checkbox class="w-100" formControlName="docTypeCheck2">
                XLSX
              </mat-checkbox>
              <mat-checkbox class="w-100" formControlName="docTypeCheck3">
                CSV
              </mat-checkbox>
              <mat-checkbox class="w-100" formControlName="docTypeCheck4">
                PDF
              </mat-checkbox>
              <mat-checkbox class="w-100" formControlName="docTypeCheck5">
                Todos
              </mat-checkbox>
            </div>

            <mat-form-field class="w-100" appearance="fill">
              <mat-label>Quantidade de Insumos permitidos: </mat-label>
              <input
                matInput
                type="number"
                appNumbersOnly
                formControlName="insumoAmount"
              />
            </mat-form-field>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn-sm btn btn-dark mr-2 btn-mat-icon"
              data-dismiss="modal"
            >
              <mat-icon>close</mat-icon>
              Cancelar
            </button>
            <button
              class="btn-sm btn btn-success btn-mat-icon"
              type="submit"
              [disabled]="insumoUpdate.invalid"
            >
              <mat-icon>done</mat-icon>
              Atualizar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="modal fade" id="validators">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title mb-0" id="TituloModalCentralizado">
            Adicionar Validadores
          </p>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Fechar"
            #closeModalAlterarResponsavel
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Adicionar Validadores</mat-label>
            <mat-chip-list #chipList aria-label="Seleção de Validadores">
              <mat-chip
                *ngFor="let validator of validators"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="remove(validator)"
              >
                {{ validator }}
                <button
                  type="button"
                  style="
                    border-radius: 100px;
                    outline: none;
                    border: none;
                    display: flex;
                    padding: 0;
                    background-color: transparent;
                    color: gray;
                  "
                  *ngIf="removable"
                >
                  <mat-icon matChipRemove>cancel</mat-icon>
                </button>
              </mat-chip>
              <input
                placeholder="Email do Validador"
                #validatorInput
                [formControl]="validatorCtrl"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="add($event)"
              />
            </mat-chip-list>
            <mat-autocomplete
              #auto="matAutocomplete"
              (optionSelected)="selected($event)"
            >
              <mat-option
                *ngFor="let validator of allValidators"
                [value]="validator.email"
              >
                {{ validator.email }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn-sm btn btn-dark mr-2 btn-mat-icon"
            data-dismiss="modal"
          >
            <mat-icon class="">close</mat-icon>
            Cancelar
          </button>
          <button
            type="submit"
            class="btn-sm btn btn-success btn-mat-icon"
            [disabled]="validatorCtrl.invalid && validators.length === 0"
            (click)="addValidators()"
            data-dismiss="modal"
          >
            <mat-icon class="">done</mat-icon>
            Atualizar
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="dataLimite">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content" style="border: none">
        <div class="modal-header">
          <p class="modal-title mb-0" id="TituloModalCentralizado">
            Atualizar Data Limite
          </p>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Fechar"
            #closeModalAlterarDeadLine
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div *ngIf="isInsumoSon == true">
          <form [formGroup]="formDeadLine" (ngSubmit)="onSubmitDeadLine()">
            <div class="modal-body">
              <div
                class="d-flex align-items-center flex-row text-align text-center"
              >
                <p class="text-muted">Data Limite:</p>
                <mat-checkbox
                  formControlName="dayOnMonth"
                  color="primary"
                  class="pl-3 mb-1"
                  (change)="onChangeLimitDate(1)"
                >
                  Dia do mês
                </mat-checkbox>

                <mat-checkbox
                  formControlName="utilDay"
                  color="primary"
                  class="pl-3 mb-1"
                  (change)="onChangeLimitDate(2)"
                >
                  Dia útil
                </mat-checkbox>
              </div>

              <mat-form-field
                appearance="fill"
                class="w-100"
                *ngIf="this.formDeadLine.get('dayOnMonth').value"
              >
                <mat-label>Data:</mat-label>
                <input
                  matInput
                  [(ngModel)]="deadlineValue"
                  formControlName="deadline"
                  [matDatepicker]="pickerDataLimite"
                  readonly
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="pickerDataLimite"
                ></mat-datepicker-toggle>
                <mat-datepicker #pickerDataLimite></mat-datepicker>
              </mat-form-field>

              <div
                class="d-flex align-items-center flex-row text-align text-center my-2"
                *ngIf="!this.formDeadLine.get('dayOnMonth').value"
              >
                <p class="text-muted">Último Dia Útil:</p>
                <mat-checkbox
                  formControlName="lastDayUtil"
                  color="primary"
                  class="pl-3 mb-1"
                  (change)="onChangeLimitDate(3)"
                >
                </mat-checkbox>
              </div>

              <mat-form-field
                class="w-100"
                appearance="fill"
                *ngIf="!this.formDeadLine.get('dayOnMonth').value"
              >
                <mat-label>Dia Limite: (Em Dias)</mat-label>
                <input
                  matInput
                  type="number"
                  appNumbersOnly
                  formControlName="limitDate"
                  [ngClass]="{
                    'is-invalid':
                      submitted && this.formDeadLine.getError('limitDate')
                  }"
                />
              </mat-form-field>

              <mat-form-field appearance="fill" class="w-100">
                <mat-label>Justificativa:</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="justify"
                  maxlength="300"
                  required
                />
              </mat-form-field>

              <mat-checkbox
                [checked]="isInsumoSon"
                color="primary"
                class="pl-3 mb-1"
                (change)="onChangeInsumoSon()"
              >
                Fazer esta alteração apenas para esta competência.
              </mat-checkbox>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn-sm btn btn-dark mr-2 btn-mat-icon"
                data-dismiss="modal"
              >
                <mat-icon class="">close</mat-icon>
                Cancelar
              </button>

              <button type="submit" class="btn-sm btn btn-success btn-mat-icon">
                <mat-icon class="">done</mat-icon>
                Atualizar
              </button>
            </div>
          </form>
        </div>

        <div *ngIf="isInsumoSon == false">
          <form
            [formGroup]="formDeadLineSon"
            (ngSubmit)="onSubmitDeadLineSon()"
          >
            <div class="modal-body">
              <div
                class="d-flex align-items-center flex-row text-align text-center"
              >
                <p class="text-muted">Data Limite:</p>
                <mat-checkbox
                  formControlName="dayOnMonth"
                  color="primary"
                  class="pl-3 mb-1"
                  (change)="onChangeLimitDateSon(1)"
                >
                  Dia do mês
                </mat-checkbox>

                <mat-checkbox
                  formControlName="utilDay"
                  color="primary"
                  class="pl-3 mb-1"
                  (change)="onChangeLimitDateSon(2)"
                >
                  Dia útil
                </mat-checkbox>
              </div>

              <mat-form-field
                appearance="fill"
                class="w-100"
                *ngIf="this.formDeadLineSon.get('dayOnMonth').value"
              >
                <mat-label>Data:</mat-label>
                <input
                  matInput
                  formControlName="deadline"
                  [(ngModel)]="deadlineValue"
                  [matDatepicker]="pickerDataLimiteSon"
                  readonly
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="pickerDataLimiteSon"
                ></mat-datepicker-toggle>
                <mat-datepicker #pickerDataLimiteSon></mat-datepicker>
              </mat-form-field>

              <div
                class="d-flex align-items-center flex-row text-align text-center my-2"
                *ngIf="!this.formDeadLineSon.get('dayOnMonth').value"
              >
                <p class="text-muted">Último Dia Útil:</p>
                <mat-checkbox
                  formControlName="lastDayUtil"
                  color="primary"
                  class="pl-3 mb-1"
                  (change)="onChangeLimitDateSon(3)"
                >
                </mat-checkbox>
              </div>

              <mat-form-field
                class="w-100"
                appearance="fill"
                *ngIf="!this.formDeadLineSon.get('dayOnMonth').value"
              >
                <mat-label>Dia Limite: (Em Dias)</mat-label>
                <input
                  matInput
                  type="number"
                  appNumbersOnly
                  formControlName="limitDate"
                  [ngClass]="{
                    'is-invalid':
                      submitted && this.formDeadLineSon.getError('limitDate')
                  }"
                />
              </mat-form-field>

              <mat-form-field appearance="fill" class="w-100">
                <mat-label>Justificativa:</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="justify"
                  maxlength="300"
                  required
                />
              </mat-form-field>

              <mat-checkbox
                [checked]="isInsumoSon"
                color="primary"
                class="pl-3 mb-1"
                (change)="onChangeInsumoSon()"
              >
                Fazer esta alteração apenas para esta competência.
              </mat-checkbox>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn-sm btn btn-dark mr-2 btn-mat-icon"
                data-dismiss="modal"
              >
                <mat-icon class="">close</mat-icon>
                Cancelar
              </button>

              <button type="submit" class="btn-sm btn btn-success btn-mat-icon">
                <mat-icon class="">done</mat-icon>
                Atualizar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="historico"
    tabindex="-1"
    role="dialog"
    aria-labelledby="historico"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title m-0">Histórico</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-sm-12">
              <section id="cd-timeline" *ngIf="insumo.history" class="">
                <div
                  class="cd-timeline-block"
                  *ngFor="let element of insumo.history.reverse()"
                >
                  <div class="cd-timeline-img cd-picture"></div>
                  <div class="cd-timeline-content bg-white">
                    <div>
                      <p class="text-primary mb-0 text-small">
                        <strong>Ação:</strong>
                      </p>
                      <h3 class="text-muted mb-0">
                        {{ element.actionEnum | cap }}
                      </h3>
                    </div>

                    <hr class="mt-2 mb-3" />

                    <div>
                      <p class="text-primary text-small mt-2 mb-0">
                        Usuário da ação:
                      </p>
                      <h3 class="text-muted mb-0 mt-0">
                        {{ element.actionUserFullName }}
                      </h3>
                      <p class="text-muted mt-0">
                        {{ element.actionUserEmail }}
                      </p>
                      <p
                        class="text-primary text-small mt-2 mb-0"
                        *ngIf="element.newResponsibleEmail"
                      >
                        Novo Responsável pelo upload:
                      </p>
                      <h3 class="text-muted mb-0 mt-0">
                        {{ element.newResponsibleFullName }}
                      </h3>
                      <p class="text-muted mt-0">
                        {{ element.newResponsibleEmail }}
                      </p>
                      <p
                        class="text-primary text-small mt-2 mb-0"
                        *ngIf="element.newAnalysisUploadEmail"
                      >
                        Novo Responsável pela Análise:
                      </p>
                      <h3 class="text-muted mb-0 mt-0">
                        {{ element.newAnalysisUploadFullName }}
                      </h3>
                      <p class="text-muted mt-0">
                        {{ element.newAnalysisUploadEmail }}
                      </p>
                      <div>
                        <p class="text-primary text-small mt-2 mb-0">
                          Data da Ação:
                        </p>
                        <h4 class="text-muted mb-0 mt-0">
                          {{ element.changedDate }}
                        </h4>
                      </div>
                    </div>

                    <div *ngIf="element.justify">
                      <hr class="mt-2 mb-3" />

                      <p class="text-primary text-small mt-2 mb-0">
                        Justificativa:
                      </p>
                      <p class="text-muted mt-0">
                        {{ element.justify }}
                      </p>
                    </div>

                    <hr class="mt-2 mb-2" />

                    <div class="col-sm-12">
                      <div class="row">
                        <div *ngIf="element.currentStatus" class="mr-3">
                          <p class="text-primary text-small mb-0">Status:</p>
                          <p class="m-0">
                            <label
                              [ngClass]="{
                                vigente: element.currentStatus === 'VIGENTE',
                                inativo: element.currentStatus === 'INATIVO',
                                encerrado: element.currentStatus === 'ENCERRADO'
                              }"
                            >
                              {{ element.currentStatus | cap }}
                            </label>
                          </p>
                        </div>
                        <div
                          *ngIf="element.currentStatus !== element.newStatus"
                        >
                          <p class="text-primary text-small mb-0">
                            Novo Status:
                          </p>
                          <p class="m-0">
                            <label
                              [ngClass]="{
                                vigente: element.newStatus === 'VIGENTE',
                                inativo: element.newStatus === 'INATIVO',
                                encerrado: element.newStatus === 'ENCERRADO'
                              }"
                            >
                              {{ element.newStatus | cap }}
                            </label>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-dark btn-sm"
            data-dismiss="modal"
          >
            Fechar
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="version">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title mb-0" id="TituloModalCentralizado">
            Mudança de Versão de Anexo de Insumo
          </p>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Fechar"
            #closeModalVersion
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="formChangeVersion">
            <div class="modal-body">
              <mat-form-field appearance="fill" class="w-100" required>
                <mat-label> Versões Disponíveis: </mat-label>
                <mat-select formControlName="version">
                  <mat-option value="None" disabled="true">
                    Selecione Alguma Versão...
                  </mat-option>
                  <mat-option
                    *ngFor="let version of insumoVersionList"
                    [value]="version"
                  >
                    {{ version }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="fill" class="w-100">
                <mat-label>Justificativa:</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="justificativa"
                  maxlength="300"
                  required
                />
              </mat-form-field>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn-sm btn btn-dark mr-2 btn-mat-icon"
            data-dismiss="modal"
          >
            <mat-icon class="">close</mat-icon>
            Fechar
          </button>
          <button
            type="submit"
            class="btn btn-success btn-sm button-flex"
            (click)="changeVersion()"
          >
            <mat-icon>task_alt</mat-icon>
            Enviar
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="numerosd">
    <form [formGroup]="formAddNumeroSd" (ngSubmit)="addNumeroSd()">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <p class="modal-title mb-0" id="TituloModalCentralizado">
              Adicionar Número SD
            </p>
            <button
              #closeModal
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Fechar"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="my-4 px-4">
              <h3 class="text-center text-muted mt-4">
                Digite o Número SD referente à esse Insumo
              </h3>

              <mat-form-field appearance="fill" class="w-100" required>
                <mat-label> Número SD:</mat-label>
                <input
                  matInput
                  formControlName="numeroSd"
                  type="text"
                  placeholder="Digite o Número SD"
                />
              </mat-form-field>
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn-sm btn btn-secondary mr-2 btn-mat-icon"
              data-dismiss="modal"
            >
              <mat-icon class="modal-icon">arrow_back</mat-icon>
              Voltar
            </button>
            <button
              type="submit"
              class="btn-sm btn btn-success mr-2 btn-mat-icon"
            >
              <mat-icon class="modal-icon mr-1">save</mat-icon>
              Salvar Número SD
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <button
    [hidden]="true"
    data-toggle="modal"
    data-target="#hasInsumoJustify"
    #openModal
  ></button>

  <div class="modal fade" id="hasInsumoJustify">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title mb-0" id="TituloModalCentralizado">
            Justificativa de Não Haver Insumos
          </p>
          <button
            #closeModalHasInsumos
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Fechar"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div class="my-4 px-4">
            <h3 class="text-center text-muted mt-4">
              Justifique o motivo de não conter Insumos
            </h3>

            <mat-form-field appearance="fill" class="w-100" required>
              <mat-label> Justificativa:</mat-label>
              <input
                matInput
                [formControl]="hasInsumoJustify"
                type="text"
                placeholder="Digite o Justificativa"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn-sm btn btn-secondary mr-2 btn-mat-icon"
            data-dismiss="modal"
          >
            <mat-icon class="modal-icon">arrow_back</mat-icon>
            Voltar
          </button>
          <button
            [disabled]="hasInsumoJustify.invalid"
            type="submit"
            class="btn-sm btn btn-success mr-2 btn-mat-icon"
            (click)="changeHasInsumo()"
          >
            <mat-icon class="modal-icon mr-1">save</mat-icon>
            Salvar Justificativa
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="errorModal" #errorModal>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content" style="border: none">
      <div class="modal-header">
        <p class="modal-title mb-0" id="TituloModalCentralizado">
          Erro de Validação
        </p>
        <button
          type="button"
          class="close"
          aria-label="Fechar"
          data-dismiss="modal"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="mt-4 pb-4">
          <div class="w-100 d-flex justify-content-center">
            <mat-icon class="text-danger" style="font-size: 30pt !important"
              >error</mat-icon
            >
          </div>
          <h3 class="text-center text-muted mt-4">Data Inválida</h3>
          <p class="text-center text-muted mt-4">
            Insumos não podem ter data de entrega nos finais de semana, por
            favor altere a data.
          </p>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn-sm btn btn-secondary mr-2 btn-mat-icon"
          data-dismiss="modal"
        >
          <mat-icon class="modal-icon">close</mat-icon>
          Fechar
        </button>
      </div>
    </div>
  </div>
</div>
