<p class="text-muted mt-4 mb-1">
  <span class="text-primary">Portal de Comissionamento</span>
</p>

<h1 class="text-muted">Cadastrar Dashboard</h1>

<div class="row mb-5">
  <div class="col-sm-6">
    <form [formGroup]="form" (ngSubmit)="createDashBoard()">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Nome do Dashboard:</mat-label>
        <input matInput type="text" formControlName="title" required />
      </mat-form-field>

      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Link do Dashboard:</mat-label>
        <input matInput type="text" formControlName="link" required />
      </mat-form-field>

      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Categoria da dashboard </mat-label>
        <mat-select formControlName="categoryId" required>
          <mat-option
            *ngFor="let element of dashboardCategories"
            [value]="element.id"
          >
            {{ element.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Area do Dashboard </mat-label>
        <mat-select formControlName="areaDashboard" required>
          <mat-option value="Cadastro"> Cadastro </mat-option>
          <mat-option value="Projetos_e_regras"> Projetos e regras </mat-option>
          <mat-option value="Atendimento"> Atendimento </mat-option>
          <mat-option value="Comissão"> Comissão </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-label class="text-muted mr-auto">Compartilhar com: </mat-label>
      <div style="margin: 10px 0" formGroupName="areaPermissions">
        <mat-checkbox class="w-100" formControlName="EQUIPE_INTERNA"
          >Equipe Interna</mat-checkbox
        >
        <mat-checkbox class="w-100" formControlName="OPERACAO_VIVO"
          >Operação Vivo</mat-checkbox
        >
        <mat-checkbox class="w-100" formControlName="OPERACAO_BPO"
          >Operação BPO</mat-checkbox
        >
        <mat-checkbox class="w-100" formControlName="TODOS">Todos</mat-checkbox>
      </div>
      <button
        type="submit"
        class="btn btn-sm btn-primary"
        [disabled]="form.invalid"
      >
        <div class="button-flex">
          <mat-icon>post_add</mat-icon>
          Cadastrar Dashboard
        </div>
      </button>
    </form>
  </div>
</div>
