import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class InsumosFieldsService {
  constructor(private http: HttpClient) {}

  save(insumoFieldDto) {
    return this.http.post<any>(
      `${environment.apiUrl}/insumo/insumo-field/`,
      insumoFieldDto
    );
  }

  update(insumoFieldId, insumoFieldUpdateDto) {
    return this.http.put<any>(
      `${environment.apiUrl}/insumo/insumo-field/${insumoFieldId}`,
      insumoFieldUpdateDto
    );
  }

  changeStatus(insumoFieldId) {
    return this.http.get<any>(
      `${environment.apiUrl}/insumo/insumo-field/change-status/${insumoFieldId}`
    );
  }

  getByType(insumoFieldType) {
    return this.http.get<any>(
      `${environment.apiUrl}/insumo/insumo-field/type/${insumoFieldType}`
    );
  }

  getByTypeActive(insumoFieldType) {
    return this.http.get<any>(
      `${environment.apiUrl}/insumo/insumo-field/active/type/${insumoFieldType}`
    );
  }

  getById(insumoFieldId) {
    return this.http.get<any>(
      `${environment.apiUrl}/insumo/insumo-field/${insumoFieldId}`
    );
  }
}
