import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: "detailsTicketReturn",
})
export class DetailsTicketReturnPipe implements PipeTransform {
  transform(array: any[]) {
    return array?.filter((item) => item.field.id === 3)[0]?.value;
  }
}
