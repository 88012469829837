import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  constructor(private http: HttpClient) {}

  getDashboard(userType: string, categoryId) {
    return this.http.get<any>(
      `${environment.apiUrl}/dashboard/category/${categoryId}/?userType=${userType}`
    );
  }
  newDashBoard(formData) {
    return this.http.post<any>(`${environment.apiUrl}/dashboard`, formData, {
      reportProgress: true,
      observe: "events",
    });
  }
  updateDashboard(dashboardId: number, formData) {
    return this.http.put<any>(
      `${environment.apiUrl}/dashboard/${dashboardId}`,
      formData,
      {
        reportProgress: true,
        observe: "events",
      }
    );
  }
  getDashboardById(dashboardId: number) {
    return this.http.get<any>(`${environment.apiUrl}/dashboard/${dashboardId}`);
  }

  createDashboardCategory(formData) {
    return this.http.post(
      `${environment.apiUrl}/dashboard/categories`,
      formData
    );
  }

  getDashboardCategories() {
    return this.http.get(`${environment.apiUrl}/dashboard/categories`);
  }

  uploadThumbnail(formData: FormData) {
    return this.http.post(`${environment.apiUrl}/dashboard/thumb`, formData);
  }
}
