import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Questionario } from '../_models/questionario';
import { QuestionarioDemo } from '../_models/questionarioDemo';

@Injectable({
  providedIn: 'root'
})
export class QuestionarioService {
  
  constructor(
    private http: HttpClient
  ) { }

  saveOrUpdate(QuestionarioDemo) {
    return this.http.post(`${environment.apiUrl}/questionario`, QuestionarioDemo);
  }


  getAll() {
    return this.http.get<QuestionarioDemo[]>(`${environment.apiUrl}/questionarioDemo`).pipe(tap(console.log));
  }

  getAllPaginado(pagina:number,tamanho:number) {
    return this.http.get<QuestionarioDemo[]>(`${environment.apiUrl}/questionarioDemo/page/${pagina}/${tamanho}`).pipe(tap(console.log));
  }

  getById(id) {
    return this.http.get<any>(`${environment.apiUrl}/questionario/${id}`).pipe(tap(console.log));
  }
  
}