import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

export interface StateGroup {
  letter: string;
  names: string[];
}

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};


@Component({
  selector: 'app-triagem',
  templateUrl: './triagem.component.html',
  styleUrls: ['./triagem.component.css']
})
export class TriagemComponent implements OnInit {


  constructor() { }

  ngOnInit() {

  }

  // table

  displayedColumns: string[] = ['id', 'criadoEm', 'razaoSocial', 'origem', 'usuario', 'quantidade', 'urgente', 'status', 'acoes'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
}

export interface PeriodicElement {
  id: number;
  criadoEm: string;
  razaoSocial: string;
  origem: string;
  usuario: string;
  quantidade: string;
  urgente: string;
  status: string;
  acoes: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { id: 1, criadoEm: '-', razaoSocial: '-', origem: '-', usuario: '-', quantidade: '-', urgente: '-', status: '-', acoes: 'acao1' },
];

