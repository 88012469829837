<!-- <span class="badge badge-success ml-2 pr-2 pl-2 pt-1 pb-1">new</span> -->
<aside
  class="bg-white-sidebar text-muted"
  id="sidebar-wrapper"
  *ngIf="this.data"
>
  <div class="sidebar-heading">
    <img
      id="step-logo"
      src="https://portaldeassinaturas.vivo.com.br/usoproprioui/assets/perfil-empresa/vivo-vivo.svg"
      class="img-perfil-empresa"
      alt=""
    />

    <button
      class="btn btn-sm bg-primary-light"
      id="menu-toggle-sidebar"
      (click)="logoVivo2('logo-vivo2')"
    >
      <!-- Voltar -->
      <mat-icon class="material-symbols-outlined" matListIcon>
        arrow_forward
      </mat-icon>
    </button>
  </div>
  <!-- src="../../assets/perfil-empresa/vivo-vivo.svg" -->

  <div class="list-group list-group-flush">
    <ul class="navbar-nav" id="navAccordion">
      <li class="nav-item" routerLinkActive="">
        <a
          routerLink="/"
          class="ma list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted"
        >
          <mat-icon class="material-symbols-outlined">home</mat-icon>
          Menu Principal
        </a>
      </li>

      <li class="nav-item" routerLinkActive="abrir-ticket">
        <a
          class="ma list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted"
          href="#"
          id="hasSubItems"
          data-toggle="collapse"
          data-target="#collapseSubItemsWiki"
          aria-controls="collapseSubItemsWiki"
          aria-expanded="false"
        >
          <mat-icon class="material-symbols-outlined">travel_explore</mat-icon>
          Wiki
          <mat-icon class="ml-1">arrow_drop_down</mat-icon>
        </a>

        <ul
          class="nav-second-level collapse"
          id="collapseSubItemsWiki"
          data-parent="#navAccordion"
        >
          <li
            class="m-0"
            routerLinkActive="menuActive"
            *ngIf="authorization([0, 1, 2, 5])"
            (click)="showSearchPage()"
          >
            <a
              routerLink="/arquivos"
              class="list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted sub-items"
              href="#"
            >
              <mat-icon class="material-symbols-outlined">
                manage_search
              </mat-icon>
              <!-- Listar Arquivos -->
              Pesquisar
            </a>
          </li>

          <li
            class="m-0"
            routerLinkActive="menuActive"
            *ngIf="authorization([1, 2, 4, 5])"
          >
            <a
              routerLink="/arquivos/criar-arquivo"
              class="list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted sub-items"
              href="#"
            >
              <mat-icon class="material-symbols-outlined" class="">
                post_add
              </mat-icon>
              Criar Arquivo
            </a>
          </li>
          <li
            class="m-0"
            routerLinkActive="menuActive"
            *ngIf="authorization([1, 2, 4, 5])"
          >
            <a
              routerLink="/arquivos/visualizar-minha"
              class="list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted sub-items"
              href="#"
            >
              <mat-icon class="material-symbols-outlined">
                travel_explore
              </mat-icon>
              Minha Wiki
            </a>
          </li>
          <li
            class="m-0"
            routerLinkActive="menuActive"
            *ngIf="changeFieldsPermissions(this.data.user.profile)"
          >
            <a
              routerLink="/arquivos/parametros"
              class="list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted sub-items"
              href="#"
            >
              <mat-icon class="material-symbols-outlined"> settings </mat-icon>
              Parâmetros
            </a>
          </li>
        </ul>
      </li>

      <li class="nav-item">
        <a
          class="ma list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted"
          href="#"
          id="hasSubItems"
          data-toggle="collapse"
          data-target="#collapseTickets"
          aria-controls="collapseTickets"
          aria-expanded="false"
        >
          <mat-icon class="material-symbols-outlined">
            confirmation_number
          </mat-icon>
          Tickets
          <mat-icon class="ml-1">arrow_drop_down</mat-icon>
        </a>

        <ul class="nav-second-level collapse" id="collapseTickets">
          <li class="m-0" routerLinkActive="abrir-ticket">
            <a
              class="ma list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted"
              href="#"
              id="hasSubItems"
              data-toggle="collapse"
              data-target="#collapseSubItemsProjetosEDemandas"
              aria-controls="collapseSubItemsProjetosEDemandas"
              aria-expanded="false"
            >
              <mat-icon class="material-symbols-outlined">
                confirmation_number
              </mat-icon>
              Abrir Ticket
              <mat-icon class="ml-1">arrow_drop_down</mat-icon>
            </a>

            <ul
              class="nav-second-level collapse"
              id="collapseSubItemsProjetosEDemandas"
              data-parent="#navAccordion"
            >
              <li class="m-0" routerLinkActive="menuActive">
                <ul class="nav-second-level" id="collapseSubItemsComOperacao">
                  <li
                    class="m-0"
                    routerLinkActive="menuActive"
                    *ngIf="authorization([0, 1, 2, 5])"
                  >
                    <a
                      routerLink="projeto-e-demanda"
                      class="list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted sub-items"
                      href="#"
                    >
                      <mat-icon
                        class="material-symbols-outlined"
                        class=""
                      ></mat-icon>
                      Projetos e Demandas
                    </a>
                  </li>

                  <li
                    class="m-0"
                    routerLinkActive="menuActive"
                    *ngIf="authorization([1, 2, 4, 5])"
                  >
                    <a
                      routerLink="auditoria"
                      class="list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted sub-items"
                      href="#"
                    >
                      <mat-icon
                        class="material-symbols-outlined"
                        class=""
                      ></mat-icon>
                      Auditoria
                    </a>
                  </li>
                  <li
                    class="m-0"
                    routerLinkActive="menuActive"
                    *ngIf="authorization([0, 1, 2, 5])"
                  >
                    <a
                      routerLink="suporte-portais-sistemas"
                      class="list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted sub-items"
                      href="#"
                    >
                      <mat-icon
                        class="material-symbols-outlined"
                        class=""
                      ></mat-icon>
                      Suporte Portais Externos
                    </a>
                  </li>
                  <li
                    class="m-0"
                    routerLinkActive="menuActive"
                    *ngIf="authorization([1, 2, 3, 5])"
                  >
                    <a
                      routerLink="consulta-juridica"
                      class="list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted sub-items"
                      href="#"
                    >
                      <mat-icon
                        class="material-symbols-outlined"
                        class=""
                      ></mat-icon>
                      Consulta Jurídica
                    </a>
                  </li>

                  <li class="m-0" routerLinkActive="menuActive">
                    <a
                      routerLink="comissao-contestacao"
                      class="list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted sub-items"
                      href="#"
                      *ngIf="authorization([0, 1, 2, 5])"
                    >
                      <mat-icon
                        class="material-symbols-outlined"
                        class=""
                      ></mat-icon>
                      Comissão / Contestação
                    </a>
                  </li>
                </ul>
              </li>

              <li class="m-0" routerLinkActive="menuActive">
                <!-- <ul class="nav-second-level" id="collapseSubItemsComEquipeInterna">
                  <li
                    class="m-0"
                    routerLinkActive="menuActive"
                    *ngIf="comissionamentoPermissions(user.userType)"
                  >
                    <a
                      routerLink="comissionamento"
                      class="list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted sub-items"
                      href="#"
                    >
                      <mat-icon class="material-symbols-outlined" class=""></mat-icon>
                      Comissionamento
                    </a>
                  </li>
                </ul> -->

                <ul
                  class="nav-second-level"
                  id="collapseSubItemsComEquipeInterna"
                  *ngIf="authorization([1, 2, 5])"
                >
                  <li class="m-0" routerLinkActive="menuActive">
                    <a
                      routerLink="solicitacoes-internas"
                      class="list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted sub-items"
                      href="#"
                    >
                      <mat-icon
                        class="material-symbols-outlined"
                        class=""
                      ></mat-icon>
                      Solicitações Internas
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <!-- <li class="nav-item" routerLinkActive="menuActive">
            <a
              routerLink="abrir-ticket"
              class="ma list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted"
              href="#"
            >
              <mat-icon class="material-symbols-outlined">confirmation_number</mat-icon>
              Abrir Ticket
            </a>
          </li> -->

          <li
            class="m-0"
            *ngIf="
              authorization([1, 2, 5]) ||
              this.data.user.id === 163 ||
              this.data.user.id === 183 ||
              this.data.user.id === 5
            "
            routerLinkActive="menuActive"
          >
            <a
              routerLink="atender-tickets"
              class="ma list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted"
              href=""
              id="hasSubItems"
              data-toggle="collapse"
              data-target="#collapseSubItemsAtenderTickets"
              aria-controls="collapseSubItemsAtenderTickets"
              aria-expanded="false"
            >
              <mat-icon class="material-symbols-outlined">
                important_devices
              </mat-icon>
              Atender Tickets
            </a>
          </li>

          <li
            class="m-0"
            *ngIf="authorization([0])"
            routerLinkActive="menuActive"
          >
            <a
              routerLink="atender-tickets-operacao-vivo"
              class="ma list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted"
              href=""
              id="hasSubItems"
              data-toggle="collapse"
              data-target="#collapseSubItemsAtenderTickets"
              aria-controls="collapseSubItemsAtenderTickets"
              aria-expanded="false"
            >
              <mat-icon class="material-symbols-outlined">
                important_devices
              </mat-icon>
              Atender Tickets
            </a>
          </li>

          <li
            class="m-0"
            routerLinkActive="menuActive"
            *ngIf="authorization([0, 1, 2, 5])"
          >
            <a
              routerLink="consultar-tickets"
              class="ma list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted"
              href="#"
            >
              <mat-icon class="material-symbols-outlined">
                manage_search
              </mat-icon>
              Consultar Tickets
            </a>
          </li>

          <li class="m-0" routerLinkActive="menuActive">
            <a
              routerLink="meus-tickets"
              class="ma list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted"
              href="#"
            >
              <mat-icon class="material-symbols-outlined">fact_check</mat-icon>
              Meus Tickets
            </a>
          </li>

          <li class="m-0" routerLinkActive="menuActive">
            <a
              routerLink="relatorio-tickets"
              class="ma list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted"
              href="#"
            >
              <mat-icon class="material-symbols-outlined">
                description
              </mat-icon>
              Relatório Tickets
            </a>
          </li>
        </ul>
      </li>

      <!-- <li class="nav-item"> -->
      <!-- <hr class="pt-4 pb-4 mt-5"> -->
      <!-- <p class="pt-4 pb-4 m-0 pl-4 text-muted">Tickets</p> -->
      <!-- </li> -->

      <!-- <li class="nav-item" routerLinkActive="abrir-ticket">
        <a class="ma list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted"
          href="#" id="hasSubItems" data-toggle="collapse" data-target="#collapseSubItemsProjetosEDemandas"
          aria-controls="collapseSubItemsProjetosEDemandas" aria-expanded="false">
          <mat-icon class="material-symbols-outlined">confirmation_number</mat-icon>
          Abrir Ticket
          <mat-icon class="ml-1">arrow_drop_down</mat-icon>
        </a>

        <ul class="nav-second-level collapse" id="collapseSubItemsProjetosEDemandas" data-parent="#navAccordion">
          <li class="m-0" routerLinkActive="menuActive">
            <ul class="nav-second-level" id="collapseSubItemsComOperacao">
              <li class="m-0" routerLinkActive="menuActive" *ngIf="authorization([0, 1, 2, 5])">
                <a routerLink="projeto-e-demanda"
                  class="list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted sub-items"
                  href="#">
                  <mat-icon class="material-symbols-outlined" class=""></mat-icon>
                  Projetos e Demandas
                </a>
              </li>

              <li class="m-0" routerLinkActive="menuActive" *ngIf="authorization([1, 2, 4, 5])">
                <a routerLink="auditoria"
                  class="list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted sub-items"
                  href="#">
                  <mat-icon class="material-symbols-outlined" class=""></mat-icon>
                  Auditoria
                </a>
              </li>
              <li class="m-0" routerLinkActive="menuActive" *ngIf="authorization([0, 1, 2, 5])">
                <a routerLink="suporte-portais-sistemas"
                  class="list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted sub-items"
                  href="#">
                  <mat-icon class="material-symbols-outlined" class=""></mat-icon>
                  Suporte Portais Externos
                </a>
              </li>
              <li class="m-0" routerLinkActive="menuActive" *ngIf="authorization([1, 2, 3, 5])">
                <a routerLink="consulta-juridica"
                  class="list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted sub-items"
                  href="#">
                  <mat-icon class="material-symbols-outlined" class=""></mat-icon>
                  Consulta Jurídica
                </a>
              </li>

              <li class="m-0" routerLinkActive="menuActive">
                <a routerLink="comissao-contestacao"
                  class="list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted sub-items"
                  href="#" *ngIf="authorization([0, 1, 2, 5])">
                  <mat-icon class="material-symbols-outlined" class=""></mat-icon>
                  Comissão / Contestação
                </a>
              </li>
            </ul>
          </li>

          <li class="m-0" routerLinkActive="menuActive"> -->
      <!-- <ul class="nav-second-level" id="collapseSubItemsComEquipeInterna">
              <li
                class="m-0"
                routerLinkActive="menuActive"
                *ngIf="comissionamentoPermissions(user.userType)"
              >
                <a
                  routerLink="comissionamento"
                  class="list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted sub-items"
                  href="#"
                >
                  <mat-icon class="material-symbols-outlined" class=""></mat-icon>
                  Comissionamento
                </a>
              </li>
            </ul> -->

      <!-- <ul class="nav-second-level" id="collapseSubItemsComEquipeInterna" *ngIf="authorization([1, 2, 5])">
              <li class="m-0" routerLinkActive="menuActive">
                <a routerLink="solicitacoes-internas"
                  class="list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted sub-items"
                  href="#">
                  <mat-icon class="material-symbols-outlined" class=""></mat-icon>
                  Solicitações Internas
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </li> -->

      <!-- <li class="nav-item" routerLinkActive="menuActive">
        <a
          routerLink="abrir-ticket"
          class="ma list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted"
          href="#"
        >
          <mat-icon class="material-symbols-outlined">confirmation_number</mat-icon>
          Abrir Ticket
        </a>
      </li> -->

      <!-- <li class="nav-item" *ngIf="
          authorization([1, 2, 5]) ||
          user.id === 163 ||
          user.id === 183 ||
          user.id === 5
        " routerLinkActive="menuActive">
        <a routerLink="atender-tickets"
          class="ma list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted"
          href="" id="hasSubItems" data-toggle="collapse" data-target="#collapseSubItemsAtenderTickets"
          aria-controls="collapseSubItemsAtenderTickets" aria-expanded="false">
          <mat-icon class="material-symbols-outlined">important_devices</mat-icon>
          Atender Tickets
        </a>
      </li> -->

      <!-- <li class="nav-item" *ngIf="authorization([0])" routerLinkActive="menuActive">
        <a routerLink="atender-tickets-operacao-vivo"
          class="ma list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted"
          href="" id="hasSubItems" data-toggle="collapse" data-target="#collapseSubItemsAtenderTickets"
          aria-controls="collapseSubItemsAtenderTickets" aria-expanded="false">
          <mat-icon class="material-symbols-outlined">important_devices</mat-icon>
          Atender Tickets
        </a>
      </li> -->

      <!-- <li class="nav-item" routerLinkActive="menuActive" *ngIf="authorization([0, 1, 2, 5])">
        <a routerLink="consultar-tickets"
          class="ma list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted"
          href="#">
          <mat-icon class="material-symbols-outlined">manage_search</mat-icon>
          Consultar Tickets
        </a>
      </li> -->

      <!-- <li class="nav-item" routerLinkActive="menuActive">
        <a routerLink="meus-tickets"
          class="ma list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted"
          href="#">
          <mat-icon class="material-symbols-outlined">fact_check</mat-icon>
          Meus Tickets
        </a>
      </li> -->

      <!-- <li class="nav-item" routerLinkActive="menuActive">
        <a routerLink="relatorio-tickets"
          class="ma list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted"
          href="#">
          <mat-icon class="material-symbols-outlined"> description</mat-icon>
          Relatório Tickets
        </a>
      </li> -->
      <!-- <li class="nav-item" routerLinkActive="menuActive">
        <a
          routerLink="arquivos"
          class="ma list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted"
          href="#"
        >
          <mat-icon>folder_open</mat-icon>
          Wiki Comissionamento
        </a>
      </li> -->

      <!-- <li class="nav-item">
        <a  class="list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted"
          href="#" id="hasSubItems" data-toggle="collapse" data-target="#collapseSubItemsMeusTickets"
          aria-controls="collapseSubItemsMeusTickets" aria-expanded="false">
          <mat-icon class="material-symbols-outlined">fact_check</mat-icon>
          Meus Tickets
        </a>

        <ul class="nav-second-level collapse" id="collapseSubItemsMeusTickets" data-parent="#navAccordion">
          <li class="m-0">
            <a  class="list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted"
              href="#">
              <mat-icon class="material-symbols-outlined" class="ml-4">construction</mat-icon>
              Acompanhar Tickets
            </a>
          </li>
        </ul>
      </li> -->

      <!-- <li class="nav-item"> -->
      <!-- <hr class="pt-4 pb-4 mt-5"> -->
      <!-- <p class="pt-4 pb-4 m-0 pl-4 text-muted">Outros</p> -->
      <!-- </li> -->

      <li class="nav-item" routerLinkActive="menuActive">
        <a
          class="ma list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted"
          href="#"
          id="hasSubItems"
          data-toggle="collapse"
          data-target="#collapseSubItemsInsumos"
          aria-controls="collapseSubItemsInsumos"
          aria-expanded="false"
        >
          <mat-icon class="material-symbols-outlined">inventory_2</mat-icon>
          Insumos
          <mat-icon class="ml-1">arrow_drop_down</mat-icon>
        </a>

        <ul
          class="nav-second-level collapse"
          id="collapseSubItemsInsumos"
          data-parent="#navAccordion"
        >
          <li class="m-0" *ngIf="authorization([1, 2, 5])">
            <a
              class="list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted"
              routerLink="insumos/criar"
            >
              <mat-icon class="material-symbols-outlined" class="">
                post_add
              </mat-icon>
              <!-- ml-4 -->
              Criar Insumos
            </a>
          </li>

          <li class="m-0">
            <!--<a
              class="list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted"
              routerLink="insumos/visualizar"
            >
              <mat-icon class="material-symbols-outlined" class="">dvr</mat-icon> 
              Visualizar Insumos
            </a>
          </li>
          <li
            class="m-0"
            *ngIf="createInsumoPermissionsMeus(user.userType)"
          >-->
            <a
              class="list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted"
              routerLink="insumos/meus"
            >
              <mat-icon class="material-symbols-outlined" class="">
                dvr
              </mat-icon>
              <!-- ml-4 -->
              Meus Insumos
            </a>
          </li>

          <li class="m-0" *ngIf="authorization([1, 2, 5])">
            <a
              class="list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted"
              routerLink="insumos/consultar"
            >
              <mat-icon class="material-symbols-outlined" class="">
                manage_search
              </mat-icon>
              <!-- ml-4 -->
              Consultar Insumos
            </a>
          </li>

          <li
            class="m-0"
            *ngIf="changeFieldsPermissions(this.data.user.profile)"
          >
            <a
              class="list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted"
              routerLink="insumos/fields-configure"
            >
              <mat-icon class="material-symbols-outlined" class="">
                tune
              </mat-icon>
              <!-- ml-4 -->
              Configurar Campos
            </a>
          </li>
        </ul>
      </li>

      <!-- criar novo menu usuarios -->

      <li class="nav-item" routerLinkActive="menuActive">
        <a
          class="ma list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted"
          href="#"
          id="hasSubItems"
          data-toggle="collapse"
          data-target="#collapseSubItemsUsuarios"
          aria-controls="collapseSubItemsUsuarios"
          aria-expanded="false"
        >
          <mat-icon class="material-symbols-outlined">
            record_voice_over
          </mat-icon>
          Usuários
          <mat-icon class="ml-1">arrow_drop_down</mat-icon>
        </a>

        <ul
          class="nav-second-level collapse"
          id="collapseSubItemsUsuarios"
          data-parent="#navAccordion"
        >
          <li
            *ngIf="
              authorization([1, 2, 5]) && this.data.user.profile == 'ROLE_ADMIN'
            "
            class="m-0"
            routerLinkActive="menuActive"
          >
            <a
              routerLink="gestao-usuario"
              class="ma list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted"
              href="#"
            >
              <mat-icon class="material-symbols-outlined">groups</mat-icon>
              Gestão de Usuários
            </a>
          </li>

          <li
            *ngIf="
              authorization([1, 2, 5]) && this.data.user.profile == 'ROLE_ADMIN'
            "
            class="m-0"
            routerLinkActive="menuActive"
          >
            <a
              routerLink="solicitacao-de-acesso"
              class="ma list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted"
              href="#"
            >
              <mat-icon class="material-symbols-outlined">input</mat-icon>
              Solicitação de Acesso
            </a>
          </li>
        </ul>
      </li>

      <!-- <li *ngIf="
          bloqueioOperador(user.userType) && user.profile == 'ROLE_ADMIN'
        " class="nav-item" routerLinkActive="menuActive">
        <a routerLink="gestao-usuario"
          class="ma list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted"
          href="#">
          <mat-icon class="material-symbols-outlined">groups</mat-icon>
          Gestão de Usuários
        </a>
      </li>

      <li *ngIf="
          bloqueioOperador(user.userType) && user.profile == 'ROLE_ADMIN'
        " class="nav-item" routerLinkActive="menuActive">
        <a routerLink="solicitacao-de-acesso"
          class="ma list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted"
          href="#">
          <mat-icon class="material-symbols-outlined">input</mat-icon>
          Solicitação de Acesso
        </a>
      </li> -->

      <li class="nav-item" routerLinkActive="menuActive">
        <a
          class="ma list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted"
          href="#"
          id="hasSubItems"
          data-toggle="collapse"
          data-target="#collapseSubItemsDashboard"
          aria-controls="collapseSubItemsDashboard"
          aria-expanded="false"
        >
          <mat-icon class="material-symbols-outlined"> bar_chart </mat-icon>
          Dashboard
          <mat-icon class="ml-1">arrow_drop_down</mat-icon>
        </a>

        <ul
          class="nav-second-level collapse"
          id="collapseSubItemsDashboard"
          data-parent="#navAccordion"
        >
          <li class="m-0" *ngIf="authorization([1, 2, 5])">
            <a
              class="list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted"
              routerLink="dashboards/criar-dashboard"
            >
              <mat-icon class="material-symbols-outlined" class="">
                post_add
              </mat-icon>
              Criar
            </a>
          </li>

          <li class="m-0">
            <a
              class="list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted"
              routerLink="dashboards"
            >
              <mat-icon class="material-symbols-outlined" class="">
                dvr
              </mat-icon>
              Listar
            </a>
          </li>
        </ul>
      </li>

      <li class="nav-item" routerLinkActive="menuActive">
        <a
          class="ma list-group-item list-group-item-sidebar-mod list-group-item-action list-group-item-action-sidebar-mod text-muted"
          (click)="openHelpDesk()"
          href="#"
        >
          <mat-icon class="material-symbols-outlined">help_outline</mat-icon>
          Suporte
        </a>
      </li>
    </ul>
  </div>
</aside>

<!-- <span class="badge badge-info ml-2 pr-2 pl-2 pt-1 pb-1" style="font-weight: 300;">new</span> -->
