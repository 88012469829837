import { PipeTransform, Pipe } from "@angular/core";

import { InsumoList } from "../_models/insumos";

@Pipe({
  name: "deliveryDateInsumo",
})
export class DeliveryDateInsumoPipe implements PipeTransform {
  transform(value: InsumoList): string {
    const attachmentsSize = value.attachments.length || 0;

    if (!value.hasInsumo) {
      return "Não Possui";
    }

    if (
      value.attachments != null &&
      value.attachments != undefined &&
      value.attachments.length != 0
    ) {
      return value.attachments[attachmentsSize - 1].createdAt;
    }

    if (
      value.hasInsumoJustify != "" ||
      value.hasInsumoJustify != null ||
      value.hasInsumoJustify != undefined
    ) {
      return "";
    }

    return "";
  }
}
