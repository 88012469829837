import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ProgressSpinnerMode } from "@angular/material/progress-spinner";
import { Router } from "@angular/router";
import { User } from "src/app/_models";
import { AccountService } from "src/app/_services";
import { DashboardService } from "src/app/_services/dashboard.service";

@Component({
  selector: "app-criar-categoria-dashboard",
  templateUrl: "./criar-categoria-dashboard.component.html",
  styleUrls: ["./criar-categoria-dashboard.component.css"],
})
export class CriarCategoriaDashboardComponent implements OnInit {
  user: User;
  form: FormGroup;
  submitted: boolean = false;

  isLoading: boolean = false;

  mode: ProgressSpinnerMode = "indeterminate";

  thumbFiles: File[] = [];
  thumbImage: any;

  @ViewChild("fileUpload") fileUpload: any;
  @ViewChild("thumbUpload") thumbUpload: any;

  constructor(
    private accountService: AccountService,
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService,
    private router: Router
  ) {
    this.user = this.accountService.userValue.user;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: ["", Validators.required],
      description: ["", Validators.required],
    });
  }

  getThumbFile() {
    this.thumbFiles = Array.from(this.thumbUpload.nativeElement.files);
    this.imagePreview(this.thumbFiles);
  }

  formatFileSize(fileSizeInBytes: number) {
    if (fileSizeInBytes < 1024) {
      return fileSizeInBytes + " bytes";
    } else if (fileSizeInBytes < 1024 * 1024) {
      return (fileSizeInBytes / 1024).toFixed(2) + " KB";
    } else {
      return (fileSizeInBytes / (1024 * 1024)).toFixed(2) + " MB";
    }
  }

  imagePreview(thumbFiles) {
    const selectedFile = thumbFiles[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const dataURL = e.target.result;
        this.thumbImage = dataURL;
      };
      reader.readAsDataURL(selectedFile);
    }
  }
  createCategory() {
    this.isLoading = true;

    /**
     * 
    private String name;
    private String description;
    private Long anexoId;
     */

    const thumbNail = new FormData();

    const formValues = this.form.value;

    const data = {
      ...formValues,
    };

    if (this.thumbUpload) {
      const files = this.thumbUpload.nativeElement.files;
      for (let i = 0; i < files.length; i++) {
        thumbNail.append("file", files[i]);
      }
    }

    this.dashboardService.uploadThumbnail(thumbNail).subscribe((res: any) => {
      data.anexoId = res.id;
      this.dashboardService.createDashboardCategory(data).subscribe((res) => {
        this.router.navigate(["/dashboards"]);
      });
    });
  }
}
