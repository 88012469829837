<h1 class="mt-4 text-muted">Lista de Usuários</h1>

<div class="row mt-4">
  <div class="col-sm-12">
    <p>Pesquisar:</p>
  </div>

  <div class="col-sm-4">
    <mat-form-field [formGroup]="formFilter" appearance="fill" class="w-100">
      <mat-label>Filtro por Palavra-Chave:</mat-label>
      <input
        matInput
        formControlName="keyword"
        placeholder="Digite qualquer coisa"
        (keyup)="applyFilter($event.target.value)"
      />
    </mat-form-field>
  </div>

  <div class="col-sm-4">
    <mat-form-field [formGroup]="formFilter" appearance="fill" class="w-100">
      <mat-label>Nome:</mat-label>
      <input matInput formControlName="name" placeholder="Insira o nome" />
    </mat-form-field>
  </div>
  <div class="col-sm-4">
    <mat-form-field [formGroup]="formFilter" appearance="fill" class="w-100">
      <mat-label>Área:</mat-label>
      <input matInput formControlName="area" placeholder="Insira a área" />
    </mat-form-field>
  </div>

  <div class="col-sm-4">
    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Selecione um Período:</mat-label>
      <mat-date-range-input [formGroup]="formFilter" [rangePicker]="picker">
        <input
          matStartDate
          formControlName="startDate"
          placeholder="Data inicial"
        />
        <input matEndDate formControlName="endDate" placeholder="Data Final" />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>

      <mat-error
        *ngIf="formFilter.controls.startDate.hasError('matStartDateInvalid')"
      >
        Data Início Inválida
      </mat-error>
      <mat-error
        *ngIf="formFilter.controls.endDate.hasError('matEndDateInvalid')"
      >
        Data Final Inválida
      </mat-error>
    </mat-form-field>
  </div>
  <div class="col-sm-4">
    <mat-form-field [formGroup]="formFilter" class="w-100" appearance="fill">
      <mat-label>Tipo de Usuário:</mat-label>
      <mat-select formControlName="userType">
        <mat-option value="OPERACAO_VIVO">Operação Vivo</mat-option>
        <mat-option value="EQUIPE_INTERNA">Equipe Interna</mat-option>
        <mat-option value="OPERACAO_BPO">Operação BPO</mat-option>
        <mat-option value="OPERADOR_VIVO_JURIDICO">
          Operador Vivo Jurídico
        </mat-option>
        <mat-option value="OPERADOR_VIVO_AUDITORIA">
          Operador Vivo Auditoria
        </mat-option>
        <mat-option value="TODOS">Todos</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="col-sm-4">
    <mat-form-field [formGroup]="formFilter" class="w-100" appearance="fill">
      <mat-label>Status do Usuário:</mat-label>
      <mat-select formControlName="status">
        <mat-option value="1">Habilitado</mat-option>
        <mat-option value="0">Não Habilitado</mat-option>
        <mat-option value="TODOS">Todos</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div class="row mb-4">
  <div class="col-sm-6 d-flex">
    <button
      type="button"
      class="btn btn-sm btn-primary btn-mat-icon mr-2"
      (click)="filter(); this.paginator.firstPage()"
    >
      <strong>
        <mat-icon class="mr-1">filter_alt</mat-icon>
      </strong>
      Filtrar
    </button>
    <button
      type="button"
      class="btn btn-sm btn-secondary btn-mat-icon mr-2"
      (click)="clean()"
    >
      <div class="button-flex">
        <mat-icon>backspace</mat-icon>
        Limpar
      </div>
    </button>
  </div>
</div>

<div class="row mb-4">
  <div class="col-sm-6 d-flex">
    <button
      type="button"
      class="btn btn-sm btn-success btn-mat-icon mr-4 mw-[156px]"
      data-toggle="modal"
      data-target="#adicionar"
    >
      <strong>
        <mat-icon class="mr-1">person_add</mat-icon>
      </strong>
      Adicionar Usuário
    </button>

    <button
      type="button"
      class="btn btn-rose btn-sm btn-mat-icon mr-4 mw-[156px]"
      (click)="exportTable()"
    >
      <div class="button-flex">
        <mat-icon>download</mat-icon>
        Exportar
      </div>
    </button>

    <button
      type="button"
      class="btn btn-sm btn-warning btn-mat-icon"
      data-toggle="modal"
      data-target="#importar"
    >
      <strong>
        <mat-icon class="mr-1">person_add</mat-icon>
      </strong>
      Importar Usuários
    </button>
  </div>
</div>

<div class="mat-elevation-z8 mt-4 pb-4">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="id"
    matSortDirection="desc"
    matSortDisableClear
  >
    <ng-container matColumnDef="id">
      <th class="id" mat-header-cell *matHeaderCellDef>Id.</th>
      <td class="id" mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>

    <ng-container matColumnDef="nomeUsuario">
      <th class="nomeUsuario" mat-header-cell *matHeaderCellDef>Usuário</th>
      <td class="nomeUsuario" mat-cell *matCellDef="let element">
        {{ element.fullName || element.firstName + " " + element.lastName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="tipoUsuario">
      <th class="tipoUsuario" mat-header-cell *matHeaderCellDef>
        Tipo de Usuário
      </th>
      <td class="tipoUsuario" mat-cell *matCellDef="let element">
        {{ element.userType | cap }}
      </td>
    </ng-container>

    <ng-container matColumnDef="area">
      <th class="area" mat-header-cell *matHeaderCellDef>Área</th>
      <td class="area" mat-cell *matCellDef="let element">
        {{ element.area }}
      </td>
    </ng-container>

    <ng-container matColumnDef="dataCadastro">
      <th class="dataCadastro" mat-header-cell *matHeaderCellDef>
        Data Cadastro
      </th>
      <td class="dataCadastro" mat-cell *matCellDef="let element">
        {{ element.firstAccessDate || "Não cadastrado" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="ultimoAcesso">
      <th class="ultimoAcesso" mat-header-cell *matHeaderCellDef>
        Ultimo Acesso
      </th>
      <td class="ultimoAcesso" mat-cell *matCellDef="let element">
        {{ element.lastAccessDate || "Não cadastrado" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="userStatus">
      <th class="userStatus" mat-header-cell *matHeaderCellDef>
        Status da Conta
      </th>
      <td class="userStatus" mat-cell *matCellDef="let element">
        <label
          class="m-0 btn btn-sm btn-mat-icon"
          [ngClass]="{
            'btn-success': element.enabled === true,
            'btn-danger': element.enabled === false || element.enabled === null
          }"
        >
          {{ checkStatus(element.enabled) }}
        </label>
      </td>
    </ng-container>

    <ng-container matColumnDef="acoes">
      <th class="acoes" mat-header-cell *matHeaderCellDef>Ações</th>
      <td class="acoes" mat-cell *matCellDef="let element">
        <a
          matSuffix
          matTooltip="Editar"
          class="btn btn-sm btn-info text-white mr-2 button-flex-table"
          data-toggle="modal"
          data-target="#editar"
          (click)="populateEditForm(element)"
        >
          <mat-icon>edit</mat-icon>
        </a>
        <a
          matSuffix
          matTooltip="Reenviar Acesso"
          class="btn btn-sm btn-warning mr-2 button-flex-table"
          (click)="reenviarAcesso(element)"
        >
          <mat-icon>reply</mat-icon>
        </a>
        <a
          matSuffix
          matTooltip="Mudar Status da Conta"
          class="btn btn-primary btn-sm text-white mr-2 button-flex-table"
          data-toggle="modal"
          data-target="#status"
          (click)="userAccountEnableTemp = element"
        >
          <mat-icon>task_alt</mat-icon>
        </a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator
    [length]="dataSourceLength"
    [pageSize]="5"
    [pageSizeOptions]="[10, 25, 50, 100]"
    showFirstLastButtons
    aria-label="Select page of periodic elements"
  >
  </mat-paginator>
</div>

<form [formGroup]="formAdicionar" (ngSubmit)="onSubmitAdd()">
  <div class="modal fade" id="adicionar">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title mb-0" id="TituloModalCentralizado">
            Adicionar Usuário
          </p>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Fechar"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Primeiro Nome:</mat-label>
            <input
              matInput
              placeholder="Insira seu primeiro nome"
              formControlName="firstName"
              [ngClass]="{
                'is-invalid': submitted && formAdd.firstName.errors
              }"
              required
            />
            <div
              *ngIf="submitted && formAdd.firstName.errors"
              class="invalid-feedback"
            >
              <div *ngIf="formAdd.firstName.errors.required">
                É necessário adicionar o Primeiro Nome
              </div>
              <div *ngIf="formAdd.firstName.errors.minlength">
                O Primeiro Nome deve ter pelo menos 3 caracteres
              </div>
            </div>
          </mat-form-field>

          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Ultimo Nome:</mat-label>
            <input
              matInput
              placeholder="Insira seu ultimo nome"
              formControlName="lastName"
              [ngClass]="{
                'is-invalid': submitted && formAdd.lastName.errors
              }"
              required
            />
            <div
              *ngIf="submitted && formAdd.lastName.errors"
              class="invalid-feedback"
            >
              <div *ngIf="formAdd.lastName.errors.required">
                É necessário adicionar o Ultimo Nome
              </div>
              <div *ngIf="formAdd.lastName.errors.minlength">
                O Ultimo Nome deve ter pelo menos 3 caracteres
              </div>
            </div>
          </mat-form-field>

          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Email:</mat-label>
            <input
              matInput
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
              maxlength="150"
              placeholder="Insira seu email"
              formControlName="email"
              [ngClass]="{
                'is-invalid': submitted && formAdd.email.errors
              }"
              required
            />
            <div
              *ngIf="submitted && formAdd.email.errors"
              class="invalid-feedback"
            >
              <div *ngIf="formAdd.email.errors.required">
                É necessário adicionar o Email
              </div>
              <div *ngIf="formAdd.email.errors.minlength">
                O Email deve ter pelo menos 3 caracteres
              </div>
              <div *ngIf="formAdd.email.errors.pattern">
                Informe um Email valido
              </div>
            </div>
          </mat-form-field>

          <mat-form-field appearance="fill" class="w-100">
            <mat-label> Área:</mat-label>
            <mat-select
              formControlName="area"
              [ngClass]="{
                'is-invalid': submitted && formAdd.area.errors
              }"
            >
              <mat-option value="Auditoria"> Auditoria </mat-option>
              <mat-option value="Canal/Comercial"> Canal/Comercial </mat-option>
              <mat-option value="Comissionamento"> Comissionamento </mat-option>
              <mat-option value="Jurídico"> Jurídico </mat-option>
              <mat-option value="Regional"> Regional </mat-option>
              <mat-option value="Operação BPO"> Operação BPO </mat-option>
            </mat-select>
            <div
              *ngIf="submitted && formAdd.area.errors"
              class="invalid-feedback"
            >
              <div *ngIf="formAdd.area.errors.required">
                É necessário adicionar a Área
              </div>
            </div>
          </mat-form-field>

          <mat-form-field appearance="fill" class="w-100">
            <mat-label> Tipo de Usuario:</mat-label>
            <mat-select
              formControlName="userType"
              [ngClass]="{
                'is-invalid': submitted && formAdd.userType.errors
              }"
            >
              <mat-option value="OPERACAO_VIVO">Operação Vivo</mat-option>
              <mat-option value="EQUIPE_INTERNA">Equipe Interna</mat-option>
              <mat-option value="OPERACAO_BPO">Operação BPO</mat-option>
              <mat-option value="OPERADOR_VIVO_JURIDICO"
                >Operador Vivo Jurídico</mat-option
              >
              <mat-option value="OPERADOR_VIVO_AUDITORIA"
                >Operador Vivo Auditoria</mat-option
              >
            </mat-select>
            <div
              *ngIf="submitted && formAdd.userType.errors"
              class="invalid-feedback"
            >
              <div *ngIf="formAdd.userType.errors.required">
                É necessário adicionar o Tipo de Usuario
              </div>
            </div>
          </mat-form-field>

          <mat-form-field appearance="fill" class="w-100">
            <mat-label> Função Usuario:</mat-label>
            <mat-select
              formControlName="profile"
              [ngClass]="{ 'is-invalid': submitted && formAdd.profile.errors }"
            >
              <mat-option value="ROLE_ADMIN"> Administrador </mat-option>
              <mat-option value="ROLE_USER"> Usuário </mat-option>
            </mat-select>
            <div
              *ngIf="submitted && formAdd.profile.errors"
              class="invalid-feedback"
            >
              <div *ngIf="formAdd.profile.errors.required">
                É necessário adicionar a Função do Usuario
              </div>
            </div>
          </mat-form-field>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn-sm btn btn-dark mr-2 btn-mat-icon"
            data-dismiss="modal"
          >
            <mat-icon class="">close</mat-icon>
            Cancelar
          </button>
          <button
            #closeModalAdicionar
            [hidden]="true"
            type="button"
            data-dismiss="modal"
          ></button>

          <button type="submit" class="btn btn-success btn-sm button-flex">
            <mat-icon>done</mat-icon>
            Adicionar
          </button>
        </div>
      </div>
    </div>
  </div>
</form>

<!-- Modal Editar -->
<form [formGroup]="formEditar" (ngSubmit)="onSubmitUpdate()">
  <div class="modal fade" id="editar">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title mb-0" id="TituloModalCentralizado">
            Editar Usuário
          </p>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Fechar"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Primeiro Nome:</mat-label>
            <input
              matInput
              placeholder="Insira seu primeiro nome"
              formControlName="firstName"
              [ngClass]="{
                'is-invalid': submitted && formEdit.firstName.errors
              }"
              required
            />
            <div
              *ngIf="submitted && formEdit.firstName.errors"
              class="invalid-feedback"
            >
              <div *ngIf="formEdit.firstName.errors.required">
                É necessário adicionar o Primeiro Nome
              </div>
              <div *ngIf="formEdit.firstName.errors.minlength">
                O Primeiro Nome deve ter pelo menos 3 caracteres
              </div>
            </div>
          </mat-form-field>

          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Ultimo Nome:</mat-label>
            <input
              matInput
              placeholder="Insira seu ultimo nome"
              formControlName="lastName"
              [ngClass]="{
                'is-invalid': submitted && formEdit.lastName.errors
              }"
              required
            />
            <div
              *ngIf="submitted && formEdit.lastName.errors"
              class="invalid-feedback"
            >
              <div *ngIf="formEdit.lastName.errors.required">
                É necessário adicionar o Ultimo Nome
              </div>
              <div *ngIf="formEdit.lastName.errors.minlength">
                O Ultimo Nome deve ter pelo menos 3 caracteres
              </div>
            </div>
          </mat-form-field>

          <mat-form-field
            appearance="fill"
            class="w-100"
            [hidden]="user.email === formEditar.value.email"
          >
            <mat-label>Email:</mat-label>
            <input
              matInput
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
              maxlength="150"
              placeholder="Insira seu email"
              formControlName="email"
              [ngClass]="{
                'is-invalid': submitted && formEdit.email.errors
              }"
              required
            />
            <div
              *ngIf="submitted && formEdit.email.errors"
              class="invalid-feedback"
            >
              <div *ngIf="formEdit.email.errors.required">
                É necessário adicionar o Email
              </div>
              <div *ngIf="formEdit.email.errors.minlength">
                O Email deve ter pelo menos 3 caracteres
              </div>
              <div *ngIf="formEdit.email.errors.pattern">
                Informe um Email valido
              </div>
            </div>
          </mat-form-field>

          <mat-form-field appearance="fill" class="w-100">
            <mat-label> Área:</mat-label>
            <mat-select
              formControlName="area"
              [ngClass]="{
                'is-invalid': submitted && formAdd.area.errors
              }"
            >
              <mat-option value="Auditoria"> Auditoria </mat-option>
              <mat-option value="Canal/Comercial"> Canal/Comercial </mat-option>
              <mat-option value="Comissionamento"> Comissionamento </mat-option>
              <mat-option value="Jurídico"> Jurídico </mat-option>
              <mat-option value="Regional"> Regional </mat-option>
              <mat-option value="Operação BPO"> Operação BPO </mat-option>
            </mat-select>
            <div
              *ngIf="submitted && formAdd.area.errors"
              class="invalid-feedback"
            >
              <div *ngIf="formAdd.area.errors.required">
                É necessário adicionar a Área
              </div>
            </div>
          </mat-form-field>

          <mat-form-field appearance="fill" class="w-100">
            <mat-label> Tipo de Usuario:</mat-label>
            <mat-select
              formControlName="userType"
              [ngClass]="{
                'is-invalid': submitted && formEdit.userType.errors
              }"
            >
              <mat-option value="OPERACAO_VIVO">Operação Vivo</mat-option>
              <mat-option value="EQUIPE_INTERNA">Equipe Interna</mat-option>
              <mat-option value="OPERACAO_BPO">Operação BPO</mat-option>
              <mat-option value="OPERADOR_VIVO_JURIDICO"
                >Operador Vivo Jurídico</mat-option
              >
              <mat-option value="OPERADOR_VIVO_AUDITORIA"
                >Operador Vivo Auditoria</mat-option
              >
            </mat-select>
            <div
              *ngIf="submitted && formEdit.userType.errors"
              class="invalid-feedback"
            >
              <div *ngIf="formEdit.userType.errors.required">
                É necessário adicionar o Tipo de Usuario
              </div>
            </div>
          </mat-form-field>

          <mat-form-field appearance="fill" class="w-100">
            <mat-label> Função Usuario:</mat-label>
            <mat-select
              formControlName="profile"
              [ngClass]="{ 'is-invalid': submitted && formEdit.profile.errors }"
            >
              <mat-option value="ROLE_ADMIN"> Administrador </mat-option>
              <mat-option value="ROLE_USER"> Usuário </mat-option>
            </mat-select>
            <div
              *ngIf="submitted && formEdit.profile.errors"
              class="invalid-feedback"
            >
              <div *ngIf="formEdit.profile.errors.required">
                É necessário adicionar a Função do Usuario
              </div>
            </div>
          </mat-form-field>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn-sm btn btn-dark mr-2 btn-mat-icon"
            data-dismiss="modal"
          >
            <mat-icon class="">close</mat-icon>
            Cancelar
          </button>
          <button
            #closeModalEditar
            [hidden]="true"
            type="button"
            data-dismiss="modal"
          ></button>
          <button type="submit" class="btn-sm btn btn-success btn-mat-icon">
            <mat-icon class="">done</mat-icon>
            Atualizar
          </button>
        </div>
      </div>
    </div>
  </div>
</form>

<div class="modal fade" id="status">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title mb-0" id="TituloModalCentralizado">
          Mudar Status do Usuário
        </p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Fechar"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>
          {{
            userAccountEnableTemp?.enabled
              ? "Deseja Desativar esse Usuario?"
              : "Deseja Ativar esse Usuario?"
          }}
        </p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn-sm btn btn-dark mr-2 btn-mat-icon"
          data-dismiss="modal"
        >
          <mat-icon class="">close</mat-icon>
          Cancelar
        </button>
        <button
          #closeModalStatus
          [hidden]="true"
          type="button"
          data-dismiss="modal"
        ></button>
        <button
          type="submit"
          class="btn-sm btn btn-success btn-mat-icon"
          (click)="updateUserStatus()"
        >
          <mat-icon class="">done</mat-icon>
          Sim
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="importar">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title mb-0" id="TituloModalCentralizado">
          Importar Usuários
        </p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Fechar"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="d-flex flex-column">
          <div class="mt-4 pb-4 pl-3 pr-3">
            <div class="form-group">
              <div class="input-group">
                <div class="custom-file">
                  <input
                    type="file"
                    #fileUpload
                    id="fileUpload"
                    name="fileUpload"
                    multiple="multiple"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    class="custom-file-input"
                    (click)="onUploadButtonClick()"
                  />
                  <label class="custom-file-label" for="anexarDocumento"
                    >Upload</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pb-4 pl-3 pr-3">
          <h3><strong>Passos Da Importação</strong></h3>
          <p>
            1. Faça o download da planilha/lodelo de importação
            <a
              href="https://portaldecomissionamento.vivo.com.br/assets/arquivos-modelos/pc_layout_importacao_usuarios.xlsx"
              target="_blank"
              rel="noopener noreferrer"
              >Layout.xls</a
            >
          </p>
          <p>2. Preencha as colunas da planilha</p>
          <p>3. Converta para CSV</p>
          <p>4. Envie o arquivo na área no campo de Upload acima.</p>
        </div>

        <div class="pb-4 pl-3 pr-3">
          <h3><strong>Observe as colunas:</strong></h3>
          <h4><strong>Profile</strong></h4>
          <p>0 - ROLE_ADMIN("Administrador")</p>
          <p>1 - ROLE_USER("Usuário")</p>
          <h4><strong> user_type_enum</strong></h4>
          <p>0 - OPERACAO_VIVO("Operação vivo")</p>
          <p>1 - EQUIPE_INTERNA("Equipe Interna")</p>
          <p>2- OPERACAO_BPO("Operação BPO")</p>
          <p>3 - OPERADOR_VIVO_JURIDICO("Operador vivo jurídico")</p>
          <p>4 - OPERADOR_VIVO_AUDITORIA("Operador vivo auditoria")</p>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn-sm btn btn-dark mr-2 btn-mat-icon"
            data-dismiss="modal"
          >
            <mat-icon class="">close</mat-icon>
            Cancelar
          </button>
          <button
            type="submit"
            (click)="onSubmit()"
            class="btn btn-success btn-sm button-flex"
          >
            <mat-icon>task_alt</mat-icon>
            Confirmar
          </button>
          <button
            #closeModalImportar
            [hidden]="true"
            type="button"
            data-dismiss="modal"
          ></button>
        </div>
      </div>
    </div>
  </div>
</div>
