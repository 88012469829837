<app-boas-vindas></app-boas-vindas>

<!-- <div class="">
    <div class=""> -->
<!-- <app-boas-vindas></app-boas-vindas> -->
<!-- <h1>Olá, {{user.firstName}}!</h1> -->
<!-- <p>Seja bem vindo...</p> -->
<!-- <p><a routerLink="/users">Manage Users</a></p> -->
<!-- <app-noticias></app-noticias> -->
<!-- </div>
</div> -->