import { Component, OnInit, ViewChild } from "@angular/core";
import * as introJs from "intro.js/intro.js";
import {
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexLegend,
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexStroke,
  ApexTheme,
  ApexTitleSubtitle,
  ApexTooltip,
  ChartComponent,
} from "ng-apexcharts";

import { User } from "../_models/user";
import { AccountService } from "../_services";
import { TicketService } from "../_services/ticket.service";

// export type ChartOptions = {
//   series: ApexAxisChartSeries;
//   chart: ApexChart;
//   xaxis: ApexXAxis;
//   title: ApexTitleSubtitle;
// };

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  colors: any[];
  theme: ApexTheme;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  legend: ApexLegend;
  tooltip: ApexTooltip;
};

interface ticketCharts {
  qtdAberto: number;
  qtdAnalise: number;
  qtdAprovado: number;
  qtdCancelado: number;
}

@Component({
  selector: "app-boas-vindas",
  templateUrl: "./boas-vindas.component.html",
  styleUrls: ["./boas-vindas.component.css"],
})
export class BoasVindasComponent implements OnInit {
  // titulo = "Seja bem-vindo ao Portal de Gestão Documental!";
  titulo = "Portal de Comissionamento";
  subtitulo = "";
  response: any;
  user: User;
  userId: number;

  ticketsGeralChart: ticketCharts = {} as ticketCharts;
  MyTicketsGeralChart: ticketCharts = {} as ticketCharts;

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  public MyTickets_chartOptions: Partial<ChartOptions>;

  constructor(
    private accountService: AccountService,
    private ticketService: TicketService
  ) {
    this.user = this.accountService.userValue.user;
    this.userId = this.user.id;

    this.chartOptions = {
      series: [0, 0, 0, 0],

      chart: {
        type: "donut",
      },
      labels: ["Em Aberto", "Em Andamento", "Concluído", "Cancelado"],
      colors: ["#17a2b8", "#ffc107", "#5cb85c", "#dc3545"],
      legend: {
        fontWeight: 500,
      },

      fill: {
        colors: ["#17a2b8", "#ffc107", "#5cb85c", "#dc3545"],
      },
      dataLabels: {
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          opacity: 0.45,
        },
      },

      tooltip: {
        enabled: true,
        enabledOnSeries: undefined,
        shared: true,
        followCursor: false,
        intersect: false,
        inverseOrder: false,
        custom: undefined,
        fillSeriesColor: false,
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };

    this.MyTickets_chartOptions = {
      series: [0, 0, 0, 0],

      chart: {
        type: "donut",
      },
      labels: ["Em Aberto", "Em Andamento", "Concluído", "Cancelado"],
      colors: ["#17a2b8", "#ffc107", "#5cb85c", "#dc3545"],
      legend: {
        fontWeight: 500,
      },

      fill: {
        colors: ["#17a2b8", "#ffc107", "#5cb85c", "#dc3545"],
      },
      dataLabels: {
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          opacity: 0.45,
        },
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };

    // this.chartOptions = {
    //   series: [25, 15, 44, 55, 41, 17],
    //   chart: {
    //     width: "100%",
    //     type: "pie"
    //   },
    //   labels: [
    //     "Monday",
    //     "Tuesday",
    //     "Wednesday",
    //     "Thursday",
    //     "Friday",
    //     "Saturday"
    //   ],
    //   theme: {
    //     monochrome: {
    //       enabled: true
    //     }
    //   },
    //   title: {
    //     text: "Number of leads"
    //   },
    //   responsive: [
    //     {
    //       breakpoint: 480,
    //       options: {
    //         chart: {
    //           width: 200
    //         },
    //         legend: {
    //           position: "bottom"
    //         }
    //       }
    //     }
    //   ]
    // };

    // this.chartOptions = {
    //   series: [
    //     {
    //       name: "My-series",
    //       data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
    //     }
    //   ],
    //   chart: {
    //     height: 350,
    //     type: "bar"
    //   },
    //   title: {
    //     text: "My First Angular Chart"
    //   },
    //   xaxis: {
    //     categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"]
    //   }
    // };
  }

  getAllTickets() {
    this.ticketService.getAllTickets().subscribe((data) => {
      $("#btnFlutuante").css("display", "block");
      this.ticketsGeralChart.qtdAberto = data.filter(
        (ticket) => ticket.statusEnum == "EM_ABERTO"
      ).length;
      this.ticketsGeralChart.qtdAnalise = data.filter(
        (ticket) => ticket.statusEnum == "EM_ANDAMENTO"
      ).length;
      this.ticketsGeralChart.qtdAprovado = data.filter(
        (ticket) => ticket.statusEnum == "CONCLUIDO"
      ).length;
      this.ticketsGeralChart.qtdCancelado = data.filter(
        (ticket) => ticket.statusEnum == "CANCELADO"
      ).length;

      this.chartOptions.series = [
        this.ticketsGeralChart.qtdAberto,
        this.ticketsGeralChart.qtdAnalise,
        this.ticketsGeralChart.qtdAprovado,
        this.ticketsGeralChart.qtdCancelado,
      ];
    });
  }

  getAllMyTickets() {
    this.ticketService
      .getTicketsByUser(
        this.userId,
        "TODOS",
        0,
        100,
        "id",
        "des",
        "",
        "",
        "",
        0,
        "",
        ""
      )
      .subscribe((data) => {
        const content = data["content"];
        this.MyTicketsGeralChart.qtdAberto = content.filter(
          (ticket) => ticket.statusEnum == "EM_ABERTO"
        ).length;
        this.MyTicketsGeralChart.qtdAnalise = content.filter(
          (ticket) => ticket.statusEnum == "EM_ANDAMENTO"
        ).length;
        this.MyTicketsGeralChart.qtdAprovado = content.filter(
          (ticket) => ticket.statusEnum == "CONCLUIDO"
        ).length;
        this.MyTicketsGeralChart.qtdCancelado = content.filter(
          (ticket) => ticket.statusEnum == "CANCELADO"
        ).length;

        this.MyTickets_chartOptions.series = [
          this.MyTicketsGeralChart.qtdAberto,
          this.MyTicketsGeralChart.qtdAnalise,
          this.MyTicketsGeralChart.qtdAprovado,
          this.MyTicketsGeralChart.qtdCancelado,
        ];
      });
  }

  ngOnInit(): void {
    this.getUserValue();
    // this.getAllTickets();
    // this.getAllMyTickets();

    // $("body").delegate("a", "click", function () {
    //   $("aside").animate({ scrollTop: 0 }, "slow");
    // });
  }

  getUserValue() {
    // console.log(this.user.lastName)
    // if (this.user.firstAccess) {
    //   console.log("aparece intro");
    // } else {
    //   console.log("não aparece intro");
    //   document.getElementById("tour").style.display = "none";
    // }
  }

  introJS = introJs();
  // carrega a uma ação de um botão
  startSteps(): void {
    this.introJS
      .setOptions({
        steps: [
          {
            element: "#step-menu-inicio",
            intro: "Apresenta as últimas notícias e os artigos relacionados.",
            position: "right",
          },
          {
            element: "#step-menu-solicitacao",
            intro:
              "Disponibiliza os ambientes de solicitação e acompanhamento das demandas. ",
            position: "right",
          },
          {
            element: "#step-menu-projetos",
            intro: "Apresenta os projetos realizados e em andamento.",
            position: "right",
          },
          {
            element: "#step-menu-dashboards",
            intro: "Apresenta os indicadores gerenciais, gráficos e números.",
            position: "right",
          },
          {
            element: "#step-menu-comissao",
            intro:
              "Apresenta informações sobre a avaliação da prescrição legal e precaução organizacional dos documentos produzidos.",
            position: "right",
          },
          {
            element: "#step-menu-normativas",
            intro:
              "Apresenta as diretrizes e boas práticas para a Gestão de Documentos da Telefônica Brasil.",
            position: "right",
          },
          {
            element: "#step-menu-acesso-rapido",
            intro:
              "Encontre os principais itens e informações do Portal de Governança Documental.",
            position: "right",
          },

          {
            element: "#step-footer-sobre",
            intro:
              "Apresenta as finalidades e objetivos do Portal de Governança Documental",
            position: "top",
          },
          {
            element: "#step-footer-quem-somos",
            intro:
              "Apresenta os responsáveis e as atividades realizadas na área de Gestão Documental da Telefônica Brasil.",
            position: "top",
          },
          {
            element: "#step-footer-faq",
            intro:
              "Apresenta as Perguntas frequentes no âmbito da Gestão Documental e do Portal de Governança da Telefônica Brasil.",
            position: "top",
          },
          {
            element: "#step-footer-contato",
            intro:
              "Apresenta os contatos para dúvidas e auxílio referente aos procedimentos de Gestão Documental.",
            position: "top",
          },
          {
            element: "#step-footer-glossario",
            intro: "Apresenta o Glossário de Termos de Governança Documental.",
            position: "top",
          },
        ],
        hidePrev: true,
        hideNext: false,
      })
      .start();
  }
}
