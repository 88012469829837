<p class="text-muted mt-4 mb-1">
  <span class="text-primary">Portal de Comissionamento</span>
</p>

<h1 class="text-muted">Cadastrar Categoria para Dashboards</h1>

<div class="row mb-5">
  <div class="col-sm-12 col-md-8 col-lg-12">
    <form [formGroup]="form" (ngSubmit)="createCategory()">
      <label for="fileUplaod" class="text-muted">Imagem de Capa</label>

      <div *ngIf="thumbFiles.length > 0">
        <ul class="files">
          <li class="files__file" *ngFor="let file of thumbFiles">
            <img src="{{ thumbImage }}" alt="" class="files__file__thumb" />
            <div>
              <p class="text-muted">{{ file.name }}</p>
              <span>{{ formatFileSize(file.size) }}</span>
            </div>
          </li>
        </ul>
      </div>

      <div class="form-group">
        <div class="input-group">
          <div class="custom-file">
            <input
              type="file"
              #thumbUpload
              name="thumbUpload"
              accept="image/*"
              class="custom-file-input"
              (change)="getThumbFile()"
            />
            <label class="custom-file-label" for="thumbUpload">Upload</label>
          </div>
        </div>
      </div>
      <p class="text-muted" style="margin-top: -8px; font-weight: 400">
        É recomendado inserir imagens com no mínimo 1080px de largura e
        resolução 16:9
      </p>

      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Nome da categoria:</mat-label>
        <input matInput type="text" formControlName="name" required />
      </mat-form-field>

      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Descrição:</mat-label>
        <textarea
          matInput
          type="text"
          formControlName="description"
          required
        ></textarea>
      </mat-form-field>

      <button
        type="submit"
        class="btn btn-sm btn-primary"
        [disabled]="form.invalid || isLoading"
      >
        <div class="button-flex">
          <div class="uploader-status">
            <mat-progress-spinner
              *ngIf="isLoading"
              [diameter]="24"
              [strokeWidth]="4"
              [mode]="mode"
            ></mat-progress-spinner>
          </div>
          <mat-icon *ngIf="!isLoading">post_add</mat-icon>
          {{ isLoading ? "Enviando Categoria..." : "Cadastrar Categoria" }}
        </div>
      </button>
    </form>
  </div>
</div>
