import { User } from "src/app/_models";
import { AccountService } from "src/app/_services";
import { WikiService } from "src/app/_services/wiki.service";
import { environment } from "src/environments/environment";

import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import * as $ from "../../../assets/jquery-3.5.1.min";

@Component({
  selector: "app-listagem",
  templateUrl: "./listagem.component.html",
  styleUrls: ["./listagem.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class ListarArquivosComponent implements OnInit {
  public href: string = "";

  user: User;
  token: string;
  wikis: any[];

  categories: any[] = [];

  wikiCount: any[];
  activeFilter: string = "Nenhum filtro aplicado";
  hasNoContent: boolean = false;

  anexoDownload: string = environment.apiUrl + "/download/anexo/";
  searchInput: string = "";
  display = false;
  area: string = "";

  constructor(
    private accountService: AccountService,
    private wikiService: WikiService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.user = this.accountService.userValue.user;
    this.token = this.accountService.userValue.token;
    const navigation = this.router.getCurrentNavigation();
    this.searchInput = navigation?.extras?.state?.searchInput;
    this.area = navigation?.extras?.state?.area;
  }

  ngOnInit(): void {
    this.href = this.router.url;
    console.log(this.router.url);

    this.wikiService
      .getWikiCount(this.user.userType)
      .subscribe((res) => (this.wikiCount = res));
  }

  displayedColumns: string[] = [
    "originalFileName",
    "creationDt",
    "userUpload",
    "actions",
  ];

  getImage(imageId: string) {
    return `${environment.apiUrl}/download/anexo/${imageId}?token=${this.token}`;
  }

  downloadFile(fileId: any) {
    this.accountService.getTokenWithLowExpiration().subscribe((data) => {
      const urlToDownloadFile: string = `${this.anexoDownload}${fileId}?token=${data.token}`;

      window.open(urlToDownloadFile);
    });
  }

  getCategories() {
    this.wikiService.getCategories().subscribe((data) => {
      this.categories = data;
    });
  }

  getFileType(fileType: string) {
    switch (fileType) {
      case "link":
        return "image";
      case "documento":
        return "description";
      case "video":
        return "videocam";
    }
  }

  redirect(nextRouter) {
    this.router.navigate([`/${nextRouter}`], { relativeTo: this.route });
  }

  setValue(event) {
    this.searchInput = event.target.value;
  }

  setValueCategory(event) {
    this.area = event.target.value;
  }

  search({
    title,
    categoryId,
    area,
    areaPermissions = this.user.userType,
    onInit = false,
  }: {
    title?: string;
    categoryId?: number;
    area?: string;
    areaPermissions?: string;
    onInit?: boolean;
  }) {
    switch (area) {
      case "projetos-gestao-regras":
        this.activeFilter = "Projetos e Gestão de Regras";
        break;
      case "apuracao-resultados":
        this.activeFilter = "Apuração de Resultados";
        break;
      case "estrategia-comissionamento":
        this.activeFilter = "Estratégia de Comissionamento";
        break;
      default:
        this.activeFilter = "Nenhum filtro aplicado";
    }

    this.display = true;

    this.wikiService
      .getWikiFiltered(title, categoryId, area, areaPermissions)
      .subscribe((res) => {
        if (res.empty) {
          this.hasNoContent = true;
        } else {
          this.hasNoContent = false;
        }

        this.wikis = res.content.map((wiki) => {
          if (wiki.anexo == null) {
            wiki.thumbnail = "0";
          } else {
            wiki.thumbnail = this.getImage(wiki.anexo.id);
          }

          return wiki;
        });
      });

    if (!onInit) {
      $(".inputSearch").css("display", "none");
      $(".topic-help").css("display", "none");
      $(".divReturnSearh").css("display", "block");
      $(".returnSearh").css("display", "block");
      $(".aside").css("display", "block");
    }

    function hasClass(element, cls) {
      return (" " + element.className + " ").indexOf(" " + cls + " ") > -1;
    }

    var el = document.getElementById("wrapper");
    console.log(hasClass(el, "toggled"));

    // desktop
    if (screen.width > 768 && hasClass(el, "toggled") == false) {
      $("#page-content-wrapper").removeClass("pcw-16");
      $("#page-content-wrapper").addClass("pcw-0");
    } else if (screen.width > 768 && hasClass(el, "toggled") == true) {
      $("#page-content-wrapper").removeClass("pcw-0");
      $("#page-content-wrapper").addClass("pcw-16");
    }

    // mobile
    if (screen.width < 768 && hasClass(el, "toggled") == false) {
      $("#wrapper").toggleClass("toggled");

      $("#page-content-wrapper").removeClass("pcw-16");
      $("#page-content-wrapper").addClass("pcw-0");
    } else if (screen.width < 768 && hasClass(el, "toggled") == true) {
      $("#page-content-wrapper").removeClass("pcw-0");
      $("#page-content-wrapper").addClass("pcw-16");
    }

    // desktop
    if (screen.width == 768 && hasClass(el, "toggled") == false) {
      $("#page-content-wrapper").removeClass("pcw-16");
      $("#page-content-wrapper").addClass("pcw-0");
    } else if (screen.width == 768 && hasClass(el, "toggled") == true) {
      $("#page-content-wrapper").removeClass("pcw-0");
      $("#page-content-wrapper").addClass("pcw-16");
    }

    $("#wrapper").toggleClass("toggled");
  }

  gridTwo() {
    $("#row-m").removeClass("row-m");

    $("#col1").removeClass("col-lg-3");
    $("#col1").removeClass("col-md-4");
    $("#col2").removeClass("col-lg-9");
    $("#col2").removeClass("col-md-8");

    $("#col1").addClass("col-md-6");
    $("#col2").addClass("col-md-6");
  }
}
