<div class="centralizar">
    <div class="container login">
        <div class="row ">
            <div class="col-lg-6 offset-lg-3 bg-login">
                <h1 class="mb-2 text-primary">Login</h1>
                <h3 class="mb-4">Seja bem vindo ao Portal de Gestão Documental</h3>
                <form [formGroup]="formLogin" novalidate>

                    <mat-list>
                        <mat-list-item>
                            <mat-form-field class="example-full-width" appearance="fill">
                                <mat-label>Usuário ou e-mail</mat-label>
                                <input type="email" matInput formControlName="email" placeholder="Usuário ou e-mail...">
                                <mat-icon matSuffix>sentiment_satisfied_alt</mat-icon>
                            </mat-form-field>
                        </mat-list-item>

                        <mat-list-item>
                            <mat-form-field appearance="fill">
                                <mat-label>Digite sua senha</mat-label>
                                <input matInput formControlName="password" [type]="hide ? 'password' : 'text'"
                                    placeholder="*********">
                                <button mat-icon-button matSuffix (click)="hide = !hide"
                                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                            </mat-form-field>
                        </mat-list-item>
                        <span matTooltip="Senha deve conter ao menos 6 caracteres">
                            <mat-icon>help</mat-icon>
                        </span>

                        <mat-list-item>
                            <section class="example-section mt-2 mb-4 campoCheckBox">
                                <div class="row conteudo-flex">
                                    <div class="col-lg-6">
                                        <mat-checkbox class="example-margin" [(ngModel)]="checked">Checked
                                        </mat-checkbox>
                                    </div>
                                    <div class="col-lg-6 text-right">
                                        <a routerLink="/cadastro-user" class="">Cadastre-se</a>
                                    </div>
                                </div>
                            </section>
                        </mat-list-item>


                        <mat-list-item>
                            <button mat-flat-button class="btn btn-primary mr-1 mb-1 btn-block"
                                [disabled]="formLogin.invalid" (click)="logar()">Login</button>
                        </mat-list-item>

                    </mat-list>


                </form>

            </div>
        </div>
    </div>
</div>