import { of } from "rxjs";
import { catchError, first, map } from "rxjs/operators";

import { HttpErrorResponse, HttpEventType } from "@angular/common/http";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";

import { User } from "../_models";
import { CustomValidators } from "../_models/custom-validators";
import { AccountService, AlertService } from "../_services";
import { UploadService } from "../_services/upload.service";
import { MyErrorStateMatcher } from "../registro/registro.component";

//import * as $ from 'jquery';
declare var $: any;

@Component({
  selector: "app-perfil-usuario",
  templateUrl: "./perfil-usuario.component.html",
  styleUrls: ["./perfil-usuario.component.css"],
})
export class PerfilUsuarioComponent implements OnInit {
  @ViewChild("fileUpload", { static: false }) fileUpload: ElementRef;
  files = [];

  response: string = "";
  currentUser: any = null;
  loading = false;
  submitted = false;
  hide = true;
  user: User;
  //formEditUser: FormGroup;
  formProfile: FormGroup;
  formResetPassword: FormGroup;
  email = new FormControl("", [Validators.required, Validators.email]);

  form: FormGroup;

  // limpar campos
  // onReset(): void {
  //   this.submitted = false;
  //   this.form.reset();
  // }

  getErrorMessage() {
    if (this.email.hasError("required")) {
      return "You must enter a value";
    }

    return this.email.hasError("email") ? "Not a valid email" : "";
  }

  emailFormControl = new FormControl("", [
    Validators.required,
    Validators.email,
  ]);

  matcher = new MyErrorStateMatcher();

  constructor(
    private uploadService: UploadService,
    public fb: FormBuilder,
    private accountService: AccountService,
    private cd: ChangeDetectorRef,
    private alertService: AlertService
  ) {
    this.user = this.accountService.userValue.user;
  }

  /*##################### Registration Form #####################*/
  registrationForm = this.fb.group({
    file: [null],
  });

  /*########################## File Upload ########################*/
  @ViewChild("fileInput") el: ElementRef;
  imageUrl: any;
  //imageUrl: any = '../../assets/perfil/zac.jpg';
  editFile: boolean = true;
  removeUpload: boolean = false;

  // uploadFile(event) {
  //   let reader = new FileReader(); // HTML5 FileReader API
  //   let file = event.target.files[0];
  //   if (event.target.files && event.target.files[0]) {
  //     reader.readAsDataURL(file);

  //     // When file uploads set it to file formcontrol
  //     reader.onload = () => {
  //       this.imageUrl = reader.result;
  //       this.registrationForm.patchValue({
  //         file: reader.result
  //       });
  //       this.editFile = false;
  //       this.removeUpload = true;
  //     }
  //     // ChangeDetectorRef since file is loading outside the zone
  //     this.cd.markForCheck();
  //   }
  // }

  // Function to remove uploaded file

  // Submit Registration Form
  // onSubmit() {
  //   this.submitted = true;
  //   if (!this.registrationForm.valid) {
  //     alert('Please fill all the required fields to create a super hero!')
  //     return false;
  //   } else {
  //     console.log(this.registrationForm.value)
  //   }
  // }

  ngOnInit(): void {
    try {
      this.currentUser = this.accountService.userValue.user;
    } catch (err) {}

    this.imageUrl =
      "https://dattasign.com.br:9904/download/user/" +
      this.currentUser.photo +
      "?token=" +
      this.accountService.userValue.token;

    this.formResetPassword = this.fb.group(
      {
        token: this.accountService.userValue.token,
        email: [this.currentUser.email],
        password: [
          null,
          Validators.compose([
            Validators.required,
            // check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, {
              hasNumber: true,
            }),
            // check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true,
            }),
            // check whether the entered password has a lower case letter
            CustomValidators.patternValidator(/[a-z]/, {
              hasSmallCase: true,
            }),
            // check whether the entered password has a special character
            CustomValidators.patternValidator(
              /[ ~!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
              {
                hasSpecialCharacters: true,
              }
            ),
            Validators.minLength(8),
          ]),
        ],
        confirmPassword: [null, Validators.compose([Validators.required])],
      },
      {
        // check whether our password and confirm password match
        validator: CustomValidators.passwordMatchValidator,
      }
    );

    this.formProfile = this.fb.group({
      userId: [0],
      phone: [
        this.currentUser.phone,
        [Validators.required, Validators.minLength(10)],
      ],
    });

    if (this.user.tempPassword) {
      $(document).ready(function () {
        $("#alterarSenha").modal(
          { backdrop: "static", keyboard: false },
          "show"
        );
      });
    }
  }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.formResetPassword.invalid) {
      return;
    }
    this.loading = true;

    this.accountService
      .updatePasswordWithToken(this.formResetPassword.value)
      .pipe(first())
      .subscribe(
        (data) => {
          this.loading = false;

          if (data["validated"]) {
            $("#alterarSenha").modal("hide");

            this.response = "";
            this.clean();
            this.updateUser(false);

            this.alertService.success(data["message"], {
              keepAfterRouteChange: true,
            });
          } else {
            this.response = data["message"];
          }

          if (this.user.tempPassword) {
            this.user.tempPassword = false;
            this.updateUser(false);
          }
        },
        (error) => {
          this.loading = false;
          this.response = "Ocorreu uma falha ao tentar atualizar a sua senha.";
        }
      );
  }

  removeUploadedFile() {
    let newFileList = Array.from(this.el.nativeElement.files);
    this.imageUrl =
      "https://i.pinimg.com/236x/d6/27/d9/d627d9cda385317de4812a4f7bd922e9--man--iron-man.jpg";
    this.editFile = true;
    this.removeUpload = false;
    this.registrationForm.patchValue({
      file: [null],
    });
  }

  uploadFile(file) {
    console.log(this.currentUser.id);
    console.log(this.currentUser.photo);
    const formData = new FormData();
    formData.append("file", file.data);
    file.inProgress = true;
    this.uploadService
      .uploadPhoto(this.currentUser.id, formData)
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round((event.loaded * 100) / event.total);
              console.log(file.progress);
              console.log(file);
              break;
            case HttpEventType.Response:
              console.log(event);
              return event;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          file.inProgress = false;
          return of(`${file.data.name} upload failed.`);
        })
      )
      .subscribe((event: any) => {
        if (typeof event === "object") {
          console.log(event.body);
          this.updateUser(true);
        }
      });
  }

  updateUser(reload) {
    this.accountService
      .getByEmail(this.currentUser.email)
      .pipe(first())
      .subscribe(
        (data) => {
          this.currentUser = data;

          this.ngOnInit();
          if (reload) {
            window.location.reload();
          }
        },
        (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      );
  }

  private uploadFiles() {
    this.fileUpload.nativeElement.value = "";
    this.files.forEach((file) => {
      this.uploadFile(file);
    });
  }

  updateProfile() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    this.loading = true;

    var user = this.formProfile.value;

    user.userId = this.currentUser.id;

    this.accountService
      .updateUser(user)
      .pipe(first())
      .subscribe(
        (data) => {
          this.loading = false;

          this.alertService.success("Usuário(a) atualizado(a) com sucesso!", {
            keepAfterRouteChange: true,
          });

          this.updateUser(false);
          //this.router.navigate(['../login'], { relativeTo: this.route });
        },
        (error) => {
          this.loading = false;
          //$("#alterarSenha").modal("hide");
          this.alertService.error(
            "Ocorreu uma falha ao tentar atualizar o(a) usuário(a)!"
          );
        }
      );
  }

  onClick() {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({ data: file, inProgress: false, progress: 0 });
      }
      this.uploadFiles();
    };
    fileUpload.click();
  }

  clean() {
    this.formResetPassword.reset();
  }
}
