import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })

export class EscolhaAgendamentoService{

    constructor(private http: HttpClient) { } 

    editAgendamento(agendamentoId, definicaoData){
      return this.http.put<any>(`${environment.apiUrl}/agendamento-entrevista/${agendamentoId}`,definicaoData);
  }
    
}