import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";

import { VisualizerMeusComponent } from "./visualizer-meus/visualizer-meus.component";
import { InsumosComponent } from "./insumos.component";
import { InsumoVisualizerComponent } from "./insumo-visualizer/insumo-visualizer.component";
import { InsumoFieldConfigComponent } from "./insumo-field-config/insumo-field-config.component";
import { CriarComponent } from "./criar/criar.component";
import { ConsultarInsumosComponent } from "./consultar-insumos/consultar-insumos.component";

const routes: Routes = [
  {
    path: "",
    component: InsumosComponent,
    children: [
      { path: "", component: CriarComponent },
      { path: "criar", component: CriarComponent },
      { path: "visualizar/:id", component: InsumoVisualizerComponent },
      { path: "visualizar", component: VisualizerMeusComponent },
      { path: "fields-configure", component: InsumoFieldConfigComponent },
      { path: "consultar", component: ConsultarInsumosComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InsumosRoutingModule {}
