import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "segmentTicketReturn",
})
export class SegmentTicketReturnPipe implements PipeTransform {
  transform(array: any[]) {
    return array?.filter((item) => item.field.id === 2)[0]?.value;
  }
}
