<p class="text-muted mt-4 mb-1">
  <span class="text-primary">Portal de Comissionamento</span>
</p>

<h1 class="text-muted">Visualização Insumos</h1>

<form [formGroup]="formFiltro">
  <div class="row mb-3">
    <div class="col-sm-6">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Nome do Insumo: </mat-label>
        <input type="text" matInput formControlName="insumoName" />
      </mat-form-field>
    </div>

    <div class="col-sm-6">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Email do Responsável: </mat-label>
        <input
          type="text"
          matInput
          autocomplete="new-responsavel"
          aria-label="Email do(a) Responsável"
          [matAutocomplete]="autoFiltro"
          [formControl]="searchUserCtrlFiltro"
          autocomplete="off"
          (click)="resetField(3)"
        />
        <mat-autocomplete #autoFiltro="matAutocomplete">
          <mat-option *ngIf="isLoading" class="is-loading"
            >Carregando...</mat-option
          >
          <ng-container *ngIf="!isLoading">
            <mat-option
              *ngFor="let user of filteredUsersFilter"
              [value]="user.email"
              (click)="setEmailResponsavel(user)"
            >
              {{ user.email }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div class="col-sm-4">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Segmento:</mat-label>
        <mat-select formControlName="segment">
          <mat-option value="B2C">B2C</mat-option>
          <mat-option value="B2B">B2B</mat-option>
          <mat-option value="TODOS">Todos</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-sm-4">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Status: </mat-label>
        <mat-select formControlName="status">
          <mat-option value="VIGENTE">Vigente</mat-option>
          <mat-option value="ENCERRADO">Encerrado</mat-option>
          <mat-option value="INATIVO">Inativo</mat-option>
          <mat-option value="TODOS">Todos</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-sm-4">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Competência: </mat-label>
        <input
          type="text"
          matInput
          formControlName="competence"
          placeholder="MM/YYYY"
          mask="00/0000"
          [dropSpecialCharacters]="false"
        />
      </mat-form-field>
    </div>
    <div class="col-sm-12">
      <!-- <button class="btn btn-sm btn-primary" (click)="filter()">
        <div class="button-flex">
          <mat-icon>filter_alt</mat-icon>
          Filtrar
        </div>
      </button> -->
      <button class="btn btn-secondary btn-sm ml-2" (click)="onReset()">
        <div class="button-flex">
          <mat-icon>backspace</mat-icon>
          Limpar
        </div>
      </button>

      <button
        type="button"
        class="btn btn-rose btn-sm ml-2"
        data-toggle="modal"
        data-target="#export"
      >
        <div class="button-flex">
          <mat-icon>file_download</mat-icon>
          Exportar
        </div>
      </button>

      <!-- <button
        type="button"
        class="btn btn-sm btn-warning ml-2"
        data-toggle="modal"
        data-target="#importarInsumos"
      >
        <div class="button-flex">
          <mat-icon class="mr-1">post_add</mat-icon>
          Importar Insumos
        </div>
      </button> -->
    </div>

    <div class="col-sm-12 mt-4 mb-0">
      <button
        [hidden]="editPermission()"
        matTooltip="Encerrar"
        data-toggle="modal"
        data-target="#encerrar"
        type="button"
        class="btn btn-rose btn-sm ml-0"
      >
        <div class="button-flex">
          <mat-icon>file_download</mat-icon>
          Encerrar Insumos Selecionados
        </div>
      </button>
      <button
        #encerrados
        data-toggle="modal"
        data-target="#encerrados"
        type="button"
        class="btn btn-rose btn-sm ml-0"
        style="display: none"
      ></button>

      <button
        #encerradosNada
        data-toggle="modal"
        data-target="#encerradosnada"
        type="button"
        class="btn btn-rose btn-sm ml-0"
        style="display: none"
      ></button>
    </div>
  </div>
</form>

<div class="row mb-5" *ngIf="start">
  <div class="col-sm-12">
    <p>Lista de Insumos:</p>

    <table
      id="FormTable"
      mat-table
      [dataSource]="dataSource"
      multiTemplateDataRows
      class="mat-elevation-z8"
    >
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef class="select">
          <mat-checkbox
            color="primary"
            (change)="$event ? toggleAllRows() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row" class="select">
          <mat-checkbox
            color="primary"
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
            [aria-label]="checkboxLabel(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <!-- <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
                    <th mat-header-cell *matHeaderCellDef> {{column}} </th>
                    <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
                </ng-container> -->

      <ng-container matColumnDef="name">
        <th class="name" mat-header-cell *matHeaderCellDef>Nome Insumo</th>
        <td class="name" mat-cell *matCellDef="let element">
          {{ element.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="publico">
        <th class="publico" mat-header-cell *matHeaderCellDef>Público</th>
        <td class="publico" mat-cell *matCellDef="let element">
          {{ element.publico | cap }}
        </td>
      </ng-container>

      <ng-container matColumnDef="segment">
        <th class="segment" mat-header-cell *matHeaderCellDef>Segmento</th>
        <td class="segment" mat-cell *matCellDef="let element">
          {{ element.segment }}
        </td>
      </ng-container>

      <ng-container matColumnDef="competence">
        <th class="competence" mat-header-cell *matHeaderCellDef>
          Competencia
        </th>
        <td class="competence" mat-cell *matCellDef="let element">
          {{ element.competence }}
        </td>
      </ng-container>

      <ng-container matColumnDef="deadline">
        <th class="deadline" mat-header-cell *matHeaderCellDef>Data Limite</th>
        <td class="deadline" mat-cell *matCellDef="let element">
          {{ element.deadline | date : "dd/MM/yyyy" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="dataEntrega">
        <th mat-header-cell *matHeaderCellDef>Data de entrega</th>
        <td mat-cell *matCellDef="let element">
          {{ element.dataEntrega | date : "dd/MM/yyyy" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="version">
        <th class="deadline" mat-header-cell *matHeaderCellDef>Versão</th>
        <td class="deadline" mat-cell *matCellDef="let element">
          {{ element.version }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th class="actions" mat-header-cell *matHeaderCellDef>Ações</th>
        <td class="actions" mat-cell *matCellDef="let element">
          <!-- <button class="btn btn-sm btn-primary mr-2">
              <mat-icon>visibility</mat-icon>
            </button>

            <button matSuffix matTooltip="Histórico" (click)="getInsumoById(element.id)" data-toggle="modal"
              data-target="#historico" class="btn btn-sm btn-primary mr-2 utton-flex-table">
              <mat-icon>history</mat-icon>
            </button>

            <button *ngIf="element.createdUser.email == user.email" matTooltip="Excluir" data-toggle="modal"
              data-target="#excluir" class="btn btn-sm btn-primary mr-2" (click)="tempInsumoId = element.id">
              <mat-icon>highlight_off</mat-icon>
            </button> -->
          <button
            [disabled]="
              element.status === 'INATIVO' || element.status === 'ENCERRADO'
            "
            data-toggle="modal"
            data-target="#confirmDoc"
            class="btn btn-sm btn-success mr-2 mb-1 mt-1"
            (click)="expandedElement = element; uploadAnexoInsumo(element)"
          >
            <mat-icon>upload</mat-icon>
          </button>

          <!-- text-white -->
          <button
            [disabled]="element.status === 'INATIVO'"
            (click)="
              expandedElement = element; downloadFileInsumoContent(element)
            "
            class="btn btn-sm btn-warning mr-2 mb-1 mt-1"
          >
            <mat-icon>download</mat-icon>
          </button>

          <button
            matSuffix
            data-toggle="modal"
            data-target="#historico"
            class="btn btn-sm btn-dark mb-1 mt-1"
            (click)="expandedElement = element; getInsumoById(element.id)"
          >
            <mat-icon>history</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container class="bg-primary" matColumnDef="expandedDetail">
        <td
          mat-cell
          *matCellDef="let element"
          [attr.colspan]="columnsToDisplay.length"
        >
          <div
            class="element-detail"
            [@detailExpand]="
              element == expandedElement ? 'expanded' : 'collapsed'
            "
          >
            <div class="container-fluid p-0 m-0 border-top">
              <h3 class="text-muted mt-4 mb-2">Detalhes do Insumo:</h3>
              <div class="row">
                <div class="col-sm-4">
                  <p class="fw-600">
                    <span class="text-muted">Criador do insumo:</span>
                    <br />
                    <span class="fw-400 text-secondary"
                      >{{ element.createdUser.email }}
                    </span>
                  </p>
                  <p class="fw-600">
                    <span class="text-muted">Responsável pelo insumo:</span>
                    <br />
                    <span class="fw-400 text-secondary"
                      >{{ element.responsibleUpload.email }}
                    </span>
                  </p>
                  <p class="fw-600">
                    <span class="text-muted">Responsável pela análise:</span>
                    <br />
                    <span class="fw-400 text-secondary"
                      >{{ element.analysisUpload.email }}
                    </span>
                  </p>
                </div>

                <div class="col-sm-4">
                  <p class="fw-600" *ngIf="element.observations">
                    <span class="text-muted">Observações:</span>
                    <br />
                    <span class="fw-400 text-secondary"
                      >{{ element.observations }}
                    </span>
                  </p>
                  <p class="fw-600" *ngIf="element.insumoType">
                    <span class="text-muted">Tipo de Insumo:</span>
                    <br />
                    <span class="fw-400 text-secondary"
                      >{{ element.insumoType }}
                    </span>
                  </p>
                  <p class="fw-600" *ngIf="element.status">
                    <span class="text-muted"> Área do Insumo:</span>
                    <br />
                    <span class="text-secondary fw-400">
                      {{ element.area | cap }}
                    </span>
                  </p>
                </div>

                <div class="col-sm-4">
                  <p class="fw-600">
                    <span class="text-muted">Dias Úteis em Atraso:</span>
                    <br />
                    <span class="text-secondary fw-400">
                      {{ element.sla }} dias
                    </span>
                  </p>

                  <p class="fw-600">
                    <span class="text-muted">Competência:</span>
                    <br />
                    <span class="text-secondary fw-400">
                      {{ element.competence }}
                    </span>
                  </p>

                  <p *ngIf="element.numeroSd" class="fw-600">
                    <span class="text-muted">Número Sd:</span>
                    <br />
                    <span class="text-secondary fw-400">
                      {{ element.numeroSd }}
                    </span>
                  </p>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-sm-12">
                  <h3 class="text-muted mt-2">Ações:</h3>
                  <button
                    [hidden]="editPermission()"
                    matTooltip="Alterar Responsavel"
                    data-toggle="modal"
                    data-target="#update"
                    class="btn btn-sm btn-success mr-2"
                    (click)="actualInsumoId = element.id"
                  >
                    <div class="btn-mat-icon">
                      <mat-icon class="mr-1">account_circle</mat-icon>
                      Alterar Responsável
                    </div>
                  </button>

                  <!-- text-white -->
                  <button
                    [hidden]="editPermission()"
                    matTooltip="Data Limite"
                    data-toggle="modal"
                    data-target="#dataLimite"
                    class="btn btn-sm btn-warning mr-2"
                    (click)="actualInsumoId = element.id"
                  >
                    <div class="btn-mat-icon">
                      <mat-icon class="mr-1">calendar_month</mat-icon>
                      Alterar Data Limite
                    </div>
                  </button>

                  <button
                    matSuffix
                    matTooltip="Histórico"
                    (click)="getInsumoById(element.id)"
                    data-toggle="modal"
                    data-target="#historico"
                    class="btn btn-sm btn-dark mr-2"
                  >
                    <div class="btn-mat-icon">
                      <mat-icon class="mr-1">history</mat-icon>
                      Histórico
                    </div>
                  </button>

                  <button
                    *ngIf="
                      user.profile === 'ROLE_ADMIN' ||
                      user.userType === 'OPERACAO_BPO'
                    "
                    matSuffix
                    matTooltip="Histórico"
                    (click)="actualInsumoId = element.id"
                    data-toggle="modal"
                    data-target="#numerosd"
                    class="btn btn-sm btn-info mr-2"
                    [disabled]="element.numeroSd"
                  >
                    <div class="btn-mat-icon">
                      <mat-icon class="mr-1">pin</mat-icon>
                      Adicionar Número SD
                    </div>
                  </button>

                  <button
                    *ngIf="
                      element.createdUser.email == user.email &&
                      element.status !== 'ENCERRADO'
                    "
                    matTooltip="Encerrar"
                    data-toggle="modal"
                    data-target="#excluir"
                    class="btn btn-sm btn-danger mr-2"
                    (click)="tempInsumoId = element.id"
                  >
                    <div class="btn-mat-icon">
                      <mat-icon class="mr-1">highlight_off</mat-icon>
                      Encerrar
                    </div>
                  </button>

                  <button
                    *ngIf="element.status !== 'ENCERRADO'"
                    [hidden]="editPermission()"
                    class="btn btn-sm mr-2"
                    [ngClass]="
                      element.status !== 'INATIVO'
                        ? 'btn-danger'
                        : 'btn-success'
                    "
                    (click)="desativarInsumo(element.id)"
                  >
                    <div class="btn-mat-icon">
                      <mat-icon class="mr-1">
                        {{
                          element.status !== "INATIVO"
                            ? "cancel"
                            : "check_circle"
                        }}</mat-icon
                      >
                      {{ element.status !== "INATIVO" ? "Inativar" : "Ativar" }}
                    </div>
                  </button>
                </div>
              </div>

              <div class="row mb-5">
                <div class="col-sm-12">
                  <h3 class="text-muted mt-2">Anexos:</h3>
                  <table
                    mat-table
                    [dataSource]="element.insumoAnexos.reverse()"
                    multiTemplateDataRows
                    class="mat-elevation-z8"
                  >
                    <ng-container matColumnDef="idArquivo">
                      <th mat-header-cell *matHeaderCellDef>Id. Arquivo</th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.id }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="Justificativa">
                      <th mat-header-cell *matHeaderCellDef>Justificativa:</th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.justify }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="nomeArquivo">
                      <th mat-header-cell *matHeaderCellDef>Nome Arquivo</th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.originalFileName }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="dataEHoraEnvio">
                      <th mat-header-cell *matHeaderCellDef>
                        Data e Hora de Envio
                      </th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.creationDt | convertDate }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="versao">
                      <th mat-header-cell *matHeaderCellDef>Versão</th>
                      <td mat-cell *matCellDef="let element">
                        v{{ element.version }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="acoes">
                      <th mat-header-cell *matHeaderCellDef>Ações</th>
                      <td mat-cell *matCellDef="let element">
                        <!-- text-white  -->
                        <button
                          class="btn btn-sm btn-warning mr-2"
                          (click)="
                            downloadFileNotification(element.id, element.id)
                          "
                        >
                          <mat-icon>download</mat-icon>
                        </button>
                      </td>
                    </ng-container>

                    <tr
                      mat-header-row
                      *matHeaderRowDef="displayedColumnsInterno"
                    ></tr>
                    <tr
                      mat-row
                      *matRowDef="let row; columns: displayedColumnsInterno"
                    ></tr>
                  </table>
                </div>
              </div>

              <div class="row mb-5">
                <div class="col-sm-12">
                  <mat-slide-toggle
                    [(ngModel)]="isChecked"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    {{ isChecked ? "Possui" : "Não possui" }}
                    Insumos</mat-slide-toggle
                  >
                  <!-- <mat-checkbox class="example-margin"> Não há Insumos </mat-checkbox> -->
                  <br />
                  <button
                    [disabled]="element.status === 'INATIVO'"
                    (click)="uploadAnexoInsumo(element)"
                    class="btn btn-sm btn-primary mr-3 mt-3"
                    data-toggle="modal"
                    data-target="#confirmDoc"
                  >
                    <div class="button-flex">
                      <mat-icon>attach_file</mat-icon>
                      Anexar Insumo
                    </div>
                  </button>

                  <!-- <button
                    [hidden]="element.version === 'Sem Anexos'"
                    (click)="
                      selectInsumoVersion(element); actualInsumoId = element.id
                    "
                    class="btn btn-sm btn-warning mr-3 mt-3"
                    data-toggle="modal"
                    data-target="#version"
                  >
                    <div class="button-flex">
                      <mat-icon>autorenew</mat-icon>
                      Mudar Versão
                    </div>
                  </button> -->
                </div>
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <!-- <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr> -->
      <tr
        mat-row
        *matRowDef="let element; columns: columnsToDisplay"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="example-detail-row"
      ></tr>
    </table>
    <mat-paginator
      [pageSizeOptions]="[10, 20, 30]"
      showFirstLastButtons
      aria-label="Select page of periodic elements"
    >
    </mat-paginator>
  </div>
</div>

<div class="modal fade" id="confirmDoc">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title mb-0 text-muted" id="TituloModalCentralizado">
          Formulário de envio de Insumo
        </p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Fechar"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]="anexoInsumo" [hidden]="actualInsumoLength <= 0">
        <div class="modal-body">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Justificativa:</mat-label>
            <input
              matInput
              type="text"
              formControlName="justify"
              maxlength="300"
              required
            />
          </mat-form-field>
        </div>
      </form>

      <mat-card-content>
        <div class="row">
          <div class="col-lg-6" style="align-self: left">
            <ul>
              <li
                *ngFor="let anexo of attachments"
                style="
                  line-height: 16px;
                  padding: 20px 10px;
                  white-space: nowrap;
                  overflow: hidden;
                  height: 60px;
                "
              >
                {{ anexo.originalFileName }}
              </li>
            </ul>
          </div>
          <div class="col-lg-3" style="align-self: right">
            <ul>
              <li
                *ngFor="let arquivo of files"
                style="padding: 20px 10px; line-height: 16px; height: 60px"
              >
                {{ arquivo.progress }}
              </li>
            </ul>
          </div>
          <div class="col-lg-3" style="align-self: right">
            <ul>
              <li
                *ngFor="let anexo of attachments"
                style="padding: 13px 10px; height: 60px"
              >
                <span
                  class="btn btn-sm mr-2 btn-danger"
                  (click)="removeAnexoFormPreUpload(anexo)"
                >
                  <mat-icon>close</mat-icon>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-12" style="align-self: center">
          <ul>
            <li *ngIf="files.length > 0" style="margin: 30xp 0">
              <mat-progress-bar [value]="somArray(files)"></mat-progress-bar>
              <span id="file-label"></span>
            </li>
          </ul>
        </div>
      </mat-card-content>

      <div class="d-flex flex-column">
        <div class="mt-4 pb-4 pl-3 pr-3">
          <div class="form-group">
            <div class="input-group">
              <div class="custom-file">
                <input
                  type="file"
                  #fileUpload
                  id="fileUpload"
                  name="fileUpload"
                  multiple="multiple"
                  accept="image/jpeg,image/gif,image/png,image/x-eps"
                  class="custom-file-input"
                  (click)="onUploadButtonClick()"
                />
                <label class="custom-file-label" for="anexarDocumento">{{
                  attachments[0]?.originalFileName == null
                    ? "Anexar arquivo"
                    : attachments[0]?.originalFileName
                }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button
          type="submit"
          (click)="onSubmit()"
          class="btn btn-success btn-sm button-flex"
        >
          <mat-icon>task_alt</mat-icon>
          Enviar
        </button>
        <button
          #closeConfirmDoc
          [hidden]="true"
          type="button"
          data-dismiss="modal"
        ></button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="excluir">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title mb-0" id="TituloModalCentralizado">
          Encerrar Insumo
        </p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Fechar"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="mt-4 pb-4">
          <div class="w-100 d-flex justify-content-center">
            <mat-icon class="text-muted" style="font-size: 30pt !important"
              >cancel</mat-icon
            >
          </div>
          <h3 class="text-center text-muted mt-4">Encerramento do Insumo</h3>
          <p class="text-center text-muted">
            Encerrar o Insumo fará com que ele não possa mais ser alterado.
          </p>
        </div>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          class="btn-sm btn btn-secondary mr-2 btn-mat-icon"
          data-dismiss="modal"
        >
          <mat-icon class="modal-icon">arrow_back</mat-icon>
          Voltar
        </button>
        <button
          type="button"
          class="btn-sm btn btn-danger mr-2 btn-mat-icon"
          (click)="closeInsumo()"
        >
          <mat-icon class="modal-icon">close</mat-icon>
          Encerrar Insumo
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title mb-0" id="TituloModalCentralizado">
          Encerrar Insumo
        </p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Fechar"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Deseja Realmente Encerrar o Insumo?</p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn-sm btn btn-dark mr-2 btn-mat-icon"
          data-dismiss="modal"
        >
          <mat-icon class="">close</mat-icon>
          Cancelar
        </button>
        <button
          #closeModalExcluir
          [hidden]="true"
          type="button"
          data-dismiss="modal"
        ></button>
        <button
          type="submit"
          class="btn-sm btn btn-success btn-mat-icon"
          (click)="closeInsumo()"
        >
          <mat-icon class="">done</mat-icon>
          Sim
        </button>
      </div>
    </div>
  </div>
</div>

<form [formGroup]="formUpdate" (ngSubmit)="onSubmitUpdate()">
  <div class="modal fade" id="update">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title mb-0" id="TituloModalCentralizado">
            Atualizar Responsavel
          </p>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Fechar"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Email Responsável do upload do insumo: </mat-label>
            <input
              type="text"
              matInput
              autocomplete="new-responsavel"
              aria-label="Email do(a) Responsável"
              [matAutocomplete]="auto1"
              [formControl]="searchUserCtrl1"
              required
              autocomplete="off"
              (click)="resetField(1)"
            />
            <mat-autocomplete #auto1="matAutocomplete">
              <mat-option *ngIf="isLoading" class="is-loading"
                >Carregando...</mat-option
              >
              <ng-container *ngIf="!isLoading">
                <mat-option
                  *ngFor="let user of filteredUsers1"
                  [value]="user.email"
                  (click)="setEmailResponsibleUpload(user)"
                >
                  <span>{{ user.email }}</span>
                </mat-option>
              </ng-container>
            </mat-autocomplete>
            <!-- <input matInput placeholder="Insira o email" type="email" formControlName="responsibleUpload" [ngClass]="{
                'is-invalid': submitted && formUp.responsibleUpload.errors
              }" required />
            <div *ngIf="submitted && formUp.responsibleUpload.errors" class="invalid-feedback">
              <div *ngIf="formUp.responsibleUpload.errors.required">
                É necessário adicionar o Email
              </div>
            </div> -->
          </mat-form-field>

          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Email Responsável pela análise do insumo: </mat-label>
            <input
              type="text"
              matInput
              autocomplete="new-responsavel"
              aria-label="Email do(a) Responsável"
              [matAutocomplete]="auto2"
              [formControl]="searchUserCtrl2"
              required
              autocomplete="off"
              (click)="resetField(2)"
            />
            <mat-autocomplete #auto2="matAutocomplete">
              <mat-option *ngIf="isLoading" class="is-loading"
                >Carregando...</mat-option
              >
              <ng-container *ngIf="!isLoading">
                <mat-option
                  *ngFor="let user of filteredUsers2"
                  [value]="user.email"
                  (click)="setEmailAnalysisUpload(user)"
                >
                  <span>{{ user.email }}</span>
                </mat-option>
              </ng-container>
            </mat-autocomplete>
            <!-- <input matInput type="email" placeholder="Insira o Email" formControlName="analysisUpload" [ngClass]="{
                'is-invalid': submitted && formUp.analysisUpload.errors
              }" required />
            <div *ngIf="submitted && formUp.analysisUpload.errors" class="invalid-feedback">
              <div *ngIf="formUp.analysisUpload.errors.required">
                É necessário adicionar o Email
              </div>
            </div> -->
          </mat-form-field>

          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Justificativa:</mat-label>
            <input
              matInput
              type="text"
              formControlName="justify"
              maxlength="300"
              required
            />
          </mat-form-field>

          <!-- <mat-form-field appearance="fill" class="w-100">
            <mat-label>Data Limite:</mat-label>
            <input matInput formControlName="deadline" [matDatepicker]="pickerDataLimite" />
            <mat-datepicker-toggle matSuffix [for]="pickerDataLimite"></mat-datepicker-toggle>
            <mat-datepicker #pickerDataLimite></mat-datepicker>
          </mat-form-field> -->
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn-sm btn btn-dark mr-2 btn-mat-icon"
            data-dismiss="modal"
          >
            <mat-icon class="">close</mat-icon>
            Cancelar
          </button>
          <button
            #closeModalAlterarResponsavel
            [hidden]="true"
            type="button"
            data-dismiss="modal"
          ></button>
          <button
            type="submit"
            class="btn-sm btn btn-success btn-mat-icon"
            [disabled]="formUpdate.invalid"
          >
            <mat-icon class="">done</mat-icon>
            Atualizar
          </button>
        </div>
      </div>
    </div>
  </div>
</form>

<form [formGroup]="formDeadLine" (ngSubmit)="onSubmitDeadLine()">
  <div class="modal fade" id="dataLimite">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title mb-0" id="TituloModalCentralizado">
            Atualizar Data Limite
          </p>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Fechar"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <!-- <mat-form-field appearance="fill" class="w-100">
            <mat-label>Email Responsável do upload do insumo: </mat-label>
            <input matInput placeholder="Insira o email" type="email" formControlName="responsibleUpload" [ngClass]="{
                'is-invalid': submitted && formUp.responsibleUpload.errors
              }" required />
            <div *ngIf="submitted && formUp.responsibleUpload.errors" class="invalid-feedback">
              <div *ngIf="formUp.responsibleUpload.errors.required">
                É necessário adicionar o Email
              </div>
            </div>
          </mat-form-field>

          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Email Responsável pela análise do insumo: </mat-label>
            <input matInput type="email" placeholder="Insira o Email" formControlName="analysisUpload" [ngClass]="{
                'is-invalid': submitted && formUp.analysisUpload.errors
              }" required />
            <div *ngIf="submitted && formUp.analysisUpload.errors" class="invalid-feedback">
              <div *ngIf="formUp.analysisUpload.errors.required">
                É necessário adicionar o Email
              </div>
            </div>
          </mat-form-field> -->

          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Data Limite:</mat-label>
            <input
              matInput
              formControlName="deadline"
              [matDatepicker]="pickerDataLimite"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="pickerDataLimite"
            ></mat-datepicker-toggle>
            <mat-datepicker #pickerDataLimite></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="modal-body">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Justificativa:</mat-label>
            <input
              matInput
              type="text"
              formControlName="justify"
              maxlength="300"
              required
            />
          </mat-form-field>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn-sm btn btn-dark mr-2 btn-mat-icon"
            data-dismiss="modal"
          >
            <mat-icon class="">close</mat-icon>
            Cancelar
          </button>
          <button
            #closeModalAlterarDeadLine
            [hidden]="true"
            type="button"
            data-dismiss="modal"
          ></button>
          <button type="submit" class="btn-sm btn btn-success btn-mat-icon">
            <mat-icon class="">done</mat-icon>
            Atualizar
          </button>
        </div>
      </div>
    </div>
  </div>
</form>

<div
  class="modal fade"
  id="historico"
  tabindex="-1"
  role="dialog"
  aria-labelledby="historico"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h5 class="modal-title" id="exampleModalLongTitle">Histórico</h5> -->
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-12 text-center">
            <h2 class="m-0 mt-4 text-primary">
              <div class="btn-mat-icon">
                <mat-icon class="mr-2">history</mat-icon>
                Histórico
              </div>
            </h2>
          </div>

          <div class="col-sm-12">
            <section id="cd-timeline" *ngIf="insumo" class="">
              <div
                class="cd-timeline-block"
                *ngFor="let element of insumo.insumoHistoryList"
              >
                <div class="cd-timeline-img cd-picture"></div>
                <div class="cd-timeline-content bg-white">
                  <h2 class="text-primary">{{ element.actionEnum | cap }}</h2>
                  <div class="timeline-content-info bg-primary">
                    <span class="timeline-content-info-title">
                      <strong
                        >{{ element.actionUser.firstName }}
                        {{ element.actionUser.lastName }}</strong
                      >
                      {{ element.statusChangedDate | convertDate }}
                    </span>
                  </div>

                  <div *ngIf="element.currentStatus">
                    <p class="text-primary mt-2">Status atual:</p>
                    <p>
                      <span
                        class="txt-req text-white mb-3"
                        style="
                          border-radius: 0.4rem;
                          padding: 1vh;
                          background-color: #660099;
                        "
                      >
                        <strong>{{ element.currentStatus | cap }}</strong>
                      </span>
                    </p>

                    <p class="text-primary mt-2">Novo Status:</p>
                    <p>
                      <span
                        class="txt-req text-white mb-3"
                        style="
                          border-radius: 0.4rem;
                          padding: 1vh;
                          background-color: #660099;
                        "
                      >
                        <strong>{{ element.newStatus | cap }}</strong>
                      </span>
                    </p>
                  </div>
                  <div [hidden]="!element.justify">
                    <p class="text-primary mt-2">Justificativa:</p>
                    <p></p>
                    <p>
                      <span
                        class="txt-req text-white mb-3"
                        style="
                          border-radius: 0.4rem;
                          padding: 1vh;
                          background-color: #660099;
                        "
                      >
                        <strong>{{ element.justify | cap }}</strong>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-dark" data-dismiss="modal">
          Fechar
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="encerrar">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title mb-0" id="TituloModalCentralizado">
          Encerrar Insumos
        </p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Fechar"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Deseja Realmente Encerrar esses Insumos?</p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn-sm btn btn-dark mr-2 btn-mat-icon"
          data-dismiss="modal"
        >
          <mat-icon class="">close</mat-icon>
          Cancelar
        </button>
        <button
          #closeModalEncerrar
          [hidden]="true"
          type="button"
          data-dismiss="modal"
        ></button>
        <button
          type="submit"
          class="btn-sm btn btn-success btn-mat-icon"
          (click)="encerrarInsumos()"
        >
          <mat-icon class="">done</mat-icon>
          Sim
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="numerosd">
  <form [formGroup]="formAddNumeroSd" (ngSubmit)="addNumeroSd()">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title mb-0" id="TituloModalCentralizado">
            Adicionar Número SD
          </p>
          <button
            #closeModal
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Fechar"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div class="my-4 px-4">
            <h3 class="text-center text-muted mt-4">
              Digite o Número SD referente à esse Insumo
            </h3>

            <mat-form-field appearance="fill" class="w-100" required>
              <mat-label> Número SD:</mat-label>
              <input
                matInput
                formControlName="numeroSd"
                type="text"
                placeholder="Digite o Número SD"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn-sm btn btn-secondary mr-2 btn-mat-icon"
            data-dismiss="modal"
          >
            <mat-icon class="modal-icon">arrow_back</mat-icon>
            Voltar
          </button>
          <button
            type="submit"
            class="btn-sm btn btn-success mr-2 btn-mat-icon"
          >
            <mat-icon class="modal-icon mr-1">save</mat-icon>
            Salvar Número SD
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal fade" id="encerrados">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title mb-0" id="TituloModalCentralizado">AVISO!!</p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Fechar"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>
          Os seus Insumos foram encerrados, porém foram selecionados alguns
          Insumos que não são seus e esses Insumos não sofreram alterações...
        </p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn-sm btn btn-dark mr-2 btn-mat-icon"
          data-dismiss="modal"
        >
          <mat-icon class="">close</mat-icon>
          Fechar
        </button>
        <button
          #closeModalEncerradosAlert
          [hidden]="true"
          type="button"
          data-dismiss="modal"
        ></button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="encerradosnada">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title mb-0" id="TituloModalCentralizado">AVISO!!</p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Fechar"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Nenhum Insumo Selecionado!</p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn-sm btn btn-dark mr-2 btn-mat-icon"
          data-dismiss="modal"
        >
          <mat-icon class="">close</mat-icon>
          Fechar
        </button>
        <button
          #closeModalEncerradosAlert
          [hidden]="true"
          type="button"
          data-dismiss="modal"
        ></button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="version">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title mb-0" id="TituloModalCentralizado">
          Mudança de Versão de Anexo de Insumo
        </p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Fechar"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="formChangeVersion">
          <div class="modal-body">
            <mat-form-field appearance="fill" class="w-100" required>
              <mat-label> Versões Disponíveis: </mat-label>
              <mat-select formControlName="version">
                <mat-option value="None" disabled="true">
                  Selecione Alguma Versão...
                </mat-option>
                <mat-option
                  *ngFor="let version of insumoVersionList"
                  [value]="version"
                  >{{ version }}</mat-option
                >
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Justificativa:</mat-label>
              <input
                matInput
                type="text"
                formControlName="justificativa"
                maxlength="300"
                required
              />
            </mat-form-field>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn-sm btn btn-dark mr-2 btn-mat-icon"
          data-dismiss="modal"
        >
          <mat-icon class="">close</mat-icon>
          Fechar
        </button>
        <button
          #closeModalVersion
          [hidden]="true"
          type="button"
          data-dismiss="modal"
        ></button>
        <button
          type="submit"
          class="btn btn-success btn-sm button-flex"
          (click)="changeVersion()"
        >
          <mat-icon>task_alt</mat-icon>
          Enviar
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="export"
  tabindex="-1"
  role="dialog"
  aria-labelledby="export"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-12 text-center">
            <h2 class="m-0 mt-4 text-primary">
              <div class="btn-mat-icon">Selecione o Tipo de Relatório</div>
              <br />
              <br />
            </h2>
          </div>

          <div class="col-sm-12 d-flex justify-content-center">
            <button
              type="button"
              class="btn btn-rose btn-sm mr-2 mb-2"
              (click)="exportTableResumido()"
            >
              <div class="button-flex">
                <mat-icon>download</mat-icon>
                Resumido
              </div>
            </button>
            <button
              type="button"
              class="btn btn-rose btn-sm mr-2 mb-2"
              (click)="exportTableDetalhado()"
            >
              <div class="button-flex">
                <mat-icon>download</mat-icon>
                Detalhado
              </div>
            </button>
            <br />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-dark" data-dismiss="modal">
          Fechar
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="historico"
  tabindex="-1"
  role="dialog"
  aria-labelledby="historico"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title m-0">Histórico</h3>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <!-- <div class="col-sm-12 text-center">
            <h2 class="m-0 mt-4 text-muted">
              <div class="btn-mat-icon">
                <mat-icon class="mr-2">history</mat-icon>
                Histórico
              </div>
            </h2>
          </div> -->

          <div class="col-sm-12">
            <section id="cd-timeline" *ngIf="insumo.insumoHistoryList" class="">
              <div
                class="cd-timeline-block"
                *ngFor="let element of insumo.insumoHistoryList.reverse()"
              >
                <div class="cd-timeline-img cd-picture"></div>
                <div class="cd-timeline-content bg-white">
                  <div>
                    <p class="text-primary mb-0 text-small">
                      <strong>Ação:</strong>
                    </p>
                    <h3 class="text-muted mb-0">
                      {{ element.actionEnum | cap }}
                    </h3>
                  </div>

                  <hr class="mt-2 mb-3" />

                  <div>
                    <p class="text-primary text-small mt-2 mb-0">
                      Responsável:
                    </p>
                    <h3 class="text-muted mb-0 mt-0">
                      {{
                        element.actionUser.fullName ||
                          element.actionUser.firstName +
                            " " +
                            element.actionUser.lastName
                      }}
                    </h3>
                    <p class="text-muted mt-0">
                      {{ element.actionUser.email }}
                    </p>
                    <div>
                      <p class="text-primary text-small mt-2 mb-0">
                        Data da Ação:
                      </p>
                      <h4 class="text-muted mb-0 mt-0">
                        {{ element.statusChangedDate | convertDate }}
                      </h4>
                    </div>
                  </div>

                  <div *ngIf="element.justify">
                    <hr class="mt-2 mb-3" />

                    <p class="text-primary text-small mt-2 mb-0">
                      Justificativa:
                    </p>
                    <p class="text-muted mt-0">
                      {{ element.justify }}
                    </p>
                  </div>

                  <hr class="mt-2 mb-2" />

                  <div class="col-sm-12">
                    <div class="row">
                      <div *ngIf="element.currentStatus" class="mr-3">
                        <p class="text-primary text-small mb-0">Status:</p>
                        <p class="m-0">
                          <label
                            [ngClass]="{
                              vigente: element.currentStatus === 'VIGENTE',
                              inativo: element.currentStatus === 'INATIVO',
                              encerrado: element.currentStatus === 'ENCERRADO'
                            }"
                          >
                            {{ element.currentStatus | cap }}
                          </label>
                        </p>
                      </div>
                      <div *ngIf="element.currentStatus !== element.newStatus">
                        <p class="text-primary text-small mb-0">Novo Status:</p>
                        <p class="m-0">
                          <label
                            [ngClass]="{
                              vigente: element.newStatus === 'VIGENTE',
                              inativo: element.newStatus === 'INATIVO',
                              encerrado: element.newStatus === 'ENCERRADO'
                            }"
                          >
                            {{ element.newStatus | cap }}
                          </label>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-dark btn-sm" data-dismiss="modal">
          Fechar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- <div class="modal fade" id="importarInsumos">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title mb-0" id="TituloModalCentralizado">
          Importar Insumos
        </p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Fechar"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="d-flex flex-column">
          <div class="mt-4 pb-4 pl-3 pr-3">
            <div class="form-group">
              <div class="input-group">
                <div class="custom-file">
                  <input
                    type="file"
                    #fileUpload
                    id="fileUpload"
                    name="fileUpload"
                    multiple="multiple"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    class="custom-file-input"
                    (click)="uploadImport()"
                  />
                  <label class="custom-file-label" for="anexarDocumento"
                    >Upload</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pb-4 pl-3 pr-3">
          <h3><strong>Passos Da Importação</strong></h3>
          <p>
            1. Faça o download da planilha/lodelo de importação
            <a
              href="https://portaldecomissionamento.vivo.com.br/assets/arquivos-modelos/layout_importacao_insumo.xlsx"
              target="_blank"
              rel="noopener noreferrer"
              >Layout.xls</a
            >
          </p>
          <p>2. Preencha as colunas da planilha</p>
          <p>3. Converta para CSV</p>
          <p>4. Envie o arquivo na área no campo de Upload acima.</p>
        </div>

        <div class="pb-4 pl-3 pr-3">
          <h3><strong>Observe as colunas:</strong></h3>
          <h4><strong>DeadLine</strong></h4>
          <p>Preencha Com uma data no formato dd/mm/yyyy</p>
          <h4><strong>SLA</strong></h4>
          <p>Preencha Com uma data no formato dd/mm/yyyy</p>
          <h4><strong>status</strong></h4>
          <p>0 - VIGENTE("Vigente")</p>
          <p>1 - ENCERRADO("Encerrado")</p>
          <p>2 - INATIVO("Inativo")</p>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn-sm btn btn-dark mr-2 btn-mat-icon"
            data-dismiss="modal"
          >
            <mat-icon class="">close</mat-icon>
            Cancelar
          </button>
          <button
            type="submit"
            (click)="onSubmit()"
            class="btn btn-success btn-sm button-flex"
          >
            <mat-icon>task_alt</mat-icon>
            Confirmar
          </button>
          <button
            #closeModalImportar
            [hidden]="true"
            type="button"
            data-dismiss="modal"
          ></button>
        </div>
      </div>
    </div>
  </div>
</div> -->
