import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { User } from "src/app/_models";
import { AccountService } from "src/app/_services";
import { DashboardService } from "../../_services/dashboard.service";

@Component({
  selector: "app-listagem",
  templateUrl: "./listagem.component.html",
  styleUrls: ["./listagem.component.css"],
})
export class ListarDashBoardsComponent implements OnInit {
  user: User;
  dashboards: any[];

  constructor(
    private accountService: AccountService,
    private dashboardService: DashboardService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.user = this.accountService.userValue.user;
  }

  ngOnInit(): void {
    this.getDashboard();
  }

  getDashboard() {
    const dashboardId = this.route.snapshot.params.id;
    this.dashboardService
      .getDashboard(this.user.userType, dashboardId)
      .subscribe((res) => (this.dashboards = res));
  }
  redirect(nextRouter) {
    this.router.navigate([`/${nextRouter}`], { relativeTo: this.route });
  }
}
