import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import {
  debounceTime,
  filter,
  finalize,
  map,
  startWith,
  switchMap,
  tap,
} from "rxjs/operators";
import { environment } from "src/environments/environment";

import { MatrizGD } from "../_models/matrizGD";
import { Pergunta } from "../_models/pergunta";
import { Resposta } from "../_models/resposta";
import { FormularioMatrizService } from "../_services/formulario-matriz.service";
import { MapeamentodocumentalService } from "../_services/mapeamentodocumental.service";
import { QuestionarioService } from "../_services/questionario.service";

@Component({
  selector: "app-formulario-matriz",
  templateUrl: "./formulario-matriz.component.html",
  styleUrls: ["./formulario-matriz.component.css"],
})
export class FormularioMatrizComponent implements OnInit {
  currentCode: any;
  matrizOption: any = {};
  errorMsg: any;
  search = new FormControl();
  filteredEspecie: Observable<string[]>;
  filtered: any;
  formMatriz: FormGroup;
  formEditMatriz: FormGroup;
  id: any;
  questionario: any = {};
  perguntas: Pergunta[] = [
    new Pergunta(),
    new Pergunta(),
    new Pergunta(),
    new Pergunta(),
    new Pergunta(),
  ];
  matrizGD: MatrizGD = {
    dtCreation: null,
    entrevistado: null,
    questionario: null,
    respostas: [],
  };
  mapeamento: any;
  isLoading = false;
  constructor(
    private formBuilder: FormBuilder,
    private formularioMatrizService: FormularioMatrizService,
    private mapeamentodocumentalService: MapeamentodocumentalService,
    private questionarioService: QuestionarioService,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {
    this.route.queryParams.subscribe((params) => {
      var id = this.route.snapshot.paramMap.get("id");
      console.log(id);

      if (id) {
        this.id = id;
        this.getMapeamentoById(this.id);
      }
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.especiesDocumentais
      .map((x) => x.descricao)
      .filter((option) => option.toLowerCase().includes(filterValue));
  }
  ngOnInit() {
    console.log(this.atividadesList);
    this.getQuestionario();

    this.formMatriz = this.formBuilder.group({
      q1: [""],
      q2: ["", Validators.required],
      q3: ["", Validators.required],
      q4: new FormControl(""),
      q5: ["", Validators.required],
      q6: ["", Validators.required],
      q7: ["", Validators.required],
      q8: ["", Validators.required],
      q9: ["", Validators.required],
      q10: ["", Validators.required],
      q11: ["", Validators.required],
      q12: ["", Validators.required],
      q13: ["", Validators.required],
      q14: ["", Validators.required],
      q15: ["", Validators.required],
      q16: ["", Validators.required],
      q17: ["", Validators.required],
      q18: ["", Validators.required],
      q19: ["", Validators.required],
      q20: ["", Validators.required],
      q21: ["", Validators.required],
      q22: ["", Validators.required],
      q23: ["", Validators.required],
      q24: [false],
      q25: [false],
      q26: [false],
      q27: [false],
      q28: [false],
      q29: [false],
      q30: [false],
      q31: [false],
      q32: [false],
      q33: [false],
      q34: ["", Validators.required],
      q35: ["", Validators.required],
      q36: ["", Validators.required],
      q37: ["", Validators.required],
      q38: ["", Validators.required],
      q39: ["", Validators.required],
      q40: ["", Validators.required],
      q41: ["", Validators.required],
      q42: ["", Validators.required],
      q43: ["", Validators.required],
    });

    this.filteredEspecie = this.formMatriz.get("q4").valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );
  }

  filtrar(code) {
    this.currentCode = code;
    this.filtered = [];
    this.search.valueChanges
      .pipe(
        filter((value) => value?.length > 3),
        debounceTime(500),
        tap(() => {
          this.errorMsg = "";
          this.filtered = [];
          this.isLoading = true;
        }),
        switchMap((value) =>
          this.http
            .get<any>(
              `${environment.apiUrl}/mapeamento-documental/MatrizOption/search?code=${this.currentCode}&name=${value}`
            )
            .pipe(
              finalize(() => {
                this.isLoading = false;
              })
            )
        )
      )
      .subscribe((data) => {
        if (data == undefined) {
          this.errorMsg = data["Error"];
          this.filtered = [];
        } else {
          this.errorMsg = "";
          this.filtered = data;
        }

        console.log(this.filtered);
      });
  }
  getMapeamentoById(id) {
    this.mapeamentodocumentalService
      .getMapeamentoById(id)
      .subscribe((response) => {
        this.mapeamento = response;
        console.log(this.mapeamento);
      });
  }

  states: string[] = [
    "Requisição de pessoal",
    "Relatório GUPY",
    "Calendário de requisição de pessoal",
    "Planilha consolidado de requisição de pessoal",
    "Cédula de Identidade RG",
    "Cadastro de Pessoa Física CPF",
    "Carteira de Trabalho e Previdência Social CTPS",
    "Título de Eleitor",
    "Reservista",
    "Programa de Integração Social  PIS",
    "Comprovante de residência",
    "Comprovante de escolaridade",
    "Certidão de Nascimento",
    "Certidão de Casamento",
    "Atestado de Saúde Ocupacional Admissional  ASO",
    "Contrato Individual de Trabalho",
    "Dossiê Admissional",
    "Termo de Acordo de Prorrogação de Horas de Trabalho",
    "Formulário de Opção de Seguro de Vida",
    "Formulário de Opção de Vale Refeição e Alimentação",
    "Formulário de Opção de Vale Transporte",
    "Formulário de Solicitação Auxilio Excepcional",
    "Formulário de Solicitação do Auxílio-Creche",
    "Formulário de Solicitação Telefone Celular",
    "Recibo de Entrega da CTPS",
    "Termo de Acordo de Compensação de Horas de Trabalho",
    "Termo de Autorização para Adesão de Plano de Assistência Médica e Plano Odontológico",
    "Termo de Compromisso a Política de Ética e Conduta da Telefônica Brasil",
    "Termo de Compromisso de Acesso e uso de Equipamentos e Informação",
    "Termo de responsabilidade e confidencialidade - sigilo de informações",
    "Termo de responsabilidade sobre declaração de dependentes para fins de imposto de renda",
    "Smart form de desligamento",
    "Carta de Desligamento Funcional",
    "Check-list conferência processo demissional",
    "Check-list de desligamento - devolução de documentos e equipamentos",
    "Check-list processo demissional",
    "Comunicado de dispensa do trabalho",
    "Formulário de orientações de desligamento",
    "Formulário de Solicitação de desligamento de empregado",
    "Protocolo de comunicação de dispensa do trabalho",
    "Questionário de desligamento - dispensa do trabalho",
    "Atestado de Saúde Ocupacional Demissional  ASO",
    "Chave FGTS",
    "Comprovante de Pagamento Bancário do TRCT",
    "Demonstrativo do trabalhador de recolhimento FGTS rescisório",
    "Demonstrativo FGTS",
    "Dossiê Demissional",
    "Extrato de conta do fundo de garantia  FGTS",
    "Ficha de anotações e atualizações da carteira de trabalho e previdência social",
    "Formulário de Atualização CTPS",
    "Formulário de Seguro Desemprego",
    "Guia de Recolhimento Rescisório do FGTS e Contribuição Social GRFF",
    "Holerites",
    "Recibo de devolução de carteira de trabalho",
    "Termo de Rescisão Do Contrato De Trabalho TRCT",
    "Formulário de medida disciplinar (advertência, suspensão, orientação)",
    "Comprovante de Entrega de Vale Alimentação",
    "Comprovante de Entrega de Vale Refeição",
    "Comprovante de Entrega de Vale Transporte",
  ];

  processos: string[] = [
    "Admissão de Pessoal",
    "Demissão de Pessoal",
    "Protocolo de Entrega de Benefício",
    "Medida Disciplinar",
  ];

  atividades: string[] = [
    "Acompanhamento da Documentação do Candidato",
    "Gerenciamento e Cadastro da Admissão",
    "Emissão do Contrato de Trabalho e Formalização do Kit Admissional",
    "Comunicação da Dispensa do Trabalho",
    "Formalização da Dispensa do Trabalho",
    "Homologação da Dispensa do Trabalho",
    "Recebimento de Medida Disciplinar",
    "Controle de Entrega de Protocolo de Benefícios",
  ];
  // especieDocumentais: string[] = [
  //   'Acórdão', 'Acordo' , 'Agenda' , 'Agravo' , 'Alvará' , 'Anexo' , 'Anotação' , 'Anuário' , 'Anuência' , 'Apólice' , 'Ata' , 'Atestado' , 'Ato' , 'Auto' , 'Avaliação' , 'Aviso' ,
  //   'Balancete' , 'Balanço' , 'Bilhete' , 'Boleto' , 'Boletim' ,
  //   'Cadastro' , 'Caderno' , 'Carta' , 'Carta-convite' , 'Cartão' , 'Cartaz' , 'Cartilha' , 'Catálogo' , 'Cronograma' , 'Croqui' , 'Cupom' ,
  //   'Debênture' , 'Decisão' , 'Declaração' , 'Decreto' , 'Decreto-lei' , 'Deliberação' , 'Demonstrativo' , 'Depósito' , 'Despacho' , 'Desenho' , 'Detalhe' , 'Diagrama' , 'Diário' , 'Documento de arrecadação' , 'Documento Auxiliar' , 'Dossiê0' , 'Duplicata' ,
  //   'Edital' , 'Embargos' , 'Emenda' , 'E-mail/mensagem' , 'Eletrônica' , 'Escritura' , 'Esquema' , 'Especificação' ,
  //   'Legislação' , 'Lei' , 'Letra de Câmbio' , 'Licença' , 'Lista' , 'Livro Registro' ,
  //   'Mandado' , 'Mandato' , 'Manual' , 'Mapa' , 'Matrícula' , 'Medida Provisória' , 'Memorando' , 'Memória' , 'Memorial' , 'Mensagem' , 'Minuta' , 'Movimento' , 'Movimentação' ,
  //   'Norma' , 'Nota de Crédito' , 'Nota de Débito' , 'Nota Fsical' , 'Nota Promissória' , 'Notificação' ,
  //   'Ofício' , 'Orçamento' , 'Orgem' , 'Organograma' , 'Programa' , 'Programação' , 'Projeto' , 'Prontuário' , 'Proposta' , 'Protocolo' , 'Quadro' , 'Questionário' , 'Razão' , 'Recibo' , 'Recurso' , 'Regimento' , 'Registro' , 'Regulamento' , 'Relação' , 'Relatório' , 'Release' , 'Requerimento' , 'Requisição' , 'Rescisão' , 'Resolução' , 'Resumo' , 'Romaneio' , 'Roteiro' ,
  //   'Sentença' , 'Súmula' ,
  //   'Tabela' , 'Talonário' , 'Telegrama'];
  especiesDocumentais: any[] = [
    {
      descricao: "Acórdão",
      atividades: [
        "Acórdão de exemplo 1",
        "Acórdão de exemplo 2",
        "Acórdão de exemplo 3",
      ],
    },
    {
      descricao: "Acordo",
      atividades: [
        "Acordo de exemplo 1",
        "Acordo de exemplo 2",
        "Acordo de exemplo 3",
      ],
    },
    {
      descricao: "Agenda",
      atividades: [
        "Agenda de exemplo 1",
        "Agenda de exemplo 2",
        "Agenda de exemplo 3",
      ],
    },
    {
      descricao: "Agravo",
      atividades: [
        "Agravo de exemplo 1",
        "Agravo de exemplo 2",
        "Agravo de exemplo 3",
      ],
    },
    {
      descricao: "Alvará",
      atividades: [
        "Alvará de exemplo 1",
        "Alvará de exemplo 2",
        "Alvará de exemplo 3",
      ],
    },
    {
      descricao: "Anexo",
      atividades: [
        "Anexo de exemplo 1",
        "Anexo de exemplo 2",
        "Anexo de exemplo 3",
      ],
    },
    {
      descricao: "Anotação",
      atividades: [
        "Anotação de exemplo 1",
        "Anotação de exemplo 2",
        "Anotação de exemplo 3",
      ],
    },
    {
      descricao: "Anuário",
      atividades: [
        "Anuário de exemplo 1",
        "Anuário de exemplo 2",
        "Anuário de exemplo 3",
      ],
    },
    {
      descricao: "Anuência",
      atividades: [
        "Anuência de exemplo 1",
        "Anuência de exemplo 2",
        "Anuência de exemplo 3",
      ],
    },
    {
      descricao: "Apólice",
      atividades: [
        "Apólice de exemplo 1",
        "Apólice de exemplo 2",
        "Apólice de exemplo 3",
      ],
    },
    {
      descricao: "Ata",
      atividades: ["Ata de exemplo 1", "Ata de exemplo 2", "Ata de exemplo 3"],
    },
    {
      descricao: "Atestado",
      atividades: [" de exemplo 1", " de exemplo 2", " de exemplo 3"],
    },
    {
      descricao: "Ato",
      atividades: [" de exemplo 1", " de exemplo 2", " de exemplo 3"],
    },
    {
      descricao: "Auto",
      atividades: [
        "Auto de exemplo 1",
        "Auto de exemplo 2",
        "Auto de exemplo 3",
      ],
    },
    {
      descricao: "Avaliação",
      atividades: [
        "Avaliação de exemplo 1",
        "Avaliação de exemplo 2",
        "Avaliação de exemplo 3",
      ],
    },
    {
      descricao: "Aviso",
      atividades: [
        "Aviso de exemplo 1",
        "Aviso de exemplo 2",
        "Aviso de exemplo 3",
      ],
    },
    {
      descricao: "Balancete",
      atividades: [
        "Balancete de exemplo 1",
        "Balancete de exemplo 2",
        "Balancete de exemplo 3",
      ],
    },
    {
      descricao: "Balanço",
      atividades: [
        "Balanço de exemplo 1",
        "Balanço de exemplo 2",
        "Balanço de exemplo 3",
      ],
    },
    {
      descricao: "Bilhete",
      atividades: [
        "Bilhete de exemplo 1",
        "Bilhete de exemplo 2",
        "Bilhete de exemplo 3",
      ],
    },
    {
      descricao: "Boleto",
      atividades: [
        "Boleto de exemplo 1",
        "Boleto de exemplo 2",
        "Boleto de exemplo 3",
      ],
    },
    {
      descricao: "Boletim",
      atividades: [
        "Boletim de exemplo 1",
        "Boletim de exemplo 2",
        "Boletim de exemplo 3",
      ],
    },
    {
      descricao: "Cadastro",
      atividades: [
        "Cadastro de exemplo 1",
        "Cadastro de exemplo 2",
        "Cadastro de exemplo 3",
      ],
    },
    {
      descricao: "Caderno",
      atividades: [
        "Caderno de exemplo 1",
        "Caderno de exemplo 2",
        "Caderno de exemplo 3",
      ],
    },
    {
      descricao: "Carta",
      atividades: [
        "Carta de exemplo 1",
        "Carta de exemplo 2",
        "Carta de exemplo 3",
      ],
    },
    {
      descricao: "Carta-convite",
      atividades: [
        "Carta-convite de exemplo 1",
        "Carta-convite de exemplo 2",
        "Carta-convite de exemplo 3",
      ],
    },
    {
      descricao: "Cartão",
      atividades: [
        "Cartão de exemplo 1",
        "Cartão de exemplo 2",
        "Cartão de exemplo 3",
      ],
    },
    {
      descricao: "Cartaz",
      atividades: [
        "Cartaz de exemplo 1",
        "Cartaz de exemplo 2",
        "Cartaz de exemplo 3",
      ],
    },
    {
      descricao: "Cartilha",
      atividades: [
        "Cartilha de exemplo 1",
        "Cartilha de exemplo 2",
        "Cartilha de exemplo 3",
      ],
    },
    {
      descricao: "Catálogo",
      atividades: [
        "Catálogo de exemplo 1",
        "Catálogo de exemplo 2",
        "Catálogo de exemplo 3",
      ],
    },
    {
      descricao: "Cronograma",
      atividades: [
        "Cronograma de exemplo 1",
        "Cronograma de exemplo 2",
        "Cronograma de exemplo 3",
      ],
    },
    {
      descricao: "Croqui",
      atividades: [
        "Croqui de exemplo 1",
        "Croqui de exemplo 2",
        "Croqui de exemplo 3",
      ],
    },
    {
      descricao: "Cupom",
      atividades: [
        "Cupom de exemplo 1",
        "Cupom de exemplo 2",
        "Cupom de exemplo 3",
      ],
    },
    {
      descricao: "Debênture",
      atividades: [
        "Debênture de exemplo 1",
        "Debênture de exemplo 2",
        "Debênture de exemplo 3",
      ],
    },
    {
      descricao: "Decisão",
      atividades: [
        "Decisão de exemplo 1",
        "Decisão de exemplo 2",
        "Decisão de exemplo 3",
      ],
    },
    {
      descricao: "Declaração",
      atividades: [
        "Declaração de exemplo 1",
        "Declaração de exemplo 2",
        "Declaração de exemplo 3",
      ],
    },
    {
      descricao: "Decreto",
      atividades: [
        "Decreto de exemplo 1",
        "Decreto de exemplo 2",
        "Decreto de exemplo 3",
      ],
    },
    {
      descricao: "Decreto-lei",
      atividades: [
        "Decreto-lei de exemplo 1",
        "Decreto-lei de exemplo 2",
        "Decreto-lei de exemplo 3",
      ],
    },
    {
      descricao: "Deliberação",
      atividades: [
        "Deliberação de exemplo 1",
        "Deliberação de exemplo 2",
        "Deliberação de exemplo 3",
      ],
    },
    {
      descricao: "Demonstrativo",
      atividades: [
        "Demonstrativo de exemplo 1",
        "Demonstrativo de exemplo 2",
        "Demonstrativo de exemplo 3",
      ],
    },
    {
      descricao: "Depósito",
      atividades: [
        "Depósito de exemplo 1",
        "Depósito de exemplo 2",
        "Depósito de exemplo 3",
      ],
    },
    {
      descricao: "Despacho",
      atividades: [
        "Despacho de exemplo 1",
        "Despacho de exemplo 2",
        "Despacho de exemplo 3",
      ],
    },
    {
      descricao: "Detalhe",
      atividades: [
        "Detalhe de exemplo 1",
        "Detalhe de exemplo 2",
        "Detalhe de exemplo 3",
      ],
    },
    {
      descricao: "Diagrama",
      atividades: [
        "Diagrama de exemplo 1",
        "Diagrama de exemplo 2",
        "Diagrama de exemplo 3",
      ],
    },
    {
      descricao: "Diário",
      atividades: [
        "Diário de exemplo 1",
        "Diário de exemplo 2",
        "Diário de exemplo 3",
      ],
    },
    {
      descricao: "Documento de arrecadação",
      atividades: [
        "Documento de arrecadação de exemplo 1",
        "Documento de arrecadação de exemplo 2",
        "Documento de arrecadação de exemplo 3",
      ],
    },
    {
      descricao: "Documento auxiliar",
      atividades: [
        "Documento auxiliar de exemplo 1",
        "Documento auxiliar de exemplo 2",
        "Documento auxiliar de exemplo 3",
      ],
    },
    {
      descricao: "Dossiê",
      atividades: [
        "Dossiê de exemplo 1",
        "Dossiê de exemplo 2",
        "Dossiê de exemplo 3",
      ],
    },
    {
      descricao: "Duplicata",
      atividades: [
        "Duplicata de exemplo 1",
        "Duplicata de exemplo 2",
        "Duplicata de exemplo 3",
      ],
    },
    {
      descricao: "Edital",
      atividades: [
        "Edital de exemplo 1",
        "Edital de exemplo 2",
        "Edital de exemplo 3",
      ],
    },
    {
      descricao: "Embargos",
      atividades: [
        "Embargos de exemplo 1",
        "Embargos de exemplo 2",
        "Embargos de exemplo 3",
      ],
    },
    {
      descricao: "Emenda",
      atividades: [
        "Emenda de exemplo 1",
        "Emenda de exemplo 2",
        "Emenda de exemplo 3",
      ],
    },
    {
      descricao: "E-mail/mensagem",
      atividades: [
        "E-mail/mensagem de exemplo 1",
        "E-mail/mensagem de exemplo 2",
        "E-mail/mensagem de exemplo 3",
      ],
    },
    {
      descricao: "Eletrônica",
      atividades: [
        "Eletrônica de exemplo 1",
        "Eletrônica de exemplo 2",
        "Eletrônica de exemplo 3",
      ],
    },
    {
      descricao: "Escritura",
      atividades: [
        "Escritura de exemplo 1",
        "Escritura de exemplo 2",
        "Escritura de exemplo 3",
      ],
    },
    {
      descricao: "Especificação",
      atividades: [
        "Especificação de exemplo 1",
        "Especificação de exemplo 2",
        "Especificação de exemplo 3",
      ],
    },
    {
      descricao: "Legislação",
      atividades: [
        "Legislação de exemplo 1",
        "Legislação de exemplo 2",
        "Legislação de exemplo 3",
      ],
    },
    {
      descricao: "Lei",
      atividades: ["Lei de exemplo 1", "Lei de exemplo 2", "Lei de exemplo 3"],
    },
    {
      descricao: "Letra de Câmbio",
      atividades: [
        "Letra de Câmbio de exemplo 1",
        "Letra de Câmbio de exemplo 2",
        "Letra de Câmbio de exemplo 3",
      ],
    },
    {
      descricao: "Licença",
      atividades: [
        "Licença de exemplo 1",
        "Licença de exemplo 2",
        "Licença de exemplo 3",
      ],
    },
    {
      descricao: "Lista",
      atividades: [
        "Lista de exemplo 1",
        "Lista de exemplo 2",
        "Lista de exemplo 3",
      ],
    },
    {
      descricao: "Livro registro",
      atividades: [
        "Livro registro de exemplo 1",
        "Livro registro de exemplo 2",
        "Livro registro de exemplo 3",
      ],
    },
    {
      descricao: "Mandado",
      atividades: [
        "Mandado de exemplo 1",
        "Mandado de exemplo 2",
        "Mandado de exemplo 3",
      ],
    },
    {
      descricao: "Mandato",
      atividades: [
        "Mandato de exemplo 1",
        "Mandato de exemplo 2",
        "Mandato de exemplo 3",
      ],
    },
    {
      descricao: "Manual",
      atividades: [
        "Manual de exemplo 1",
        "Manual de exemplo 2",
        "Manual de exemplo 3",
      ],
    },
    {
      descricao: "Mapa",
      atividades: [
        "Mapa de exemplo 1",
        "Mapa de exemplo 2",
        "Mapa de exemplo 3",
      ],
    },
    {
      descricao: "Matrícula",
      atividades: [
        "Matrícula de exemplo 1",
        "Matrícula de exemplo 2",
        "Matrícula de exemplo 3",
      ],
    },
    {
      descricao: "Medida provisória",
      atividades: [
        "Medida provisória de exemplo 1",
        "Medida provisória de exemplo 2",
        "Medida provisória de exemplo 3",
      ],
    },
    {
      descricao: "Memorando",
      atividades: [
        "Memorando de exemplo 1",
        "Memorando de exemplo 2",
        "Memorando de exemplo 3",
      ],
    },
    {
      descricao: "Memória",
      atividades: [
        "Memória de exemplo 1",
        "Memória de exemplo 2",
        "Memória de exemplo 3",
      ],
    },
    {
      descricao: "Memorial",
      atividades: [
        "Memorial de exemplo 1",
        "Memorial de exemplo 2",
        "Memorial de exemplo 3",
      ],
    },
    {
      descricao: "Mensagem",
      atividades: [
        "Mensagem de exemplo 1",
        "Mensagem de exemplo 2",
        "Mensagem de exemplo 3",
      ],
    },
    {
      descricao: "Minuta",
      atividades: [
        "Minuta de exemplo 1",
        "Minuta de exemplo 2",
        "Minuta de exemplo 3",
      ],
    },
    {
      descricao: "Movimento",
      atividades: [
        "Movimento de exemplo 1",
        "Movimento de exemplo 2",
        "Movimento de exemplo 3",
      ],
    },
    {
      descricao: "Movimentação",
      atividades: [
        "Movimentação de exemplo 1",
        "Movimentação de exemplo 2",
        "Movimentação de exemplo 3",
      ],
    },
    {
      descricao: "Norma",
      atividades: [
        "Norma de exemplo 1",
        "Norma de exemplo 2",
        "Norma de exemplo 3",
      ],
    },
    {
      descricao: "Nota de Crédito",
      atividades: [
        "Nota de Crédito de exemplo 1",
        "Nota de Crédito de exemplo 2",
        "Nota de Crédito de exemplo 3",
      ],
    },
    {
      descricao: "Nota de Débito",
      atividades: [
        "Nota de Débito de exemplo 1",
        "Nota de Débito de exemplo 2",
        "Nota de Débito de exemplo 3",
      ],
    },
    {
      descricao: "Nota Fiscal",
      atividades: [
        "Nota Fiscal de exemplo 1",
        "Nota Fiscal de exemplo 2",
        "Nota Fiscal de exemplo 3",
      ],
    },
    {
      descricao: "Nota Promissória",
      atividades: [
        "Nota Promissória de exemplo 1",
        "Nota Promissória de exemplo 2",
        "Nota Promissória de exemplo 3",
      ],
    },
    {
      descricao: "Notificação",
      atividades: [
        "Notificação de exemplo 1",
        "Notificação de exemplo 2",
        "Notificação de exemplo 3",
      ],
    },
    {
      descricao: "Ofício",
      atividades: [
        "Ofício de exemplo 1",
        "Ofício de exemplo 2",
        "Ofício de exemplo 3",
      ],
    },
    {
      descricao: "Orçamento",
      atividades: [
        "Orçamento de exemplo 1",
        "Orçamento de exemplo 2",
        "Orçamento de exemplo 3",
      ],
    },
    {
      descricao: "Ordem",
      atividades: [
        "Ordem de exemplo 1",
        "Ordem de exemplo 2",
        "Ordem de exemplo 3",
      ],
    },
    {
      descricao: "Organograma",
      atividades: [
        "Organograma de exemplo 1",
        "Organograma de exemplo 2",
        "Organograma de exemplo 3",
      ],
    },
    {
      descricao: "Programa",
      atividades: [
        "Programa de exemplo 1",
        "Programa de exemplo 2",
        "Programa de exemplo 3",
      ],
    },
    {
      descricao: "Programação",
      atividades: [
        "Programação de exemplo 1",
        "Programação de exemplo 2",
        "Programação de exemplo 3",
      ],
    },
    {
      descricao: "Projeto",
      atividades: [
        "Projeto de exemplo 1",
        "Projeto de exemplo 2",
        "Projeto de exemplo 3",
      ],
    },
    {
      descricao: "Prontuário",
      atividades: [
        "Prontuário de exemplo 1",
        "Prontuário de exemplo 2",
        "Prontuário de exemplo 3",
      ],
    },
    {
      descricao: "Proposta",
      atividades: [
        "Proposta de exemplo 1",
        "Proposta de exemplo 2",
        "Proposta de exemplo 3",
      ],
    },
    {
      descricao: "Protocolo",
      atividades: [
        "Protocolo de exemplo 1",
        "Protocolo de exemplo 2",
        "Protocolo de exemplo 3",
      ],
    },
    {
      descricao: "Quadro",
      atividades: [
        "Quadro de exemplo 1",
        "Quadro de exemplo 2",
        "Quadro de exemplo 3",
      ],
    },
    {
      descricao: "Questionário",
      atividades: [
        "Questionário de exemplo 1",
        "Questionário de exemplo 2",
        "Questionário de exemplo 3",
      ],
    },
    {
      descricao: "Razão",
      atividades: [
        "Razão de exemplo 1",
        "Razão de exemplo 2",
        "Razão de exemplo 3",
      ],
    },
    {
      descricao: "Recibo",
      atividades: [
        "Recibo de exemplo 1",
        "Recibo de exemplo 2",
        "Recibo de exemplo 3",
      ],
    },
    {
      descricao: "Recurso",
      atividades: [
        "Recurso de exemplo 1",
        "Recurso de exemplo 2",
        "Recurso de exemplo 3",
      ],
    },
    {
      descricao: "Regimento",
      atividades: [
        "Regimento de exemplo 1",
        "Regimento de exemplo 2",
        "Regimento de exemplo 3",
      ],
    },
    {
      descricao: "Registro",
      atividades: [
        "Registro de exemplo 1",
        "Registro de exemplo 2",
        "Registro de exemplo 3",
      ],
    },
    {
      descricao: "Regulamento",
      atividades: [
        "Regulamento de exemplo 1",
        "Regulamento de exemplo 2",
        "Regulamento de exemplo 3",
      ],
    },
    {
      descricao: "Relação",
      atividades: [
        "Relação de exemplo 1",
        "Relação de exemplo 2",
        "Relação de exemplo 3",
      ],
    },
    {
      descricao: "Relatório",
      atividades: [
        "Relatório de exemplo 1",
        "Relatório de exemplo 2",
        "Relatório de exemplo 3",
      ],
    },
    {
      descricao: "Release",
      atividades: [
        "Release de exemplo 1",
        "Release de exemplo 2",
        "Release de exemplo 3",
      ],
    },
    {
      descricao: "Requerimento",
      atividades: [
        "Requerimento de exemplo 1",
        "Requerimento de exemplo 2",
        "Requerimento de exemplo 3",
      ],
    },
    {
      descricao: "Requisição",
      atividades: [
        "Requisição de exemplo 1",
        "Requisição de exemplo 2",
        "Requisição de exemplo 3",
      ],
    },
    {
      descricao: "Rescisão",
      atividades: [
        "Rescisão de exemplo 1",
        "Rescisão de exemplo 2",
        "Rescisão de exemplo 3",
      ],
    },
    {
      descricao: "Resolução",
      atividades: [
        "Resolução de exemplo 1",
        "Resolução de exemplo 2",
        "Resolução de exemplo 3",
      ],
    },
    {
      descricao: "Resumo",
      atividades: [
        "Resumo de exemplo 1",
        "Resumo de exemplo 2",
        "Resumo de exemplo 3",
      ],
    },
    {
      descricao: "Romaneio",
      atividades: [
        "Romaneio de exemplo 1",
        "Romaneio de exemplo 2",
        "Romaneio de exemplo 3",
      ],
    },
    {
      descricao: "Roteiro",
      atividades: [
        "Roteiro de exemplo 1",
        "Roteiro de exemplo 2",
        "Roteiro de exemplo 3",
      ],
    },
    {
      descricao: "Sentença",
      atividades: [
        "Sentença de exemplo 1",
        "Sentença de exemplo 2",
        "Sentença de exemplo 3",
      ],
    },
    {
      descricao: "Súmula",
      atividades: [
        "Súmula de exemplo 1",
        "Súmula de exemplo 2",
        "Súmula de exemplo 3",
      ],
    },
    {
      descricao: "Tabela",
      atividades: [
        "Tabela de exemplo 1",
        "Tabela de exemplo 2",
        "Tabela de exemplo 3",
      ],
    },
    {
      descricao: "Talonário",
      atividades: [
        "Talonário de exemplo 1",
        "Talonário de exemplo 2",
        "Talonário de exemplo 3",
      ],
    },
    {
      descricao: "Telegrama",
      atividades: [
        "Telegrama de exemplo 1",
        "Telegrama de exemplo 2",
        "Telegrama de exemplo 3",
      ],
    },
    { descricao: "Outra Opção" },
  ];
  atividadesList: any[] = [];
  outraOpcaoEspecie: any = false;
  setAtividadeList(descricao) {
    if (descricao == "Outra Opção") {
      this.outraOpcaoEspecie = true;
    } else {
      this.outraOpcaoEspecie = false;
      this.atividadesList = this.especiesDocumentais.find(
        (options) => options.descricao === descricao
      ).atividades;
    }
  }
  docsDigitalizado: string[] = [
    "1999",
    "2000",
    "2001",
    "2002",
    "2003",
    "2004",
    "2005",
    "2006",
    "2007",
    "2008",
    "2009",
    "2010",
  ];

  tempoArquivados: string[] = [
    "7 Anos",
    "10 Anos",
    "12 Anos",
    "20 Anos",
    "25 Anos",
    "30 Anos",
    "50 Anos",
  ];

  tempoArquivadosArea: string[] = ["1 Ano", "2 Anos", "3 Anos"];

  quantidadeFolhas: string[] = [
    "5000",
    "10000",
    "20000",
    "30000",
    "40000",
    "50000",
    "100000",
    "150000",
    "200000",
    "250000",
    "300000",
    "400000",
    "500000",
    "Acima de 000.000",
  ];

  quantidadeImpressoes: string[] = [
    "Abaixo de 50 folhas",
    "Acima de 50 folhas",
    "Acima de 100 folhas",
    "Acima de 150 folhas",
    "Acima de 200 folhas",
    "Não se aplica",
  ];

  suportesMidia: string[] = [
    "CD",
    "Digital",
    "Disquete",
    "DVD",
    "VHS",
    "Fita Cassete",
    "Microfilme",
    "Papel",
    "Papel/Digital",
  ];

  formatos: string[] = [
    "A0",
    "A1",
    "A3",
    "A4",
    "A5",
    ".DOC",
    ".XLS",
    ".PDF",
    ".JPG",
  ];

  destinacoesFinais: string[] = [
    "Descarte/Fragmentação",
    "Guarda Permanente",
    "Digitalização/Descarte",
    "Digitalização/Guarda Permanente",
  ];

  acessos: string[] = [
    "C - Confidencial",
    "I - Interna",
    "P - Pública",
    "R - Restrita",
  ];

  vias: string[] = ["Minuta", "Original", "Xerox/Cópia"];

  valores: string[] = [
    "A - Administrativo",
    "C - Contábil",
    "E - Estratégico",
    "F - Fiscal",
    "Fi - Financeiro",
    "H - Histórico",
    "I - Informativo",
    "L - Legal",
    "N - Normativo",
    "Pe - Previdenciário/Trabalhista",
    "S - Societário",
    "T - Tributário",
    "Te - Técnico",
    "Co - Comercial",
  ];

  metadadosIndexadores: string[] = [
    "Lista",
    "Data/Texto/Número",
    "Número",
    "Texto",
    "Texto/Número",
  ];

  formasEnvioDocs: string[] = [
    "Email",
    "Matole",
    "Pessoalmente",
    "Sistema",
    "Sim",
    "Não",
    "Não se Aplica",
  ];

  consultas: string[] = ["Diário", "Esporadicamente", "Não é consultado"];

  tipoAssinaturas: string[] = [
    "Físico com assinatura",
    "Digital com assinatura digital ICP Brasil",
    "Digital com aceite eletrônico",
    "Não é assinado",
  ];

  tempoMensalGastos: string[] = [
    "1 hora",
    "2 horas",
    "3 horas",
    "4 horas",
    "5 horas",
    "6 horas",
  ];

  atualizacoes: string[] = ["Diário", "Mensal", "Não é atualizado"];

  quantidadePessoas: string[] = ["1", "2", "3", "4", "5"];

  midiaAnexadoDocumentos: string[] = ["Sim", "Não", "Migrar de Mídia"];
  horarios: string[] = [
    "12/10/2021 12:30",
    "22/11/2021 14:30",
    "30/12/2021 16:30",
  ];
  data1: any;
  selected: any;
  selectedTipologiaDocumental: any;
  selectedProcesso: any;
  selectedQuantidadeFolha: any;
  selectedAtividade: any;
  selectedEspecieDocumental: any;
  selectedMidiaAnexadoDocumento: any;
  selectedValor: any;
  selectedAcesso: any;
  selectedSuporteMidia: any;
  selectedVia: any;
  selectedFormato: any;
  selectedQuantidadePessoa: any;
  selectedFormaEnvioDoc: any;
  selectedTipoAssinatura: any;
  selectedConsulta: any;
  selectedAtualizacao: any;
  selectedTempoMensalGasto: any;
  selectedTempoArquivoArea: any;
  selectedTempoArquivo: any;
  selectedDestinacaoFinal: any;
  selectedQuantidadeImpressao: any;
  selectedMetadadosIndexador: any;
  selectedDocDigitalizado: any;

  setVar(questao) {
    this.formMatriz.get(questao).setValue(this.search.value);
    if (questao === "q4" || questao === "q3") {
      var q4 = this.formMatriz.get("q4").value;
      var q3 = this.formMatriz.get("q3").value;
      this.formMatriz.get("q1").setValue(q4 + " de " + q3);
    }

    console.log(this.formMatriz.get(questao).value);
  }

  setTipologia() {
    var q4 = this.formMatriz.get("q4").value;
    var q3 = this.formMatriz.get("q3").value;
    this.formMatriz.get("q1").setValue(q4 + " de " + q3);
  }
  inserirMatrizGD() {
    var matriz = this.formMatriz.value;
    this.matrizGD.dtCreation = new Date();
    // this.matrizGD.entrevistado = this.mapeamento.captacaoInformacao.entrevistado
    this.matrizGD.questionario = this.questionario;
    //Essas respostas terão que ter o nome que vc colocar no FormBuild (aconselho ser q1,q2,q3... pq fica dinâmico o preenchimento como no exemplo abaixo)
    for (let index = 0; index < this.perguntas.length; index++) {
      var resposta = new Resposta();
      resposta.pergunta = new Pergunta();
      resposta.pergunta.id = this.perguntas[index].id;
      resposta.pergunta.pergunta = this.perguntas[index].pergunta;
      resposta.resposta = matriz["q" + (index + 1)];
      console.log(resposta);
      this.matrizGD.respostas.push(resposta);
    }
    console.log(this.matrizGD);
    this.formularioMatrizService
      .insertMatrizGD(this.matrizGD, this.id)
      .subscribe((response) => {
        this.router.navigate(["/monitoramento"], { relativeTo: this.route });
      });
  }

  getQuestionario() {
    this.questionarioService.getById(2).subscribe((response) => {
      this.questionario = response;

      this.perguntas = this.questionario.perguntas;
    });
  }
}
