import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-suporte-portais-sistemas',
  templateUrl: './suporte-portais-sistemas.component.html',
  styleUrls: ['./suporte-portais-sistemas.component.css']
})
export class SuportePortaisSistemasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
