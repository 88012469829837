<!-- <h1 class="mt-2">Contato</h1> -->

<!-- <p>
    Para dúvidas e auxílio referente a procedimentos de gestão documental: <br><br>
    <strong>
        E-mail: <a href="mailto:gestao.documental.br@telefônica.com?subject=Olá,%20seja%20bem-vindo!">
            gestao.documental.br@telefônica.com </a>
    </strong>
</p> -->


<!-- <p>
    Para dúvidas e auxílio referente a prazos de guarda e descarte de documentos (Temporalidade Documental) com base na
    legislação, consultar a área Jurídica/Secretaria Geral da Telefônica Brasil: <br><br>

    <strong>
        Processum: <a
            href="https://autenticaint.vivo.com.br/loginappv2/login.jsp">https://autenticaint.vivo.com.br/loginappv2/login.jsp
        </a>
    </strong>
</p> -->

<!-- <p>
    Para dúvidas e auxílio referente a questões fiscais e tributárias que envolvam a temporalidade e descarte de
    documentos, consultar: <br><br>

    <strong>E-mail: <a href="mailto:contenciosotributario.br@telefonica.com?subject=Olá,%20seja%20bem-vindo!">
            contenciosotributario.br@telefonica.com </a></strong>
</p> -->



<div class="row">

    <div class="col-lg-6 mt-5">
        <!-- <img src="../../assets/imagens/contato-user.jpg" class="img-fluid" alt="contato"> -->
        <img src="../../assets/imagens/entre em contato@4x.png" class="img-fluid" alt="contato">
    </div>

    <div class="col-lg-6">

        <h1 class="mt-5 text-primary">Contatos</h1>

        <p class="mt-2">
            Para dúvidas e auxílio referente a procedimentos de gestão documental: <br>
            <strong>
                <a href="mailto:gestao.documental.br@telefônica.com?subject=Olá,%20seja%20bem-vindo!">
                    gestao.documental.br@telefônica.com </a>
            </strong>
            <br>
            <strong>
                <a href="mailto:atendimentopesquisa.vivo@soluarq.com.br?subject=Olá,%20seja%20bem-vindo!">
                    atendimentopesquisa.vivo@soluarq.com.br </a>
            </strong>
        </p>

        <p class="mt-2">
            Para dúvidas e auxílio referente a prazos de guarda e descarte de documentos (Temporalidade
            Documental) com base na
            legislação, consultar a área Jurídica/Secretaria Geral da Telefônica Brasil: <br>

            <strong>
                <a href="https://autenticaint.vivo.com.br/loginappv2/login.jsp">https://autenticaint.vivo.com.br/loginappv2/login.jsp
                </a>
            </strong>
        </p>


        <p class="mt-2">
            Para dúvidas e auxílio referente a questões fiscais e tributárias que envolvam a temporalidade e
            descarte de
            documentos, consultar: <br>

            <strong>
                <a href="mailto:contenciosotributario.br@telefonica.com?subject=Olá,%20seja%20bem-vindo!">
                    contenciosotributario.br@telefonica.com
                </a>
            </strong>
        </p>
        <p class="mt-2">
            Suporte do Portal de Governança <br>

            <strong>
                <a href="mailto:suporte@soluarq.com.br?subject=Olá,%20seja%20bem-vindo!">
                    suporte@soluarq.com.br
                </a>
            </strong>
        </p>
    </div>
</div>