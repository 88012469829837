<p class="text-muted mt-4 mb-1">
  <span class="text-primary">Portal de Comissionamento.</span>
</p>

<h1 class="mt-0 mb-0 text-muted">Concluir Pedidos</h1>

<div class="row mt-4">
  <div class="col-sm-8">
    <div class="form-group">
      <mat-form-field appearance="fill">
        <mat-label>Data do pedido</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          [(ngModel)]="criadoEm"
          disabled
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <mat-form-field appearance="fill" class="inputs">
            <mat-label>Ordem do Serviço:</mat-label>
            <input
              matInput
              placeholder="Ex. "
              value=""
              [(ngModel)]="ordemServico"
              disabled
            />
          </mat-form-field>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <mat-form-field appearance="fill" class="inputs">
            <mat-label>Departamento:</mat-label>
            <input
              matInput
              placeholder="Ex. "
              value=""
              [(ngModel)]="departamento"
              disabled
            />
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <mat-form-field appearance="fill">
            <mat-label>Empresa:</mat-label>
            <input
              matInput
              placeholder="Ex. "
              value=""
              [(ngModel)]="razaoSocial"
              disabled
            />
          </mat-form-field>
        </div>
      </div>

      <div class="col-sm-6">
        <div class="form-group">
          <mat-form-field appearance="fill" class="inputs">
            <mat-label>Autorizado:</mat-label>
            <input
              matInput
              placeholder="Ex. "
              value=""
              [(ngModel)]="solicitante"
              disabled
            />
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="form-group">
      <mat-label>Opções</mat-label>
      <br />
      <mat-slide-toggle class="mr-3" [(ngModel)]="isChecked" disabled
        >Urgente</mat-slide-toggle
      >

      <mat-slide-toggle class="mr-3" [(ngModel)]="materialInterno" disabled
        >Material Interno</mat-slide-toggle
      >
    </div>

    <div class="row mt-4">
      <div class="col-sm-12">
        <h1 class="text-primary">Dados para conclusão do Pedido</h1>
      </div>
    </div>

    <div class="form-group">
      <mat-radio-group aria-label="Select an option" [(ngModel)]="tipoEntrega">
        <p>Meio de entrega:</p>
        <mat-radio-button
          value="SOLICITAÇÃO DE PESQUISA DE DOCUMENTOS FÍSICOS"
          disabled
          >Documentos Físicos
        </mat-radio-button>
        <br />
        <mat-radio-button
          value="SOLICITAÇÃO DE PESQUISA DE DOCUMENTOS ELETRÔNICOS"
          disabled
          >Documentos Eletrônicos</mat-radio-button
        >
      </mat-radio-group>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <mat-form-field appearance="fill" class="example-full-width">
            <mat-label>Observação Conclusão</mat-label>
            <textarea
              matInput
              [(ngModel)]="observacaoConclusao"
              (keyup)="checkObservacaoConclusao()"
              disabled
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div id="btnEnviar" class="col-sm-12 text-right">
        <button
          mat-raised-button
          (click)="salvarObservacao()"
          [disabled]="disabledEnviarButton"
        >
          Salvar Observação
        </button>
      </div>
    </div>

    <div class="form-group">
      <div class="input-group">
        <div class="custom-file">
          <input
            type="file"
            #fileUpload
            id="fileUpload"
            name="fileUpload"
            multiple="multiple"
            accept="image/*,
                            application/pdf, 
                            application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                            application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                            application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation,
                            .txt"
            class="custom-file-input"
            (click)="onUploadButtonClick()"
            disabled
          />
          <label class="custom-file-label" for="anexarDocumento"
            >Anexar Documento</label
          >
        </div>
        <!-- <div class="input-group-append">
                    <button class="btn btn-outline-secondary" type="button" >Enviar</button>
                </div> -->
      </div>
    </div>

    <div class="form-group mt-4">
      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSourceAnexos" class="table-striped">
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>Id.</th>
            <td mat-cell *matCellDef="let element">{{ element.id }}</td>
          </ng-container>

          <ng-container matColumnDef="anexo">
            <th mat-header-cell *matHeaderCellDef>Anexo</th>
            <td mat-cell *matCellDef="let element">{{ element.anexo }}</td>
          </ng-container>

          <ng-container matColumnDef="download">
            <th mat-header-cell *matHeaderCellDef>Download</th>
            <td mat-cell *matCellDef="let element">
              <a
                id="deleteAnexo"
                class="cursorHand text-center"
                (click)="setCurrentAnexo(element)"
                data-toggle="modal"
                data-target="#acaoDeletarPedido"
              >
                <mat-icon color="link" class="text-primary" matListIcon
                  >delete</mat-icon
                >
              </a>
              <a
                (click)="baixarAnexo(element.anexoDownload)"
                target="_blank"
                class="cursorHand text-center ml-1"
              >
                <mat-icon color="link" class="text-primary" matListIcon
                  >get_app</mat-icon
                >
              </a>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsAnexos"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumnsAnexos"
          ></tr>
        </table>

        <mat-paginator
          [pageSizeOptions]="[5, 10, 20]"
          showFirstLastButtons
        ></mat-paginator>
      </div>
    </div>
  </div>

  <div class="col-sm-4">
    <div class="form-group bg-white-mod">
      <div class="container">
        <div class="row">
          <!-- <div class="col-sm-3 border-right">
                        <p>
                            Responsável:
                        </p>
                    </div> -->
          <div class="col-sm-12">
            <p>
              <strong> Dados </strong>
            </p>
            <hr />
            <p>
              Nome: <br />
              <span class="text-primary">
                {{ responsavel.nome }}
              </span>
            </p>
            <p>
              Endereço: <br />
              <span class="text-primary">
                {{ responsavel.endereco }}
              </span>
            </p>
            <p>
              Andar: <br />
              <span class="text-primary">
                {{ responsavel.andar }}
              </span>
            </p>
            <p>
              Telefone: <br />
              <span class="text-primary">
                +55 {{ responsavel.telefone1 }}
              </span>
            </p>
            <p>
              E-mail: <br />
              <span class="text-primary">
                {{ responsavel.email }}
              </span>
            </p>
            <p>
              Centro de custo: <br />
              <span class="text-primary">
                {{ responsavel.centroCusto }}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-sm-12">
        <button
          data-toggle="modal"
          data-target="#acaoRecepcionar"
          class="btn btn-primary btn-block mr-2"
        >
          Detalhes do Pedido
        </button>

        <button
          data-toggle="modal"
          data-target="#criarChamadaHelpdesk"
          class="btn btn-danger btn-block mr-2"
          (click)="setCurrentTicketMessage()"
          [disabled]="true"
        >
          Marcar Pedido como Pendente
        </button>

        <button
          data-toggle="modal"
          data-target="#acaoCancelarPedido"
          class="btn btn-success btn-block mr-2"
          disabled
        >
          Concluir Pedido
        </button>

        <button
          routerLink="/pedidos-online"
          class="btn btn-dark btn-block mr-2"
        >
          Voltar
        </button>
      </div>

      <!-- Modal detalhes pedido -->
      <div
        class="modal fade"
        id="acaoRecepcionar"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Detalhes do Pedido
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <mat-form-field appearance="fill" class="example-full-width">
                  <mat-label>Observações</mat-label>
                  <textarea matInput [(ngModel)]="observacao"></textarea>
                </mat-form-field>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group mt-4" *ngIf="showAnexos">
                    <p>Anexos do pedido:</p>

                    <div class="mat-elevation-z8">
                      <table
                        mat-table
                        [dataSource]="dataSourcePedidoAnexos"
                        class="table-striped"
                      >
                        <!-- <ng-container matColumnDef="id">
                                                    <th mat-header-cell *matHeaderCellDef> Id. </th>
                                                    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                                                </ng-container> -->

                        <ng-container matColumnDef="anexo">
                          <th mat-header-cell *matHeaderCellDef>Anexo</th>
                          <td mat-cell *matCellDef="let element">
                            {{ element }}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="download">
                          <th mat-header-cell *matHeaderCellDef>Download</th>
                          <td mat-cell *matCellDef="let element">
                            <a
                              (click)="baixarPedidoAnexo(element)"
                              target="_blank"
                              class="cursorHand text-center ml-1"
                            >
                              <mat-icon
                                color="link"
                                class="text-primary"
                                matListIcon
                              >
                                get_app</mat-icon
                              >
                            </a>
                          </td>
                        </ng-container>

                        <tr
                          mat-header-row
                          *matHeaderRowDef="displayedColumnsPedidoAnexos"
                        ></tr>
                        <tr
                          mat-row
                          *matRowDef="
                            let row;
                            columns: displayedColumnsPedidoAnexos
                          "
                        ></tr>
                      </table>

                      <mat-paginator
                        [pageSizeOptions]="[5, 10, 20]"
                        showFirstLastButtons
                      >
                      </mat-paginator>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <!-- <div class="mat-elevation-z8"> -->
                  <table
                    mat-table
                    [dataSource]="dataSource"
                    class="table-striped"
                    matSort
                  >
                    <ng-container matColumnDef="id">
                      <th mat-header-cell *matHeaderCellDef>Id.</th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.id }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="campo">
                      <th mat-header-cell *matHeaderCellDef>Campo</th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.descricao }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="conteudo">
                      <th mat-header-cell *matHeaderCellDef>Conteúdo</th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.valor }}
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr
                      mat-row
                      *matRowDef="let row; columns: displayedColumns"
                    ></tr>
                  </table>

                  <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
                  <!-- </div> -->
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal cancelar pedido -->
      <div
        class="modal fade text-center"
        id="acaoCancelarPedido"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <h3 class="text-muted mt-4">
                Deseja confirmar a conclusão deste pedido?
              </h3>
              <div class="mb-4">
                <button
                  type="button"
                  class="btn btn-secondary mr-3"
                  data-dismiss="modal"
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  (click)="concluirPedido()"
                >
                  Confimar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row mt-3">

            <div class="col">
                <div class="btn-group btn-group-toggle" data-toggle="buttons">
                    <label class="btn btn-secondary">
                        <input type="radio" name="aguardando">
                        Voltar
                    </label>
                    <label class="btn btn-success">
                        <input type="radio" name="andamento">
                        Repecionar Pedido
                    </label>
                    <label class="btn btn-danger">
                        <input type="radio" name="concluidos">
                        Cancelar este Pedido
                    </label>
                </div>
            </div>


        </div> -->
  </div>

  <!-- Modal detalhes pedido -->
  <div
    class="modal fade"
    id="criarChamadaHelpdesk"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title" id="exampleModalLongTitle">
            {{ tituloPendencia }}
          </h2>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="formChamadoHelpdesk">
            <!-- <div class="row">
                            <div class="col-lg-12">
                                <h4 class="mt-3">{{tituloPendencia}}</h4>
                            </div>
                        </div>
                        <mat-divider></mat-divider> -->
            <br />
            <div class="row">
              <div class="col-lg-8">
                <mat-form-field>
                  <mat-label>Informe a(s) Pendência(s)</mat-label>
                  <input matInput type="text" formControlName="pendencia" />
                </mat-form-field>
              </div>
              <div class="col-lg-2 text-right">
                <button
                  type="button"
                  class="btn btn-danger text-white"
                  style="width: 100%"
                  (click)="limpar()"
                >
                  Limpar
                </button>
              </div>
              <div class="col-lg-2 text-right">
                <button
                  type="button"
                  class="btn btn-info"
                  style="width: 100%"
                  (click)="addPendencia()"
                >
                  Adicionar
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <table>
                  <tr *ngFor="let item of pendenciaList; let i = index">
                    <td>{{ i + 1 }}. {{ item }}</td>
                  </tr>
                </table>
              </div>
            </div>

            <div class="form-group mt-3">
              <mat-form-field>
                <mat-label>Mensagem</mat-label>
                <label for="message"></label>
                <textarea
                  matInput
                  placeholder="Ex: ..."
                  cols="30"
                  rows="4"
                  formControlName="message"
                ></textarea>
              </mat-form-field>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Fechar
          </button>
          <button
            type="button"
            class="btn btn-success"
            data-dismiss="modal"
            [disabled]="formChamadoHelpdesk.invalid || checkPendencias"
            (click)="criarPendencia()"
          >
            Enviar
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade text-center"
    id="acaoDeletarPedido"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <h3 class="text-muted mt-4">
            Deseja deletar o anexo <b>{{ currentAnexo.anexo }}</b
            >?
          </h3>
          <div class="mb-4">
            <button
              type="button"
              class="btn btn-secondary mr-3"
              data-dismiss="modal"
            >
              Cancelar
            </button>
            <button
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
              (click)="deleteAnexo(currentAnexo.id)"
            >
              Confimar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
