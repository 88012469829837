import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CriarArquivoComponent } from "./arquivos/criar/criar-arquivo.component";
import { EditarArquivoComponent } from "./arquivos/editar/editar-arquivo.component";
import { ListarArquivosComponent } from "./arquivos/listagem/listagem.component";
import { ParametrosComponent } from "./arquivos/parametros/parametros.component";
import { VisualizarArquivoComponent } from "./arquivos/visualizar/visualizar.component";
import { VisualizerMinhaComponent } from "./arquivos/visualizer-minha/visualizer-minha.component";
import { BoasVindasComponent } from "./boas-vindas/boas-vindas.component";
import { CriarCategoriaDashboardComponent } from "./dashboards/criar-categoria-dashboard/criar-categoria-dashboard.component";
import { CriarDashBoardComponent } from "./dashboards/criar/criar.component";
import { EditarDashBoardComponent } from "./dashboards/editar/editar.component";
import { ListarCategoriasDashboardComponent } from "./dashboards/listar-categorias-dashboard/listar-categorias-dashboard.component";
import { ListarDashBoardsComponent } from "./dashboards/visualizar/listagem.component";
import { ConsultarInsumosComponent } from "./insumos/consultar-insumos/consultar-insumos.component";
import { CriarComponent } from "./insumos/criar/criar.component";
import { InsumoFieldConfigComponent } from "./insumos/insumo-field-config/insumo-field-config.component";
import { InsumoVisualizerComponent } from "./insumos/insumo-visualizer/insumo-visualizer.component";
import { VisualizerMeusComponent } from "./insumos/visualizer-meus/visualizer-meus.component";
import { AuthGuard } from "./_helpers";

// imports login
// CONST
const accountModule = () =>
  import("./account/account.module").then((x) => x.AccountModule);
const usersModule = () =>
  import("./users/users.module").then((x) => x.UsersModule);
const PerfilUsuarioModule = () =>
  import("./perfil-usuario/perfil-usuario.module").then(
    (x) => x.PerfilUsuarioModule
  );
const CriacaoConteudoModule = () =>
  import("./criacao-conteudo/criacao-conteudo.module").then(
    (x) => x.CriacaoConteudoModule
  );
const ContatoModule = () =>
  import("./contato/contato.module").then((x) => x.ContatoModule);
const FaqModule = () => import("./faq/faq.module").then((x) => x.FaqModule);
const QuemSomosModule = () =>
  import("./quem-somos/quem-somos.module").then((x) => x.QuemSomosModule);
const OQueFazemosModule = () =>
  import("./o-que-fazemos/o-que-fazemos.module").then(
    (x) => x.OQueFazemosModule
  );
const SobreModule = () =>
  import("./sobre/sobre.module").then((x) => x.SobreModule);
const AbrirTicketModule = () =>
  import("./abrir-ticket/abrir-ticket.module").then((x) => x.AbrirTicketModule);
const PedidosOnline = () =>
  import("./pedidos-online/pedidos-online.module").then(
    (x) => x.PedidosOnlineModule
  );
const Relatorios = () =>
  import("./relatorios/relatorios.module").then((x) => x.RelatoriosModule);
const Tabelas = () =>
  import("./tabelas/tabelas.module").then((x) => x.TabelasModule);
const Triagem = () =>
  import("./triagem/triagem.module").then((x) => x.TriagemModule);
const SolicitacacoCredenciamentoParceiro = () =>
  import(
    "./solicitacaco-credenciamento-parceiro/solicitacaco-credenciamento-parceiro.module"
  ).then((x) => x.SolicitacacoCredenciamentoParceiroModule);
const RecepcionarPedidos = () =>
  import("./recepcionar-pedidos/recepcionar-pedidos.module").then(
    (x) => x.RecepcionarPedidosModule
  );
const ConcluirPedido = () =>
  import("./concluir-pedido/concluir-pedido.module").then(
    (x) => x.ConcluirPedidoModule
  );
const VisualizarPedido = () =>
  import("./visualizar-pedido/vizualizar-pedido.module").then(
    (x) => x.VisualizarPedidoModule
  );
const OpcoesAgendamento = () =>
  import("./opcoes-agendamento/opcoes-agendamento.module").then(
    (x) => x.OpcoesAgendamentoModule
  );
const EscolhaAgendamento = () =>
  import("./escolha-agendamento/escolha-agendamento.module").then(
    (x) => x.EscolhaAgendamentoModule
  );
const FormularioMatriz = () =>
  import("./formulario-matriz/formulario-matriz.module").then(
    (x) => x.FormularioMatrizModule
  );
const GestaoUsuario = () =>
  import("./gestao-usuarios/gestao-usuarios.module").then(
    (x) => x.GestaoUsuarioModule
  );
const SolicitacaoAcesso = () =>
  import("././solicitacao-de-acesso/solicitacao-de-acesso.module").then(
    (x) => x.SolicitacaoAcessoModule
  );
const ConsultarTickets = () =>
  import("./consultar-tickets/consultar-tickets.module").then(
    (x) => x.ConsultarTicketsModule
  );

const RelatorioTickets = () =>
  import("./relatorio-ticket/relatorio-ticket.module").then(
    (x) => x.RelatorioTicketModule
  );

// new screens
// const Product = () => import('./product/product.module').then(x => x.ProductModule);
// const ProrrogacaoRegra = () => import('./projeto-e-demanda/prorrogacao-regra/prorrogacao-regra.module').then(x => x.ProrrogacaoRegraModule);
// const RequestAccess = () => import('./account/request-access/request-access.module').then(x => x.RequestAccessModule);
// const Regras = () => import('./regras/regras.module').then(x => x.RegrasModule);
const ProjetoEDemanda = () =>
  import("./projeto-e-demanda/projeto-e-demanda.module").then(
    (x) => x.ProjetoEDemandaModule
  );
const Auditoria = () =>
  import("./auditoria/auditoria.module").then((x) => x.AuditoriaModule);
const SuportePortaisSistemas = () =>
  import("./suporte-portais-sistemas/suporte-portais-sistemas.module").then(
    (x) => x.SuportePortaisSistemasModule
  );
const ConsultaJuridica = () =>
  import("./consulta-juridica/consulta-juridica.module").then(
    (x) => x.ConsultaJuridicaModule
  );

const Insumos = () =>
  import("./insumos/insumos.module").then((x) => x.InsumosModule);

const Arquivos = () =>
  import("./arquivos/arquivos.module").then((x) => x.ArquivosModule);

// juan's screens
const ComissaoContestacao = () =>
  import("./comissao-contestacao/comissao-contestacao.module").then(
    (x) => x.ComissaoContestacaoModule
  );
// const ComissaoContestacao = () => import('./comissao-contestacao/comissao-contestacao.module').then(x => x.ComissaoContestacaoModule);
const MeusTickets = () =>
  import("./meus-tickets/meus-tickets.module").then((x) => x.MeusTicketsModule);
const AtenderTickets = () =>
  import("./atender-tickets/atender-tickets.module").then(
    (x) => x.AtenderTicketsModule
  );
const AtenderTicketsOperacaoVivo = () =>
  import(
    "./atender-tickets-operacao-vivo/atender-tickets-operacao-vivo.module"
  ).then((x) => x.AtenderTicketsOperacaoVivoModule);
const Comissionamento = () =>
  import("./comissionamento/comissionamento.module").then(
    (x) => x.ComissionamentoModule
  );
const SolicitacoesInternas = () =>
  import("./solicitacoes-internas/solicitacoes-internas.module").then(
    (x) => x.SolicitacoesInternasModule
  );
// NEW ROUTES
const routes: Routes = [
  { path: "", component: BoasVindasComponent, canActivate: [AuthGuard] },
  { path: "users", loadChildren: usersModule, canActivate: [AuthGuard] },
  { path: "account", loadChildren: accountModule },
  {
    path: "perfil-usuario",
    loadChildren: PerfilUsuarioModule,
    canActivate: [AuthGuard],
  },
  {
    path: "criacao-conteudo",
    loadChildren: CriacaoConteudoModule,
    canActivate: [AuthGuard],
  },
  { path: "contato", loadChildren: ContatoModule, canActivate: [AuthGuard] },
  { path: "faq", loadChildren: FaqModule, canActivate: [AuthGuard] },
  {
    path: "quem-somos",
    loadChildren: QuemSomosModule,
    canActivate: [AuthGuard],
  },
  {
    path: "o-que-fazemos",
    loadChildren: OQueFazemosModule,
    canActivate: [AuthGuard],
  },
  { path: "sobre", loadChildren: SobreModule, canActivate: [AuthGuard] },
  {
    path: "abrir-ticket",
    loadChildren: AbrirTicketModule,
    canActivate: [AuthGuard],
  },
  {
    path: "pedidos-online",
    loadChildren: PedidosOnline,
    canActivate: [AuthGuard],
  },
  { path: "relatorios", loadChildren: Relatorios, canActivate: [AuthGuard] },
  { path: "tabelas", loadChildren: Tabelas, canActivate: [AuthGuard] },
  { path: "triagem", loadChildren: Triagem, canActivate: [AuthGuard] },
  {
    path: "solicitacao-credenciamento-parceiro",
    loadChildren: SolicitacacoCredenciamentoParceiro,
    canActivate: [AuthGuard],
  },
  {
    path: "solicitacao-credenciamento-parceiro/:id",
    loadChildren: SolicitacacoCredenciamentoParceiro,
    canActivate: [AuthGuard],
  },
  {
    path: "recepcionar-pedidos",
    loadChildren: RecepcionarPedidos,
    canActivate: [AuthGuard],
  },
  {
    path: "recepcionar-pedidos/:id",
    loadChildren: RecepcionarPedidos,
    canActivate: [AuthGuard],
  },
  {
    path: "concluir-pedido",
    loadChildren: ConcluirPedido,
    canActivate: [AuthGuard],
  },
  {
    path: "concluir-pedido/:id",
    loadChildren: ConcluirPedido,
    canActivate: [AuthGuard],
  },
  {
    path: "opcoes-agendamento",
    loadChildren: OpcoesAgendamento,
    canActivate: [AuthGuard],
  },
  {
    path: "opcoes-agendamento/:id",
    loadChildren: OpcoesAgendamento,
    canActivate: [AuthGuard],
  },
  {
    path: "escolha-agendamento",
    loadChildren: EscolhaAgendamento,
    canActivate: [AuthGuard],
  },
  {
    path: "escolha-agendamento/:id",
    loadChildren: EscolhaAgendamento,
    canActivate: [AuthGuard],
  },
  {
    path: "formulario-matriz/:id",
    loadChildren: FormularioMatriz,
    canActivate: [AuthGuard],
  },
  {
    path: "visualizar-pedido/:id",
    loadChildren: VisualizarPedido,
    canActivate: [AuthGuard],
  },
  {
    path: "gestao-usuario",
    loadChildren: GestaoUsuario,
    canActivate: [AuthGuard],
  },
  {
    path: "consultar-tickets",
    loadChildren: ConsultarTickets,
    canActivate: [AuthGuard],
  },
  {
    path: "relatorio-tickets",
    loadChildren: RelatorioTickets,
    canActivate: [AuthGuard],
  },
  {
    path: "projeto-e-demanda",
    loadChildren: ProjetoEDemanda,
    canActivate: [AuthGuard],
  },
  {
    path: "auditoria",
    loadChildren: Auditoria,
    canActivate: [AuthGuard],
  },
  {
    path: "suporte-portais-sistemas",
    loadChildren: SuportePortaisSistemas,
    canActivate: [AuthGuard],
  },
  {
    path: "consulta-juridica",
    loadChildren: ConsultaJuridica,
    canActivate: [AuthGuard],
  },

  // { path: "insumos", loadChildren: Insumos, canActivate: [AuthGuard] },
  {
    path: "insumos",
    canActivate: [AuthGuard],
    children: [
      { path: "criar", component: CriarComponent },
      { path: "meus", component: VisualizerMeusComponent },
      { path: "consultar", component: ConsultarInsumosComponent },
      { path: "fields-configure", component: InsumoFieldConfigComponent },
      { path: "visualizar/:id", component: InsumoVisualizerComponent },
    ],
  },

  {
    path: "arquivos",
    canActivate: [AuthGuard],
    children: [
      { path: "criar-arquivo", component: CriarArquivoComponent },
      { path: "editar-arquivo/:id", component: EditarArquivoComponent },
      { path: "parametros", component: ParametrosComponent },
      { path: "visualizar/:id", component: VisualizarArquivoComponent },
      { path: "visualizar-minha", component: VisualizerMinhaComponent },
      { path: "", component: ListarArquivosComponent },
    ],
  },

  {
    path: "dashboards",
    canActivate: [AuthGuard],
    children: [
      { path: "criar-dashboard", component: CriarDashBoardComponent },
      { path: "criar-categoria", component: CriarCategoriaDashboardComponent },
      { path: "editar-dashboard/:id", component: EditarDashBoardComponent },
      // { path: "listar-dashbaord", component: ListarDashBoardsComponent },
      { path: "", component: ListarCategoriasDashboardComponent },
      { path: "categorias", component: ListarCategoriasDashboardComponent },
      { path: "categorias/:id", component: ListarDashBoardsComponent },
    ],
  },

  // juan's screens
  {
    path: "comissao-contestacao",
    loadChildren: ComissaoContestacao,
    canActivate: [AuthGuard],
  },
  {
    path: "comissionamento",
    loadChildren: Comissionamento,
    canActivate: [AuthGuard],
  },
  {
    path: "solicitacoes-internas",
    loadChildren: SolicitacoesInternas,
    canActivate: [AuthGuard],
  },
  {
    path: "comissao-contestacao",
    loadChildren: ComissaoContestacao,
    canActivate: [AuthGuard],
  },
  { path: "meus-tickets", loadChildren: MeusTickets, canActivate: [AuthGuard] },
  {
    path: "atender-tickets",
    loadChildren: AtenderTickets,
    canActivate: [AuthGuard],
  },
  {
    path: "atender-tickets-operacao-vivo",
    loadChildren: AtenderTicketsOperacaoVivo,
    canActivate: [AuthGuard],
  },
  {
    path: "solicitacao-de-acesso",
    loadChildren: SolicitacaoAcesso,
    canActivate: [AuthGuard],
  },

  // otherwise redirect to home
  { path: "**", redirectTo: "" },
];

// NEW NGMODULE
@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
