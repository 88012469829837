<p class="text-muted mt-4 mb-1">
    <span class="text-primary">Portal de Comissionamento.</span>
</p>

<div class="d-flex align-items-center">
    <h1 class="mt-0 mb-0 text-muted">
        Escolha da data de agendamento
    </h1>
    <button class="btn btn-primary float-right ml-auto" routerLink="/monitoramento">
        <mat-icon>arrow_back</mat-icon>
    </button>
</div>

<div class="mt-5 mb-3">


    <mat-card>
        <mat-card-header class="mt-3">
            <mat-card-title class="d-flex text-center text-muted">
                Escolha das datas disponíveis
                <mat-icon class="ml-4">event</mat-icon>
            </mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <form [formGroup]="formEscolhaAgendamento">
                <div class="modal-body">
                    <h3 class="text-primary">Datas e horarios disponiveis</h3>

                    <div class="w-100">
                        <mat-form-field appearance="fill" style="width: 100% !important;">
                            <mat-label> Datas disponiveis para escolha:</mat-label>
                            <mat-select formControlName="dataEscolhida">

                                <mat-option *ngFor="let datas of datasDisponiveis" [value]="datas.opcaoDeData">
                                    {{datas.opcaoDeData | date: 'dd/MM/yyyy HH:mm:ss'}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <!-- <ul class="mt-1 overflow-auto" style="max-height: 20rem !important;">
            <li   class="borda-horario">
                <mat-radio-button type="radio" class="mt-2 ml-2" color="warn"   ></mat-radio-button>
            </li>
        </ul> -->
                    <div class="modal-footer">
                        <button class="btn btn-primary ml-3 mb-3" (click)="enviarDataEscolhida()">Enviar</button>
                    </div>

                </div>
            </form>

        </mat-card-content>
    </mat-card>
</div>