<p class="text-muted mt-4 mb-1">
  <span class="text-primary">Portal de Comissionamento</span>
</p>

<h1 class="text-muted">Consultar Insumos</h1>

<form [formGroup]="formFiltro">
  <div class="row mb-3">
    <div class="col-sm-4">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Nome do Insumo: </mat-label>
        <input type="text" matInput formControlName="insumoName" />
      </mat-form-field>
    </div>

    <div class="col-sm-4">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Email do Responsável: </mat-label>
        <input
          type="text"
          matInput
          autocomplete="new-responsavel"
          aria-label="Email do(a) Responsável"
          [matAutocomplete]="autoFiltro"
          [formControl]="searchUserCtrlFiltro"
          autocomplete="off"
          (click)="resetField(3)"
        />
        <mat-autocomplete #autoFiltro="matAutocomplete">
          <mat-option *ngIf="isLoading" class="is-loading">
            Carregando...
          </mat-option>
          <ng-container *ngIf="!isLoading">
            <mat-option
              *ngFor="let user of filteredUsersFilter"
              [value]="user.email"
              (click)="setEmailResponsavel(user)"
            >
              {{ user.email }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="col-sm-4">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Status da Competência: </mat-label>
        <mat-select formControlName="competenceStatus">
          <mat-option value="VIGENTE">Vigente</mat-option>
          <mat-option value="ENCERRADO">Encerrado</mat-option>
          <mat-option value="TODOS">Todos</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-sm-3">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Segmento:</mat-label>
        <mat-select formControlName="segment">
          <mat-option
            *ngFor="let segment of insumoSegmentList"
            [value]="segment"
          >
            {{ segment }}
          </mat-option>
          <mat-option value="TODOS">Todos</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-sm-3">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Status: </mat-label>
        <mat-select formControlName="status">
          <mat-option value="ATIVO">Ativo</mat-option>
          <mat-option value="INATIVO">Inativo</mat-option>
          <mat-option value="TODOS">Todos</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-sm-3">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Competência: </mat-label>
        <input
          type="text"
          matInput
          formControlName="competence"
          placeholder="MM/YYYY"
          mask="00/0000"
          [dropSpecialCharacters]="false"
        />
      </mat-form-field>
    </div>
    <div class="col-sm-3">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Consulta por Id: </mat-label>
        <input type="text" matInput formControlName="insumoId" />
      </mat-form-field>
    </div>
    <div class="col-sm-12">
      <button
        class="btn btn-sm btn-primary"
        (click)="filter(); this.paginator.firstPage()"
      >
        <div class="button-flex">
          <mat-icon>filter_alt</mat-icon>
          Filtrar
        </div>
      </button>
      <button class="btn btn-secondary btn-sm ml-2" (click)="onReset()">
        <div class="button-flex">
          <mat-icon>backspace</mat-icon>
          Limpar
        </div>
      </button>
      <button
        type="button"
        class="btn btn-rose btn-sm ml-2"
        data-toggle="modal"
        data-target="#export"
      >
        <div class="button-flex">
          <mat-icon>file_download</mat-icon>
          Exportar
        </div>
      </button>
    </div>

    <div class="col-sm-12 mt-4 mb-0">
      <button
        [hidden]="editPermission()"
        [disabled]="filterParams.status === 'INATIVO'"
        matTooltip="Encerrar"
        data-toggle="modal"
        data-target="#encerrar"
        type="button"
        class="btn btn-rose btn-sm ml-0"
      >
        <div class="button-flex">
          <mat-icon>file_download</mat-icon>
          Encerrar Insumos Selecionados
        </div>
      </button>
      <button
        #encerrados
        data-toggle="modal"
        data-target="#encerrados"
        type="button"
        class="btn btn-rose btn-sm ml-0"
        style="display: none"
      ></button>

      <button
        #encerradosNada
        data-toggle="modal"
        data-target="#encerradosnada"
        type="button"
        class="btn btn-rose btn-sm ml-0"
        style="display: none"
      ></button>
    </div>
  </div>
</form>

<div class="row mb-5">
  <div class="col-sm-12">
    <div class="visualization-mode_container">
      <p>Insumos</p>
      <mat-button-toggle-group
        [value]="visualizationMode"
        name="favoriteColor"
        aria-label="Favorite Color"
        (change)="changeVisualizationMode($event)"
      >
        <mat-button-toggle value="cards" class="text-muted"
          ><mat-icon> grid_view </mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="list" class="text-muted">
          <mat-icon> view_list </mat-icon></mat-button-toggle
        >
      </mat-button-toggle-group>
    </div>
    <table
      id="matSort"
      mat-table
      [dataSource]="dataSource"
      matSort
      matSortActive="id"
      matSortDirection="desc"
      matSortDisableClear
      class="mat-elevation-z8"
      [hidden]="visualizationMode === 'cards'"
    >
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef class="select">
          <mat-checkbox
            color="primary"
            (change)="$event ? toggleAllRows() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row" class="select">
          <mat-checkbox
            color="primary"
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
            [aria-label]="checkboxLabel(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="id">
        <th class="id" mat-header-cell *matHeaderCellDef>Id.</th>
        <td class="id" mat-cell *matCellDef="let element">
          {{ element.id }}
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th class="name" mat-header-cell *matHeaderCellDef>Nome Insumo</th>
        <td class="name" mat-cell *matCellDef="let element">
          {{ element.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="publico">
        <th class="publico" mat-header-cell *matHeaderCellDef>Público</th>
        <td class="publico" mat-cell *matCellDef="let element">
          {{ element.publico | cap }}
        </td>
      </ng-container>

      <ng-container matColumnDef="segment">
        <th class="segment" mat-header-cell *matHeaderCellDef>Segmento</th>
        <td class="segment" mat-cell *matCellDef="let element">
          {{ element.segment }}
        </td>
      </ng-container>

      <ng-container matColumnDef="competence">
        <th class="competence" mat-header-cell *matHeaderCellDef>
          Competencia
        </th>
        <td class="competence" mat-cell *matCellDef="let element">
          {{ element.competence | competenceDate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="deadline">
        <th class="deadline" mat-header-cell *matHeaderCellDef>Data Limite</th>
        <td class="deadline" mat-cell *matCellDef="let element">
          {{ element.deadline }}
        </td>
      </ng-container>

      <ng-container matColumnDef="dataDeUpload">
        <th mat-header-cell *matHeaderCellDef>Data de entrega</th>
        <td mat-cell *matCellDef="let element">
          {{ element | deliveryDateInsumo }}
        </td>
      </ng-container>

      <ng-container matColumnDef="responsavel">
        <th mat-header-cell *matHeaderCellDef>Responsável</th>
        <td mat-cell *matCellDef="let element">
          {{ element.responsibleUploadFullName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="version">
        <th class="version" mat-header-cell *matHeaderCellDef>Versão</th>
        <td class="version" mat-cell *matCellDef="let element">
          {{ element.attachments.length }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th class="actions" mat-header-cell *matHeaderCellDef>Ações</th>
        <td class="actions" mat-cell *matCellDef="let element">
          <div class="button">
            <button
              matSuffix
              data-toggle="modal"
              data-target="#historico"
              class="btn btn-sm btn-dark"
              (click)="expandedElement = element; populateInsumo(element)"
            >
              <mat-icon>history</mat-icon>
            </button>

            <button
              [hidden]="editPermission()"
              matTooltip="Download"
              [disabled]="element.status === 'INATIVO'"
              (click)="
                expandedElement = element; downloadFileInsumoContent(element)
              "
              class="btn btn-sm btn-warning"
            >
              <mat-icon>download</mat-icon>
            </button>

            <button
              matSuffix
              matTooltip="Visualizar"
              routerLink="/insumos/visualizar/{{ element.id }}"
              class="btn btn-sm btn-primary"
            >
              <mat-icon>visibility</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let element; columns: columnsToDisplay"></tr>
    </table>

    <div *ngIf="visualizationMode === 'cards'">
      <div class="col-sm-12">
        <div class="row">
          <div
            class="p-2 col-sm-12 col-md-6 col-lg-4"
            *ngFor="let element of insumoList"
          >
            <div class="card-insumo">
              <div class="card-insumo_header">
                <span>{{ element.competence | competenceDate }}</span>
                <div>
                  <button
                    [hidden]="editPermission()"
                    matTooltip="Download"
                    [disabled]="element.status === 'INATIVO'"
                    (click)="
                      expandedElement = element;
                      downloadFileInsumoContent(element)
                    "
                    class="btn btn-sm btn-primary"
                  >
                    <mat-icon>download</mat-icon>
                  </button>
                  <button
                    matSuffix
                    data-toggle="modal"
                    data-target="#historico"
                    class="btn btn-sm btn-warning"
                    (click)="expandedElement = element; populateInsumo(element)"
                  >
                    <mat-icon>history</mat-icon>
                  </button>
                </div>
              </div>

              <div class="card-insumo_body">
                <span class="text-muted text-small"
                  >{{ element.publico }} | {{ element.segment }}</span
                >
                <span>{{ element.name }}</span>
                <span class="text-muted text-small">{{
                  element.responsibleUploadFullName
                }}</span>
              </div>

              <div class="card-insumo_tags">
                <div>
                  <span class="text-muted text-small">Data Limite</span>
                  <span class="text-small badge bg-danger text-white">{{
                    element.deadline
                  }}</span>
                </div>

                <div *ngIf="element | deliveryDateInsumo">
                  <span class="text-muted text-small">Data da Entrega</span>
                  <span class="text-small badge bg-success text-white">{{
                    element | deliveryDateInsumo
                  }}</span>
                </div>
                <div>
                  <span class="text-muted text-small">Versão</span>
                  <span class="text-small badge bg-info">{{
                    element.version
                  }}</span>
                </div>
              </div>
              <div class="card-insumo_footer">
                <a
                  routerLink="/insumos/visualizar/{{ element.id }}"
                  class="anchor"
                >
                  Ver detalhes <mat-icon>arrow_right</mat-icon>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <mat-paginator
      [length]="dataSourceLength"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
      showFirstLastButtons
      aria-label="Select page of periodic elements"
    >
    </mat-paginator>
  </div>
</div>

<div class="modal fade" id="confirmDoc">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title mb-0 text-muted" id="TituloModalCentralizado">
          Formulário de envio de Insumo
        </p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Fechar"
          #closeConfirmDoc
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]="anexoInsumo" [hidden]="actualInsumoLength <= 0">
        <div class="modal-body">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Justificativa:</mat-label>
            <input
              matInput
              type="text"
              formControlName="justify"
              maxlength="300"
              required
            />
          </mat-form-field>
        </div>
      </form>

      <mat-card-content>
        <div class="row">
          <div class="col-lg-6" style="align-self: left">
            <ul>
              <li
                *ngFor="let anexo of attachments"
                style="
                  line-height: 16px;
                  padding: 20px 10px;
                  white-space: nowrap;
                  overflow: hidden;
                  height: 60px;
                "
              >
                {{ anexo.originalFileName }}
              </li>
            </ul>
          </div>
          <div class="col-lg-3" style="align-self: right">
            <ul>
              <li
                *ngFor="let arquivo of files"
                style="padding: 20px 10px; line-height: 16px; height: 60px"
              >
                {{ arquivo.progress }}
              </li>
            </ul>
          </div>
          <div class="col-lg-3" style="align-self: right">
            <ul>
              <li
                *ngFor="let anexo of attachments"
                style="padding: 13px 10px; height: 60px"
              >
                <span
                  class="btn btn-sm mr-2 btn-danger"
                  (click)="removeAnexoFormPreUpload(anexo)"
                >
                  <mat-icon>close</mat-icon>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-12" style="align-self: center">
          <ul>
            <li *ngIf="files.length > 0" style="margin: 30xp 0">
              <mat-progress-bar [value]="somArray(files)"></mat-progress-bar>
              <span id="file-label"></span>
            </li>
          </ul>
        </div>
      </mat-card-content>

      <div class="d-flex flex-column">
        <div class="mt-4 pb-4 pl-3 pr-3">
          <div class="form-group">
            <div class="input-group">
              <div class="custom-file">
                <input
                  type="file"
                  #fileUpload
                  id="fileUpload"
                  name="fileUpload"
                  multiple="multiple"
                  accept="image/jpeg,image/gif,image/png,image/x-eps"
                  class="custom-file-input"
                  (click)="onUploadButtonClick()"
                />
                <label class="custom-file-label" for="anexarDocumento">{{
                  attachments[0]?.originalFileName == null
                    ? "Anexar arquivo"
                    : attachments[0]?.originalFileName
                }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button
          type="submit"
          (click)="onSubmit()"
          class="mb-2 btn btn-success text-white mr-2 button-flex-table"
          data-toggle="modal"
          data-target="#confirmUploadModal"
          data-dismiss="modal"
        >
          Enviar
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="confirmUploadModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title mb-0" id="TituloModalCentralizado">
          Upload de Insumo
        </p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Fechar"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="mt-4 pb-4">
          <h3 class="text-center text-muted mt-4">
            Upload de insumo realizado com sucesso!
          </h3>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-dark btn-sm" data-dismiss="modal">
          Fechar
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="excluir">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title mb-0" id="TituloModalCentralizado">
          Encerrar Insumo
        </p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Fechar"
          #closeModalExcluir
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Deseja Realmente Encerrar o Insumo?</p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn-sm btn btn-dark mr-2 btn-mat-icon"
          data-dismiss="modal"
        >
          <mat-icon class="">close</mat-icon>
          Cancelar
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="update">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title mb-0" id="TituloModalCentralizado">
          Atualizar Responsavel
        </p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Fechar"
          #closeModalAlterarResponsavel
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]="formUpdate" (ngSubmit)="onSubmitUpdate()">
        <div class="modal-body">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Email Responsável do upload do insumo: </mat-label>
            <input
              type="text"
              matInput
              autocomplete="new-responsavel"
              aria-label="Email do(a) Responsável"
              [matAutocomplete]="auto1"
              [formControl]="searchUserCtrl1"
              required
              autocomplete="off"
              (click)="resetField(1)"
            />
            <mat-autocomplete #auto1="matAutocomplete">
              <mat-option *ngIf="isLoading" class="is-loading">
                Carregando...
              </mat-option>
              <ng-container *ngIf="!isLoading">
                <mat-option
                  *ngFor="let user of filteredUsers1"
                  [value]="user.email"
                  (click)="setEmailResponsibleUpload(user)"
                >
                  <span>{{ user.email }}</span>
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Email Responsável pela análise do insumo: </mat-label>
            <input
              type="text"
              matInput
              autocomplete="new-responsavel"
              aria-label="Email do(a) Responsável"
              [matAutocomplete]="auto2"
              [formControl]="searchUserCtrl2"
              required
              autocomplete="off"
              (click)="resetField(2)"
            />
            <mat-autocomplete #auto2="matAutocomplete">
              <mat-option *ngIf="isLoading" class="is-loading">
                Carregando...
              </mat-option>
              <ng-container *ngIf="!isLoading">
                <mat-option
                  *ngFor="let user of filteredUsers2"
                  [value]="user.email"
                  (click)="setEmailAnalysisUpload(user)"
                >
                  <span>{{ user.email }}</span>
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Justificativa:</mat-label>
            <input
              matInput
              type="text"
              formControlName="justify"
              maxlength="300"
              required
            />
          </mat-form-field>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn-sm btn btn-dark mr-2 btn-mat-icon"
            data-dismiss="modal"
          >
            <mat-icon class="">close</mat-icon>
            Cancelar
          </button>
          <button
            type="submit"
            class="btn-sm btn btn-success btn-mat-icon"
            [disabled]="formUpdate.invalid"
          >
            <mat-icon class="">done</mat-icon>
            Atualizar
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="modal fade" id="dataLimite">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title mb-0" id="TituloModalCentralizado">
          Atualizar Data Limite
        </p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Fechar"
          #closeModalAlterarDeadLine
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]="formDeadLine" (ngSubmit)="onSubmitDeadLine()">
        <div class="modal-body">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Data Limite:</mat-label>
            <input
              matInput
              formControlName="deadline"
              [matDatepicker]="pickerDataLimite"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="pickerDataLimite"
            ></mat-datepicker-toggle>
            <mat-datepicker #pickerDataLimite></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="modal-body">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Justificativa:</mat-label>
            <input
              matInput
              type="text"
              formControlName="justify"
              maxlength="300"
              required
            />
          </mat-form-field>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn-sm btn btn-dark mr-2 btn-mat-icon"
            data-dismiss="modal"
          >
            <mat-icon class="">close</mat-icon>
            Cancelar
          </button>
          <button type="submit" class="btn-sm btn btn-success btn-mat-icon">
            <mat-icon class="">done</mat-icon>
            Atualizar
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="historico"
  tabindex="-1"
  role="dialog"
  aria-labelledby="historico"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title m-0">Histórico</h3>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-12">
            <section id="cd-timeline" *ngIf="insumo.history" class="">
              <div
                class="cd-timeline-block"
                *ngFor="let element of insumo.history.reverse()"
              >
                <div class="cd-timeline-img cd-picture"></div>
                <div class="cd-timeline-content bg-white">
                  <div>
                    <p class="text-primary mb-0 text-small">
                      <strong>Ação:</strong>
                    </p>
                    <h3 class="text-muted mb-0">
                      {{ element.actionEnum | cap }}
                    </h3>
                  </div>

                  <hr class="mt-2 mb-3" />

                  <div>
                    <p class="text-primary text-small mt-2 mb-0">
                      Responsável:
                    </p>
                    <h3 class="text-muted mb-0 mt-0">
                      {{ element.actionUserFullName }}
                    </h3>
                    <p class="text-muted mt-0">
                      {{ element.actionUserEmail }}
                    </p>
                    <div>
                      <p class="text-primary text-small mt-2 mb-0">
                        Data da Ação:
                      </p>
                      <h4 class="text-muted mb-0 mt-0">
                        {{ element.changedDate }}
                      </h4>
                    </div>
                  </div>

                  <div *ngIf="element.justify">
                    <hr class="mt-2 mb-3" />

                    <p class="text-primary text-small mt-2 mb-0">
                      Justificativa:
                    </p>
                    <p class="text-muted mt-0">
                      {{ element.justify }}
                    </p>
                  </div>

                  <hr class="mt-2 mb-2" />

                  <div class="col-sm-12">
                    <div class="row">
                      <div *ngIf="element.currentStatus" class="mr-3">
                        <p class="text-primary text-small mb-0">Status:</p>
                        <p class="m-0">
                          <label
                            [ngClass]="{
                              vigente: element.currentStatus === 'VIGENTE',
                              inativo: element.currentStatus === 'INATIVO',
                              encerrado: element.currentStatus === 'ENCERRADO'
                            }"
                          >
                            {{ element.currentStatus | cap }}
                          </label>
                        </p>
                      </div>
                      <div *ngIf="element.currentStatus !== element.newStatus">
                        <p class="text-muted mt-2 mb-0">Novo Status:</p>
                        <p class="m-0">
                          <label>
                            {{ element.newStatus | cap }}
                          </label>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-dark btn-sm" data-dismiss="modal">
          Fechar
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="encerrar">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title mb-0" id="TituloModalCentralizado">
          Encerrar Insumos
        </p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Fechar"
          #closeModalEncerrar
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Deseja Realmente Encerrar esses Insumos?</p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn-sm btn btn-dark mr-2 btn-mat-icon"
          data-dismiss="modal"
        >
          <mat-icon class="">close</mat-icon>
          Cancelar
        </button>
        <button
          type="submit"
          class="btn-sm btn btn-success btn-mat-icon"
          (click)="encerrarInsumos()"
        >
          <mat-icon class="">done</mat-icon>
          Sim
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="encerradosnada">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title mb-0" id="TituloModalCentralizado">AVISO!!</p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Fechar"
          #closeModalEncerradosAlert
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Nenhum Insumo Selecionado!</p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn-sm btn btn-dark mr-2 btn-mat-icon"
          data-dismiss="modal"
        >
          <mat-icon class="">close</mat-icon>
          Fechar
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="version">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title mb-0" id="TituloModalCentralizado">
          Mudança de Versão de Anexo de Insumo
        </p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Fechar"
          #closeModalVersion
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="formChangeVersion">
          <div class="modal-body">
            <mat-form-field appearance="fill" class="w-100" required>
              <mat-label> Versões Disponíveis: </mat-label>
              <mat-select formControlName="version">
                <mat-option value="None" disabled="true">
                  Selecione Alguma Versão...
                </mat-option>
                <mat-option
                  *ngFor="let version of insumoVersionList"
                  [value]="version"
                  >{{ version }}</mat-option
                >
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Justificativa:</mat-label>
              <input
                matInput
                type="text"
                formControlName="justificativa"
                maxlength="300"
                required
              />
            </mat-form-field>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn-sm btn btn-dark mr-2 btn-mat-icon"
          data-dismiss="modal"
        >
          <mat-icon class="">close</mat-icon>
          Fechar
        </button>
        <button
          type="submit"
          class="btn btn-success btn-sm button-flex"
          (click)="changeVersion()"
        >
          <mat-icon>task_alt</mat-icon>
          Enviar
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="export"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3
          class="modal-title m-0"
          id="exampleModalLongTitle"
          *ngIf="!isDownloadProcess"
        >
          Selecione o Tipo de Relatório
        </h3>

        <h3
          class="modal-title m-0"
          id="exampleModalLongTitle"
          *ngIf="isDownloadProcess"
        >
          Gerando Relatório...
        </h3>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          #exportModal
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div
        class="modal-body d-flex justify-content-center align-items-center"
        *ngIf="isDownloadProcess"
      >
        <div class="loading-shade">
          <mat-spinner></mat-spinner>
        </div>
      </div>

      <div class="modal-body" *ngIf="!isDownloadProcess">
        <div class="d-flex justify-content-center align-items-center">
          <button
            type="button"
            class="btn btn-rose btn-sm mr-2 mb-2"
            (click)="exportToExcel('sm')"
            data-dismiss="modal"
          >
            <div class="button-flex">
              <mat-icon>download</mat-icon>
              Resumido
            </div>
          </button>
          <button
            type="button"
            class="btn btn-rose btn-sm mr-2 mb-2"
            (click)="exportToExcel('lg')"
            data-dismiss="modal"
          >
            <div class="button-flex">
              <mat-icon>download</mat-icon>
              Detalhado
            </div>
          </button>
        </div>
      </div>
      <div class="modal-footer" *ngIf="!isDownloadProcess">
        <button type="button" class="btn btn-dark btn-sm" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
