import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { finalize } from "rxjs/operators";

import { LoadingService } from "../_services/loading.service";

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private loadingService: LoadingService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    if (
      request.url.includes("insumo/search") ||
      request.url.includes("user/search") ||
      request.url.includes("ticket/search/filtered")
    ) {
      this.loadingService.show();
    }
    return next
      .handle(request)
      .pipe(finalize(() => this.loadingService.hide()));
  }
}
