<!-- <apx-chart [series]="series" [chart]="chart" [title]="title"></apx-chart> -->
<!-- bg-white  -->
<div id="chart" class="mt-4 pb-4">
  <p class="text-center pt-4">Tickets para Atender</p>
  <apx-chart
    [series]="chartOptions.series"
    [stroke]="chartOptions.stroke"
    [fill]="chartOptions.fill"
    [dataLabels]="chartOptions.dataLabels"
    [legend]="chartOptions.legend"
    [chart]="chartOptions.chart"
    [labels]="chartOptions.labels"
    [title]="chartOptions.title"
    [theme]="chartOptions.theme"
    [responsive]="chartOptions.responsive"
    [plotOptions]="chartOptions.plotOptions"
    [tooltip]="chartOptions.tooltip"
    [colors]="chartOptions.colors"
  ></apx-chart>
</div>
