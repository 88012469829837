import { Component, OnInit, ViewChild } from "@angular/core";
import {
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexLegend,
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexStroke,
  ApexTheme,
  ApexTitleSubtitle,
  ChartComponent,
} from "ng-apexcharts";

import { AccountService } from "../../_services";
import { TicketService } from "../../_services/ticket.service";
import { User } from "./../../_models/user";
import { ChartOptions } from "../../_models/tickets";
import { chartPartialOptions } from "../../_helpers/chartPartialOptions";

@Component({
  selector: "app-seus-tickets",
  templateUrl: "./seus-tickets.component.html",
  styleUrls: ["./seus-tickets.component.css"],
})
export class SeusTicketsComponent implements OnInit {
  user: User;
  chartOptions: Partial<ChartOptions>;
  @ViewChild("chart") chart: ChartComponent;

  constructor(
    private accountService: AccountService,
    private ticketService: TicketService
  ) {
    this.user = this.accountService.userValue.user;

    this.chartOptions = {
      ...chartPartialOptions,
    };
  }

  ngOnInit(): void {
    this.getAllMyTickets();
  }

  getAllMyTickets() {
    this.ticketService
      .getAllTicketChartByUserId(this.user.id)
      .subscribe(
        ({
          cancelado,
          concluido,
          devolvido,
          emAberto,
          emAndamento,
          reaberto,
        }) => {
          this.chartOptions.series = [
            emAberto,
            emAndamento,
            concluido,
            cancelado,
            devolvido,
            reaberto,
          ];
        }
      );
  }
}
