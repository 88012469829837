import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: "convertDate",
})
export class ConvertDatePipe implements PipeTransform {
  transform(date: number): string {
    function formatZero(numero) {
      return numero <= 9 ? "0" + numero : numero;
    }

    let timeStamp_date = new Date(date);
    let year = timeStamp_date.getFullYear();
    let month = formatZero(timeStamp_date.getMonth() + 1);
    let day = formatZero(timeStamp_date.getDate());
    let hour = formatZero(timeStamp_date.getHours());
    let min = formatZero(timeStamp_date.getMinutes());

    return `${day}/${month}/${year} - ${hour}:${min}`;
  }
}
