import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { CaptalizePipe } from "./captalize.pipe";
import { ChangeStatusPipe } from "./change-status.pipe";
import { CompetenceDatePipe } from "./competence-date.pipe";
import { ConvertDatePipe } from "./convert-date.pipe";
import { DeliveryDatePipe } from "./deliveryDate.pipe";
import { DetailsTicketReturnPipe } from "./details-ticket-return.pipe";
import { DisclosureDatePipe } from "./disclosureDate.pipe";
import { FullNamePipe } from "./full-name.pipe";
import { LastAttendantPipe } from "./lastAttendant.pipe";
import { NumbersOnlyDirective } from "./numbers-only.directive";
import { SegmentTicketReturnPipe } from "./segment-ticket-return.pipe";
import { DeliveryDateInsumoPipe } from "./deliveryDateInsumo.pipe";

@NgModule({
  declarations: [
    CaptalizePipe,
    ConvertDatePipe,
    CompetenceDatePipe,
    ChangeStatusPipe,
    LastAttendantPipe,
    DetailsTicketReturnPipe,
    SegmentTicketReturnPipe,
    DisclosureDatePipe,
    DeliveryDatePipe,
    DeliveryDateInsumoPipe,
    NumbersOnlyDirective,
    FullNamePipe,
  ],
  imports: [CommonModule],
  exports: [
    CaptalizePipe,
    ConvertDatePipe,
    CompetenceDatePipe,
    ChangeStatusPipe,
    LastAttendantPipe,
    DetailsTicketReturnPipe,
    SegmentTicketReturnPipe,
    DisclosureDatePipe,
    DeliveryDatePipe,
    DeliveryDateInsumoPipe,
    NumbersOnlyDirective,
    FullNamePipe,
  ],
})
export class ApplicationPipesModuleModule {}
