import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-atender-tickets',
  templateUrl: './atender-tickets.component.html',
  styleUrls: ['./atender-tickets.component.css']
})
export class AtenderTicketsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
