<div class="row">
    <div class="col-lg-8">
        <h1 class="mt-4 text-primary">Área criação de Conteúdos</h1>
    </div>

    <div class="col-lg-4 text-right">
        <div class="mt-4 conteudo-flex-button">
            <button class="btn btn-sm btn-primary order-2">
                <p>Publicar agora</p>
            </button>
            <button class="btn btn-sm btn-outline-success order-1 mr-1">
                <p>Precisando de ajuda?</p>
            </button>
        </div>
    </div>
</div>

<!-- <angular-editor [placeholder]="'Enter text here...'" [(ngModel)]="htmlContent"></angular-editor> -->
<!-- <angular-editor formControlName="htmlContent" [config]="editorConfig"></angular-editor> -->
<!-- <angular-editor id="editor1" formControlName="htmlContent1" [config]="editorConfig"></angular-editor>
<angular-editor id="editor2" formControlName="htmlContent2" [config]="editorConfig"></angular-editor> -->

<div class="row">
    <div class="col-lg-8">

        <mat-form-field class="example-full-width">
            <mat-label>Título do Conteúdo</mat-label>
            <input type="text" matInput placeholder="">
            <!-- <mat-icon matSuffix>mood</mat-icon> -->
        </mat-form-field>
        <!-- [config]="config" -->
        <!-- <angular-editor [(ngModel)]="htmlContent"></angular-editor> -->
        <angular-editor [(ngModel)]="htmlContent" [config]="config" ></angular-editor>
        <br>
        <h5 class="html-header">HTML Output</h5>
        <div class="html">
            {{ htmlContent }}
        </div>
    </div>
    <div class="col-lg-4">
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Publicação
                    </mat-panel-title>
                    <!-- <mat-panel-description>
                        Publicação
                    </mat-panel-description> -->
                </mat-expansion-panel-header>

                <div class="row">
                    <div class="col-lg-6">
                        <div class="conteudo-flex">
                            <mat-icon color="link" matListIcon>traffic</mat-icon>
                            <p>Status: </p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="conteudo-flex">
                            <p class="text-success"> Online </p>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-lg-6">
                        <div class="conteudo-flex">
                            <mat-icon color="link" matListIcon>remove_red_eye</mat-icon>
                            <p>Visibilidade: </p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="conteudo-flex">
                            <p class="text-success"> Público </p>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col-lg-6">
                        <button class="btn btn-sm btn-block btn-danger">Mover para lixeira</button>
                    </div>
                    <div class="col-lg-6">
                        <button class="btn btn-sm btn-block btn-primary">Publicar</button>
                    </div>
                </div>

            </mat-expansion-panel>

        </mat-accordion>


        <div class="mt-3">
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Atributos do Conteúdo
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="row">
                        <div class="col-lg-12">

                            <p>Adicionar tags</p>
                            <mat-form-field class="example-chip-list">
                                <mat-chip-list #chipList aria-label="Fruit selection">
                                    <mat-chip *ngFor="let fruit of fruits" [selectable]="selectable"
                                        [removable]="removable" (removed)="remove(fruit)">
                                        {{fruit}}
                                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                    </mat-chip>
                                    <input placeholder="Adicione sua melhor tag para o conteúdo" #fruitInput
                                        [formControl]="fruitCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList"
                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                        (matChipInputTokenEnd)="add($event)">
                                </mat-chip-list>
                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                                    <mat-option *ngFor="let fruit of filteredFruits | async" [value]="fruit">
                                        {{fruit}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>

                        </div>
                    </div>


                    <div class="row">
                        <div class="col-lg-12">

                            <div class="conteudo-flex">
                                <mat-icon color="link" matListIcon>attach_file</mat-icon>
                                <p>Modelo: </p>

                                <div class="ml-2">
                                    <mat-form-field>
                                        <mat-label class="text-primary">Selecione modelo</mat-label>
                                        <mat-select disableRipple>
                                            <mat-option value="1">Modelo 1</mat-option>
                                            <mat-option value="2">Modelo 2</mat-option>
                                            <mat-option value="3">Modelo 3</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="row">
                        <div class="col-lg-12">

                            <div class="conteudo-flex">
                                <mat-icon color="link" matListIcon>attach_file</mat-icon>
                                <p>Área: </p>

                                <div class="ml-2">
                                    <mat-form-field>
                                        <mat-label class="text-primary">Selecione área</mat-label>
                                        <mat-select disableRipple>
                                            <mat-option value="1">Área 1</mat-option>
                                            <mat-option value="2">Área 2</mat-option>
                                            <mat-option value="3">Área 3</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="row">
                        <div class="col-lg-12">

                            <div class="conteudo-flex">
                                <mat-icon color="link" matListIcon>attach_file</mat-icon>
                                <p>Departamento: </p>

                                <div class="ml-2">
                                    <mat-form-field>
                                        <mat-label class="text-primary">Selecione um ou vários</mat-label>
                                        <mat-select [formControl]="departamento" multiple>
                                            <mat-option *ngFor="let departamento of departamentoList" [value]="departamento">
                                                {{departamento}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="row">
                        <div class="col-lg-12">

                            <div class="conteudo-flex">
                                <mat-icon color="link" matListIcon>supervisor_account</mat-icon>
                                <p>Colaboradores: </p>

                                <div class="ml-2">
                                    <mat-form-field>
                                        <mat-label class="text-primary">Selecione um ou vários</mat-label>
                                        <mat-select [formControl]="colaborador" multiple>
                                            <mat-option *ngFor="let colaborador of colaboradorList" [value]="colaborador">
                                                {{colaborador}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                        </div>

                    </div>






                </mat-expansion-panel>

            </mat-accordion>

        </div>


        <div class="mt-3">
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Thumbnaill
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <!-- <div class="row">
                        <div class="col-lg-12">
                            <div class="conteudo-flex">
                                <mat-icon color="link" matListIcon>image_search</mat-icon>
                                <label for="file">Editar</label>
                            </div>
                        </div>
                    </div> -->

                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <input type="file" id="file" (change)="handleFileInput($event.target.files)">
                            </div>
                        </div>
                    </div>

                </mat-expansion-panel>

            </mat-accordion>

        </div>






    </div>
</div>