import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { OpcoesAgendamento } from '../_models/opcoes-agendamento';
import { MapeamentodocumentalService } from '../_services/mapeamentodocumental.service';
import { OpcoesAgendamentoService } from '../_services/opcoes-agendamento.service';

@Component({
    selector: 'app-opcoes-agendamento',
    templateUrl: './opcoes-agendamento.component.html',
    styleUrls: ['./opcoes-agendamento.component.css'],
})
export class OpcoesAgendamentoComponent implements OnInit{

    formDateRange: FormGroup;
    formHorario: FormGroup;
    formDataHorario: FormGroup;
    dataInserir: any[]=[];
    dataHorario: any;
    dateRange: OpcoesAgendamento = new OpcoesAgendamento();
    // opcaoData: any[]=[];
    // opcaoData: any[] = ["8:00", "8:30", "9:00", "9:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", ];
    opcaoDataList: OpcoesAgendamento = new OpcoesAgendamento();
    opcaoDataAgendamento: any[]=[];
    areaHorario: boolean = false;
    id: any;
    mapeamento:any;
    displayedColumns: string[]= ['id', 'dataAgendamento', 'horarioAgendamento', 'acoes'];
    constructor (private formBuilder: FormBuilder, 
      private opcoesAgendamentoService: OpcoesAgendamentoService,
      private route: ActivatedRoute,
      private router: Router,
      private mapeamentodocumentalService: MapeamentodocumentalService){
        
      this.route.queryParams.subscribe(params => {
        var id = this.route.snapshot.paramMap.get('id');
        console.log(id);
    
        if(id){
          this.id = id;
          this.getMapeamentoById(this.id);
        }
          
      });

    }

    getMapeamentoById(id){
      this.mapeamentodocumentalService.getMapeamentoById(id).subscribe(response=>{
  
       this.mapeamento = response;
       console.log(this.mapeamento);
      });
    }

    ngOnInit(){
      this.formDateRange = this.formBuilder.group({
        start: ["", Validators.required],
        end: ["", Validators.required]
      });
      this.formDataHorario = this.formBuilder.group({
        dataInserir: ["", Validators.required],
      });
      
      this.formHorario = this.formBuilder.group({
        dataEscolhida: ["", Validators.required],
        link:["", Validators.required]
      });


    }
    // getAllhorarioDisponivel(){
    //   this.dateRange = this.formDateRange.value;
    //   this.opcoesAgendamentoService.getAllAvailableDate(this.dateRange).subscribe(response => {
    //     this.opcaoData = response;
    //     console.log(this.opcaoData);
    //     this.areaHorario = true;
    //   })

    // }
    // enviarData(){
    //   this.dateRange = this.formDateRange.value;
    //   this.opcoesAgendamentoService.sendDateRange(this.dateRange).subscribe(response =>{
    //     //this.getAllhorario;
    //   })
    // }
    enviarDataList(){
      var definicaoData = this.formHorario.value;
      
      this.opcoesAgendamentoService.saveDefinicaoData(definicaoData, this.id).subscribe(response=>{
        this.router.navigate(['/monitoramento'], { relativeTo: this.route });
      })
    }


    adicionarData(){
      console.log(this.dataInserir)
      this.opcaoDataAgendamento.push(this.dataInserir);
      console.log(this.opcaoDataAgendamento);
    }

}