import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-auditoria",
  templateUrl: "./auditoria.component.html",
  styleUrls: ["./auditoria.component.css"],
})
export class AuditoriaComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
