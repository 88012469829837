<p class="text-muted mt-4 mb-1">
  <span class="text-primary">Portal de Comissionamento</span>
</p>

<h1 class="text-muted">Minha Wiki</h1>
<!-- 
<form [formGroup]="formFiltro">
  <div class="row mb-3">
    <div class="col-sm-4">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Título do Post: </mat-label>
        <input type="text" matInput formControlName="insumoName" />
      </mat-form-field>
    </div>

    <div class="col-sm-4">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>ID do Post: </mat-label>
        <input
          type="text"
          matInput
          autocomplete="new-responsavel"
          aria-label="Email do(a) Responsável"
          [matAutocomplete]="autoFiltro"
          [formControl]="searchUserCtrlFiltro"
          autocomplete="off"
          (click)="resetField(3)"
        />
        <mat-autocomplete #autoFiltro="matAutocomplete">
          <mat-option *ngIf="isLoading" class="is-loading">
            Carregando...
          </mat-option>
          <ng-container *ngIf="!isLoading">
            <mat-option
              *ngFor="let user of filteredUsersFilter"
              [value]="user.email"
              [value]="user.email"
              (click)="setEmailResponsavel(user)"
            >
              {{ user.email }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="col-sm-12">
      <button
        class="btn btn-sm btn-primary"
        (click)="filter(); this.paginator.firstPage()"
      >
        <div class="button-flex">
          <mat-icon>filter_alt</mat-icon>
          Filtrar
        </div>
      </button>
      <button class="btn btn-secondary btn-sm ml-2" (click)="onReset()">
        <div class="button-flex">
          <mat-icon>backspace</mat-icon>
          Limpar
        </div>
      </button>
    </div>
  </div>
</form> -->

<div class="row mb-5">
  <div class="col-sm-12">
    <p>Lista de Posts:</p>

    <table
      id="FormTable"
      mat-table
      [dataSource]="dataSource"
      matSort
      matSortActive="id"
      matSortDirection="desc"
      matSortDisableClear
      class="mat-elevation-z8"
    >
      <ng-container matColumnDef="id">
        <th class="id" mat-header-cell *matHeaderCellDef>Id.</th>
        <td class="id" mat-cell *matCellDef="let element">
          {{ element.id }}
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th class="name" mat-header-cell *matHeaderCellDef>Título</th>
        <td class="name" mat-cell *matCellDef="let element">
          {{ element.title }}
        </td>
      </ng-container>

      <ng-container matColumnDef="publico">
        <th class="publico" mat-header-cell *matHeaderCellDef>Descrição</th>
        <td class="publico" mat-cell *matCellDef="let element">
          {{ element.description }}
        </td>
      </ng-container>

      <ng-container matColumnDef="segment">
        <th class="segment" mat-header-cell *matHeaderCellDef>Categoria</th>
        <td class="segment" mat-cell *matCellDef="let element">
          {{ element.wikiCategory.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="competence">
        <th class="competence" mat-header-cell *matHeaderCellDef>
          Subcategoria
        </th>
        <td class="competence" mat-cell *matCellDef="let element">
          {{ element.wikiSubcategory.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="deadline">
        <th class="deadline" mat-header-cell *matHeaderCellDef>
          Tipo de Arquivo
        </th>
        <td class="deadline" mat-cell *matCellDef="let element">
          {{ element.fileType }}
        </td>
      </ng-container>

      <ng-container matColumnDef="dataDeUpload">
        <th mat-header-cell *matHeaderCellDef>Área Responsável</th>
        <td mat-cell *matCellDef="let element">Projetos e Gestão</td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th class="actions" mat-header-cell *matHeaderCellDef>Ações</th>
        <td class="actions" mat-cell *matCellDef="let element">
          <button
            matTooltip="Editar"
            [routerLink]="['/arquivos/editar-arquivo/', element.id]"
            class="btn btn-sm btn-warning"
          >
            <mat-icon class="material-symbols-outlined"> edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let element; columns: columnsToDisplay"></tr>
    </table>
    <mat-paginator
      [length]="dataSourceLength"
      [pageSize]="5"
      [pageSizeOptions]="[5, 10, 25, 50, 100]"
      showFirstLastButtons
      aria-label="Select page of periodic elements"
    >
    </mat-paginator>
  </div>
</div>
