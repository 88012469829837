import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";

import * as moment from "moment";
import { Moment } from "moment";

export const MY_FORMATS = {
  parse: {
    dateInput: "MMMM/YYYY",
  },
  display: {
    dateInput: "MMMM/YYYY",
    monthYearLabel: "MMMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

interface EventEmitterValue {
  competence: string;
}

@Component({
  selector: "competence-input",
  templateUrl: "./competence-input.component.html",
  styleUrls: ["./competence-input.component.css"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class CompetenceInputComponent implements OnInit {
  @Input() competenceValue?: Moment;
  @Output() competenceValueConverted = new EventEmitter<EventEmitterValue>();

  ngOnInit(): void {
    if (this.competenceValue) {
      this.competenceDate.setValue(this.competenceValue);
    }
  }

  competenceDate = new FormControl();

  setMonthAndYear(
    normalizedMonthAndYear: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue: Moment = this.competenceDate.value || moment();

    ctrlValue.date(1);
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    ctrlValue.hour(0);
    ctrlValue.minute(0);
    ctrlValue.second(0);
    ctrlValue.millisecond(0);

    this.competenceDate.setValue(ctrlValue);
    this.competenceValueConverted.emit({ competence: ctrlValue.toISOString() });
    datepicker.close();
  }
}
