<h1 class="mt-4 text-primary">FAQ</h1>
<!-- <h1 class="mt-2">FAQ</h1> -->

<div class="row">
    <div class="col-4">
        <div class="list-group" id="list-tab" role="tablist">
            <a class="list-group-item list-group-item-action active" id="list-servicos-e-funcionalidades-list"
                data-toggle="list" href="#list-servicos-e-funcionalidades" role="tab" aria-controls="home">Serviços e
                Funcionalidades do Portal </a>

            <a class="list-group-item list-group-item-action" id="list-servicos-e-procedimentos-list" data-toggle="list"
                href="#list-servicos-e-procedimentos" role="tab" aria-controls="profile">Serviços, procedimentos e
                definições de Gestão
                Documental </a>

            <a class="list-group-item list-group-item-action" id="list-ghiche-de-gestao-documental-list"
                data-toggle="list" href="#list-ghiche-de-gestao-documental" role="tab" aria-controls="messages">Guichê
                de Gestão Documental </a>

            <a class="list-group-item list-group-item-action" id="list-digitalizacao-list" data-toggle="list"
                href="#list-digitalizacao" role="tab" aria-controls="settings">Digitalização</a>

            <a class="list-group-item list-group-item-action" id="list-transformacao-digital-list" data-toggle="list"
                href="#list-transformacao-digital" role="tab" aria-controls="settings">Transformação Digital (Paperless)
            </a>

            <a class="list-group-item list-group-item-action" id="list-servicos-cartoriais-list" data-toggle="list"
                href="#list-servicos-cartoriais" role="tab" aria-controls="settings">Serviços Cartoriais </a>

            <a class="list-group-item list-group-item-action" id="list-fornecedores-gestao-documental-list"
                data-toggle="list" href="#list-fornecedores-gestao-documental" role="tab"
                aria-controls="settings">Nossos Fornecedores de Gestão Documental </a>
        </div>
    </div>
    <div class="col-8">
        <div class="tab-content" id="nav-tabContent">
            <!-- Serviços e Funcionalidades do Portal -->
            <div class="tab-pane fade show active" id="list-servicos-e-funcionalidades" role="tabpanel"
                aria-labelledby="list-servicos-e-funcionalidades-list">
                <!-- pergunta 1 -->
                <div class="row">
                    <div class="col-lg-12">
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="mpt">
                                        Quais são os serviços disponíveis?
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <div class="row">
                                    <div class="col-lg-12">
                                        <p>
                                            Os principais serviços disponíveis no portal de Governança Documental são:
                                        </p>
                                        <ul>
                                            <li>
                                                Solicitação de pesquisa de documentos físicos e digitais;
                                            </li>
                                            <li>
                                                Solicitação de insumos e materiais (caixas, etiquetas e lacres);
                                            </li>
                                            <li>
                                                Solicitação de caixas para consulta de documentos físicos;
                                            </li>
                                            <li>
                                                Solicitação de retirada de caixas para descarte e posterior destruição
                                                (fragmentação), segura e sigilosa de documentos de modo ecologicamente
                                                correto;
                                            </li>
                                            <li>
                                                Solicitação de retirada de documentos, no caso de haver uma
                                                desmobilização de imóveis nos estados em que a Telefônica/Vivo opera;
                                            </li>
                                            <li>
                                                Solicitação de pesquisa de documentos físicos e eletrônicos;
                                            </li>
                                            <li>
                                                Solicitação de análise documental in loco;
                                            </li>
                                            <li>
                                                Solicitação de serviços de digitalização de documentos;
                                            </li>
                                            <li>
                                                Solicitação de implementação de assinatura digital de documentos
                                                natodigitais
                                            </li>
                                            <li>
                                                Solicitação de serviços cartoriais digitais;
                                            </li>
                                            <li>
                                                Solicitação de serviços de treinamento e capacitação.
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </mat-expansion-panel>

                        </mat-accordion>
                    </div>
                </div>
                <!-- pergunta 2 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Como solicitar o acesso ao Portal de Governança Documental?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                A solicitação de acesso será realizada via e-mail para o contato:
                                                <strong><a
                                                        href="mailto:Gestao.documental.br@telefonica.com?subject=Olá,%20seja%20bem-vindo!">
                                                        Gestao.documental.br@telefonica.com </a></strong>
                                            </p>
                                            <ul>
                                                <li>
                                                    Assunto do email: Solicitação de Acesso ao Portal de Governança.
                                                </li>
                                            </ul>
                                            <p>
                                                Na sequência você receberá uma planilha para o preenchimento dos dados,
                                                além disso,deve ser enviado a concordância do gestor imediato
                                                autorizando o acesso.
                                            </p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>
                <!-- pergunta 3 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            <!-- Como solicitar um reset da senha do Portal? -->
                                            Como solicitar uma recuperação de senha do Portal?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                Para realizar a solicitação de recuperação de senha, o usuário poderá
                                                utilizar as seguintes opções:
                                            </p>
                                            <ul>
                                                <li>
                                                    Confirmar e-mail ou login para o envio via e-mail de link para a
                                                    criação da nova senha.
                                                </li>
                                                <li>

                                                    Enviar um e-mail para a Caixa: <strong><a
                                                            href="mailto:Gestao.documental.br@telefonica.com?subject=Olá,%20seja%20bem-vindo!">
                                                            Gestao.documental.br@telefonica.com </a></strong>,
                                                    Assunto: Recuperação de Senha, objetivando solicitar o envio de
                                                    senha provisória.
                                                </li>

                                            </ul>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>
                <!-- pergunta 4 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Como solicito um serviço de Gestão Documental?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                O colaborador deverá fazer seu login no Portal de Governança Documental,
                                                em seguida acessar o menu "Solicitações", selecionar o tipo de serviço
                                                solicitado e preencher o respectivo formulário on-line.
                                            </p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>
                <!-- pergunta 5 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Como acompanho as minhas solicitações?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                O acompanhamento das solicitações de serviços e insumos poderão ser
                                                acompanhados na opção "Time Line" do menu de "Solicitações" do Portal de
                                                Governança Documental.
                                            </p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>
                <!-- pergunta 6 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Como recebo o retorno das minhas solicitações?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            O retorno das solicitações realizadas, poderão ser acompanhadas na opção
                                            "TimeLine" no menu de "Solicitações" do Portal de Governança Documental e/ou
                                            por meio da recepção de emails, informando o status atual da respectiva
                                            solicitação de serviços e insumos.
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>
                <!-- pergunta 7 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Onde reporto um problema de funcionalidade?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                Caso possua um problema relacionado a funcionalidade do portal, deverá
                                                ser enviado um e-mail para a caixa <strong>
                                                    <a
                                                        href="mailto:suporte@soluarq.com.br?subject=Olá,%20seja%20bem-vindo!">
                                                        suporte@soluarq.com.br
                                                    </a>
                                                </strong> reportando os
                                                detalhes.


                                            </p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>
                <!-- pergunta 8 -->
                <!-- <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Qual é o SLA dos Serviços?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore illo
                                            reprehenderit officia,
                                            aliquam nisi atque ab consectetur. Id quibusdam, iste possimus, impedit
                                            perferendis,
                                            molestiae
                                            enim quas illo sapiente porro necessitatibus.
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div> -->
                <!-- pergunta 9 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Como realizo uma indicação de uma matéria no portal?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                Para a realização desta possibilidade, temos as seguintes opções:
                                            </p>
                                            <ul>
                                                <li>
                                                    Envio por meio de formulário para preenchimento de indicação da
                                                    matéria, com a opção de enviar o link da matéria ou arquivo.
                                                </li>
                                                <li>
                                                    Enviar um e-mail para a caixa:
                                                    <strong>
                                                        <a
                                                            href="mailto:gestao.documental.br@telefonica.com?subject=Olá,%20seja%20bem-vindo!">
                                                            gestao.documental.br@telefonica.com
                                                        </a>
                                                    </strong>
                                                </li>
                                                <li>
                                                    Assunto: Indicação de Matéria para o portal, incluindo os links,
                                                    conteúdos e/ou anexos.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>
                <!-- pergunta 10 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Como solicito ou realizo um treinamento?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>A solicitação de treinamentos em Gestão Documental, poderá ser realizada
                                                no menu "Solicitações" do Portal de Governança Documental.</p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>
                <!-- pergunta 11 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Não encontrei o que estava procurando?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                Podemos te apoiar a partir de dois canais principais: 
                                            </p>
                                            <ul>
                                                <li>
                                                    <strong>
                                                        <a
                                                            href="mailto:gestao.documental.br@telefonica.com?subject=Olá,%20seja%20bem-vindo!">
                                                            gestao.documental.br@telefonica.com
                                                        </a>
                                                    </strong>
                                                </li>
                                                <li>
                                                    <strong>
                                                        <a
                                                            href="mailto:atendimentopesquisa.vivo@soluarq.com.br?subject=Olá,%20seja%20bem-vindo!">
                                                            atendimentopesquisa.vivo@soluarq.com.br
                                                        </a>
                                                    </strong>
                                                </li>
                                            </ul>
                                            <p>
                                                Deverá preencher um formulário de envio de email no próprio Portal.
                                            </p>
                                            <ul>
                                                <li>
                                                    Assunto: Não encontrei o que estava procurando.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>
                <!-- pergunta 12 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é Timeline?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                Timeline, ou linha do tempo em português no contexto do Portal,
                                                significa a
                                                forma gráfica e linear de representar a sequência em ordem cronológica
                                                das
                                                solicitações realizadas pelo solicitante. É uma ferramenta que apresenta
                                                o
                                                "status das solicitações realizadas", permitindo o seu acompanhamento.
                                            </p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>
                <!-- pergunta 13 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é o guia de acesso rápido?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>É um guia para pesquisas mais utilizadas no Portal, foram definidas
                                                mediante a percepção das informações mais procurar pelos usuários. </p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>
                <!-- pergunta 14 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é o Glossário?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            Um glossário é uma lista alfabética de termos especializados em Governança
                                            Documental com a definição destes termos. O glossário compõe e expressam os
                                            termos utilizados na "Normativa de Gestão Documental da Telefônica/Vivo".
                                            Sua finalidade é auxiliar os colaboradores da companhia na compreensão dos
                                            processos, atividades, procedimentos, métodos e tecnologias aplicadas e
                                            utilizadas no cenário de Governança Documental da Telefônica/Vivo. Você
                                            pode-se acessá-lo na nota de rodapé do Portal.
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>
                <!-- pergunta 15 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Onde estão os Contatos?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            Você pode visualizar os contatos dos atores/colaboradores envolvidos nos
                                            processos e atividades da Telefônica/Vivo na nota de rodapé "Quem somos" do
                                            Portal de Governança Documental da Telefônica/Vivo
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>
                <!-- pergunta 16 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Como solicitar documentos físicos?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>O colaborador deverá fazer seu login no Portal de Governança Documental,
                                                em seguida acessar o menu "Solicitações", selecionar a opção "Pesquisa
                                                de Documentos físicos", preencher e enviar o formulário de solicitação
                                            </p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>
                <!-- pergunta 17 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Como solicitar Documentos Eletrônicos?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                O colaborador deverá fazer seu login no Portal de Governança Documental,
                                                em seguida acessar o menu "Solicitações", selecionar a opção "Pesquisa
                                                de Documentos Eletrônicos", preencher e enviar o formulário de
                                                solicitação.
                                                <!-- O colaborador deverá fazer seu login no Portal de Governança Documental,
                                                em seguida acessar o menu "Solicitações", selecionar a opção "Pesquisa
                                                de Documentos físicos", preencher e enviar o formulário de solicitação -->
                                            </p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>
                <!-- pergunta 18 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Como solicitar insumos (Caixas, Lacres, Etiquetas, Caixas de Descarte
                                            Seguro)?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>O colaborador deverá fazer seu login no Portal de Governança Documental,
                                                em seguida acessar o menu "Solicitações", selecionar a opção "Insumos",
                                                preencher e enviar o formulário de solicitação. </p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>
                <!-- pergunta 19 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Como solicitar uma visita Técnica para Analise Documental?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            Caso você tenha na sua área arquivos em suporte papel e/ou digital que
                                            precisam ser avaliados quanto a sua destinação (organização, digitalização,
                                            descarte e/ou migração de mídia), solicite uma avaliação técnica da equipe
                                            de gestão documental. O colaborador deverá fazer seu login no Portal de
                                            Governança Documental, em seguida acessar o menu "Solicitações", selecionar
                                            a opção "Análise Documental", preencher e enviar o formulário de
                                            solicitação.
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>
                <!-- pergunta 20 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Como solicitar o serviço de Assinatura Digital de Documentos?

                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            O colaborador deverá fazer seu login no Portal de Governança Documental, em
                                            seguida acessar o menu "Solicitações", selecionar a opção "Assinatura
                                            Digital de Documentos", preencher e enviar o formulário de solicitação.
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>
                <!-- pergunta 21 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            <!-- Como solicitar uma Coleta de Caixas de Documentos? -->
                                            Como solicitar uma Coleta de Caixas de Documentos para Guarda?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                O colaborador deverá fazer seu login no Portal de Governança Documental,
                                                em seguida acessar o menu "Solicitações", selecionar a opção "Coleta de
                                                Caixas Para Guarda", preencher e enviar o formulário de solicitação.
                                            </p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>
                <!-- pergunta 22 att-->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Como solicitar uma Retirada de Caixas de Documentos para Descarte?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            O colaborador deverá fazer seu login no Portal de Governança Documental, em
                                            seguida acessar o menu "Solicitações", selecionar a opção "Retirada de
                                            Caixas Para Descarte", preencher e enviar o formulário de solicitação.
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>
                <!-- pergunta 22 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            <!-- Como solicitar uma Retirada de Documentos para Desmobilização? -->
                                            Como solicitar uma Retirada de Documentos - Desmobilização de imóveis da
                                            Telefônica/Vivo?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                O colaborador deverá fazer seu login no Portal de Governança Documental,
                                                em seguida acessar o menu "Solicitações", selecionar a opção "Retirada
                                                de Documentos - Desmobilização", preencher e enviar o formulário de
                                                solicitação.
                                            </p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>
                <!-- pergunta 23 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Onde vejo as notícias de Gestão Documental?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                As principais notícias sobre gestão e governança documental são
                                                publicadas na página principal do Portal de Governança Documental.
                                            </p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>




            </div>

            <!-- Serviços, procedimentos e definições de Gestão Documental -->
            <div class="tab-pane fade" id="list-servicos-e-procedimentos" role="tabpanel"
                aria-labelledby="list-servicos-e-procedimentos-list">

                <!-- pergunta 1 -->

                <div class="row">
                    <div class="col-lg-12">
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="mpt">
                                        O que é Governança Documental?
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <div class="row">
                                    <div class="col-lg-12">
                                        <p>
                                            Aplicação de uma macrovisão nos projetos de gestão documental de seus
                                            clientes.
                                            Definido como: "Um conjunto transversal de diretrizes estratégicas, táticas
                                            e
                                            operacionais de modo a provocar sinergias interativas e relacionais entre
                                            pessoas, processos de negócios, soluções tecnológicas, fluxos de trabalho,
                                            documentos, dados e informações, ajudando assim as organizações corporativas
                                            alinharem os seus programas arquivísticos com os 4 pilares que sustentam a
                                            governança corporativa: transparência, equidade, prestação de contas e
                                            responsabilidade de seus negócios".

                                            <br><br>

                                            Na prática significa, conceber e implementar uma "Arquitetura de Governança
                                            Documental da Telefônica/Vivo Brasil" a partir da concepção de programas,
                                            projetos, redes, sistemas, políticas, procedimentos, etc para instituir a
                                            cultura Arquivística na cultura organizacional de seus clientes, tendo como
                                            ponto de partida a adoção e aplicação de um conjunto de melhores práticas e
                                            padrões assumidos nacional e internacionalmente, com o objetivo de garantir
                                            controles e gerenciamento efetivos de modo a ampliar e garantir a
                                            sustentabilidade, segurança e melhor performance dos negócios da
                                            Telefônica/Vivo
                                            Brasil.

                                            <br><br>

                                            Em sua essência a aplicação da "Governança Arquivística" tem como principal
                                            finalidade instituir a sustentabilidade, confiabilidade e responsabilidade
                                            dos
                                            diversos atores internos e externos às organizações, criando uma cultura
                                            documental pautada na eficiência a partir da adoção de mecanismos de
                                            monitoramento, avaliação, planejamento e re(direcionamento) das
                                            organizações.
                                        </p>
                                    </div>
                                </div>

                            </mat-expansion-panel>

                        </mat-accordion>
                    </div>
                </div>


                <!-- pergunta 2 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é Gestão Documental?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                Refere-se a aplicação de técnicas, procedimentos e métodos sistemáticos
                                                no
                                                gerenciamento dos documentos nas suas respectivas fases: produção;
                                                tramitação; acesso/uso; arquivamento, avaliação e sua destinação final
                                                (eliminação e/ou recolhimento) a partir da adoção dos principais
                                                instrumentos de gestão: PCD - Plano de Classificação de Documentos e TTD
                                                -
                                                Tabela de Temporalidade de Documentos. Isto é, na prática, diz respeito
                                                aos
                                                aspectos gerenciais de todo o ciclo documental, contemplando ações de
                                                preservação física e lógica de documentos a curto, médio e longo prazo,
                                                mecanismos eficazes de recuperação, difusão, acesso, infra-estrutura
                                                física
                                                e tecnológica, compliance com os marcos jurídicos, normativos e
                                                regulatórios, etc.
                                            </p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 3 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Qual o Papel da área da Gestão Documental da Telefônica/Vivo?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                A área de Gestão Documental da Telefônica Brasil é responsável pelas
                                                seguintes atividades:
                                            </p>
                                            <ul>
                                                <li>
                                                    Efetivar a transformação digital dos processos documentais
                                                    (assinaturas digitais de documentos; digitalização de documentos;
                                                    automação dos processos e fluxos documentais e implementação de
                                                    plataformas digitais de armazenamento, acesso e preservação a longo
                                                    prazo dos documentos);
                                                </li>
                                                <li>
                                                    Gestão integral do processo de Gestão Documental em todos os seus
                                                    aspectos e interfaces;
                                                </li>
                                                <li>
                                                    Desenvolvimento e implementação de metodologias e melhorias em
                                                    Gestão Documental;
                                                </li>
                                                <li>
                                                    Implementar e gerenciar medidas de segurança e confidencialidade da
                                                    informação;
                                                </li>
                                                <li>
                                                    Apoiar as áreas na adoção de procedimentos e metodologias para
                                                    assegurar a integridade física e digital de todos os documentos
                                                    produzidos;
                                                </li>
                                                <li>
                                                    Mapear o cenário de Gestão de Documentos da Telefônica Brasil e
                                                    propor melhorias contínuas (produção, organização, armazenamento,
                                                    acesso e uso de documentos);
                                                </li>
                                                <li>
                                                    Gerenciamento do ciclo de vida dos documentos através do Plano de
                                                    Classificação e Tabela de Temporalidade de Documentos;
                                                </li>
                                                <li>
                                                    Identificar junto com a área responsável pelo documento o seu ciclo
                                                    de vida e prazo de permanência em arquivo, digital ou físico,
                                                    efetuando com frequência o expurgo dos documentos fora de seu prazo
                                                    de guarda, com a concordância da área responsável pelo documento;
                                                </li>
                                                <li>
                                                    Melhoria contínua dos processos documentais.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>


                <!-- pergunta 5 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é o projeto de Catalogação do Acervo Legado Telefônica/Vivo?. Quais
                                            são seus objetivos e como é feito?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                Para realizar a catalogação do acervo Legado da Telefônica/Vivo estimado
                                                em 550 mil caixas, foi contratada Portal de Comissionamento para
                                                realizar esse projeto.
                                                <br><br>
                                                Os objetivos do Projeto de Catalogação Acervo Legado tem como
                                                finalidade:
                                            </p>

                                            <p>a) Identificação dos conteúdos das 550 mil caixas:</p>
                                            <ul>
                                                <li>
                                                    Descrição dos tipos documentais;
                                                </li>
                                                <li>
                                                    Identificação responsável pela produção do documento;
                                                </li>
                                                <li>
                                                    Atribuição de valor legal ao documento (tempo de arquivamento e sua
                                                    destinação final (descarta; guarda ou digitalização);
                                                </li>
                                                <li>
                                                    Identificação da data em que o documento foi produzido;
                                                </li>
                                                <li>
                                                    Identificação da empresa produtora do documento (Global Telecom;
                                                    Telesp; Telefônica, etc);
                                                </li>
                                            </ul>

                                            <p>b) Redução de custos de custódia do acervos físicos:</p>
                                            <ul>
                                                <li>
                                                    Redução de espaço ocioso em caixas;
                                                </li>
                                                <li>
                                                    Descarte seguro de documentos via TTD com base em legislação
                                                    vigente;
                                                </li>
                                            </ul>
                                            <p>
                                                c) Garantir a integridade, confiabilidade, acesso e segurança do acervo
                                                legado:
                                            </p>
                                            <ul>
                                                <li>
                                                    Segurança da Informação;
                                                </li>
                                                <li>
                                                    Preservação a longo prazo dos documentos críticos;
                                                </li>
                                            </ul>

                                            <p>
                                                d) Permitir a localização de documentos
                                            </p>

                                            <ul>
                                                <li>
                                                    Catalogação e/ou inventário do conteúdo de caixas integrada com
                                                    sistemas de gerenciamento de acervos
                                                </li>
                                                <li>
                                                    Digitalização de documentos com utilização de tecnologias e
                                                    metodologias visando a melhor relação
                                                    custo/benefício/segurança/acesso
                                                </li>
                                            </ul>

                                            <p>
                                                e) Garantir a segurança jurídica e conformidade com normas e leis
                                                vigentes:
                                            </p>
                                            <ul>
                                                <li>
                                                    Legislação municipal, estadual e federal
                                                </li>
                                                <li>
                                                    Normas - ABNT/ISO
                                                </li>
                                                <li>
                                                    Melhores práticas e recomendações Conarq
                                                </li>
                                            </ul>

                                            <p>
                                                As etapas da Catalogação do Acervo Legado são:
                                            </p>
                                            <ul>
                                                <li>
                                                    <strong> Catalogação:</strong> Identificação das características dos
                                                    documentos em sistemas de gerenciamento de acervos físicos:
                                                    <ul>
                                                        <li class="sub-list">
                                                            Espécie e Tipologia Documental
                                                        </li>
                                                        <li class="sub-list">
                                                            Área responsável pela produção do documento
                                                        </li>
                                                        <li class="sub-list">
                                                            Data de criação do documento
                                                        </li>
                                                        <li class="sub-list">
                                                            Nome do empresa que gerou o documento
                                                        </li>
                                                        <li class="sub-list">
                                                            UF (Estado)
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <strong>Otimização de caixas:</strong> troca das caixas (com cubagem
                                                    de 5kg e 10k)
                                                    catalogadas para guarda para caixas de 20 kg, além de racionalizar o
                                                    espaçamento de caixas de 20kg que não estiverem com a sua capacidade
                                                    máxima de armazenamento. Aplica-se nessa atividade a retirada de
                                                    pastas AZ, caixas box de 5kg e 10kg de dentro das caixas de 20kg,
                                                    visando minimizar a quantidade de caixas custodiadas na empresa de
                                                    guarda externa.
                                                </li>
                                                <li>
                                                    <strong>Organização:</strong> Definição e aplicação de critérios de
                                                    organização e ordenação física dos documentos.
                                                </li>
                                                <li>
                                                    <strong>Digitalização:</strong> Seleção dos conjuntos documentais
                                                    aprovados para digitalização. A abordagem utilizada na digitalização
                                                    terá como base a aplicação do Decreto 10.278/2020 para garantir a
                                                    “segurança jurídica” dos documentos que serão digitalizados.
                                                </li>
                                                <li>
                                                    <strong> Dossiê Informativo de Descarte:</strong> Com base no
                                                    conceito de "Descarte
                                                    Seguro”, nenhum documento poderá ser eliminado se não tiver sido
                                                    submetido à avaliação e se não constar na Tabela de Temporalidade
                                                    Documental da Telefônica/Vivo Brasil, devidamente aprovada por
                                                    autoridade competente. A eliminação depende de algum instrumento
                                                    legal ou normativo que a autorize. A eliminação deverá ser
                                                    registrada em Dossiê Informativo para aprovação de eliminação de
                                                    documentos, juntamente com a Ata ou Termo de Eliminação onde constem
                                                    os seguintes dados:
                                                    <ul>
                                                        <li class="sub-list">
                                                            Área produtora;
                                                        </li>
                                                        <li class="sub-list">
                                                            Nome da operadora/empresa Vivo;
                                                        </li>
                                                        <li class="sub-list">
                                                            Série documental e/ou tipos documentais;
                                                        </li>
                                                        <li class="sub-list">
                                                            Datas-limite (início e final);
                                                        </li>
                                                        <li class="sub-list">
                                                            Quantidade eliminada (por caixas e kg de papeis destinados
                                                            para fragmentação e reciclagem);
                                                        </li>
                                                        <li class="sub-list">
                                                            Data da eliminação;
                                                        </li>
                                                        <li class="sub-list">
                                                            Fotografia do documento e da caixa (SKP - identificação da
                                                            caixa com código de barra) descartada;
                                                        </li>
                                                        <li class="sub-list">
                                                            Registro fotográfico e/ou vídeo do processo de fragmentação
                                                            e reciclagem;
                                                        </li>
                                                        <li class="sub-list">
                                                            Assinatura dos responsáveis.
                                                        </li>
                                                    </ul>
                                                </li>

                                                <li>
                                                    <strong>Aprovação do Descarte pela Comissão Permanente de Avaliação
                                                        Documental:</strong> Será enviado o “Dossiê Informativo de
                                                    Descarte” para deliberação da Comissão Permanente de Avaliação
                                                    Documental, contendo as seguintes informações: amostras documentais,
                                                    períodos; empresas do grupo; definição; tipos documentais anexos e
                                                    legislaçao.
                                                </li>

                                                <li>
                                                    <strong>Descarte Seguro de Documentos.</strong> Após a aprovação do
                                                    Descarte do respectivo Tipo Documental, é feita a seleção e
                                                    avaliação das caixas a serem descartadas.
                                                </li>

                                                <li>
                                                    <strong>Fragmentação Segura de Documentos (Aparas).</strong> Após a
                                                    aplicação do descarte, as caixas serão enviadas para a fragmentação.
                                                    Em seguida é gerado um Certificado de fragmentação dos respectivos
                                                    documentos.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 6 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é, como é feito e para que serve o Mapeamento de Processos
                                            Documentais?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                Os objetivos do Mapeamento de Processos Documentais tem como finalidade
                                                levantar junto as áreas da Telefônica/Vivo Brasil:
                                            </p>
                                            <ul>
                                                <li>
                                                    O Processo, Subprocesso e Atividade que gerou o documento;
                                                </li>
                                                <li>
                                                    A identificação da Espécie e Tipologia Documental;
                                                </li>
                                                <li>
                                                    A identificação do sistema/plataforma que gerou ou armazena o
                                                    documento;
                                                </li>
                                                <li>
                                                    A identificação de documentos críticos (compliance legal e
                                                    regulatória);
                                                </li>
                                                <li>
                                                    A identificação do tipos de assinaturas aplicados aos documentos
                                                    (manual e/ou digital/eletrônico);
                                                </li>
                                                <li>
                                                    A identificação dos tipos de documentos físicos e natodigitais
                                                    (formatos e suportes);
                                                </li>
                                                <li>
                                                    A identificação de documentos críticos que justificam a sua
                                                    digitalização;
                                                </li>
                                                <li>
                                                    Acessos e restrições aos documentos;
                                                </li>
                                                <li>
                                                    Metadados/Indexadores de recuperação;
                                                </li>
                                                <li>
                                                    Formas de compartilhamento;
                                                </li>
                                                <li>
                                                    Tempo de arquivamento dos documentos.
                                                </li>
                                            </ul>

                                            <p>
                                                Como é feito?
                                            </p>

                                            <ul>
                                                <li>
                                                    Aplicação de entrevistas remotas e/ou presenciais com os
                                                    responsáveis pelos processos e atividades produzidas pelas áreas
                                                    funcionais da Telefônica Brasil.
                                                </li>
                                            </ul>

                                            <p>
                                                Pra que serve?
                                            </p>

                                            <ul>
                                                <li>
                                                    Diagnosticar e identificar falhas e oportunidades para proposição de
                                                    melhorias contínuas do cenário de Governança Documental da
                                                    Telefônica Brasil, contribuindo efetivamente para acelerar a
                                                    transformação digital de seus processos documentais;
                                                </li>
                                                <li>
                                                    Desenvolver políticas de Governança Documental.
                                                </li>
                                            </ul>

                                            <p>
                                                Produtos finais do Mapeamento de Processos Documentais
                                            </p>

                                            <ul>
                                                <li>
                                                    Plano de Classificação de Documentos - PCD
                                                </li>
                                                <li>
                                                    Tabela de Temporalidade de Documentos - TTD
                                                </li>
                                                <li>
                                                    Lista de Espécies e Tipologias Documentais
                                                </li>
                                                <li>
                                                    Matriz de Gestão Documental
                                                </li>
                                                <li>
                                                    Plano de Melhorias em Gestão Documental
                                                </li>
                                                <li>
                                                    Política e normativa de Governança Documental
                                                </li>
                                            </ul>

                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 7 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é um Ponto Focal?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                É a pessoa indicada/nomeada para representar a sua área no projeto de
                                                Mapeamento de Processos Documentais que tem como propósito levantar e
                                                identificar por meio de entrevistas on-line e/ou presencial os
                                                processos, subprocessos, atividades e as tipologias documentais
                                                produzidas pela sua área. Esse mapeamento tem como objetivo o
                                                desenvolvimento de instrumentos de Gestão Documental: Plano de
                                                Classificação de Documentos, Tabela de Temporalidade de Documentos,
                                                Plano de Melhorias em Gestão Documental e Matriz de Gestão Documental.
                                            </p>

                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 8 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Quais requisitos devo ter para ser nomeado um ponto focal?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                Como ponto focal você precisará ter conhecimento dos processos,
                                                artividades e documentos de sua área produzidos e recebidos em meio
                                                digital e físico, pois elas serão analisadas para inclusão no Plano de
                                                Classificação de Documentos - PCD e Tabela de Temporalidade de
                                                Documentos - TTD.

                                                Como ponto focal você será o responsável pela comunicação entre a sua
                                                área e área de Gestão Documental da Telefônica/Vivo Brasil.

                                                Você terá que participar de reuniões on-line e/ou presencial com a
                                                equipe da área de Gestão Documental da Telefônica/Vivo Brasil e os
                                                consultores da empresa Portal de Comissionamento para alinhamento das
                                                ações, resolução de
                                                dúvidas, e demais atividades necessárias para a Elaboração do Plano de
                                                Classificação de Documentos - PCD; Tabela de Temporalidade de Documentos
                                                - TTD; Plano de Melhorias em Gestão Documental e Matriz de Gestão
                                                Documental, incluindo a definição e o cumprimento do cronograma para
                                                entrega das atividades aos consultores da empresa E2E.
                                            </p>

                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 9 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Fui nomeado Ponto Focal da minha área para o Mapeamento de Processos
                                            Documentais e agora?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                Você terá a responsabilidade de auxiliar na elaboração do Plano de
                                                Classificação, Tabela de Temporalidade Documental, Plano de Melhorias e
                                                Matriz de Gestão Documental de sua área.
                                            </p>

                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 11 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Quais as responsabilidades dos pontos focais?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                São atribuições do Ponto Focal:
                                            </p>

                                            <p>
                                                a) Ter conhecimento dos processos, subprocessos, atividades e tipos
                                                documentais produzidos e recebidos pela sua área;
                                                <br><br>

                                                b) Solicitar e acompanhar o preenchimento da Matriz de Gestão Documental
                                                e do Questionário de Riscos e Melhorias em Gestão Documental, bem como a
                                                "validação" do Plano de Classificação de Documentos - PCD e da Tabela de
                                                Temporalidade de Documentos - TTD que será encaminhada pelos consultores
                                                da da E2E, empresa contratada para elaborar o "mapeamento de
                                                processos documentais da Telefônica/Vivo Brasil;
                                                <br><br>

                                                c) Participar de reuniões on-line e/ou presencial com a equipe da área
                                                de Gestão Documental da Telefônica/Vivo Brasil juntamente com o time de
                                                consultores da E2E, para alinhamento das ações de Mapeamento de
                                                Processos Documentais;
                                                <br><br>

                                                d) Centralizar o recebimento dos Formulários de Mapeamento de Processos
                                                Documentais, para posterior envio aos consultores da empresa E2E;
                                                <br><br>

                                                e) Ser o responsável pela devolução dos Formulários de Mapeamento de
                                                Processos Documentais à E2E;
                                                <br><br>


                                                f) Auxiliar nas atividades de agendamento e cronograma das reuniões
                                                on-line e/ou presencial com os colaboradores responsáveis pela
                                                produção/recebimento de documentos de sua área;
                                                <br><br>

                                                g) Auxiliar na definição dos prazos de guarda dos documentos que
                                                constarão na Tabela de Temporalidade de sua área;

                                                <br><br>
                                                h) Autuar o processo de elaboração e formalização da Tabela de
                                                Temporalidade;
                                                <br><br>

                                                i) Cumprir os prazos estabelecidos pela área de Gestão Documental da
                                                Telefônica/Vivo Brasil no cronograma de entregas e validação dos
                                                Formulários de Mapeamento de Processos Documentais.
                                            </p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 12 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Sempre existirá um ponto focal?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                O Ponto Focal exercerá suas funções enquanto houver a necessidade de
                                                "Mapeamento dos Processos Documentais para a elaboração e atualização do
                                                Plano de Classificação de Documentos - PCD e a Tabela de Temporalidade
                                                de Documentos - TTD, Plano de Melhorias em Gestão Documental e Matriz de
                                                Gestão Documental de sua área, ou seja, suas funções são de caráter
                                                permanente.
                                            </p>

                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 13 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é Matriz de Gestão Documental?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                A Matriz de Gestão Documental tem como finalidade levantar junto as
                                                áreas da Telefônica Brasil:
                                            </p>

                                            <ul>
                                                <li>
                                                    O Processo, Subprocesso e Atividade que gerou o documento;
                                                </li>
                                                <li>
                                                    A identificação da Espécie e Tipologia Documental;
                                                </li>
                                                <li>
                                                    A identificação do sistema/plataforma que gerou ou armazena o
                                                    documento;
                                                </li>
                                                <li>
                                                    A identificação de documentos críticos (compliance legal e
                                                    regulatória);
                                                </li>
                                                <li>
                                                    A identificação do tipos de assinaturas aplicados aos documentos
                                                    (manual e/ou digital/eletrônico);
                                                </li>
                                                <li>
                                                    A identificação dos tipos de documentos físicos e natodigitais
                                                    (formatos e suportes);
                                                </li>
                                                <li>
                                                    A identificação de documentos críticos que justificam a sua
                                                    digitalização;
                                                </li>
                                                <li>
                                                    Acessos e restrições aos documentos;
                                                </li>
                                                <li>
                                                    Nomes das plataformas e/ou sistemas que produziram e/ou armazenam os
                                                    documentos natodigitais;
                                                </li>
                                                <li>
                                                    Metadados/Indexadores de recuperação;
                                                </li>
                                                <li>
                                                    Formas de compartilhamento dos documentos;
                                                </li>
                                                <li>
                                                    Tempo de arquivamento e destinação final dos documentos.
                                                </li>
                                            </ul>

                                            <p>
                                                Como é feito?
                                            </p>
                                            <ul>
                                                <li>
                                                    Aplicação de entrevistas remotas e/ou presenciais com os
                                                    responsáveis pelos processos e atividades produzidas pelas áreas
                                                    funcionais da Telefônica Brasil.
                                                </li>
                                            </ul>

                                            <p>
                                                Pra que serve?
                                            </p>
                                            <ul>
                                                <li>
                                                    Diagnosticar e identificar falhas e oportunidades para proposição de
                                                    melhorias contínuas do cenário de Governança Documental da
                                                    Telefônica Brasil, contribuindo efetivamente para acelerar a
                                                    transformação digital de seus processos documentais.
                                                </li>
                                                <li>
                                                    Desenvolver políticas de Governança Documental.
                                                </li>
                                            </ul>

                                            <p>
                                                rodutos finais do mapeamento de processos documentais
                                            </p>
                                            <ul>
                                                <li>
                                                    Plano de Classificação de Documentos - PCD
                                                </li>
                                                <li>
                                                    Tabela de Temporalidade de Documentos - TTD
                                                </li>
                                                <li>
                                                    Matriz de Gestão Documental
                                                </li>
                                                <li>
                                                    Política e normativa de Governança Documental
                                                </li>
                                            </ul>

                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 14 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é a Comissão Permanente de Avaliação Documental?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                É um grupo de trabalho multidisciplinar composto por colaboradores da
                                                Telefônica Brasil encarregados pela avaliação da prescrição legal e
                                                precaução organizacional dos documentos produzidos, recebidos e
                                                acumulados em decorrência dos seus processos e atividades de negócios. A
                                                comissão tem como competências :
                                            </p>

                                            <ul>
                                                <li>
                                                    Aprovação dos “Dossiês informativos para Descarte de Documentos”.
                                                </li>
                                                <li>
                                                    Deliberar sobre a aprovação da eliminação , transferência,
                                                    recolhimento, conversão e digitalização de documentos.
                                                </li>
                                                <li>
                                                    Aprovação do Plano de Classificação de Documentos - PCD e da Tabela
                                                    de Temporalidade de Documentos – TTD.
                                                </li>
                                                <li>
                                                    Supervisionar as eliminações, que serão realizadas por meio de
                                                    fragmentação mecânica, com a garantia de que a descaracterização dos
                                                    documentos não possa ser revertida.
                                                </li>
                                                <li>
                                                    Propor a constituição de grupos de trabalho para tratar de assuntos
                                                    específicos relacionados a Gestão Documental.
                                                </li>
                                            </ul>


                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>


                <!-- pergunta 15 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é Lista de Espécies e Tipologias Documentais?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                A finalidade da Lista de Espécies e Tipologias Documentais é normatizar
                                                a
                                                nomeação de todos os tipos documentais produzidos e recebidos pela
                                                Telefônica Brasil deverão ser padronizados. A finalidade dessa
                                                padronização
                                                quando aplicada no momento da identificação do documento, assegura a
                                                qualidade da catalogação e conseqüentemente do seu acesso e recuperação
                                                quando solicitado para fins de pesquisa e consulta.
                                            </p>

                                            <p>
                                                Para tal fim, disponibilizamos para consulta uma lista de espécies e
                                                tipologias documentais para apoiar o colaborador no momento de
                                                catalogação
                                                de um tipo documental, em conjunto com os procedimentos e regras básicas
                                                para auxiliar os colaboradores da Telefônica Brasil nomearem os
                                                documentos
                                                produzidos/recebidos pelas áreas de negócios da instituição de modo
                                                padronizado e consistente.
                                            </p>

                                            <p>
                                                Em resumo, a Tipologia Documental têm como alicerce a identificação do
                                                tipo,
                                                cuja fixação atrela-se ao conceito de espécie documental. A nomeação de
                                                todos os tipos documentais produzidos/recebidos pela Telefônica devem
                                                preceder da espécie documental. Por exemplo:
                                            </p>

                                            <table class="table table-borderless">
                                                <thead>
                                                    <tr>
                                                        <!-- <th scope="col">#</th> -->
                                                        <th scope="col">Espécie Documental</th>
                                                        <th scope="col">Tipologia Documental</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <!-- row 1 -->
                                                    <tr>
                                                        <td rowspan="2">Alvará</td>
                                                        <td>Alvará de funcionamento </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Alvará judicial </td>
                                                    </tr>


                                                </tbody>

                                                <tbody>
                                                    <!-- row 2 -->

                                                    <tr>
                                                        <td rowspan="3">Apólice </td>
                                                        <td>Apólice de seguro de vida </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Apólice de seguro automóvel </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Apólice de seguro de incêndio </td>
                                                    </tr>

                                                </tbody>

                                                <tbody>

                                                    <!-- row 3 -->

                                                    <tr>
                                                        <td rowspan="3">Ata </td>
                                                        <td>Ata de eleição dos representantes da CIPA</td>
                                                    </tr>

                                                    <tr>
                                                        <td>Ata de estudo prévio e abertura de mão-de-obra</td>
                                                    </tr>

                                                    <tr>
                                                        <td>Ata de Reunião de Assembléia Geral de acionistas </td>
                                                    </tr>


                                                </tbody>

                                                <tbody>
                                                    <!-- row 4 -->

                                                    <tr>
                                                        <td rowspan="3">Processo </td>
                                                        <td>Processo Revisão de Fatura </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Processo de Habilitação de Serviço Móvel Pessoal Pós-Pago
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Processo de Conciliação de ICMS </td>
                                                    </tr>

                                                </tbody>

                                                <tbody>

                                                    <!-- row 5 -->

                                                    <tr>
                                                        <td rowspan="3">Relatório </td>
                                                        <td>Relatório de acerto de ocorrência de nota fiscal fatura
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Relatório de acerto tributário
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Relatório de Abertura da Reclamação PROCON </td>
                                                    </tr>

                                                </tbody>
                                            </table>

                                            <!-- aqui -->

                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 15 att -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é o Plano de Classificação de Documentos - PCD?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">


                                            <p>
                                                O Plano de Classificação de Documentos - PCD consiste na estrutura
                                                utilizada para classificar todo e qualquer documento tradicional ou
                                                eletrônico produzido ou recebido pela Telefônica/Vivo Brasil no
                                                exercício de suas atividades. Esta estrutura de classificação reflete a
                                                hierarquia funcional da empresa.

                                                <br><br>

                                                O objetivo é identificar e recuperar o contexto de produção dos
                                                documentos, ou seja, qual ATIVIDADE específica exigiu a produção do
                                                documento, visando identificar o valor deste documento para a
                                                Instituição.

                                                <br><br>

                                                Deste modo, para estruturar o PCD, as funções e atividades são
                                                organizadas hierarquicamente, de modo a formar uma estrutura de assuntos
                                                utilizada para classificar todo e qualquer documento gerado ou recebido
                                                pela Telefônica/Vivo Brasil.

                                                <br><br>

                                                Assim, para cada Unidade Organizacional da Telefônica/Vivo Brasil, são
                                                identificadas os processos, subprocessos e atividades, conforme descrito
                                                a seguir:
                                            </p>

                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Item</th>
                                                        <th scope="col">Descrição</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">1</th>
                                                        <td>Processo:</td>
                                                        <td>Subprocesso:</td>
                                                        <td>Atividade:</td>
                                                        <td>Tipologia documental:</td>
                                                        <td>Série/dossiê:</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Conjunto de atividades sistemáticas direcionadas para a
                                                            consecução de objetivos e resultados das unidades
                                                            organizacionais da Telefônica/Vivo Brasil. </td>
                                                        <td>Agrupamento de atividades afins, subordinadas a um processo
                                                            específico. </td>
                                                        <td>Ação, encargo ou serviço decorrentes do exercício de um
                                                            processo/subprocesso que pode ser identificada como
                                                            atividade-meio e atividade-fim. </td>
                                                        <td>É a configuração que assume a espécie de acordo com a
                                                            atividade que a gerou, por exemplo: Ata de reunião da
                                                            diretoria, Contrato de prestação de serviço, Relatório de
                                                            planejamento de atividades, etc. </td>
                                                        <td>Conjunto de documentos do mesmo assunto, produzidos em
                                                            decorrência do exercício do mesmo processo, subprocesso e
                                                            atividade e que resultam de forma idêntica de produção,
                                                            tramitação e obedecem à mesma temporalidade e destinação.
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>



                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 16 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é Tabela de Temporalidade de Documentos?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">


                                            <p>
                                                A Tabela de Temporalidade de Documentos - TTD ,  é um dos principais
                                                instrumentos de gestão documental, já que ela cumpre a função de
                                                determinar:
                                            </p>

                                            <ul>
                                                <li>
                                                    Os prazos de arquivamento e guarda dos documentos no arquivo
                                                    corrente operacional;
                                                </li>
                                                <li>
                                                    O prazo de arquivamento e guarda para o arquivo intermediário
                                                    (transferência da custódia para a empresa de guarda) com base em
                                                    legislação vigente;
                                                </li>
                                                <li>
                                                    Após o cumprimento de seus prazos prescricionais e precaucionais, os
                                                    documentos são destinados para eliminação, guarda permanente e/ou
                                                    digitalização;
                                                </li>
                                                <li>
                                                    E por fim a destinação final dos documentos que já cumpriram a
                                                    prescrição legal, regulatório e precaução organizacional
                                                    (eliminação; guarda permanente e/ou sua digitalização).
                                                </li>
                                            </ul>

                                            <p>
                                                Na prática a <strong>TTD</strong> assegura a racionalização dos fluxos
                                                documentais nas
                                                suas fases (corrente, intermediária e permanente), bem como de sua
                                                acumulação ordenada. Por isso a <strong>TTD</strong> tem que
                                                compreendida como um
                                                documento normativo institucional para promover a confiança, segurança e
                                                transparência dos processos de organização, arquivamento, avaliação e
                                                descarte de documentos arquivísticos.
                                            </p>


                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 17 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Quais são as etapas para a elaboração e formalização da TTD?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <ul>
                                                <li>
                                                    Passo 1: O ponto focal irá se reunir on-line e/ou presencial com a
                                                    equipe da área de Gestão Documental da Telefônica/Vivo Brasil e com
                                                    os consultores da empresa Portal de Comissionamento para alinhamento
                                                    e início das
                                                    atividades do Mapeamento dos Processos Documentais, bem como a
                                                    definição do cronograma das entregas e esclarecimentos de dúvidas.
                                                </li>
                                                <li>
                                                    Passo 2: O ponto focal irá encaminhar os Formulários de Mapeamento
                                                    de Processos Documentais para os colaboradores de sua área.
                                                </li>
                                                <li>
                                                    Passo 3: Caso seja necessário o ponto focal irá se reunir com os
                                                    colaboradores de sua área para esclarecer dúvidas.
                                                </li>
                                                <li>
                                                    Passo 4: O ponto focal irá definir os prazos de guarda dos
                                                    documentos juntamente com os colaboradores de sua área.
                                                </li>
                                                <li>
                                                    Passo 5: O ponto focal será o responsável por enviar os Formulários
                                                    preenchidos de Mapeamento de Processos Documentais para os
                                                    consultores da E2E
                                                </li>
                                                <li>
                                                    Passo 6: Após a sistematização do Plano de Classificação de
                                                    Documentos - PCD e da Tabela de Temporalidade de Documentos - TTD
                                                    pelos consultores da E2E, o ponto focal deverá valida-la
                                                    juntamente com os colaboradores de sua área e posterior envio para
                                                    os consultores da E2E.
                                                </li>
                                                <li>
                                                    Passo 7: A PCD e a TTD deverá ser submetida para aprovação da CPAD -
                                                    Comissão Permanente de Avaliação Documental da Telefônica/Vivo
                                                    Brasil.
                                                </li>
                                            </ul>


                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 18 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Devo ou não imprimir documentos? Eis a questão?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                A Telefônica/Vivo ao institucionalizar no seu planejamento estratégico a
                                                meta "Digitalizar para Aproximar", deixa evidente a abordagem de
                                                Transformação Digital de seus processos de negócios. Sendo assim, caso
                                                na sua área, haja processos e atividades que ainda transacionam (produz
                                                e recebe) documentos físicos é altamente recomendável aplicar a
                                                transformação digital desses processos, atividades e documentos.

                                                <br><br>

                                                A solicitação de serviços de transformação digital de seus processos,
                                                atividades e documentos (assinatura digital e digitalização) pode ser
                                                solicitada no menu "solicitações" do Portal de Governança Documental da
                                                Telefônica/Vivo.

                                                <br><br>

                                                Assim com base nessas premissas, é altamente recomendável a redução das
                                                impressões e tramitação de documentos físicos, bem como reduzir o acervo
                                                legado, aplicando a digitalização segura para garantir a sua
                                                conformidade jurídica.
                                            </p>


                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 20 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Como saber o tempo que devo guardar um documento e se devo eliminá-lo ou
                                            não?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                Para saber o tempo de guarda de cada documento produzido/recebido pela
                                                Telefônica/Vivo e a sua respectiva destinação (guarda; descarte;
                                                digitalização/descarte; digitalização/guarda) deve-se consultar a TTD -
                                                Tabela de Temporalidade de Documentos, onde constam a destinação final
                                                de todos os tipos documentais aprovados pela CPAD Telefônica/Vivo.

                                                <br><br>

                                                Na prática significa que para realizar o descarte seguro de documentos,
                                                a tipologia documental deve constar aprovação de sua destinação final na
                                                TTD - Tabela de Temporalidade de Documentos aprovada pela CPAD. Caso a
                                                tipologia documental sugerida para descarte não conste na TTD, deve-se
                                                elaborar um "Dossiê Informativo para Aprovação de Guarda /e ou Descarte
                                                de Documentos" para envio e deliberação de sua destinação final da CPAD,
                                                e por fim incluir a respectiva tipologia na TTD.

                                                <br><br>

                                                De acordo com a Normativa de Gestão Documental da Telefônica/Vivo é
                                                estritamente proibido descartar/eliminar documentos corporativos sem a
                                                autorização formal da área de gestão documental e deliberação final da
                                                Comissão Permanente de Avaliação Documental - CPAD.
                                            </p>


                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 22 -->
                <!-- <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é um Documento de Memoria Institucional

                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam
                                                reiciendis
                                                praesentium eum itaque, doloremque quas voluptas corrupti accusamus
                                                quia, ex adipisci ipsa? Odit sint, quae eligendi quos perspiciatis harum
                                                necessitatibus?
                                            </p>


                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div> -->

                <!-- pergunta 24 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é um Documento Arquivístico?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                <strong>Documento arquivístico</strong> é a informação registrada,
                                                independente da forma
                                                ou do suporte, produzida ou recebida no decorrer dos processos e das
                                                atividades desempenhadas pela Telefônica/Vivo Brasil, e que seja que
                                                possua elementos constitutivos suficientes para servir de prova dessas
                                                atividades.Para tal, se faz necessário atribuir procedimentos, métodos e
                                                aplicação de tecnologias para assegurar a sua proveniência,
                                                autenticidade, identidade, integridade, organicidade, confidencialidade
                                                e acessibilidade.
                                            </p>


                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 24 att -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é m projeto de Memória Institucional?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                Projeto de Memória Institucional tem por finalidade coletar, organizar e
                                                disseminar fontes de informações registradas em documentos
                                                institucionais, a partir de seus diferentes suportes e formatos
                                                (fotografias, filmes, áudios, textos, etc), ajudando a fortalecer os
                                                caminhos de identidade da sua instituição, dever ser institucionalizados
                                                enquanto estratégia e ferramenta para potencializar o fortalecimento da
                                                identidade da Telefônica/Vivo Brasil, de modo a valorizar o seu capital
                                                intelectual e da sua cultura organizacional.
                                            </p>


                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 24 att -->
                <!-- <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Como garantir a segurança jurídica de um documento natodigital?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis
                                                blanditiis reiciendis enim veniam cumque pariatur obcaecati doloremque
                                                voluptates vero odio nulla autem neque quia, dolore error soluta
                                                voluptas. Commodi, minus.
                                            </p>


                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div> -->

                <!-- pergunta 24 att -->
                <!-- <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Como garantir a segurança jurídica de um documento digitalizado?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis
                                                blanditiis reiciendis enim veniam cumque pariatur obcaecati doloremque
                                                voluptates vero odio nulla autem neque quia, dolore error soluta
                                                voluptas. Commodi, minus.
                                            </p>


                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div> -->

                <!-- pergunta 26 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é e para o que serve a Normativa de Gestão Documental?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                Resposta: A Normativa de Gestão Documental da Telefônica/Vivo Brasil tem
                                                a finalidade de:
                                            </p>

                                            <ul>
                                                <li>
                                                    Estabelecer diretrizes para a Gestão de Documentos da Telefônica
                                                    Brasil de modo a orientar o gerenciamento do ciclo de vida dos
                                                    documentos, abrangendo a sua criação, arquivamento, guarda
                                                    permanente e/ou eliminação, sejam eles registrados em suportes
                                                    físicos ou em mídias (analógicas, digitais ou eletrônicas).
                                                </li>
                                                <li>
                                                    Estabelecer um conjunto de procedimentos e operações técnicas para
                                                    subsidiar as atividades de produção, tramitação, classificação,
                                                    organização, arquivamento, avaliação, digitalização/conversão,
                                                    eliminação/descarte seguro, recuperação e acesso aos documentos
                                                    físicos e digitais nas fases corrente e intermediária e permanente.
                                                </li>
                                                <li>
                                                    Institucionalizar na Telefônica/Vivo Brasil diretrizes aplicáveis a
                                                    todas as áreas e departamentos da Telefônica Brasil, suas
                                                    subsidiárias e controladas (“Telefônica Brasil” ou “Companhia”),
                                                    elegíveis de um sistema de Gestão Documental ou que necessitem
                                                    tratar seus documentos administrativos/técnicos e aceites de voz
                                                    contemplando as melhores práticas para gerenciamento, armazenamento,
                                                    preservação e disponibilização do acervo técnico e administrativo,
                                                    assegurando funcionalidade, segurança, confiança e capacidade de
                                                    armazenamento e recuperação a longo prazo de seus conteúdos.
                                                </li>
                                            </ul>


                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 28 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            <!-- O que é uma visita Técnica para Analise Documental? Para que serve? -->
                                            O que é uma visita técnica pra analise documental? Para que serve?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                Caso você tenha na sua área arquivos em suporte papel e/ou digital que
                                                precisam ser avaliados quanto a sua destinação (organização,
                                                digitalização, descarte e/ou migração de mídia), solicite uma avaliação
                                                técnica da equipe de Gestão Documental. Esse serviço tem como finalidade
                                                garantir a continuidade do tratamento e organização técnica dos acervos
                                                corporativos da Telefônica/Vivo Brasil. Para solicitar esse serviço o
                                                colaborador deverá fazer seu login no Portal de Governança Documental,
                                                em seguida acessar o menu "Solicitações", selecionar a opção "Análise
                                                Documental", preencher e enviar o formulário de solicitação.
                                            </p>


                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 30 att -->
                <!-- <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é uma Analise Documental? Para que serve?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam
                                                reiciendis
                                                praesentium eum itaque, doloremque quas voluptas corrupti accusamus
                                                quia, ex adipisci ipsa? Odit sint, quae eligendi quos perspiciatis harum
                                                necessitatibus?
                                            </p>


                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div> -->

                <!-- pergunta 30 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            <!-- O que é uma Retirada de Documentos para Desmobilização? -->
                                            O que é uma retirada de arquivos corporativos em decorrência da
                                            desmobilização de imóveis?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                Ocorre toda vez em que há uma desmobilização dos imóveis e escritórios
                                                do grupo Telefônica/Vivo Brasil. Em decorrência dessa desmobilização é
                                                necessário proceder com retirada dos arquivos corporativos. O
                                                colaborador responsável pelos arquivos deverá solicitar a retirada
                                                desses arquivos diretamente no Portal de Governança Documental. Para
                                                solicitar a coleta de arquivos corporativos o colaborador deverá fazer
                                                seu login no Portal de Governança Documental, em seguida acessar o menu
                                                "Solicitações", selecionar a opção "Retirada de Documentos -
                                                Desmobilização", preencher e enviar o formulário de solicitação.
                                            </p>


                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 32 -->
                <!-- <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Como saber o tempo que devo guardar um documento e se devo eliminá-lo ou
                                            não?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam
                                                reiciendis
                                                praesentium eum itaque, doloremque quas voluptas corrupti accusamus
                                                quia, ex adipisci ipsa? Odit sint, quae eligendi quos perspiciatis harum
                                                necessitatibus?
                                            </p>


                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div> -->

                <!-- pergunta 34 -->
                <!-- <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é Plano de Classificação de documentos?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam
                                                reiciendis
                                                praesentium eum itaque, doloremque quas voluptas corrupti accusamus
                                                quia, ex adipisci ipsa? Odit sint, quae eligendi quos perspiciatis harum
                                                necessitatibus?
                                            </p>


                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div> -->






            </div>

            <!-- Guichê de Gestão Documental  -->
            <div class="tab-pane fade" id="list-ghiche-de-gestao-documental" role="tabpanel"
                aria-labelledby="list-ghiche-de-gestao-documental-list">


                <!-- pergunta 1 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é o Guichê de Gestão Documental?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                O Guichê de Gestão Documental é um setor responsável pelo fluxo de
                                                recepção (entrada) de documentos que foram enviados pelo correio e
                                                malotes para a sede da Telefônica/Vivo Brasil (Ecoberrini).
                                                <br><br>
                                                Quais são os serviços prestados pelo Guichê de Gestão Documental?
                                                <br><br>
                                            </p>

                                            <ul>
                                                <li>
                                                    Digitalização de documentos em fase corrente
                                                </li>
                                                <li>
                                                    Coleta de caixas para descarte seguro (fragmentação de papéis)
                                                </li>
                                                <li>
                                                    Organização e tratamento de documentos em fase corrente
                                                </li>
                                            </ul>


                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 2 -->
                <!-- <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Quais são os serviços prestados?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam
                                                reiciendis
                                                praesentium eum itaque, doloremque quas voluptas corrupti accusamus
                                                quia, ex adipisci ipsa? Odit sint, quae eligendi quos perspiciatis harum
                                                necessitatibus?
                                            </p>


                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div> -->

                <!-- pergunta 3 -->
                <!-- <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Como solicito um serviço ao Guichê de Gestão Documental?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam
                                                reiciendis
                                                praesentium eum itaque, doloremque quas voluptas corrupti accusamus
                                                quia, ex adipisci ipsa? Odit sint, quae eligendi quos perspiciatis harum
                                                necessitatibus?
                                            </p>


                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div> -->

                <!-- pergunta 4 -->
                <!-- <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Como incluir o meu serviço no fluxo do Guichê de Gestão Documental?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam
                                                reiciendis
                                                praesentium eum itaque, doloremque quas voluptas corrupti accusamus
                                                quia, ex adipisci ipsa? Odit sint, quae eligendi quos perspiciatis harum
                                                necessitatibus?
                                            </p>


                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div> -->

                <!-- pergunta 5 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Onde fica o Guichê de Gestão de Documental?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                O Guichê de Gestão Documental da Telefônica/Vivo está localizado no
                                                térreo do prédio Ecoberrini. O atendimento é das 8:00 as 18:00hs.
                                                Contato: guicheberrini@soluarq.com.br
                                            </p>


                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>





            </div>

            <!-- Digitalização -->
            <div class="tab-pane fade" id="list-digitalizacao" role="tabpanel"
                aria-labelledby="list-digitalizacao-list">


                <!-- pergunta 1 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Preciso realizar a digitalização dos Documentos da minha área? Por que?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                Para efetivar a transformação digital de seus negócios a Telefônica/Vivo
                                                Brasil, vem atuando com a premissa de que é necessário "Digitalizar para
                                                aproximar". Sendo assim a digitalização de seus processos de negócios e
                                                dos documentos transacionados e tramitados em suporte papel é
                                                imprescindível para que essa transformação digital ocorra. Veja a seguir
                                                as principais vantagens que a digitalização de documentos agrega ao seu
                                                processo e fluxos de trabalho:
                                            </p>

                                            <ul>
                                                <li>
                                                    Redução no tempo de busca
                                                </li>
                                                <li>
                                                    Redução de custos relacionados com a manutenção dos arquivos
                                                </li>
                                                <li>
                                                    Redução de perdas e extravios de documentos
                                                </li>
                                                <li>
                                                    Acesso compartilhado
                                                </li>
                                                <li>
                                                    Controle de acesso
                                                </li>
                                                <li>
                                                    Reuso de dados
                                                </li>
                                            </ul>

                                            <p>
                                                Por esse motivo, caso haja na sua área acervos legados ou em fase
                                                corrente que justifiquem a sua digitalização, solicite esse serviço no
                                                Portal de Governança Documental.
                                            </p>


                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 2 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Existe segurança jurídica para os documentos digitalizados?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                Sim. Caso seja aplicada no processo de digitalização os requisitos
                                                técnicos e procedimentais em conformidade com o Decreto 10.278/2020 que
                                                regulamenta os dispositivos da Lei 13.874/2020 e da Lei 12.682/2012, ao
                                                as estabelecer as técnicas e os requisitos para a digitalização de
                                                documentos públicos ou privados, a fim de que os documentos
                                                digitalizados produzam os mesmos efeitos legais dos documentos
                                                originais. Assim, o documento digitalizado quando solicitado para
                                                comprovação de qualquer ato perante pessoa jurídica, deverá seguir os
                                                padrões técnicos, bem como conter metadados mínimos especificados nos
                                                anexos do referido Decreto que visam assegurar: a integridade,
                                                confiabilidade, legibilidade, rastreabilidade e auditabilidade dos
                                                procedimentos e padrões empregados no processo de digitalização.

                                                <br><br>

                                                Como realizar uma Digitalização Segura de Documentos?
                                                Resposta: Para realizar uma digitalização segura de documentos, é
                                                necessário aplicar os requisitos técnicos e procedimentais em
                                                conformidade com o Decreto 10.278/2020, bem como aplicar técnicas da
                                                gestão documental. Abaixo segue os padrões técnicos míninos para
                                                digitalização de documentos.
                                            </p>

                                            <p> Padrões Técnicos Mínimos para Digitalização de Documentos</p>

                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Documento</th>
                                                        <th scope="col">Resolução mínima</th>
                                                        <th scope="col">Cor</th>
                                                        <th scope="col">Tipo Original</th>
                                                        <th scope="col">Formato de arquivo</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            Textos impressos, sem ilustração, em preto e branco
                                                        </td>
                                                        <td>
                                                            300 dpi
                                                        </td>
                                                        <td>
                                                            Monocromático
                                                            (preto e branco)
                                                        </td>
                                                        <td>
                                                            Texto
                                                        </td>
                                                        <td>
                                                            PDF/A
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Textos impressos, com ilustração, em preto e branco
                                                        </td>
                                                        <td>
                                                            300 dpi
                                                        </td>
                                                        <td>
                                                            Escala de cinza
                                                        </td>
                                                        <td>
                                                            Texto/imagem
                                                        </td>
                                                        <td>
                                                            PDF/A
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            Textos impressos, com ilustração e cores
                                                        </td>
                                                        <td>
                                                            300 dpi
                                                        </td>
                                                        <td>
                                                            RGB (colorido)
                                                        </td>
                                                        <td>
                                                            Texto/imagem
                                                        </td>
                                                        <td>
                                                            PDF/A
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            Textos manuscritos, com ou sem ilustração, em preto e branco
                                                        </td>
                                                        <td>
                                                            300 dpi
                                                        </td>
                                                        <td>
                                                            Escala de cinza
                                                        </td>
                                                        <td>
                                                            Texto/imagem
                                                        </td>
                                                        <td>
                                                            PDF/A
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            Textos manuscritos, com ou sem ilustração,em cores
                                                        </td>
                                                        <td>
                                                            300 dpi
                                                        </td>
                                                        <td>
                                                            RGB (colorido)
                                                        </td>
                                                        <td>
                                                            Texto/imagem
                                                        </td>
                                                        <td>
                                                            PDF/A
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            Fotografias cartazes
                                                        </td>
                                                        <td>
                                                            300 dpi
                                                        </td>
                                                        <td>
                                                            RGB (colorido)
                                                        </td>
                                                        <td>
                                                            Imagem
                                                        </td>
                                                        <td>
                                                            PNG
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            Plantas e mapas
                                                        </td>
                                                        <td>
                                                            600 dpi
                                                        </td>
                                                        <td>
                                                            Monocromático (preto e branco)
                                                        </td>
                                                        <td>
                                                            Texto/imagem
                                                        </td>
                                                        <td>
                                                            PNG
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>

                                            <p>Metadados Mínimos para todos os documentos</p>

                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Metadados</th>
                                                        <th scope="col">Definição</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            Assunto
                                                        </td>
                                                        <td>
                                                            Palavras-chave que representam o conteúdo do documento.
                                                            Pode ser de preenchimento livre ou com o uso de
                                                            vocabulário controlado ou tesauro.
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            Autor (nome)
                                                        </td>
                                                        <td>
                                                            Pessoa natural ou jurídica que emitiu o documento.
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            Data e local da digitalização
                                                        </td>
                                                        <td>
                                                            Registro cronológico (data e hora) e tópico (local) da
                                                            digitalização do documento.
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            Identificador documento do digital
                                                        </td>
                                                        <td>
                                                            Identificador único atribuído ao documento no ato de sua
                                                            captura para o sistema informatizado (sistema de negócios).
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            Responsável pela digitalização
                                                        </td>
                                                        <td>
                                                            Pessoa jurídica ou física responsável pela digitalização
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            Titulo
                                                        </td>
                                                        <td>
                                                            Elemento de descrição que nomeia o documento. Pode ser
                                                            formal ou atribuido:
                                                            Formal: designação registrada no documento; atribuido:
                                                            designação providenciada para identicação de um documento
                                                            formalmente desprovidade titulo.
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            Tipo documental
                                                        </td>
                                                        <td>
                                                            Indica o tipo de documento, ou seja, a configuração da
                                                            espécie documental de acordo com a atividade que agerou.
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            Hash (chekcsum) da imagem
                                                        </td>
                                                        <td>
                                                            Algoritmo que mapeia uma sequência de bits (de um arquivo em
                                                            formato digital), com a finalidade de realizar a sua
                                                            verificaçãodeintegridade.
                                                        </td>
                                                    </tr>


                                                </tbody>
                                            </table>

                                            <p>
                                                Em resumo, recomenda-se:
                                            </p>

                                            <ul>
                                                <li>
                                                    Deverá ser aplicado no processo de digitalização o conceito de:
                                                    Digitalização Arquivística Documentos, isto é, a aplicação do
                                                    protocolo ICP-Brasil para assegurar os requisitos técnicos da
                                                    arquivística (integridade, autenticidade e confidencialidade), bem
                                                    como garantir a sua segurança jurídica.
                                                </li>

                                                <li>
                                                    Classificação e descrição dos documentos por meio de metadados
                                                    descritivos armazenados e disponíveis em repositórios digitais
                                                    confiáveis (guarda permanente e preservação digital a longo prazo)
                                                </li>
                                            </ul>

                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 4 -->
                <!-- <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Como realizar uma Digitalização Segura de Documentos? </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam
                                                reiciendis
                                                praesentium eum itaque, doloremque quas voluptas corrupti accusamus
                                                quia, ex adipisci ipsa? Odit sint, quae eligendi quos perspiciatis harum
                                                necessitatibus?
                                            </p>


                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div> -->

                <!-- pergunta 5 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Como solicitar uma Digitalização Segura?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                O colaborador deverá fazer seu login no Portal de Governança Documental,
                                                em seguida acessar o menu "Solicitações", selecionar a opção
                                                "Digitalização Segura de Documentos", preencher e enviar o formulário de
                                                solicitação.
                                            </p>


                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 6 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é Indexadores Documentais? Para que serve?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                Indexadores são atributos que referenciam e particularizam o conteúdo de
                                                cada tipo documental. A indexação tem por finalidade identificar os
                                                atributos dos conteúdos documentais, visando referenciá-los para uma
                                                melhor recuperação em plataformas de armazenamento e acesso aos
                                                documentos.Exemplos de indexadores/metadados: nº da nota fiscal; número
                                                do RE (Registro de Empregado); Data de produção do documento; etc).
                                            </p>


                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 7 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Onde posso armazenar os Documentos Digitalizados?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                A plataforma VIVODOCDIGITAL é o repositório oficial de guarda de
                                                documentos eletrônicos da Telefônica/Vivo Brasil.O acesso é realizado
                                                via credenciais de acesso liberado pelo time de Gestão Documental. Para
                                                solicitar o acesso deve enviar as seguintes informações: Nome Completo,
                                                E-mail, RE e Perfil. Enviar para caixa:
                                                gestao.documental.br@telefonica.com
                                                Para inserir os documentos da área no portal de documentos eletrônicos,
                                                o colaborador deve solicitar uma avaliação ao time de gestão documentos
                                                com as seguintes informações: Tipo documental, quantidade de
                                                páginas/volume, indexadores de busca daquele documento. Essas
                                                informações devem ser enviadas ao e-mail:
                                                gestao.documental.br@telefonica.com. A sua solicitação será avaliada e o
                                                time Gestão Documental irá agendar com você uma reunião para
                                                entendimento do processo.

                                            </p>


                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 8 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            <!-- O que é uma digitalização com uma Pré-Autenticação? -->
                                            O que é uma digitalização com uma Pré-Autenticação Digital em Cartório?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                A pré-autenticação digital em cartório em documentos digitalizados é uma
                                                forma de comprovar que a cópia é idêntica e fiel à versão original do
                                                documento. Esse método, além de garantir que o documento foi gerado por
                                                uma pessoa autorizada, também confirma a origem e a integridade de
                                                determinado arquivo.
                                            </p>


                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 9 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Como acessar os Documentos Digitalizados?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                Os documentos digitalizados ficaram acessíveis no Portal de Gestão
                                                Documental Eletrônico VIVODOCDIGITAL :
                                                <a
                                                    href="https://vivodocdigital.vivo.com.br/autenticacao/autenticacao/login">
                                                    https://vivodocdigital.vivo.com.br/autenticacao/autenticacao/login
                                                </a>

                                                O acesso é realizado via credenciais de acesso liberado pelo time de
                                                Gestão Documental.
                                            </p>


                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 9 att -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é o portal VIVODOCDIGITAL? O que faz?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                O portal VIVODOCDIGITAL é o repositório oficial de guarda de documentos
                                                eletrônicos da Telefônica.
                                            </p>


                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 10 -->
                <!-- <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que o portal VIVODOCDIGITAL? O que faz?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                O portal VIVODOCDIGITAL é o repositório oficial de guarda de
                                                documentos eletrônicos da Telefônica.
                                            </p>


                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div> -->

                <!-- pergunta 11 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Como solicitar o acesso ao VIVODOCDIGITAL?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">

                                            <p>
                                                Para solicitar o acesso deve enviar as seguintes informações:

                                                Nome Completo, E-mail, RE e Perfil. Enviar para caixa:
                                                <strong>
                                                    <a
                                                        href="mailto:gestao.documental.br@telefônica.com?subject=Olá,%20seja%20bem-vindo!">
                                                        gestao.documental.br@telefônica.com
                                                    </a>
                                                </strong>
                                            </p>




                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>


                <!-- pergunta 11 -->
                <!-- <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Como solicitar o acesso ao VIVODOCDIGITAL?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                Para solicitar o acesso deve enviar as seguintes informações:

                                                Nome Completo, E-mail, RE e Perfil. Enviar para caixa:
                                                <a
                                                    href="mailto:gestao.documental.br@telefônica.com?subject=Olá,%20seja%20bem-vindo!">
                                                    gestao.documental.br@telefônica.com
                                                </a>
                                            </p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div> -->

                <!-- pergunta 12 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Como inserir documentos no VIVODOCDIGITAL?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                Para inserir os documentos da area no portal de documentos
                                                eletrônicos, o colaborador deve solicitar uma avaliação ao time de
                                                gestão documentos com as seguintes informações: Tipo documental,
                                                quantidade de páginas/volume, indexadores de busca daquele documento.

                                                Essas informações devem ser enviadas ao e-mail:
                                                <a
                                                    href="mailto:gestao.documental.br@telefônica.com?subject=Olá,%20seja%20bem-vindo!">
                                                    gestao.documental.br@telefonica.com
                                                </a> sua solicitação será avaliada e o
                                                time responsável ira agendar com você uma reunião para entendimento do
                                                processo.

                                            </p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>



            </div>

            <!-- Transformação Digital (Paperless) -->
            <div class="tab-pane fade" id="list-transformacao-digital" role="tabpanel"
                aria-labelledby="list-transformacao-digital-list">


                <!-- pergunta 1 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é Transformação Digital?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                "Digitalizar para aproximar" é um dos princípios estratégicos da
                                                Telefônica/ Vivo Brasil. A digitização já existia na companhia, porém o
                                                advento da pandemia vem acelerando ainda mais a necessidade de
                                                institucionalizar tecnologias, técnicas e metodologias inovadoras para
                                                estimular a agilidade, criatividade e inovação com foco em oferecer a
                                                melhor experiência para nossos clientes e colaboradores. Nesse sentido a
                                                Transformação Digital é abordada pela Telefônica/Vivo Brasil com o
                                                propósito de solucionar problemas de desempenho, produtividade,
                                                agilidade e eficácia de seus processos de negócios.
                                                A Transformação Digital aplicada no cenário de Gestão Documental tem por
                                                finalidade instituir o "escritório papel zero", onde o papel será
                                                eliminado e substancialmente reduzido. Veja a seguir alguns projetos de
                                                Transformação Digital/Paperless da Telefônica/Vivo Brasil:
                                            </p>

                                            <ul>
                                                <li>
                                                    <strong>Digitalização do acervo legado</strong>
                                                    <ul>
                                                        <li>
                                                            Digitalização com protocolo ICP Brasil
                                                        </li>
                                                    </ul>
                                                </li>

                                                <li>
                                                    <strong>Plataforma de Assinatura Digital</strong>
                                                    <ul>
                                                        <li>
                                                            Assinatura digital com protocolos A1 e A3
                                                        </li>
                                                        <li>
                                                            Assinatura com aceite eletrônico
                                                        </li>
                                                        <li>
                                                            Assinatura com BlockChain ID
                                                        </li>
                                                        <li>
                                                            Assinatura com Token
                                                        </li>
                                                        <li>
                                                            TimesTamp
                                                        </li>
                                                    </ul>
                                                </li>

                                                <li>
                                                    <strong>Plataforma de Serviços Notariais Digitais</strong>
                                                    <ul>
                                                        <li>
                                                            Reconhecimento de firma da assinatura digital
                                                        </li>
                                                        <li>
                                                            Reconhecimento de firma da assinatura digital cadastrada no
                                                            cartório
                                                        </li>
                                                        <li>
                                                            Autenticação digital
                                                        </li>
                                                        <li>
                                                            Autenticação de um documento digitalizado pelo usuário
                                                        </li>
                                                    </ul>
                                                </li>

                                                <li>
                                                    <strong>Mapeamento de processos documentais para transformação
                                                        digital dos
                                                        processos de negócios</strong>
                                                </li>
                                                <li>
                                                    <strong> Plataforma de Repositório Institucional centralizado para
                                                        armazenamento e acesso a documentos digitalizados e nato
                                                        digitais</strong>
                                                </li>
                                            </ul>






                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 2 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            <!-- Como posso solicitar uma avaliação para realizar a transformação digital nos
                                            meus processos? -->
                                            Como posso solicitar uma avaliação para realizar a transformação digital
                                            dos processos da minha área de negócios?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                Caso você identifique na sua área oportunidades de melhorias e
                                                transformação digital de seus processos/atividades e documentos, visando
                                                na diminuição de erros, insegurança, não efetividade, erros, desperdício
                                                de tempo e dinheiro. Acesse o Portal e escolha as seguintes opções a
                                                seguir:
                                            </p>
                                            <ul>
                                                <li>
                                                    Solicitações (Menu);
                                                    <ul>
                                                        <li>
                                                            Assinatura digital de documentos
                                                        </li>
                                                    </ul>
                                                </li>

                                                <li>
                                                    Em seguida preencha o formulário que será enviado automaticamente
                                                    para
                                                    a equipe da área de Gestão Documental.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>


                <!-- pergunta 3 Att -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é o Portal de Assinatura Digital?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                A Plataforma de Assinaturas Digitais será integrado ao Portal de
                                                Governança Documental da Telefônica/Vivo Brasil, assim oportunizará ao
                                                usuário interagir com as funcionalidades da Plataforma de Assinaturas
                                                Digitais.

                                                <br><br>

                                                O Plataforma utiliza como padrão para assinatura digital o Certificado
                                                Digital ICP Brasil para comprovar a autoria da assinatura. A assinatura
                                                digital assegura autenticidade, integridade e não repúdio (signatário
                                                não tem como negar sua autoria).

                                                <br><br>

                                                O Certificado Digital é a identidade digital da pessoa física e jurídica
                                                no meio eletrônico. É validado presencialmente, não permitindo fraudes.

                                                <br><br>

                                                Se você necessita enviar/transacionar documentos interna e para fora de
                                                sua rede, é fundamental que haja confiança pública para que a assinatura
                                                seja automaticamente aceita pelos diferentes órgãos e empresas.

                                                <br><br>

                                                É possível assinar qualquer conteúdo ou documento eletrônico, seja qual
                                                for a sua finalidade. Veja alguns exemplos comuns: contratos de alto
                                                valor, perícias, laudos, procurações, aceites, documentos enviados ao
                                                Poder Judiciário, Receita Federal, DETRAN, ANATEL e outros documentos
                                                ligados ao governo. É juridicamente válida, pois atende as normas da MP
                                                n° 2.200-2.
                                            </p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>


















                <!-- pergunta 3 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            <!-- Como posso automatizar os meus processos? -->
                                            Como automatizar os meus processos?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                Caso identifique oportunidades para otimizar a performance dos
                                                processos, fluxos e atividades da sua área que visem a transformação
                                                digital (digitalização; assinatura digital). O colaborador deverá fazer
                                                seu login no Portal de Governança Documental, em seguida acessar o menu
                                                "Solicitações", selecionar a opção "Transformação Digital", preencher e
                                                enviar o formulário de solicitação.
                                            </p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 4 -->
                <!-- <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é o Portal de Assinaturas Eletrônicas?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum, amet!
                                                Nobis, accusantium aperiam alias cupiditate iusto ipsum eum, sapiente et
                                                sint accusamus rem, in totam ipsam. Fugiat cum accusantium minus?
                                            </p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div> -->

                <!-- pergunta 5 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Como incluir e assinar meus documentos no Portal de Assinatura Eletrônica?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                Acesse o Portal e escolha as seguintes opções a seguir:
                                            </p>

                                            <ul>
                                                <li>
                                                    Solicitações (Menu);
                                                </li>
                                                <ul>
                                                    <li>
                                                        Assinatura digital de documentos
                                                    </li>
                                                </ul>
                                                <li>
                                                    Em seguida será redirecionado para o ambiente da Plataforma de
                                                    Assinatura Digital, onde será apresentado as principais
                                                    funcionalidade para realização de assinaturas digitais.
                                                </li>
                                                <li>
                                                    Acesse <a href="#"><strong>aqui o vídeo e tutorial</strong></a> da
                                                    Plataforma de
                                                    Assinatura Digital
                                                    para auxilia
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 6 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é um Aceite Eletrônico?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                Aceite eletrônico é um tipo de assinatura digital indicado para
                                                formalizar qualquer ato digital que necessite de concordância da outra
                                                parte, como por exemplos: o aceite de uma ordem de serviço ou de um
                                                termo de uso, a aprovação de um ordem de compra, requisição de pessoal,
                                                orçamento ou proposta comercial, ou a confirmação de um pedido.
                                                Geralmente nesse tipo de assinatura , são assinados documentos contendo
                                                "teor administrativo" para qualquer tipo de aprovação, aceite ou
                                                autorização eletrônica e digital.
                                            </p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 6 att -->
                <!-- <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Qual a diferença entre assinatura digital e eletrônica?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                Na prática, quando um documento é assinado digital, significa que esta
                                                assinatura utiliza o Certificado padrão ICP-Brasil, eliminando assim a
                                                necessidade de reconhecer assinatura em cartório, imprimir documentos,
                                                etc. Quando alguém utiliza a assinatura digital uma série de mecanismos
                                                confirmam sua autenticidade, integridade, não repúdio ou irretrabilidade
                                                através do certificado. Assim, o documento adquire validade jurídica.
                                                Quando se utiliza o padrão protocolo ICP-Brasil para assinatura digital,
                                                significa que ela é criptografada e necessita de um certificado digital
                                                emitido por autoridade certificadora, por exemplo, ICP-Brasil, como se
                                                vê no caso da emissão de Nota Fiscal Eletrônica - Nf-e.

                                                <br><br>

                                                Já a assinatura eletrônica é a mais comum, utilizada para acessar,
                                                compartilhar e aprovar informações em meio digital no dia a dia.Qualquer
                                                pessoa, pode assinar qualquer tipo de documento eletronicamente de modo
                                                rápido, fácil e seguro. Alguns tipos de assinaturas eletrônicas:
                                                biometria, senha, token, assinaturas escaneadas, etc.
                                            </p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div> -->

                <!-- pergunta 7 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Onde solicito um certificado A1 (ICP BRASIL) para realizar assinatura com
                                            certificado digital?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                Para obter um certificado digital, o primeiro passo é escolher uma
                                                autoridade certificadora (AC), que funciona quase como um “cartório”
                                                digital. Há várias delas no mercado, todas subordinadas ao ICP-Brasil,
                                                serviço público criado em 2001, que monitora e regulamenta a emissão
                                                desses certificados no Brasil.
                                            </p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 8 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é um Certificado Digital ICP BRASIL?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                É um arquivo eletrônico que funciona como se fosse uma assinatura
                                                digital, com validade jurídica, e que garante proteção às transações
                                                eletrônicas e outros serviços via internet, de maneira que pessoas
                                                (físicas e jurídicas) se identifiquem e assinem digitalmente, de
                                                qualquer lugar do mundo, com mais segurança e agilidade.



                                                É uma tecnologia desenvolvida especificamente para oferecer segurança,
                                                autenticidade, confidencialidade e integridade às informações
                                                eletrônicas transacionadas entre as partes. Na prática o Certificado
                                                Digital ICP-Brasil é como se fosse uma carteira de identidade eletrônica
                                                que garante a identidade das partes envolvidas.

                                                É importante salientar que a certificação digital existe no Brasil desde
                                                2001, a partir da criação da Infraestrutura de Chaves Públicas
                                                Brasileira – ICP Brasil. Ela é um conjunto de normas, padrões técnicos e
                                                regulamentos sobre o sistema criptográfico que permite a emissão e
                                                utilização de certificados digitais no país. Também é a responsável por
                                                conferir poder às Autoridades Certificadoras, entidades responsáveis
                                                pela emissão comercial de todos os tipos de certificados digitais.

                                                Por fim, destacamos aqui as principais atividades que podem ser
                                                seguradas pro um Certificado Digital são:
                                            </p>

                                            <ul>
                                                <li>
                                                    Assinatura e envio documentos pela internet;
                                                </li>
                                                <li>
                                                    Realização de transações bancárias;
                                                </li>
                                                <li>
                                                    Envio de declarações da sua empresa;
                                                </li>
                                                <li>
                                                    Assinatura de notas fiscais, conhecimentos de transporte eletrônico
                                                    (CTs-e) e manifestações do destinatário;
                                                </li>
                                                <li>
                                                    Realização de transações bancárias;
                                                </li>
                                                <li>
                                                    “Login” em ambientes virtuais com segurança;
                                                </li>
                                            </ul>

                                            <p>
                                                Quais são os principais benefícios?
                                            </p>

                                            <ul>
                                                <li>
                                                    Garantia da validade jurídica dos documentos eletrônicos;
                                                </li>
                                                <li>
                                                    Desburocratização de processos uma vez que dispensa reconhecimento
                                                    de firmas;
                                                </li>
                                            </ul>

                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>


                <!-- pergunta 8 att -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            <!-- Quais os principais tipos de certificados: -->
                                            Quais os principais tipos de certificação digital?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                Existem diversos tipos de certificados digitais, classificados de acordo
                                                quanto a sua aplicação ou níveis de segurança criptográfica. Com eles, é
                                                possível assinar digitalmente documentos, garantir o sigilo de seu
                                                conteúdo e proteger informações sigilosas na internet. Cada um deles tem
                                                um uso indicado para determinados tipos de atividade adequado às suas
                                                demandas, de acordo com a classificação da ICP-Brasil. A ICP Brasil
                                                classifica os tipos de certificados digitais quanto duas características
                                                principais: sua aplicação e suas características de segurança. Primeiro,
                                                vamos entender cada tipo de acordo com suas aplicações práticas. Elas
                                                são três: Assinatura digital, sigilo ou confidencialidade e Carimbo do
                                                tempo. Começaremos falando sobre o mais popular, a assinatura digital:
                                            </p>

                                            <ul>
                                                <li>
                                                    <strong>Certificado tipo A – Assinatura digital</strong> - É o tipo
                                                    de certificado
                                                    mais utilizado, que serve para realizar assinaturas digitais em
                                                    todos os tipos de documentos. Tem como função identificar o
                                                    assinante, atestar a autenticidade da operação e confirmar a
                                                    integridade do documento assinado.
                                                </li>
                                                <li>
                                                    <strong>Certificado tipo S – Sigilo/Confidencialidade</strong> - O
                                                    certificado
                                                    digital de tipo S é utilizado somente para proporcionar sigilo à
                                                    transação. Com ele, é possível criptografar os dados de um
                                                    documento, que passa a ser acessível somente com a utilização de um
                                                    certificado digital autorizado para abrir o arquivo. Dessa forma, um
                                                    documento de conteúdo sigiloso torna-se inacessível à pessoas não
                                                    autorizadas, tornando-se muito mais seguro. É usado para o envio ou
                                                    armazenamento de arquivos de forma segura. Na prática visa proteger
                                                    digitalmente informações de valor.

                                                </li>
                                                <li>
                                                    <strong>Certificado tipo T - Carimbo do tempo - O certificado
                                                        digital do
                                                        tipo T</strong> é mais conhecido como carimbo do tempo, ou
                                                    timestamp. O
                                                    carimbo de tempo é um documento eletrônico emitido por uma parte
                                                    confiável, que serve como evidência de que uma informação digital
                                                    existia numa determinada data e hora no passado. O carimbo do tempo
                                                    busca a informação de data e hora de uma terceira parte segura, que
                                                    é uma fonte confiável para atestar corretamente estas informações.
                                                    Ele é muito importante para validar não só o documento, mas também
                                                    sua data e hora, uma vez que esses dados podem ser facilmente
                                                    adulterados. É utilizado em conjunto com os outros tipos de
                                                    certificados digitais e é essencial para garantir a temporalidade e
                                                    a tempestividade de documentos importantes.
                                                </li>
                                            </ul>

                                            <p>
                                                Também é possível classificar os tipos de de certificado digital de
                                                acordo com seus níveis de segurança criptográfica. A ICP Brasil os
                                                agrupa em dois grupos principais:
                                            </p>

                                            <ul>
                                                <li>
                                                    O <strong>A1</strong>, de menor segurança, e as variações
                                                    <strong>A3/S3/T3</strong>, com criptografia
                                                    mais complexa e, por isso, maior proteção.
                                                </li>
                                                <li>
                                                    <strong>Certificados A1</strong> - São certificados digitais de
                                                    menor segurança.
                                                    Utilizam chaves de 1024 bits, geradas por um software armazenado no
                                                    computador do usuário, acessível por login e senha. Têm validade de
                                                    um ano. A maior diferença prática é que eles precisam estar
                                                    armazenados em um computador, portanto, não tem mobilidade. O
                                                    usuário precisa estar junto do computador onde o certificado foi
                                                    instalado para poder assinar digitalmente seus documentos.
                                                </li>
                                                <li>
                                                    <strong>Certificados A3/S3/T3</strong> - São certificados com níveis
                                                    mais altos de
                                                    criptografia de proteção das informações, e portanto, de maior
                                                    segurança.O A3 se refere aos certificados de assinatura digital, o
                                                    S3 aos de sigilo e confidencialidade e o T3 aos de carimbo do tempo.
                                                    Podem ter validade de até cinco anos, e utilizam chaves de 2048 bits
                                                    geradas pelo smart card ou token que armazena o certificado. A maior
                                                    diferença entre os certificados de final 3 para o A1, além da maior
                                                    validade e segurança, é a possibilidade de serem armazenados em
                                                    dispositivos criptográficos móveis, como smartcards ou tokens. Eles
                                                    também podem permanecer em uma nuvem e serem acessados pelo usuário
                                                    de qualquer lugar.
                                                </li>
                                            </ul>

                                            <p>
                                                O A3 é o tipo de certificado mais utilizado pelos usuários finais, já
                                                que garante mais segurança e tem prazo de validade maior para qualquer
                                                tipo de documento.

                                                <br><br>

                                                Para obter seu certificado digital, busque uma das autoridades
                                                certificadoras credenciadas (AC) pela Infraestrutura de Chaves Públicas
                                                Brasileira. A lista completa de ACs e informações complementares sobre
                                                compra de certificados digitais pode ser acessada no site do ICP-Brasil.
                                            </p>

                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>




                <!-- pergunta 8.1 att -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é uma Assinatura Digital ou Digitalizada?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                Na prática, quando um documento é assinado digital, significa que esta
                                                assinatura utiliza o Certificado padrão ICP-Brasil, eliminando assim a
                                                necessidade de reconhecer assinatura em cartório, imprimir documentos,
                                                etc. Quando alguém utiliza a assinatura digital uma série de mecanismos
                                                confirmam sua autenticidade, integridade, não repúdio ou irretrabilidade
                                                através do certificado. Assim, o documento adquire validade jurídica.
                                                Quando se utiliza o padrão protocolo ICP-Brasil para assinatura digital,
                                                significa que ela é criptografada e necessita de um certificado digital
                                                emitido por autoridade certificadora, por exemplo, ICP-Brasil.
                                            </p>

                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>









































                <!-- pergunta 9 -->
                <!-- <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é uma Assinatura Eletrônica ou Digitalizada?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum, amet!
                                                Nobis, accusantium aperiam alias cupiditate iusto ipsum eum, sapiente et
                                                sint accusamus rem, in totam ipsam. Fugiat cum accusantium minus?
                                            </p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div> -->

                <!-- pergunta 10 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é um Timestamp? E onde é aplicado?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                Timestamp é mais conhecido como carimbo do tempo. O carimbo de tempo é
                                                um documento eletrônico emitido por uma parte confiável, que serve como
                                                evidência de que uma informação digital existia numa determinada data e
                                                hora no passado. O carimbo do tempo busca a informação de data e hora de
                                                uma terceira parte segura, que é uma fonte confiável para atestar
                                                corretamente estas informações. Ele é muito importante para validar não
                                                só o documento, mas também sua data e hora, uma vez que esses dados
                                                podem ser facilmente adulterados. É utilizado em conjunto com os outros
                                                tipos de certificados digitais e é essencial para garantir a
                                                temporalidade e a tempestividade de documentos importantes.
                                            </p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 11 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é uma assinatura BlockChain ID?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                Trata-se de um sistema constituído numa blockchain que armazenaria dados
                                                de forma criptografada e descentralizada. A partir daí, os usuários
                                                decidem se dão acesso a esses dados para terceiros – empresas e governos
                                                – usando chaves criptográficas. E já existem diversos casos de uso para
                                                essa tecnologia e crescimento significativo de sua adoção nos setores
                                                público e privado. Na pratica é uma identidade, que permite aos usuários
                                                acessarem sites sem a necessidade de formulários de cadastro ou login
                                                com usuário e senha. É um sistema avançado de identificação digital que
                                                pode viabilizar transações para comprovar a identidade ao fazer login em
                                                bancos, assinaturas digitais, votação, registros médicos e outros usos.
                                            </p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 12 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é uma assinatura com token?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                Em termos gerais, tokens são dispositivos físicos, semelhantes a um
                                                pendrive, que auxiliam o usuário quanto à segurança do Certificado
                                                Digital. Este certificado, pessoal e intransferível, pode ser utilizado
                                                para assinatura digital de documentos, acesso a sistemas, entre outras
                                                coisas. O token serve como conexão do Certificado Digital com o
                                                computador, extinguindo a necessidade de uma Leitora.

                                                <br><br>

                                                porém, enquanto o pen drive armazena vários tipos de arquivos como
                                                fotos, músicas e vídeos, o Token, de maneira segura e inviolável,
                                                armazena somente o Certificado Digital, protegendo as informações
                                                salvas, tornando mais confiável o uso do Certificado Digital online.
                                                Desenvolvido para armazenar o Certificado Digital de forma segura e
                                                inviolável, o Token pode ser utilizado em assinatura digital de
                                                documentos eletrônicos; acesso seguro a sistemas e aplicativos; proteção
                                                do conteúdo de e-mails enviados; autenticação de Notas Fiscais
                                                Eletrônicas - NF-e, acesso a Conectividade Social, CT-E, entre outros.
                                            </p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 13 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é BlockChain?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                A tecnologia blockchain, também conhecido como “o protocolo da
                                                confiança" é uma tecnologia de registro distribuído que visa a
                                                descentralização como medida de segurança. O blockchain é uma rede que
                                                funciona com blocos encadeados muito seguros que sempre carregam um
                                                conteúdo junto a uma impressão digital. Ele armazena essas informações,
                                                esse conjunto de transações, em blocos carimbando cada bloco com um
                                                registro de tempo e data. A cada período de tempo (10 minutos no
                                                blockchain), é formado um novo bloco de transações, que se liga ao bloco
                                                anterior. Os blocos são dependem um dos outros e formam uma cadeia de
                                                blocos. Isso torna a tecnologia perfeita para registro de informações
                                                que necessitam de confiança. Na prática a tecnologia blockchain também
                                                pode ser utilizada para arquivamento de documentos eletrônicos e
                                                negócios probatórios.
                                            </p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 14 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é um workflow de processo?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                Fluxo de trabalho, em português, workflow é um conjunto de tecnologias e
                                                ferramentas que permitem que documentos, informações, atividades e
                                                tarefas fluam adequadamente pela empresa ou departamento de uma empresa
                                                para que os envolvidos no processo sigam sua sequência e pratiquem
                                                determinados procedimentos de forma que os objetivos do processo sejam
                                                alcançados de forma adequada. O workflow de processo é uma consequência
                                                da existência de processos, foi criado em função deles, portanto, é
                                                possível existir um processo sem workflow, mas não poderá existir um
                                                workflow sem o processo correspondente.
                                            </p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>






            </div>

            <!-- Serviços Cartoriais -->
            <div class="tab-pane fade" id="list-servicos-cartoriais" role="tabpanel"
                aria-labelledby="list-servicos-cartoriais-list">


                <!-- pergunta 1 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é Reconhecimento de Firma?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                Após assinar um contrato, geralmente temos que fazer o chamado
                                                “reconhecimento da firma” em cartório, que tem como objetivo atestar que
                                                a assinatura feita no documento foi de fato realizada por quem diz ser o
                                                assinante. No caso da assinatura digital, acontece a mesma coisa, mas
                                                sem deslocamentos.
                                            </p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 2 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Como posso solicitar esse serviço?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                O colaborador deverá fazer seu login no Portal de Governança Documental,
                                                em seguida acessar o menu "Solicitações", selecionar a opção
                                                "Reconhecimento de Firmas", preencher e enviar o formulário de
                                                solicitação.
                                            </p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 3 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é Autenticação Digital?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                A autenticação digital é usada como forma de se referir ao processo
                                                de autenticação que confirma ou certifica a identidade da pessoa que
                                                elaborou o documento. Esse método, além de garantir que o documento foi
                                                gerado por uma pessoa autorizada, também confirma a origem e a
                                                integridade de determinado arquivo.
                                            </p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 4 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Como solicitar o serviço de Autenticação Digital?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>

                                                O colaborador deverá fazer seu login no Portal de Governança Documental,
                                                em seguida acessar o menu "Solicitações", selecionar a opção
                                                "Autenticação Digital de Documentos", preencher e enviar o formulário de
                                                solicitação.
                                            </p>

                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 5 -->
                <!-- <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é Ata Notarial?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum, amet!
                                                Nobis, accusantium aperiam alias cupiditate iusto ipsum eum, sapiente et
                                                sint accusamus rem, in totam ipsam. Fugiat cum accusantium minus?
                                            </p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div> -->

                <!-- pergunta 6 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Como solicitar um serviço de Ata Notarial?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                O colaborador deverá fazer seu login no Portal de Governança Documental,
                                                em seguida acessar o menu "Solicitações", selecionar a opção "Serviços
                                                Cartoriaisl", preencher e enviar o formulário de solicitação.
                                            </p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 7 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            O que é ata Notarial?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                Conceituamos a ata notarial como instrumento público através do qual o
                                                tabelião ou seu preposto – a pedido de pessoa interessada ou por quem a
                                                ela represente – autentica em forma narrativa os fatos, se estado, e
                                                tudo aquilo que atesta por seus próprios sentidos sem a emissão de
                                                opinião, juízo de valor ou conclusão, portando por fé (pública) que tudo
                                                aquilo presenciado e relatado representa a verdade com consignação nos
                                                livros de notas. As espécies da ata notarial divide-se em três grandes
                                                condutores: i) as declarativas; ii) as diligenciais e iii) as
                                                eletrônicas.
                                            </p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>



            </div>

            <!-- Nossos Fornecedores de Gestão Documental -->
            <div class="tab-pane fade" id="list-fornecedores-gestao-documental" role="tabpanel"
                aria-labelledby="list-fornecedores-gestao-documental-list">


                <!-- pergunta 1 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Quem é a SOLUARQ? O que faz?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                <!-- adicionar svg coração -->
                                                <img src="../../assets/svgs/faq/001-cardiogram.svg"
                                                    class="img-fluid svg-faq" alt="">
                                                <br>
                                                "Somos apaixonados e motivados em transformar documentos em
                                                informações!"
                                            </p>
                                            <hr>
                                            <p>
                                                <!-- adicionar alvo com flecha -->
                                                <img src="../../assets/svgs/faq/002-goal.svg" class="img-fluid svg-faq"
                                                    alt="">
                                                <br>
                                                <strong> Nossa missão</strong>

                                                Promover a cultura da Governança Documental nas empresas, por meio de
                                                ações de Transformação Digital de seus negócios de forma simples,
                                                transparente e sustentável.
                                            </p>
                                            <hr>
                                            <p>
                                                <!-- adicionar calendario -->
                                                <img src="../../assets/svgs/faq/003-calendar.svg"
                                                    class="img-fluid svg-faq" alt="">
                                                <br>
                                                + de 11 anos de mercado
                                            </p>
                                            <hr>
                                            <p>
                                                <!-- adicionar lampada citilando -->
                                                <img src="../../assets/svgs/faq/004-light-bulb.svg"
                                                    class="img-fluid svg-faq" alt="">
                                                <br>
                                                Pionerismo na construção de boas práticas de Gestão Documental

                                                <br><br>

                                                Através da Inovação, transparência e excelência construimos relações de
                                                confiança com nossos clientes, fornecendo uma visão 360º em todas as
                                                etapas da governança documental: desde a consultoria à implementação,
                                                trabalhamos para integrar pessoas, processos e tecnologias.
                                            </p>
                                            <hr>
                                            <p>
                                                <!-- adicionar mão ao redor do cerebro -->
                                                <img src="../../assets/svgs/faq/005-intellectual.svg"
                                                    class="img-fluid svg-faq" alt="">
                                                <br>
                                                O que fazemos: Promovemos boas práticas de Governança Documental
                                            </p>
                                            <ul>
                                                <li>
                                                    Gestão Documental
                                                </li>
                                                <li>
                                                    Transformação Digital
                                                </li>
                                                <li>
                                                    Gestão de mudança
                                                </li>
                                            </ul>
                                            <hr>
                                            <p>
                                                <!-- adicionar medalha -->
                                                <img src="../../assets/svgs/faq/006-medal.svg" class="img-fluid svg-faq"
                                                    alt="">
                                                <br>
                                                Diferenciais
                                            </p>
                                            <img src="../../assets/imagens/faq-diferenciais.png" class="img-fluid"
                                                alt="diferenciais">
                                            <hr>
                                            <p>
                                                <!-- adicionar engrenagens -->
                                                <img src="../../assets/svgs/faq/007-cogs.svg" class="img-fluid svg-faq"
                                                    alt="">
                                                <br>
                                                Abordagem metodológica E2E
                                                <br>
                                                Metodologia sólida e efetiva de Governança Documental e Transformação
                                                Digital
                                                <br><br>

                                                Os pilares que sustentam a diferenciada metodologia de Governança
                                                Documental da Portal de Comissionamento para ajudar nossos clientes a
                                                efetivarem a
                                                transformação digital de seus negócios, ancora-se numa visão integrada
                                                (360º) para conceber, desenvolver e implementar cultura e boas práticas
                                                de gestão documental, considerando a inter-relação de seis dimensões em
                                                nossos projetos:
                                            </p>

                                            <ul>
                                                <li>
                                                    Primeira dimensão, <strong>o alinhamento estratégico;</strong>
                                                </li>
                                                <li>
                                                    Segunda dimensão, <strong>a governança;</strong>
                                                </li>
                                                <li>
                                                    Terceira dimensão, <strong>os métodos:</strong>
                                                </li>
                                                <li>
                                                    Quarta dimensão,<strong> as Tecnologias de Informação;</strong>
                                                </li>
                                                <li>
                                                    Quinta dimensão, <strong> as pessoas;</strong>
                                                </li>
                                                <li>
                                                    Quinta dimensão, <strong>os processos;</strong>
                                                </li>
                                                <li>
                                                    Sétima dimensão,<strong> a cultura organizal.</strong>
                                                </li>
                                            </ul>
                                            <hr>
                                            <p>
                                                <!-- adicionar lampada citilando -->
                                                <img src="../../assets/svgs/faq/004-light-bulb.svg"
                                                    class="img-fluid svg-faq" alt="">
                                                <br>
                                                Principais Soluções
                                            </p>

                                            <p>
                                                <strong>Gestão Documental</strong>
                                            </p>

                                            <ul>
                                                <li>
                                                    Diagnóstico Estratégico em Governança Documental
                                                </li>
                                                <li>
                                                    Política de Gestão Documental
                                                </li>
                                                <li>
                                                    Plano de Classificação de Documentos - PCD
                                                </li>
                                                <li>
                                                    Tabela de Temporalidade de Documentos - TTD
                                                </li>
                                                <li>
                                                    Matriz de Gestão Documental
                                                </li>
                                                <li>
                                                    Planejamento e implementação de Requisitos de metadados de
                                                    negócios/arquivísticos
                                                </li>
                                                <li>
                                                    Parametrização de sistemas de negócios GED/ECM
                                                </li>
                                                <li>
                                                    Sistema Informatizado de Gestão Arquivística de Documentos - SIGAD
                                                </li>

                                                <li>
                                                    Política de Preservação Digital a longo prazo
                                                </li>
                                                <li>
                                                    Implementação de Repositórios Arquivísticos Digitais Confiáveis -
                                                    RDC-Arq
                                                </li>
                                            </ul>






                                            <p>
                                                <strong>Transformação Digital</strong>
                                            </p>

                                            <ul>
                                                <li>
                                                    Elaboração e implementação de planos para transformação digital de
                                                    serviços e processos de negócios (digitatização)
                                                </li>
                                                <li>
                                                    Digitalização Segura de Documentos com Protocolo ICP-Brasil
                                                </li>
                                                <li>
                                                    Plataformas de Assinatura digital
                                                </li>
                                                <li>
                                                    Translação com fé pública – autenticação digital e reconhecimento de
                                                    firmas digital
                                                </li>
                                                <li>
                                                    Blockchain
                                                </li>
                                                <li>
                                                    Plataformas digitais para otimização e racionalização de processos
                                                    de
                                                    negócios - Workflow
                                                </li>
                                                <li>
                                                    GED/ECM
                                                </li>
                                                <li>
                                                    Implementação de Serviços e ambientes de Custódia Digital Confiável
                                                    de
                                                    Documentos
                                                </li>

                                                <li>
                                                    Portal de Governança Documental
                                                </li>
                                            </ul>












                                            <p>
                                                <strong>Gestão de Mudança em Governança Documental</strong>
                                            </p>

                                            <ul>
                                                <li>
                                                    Palestras e oficinas em gestão documental
                                                </li>
                                                <li>
                                                    Treinamento e capacitação de pessoal in company
                                                </li>
                                                <li>
                                                    Documento Day – Dia do Documento (evento educativo para
                                                    sensibilização e
                                                    conscientização)
                                                </li>
                                            </ul>



                                            <p>
                                                Conheça nosso website:
                                                <strong><a href="http://www.soluarq.com.br">
                                                        http://www.soluarq.com.br
                                                    </a></strong>
                                            </p>

                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 2 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Quem é a IRON MOUNTAIN? O que faz?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                A Iron Mountain é a empresa responsável pelo armazenamento das caixas em
                                                guarda externa. Os documentos são acondicionados pelo usuário nas caixas
                                                da Iron Mountain e são coletadas e enviadas para o galpão com alta
                                                segurança. Todo o acervo fica acondicionado nas caixas que eles
                                                gerenciam, e mediante solicitações, eles disponibilizam aos usuários as
                                                caixas, após a solicitação interna na Telefônica.
                                            </p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <!-- pergunta 3 -->
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="mpt">
                                            Quem é BRSCAN? O que faz?
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>
                                                A BrScan Tecnologia desenvolve ferramentas eficientes e cria soluções de
                                                gestão inteligentes desde 2004. A empresa trabalha com soluções de
                                                gestão de risco documental, de informações, de segurança e de processos
                                                organizacionais.

                                                <br><br>

                                                Desafios pedem soluções criativas e inovadoras. A BrScan, através de
                                                suas soluções inteligentes, previne prejuízos para seus clientes,
                                                proporcionando-lhes segurança em seus negócios. A empresa participa da
                                                rotina de seus clientes, com foco na objetividade das ações,
                                                contribuindo com sua experiência por meio de estratégias adequadas a
                                                cada caso, é dinâmica e tem a criatividade necessária para atender às
                                                demandas financeiras, técnicas e de gestão.

                                                <br><br>

                                                <strong>O que faz?</strong>

                                                Resposta. A BrScan atende a Telefónica desde 2007 oferecendo serviços e
                                                sistemas para gestão de documentos, workflows de aprovações de
                                                documentos, fluxos de assinatura eletrônica e aceite digital, análise de
                                                antifraude documental e biometria facial nos processos de prevenção e
                                                detecção, desenvolvimento de sistemas e integrações com sistemas
                                                legados, etc.

                                                <br><br>

                                                Os sistemas e serviços fornecidos atendem 100% dos canais presenciais de
                                                operações comerciais, e auxilia na gestão de toda cadeia de documentos
                                                administrativos proporcionando e viabilizando a evolução dos projetos de
                                                transformação digital e PaperLess.
                                            </p>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>
                    </div>
                </div>


                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <video controlsList="nodownload" oncontextmenu="return false;"
                                class="img-fluid video-soluarq" controls preload="auto" loop #myVideo tabindex="0">
                                <source src="../../assets/vídeo/soluarq.mp4" type="video/mp4">
                                <object data="">
                                    <embed src="soluarq.mp4">
                                </object>
                            </video>
                        </div>
                    </div>
                </div>




            </div>
        </div>
    </div>
</div>