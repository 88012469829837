import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Origem } from '../_models/origem';

@Injectable({
  providedIn: 'root'
})
export class OrigemService {

  constructor(private http: HttpClient) { }


  getAllOrigem(empresaId, page, count,sort,order) {
    return this.http.get<any>(`${environment.apiUrl}/origem/search/${empresaId}/${page}/${count}?sort=${sort}&order=${order}`).pipe(tap(console.log));
  }

  insertOrigem(origem: Origem){
    return this.http.post<any>(`${environment.apiUrl}/origem`, origem);
  }
  editOrigem(origem: Origem){
    return this.http.put<any>(`${environment.apiUrl}/origem`, origem);
  }
  deleteOrigem(origem: Origem){
    return this.http.delete<any>(`${environment.apiUrl}/origem/${origem.id}`, origem.id);
  }
}
