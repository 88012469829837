import { first, map, startWith, tap } from 'rxjs/operators';
import { LocalGuarda } from 'src/app/_models/local-guarda';
import { AlertService } from 'src/app/_services';
import { CartonagemService } from 'src/app/_services/cartonagem.service';
import { GrupoEmpresaService } from 'src/app/_services/grupo-empresa.service';
import { LocalGuardaService } from 'src/app/_services/local-guarda.service';

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-locais-guarda',
  templateUrl: './locais-guarda.component.html',
  styleUrls: ['./locais-guarda.component.css']
})
export class LocaisGuardaComponent implements OnInit {


  // select
  // porEmpresas: string[] = [
  //   'Empresa X', 'Empresa Y', 'Empresa Z'
  // ];
  porEmpresas2: string[] = [
    'Empresa X', 'Empresa Y', 'Empresa Z'
  ];
  caixas: string[] = [
    'Caixa 5KG', 'Caixa 10KG', 'Caixa 2KG', 'DEMO'
  ];


  formEmpresaValue = "";

  selectedPorEmpresa: any;
  selectedPorEmpresa2: any;
  selectedCaixa: any;
  // end select



  pedidos: any[] = [];
  empresas: any[] = [];
  empresaId: number = 0;
  formEmpresa: FormGroup;
  formLocalGuarda: FormGroup;
  formEditLocalGuarda: FormGroup;
  localGuarda: LocalGuarda = new LocalGuarda;
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['id', 'descricao', 'identificador' , 'criadoEm', 'ativo', 'acoes'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  length = 100;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  pageable: any;
  token: string;
  // MatPaginator Output
  pageEvent: PageEvent;

  constructor(private localGuardaService: LocalGuardaService,
    private formBuilder: FormBuilder,
    private grupoEmpresaService: GrupoEmpresaService,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService) {

  }
  ngOnInit() {

    this.formEmpresa = this.formBuilder.group({
      idEmpresa: [''],
    });
    this.formLocalGuarda = this.formBuilder.group({
      idEmpresa: ["", Validators.required],
      descricao: ["", Validators.required],
      dataCadastro: ["", Validators.required],
      identificador: ["", Validators.required],
      ativo: ["", Validators.required],
    });
    this.formEditLocalGuarda = this.formBuilder.group({
      id: [""],
      idEmpresa: ["", Validators.required],
      descricao: ["", Validators.required],
      dataCadastro: ["", Validators.required],
      identificador: ["", Validators.required],
      ativo: ["", Validators.required],
    });
    this.getAllEmpresas();
    this.getAllpedidos();
  }
  inserirLocalGuarda(){
    this.localGuarda = this.formLocalGuarda.value;
    this.localGuarda.ativo = this.localGuarda.ativo ? "S" : "N";
    this.localGuardaService.insertLocalGuarda(this.localGuarda).subscribe(response => {
      this.getAllpedidos();
      console.log(this.localGuarda);
    })
  }
  editarLocalGuarda(){
    this.localGuarda = this.formEditLocalGuarda.value;
    this.localGuarda.ativo = this.localGuarda.ativo ? "S" : "N";
    this.localGuardaService.editLocalGuarda(this.localGuarda).subscribe(response => {
      this.getAllpedidos();
    })
  }
  deletarLocalGuarda(){
    this.localGuarda = this.formEditLocalGuarda.value;
    this.localGuardaService.deleteLocalGuarda(this.localGuarda).subscribe(response =>{
      this.getAllpedidos();
    })
  }
  selectFilter() {

    this.formEmpresaValue = this.formEmpresa.get("idEmpresa").value;
    this.empresaId = Number(this.formEmpresaValue);
    this.getAllpedidos(); 
  }

  get f() { return this.formEmpresa.controls; }

  ngAfterViewInit() {

    this.paginator.page
      .pipe(
        tap(() => this.onPageController())
      )
      .subscribe(
      );

  }

  RegisterUser() { }

  onPageController() {
    this.pageSize = this.pageEvent.pageSize;
    this.pageIndex = this.pageEvent.pageIndex;

    this.alertService.clear();


    //this.loading = true;

    this.localGuardaService.getAllLocalGuarda(this.empresaId, this.pageIndex, this.pageSize, "id", "asc")
      .pipe(first())
      .subscribe(
        data => {

          this.dataSource = new MatTableDataSource(data['content']);

          this.pageable = data['pageable'];
          //this.carregandoTabela = true;

          // this.length = this.pageable.totalRecordCount;
          this.dataSource.sort = this.sort;

        }, error => {
          this.alertService.error(error);
          // this.carregandoTabela = true;
          // this.loading = false;
        });

  }



  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

  }
  getAllpedidos() {

    this.localGuardaService.getAllLocalGuarda(this.empresaId, this.pageIndex, this.pageSize, "id", "asc").subscribe(response => {

      this.dataSource = new MatTableDataSource(response['content']);

    });
  }

  getAllEmpresas() {

    this.grupoEmpresaService.getAllGrupoEmpresa().subscribe(response => {

      this.empresas = response;

    });
  }
  setCurrentLocalGuarda(localGuarda){
    this.formEditLocalGuarda.get("id").setValue(localGuarda.id);
    this.formEditLocalGuarda.get("descricao").setValue(localGuarda.descricao);
    this.formEditLocalGuarda.get("identificador").setValue(localGuarda.identificador);
    this.formEditLocalGuarda.get("ativo").setValue(localGuarda.ativo=="S" ? true : false);
    this.formEditLocalGuarda.get("dataCadastro").setValue(new Date (localGuarda.dataCadastro));
    this.formEditLocalGuarda.get("idEmpresa").setValue(localGuarda.idEmpresa);
    
  }

}
