<div id="chart" class="mt-4 pb-4">
    <p class="text-center pt-4">Insumos</p>
    <apx-chart
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [dataLabels]="chartOptions.dataLabels"
    [plotOptions]="chartOptions.plotOptions"
    [yaxis]="chartOptions.yaxis"
    [legend]="chartOptions.legend"
    [fill]="chartOptions.fill"
    [stroke]="chartOptions.stroke"
    [tooltip]="chartOptions.tooltip"
    [xaxis]="chartOptions.xaxis"
    ></apx-chart>
  </div>

