import { Component, OnInit, ViewChild } from "@angular/core";
import { ChartComponent } from "ng-apexcharts";
import { TicketService } from "src/app/_services/ticket.service";
import { ChartOptions } from "../../_models/tickets";
import { AccountService } from "../../_services";
import { User } from "../../_models";
import { chartPartialOptions } from "../../_helpers/chartPartialOptions";

@Component({
  selector: "app-tickets-para-atender",
  templateUrl: "./tickets-para-atender.component.html",
  styleUrls: ["./tickets-para-atender.component.css"],
})
export class TicketsParaAtenderComponent implements OnInit {
  chartOptions: Partial<ChartOptions>;
  @ViewChild("chart") chart: ChartComponent;

  constructor(private ticketService: TicketService) {
    this.chartOptions = {
      ...chartPartialOptions,
      series: [0, 0],
      labels: ["Em Aberto", "Em Andamento"],
    };
  }

  ngOnInit(): void {
    this.getAllTickets();
  }

  getAllTickets() {
    this.ticketService
      .getAllTicketChart()
      .subscribe(({ emAberto, emAndamento }) => {
        this.chartOptions.series = [emAberto, emAndamento];
      });
  }
}
