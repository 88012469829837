import * as moment from "moment";
import { Observable, forkJoin } from "rxjs";
import { debounceTime, filter, finalize, switchMap, tap } from "rxjs/operators";
import { User } from "src/app/_models";
import { AccountService } from "src/app/_services";
import { InsumoService } from "src/app/_services/insumos.service";
import { environment } from "src/environments/environment";

import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { ActivatedRoute, Router } from "@angular/router";

import { isHoliday } from "../../_helpers/saoPauloHolidays";
import { Insumo } from "../../_models/insumos";
import { InsumosFieldsService } from "../../_services/insumosFields.service";

@Component({
  selector: "app-criar",
  templateUrl: "./criar.component.html",
  styleUrls: ["./criar.component.css"],
})
export class CriarComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  user: User;
  searchUserCtrl1 = new FormControl();
  searchUserCtrl2 = new FormControl();
  errorMsg: string;
  filteredUsers1: any;
  filteredUsers2: any;
  isLoading = false;

  insumoError: Insumo;

  areaOptions: string[] = [];
  publicoOptions: string[] = [];
  segmentoOptions: string[] = [];
  tipoDeInsumoOptions: string[] = [];
  periodicidadeOptions: string[] = [];

  holydays: string[] = [];

  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  validatorCtrl = new FormControl();
  filteredValidators: Observable<string[]>;
  validators: any[] = [];
  allValidators: any;

  @ViewChild("validatorInput") validatorInput: ElementRef<HTMLInputElement>;
  @ViewChild('errorModal') errorModal: ElementRef;

  constructor(
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private insumoService: InsumoService,
    private accountService: AccountService,
    private insumoFieldService: InsumosFieldsService
  ) {
    this.user = this.accountService.userValue.user;
  }

  ngOnInit(): void {
    this.getFields();
    this.getHolidays();

    this.form = this.formBuilder.group({
      name: ["", [Validators.required, Validators.minLength(1)]],
      segment: [""],
      publico: [""],
      area: [""],
      insumoType: [""],
      observations: [""],
      createdUser: [""],
      responsibleUpload: [""],
      analysisUpload: [""],
      frequency: [""],
      competence: [""],
      deadline: [new Date().toISOString()],
      utilDay: [true],
      dayOnMonth: [false],
      lastDayUtil: [false],
      posCompetence: [true],
      bothMonthCompetence: [false],
      limitDate: [""],
      docType: new FormGroup({
        xls: new FormControl(""),
        xlsx: new FormControl(""),
        xlsm: new FormControl(""),
        csv: new FormControl(""),
        pdf: new FormControl(""),
        pbi: new FormControl(""),
        todos: new FormControl(""),
      }),
      insumoAmount: [0],
    });

    this.searchUserCtrl1.valueChanges
      .pipe(
        filter((value) => value?.length > 3),
        debounceTime(500),
        tap(() => {
          this.errorMsg = "";
          this.filteredUsers1 = [];
          this.isLoading = true;
        }),
        switchMap((value) =>
          this.http
            .get(`${environment.apiUrl}/api/user/search?email=${value}`)
            .pipe(
              finalize(() => {
                this.isLoading = false;
              })
            )
        )
      )
      .subscribe((data) => {
        if (data == undefined) {
          this.errorMsg = data["Error"];
          this.filteredUsers1 = [];
        } else {
          this.errorMsg = "";
          this.filteredUsers1 = data;
        }
      });

    this.searchUserCtrl2.valueChanges
      .pipe(
        filter((value) => value?.length > 3),
        debounceTime(500),
        tap(() => {
          this.errorMsg = "";
          this.filteredUsers2 = [];
          this.isLoading = true;
        }),
        switchMap((value) =>
          this.http
            .get(`${environment.apiUrl}/api/user/search?email=${value}`)
            .pipe(
              finalize(() => {
                this.isLoading = false;
              })
            )
        )
      )
      .subscribe((data) => {
        if (data == undefined) {
          this.errorMsg = data["Error"];
          this.filteredUsers2 = [];
        } else {
          this.errorMsg = "";
          this.filteredUsers2 = data;
        }
      });

    this.validatorCtrl.valueChanges
      .pipe(
        filter((value) => value?.length > 3),
        debounceTime(500),
        tap(() => {
          this.errorMsg = "";
          this.allValidators = [];
          this.isLoading = true;
        }),
        switchMap((value) =>
          this.http
            .get(`${environment.apiUrl}/api/user/search?email=${value}`)
            .pipe(
              finalize(() => {
                this.isLoading = false;
              })
            )
        )
      )
      .subscribe((data) => {
        if (data == undefined) {
          this.errorMsg = data["Error"];
          this.allValidators = [];
        } else {
          this.errorMsg = "";
          this.allValidators = data;
        }
      });
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || "").trim();
    if (value) {
      this.validators.push(value);
    }

    event.chipInput!.clear();
    this.validatorCtrl.setValue(null);
  }

  nameVerify() {
    if (
      this.form.value.name !== null &&
      this.form.value.name !== undefined &&
      this.form.value.name !== ""
    ) {
      this.insumoService
        .getInsumoByName(this.form.value.name)
        .subscribe((data: Insumo) => {
          console.log(this.form);
          if (data !== null) {
            this.insumoError = data;
          }
          if (data === null) {
            this.insumoError = null;
          }
        });
    }
  }

  remove(validator: string): void {
    const index = this.validators.indexOf(validator);

    if (index >= 0) {
      this.validators.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.validators.push(event.option.value);
    this.validatorInput.nativeElement.value = "";
    this.validatorCtrl.setValue(null);
  }

  handleEnterKeyAnalysis(event: KeyboardEvent) {
    if (event.key === "Enter") {
      const selectedUser = this.filteredUsers2.find(
        (user) => user.email === this.searchUserCtrl2.value
      );
      if (selectedUser) {
        this.setEmailAnalysisUpload(selectedUser);
      }
    }
  }

  handleEnterKeyResponsible(event: KeyboardEvent) {
    if (event.key === "Enter") {
      const selectedUser = this.filteredUsers1.find(
        (user) => user.email === this.searchUserCtrl1.value
      );
      if (selectedUser) {
        this.setEmailResponsibleUpload(selectedUser);
      }
    }
  }

  get createForm(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;

    if (!this.validationDeadLine()) {
      this.showModal();
      return;
    }

    if (this.form.invalid) {
      return;
    }

    const deadline = new Date(this.form.get("deadline").value);
    const deadlineDay = deadline.getDate();
    const competenceDate = new Date(this.form.get("competence").value);

    let temp_insumo = {
      name: this.form.get("name").value,
      segment: this.form.get("segment").value,
      publico: this.form.get("publico").value,
      area: this.form.get("area").value,
      createdUser: this.user.email,
      responsibleUpload: this.form.get("responsibleUpload").value,
      analysisUpload: this.form.get("analysisUpload").value,
      frequency: this.form.get("frequency").value,
      competence: this.form.get("competence").value,
      deadline: deadline.toISOString(),
      observations: this.form.get("observations").value,
      insumoType: this.form.get("insumoType").value,
      utilDays: "d" + deadlineDay,
      acceptedDocs: this.formatAcceptDocsString(),
      validators: this.validators,
      insumoAmount: this.form.get("insumoAmount").value,
    };

    this.form.controls["name"].setValue("");
    this.validateDatesInForm(temp_insumo, competenceDate);

    this.insumoService.saveInsumo(temp_insumo).subscribe(() =>
      this.router.navigate(["/insumos/meus"], {
        relativeTo: this.route,
      })
    );
  }

  showModal() {
    const modalElement = this.errorModal.nativeElement;
    ($(modalElement) as any).modal('show');
  }

  validateUtilDay(index) {
    if (index == 3) {
      this.form.get("limitDate").reset();
      if (this.form.get("lastDayUtil").value == true) {
        this.form.get("limitDate").disable();
      } else {
        this.form.get("limitDate").enable();
      }
    }
    if (index == 1) {
      this.form.get("dayOnMonth").setValue(true);
      this.form.get("utilDay").setValue(false);
    } else {
      this.form.get("dayOnMonth").setValue(false);
      this.form.get("utilDay").setValue(true);
    }
  }

  valideCompetenceDate(index: number) {
    const pos = this.form.get("posCompetence").value;
    const both = this.form.get("bothMonthCompetence").value;

    if (index == 1 && pos == true) {
      this.form.get("posCompetence").setValue(true);
      this.form.get("bothMonthCompetence").setValue(false);
    } else if (index == 2 && both == true) {
      this.form.get("posCompetence").setValue(false);
      this.form.get("bothMonthCompetence").setValue(true);
    } else {
      this.form.get("posCompetence").setValue(true);
      this.form.get("bothMonthCompetence").setValue(false);
    }
  }

  setEmailResponsibleUpload(user) {
    this.form.get("responsibleUpload").setValue(user.email);
  }

  setEmailAnalysisUpload(user) {
    this.form.get("analysisUpload").setValue(user.email);
  }

  resetField(form) {
    if (form === 1) {
      this.form.get("responsibleUpload").reset();
      this.searchUserCtrl1.reset();
    } else {
      this.form.get("analysisUpload").reset();
      this.searchUserCtrl2.reset();
    }
  }

  validationDeadLine(): boolean {
    let deadline = this.form.get("deadline").value;
    deadline = this.formatData(deadline);

    let isInvalid = false;

    this.holydays.forEach((element) => {
      if (element == deadline) {
        this.form.get("deadline")?.setErrors({ invalid: "holiday" });
        isInvalid = true;
      }
    });

    if (new Date(deadline).getDay() == 5 || new Date(deadline).getDay() == 6) {
      this.form.get("deadline")?.setErrors({ invalid: "notWorkDay" });
      isInvalid = true;
    }

    const day = deadline.split("-")[2];
    const month = deadline.split("-")[1];

    if (isHoliday(day, month)) {
      this.form.get("deadline")?.setErrors({ invalid: "holiday" });
      isInvalid = true;
    }

    return !isInvalid;
  }

  getLastUtilDay(date: Date) {
    const pos = this.form.get("posCompetence").value;

    let lastDay = moment();

    if (pos) {
      lastDay = moment(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    } else {
      lastDay = moment(new Date(date.getFullYear(), date.getMonth(), 0));
    }

    let validate = true;

    while (validate) {
      if (lastDay.date() === 0 || lastDay.date() === 6) {
        lastDay.add(1, "days");
      } else {
        validate = false;
      }
    }

    return lastDay.toDate();
  }

  getDateWithAddedDays(days: number, date: Date) {
    let index = days;
    const pos = this.form.get("posCompetence").value;

    let lastDay = moment();

    if (pos) {
      lastDay = moment(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    } else {
      lastDay = moment(new Date(date.getFullYear(), date.getMonth(), 0));
    }

    while (index > 0) {
      lastDay.add(1, "days");

      let isHoliday = false;

      this.holydays.forEach((element) => {
        const elementDay = Number(element.split("-")[2]);
        const elementMonth = Number(element.split("-")[1]);
        if (elementDay == lastDay.date() && elementMonth == lastDay.month()) {
          isHoliday = true;
        }
      });

      if ((lastDay.day() !== 0 && lastDay.day() !== 6) || isHoliday) {
        index--;
      }
    }

    return lastDay.toDate();
  }

  validateDatesInForm(tempForm: any, competenceDate: Date) {
    if (
      this.createForm.utilDay.value == true &&
      this.createForm.lastDayUtil.value == true
    ) {
      tempForm.deadline = this.getLastUtilDay(competenceDate);
      tempForm.utilDays = "-1";
    } else if (
      this.createForm.utilDay.value == true &&
      this.createForm.lastDayUtil.value == false
    ) {
      tempForm.deadline = this.getDateWithAddedDays(
        this.createForm.limitDate.value,
        competenceDate
      );
      tempForm.utilDays = this.createForm.limitDate.value;
    }
  }

  formatAcceptDocsString() {
    let docTypeString = "";

    if (this.form.get("docType.todos").value) {
      docTypeString = "todos";
    } else {
      if (this.form.get("docType.xls").value) {
        docTypeString = docTypeString + ".xls,";
      }
      if (this.form.get("docType.xlsx").value) {
        docTypeString = docTypeString + ".xlsx,";
      }
      if (this.form.get("docType.xlsm").value) {
        docTypeString = docTypeString + ".xlsm,";
      }
      if (this.form.get("docType.csv").value) {
        docTypeString = docTypeString + ".csv,";
      }
      if (this.form.get("docType.pdf").value) {
        docTypeString = docTypeString + ".pdf";
      }
      if (this.form.get("docType.pbi").value) {
        docTypeString = docTypeString + ".pbi";
      }
    }

    return docTypeString;
  }

  getFields() {
    forkJoin({
      area: this.insumoFieldService.getByType("AREA"),
      segment: this.insumoFieldService.getByType("SEGMENTO"),
      type: this.insumoFieldService.getByType("TIPO_DE_INSUMO"),
      periodicity: this.insumoFieldService.getByType("PERIODICIDADE"),
      publico: this.insumoFieldService.getByType("CADASTRO_PUBLICO"),
    }).subscribe(({ area, periodicity, publico, segment, type }) => {
      this.areaOptions = area.map((value) => value.fieldDescription);
      this.segmentoOptions = segment.map((value) => value.fieldDescription);
      this.tipoDeInsumoOptions = type.map((value) => value.fieldDescription);
      this.periodicidadeOptions = periodicity.map(
        (value) => value.fieldDescription
      );
      this.publicoOptions = publico.map((value) => value.fieldDescription);
    });
  }

  setCompetenceFormValue($event: EventEmitterValue) {
    this.form.get("competence").setValue($event.competence);
  }

  formatData(date: any) {
    let timeStamp_date = new Date(date);

    let data_date = {
      year: timeStamp_date.getFullYear(),
      month: String(timeStamp_date.getMonth() + 1),
      day: String(timeStamp_date.getDate()),
    };

    let { year, month, day } = data_date;

    month = Number(month) <= 9 ? "0" + month : month;
    day = Number(day) <= 9 ? "0" + day : day;

    return `${year}-${month}-${day}`;
  }

  getHolidays() {
    this.http
      .get<any[]>(
        `https://date.nager.at/api/v3/PublicHolidays/${new Date().getFullYear()}/BR`
      )
      .subscribe((data) => {
        data.forEach((element) => {
          this.holydays.push(element.date);
        });
      });
  }
}

interface EventEmitterValue {
  competence: string;
}
