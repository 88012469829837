import { HttpEventType } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { User } from "../_models";
import { Item } from "../_models/item";
import { Pendencia } from "../_models/pendencia";
import { AccountService, AlertService } from "../_services";
import { PedidoPortalService } from "../_services/pedido-portal.service";
import { PendenciaService } from "../_services/pendencia.service";
import { UploadService } from "../_services/upload.service";

@Component({
  selector: "app-concluir-pedido",
  templateUrl: "./concluir-pedido.component.html",
  styleUrls: ["./concluir-pedido.component.css"],
})
export class ConcluirPedidoComponent implements OnInit {
  id: any;
  pedidoOnline: any = {};
  criadoEm: any;
  items: any[] = [];
  responsavel: any = {};
  user: User;
  razaoSocial: any = {};
  solicitante: any = {};
  departamento: any = {};
  isChecked: any = {};
  observacao: any = {};
  anexos: any[] = [];
  token: any;
  materialInterno: boolean = false;
  tipoEntrega: any;
  ordemServico: any;
  currentAnexo: any = {};
  currentPedidoAnexo: any = {};
  observacaoConclusao: string = "";
  disabledEnviarButton: boolean = true;
  formChamadoHelpdesk: FormGroup;
  currentTicket: any;
  pendencia: Pendencia = new Pendencia(null, null, [], "");
  pendenciaList: any = [];
  tempPendencia: string = "";
  tituloPendencia: string = "";
  showAnexos = false;
  pedidoAnexos: any[] = [];
  displayedColumnsPedidoAnexos: string[] = ["anexo", "download"];
  dataSourcePedidoAnexos = new MatTableDataSource<any>();

  anexoDownload: string = `${environment.apiUrl}/download/`;
  anexoPedidoDownload: string = `${environment.apiUrl}/download/portal/anexo/`;
  // "https://dattasign.com.br:9904/download/anexo-pedido/";

  @ViewChild("fileUpload", { static: false }) fileUpload: ElementRef;
  files = [];
  attachments = [];

  displayedColumns: string[] = ["id", "campo", "conteudo"];
  displayedColumnsAnexos: string[] = ["id", "anexo", "download"];
  dataSource = new MatTableDataSource<any>();
  dataSourceAnexos = new MatTableDataSource<any>();
  checkPendencias = true;

  // MatPaginator Inputs
  // length = 100;
  // pageSize = 10;
  // pageIndex = 0;
  // pageSizeOptions: number[] = [5, 10, 25, 100];
  // pageable: any;

  // MatPaginator Output
  //pageEvent: PageEvent;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sortAnexos: MatSort;
  @ViewChild(MatPaginator) paginatorAnexos: MatPaginator;

  constructor(
    private route: ActivatedRoute,
    private accountService: AccountService,
    private router: Router,
    private uploadService: UploadService,
    private alertService: AlertService,
    private pedidoPortalService: PedidoPortalService,
    private formBuilder: FormBuilder,
    private pendenciaService: PendenciaService
  ) {
    this.user = this.accountService.userValue.user;
    this.token = this.accountService.userValue.token;

    this.route.queryParams.subscribe((params) => {
      var id = this.route.snapshot.paramMap.get("id");
      console.log(id);

      if (id) {
        this.id = id;
        this.getPedidoPortalById(this.id);
        this.getPedidosAnexo(this.id);
      }
    });
  }

  ngOnInit(): void {
    this.formChamadoHelpdesk = this.formBuilder.group({
      userId: [""],
      pendencia: [""],
      message: ["", Validators.required],
    });
  }

  getPedidosAnexo(id) {
    this.pedidoPortalService.getAnexosPedido(id).subscribe((response) => {
      this.anexos = response;
      this.dataSourceAnexos = new MatTableDataSource(this.anexos);
      this.dataSourceAnexos.paginator = this.paginatorAnexos;

      this.dataSourceAnexos.sort = this.sortAnexos;
    });
  }

  getPedidoPortalById(id) {
    this.pedidoPortalService
      .getPedidoPortalById(id, 1)
      .subscribe((response) => {
        this.pedidoOnline = response.pedidoOnlineDto;
        this.criadoEm = new Date(this.pedidoOnline.criadoEm);
        this.razaoSocial = this.pedidoOnline.razaoSocial;
        this.solicitante = this.pedidoOnline.solicitante;
        this.departamento = this.pedidoOnline.departamento;
        this.observacao = this.pedidoOnline.observacao;
        this.isChecked = this.pedidoOnline.urgente === "Y";
        this.ordemServico = this.pedidoOnline.id;
        this.tipoEntrega = this.pedidoOnline.tipoDeSolicitacao;
        this.materialInterno =
          this.pedidoOnline.tipoDeSolicitacao === "SOLICITAÇÃO DE CARTONAGEM";
        this.observacaoConclusao = this.pedidoOnline.observacaoConclusao;
        console.log(this.isChecked);

        this.showAnexos = "anexoBase64" !== this.pedidoOnline.anexo;
        this.currentPedidoAnexo = this.pedidoOnline.anexo.substring(20);

        this.pedidoAnexos.push(this.currentPedidoAnexo);
        this.dataSourcePedidoAnexos = new MatTableDataSource(this.pedidoAnexos);

        this.items = response.pedidoPortalItemDtos;
        this.responsavel = response.responsavelPedidoOnlineDto;
        // this.resultsLength = this.items.length;

        this.dataSource = new MatTableDataSource<Item>(
          response.pedidoPortalItemDtos
        );
        // this.dataSource.paginator = this.paginator;
        // this.dataSource.sort = this.sort;

        // this.dataSource.paginator = this.paginator;
        // this.dataSource.sort = this.sort;
        // this.dataSource.paginator = this.paginator;
      });
  }

  concluirPedido() {
    console.log(this.materialInterno);
    console.log(this.tipoEntrega);

    this.pedidoPortalService
      .concluirPedido(this.id, this.user.id)
      .subscribe((response) => {
        if (response) {
          this.router.navigate(["/pedidos-online"], { relativeTo: this.route });
        }
      });
  }

  uploadFile(file) {
    const formData = new FormData();
    formData.append("file", file.data);
    file.inProgress = true;
    this.uploadService
      .uploadAnexo(this.id, formData)
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round((event.loaded * 100) / event.total);
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        catchError(() => {
          file.inProgress = false;
          return of(`${file.data.name} upload failed.`);
        })
      )
      .subscribe((event: any) => {
        if (typeof event === "object") {
          this.attachments.push(event.body);
          console.log(this.attachments);
          this.getPedidosAnexo(this.id);
        }
      });
  }

  private uploadFiles() {
    this.fileUpload.nativeElement.value = "";
    this.files.forEach((file) => {
      this.uploadFile(file);
    });
  }

  onUploadButtonClick() {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({ data: file, inProgress: false, progress: 0 });
      }
      this.uploadFiles();
    };
    // fileUpload.click();
  }

  baixarAnexo(filename) {
    this.accountService.getTokenWithLowExpiration().subscribe((data) => {
      const urlToDownloadFile: string = `${this.anexoDownload}${filename}?token=${data.token}`;

      window.open(urlToDownloadFile);
    });
  }

  checkTipoEntrega() {
    console.log(this.tipoEntrega);
  }

  deleteAnexo(anexoId) {
    this.pedidoPortalService
      .deleteAnexosPedido(anexoId)
      .subscribe((response) => {
        if (response) {
          this.getPedidosAnexo(this.id);
          this.alertService.success("Anexo removido com sucesso!", {
            keepAfterRouteChange: true,
            autoClose: true,
          });
        } else {
          this.alertService.success("Erro ao remover o Anexo!", {
            keepAfterRouteChange: true,
            autoClose: true,
          });
        }
      });
    console.log(anexoId);
  }

  setCurrentAnexo(anexo) {
    this.currentAnexo = anexo;
  }

  //   ngAfterViewInit() {
  //     this.paginator.page
  //         .pipe(
  //             tap(() => {
  //               this.dataSource = new MatTableDataSource(this.items);
  //               this.dataSource.paginator = this.paginator;
  //             })
  //         )
  //         .subscribe();
  // }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applyFilter2(filterValue: string) {
    this.dataSourceAnexos.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceAnexos.paginator) {
      this.dataSourceAnexos.paginator.firstPage();
    }
  }

  salvarObservacao() {
    this.pedidoPortalService
      .addObservacaoConclusao(this.id, this.observacaoConclusao)
      .subscribe((response) => {
        if (response) {
          this.alertService.success("Observação salva com sucesso!", {
            keepAfterRouteChange: true,
            autoClose: true,
          });
        } else {
          this.alertService.success("Erro ao salvar observação!", {
            keepAfterRouteChange: true,
            autoClose: true,
          });
        }
      });
  }

  checkObservacaoConclusao() {
    if (this.observacaoConclusao.length > 5) {
      this.disabledEnviarButton = false;
    } else {
      this.disabledEnviarButton = true;
    }
  }

  setCurrentTicketMessage() {
    this.formChamadoHelpdesk
      .get("userId")
      .setValue(this.pedidoOnline.solicitante);
    this.tituloPendencia = "Pendência no Pedido de Id #" + this.pedidoOnline.id;
    //this.formChamadoHelpdesk.get("message").setValue(message);
  }

  addPendencia() {
    this.tempPendencia = this.formChamadoHelpdesk.get("pendencia").value;
    this.pendenciaList.push(this.tempPendencia);

    if (this.pendenciaList.length > 0) {
      this.checkPendencias = false;
    }
    this.formChamadoHelpdesk.get("pendencia").setValue("");

    console.log(this.pendenciaList);
  }

  limpar() {
    this.pendenciaList = [];
    this.formChamadoHelpdesk.get("pendencia").setValue("");
    this.formChamadoHelpdesk.get("message").setValue("");
    this.checkPendencias = true;
  }

  criarPendencia() {
    console.log(this.id);
    var pendencia = this.formChamadoHelpdesk.value;
    this.pendencia.idPedidoPortal = this.id;
    this.pendencia.pendencias = this.pendenciaList;
    this.pendencia.message = pendencia.message;

    console.log(this.pendencia);

    this.pendenciaService
      .insertPendencia(this.pendencia)
      .subscribe((response) => {});
  }

  baixarPedidoAnexo(filename) {
    this.accountService.getTokenWithLowExpiration().subscribe((data) => {
      const urlToDownloadFile: string = `${this.anexoDownload}${filename}?token=${data.token}`;

      window.open(urlToDownloadFile);
    });
  }
}
