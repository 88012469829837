import { DOCUMENT } from "@angular/common";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { LoginWith2AF, User, User2AF } from "../_models";

@Injectable({ providedIn: "root" })
export class AccountService {
  public userObservable: Observable<User2AF>;

  private userSubject: BehaviorSubject<User2AF>;
  private refreshTokenTimeout;
  private renderer: Renderer2;

  constructor(
    private rendererFactory: RendererFactory2,
    private router: Router,
    private http: HttpClient,
    @Inject(DOCUMENT) private document: Document
  ) {
    const initialUser = this.getStoredUser();

    this.userSubject = new BehaviorSubject<User2AF | null>(initialUser);
    this.userObservable = this.userSubject.asObservable();
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  public get userValue(): any {
    return this.userSubject.value;
  }

  private getStoredUser(): User2AF | null {
    try {
      const userJson = localStorage.getItem("user");

      return userJson ? JSON.parse(userJson) : null;
    } catch (e) {
      console.warn("Erro ao parsear o usuário do localStorage", e);
      return null;
    }
  }

  private setStoredUser(user: User2AF | null): void {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      localStorage.removeItem("user");
    }
  }

  //AuthenticationRestController Endpoints
  /**
   * Initial login With TwoAuthFactor.
   *
   * @param   email and password.
   * @returns emailMask and token.
   */
  loginTwoAuthFactor(email: string, password: string) {
    return this.http.post<LoginWith2AF>(`${environment.apiUrl}/api/2af`, {
      email,
      password,
    });
  }

  /**
   * Confirm login With TwoAuthFactor code.
   *
   * @param   2af Code and email.
   * @returns token and user.
   */
  confirmTwoAuthFactorToken(code: string, email: string) {
    return this.http
      .post<User2AF>(`${environment.apiUrl}/api/confirm/2af`, {
        token: code,
        email,
      })
      .pipe(
        tap((data) => {
          if (data == null) {
            return null;
          }

          const btnFlutuante = this.document.getElementById("btnFlutuante");

          if (btnFlutuante) {
            this.renderer.setStyle(btnFlutuante, "display", "block");
          }

          this.setStoredUser(data);
          this.userSubject.next(data);
          this.startRefreshTokenTimer();

          return data;
        })
      );
  }

  /**
   *  login Without TwoAuthFactor code.
   *
   * @param   email and password.
   * @returns token and user.
   */
  login(email: string, password: string) {
    return this.http
      .post<User2AF>(`${environment.apiUrl}/api/auth`, { email, password })
      .pipe(
        tap((data) => {
          this.setStoredUser(data);
          this.userSubject.next(data);
          return data;
        })
      );
  }

  /**
   *  logout from platform.
   *
   */
  logout() {
    const btnFlutuante = this.document.getElementById("btnFlutuante");

    if (btnFlutuante) {
      this.renderer.setStyle(btnFlutuante, "display", "none");
    }

    this.setStoredUser(null);
    this.userSubject.next(null);
    this.stopRefreshTokenTimer();
    this.router.navigate(["/account/login"]);
  }

  /**
   *  Refresh Auth Token.
   *
   * @param   email and password.
   * @returns token and user.
   */
  refreshToken(data: User2AF) {
    return this.http
      .post<User2AF>(`${environment.apiUrl}/api/refresh`, {
        headers: new HttpHeaders().set("Authorization", data.token),
      })
      .pipe(
        tap((data) => {
          this.setStoredUser(data);
          this.userSubject.next(data);
          return data;
        })
      );
  }

  getTokenWithLowExpiration() {
    return this.http.get<{ token: string }>(
      `${environment.apiUrl}/api/lowExpiration`
    );
  }

  private startRefreshTokenTimer() {
    this.refreshTokenTimeout = setTimeout(() => {
      if (this.userValue !== null) {
        this.refreshToken(this.userValue).subscribe();
      }
    }, 86400000);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  getByEmail(email: string) {
    return this.http
      .get<User2AF>(`${environment.apiUrl}/api/user/email?email=${email}`, {
        headers: new HttpHeaders().set("Authorization", this.userValue.token),
      })
      .pipe(
        tap((data) => {
          this.setStoredUser(data);
          this.userSubject.next(data);
          return data;
        })
      );
  }

  updateUser(user) {
    return this.http.post(
      `${environment.apiUrl}/api/user/update-mobile-phone`,
      user
    );
  }

  requestAccess(user: User) {
    return this.http.post<Boolean>(
      `${environment.apiUrl}/api/user/request-access`,
      user
    );
  }

  resetPassword(user: User) {
    return this.http.get<Boolean>(
      `${environment.apiUrl}/api/user/reset-password?email=` + user.email
    );
  }

  updatePassword(user: User) {
    return this.http.post(
      `${environment.apiUrl}/api/user/update-password-by-reset`,
      user
    );
  }

  updatePasswordWithToken(data: User2AF) {
    return this.http.post(
      `${environment.apiUrl}/api/user/update-password`,
      data,
      {
        headers: new HttpHeaders().set("Authorization", data.token),
      }
    );
  }

  getAll() {
    return this.http.get(`${environment.apiUrl}/api/user`);
  }

  getById(id: string) {
    return this.http.get<User2AF>(`${environment.apiUrl}/api/user/${id}`).pipe(
      tap((data) => {
        this.setStoredUser(data);
        this.userSubject.next(data);
        return data;
      })
    );
  }

  getUserById(id: string) {
    return this.http.get<User>(`${environment.apiUrl}/api/user/${id}`);
  }

  update(id, params) {
    return this.http.put(`${environment.apiUrl}/api/user/${id}`, params).pipe(
      tap((x) => {
        // update stored user if the logged in user updated their own record
        if (id == this.userValue.id) {
          // update local storage
          const user = { ...this.userValue, ...params };
          this.setStoredUser(user);
          // publish updated user to subscribers
          this.userSubject.next(user);
        }
        return x;
      })
    );
  }

  delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/api/user/${id}`).pipe(
      tap((x) => {
        // auto logout if the logged in user deleted their own record
        if (id == this.userValue.id) {
          this.logout();
        }
        return x;
      })
    );
  }

  firstAccess(token, userId, value) {
    var header = {
      headers: new HttpHeaders().set("Authorization", token),
    };
    return this.http.get<any>(
      `${environment.apiUrl}/api/user/update-first-access/${userId}/${value}`,
      header
    );
  }
}
