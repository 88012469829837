<p class="text-muted mt-4 mb-1">
  <span class="text-primary">Portal de Comissionamento</span>
</p>

<h1 class="text-muted">Cadastrar Insumos</h1>

<div class="row mb-5">
  <div class="col-sm-6">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Nome do Insumo:</mat-label>
        <input
          matInput
          type="text"
          formControlName="name"
          (blur)="nameVerify()"
          [ngClass]="{
            'is-invalid': createForm.name.errors || insumoError !== null
          }"
          required
        />
        <div *ngIf="insumoError" class="invalid-feedback">
          <div>Já existe um Insumo cadastrado com esse Nome</div>
        </div>
      </mat-form-field>

      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Segmento:</mat-label>
        <mat-select
          formControlName="segment"
          [ngClass]="{ 'is-invalid': submitted && createForm.segment.errors }"
          required
        >
          <mat-option *ngFor="let segment of segmentoOptions" [value]="segment">
            {{ segment }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Público:</mat-label>
        <mat-select
          formControlName="publico"
          [ngClass]="{ 'is-invalid': submitted && createForm.publico.errors }"
          required
        >
          <mat-option *ngFor="let publico of publicoOptions" [value]="publico">
            {{ publico }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Área:</mat-label>
        <mat-select
          formControlName="area"
          [ngClass]="{ 'is-invalid': submitted && createForm.area.errors }"
          required
        >
          <mat-option *ngFor="let area of areaOptions" [value]="area">
            {{ area }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Tipo de Insumo: </mat-label>
        <mat-select
          formControlName="insumoType"
          [ngClass]="{
            'is-invalid': submitted && createForm.insumoType.errors
          }"
          required
        >
          <mat-option
            *ngFor="let insumoType of tipoDeInsumoOptions"
            [value]="insumoType"
          >
            {{ insumoType }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Observações:</mat-label>
        <textarea
          #comment
          matInput
          type="text"
          formControlName="observations"
          [ngClass]="{
            'is-invalid': submitted && createForm.observations.errors
          }"
          required
          maxlength="1000"
        ></textarea>
        <div class="text-muted">{{ comment.value?.length }}/1000</div>
      </mat-form-field>

      <mat-form-field class="w-100" appearance="fill">
        <mat-label>
          Email do Responsável pelo carregamento (upload) do insumo:
        </mat-label>
        <input
          type="text"
          matInput
          autocomplete="new-responsavel"
          aria-label="Email do(a) Responsável"
          [matAutocomplete]="auto1"
          [formControl]="searchUserCtrl1"
          required
          autocomplete="off"
          (click)="resetField(1)"
          (keydown.enter)="handleEnterKeyResponsible($event)"
        />
        <mat-autocomplete #auto1="matAutocomplete">
          <mat-option *ngIf="isLoading" class="is-loading">
            Carregando...
          </mat-option>
          <ng-container *ngIf="!isLoading">
            <mat-option
              *ngFor="let user of filteredUsers1"
              [value]="user.email"
              (click)="setEmailResponsibleUpload(user)"
            >
              {{ user.email }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field class="w-100" appearance="fill">
        <mat-label>
          Email do Responsável pela análise do insumo (download):
        </mat-label>
        <input
          type="text"
          matInput
          autocomplete="new-responsavel"
          aria-label="Email do(a) Responsável"
          [matAutocomplete]="auto2"
          [formControl]="searchUserCtrl2"
          required
          autocomplete="off"
          (click)="resetField(2)"
          (keydown.enter)="handleEnterKeyAnalysis($event)"
        />
        <mat-autocomplete #auto2="matAutocomplete">
          <mat-option *ngIf="isLoading" class="is-loading">
            Carregando...
          </mat-option>
          <ng-container *ngIf="!isLoading">
            <mat-option
              *ngFor="let user of filteredUsers2"
              [value]="user.email"
              (click)="setEmailAnalysisUpload(user)"
            >
              {{ user.email }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Adicionar Validadores</mat-label>
        <mat-chip-list #chipList aria-label="Seleção de Validadores">
          <mat-chip
            *ngFor="let validator of validators"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="remove(validator)"
          >
            {{ validator }}
            <button
              type="button"
              style="
                border-radius: 100px;
                outline: none;
                border: none;
                display: flex;
                padding: 0;
                background-color: transparent;
                color: gray;
              "
              *ngIf="removable"
            >
              <mat-icon matChipRemove>cancel</mat-icon>
            </button>
          </mat-chip>
          <input
            placeholder="Email do Validador"
            #validatorInput
            [formControl]="validatorCtrl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="add($event)"
          />
        </mat-chip-list>
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="selected($event)"
        >
          <mat-option
            *ngFor="let validator of allValidators"
            [value]="validator.email"
          >
            {{ validator.email }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Periodicidade:</mat-label>
        <mat-select
          formControlName="frequency"
          [ngClass]="{ 'is-invalid': submitted && createForm.frequency.errors }"
          required
        >
          <mat-option
            *ngFor="let Periodicidade of periodicidadeOptions"
            [value]="Periodicidade"
          >
            {{ Periodicidade }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <competence-input
        (competenceValueConverted)="setCompetenceFormValue($event)"
      ></competence-input>

      <div
        *ngIf="submitted && createForm.competence.errors"
        class="invalid-feedback"
      >
        Insira uma data válida
      </div>

      <div
        class="d-flex align-items-center flex-row text-align text-center my-2"
      >
        <p class="text-muted">Data Limite:</p>

        <mat-checkbox
          formControlName="dayOnMonth"
          color="primary"
          class="pl-3 mb-1"
          (change)="validateUtilDay(1)"
        >
          Dia do mês
        </mat-checkbox>

        <mat-checkbox
          formControlName="utilDay"
          color="primary"
          class="pl-3 mb-1"
          (change)="validateUtilDay(2)"
        >
          Dia útil
        </mat-checkbox>
      </div>

      <mat-form-field
        appearance="fill"
        class="w-100"
        *ngIf="this.form.get('dayOnMonth').value"
      >
        <mat-label>Data Limite:</mat-label>
        <input
          matInput
          formControlName="deadline"
          [matDatepicker]="pickerDataLimite"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="pickerDataLimite"
        ></mat-datepicker-toggle>
        <mat-datepicker #pickerDataLimite></mat-datepicker>

        <div
          *ngIf="submitted && createForm.deadline.errors"
          class="invalid-feedback"
        >
          Insira uma data válida
        </div>
      </mat-form-field>

      <div
        class="d-flex align-items-center flex-row text-align text-center my-2"
        *ngIf="!this.form.get('dayOnMonth').value"
      >
        <p class="text-muted">Último Dia Útil:</p>
        <mat-checkbox
          formControlName="lastDayUtil"
          color="primary"
          class="pl-3 mb-1"
          (change)="validateUtilDay(3)"
        >
        </mat-checkbox>
      </div>

      <mat-form-field
        class="w-100"
        appearance="fill"
        *ngIf="!this.form.get('dayOnMonth').value"
      >
        <mat-label>Dia Limite: (Em Dias)</mat-label>
        <input
          matInput
          type="number"
          appNumbersOnly
          formControlName="limitDate"
          [ngClass]="{ 'is-invalid': submitted && createForm.limitDate.errors }"
        />
      </mat-form-field>

      <div class="d-flex align-items-start flex-row text-align my-2">
        <p class="text-muted">Competência e Data Limite:</p>
        <div class="d-flex flex-column align-items-start">
          <mat-checkbox
            formControlName="posCompetence"
            color="primary"
            class="pl-3 mb-1"
            (change)="valideCompetenceDate(1)"
          >
            Data Limite no mês posterior ao da competência
          </mat-checkbox>
          <mat-checkbox
            formControlName="bothMonthCompetence"
            color="primary"
            class="pl-3 mb-1"
            (change)="valideCompetenceDate(2)"
          >
            Ambos no mesmo mês
          </mat-checkbox>
        </div>
      </div>

      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Quantidade de Insumos permitidos: </mat-label>
        <input
          matInput
          type="number"
          appNumbersOnly
          formControlName="insumoAmount"
        />
      </mat-form-field>
      <mat-label class="text-muted mr-auto">Documentos Aceitos: </mat-label>
      <div style="margin: 10px 0" formGroupName="docType">
        <mat-checkbox class="w-100" formControlName="xls"> XLS </mat-checkbox>
        <mat-checkbox class="w-100" formControlName="xlsx"> XLSX </mat-checkbox>
        <mat-checkbox class="w-100" formControlName="xlsm"> XLSM </mat-checkbox>
        <mat-checkbox class="w-100" formControlName="csv"> CSV </mat-checkbox>
        <mat-checkbox class="w-100" formControlName="pdf"> PDF </mat-checkbox>
        <mat-checkbox class="w-100" formControlName="pbi"> PBI </mat-checkbox>
        <mat-checkbox class="w-100" formControlName="todos">
          Todos
        </mat-checkbox>
      </div>

      <button
        type="submit"
        class="btn btn-sm btn-primary"
        [disabled]="form.invalid"
      >
        <div class="button-flex">
          <mat-icon>post_add</mat-icon>
          Cadastrar Insumo
        </div>
      </button>
    </form>
  </div>
</div>

<div class="modal fade" id="errorModal" #errorModal>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title mb-0 text-muted" id="TituloModalCentralizado">
          Erro de Validação
        </p>
        <button
          type="button"
          class="close"
          aria-label="Fechar"
          data-dismiss="modal"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="mt-4 pb-4">
          <div class="w-100 d-flex justify-content-center">
            <mat-icon class="text-danger" style="font-size: 30pt !important"
              >error</mat-icon
            >
          </div>
          <h3 class="text-center text-muted mt-4">Data Inválida</h3>
          <p class="text-center text-muted mt-4">
            Insumos não podem ter data de entrega nos finais de semana, por
            favor altere a data.
          </p>
        </div>
      </div>
      <div class="modal-footer">
        <button
          data-dismiss="modal"
          type="button"
          class="btn-sm btn btn-secondary mr-2 btn-mat-icon"
        >
          <mat-icon class="modal-icon">close</mat-icon>
          Fechar
        </button>
      </div>
    </div>
  </div>
</div>
