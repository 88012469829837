<div class="row mt-4 mb-4 cards">
  <div class="col-lg-8 mb-2">
    <div class="bg-white pb-3 pt-3 divReturnSearh mb-2">
      <div class="col-lg-12 v-details">
        <div class="row">
          <div class="col-lg-4">
            <img
              [src]="wiki.thumbnail"
              class="mb-2 img-fluid img-fluid-border c-img"
              alt=""
            />
          </div>
          <div class="col-lg-8">
            <h1 class="m-0">{{ wiki.title }}</h1>
            <p class="text-muted">
              {{ wiki.description }}
            </p>
          </div>
        </div>

        <hr />

        <ul class="list-h">
          <li class="text-muted">
            <div class="flex-icon-btn">
              <mat-icon class="material-symbols-outlined">folder</mat-icon>
              <p class="m-0">{{ wiki.fileType }}</p>
            </div>
          </li>
          <li class="text-muted">
            <div class="flex-icon-btn">
              <mat-icon class="material-symbols-outlined">
                calendar_month
              </mat-icon>
              Criado em {{ wiki.creationDate | convertDate }}
              <!-- por -->
              <!-- {{ wiki.userUpload.fullName }} -->
            </div>
          </li>
          <li class="text-muted">
            <div class="flex-icon-btn">
              <mat-icon class="material-symbols-outlined">
                account_circle
              </mat-icon>
              {{ wiki.userUpload.fullName }}
            </div>
          </li>
        </ul>

        <hr />

        <div class="row">
          <quill-view
            [content]="wiki.wikiBody"
            format="html"
            theme="snow"
          ></quill-view>
        </div>

        <hr />

        <div class="row mb-2 mt-4" *ngIf="wiki.fileType === 'documento'">
          <div class="col-sm-12">
            <h3 class="text-muted">
              <div class="flex-icon-btn">
                <mat-icon class="material-symbols-outlined"
                  >attach_file</mat-icon
                >
                Anexos
              </div>
            </h3>

            <div class="row">
              <div class="col-sm-12 mb-4">
                <div class="mat-elevation-z8">
                  <table mat-table [dataSource]="dataSource">
                    <ng-container matColumnDef="originalFileName">
                      <th mat-header-cell *matHeaderCellDef>
                        <div class="flex-icon-btn">
                          <mat-icon class="material-symbols-outlined"
                            >description</mat-icon
                          >
                          Nome do Documento:
                        </div>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <div class="button-flex">
                          {{ element.originalFileName }}
                        </div>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="creationDt">
                      <th mat-header-cell *matHeaderCellDef>
                        <div class="flex-icon-btn">
                          <mat-icon class="material-symbols-outlined"
                            >calendar_month</mat-icon
                          >
                          Data e Hora de Envio:
                        </div>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <div class="button-flex">
                          {{ element.creationDt | convertDate }}
                        </div>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                      <th mat-header-cell *matHeaderCellDef>
                        <div class="flex-icon-btn">
                          <mat-icon class="material-symbols-outlined"
                            >more_vert</mat-icon
                          >
                          Ações
                        </div>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <!-- <div class="flex-icon-btn"> -->
                        <a
                          class="btn btn-rose text-white"
                          (click)="downloadFile(element.id)"
                          target="_blank"
                          matTooltip="Download"
                        >
                          <mat-icon class="material-symbols-outlined">
                            download
                          </mat-icon>
                        </a>
                        <!-- </div> -->
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr
                      mat-row
                      *matRowDef="let row; columns: displayedColumns"
                    ></tr>
                  </table>

                  <mat-paginator
                    #afterPaginator
                    [pageSizeOptions]="[5, 10, 20]"
                    showFirstLastButtons
                    aria-label="Select page of periodic elements"
                  >
                  </mat-paginator>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="row"
          *ngIf="wiki.fileType === 'video' || wiki.fileType === 'link'"
        >
          <div class="col-sm-12">
            <h3 class="text-muted mt-3">Link para Conteúdo</h3>
            <div class="bg-white p-4">
              <a href="https://{{ wiki.link }}">{{ wiki.link }}</a>
            </div>
          </div>
        </div>

        <!-- descrever feedback -->
        <!-- <div class="bg-feedback p-4 mt-4">
          <div class="row">
            <div class="col-lg-12">

              <h2 class="mb-1">
                Área para feedbacks
              </h2>
              <p class="text-muted m-0">
                É muito importante escutar o que tens a falar, sinta-se a vontade, respeitando as diretrizes da comunidade.
              </p>

              <div class="flex-icon-btn mb-2 mt-4">
                <mat-icon class="material-symbols-outlined">account_circle</mat-icon>
                <p class="text-muted m-0">
                  Diogenes Emilio
                </p>
              </div>

              <form action="">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Descrever corpo da mensagem</mat-label>
                  <input matInput type="text" formControlName="name" placeholder="Extrato" />
                  <button mat-button matSuffix>
                    <div class="button-flex">
                      <mat-icon>send</mat-icon>
                      Enviar
                    </div>
                  </button>
                </mat-form-field>
              </form>

            </div>
          </div>
        </div> -->
        <!-- descrever feedback -->

        <!-- feedback 1 -->
        <div class="bg-feedback p-4 mt-4">
          <div class="row">
            <div class="col-lg-12">
              <div class="headerFeedbacks">
                <h2 class="mb-1">Feedbacks</h2>
                <div style="display: flex; align-items: center; gap: 1rem">
                  <mat-icon
                    class="text-primary p-2"
                    aria-label="Example icon-button with a heart icon"
                  >
                    favorite
                  </mat-icon>
                  {{ positivesFeedbacks }} |

                  <mat-icon
                    class="text-danger p-2"
                    aria-label="Example icon-button with a heart icon"
                  >
                    warning
                  </mat-icon>
                  {{ negativesFeedbacks }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div
                  class="col-lg-12 mb-2"
                  *ngFor="let feedback of wiki.wikiFeedbacks"
                >
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="titleFeedback">
                        <div class="flex-icon-btn mb-1">
                          <mat-icon
                            class="{{
                              feedback.positiveFeedback
                                ? 'text-primary p-2'
                                : 'text-danger p-2'
                            }}"
                            aria-label="Example icon-button with a heart icon"
                          >
                            {{
                              feedback.positiveFeedback ? "favorite" : "warning"
                            }}
                          </mat-icon>
                          <span style="font-size: 16px">
                            Feedback
                            {{
                              feedback.positiveFeedback
                                ? "Positivo"
                                : "Negativo"
                            }}
                          </span>
                        </div>
                        <div class="flex-icon-btn mb-2">
                          <mat-icon class="material-symbols-outlined"
                            >account_circle</mat-icon
                          >
                          <p class="m-0 text-muted">
                            {{ feedback.user.fullName }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 text-right">
                      <p class="text-muted m-0">
                        {{ feedback.creationDate | convertDate }}
                      </p>
                    </div>
                  </div>
                  <div>
                    <p class="text-muted">
                      {{ feedback.comment }}
                    </p>
                  </div>

                  <hr />
                  <!--quebra de linha para novo comentario-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- feedback 1 -->

        <!-- feedback 2 -->
        <div class="bg-feedback p-4 mt-4">
          <div class="row section-feedback">
            <div class="col-lg-12">
              <h1 class="text-primary mb-1">Esse artigo foi útil para você?</h1>
              <p class="text-muted">
                Uma forma de espalhar conteúdo de qualidade em nossa plataforma,
                é através de feedback.
                <br />
                Se você achou esse artigo útil e quer que mais pessoas tenham
                acesso, clique no coração e favorite.
              </p>

              <a
                (click)="togleResponse(true)"
                class="btn btn-favorite mr-2 mb-2"
              >
                <span class="flex-icon-btn">
                  <mat-icon
                    class="p-2"
                    aria-label="Example icon-button with a heart icon"
                  >
                    {{
                      responseStatus && selectedResponse
                        ? "favorite"
                        : "favorite_border"
                    }}
                  </mat-icon>
                  <small>
                    {{
                      responseStatus && selectedResponse
                        ? "Você e outras 344 pessoas favoritou este conteúdo, obrigado!"
                        : "Favoritar"
                    }}
                  </small>
                </span>
              </a>

              <a
                (click)="togleResponse(false)"
                class="btn btn-revisao mr-2 mb-2 toggleSelectedRevisar"
              >
                <span class="flex-icon-btn">
                  <mat-icon
                    class="{{
                      selectedResponse && !responseStatus
                        ? 'p-2'
                        : 'material-symbols-outlined p-2'
                    }}"
                    aria-label="Example icon-button with a heart icon"
                  >
                    warning
                  </mat-icon>
                  <small>{{
                    selectedResponse && !responseStatus
                      ? "Obrigado pelo feedback!"
                      : "Indicar Revisão"
                  }}</small>
                </span>
              </a>

              <!-- input favoritar -->
              <div *ngIf="selectedResponse" class="inputFavoritar">
                <form
                  action=""
                  class=""
                  [formGroup]="addFeedback"
                  (ngSubmit)="createFeedback()"
                >
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Descreva com mais detalhes</mat-label>
                    <input
                      matInput
                      type="text"
                      formControlName="comment"
                      placeholder=""
                    />
                    <button mat-button matSuffix (click)="enviar()">
                      <div class="button-flex">
                        <mat-icon>send</mat-icon>
                        Enviar
                      </div>
                    </button>
                  </mat-form-field>
                </form>

                <small class="text-muted">
                  Dessa forma compartilhamos boas práticas e informações para
                  todos.
                </small>
              </div>
            </div>
          </div>

          <div class="row">
            <div
              class="col-lg-12 section-feedback-return text-center"
              style="display: none"
            >
              <img
                src="../../../assets/imagens/feedback.webp"
                class="img-feedback mb-4"
                alt=""
              />
              <h1 class="text-primary">
                Sr. {{ user.firstName }} {{ user.lastName }}, agradecemos pelo
                seu feedback!
              </h1>
              <p class="text-muted m-0">
                Espero que esteja tendo um ótimo dia! Gostaríamos de expressar
                nossa sincera gratidão pelo tempo que dedicou para compartilhar
                seu feedback conosco.
                <br /><br />
                A opinião dos nossos usuários é de suma importância para nós,
                pois nos ajuda a aprimorar constantemente a nossa plataforma e
                oferecer uma experiência ainda melhor para todos. Valorizamos
                cada sugestão e observação que recebemos.
                <br /><br />
                Estamos animados em saber que você está envolvido(a) com nosso
                blog e wiki, e ficamos felizes por poder contar com usuários tão
                engajados como você. Seu feedback é uma peça fundamental para o
                constante aperfeiçoamento da nossa comunidade online.
              </p>
            </div>
          </div>
        </div>
        <!-- feedback 2 -->
      </div>
    </div>
  </div>
  <div class="col-lg-4 aside">
    <div class="bg-white p-3">
      <div class="row mb-0">
        <div class="col-lg-12">
          <h2 class="m-0">Pesquisar</h2>
        </div>
        <div class="col-lg-12">
          <form action="" class="">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>O que você está procurando?</mat-label>
              <input
                matInput
                type="text"
                (value)="(searchInput)"
                (change)="setValue($event)"
              />
              <button mat-button matSuffix (click)="searchView()">
                <div class="button-flex">
                  <mat-icon>search</mat-icon>
                  Explorar
                </div>
              </button>
            </mat-form-field>
          </form>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-lg-12">
          <h2 class="m-0">Categorias</h2>
        </div>
        <div class="col-lg-12">
          <ul class="list-category">
            <li>
              <a class="categorySearch row" (click)="searchView(1)">
                <div class="col text-left flex-icon-btn">
                  <mat-icon class="material-symbols-outlined"
                    >category</mat-icon
                  >
                  <p class="m-0">Projetos e Gestão de Regras</p>
                </div>
                <!-- <p class="col text-right m-0 text-muted">(6)</p> -->
                <p class="col text-right m-0 text-muted">(0)</p>
              </a>
            </li>
            <li>
              <a (click)="searchView(2)" class="categorySearch row">
                <div class="col text-left flex-icon-btn">
                  <mat-icon class="material-symbols-outlined"
                    >category</mat-icon
                  >
                  <p class="m-0">Apuração de Resultados</p>
                </div>
                <!-- <p class="col text-right m-0 text-muted">(23)</p> -->
                <p class="col text-right m-0 text-muted">(0)</p>
              </a>
            </li>
            <li>
              <a (click)="searchView(3)" class="categorySearch row">
                <div class="col text-left flex-icon-btn">
                  <mat-icon class="material-symbols-outlined"
                    >category</mat-icon
                  >
                  <p class="m-0">Estratégia de Comissionamento</p>
                </div>
                <!-- <p class="col text-right m-0 text-muted">(29)</p> -->
                <p class="col text-right m-0 text-muted">(0)</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="bg-white p-3 mt-4" *ngIf="wikiRelacionate.length">
      <div class="row mb-2">
        <div class="col-lg-12">
          <h2 class="m-0">Itens Relacionados</h2>
        </div>
        <div class="col-lg-12">
          <div class="list-category" *ngFor="let element of wikiRelacionate">
            <div class="row" style="margin-top: 10px">
              <div class="col-lg-3 img-thumb">
                <img [src]="element.thumbnail" class="img-thumb" alt="" />
              </div>
              <div class="col-lg-9">
                <div class="row mb-3">
                  <div class="col-lg-12">
                    <a
                      [href]="'/arquivos/visualizar/' + element.id"
                      class="text-primary"
                    >
                      <h2 class="m-0">
                        {{ element.title }}
                      </h2>
                    </a>
                    <div class="mt-4 mb-4">
                      <p class="m-0 text-muted">
                        {{ element.description.slice(0, 100) }}
                        {{ element.description.length > 100 ? "..." : "" }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
