import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Cartonagem } from '../_models/cartonagem';

@Injectable({
  providedIn: 'root'
})
export class CartonagemService {

  constructor(
    private http: HttpClient) { }


  insertCartonagem(cartonagem: Cartonagem){
    return this.http.post<Cartonagem>(`${environment.apiUrl}/cartonagem`,cartonagem);
  }
  editCartonagem(cartonagem: Cartonagem){
    return this.http.put<Cartonagem>(`${environment.apiUrl}/cartonagem`,cartonagem);
  }
  deleteCartonagem(cartonagem: Cartonagem){
    return this.http.delete<Cartonagem>(`${environment.apiUrl}/cartonagem/${cartonagem.id}`,cartonagem.id);
  }
  getAllCartonagem(empresaId, page, count,sort,order) {
    return this.http.get<any>(`${environment.apiUrl}/cartonagem/search/${empresaId}/${page}/${count}?sort=${sort}&order=${order}`).pipe(tap(console.log));
  }
}
