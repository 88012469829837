<p class="text-muted mt-4 mb-1">
  <span class="text-primary">Portal de Comissionamento</span>
</p>

<div class="row row-m">
  <div class="col-lg-6">
    <h1 class="text-muted m-0">Dashboards</h1>
  </div>
  <div class="col-lg-6 text-end">
    <button
      class="btn btn-sm btn-primary"
      (click)="redirect('dashboards/criar-dashboard')"
    >
      <div class="icon-flex-btn">
        <mat-icon class="material-symbols-outlined">add_circle</mat-icon>
        Novo Dashboard
      </div>
    </button>
  </div>
</div>

<div class="row mt-4">
  <div class="col-lg-4 mb-4" *ngFor="let element of dashboards">
    <div class="bg-white h-100 p-4 card-dashboard" style="position: relative">
      <button
        mat-icon-button
        color="primary"
        style="position: absolute; top: 12px; right: 12px"
        [routerLink]="'/dashboards/editar-dashboard/' + element.id"
      >
        <mat-icon>edit</mat-icon>
      </button>
      <mat-icon class="material-symbols-outlined">bar_chart_4_bars</mat-icon>
      <div>
        <h2 class="m-0">{{ element.title }}</h2>
        <p class="mb-2 text-muted">Visualize os dados da dashboard</p>
        <div style="display: flex; align-items: center; gap: 0.5rem">
          <a href="{{ element.link }}" class="text-primary">Ver Dashboard</a>
          <mat-icon class="material-symbols-outlined" class="text-primary"
            >arrow_right_alt</mat-icon
          >
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div style="clear: both"></div>
<div class="row cards">
  <div class="col-md-3 mb-4" *ngFor="let element of dashboards">
    <div
      class="bg-white p-3"
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
      "
    >
      <div class="button-flex">
        <mat-icon class="material-symbols-outlined text-primary">
          bar_chart_4_bars
        </mat-icon>
      </div>
      <h2 class="m-0 text-left">
        <strong>{{ element.title }}</strong>
      </h2>
      <div style="flex: 1"></div>
      <a href="{{ element.link }}">
        <button mat-raised-button color="primary">Dashboard Power BI</button>
      </a>
      <button
        mat-icon-button
        color="accent"
        style="position: absolute; top: 5px; right: 5px"
        [routerLink]="'/dashboards/editar-dashboard/' + element.id"
      >
        <mat-icon>edit</mat-icon>
      </button>
    </div>
  </div>
</div> -->
