<!-- <div class="centralizar">
    <div class="container registro">
        <div class="row mt-5">
            <div class="col-lg-6 offset-lg-3 bg-registro">
                <h1 class="text-primary">Formulário de cadastro de usário</h1>
                <p class="mb-4">Lorem ipsum dolor, sit amet consectetur.</p>
                <form [formGroup]="form" novalidate>

                    <mat-list>

                        <mat-list-item>
                            <mat-form-field hintLabel="Max 40 characters">
                                <mat-label>Nome completo</mat-label>
                                <input matInput formControlName="nomeCompleto" #nomeCompleto maxlength="40"
                                    placeholder="Ex. Joseph Martins" required>
                                <mat-hint align="end">{{nomeCompleto.value?.length || 0}}/40</mat-hint>
                                <mat-icon matSuffix>person_pin</mat-icon>
                            </mat-form-field>
                        </mat-list-item>


                        <mat-list-item>
                            <mat-form-field>
                                <mat-label>CPF</mat-label>
                                <input matInput formControlName="cpf" #cpf maxlength="14"
                                    placeholder="Ex. 123.456.789-11" required>
                                <mat-hint align="end">{{cpf.value?.length || 0}}/14</mat-hint>
                                <mat-icon matSuffix>lock</mat-icon>
                            </mat-form-field>
                        </mat-list-item>

                        <mat-list-item>
                            <mat-form-field class="">
                                <mat-label>E-mail</mat-label>
                                <input matInput formControlName="email" [formControl]="emailFormControl"
                                    placeholder="Digite seu melhor e-mail" required>
                                <mat-error
                                    *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                                    Please enter a valid email address
                                </mat-error>
                                <mat-error *ngIf="emailFormControl.hasError('required')">
                                    É necessário <strong>um e-mail</strong>
                                </mat-error>
                                <mat-icon matSuffix>email</mat-icon>
                            </mat-form-field>
                        </mat-list-item>


                        <mat-list-item>
                            <mat-form-field class="example-full-width">
                                <mat-label>Telefone para contato</mat-label>
                                <span matPrefix>+55 &nbsp;</span>
                                <input type="tel" matInput formControlName="telefone" maxlength="12"
                                    placeholder="xx xxxxxxxxx">
                                <mat-icon matSuffix>phone</mat-icon>
                            </mat-form-field>
                        </mat-list-item>

                        <mat-list-item>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <mat-form-field>
                                        <mat-label>Digite sua senha</mat-label>
                                        <input matInput formControlName="senha" [type]="hide ? 'password' : 'text'"
                                            placeholder="*********">
                                        <button mat-icon-button matSuffix (click)="hide = !hide"
                                            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                        </button>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <mat-form-field>
                                        <mat-label>Confirme a senha</mat-label>
                                        <input matInput formControlName="confirmeSenha"
                                            [type]="hide ? 'password' : 'text'" placeholder="*********">
                                        <button mat-icon-button matSuffix (click)="hide = !hide"
                                            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                        </button>
                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-list-item>

                        <mat-list-item>
                            <mat-form-field>
                                <mat-label>Segmento de mercado</mat-label>
                                <mat-select>
                                    <mat-option value="option">Option</mat-option>
                                    <mat-option value="option">Option</mat-option>
                                    <mat-option value="option">Option</mat-option>
                                    <mat-option value="option">Option</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-list-item>


                        <mat-list-item>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 order-1">
                                    <button mat-flat-button class="btn btn-danger mb-1 btn-block">Cancelar</button>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 order-2">
                                    <button mat-flat-button class="btn btn-success mb-1 btn-block">Cadastrar</button>
                                </div>
                            </div>
                        </mat-list-item>

                    </mat-list>

                </form>
            </div>
        </div>
    </div>
</div> -->

<div class="centralizar">
    <div class="container registro">
        <div class="row mt-5">
            <div class="col-lg-6 offset-lg-3 bg-registro">
                <h1 class="text-primary">Formulário de cadastro de usário</h1>
                <p class="mb-4">Lorem ipsum dolor, sit amet consectetur.</p>
                <form [formGroup]="formUser" novalidate>

                    <mat-list>
                        <mat-list-item>
                            <mat-form-field>
                                <input matInput placeholder="CNPJ" formControlName="cnpj">
                            </mat-form-field>
                        </mat-list-item>


                        <mat-list-item>
                            <mat-form-field>
                                <input matInput placeholder="Razão Social" formControlName="razaoSocial">
                            </mat-form-field>
                        </mat-list-item>


                        <mat-list-item>
                            <mat-form-field>
                                <input matInput placeholder="CPF" formControlName="cpf">
                            </mat-form-field>
                        </mat-list-item>

                        <mat-list-item>
                            <mat-form-field>
                                <input matInput placeholder="Nome Completo" formControlName="nome">
                            </mat-form-field>
                        </mat-list-item>

                        <mat-list-item>
                            <mat-form-field>
                                <input matInput placeholder="E-mail" formControlName="email">
                            </mat-form-field>
                        </mat-list-item>

                        <mat-list-item>
                            <mat-form-field>
                                <input matInput type="password" placeholder="Senha" formControlName="senha">
                            </mat-form-field>
                            <span matTooltip="Senha deve conter no minimo 6 caracters">
                                <mat-icon>help</mat-icon>
                            </span>
                        </mat-list-item>

                        <mat-list-item>
                            <button mat-raised-button color="primary" [disabled]="formUser.invalid" (click)="cadastrarUser()">Cadastrar</button>
                        </mat-list-item>

                        <mat-list-item>
                            <button mat-raised-button routerLink="/login">Retornar</button>
                        </mat-list-item>

                    </mat-list>
                </form>
            </div>
        </div>
    </div>
</div>