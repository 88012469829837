import { User } from "src/app/_models";
import { AccountService } from "src/app/_services";
import { WikiService } from "src/app/_services/wiki.service";

import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-editar-arquivo",
  templateUrl: "./editar-arquivo.component.html",
  styleUrls: ["./editar-arquivo.component.css"],
})
export class EditarArquivoComponent implements OnInit {
  arquivoId: string = null;
  arquivo: any = null;

  user: User;
  form: FormGroup;
  submitted: boolean = false;
  categories: any[] = [];
  selectedCategory: any = null;
  subcategories: any[] = [];

  thumbFiles: File[] = [];
  thumbImage: any;

  @ViewChild("fileUpload") fileUpload: any;
  @ViewChild("thumbUpload") thumbUpload: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private wikiService: WikiService,
    private formBuilder: FormBuilder,
    private accountService: AccountService
  ) {
    this.arquivoId = this.route.snapshot.paramMap.get("id");
    this.user = this.accountService.userValue.user;
  }

  ngOnInit(): void {
    this.getCategories();

    this.form = new FormGroup({
      title: new FormControl("", Validators.required),
      description: new FormControl("", Validators.required),
      categoryId: new FormControl("", Validators.required),
      subcategoryId: new FormControl("", Validators.required),
      fileType: new FormControl("", Validators.required),
      link: new FormControl(""),
      area: new FormControl(""),
      wikiBody: new FormControl(""),
      areaPermissions: new FormGroup({
        EQUIPE_INTERNA: new FormControl(false, Validators.required),
        OPERACAO_VIVO: new FormControl(false, Validators.required),
        OPERACAO_BPO: new FormControl(false, Validators.required),
        TODOS: new FormControl(false, Validators.required),
      }),
    });

    this.wikiService.findOne(this.arquivoId).subscribe((res) => {
      this.arquivo = res;

      this.form.patchValue({
        title: this.arquivo.title,
        description: this.arquivo.description,
        categoryId: this.arquivo.wikiCategoryId,
        subcategoryId: this.arquivo.wikiSubcategoryId,
        fileType: this.arquivo.fileType,
        link: this.arquivo.link,
        area: this.arquivo.areaResponsavel,
        wikiBody: this.arquivo.wikiBody,
        areaPermissions: {
          EQUIPE_INTERNA:
            this.arquivo.areaPermissions.includes("EQUIPE_INTERNA"),
          OPERACAO_VIVO: this.arquivo.areaPermissions.includes("OPERACAO_VIVO"),
          OPERACAO_BPO: this.arquivo.areaPermissions.includes("OPERACAO_BPO"),
          TODOS: this.arquivo.areaPermissions.includes("TODOS"),
        },
      });
    });
  }

  getCategories() {
    this.wikiService.getCategories().subscribe((res) => {
      this.categories = res;
    });
  }

  setSubcategories(category) {
    this.subcategories = category.subcategories;
  }

  getThumbFile() {
    this.thumbFiles = Array.from(this.thumbUpload.nativeElement.files);
    this.imagePreview(this.thumbFiles);
  }

  formatFileSize(fileSizeInBytes: number) {
    if (fileSizeInBytes < 1024) {
      return fileSizeInBytes + " bytes";
    } else if (fileSizeInBytes < 1024 * 1024) {
      return (fileSizeInBytes / 1024).toFixed(2) + " KB";
    } else {
      return (fileSizeInBytes / (1024 * 1024)).toFixed(2) + " MB";
    }
  }

  imagePreview(thumbFiles) {
    const selectedFile = thumbFiles[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const dataURL = e.target.result;
        this.thumbImage = dataURL;
      };
      reader.readAsDataURL(selectedFile);
    }
  }

  editWiki() {
    const thumbNail = new FormData();

    const formValues = this.form.value;
    const areaPermissions = [];

    for (const chave in this.form.value.areaPermissions) {
      if (this.form.value.areaPermissions[chave] === true) {
        areaPermissions.push(chave);
      }
    }
    const stringAreaPermissions = areaPermissions.join(",");

    const data = {
      ...formValues,
      areaPermissions: stringAreaPermissions,
      userId: this.user.id,
    };

    if (this.thumbUpload) {
      const files = this.thumbUpload.nativeElement.files;

      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          thumbNail.append("file", files[i]);
        }

        this.wikiService.uploadThumbnail(thumbNail).subscribe((thumbNail) => {
          data.thumbnailId = thumbNail.id;

          this.wikiService.edit(this.arquivoId, data).subscribe((res) => {
            this.router.navigate(["/arquivos"]);
          });
        });
      }
    }

    this.wikiService.edit(this.arquivoId, data).subscribe((res) => {
      this.router.navigate(["/arquivos"]);
    });
  }
}
