export class User {
  id: number;
  area: string;
  email: string;
  profile: string;
  lastName: string;
  fullName: string;
  userType: string;
  enabled: boolean;
  firstName: string;
  tempPassword: boolean;
}

export interface InsertUserDto {
  id?: number;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  profile: string;
  userType: string;
  area: string;
}

export interface User2AF {
  token: string;
  user: User;
  validated: boolean;
}

export interface LoginWith2AF {
  email: string;
  emailMask: string;
  token: string;
  validated: boolean;
}

export interface UserFilterParams {
  name: string;
  status: string;
  area: string;
  userType: string;
  startDate: string;
  endDate: string;
}

export interface UserList extends User {
  firstAccessDate: string;
  lastAccessDate: string;
}
