import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "competenceDate",
})
export class CompetenceDatePipe implements PipeTransform {
  transform(date: string): string {
    const splitDate = date.split("/");

    let month = Number(splitDate[0]) - 1;
    let year = Number(splitDate[1]);

    const changeMonth = new Date("01/01/2000");
    changeMonth.setMonth(month);

    const monthName = changeMonth
      .toLocaleString("pt-BR", {
        month: "short",
      })
      .toUpperCase()
      .replace(".", "");

    return `${monthName}/${year}`;
  }
}
