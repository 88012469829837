import { first, map, startWith, tap } from 'rxjs/operators';
import { AlertService } from 'src/app/_services';
import { CartonagemService } from 'src/app/_services/cartonagem.service';
import { DepartamentosService } from 'src/app/_services/departamentos.service';
import { GrupoEmpresaService } from 'src/app/_services/grupo-empresa.service';

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';

import { LayoutImportacaoService } from '../../_services/layout-importacao.service';

@Component({
  selector: 'app-layout-importacao',
  templateUrl: './layout-importacao.component.html',
  styleUrls: ['./layout-importacao.component.css']
})
export class LayoutImportacaoComponent implements OnInit {


  // select
  porEmpresas: string[] = [
    'Empresa X', 'Empresa Y', 'Empresa Z'
  ];
  porEmpresas2: string[] = [
    'Empresa X', 'Empresa Y', 'Empresa Z'
  ];
  caixas: string[] = [
    'Caixa 5KG', 'Caixa 10KG', 'Caixa 2KG', 'DEMO'
  ];
  porDepartamentos: string[] = [
    'Departamento X', 'Departamento Y', 'Departamento Z'
  ];


  selectedPorEmpresa: any;
  selectedPorEmpresa2: any;
  selectedCaixa: any;
  selectedPorDepartamento: any;
  // end select


  formEmpresaValue = "";
  pedidos: any[] = [];
  empresas: any[] = [];
  departamentos: any[]=[];
  empresaId: number = 0;
  codigoPerfil:number = 0;
  formEmpresa: FormGroup;
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['id', 'descricao', 'expurgo', 'prazo', 'periodo', 'digitalizar', 'guarda', 'ativo', 'acoes'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  length = 100;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  pageable: any;
  token: string;
  // MatPaginator Output
  pageEvent: PageEvent;

  constructor(private layoutImportacaoService: LayoutImportacaoService,
              private formBuilder: FormBuilder,
              private grupoEmpresaService: GrupoEmpresaService,
              private departamentosService: DepartamentosService,
              private route: ActivatedRoute,
              private router: Router,
              private alertService: AlertService,) {

  }
  ngOnInit() {

    this.formEmpresa = this.formBuilder.group({
      idEmpresa: [""],
      codigoPerfil: [""]
    });
    this.getAllEmpresas();
    this.getAllpedidos();
    
  }

  selectEmpresaFilter() {
    this.formEmpresaValue = this.formEmpresa.get("idEmpresa").value; 
    this.empresaId = Number(this.formEmpresaValue);
    this.getAllDepartamentosPorEmpresasId(this.empresaId);
    
  }

  selectDepartamentoFilter(){
    var departamento = this.formEmpresa.get("codigoPerfil").value; 
    this.codigoPerfil = Number(departamento);
    this.getAllpedidos();
  }

  get f() { return this.formEmpresa.controls; }

  ngAfterViewInit() {

    this.paginator.page
      .pipe(
        tap(() => this.onPageController())
      )
      .subscribe(
      );

  }

  RegisterUser() { }

  onPageController() {
    this.pageSize = this.pageEvent.pageSize;
    this.pageIndex = this.pageEvent.pageIndex;

    this.alertService.clear();


    //this.loading = true;

    this.layoutImportacaoService.getAllLayoutImportacao(this.codigoPerfil, this.pageIndex, this.pageSize, "id_vinculo_perfil_docto", "asc")
      .pipe(first())
      .subscribe(
        data => {

          this.dataSource = new MatTableDataSource(data['content']);

          this.pageable = data['pageable'];
          //this.carregandoTabela = true;

          // this.length = this.pageable.totalRecordCount;
          this.dataSource.sort = this.sort;

        }, error => {
          this.alertService.error(error);
          // this.carregandoTabela = true;
          // this.loading = false;
        });

  }



  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

  }
  getAllpedidos() {
    this.layoutImportacaoService.getAllLayoutImportacao(this.codigoPerfil, this.pageIndex, this.pageSize, "id_vinculo_perfil_docto", "asc").subscribe(response => {
      this.dataSource = new MatTableDataSource(response['content']);
    });
  }

  getAllEmpresas() {
    this.grupoEmpresaService.getAllGrupoEmpresa().subscribe(response => {
    this.empresas = response;
    });
  }

  getAllDepartamentosPorEmpresasId(idEmpresa) {
    this.departamentosService.getAllDepartamentos(idEmpresa).subscribe(response => {
    this.departamentos = response;
    });
  }

  // table 1

  // displayedColumns: string[] = ['id', 'descricao', 'expurgo', 'prazo', 'periodo', 'digitalizar', 'guarda', 'ativo', 'acoes'];
  // dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  // @ViewChild(MatPaginator) paginator: MatPaginator;

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  // }


  // table 2

  displayedColumns2: string[] = ['nome', 'tipo', 'tamanho', 'coluna'];
  dataSource2 = new MatTableDataSource<PeriodicElement2>(ELEMENT_DATA2);

  @ViewChild(MatPaginator) paginator2: MatPaginator;

  ngAfterViewInit2() {
    this.dataSource.paginator = this.paginator;
  }


}

export interface PeriodicElement {
  id: number;
  descricao: string;
  expurgo: string;
  prazo: string;
  periodo: string;
  digitalizar: string;
  guarda: string;
  ativo: string;
  acoes: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { id: 1, descricao: 'ACORDAO', expurgo: 'Sim', prazo: '6', periodo: 'Ano', digitalizar: 'Não', guarda: 'Sim', ativo: 'Sim', acoes: 'acao1' },
];



export interface PeriodicElement2 {
  nome: string;
  tipo: string;
  tamanho: string;
  coluna: string;
}

const ELEMENT_DATA2: PeriodicElement2[] = [
  { nome: 'CNPJ', tipo: 'CARACTERE', tamanho: '15', coluna: '-' },
  { nome: 'DATA DE EMISSAO', tipo: 'DATA', tamanho: '-', coluna: '-' },
  { nome: 'ESTADO', tipo: 'LISTA', tamanho: '0', coluna: '-' },
  { nome: 'N CX IRON', tipo: 'NUMERICO', tamanho: '10', coluna: '-' },
  { nome: 'ANO', tipo: 'TEXTO', tamanho: '4', coluna: '-' },
];


