import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

import { User, UserList } from "../_models";

@Injectable({
  providedIn: "root",
})
export class GestaoUsuarioService {
  constructor(private http: HttpClient) {}

  insertUsuario(user: any) {
    return this.http.post<any>(`${environment.apiUrl}/api/user`, user);
  }

  userUpdate(user: any) {
    return this.http.put<any>(`${environment.apiUrl}/api/user`, user);
  }

  tempPasswordUsuario(userId: any) {
    return this.http.get<any>(`${environment.apiUrl}/api/user/temp/${userId}`);
  }

  getAllUsuarios() {
    return this.http.get<any>(`${environment.apiUrl}/api/user`);
  }

  getAllUsuariosPaged(page, size, sort, order, name, area, usertype) {
    return this.http.get<any>(
      `${environment.apiUrl}/api/user/${page}/${size}?sort=${sort}&order=${order}&name=${name}&area=${area}&usertype=${usertype}`
    );
  }

  getUsersFiltered(
    page,
    size,
    sort,
    order,
    name,
    status,
    area,
    usertype,
    startDate,
    endDate
  ) {
    return this.http.get<any>(
      `${environment.apiUrl}/api/user/search/${page}/${size}?sort=${sort}&order=${order}&name=${name}&status=${status}&area=${area}&usertype=${usertype}&startDate=${startDate}&endDate=${endDate}`
    );
  }

  editUsuario(user: User) {
    return this.http.put<User>(`${environment.apiUrl}/api/user/`, user);
  }
}
