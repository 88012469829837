<h1 class="text-muted">Locais de Guarda</h1>

<form [formGroup]="formEmpresa">
    <div class="form-group">
        <mat-form-field appearance="fill" class="selects100vw">
            <mat-label> Por Empresa:</mat-label>
            <mat-select formControlName="idEmpresa">
                <mat-option *ngFor="let empresa of empresas" [value]="empresa.codigo" (click)="selectFilter()">
                    {{empresa.descricao}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</form>


<!-- Botão para acionar modal -->
<button type="button" class="btn btn-primary" style="display: flex;align-items: center;" data-toggle="modal" data-target="#ExemploModalCentralizado">
    Novo local de guarda<strong><mat-icon class="ml-1" style="font-size: 20px;">add</mat-icon></strong>
</button>

<!-- Modal -->
<div class="modal fade" id="ExemploModalCentralizado" tabindex="-1" role="dialog"
    aria-labelledby="TituloModalCentralizado" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title mb-0" id="TituloModalCentralizado">
                    Dados da Origem <br>
                    <span class="text-primary" style="font-size: 10pt;">
                        Novo Registro
                    </span>
                </h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Fechar">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <form [formGroup]="formLocalGuarda">
                    <div class="form-group">
                        <mat-label class="text-primary" style="font-size: 8pt;">Empresa</mat-label>
                        <br>
                        <mat-form-field appearance="fill" class="selects100vw">
                            
                            <mat-label> Por Empresa:</mat-label>
                            <mat-select formControlName="idEmpresa" required>
                                <mat-option  *ngFor="let empresa2 of empresas" [value]="empresa2.codigo" >
                                    {{empresa2.descricao}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
    


                <div class="form-group">
                    <mat-form-field class="inputs">
                        <mat-label>Descrição</mat-label>
                        <input matInput placeholder="Ex. arquivo" formControlName="descricao" required>
                    </mat-form-field>
                </div>

                <div class="form-group">
                    <mat-form-field class="inputs">
                        <mat-label>Identificador</mat-label>
                        <input matInput placeholder="Ex. arquivo" formControlName="identificador" required>
                    </mat-form-field>
                </div>
                
                <mat-form-field appearance="fill" >
                    <mat-label> Data selecionada:</mat-label>
                    <input matInput placeholder="Escolha a data" [ngxMatDatetimePicker]="picker" formControlName="dataCadastro"> 
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #picker [showSeconds]="true" ></ngx-mat-datetime-picker>
                 
          </mat-form-field>

                <div class="form-group">
                    <mat-label>Opção</mat-label>
                    <br>
                    <mat-slide-toggle class="mr-3" color="warn"  formControlName="ativo">Ativo</mat-slide-toggle>
                </div>

            </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-dark" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary" (click)="inserirLocalGuarda()" [disabled]="formLocalGuarda.invalid" data-dismiss="modal">Adicionar</button>
            </div>
        </div>
    </div>
</div>



<div class="mat-elevation-z8 mt-4">
    <table class="table-striped" mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="id">
            <th class="id" mat-header-cell *matHeaderCellDef> Id. </th>
            <td class="id text-primary" mat-cell *matCellDef="let element"> <strong>{{element.id}}</strong></td>
        </ng-container>

        <ng-container matColumnDef="descricao">
            <th mat-header-cell *matHeaderCellDef> Descrição </th>
            <td class="descricao" mat-cell *matCellDef="let element"> {{element.descricao}} </td>
        </ng-container>

        <!--
            ADICIONAR

         -->
         <ng-container matColumnDef="identificador">
            <th mat-header-cell *matHeaderCellDef> Identificador </th>
            <td class="identificador" mat-cell *matCellDef="let element"> {{element.identificador}} </td>
         </ng-container>

        <ng-container matColumnDef="criadoEm">
            <th mat-header-cell *matHeaderCellDef> Criado em </th>
            <td class="criadoEm" mat-cell *matCellDef="let element"> {{element.dataCadastro | date: 'dd/MM/yyyy HH:mm:ss'}} </td>
        </ng-container>

        <!-- 
            ADICIONAR
            <ng-container matColumnDef="reqExt">
                <th mat-header-cell *matHeaderCellDef> reqExt </th>
                <td class="reqExt" mat-cell *matCellDef="let element"> {{element.reqExt}} </td>
            </ng-container>
         -->

        <ng-container matColumnDef="ativo">
            <th mat-header-cell *matHeaderCellDef> Ativo </th>
            <td class="ativo" mat-cell *matCellDef="let element"> {{(element.ativo === 'S') ? 'Sim':'Não'}} </td>
        </ng-container>

        <!-- ações -->
        <ng-container matColumnDef="acoes">
            <th mat-header-cell *matHeaderCellDef> Ações </th>
            <td class="acoes" mat-cell *matCellDef="let element">
                <a matSuffix matTooltip="Editar" class="btn btn-sm btn-success text-white mr-2 a-acoes"
                    data-toggle="modal" data-target="#acaoEditar">
                    <div class="mat-icon-flex-button" (click)="setCurrentLocalGuarda(element)">
                        <mat-icon>edit </mat-icon>
                    </div>
                </a>
                <a matSuffix matTooltip="Deletar" class="btn btn-sm btn-danger text-white mr-2 a-acoes"
                    data-toggle="modal" data-target="#acaoRemover">
                    <div class="mat-icon-flex-button" (click)="setCurrentLocalGuarda(element)">
                        <mat-icon>delete </mat-icon>
                    </div>
                </a>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="pageEvent = $event"></mat-paginator>
</div>


<!-- Modal editar -->
<div class="modal fade" id="acaoEditar" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title mb-0" id="TituloModalCentralizado">
                    Dados da Origem <br>
                    <span class="text-primary" style="font-size: 10pt;">
                        Novo Registro
                    </span>
                </h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Fechar">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <form [formGroup]="formEditLocalGuarda">
                    <div class="form-group">
                        <mat-label class="text-primary" style="font-size: 8pt;">Empresa</mat-label>
                        <br>
                        <mat-form-field appearance="fill" class="selects100vw">
                            
                            <mat-label> Por Empresa:</mat-label>
                            <mat-select formControlName="idEmpresa" required>
                                <mat-option  *ngFor="let empresa2 of empresas" [value]="empresa2.codigo" >
                                    {{empresa2.descricao}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                <div class="form-group">
                    <mat-form-field class="inputs">
                        <mat-label>Descrição</mat-label>
                        <input matInput placeholder="Ex. arquivo" formControlName="descricao" required>
                    </mat-form-field>
                </div>

                <div class="form-group">
                    <mat-form-field class="inputs">
                        <mat-label>Identificador</mat-label>
                        <input matInput placeholder="Ex. arquivo" formControlName="identificador" required>
                    </mat-form-field>
                </div>
                <mat-form-field appearance="fill" >
                    <mat-label> Data selecionada:</mat-label>
                    <input matInput placeholder="Escolha a data" [ngxMatDatetimePicker]="picker2" formControlName="dataCadastro"> 
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #picker2 [showSeconds]="true" ></ngx-mat-datetime-picker>
                 
          </mat-form-field>
                <div class="form-group">
                    <mat-label>Opção</mat-label>
                    <br>
                    <mat-slide-toggle class="mr-3" color="warn" formControlName="ativo">Ativo</mat-slide-toggle>
                </div>

            </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-dark" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="editarLocalGuarda()" [disabled]="formEditLocalGuarda.invalid">Editar</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal remover -->
<div class="modal fade text-center" id="acaoRemover" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <!-- <div class="modal-header text-center">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div> -->
            <div class="modal-body">
                <h3 class="text-muted mt-4">Você está prestes a deletar o local de guarda com o <strong class="text-primary">ID: {{formEditLocalGuarda.get("id").value}} </strong> <strong class="text-danger">tem
                    certeza desta ação?</strong></h3>
                <div class="mb-4">
                    <button type="button" class="btn btn-secondary mr-3" data-dismiss="modal">Cancelar</button>
                    <button type="button" class="btn btn-danger" (click)="deletarLocalGuarda()" data-dismiss="modal">Deletar</button>
                </div>
            </div>
            <!-- <div class="modal-footer text-center">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <a href="#" type="button" class="btn btn-danger"> Deletar </a>
            </div> -->
        </div>
    </div>
</div>