import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MatrizGD } from '../_models/matrizGD';

@Injectable({
    providedIn: 'root'
  })

export class FormularioMatrizService{

    constructor(private http: HttpClient) { } 


    insertMatrizGD(matrizGD: MatrizGD, mapeamentoId){
      return this.http.post<MatrizGD>(`${environment.apiUrl}/mapeamento-documental/matriz/${mapeamentoId}`, matrizGD);
  }

  updateMatrizGD(formularioId, matrizGD){
    return this.http.put<any>(`${environment.apiUrl}/mapeamento-documental/formulario/${formularioId}`, matrizGD);
}
    
}