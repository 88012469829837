import { first, map, startWith, tap } from 'rxjs/operators';
import { Origem } from 'src/app/_models/origem';
import { AlertService } from 'src/app/_services';
import { GrupoEmpresaService } from 'src/app/_services/grupo-empresa.service';
import { OrigemService } from 'src/app/_services/origem.service';

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-origem',
  templateUrl: './origem.component.html',
  styleUrls: ['./origem.component.css']
})
export class OrigemComponent implements OnInit {


  // select
  // porEmpresas: string[] = [
  //   'Empresa X', 'Empresa Y', 'Empresa Z'
  // ];
  porEmpresas2: string[] = [
    'Empresa X', 'Empresa Y', 'Empresa Z'
  ];
  caixas: string[] = [
    'Caixa 5KG', 'Caixa 10KG', 'Caixa 2KG', 'DEMO'
  ];


  selectedPorEmpresa: any;
  selectedPorEmpresa2: any;
  selectedCaixa: any;
  // end select

  formEmpresaValue = "";

  currentOrigem: any = {};
  origem: Origem = new Origem();




  descricao: any = "";
  ativo: any = false;
  dataCadastro: any = "";
  reqExt: any = false;
  failMsg: any = false;
  failMsg2: any = false;
  successMsg: any = false;
  pegarValorEmpresa: any = false;
  pegarValorDescricao: any = false;
  pegarValorDataCadastro: any = false;
  pegarValorOpcao: any = false;


  
  pedidos: any[] = [];
  empresas: any[] = [];
  empresaId: number = 0;
  formEmpresa: FormGroup;
  formOrigem: FormGroup;
  formEditOrigem: FormGroup;
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['id', 'descricao', 'criadoEm', 'ativo', 'reqExt', 'acoes'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  length = 100;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  pageable: any;
  token: string;
  // MatPaginator Output
  pageEvent: PageEvent;

  constructor(private origemService: OrigemService,
              private formBuilder: FormBuilder,
              private grupoEmpresaService: GrupoEmpresaService,
              private route: ActivatedRoute,
              private router: Router,
              private alertService: AlertService,
              private dateAdapter: DateAdapter<Date>) {
                this.dateAdapter.setLocale('pt-BR');
  }
  ngOnInit() {

    this.formEmpresa = this.formBuilder.group({
      idEmpresa: [''],
    });
    this.formOrigem = this.formBuilder.group({
      idEmpresa: ["", Validators.required],
      descricao: ["", Validators.required],
      dataCadastro: ["", Validators.required],
      ativo: ["", Validators.required],
      reqExterna:  ["", Validators.required]
    });
    this.formEditOrigem = this.formBuilder.group({
      id: [""],
      idEmpresa: ["", Validators.required],
      descricao: ["", Validators.required],
      dataCadastro: ["", Validators.required],
      ativo: ["", Validators.required],
      reqExterna:  ["", Validators.required]
    });
    this.getAllEmpresas();
    this.getAllpedidos();
  
  }

inserirOrigem(){
  this.origem = this.formOrigem.value;
  this.origem.ativo = this.origem.ativo ? "S" : "N";
  this.origem.reqExterna = this.origem.reqExterna ? "Y" : "N";
  // this.loading = true;
  this.origemService.insertOrigem(this.origem).subscribe(response => {

    this.getAllpedidos();
    this.router.navigate(['/tabelas/origem'], { relativeTo: this.route });
  },
  errors => {
    //this.alertService.error(error);
    // this.loading = true;
  });

  // this.origem.idEmpresa = this.formEmpresa.get("idEmpresa").value;
  // if(this.descricao == "" || this.dataCadastro == "" || this.origem.idEmpresa == 0){
  //   this.failMsg = true;
  // }else{
  //   this.failMsg = false;
  //   if(this.ativo){
  //     this.origem.ativo = "S";
  //   }else{
  //     this.origem.ativo = "N";
  //   }
  //   if(this.reqExt){
  //     this.origem.reqExterna = "Y";
  //   }
  //   else{
  //     this.origem.reqExterna = "N";
  //   }
  //   this.origem.descricao = this.descricao;
  //   this.origem.dataCadastro = this.dataCadastro;
  //   this.origemService.insertOrigem(this.origem).subscribe(Data =>{
  //     window.setTimeout(function(){window.location.reload()}, 2000);
  //   })
  //   this.successMsg = true;
  // }
}
editarOrigem(){
  this.origem = this.formEditOrigem.value;
  this.origem.ativo = this.origem.ativo ? "S" : "N";
  this.origem.reqExterna = this.origem.reqExterna ? "Y" : "N";
  this.origemService.editOrigem(this.origem).subscribe(response =>{
    this.getAllpedidos();
    this.router.navigate(['/tabelas/origem'], { relativeTo: this.route });
  })
  //   if(this.pegarValorDataCadastro == false && this.pegarValorDescricao == false && this.pegarValorEmpresa == false && this.pegarValorOpcao == false){
  //     this.failMsg2 = true;
  //     this.failMsg = false;
  //   }else{
  //     if(this.pegarValorEmpresa==false){
  //       this.origem.idEmpresa = this.currentOrigem.idEmpresa;
  //     }else{
  //       this.origem.idEmpresa = this.formEmpresa.get("idEmpresa").value;
  //     }
  //     if(this.pegarValorDescricao==false){
  //       this.descricao = this.currentOrigem.descricao;
  //     }
  //     if(this.pegarValorDataCadastro==false){
  //       this.dataCadastro = this.currentOrigem.dataCadastro;
  //     }if(this.pegarValorOpcao==false){
  //       this.origem.ativo = this.currentOrigem.ativo;
  //       this.origem.reqExterna = this.currentOrigem.reqExterna;
  //     }else{
  //       if(this.ativo){
  //         this.origem.ativo = "S";
  //       }else{
  //         this.origem.ativo = "N";
  //       }
  //       if(this.reqExt){
  //         this.origem.reqExterna = "Y";
  //       }
  //       else{
  //         this.origem.reqExterna = "N";
  //       }
  //     }
  //     if(this.descricao == "" || this.dataCadastro == "" || this.origem.idEmpresa == 0){
  //       this.failMsg = true;
  //       this.failMsg2 = false;
  //     }
  //     else{
  //     this.origem.id = this.currentOrigem.id;
  //     this.origem.descricao = this.descricao;
  //     this.origem.dataCadastro = this.dataCadastro;;
  //     this.failMsg = false;
  //     this.failMsg2 = false;
  //     this.origemService.insertOrigem(this.origem).subscribe(Data =>{
  //       window.setTimeout(function(){window.location.reload()}, 2000);
  //     })
  //     this.successMsg = true;
  //   }
  // }
}
deletarOrigem(){
  this.origem = this.formEditOrigem.value;
  console.log(this.origem);
  this.origemService.deleteOrigem(this.origem).subscribe(data =>{
    this.getAllpedidos();
    this.router.navigate(['/tabelas/origem'], { relativeTo: this.route });
  });
  
}
// limparCampos(){
//   this.failMsg = false;
//   this.failMsg2 = false;
//   this.descricao = "";
//   this.ativo = false;
//   this.reqExt = false;
//   this.dataCadastro = "";
// }
  selectFilter() {
    this.formEmpresaValue = this.formEmpresa.get("idEmpresa").value;
  
    this.empresaId = Number(this.formEmpresaValue);
    this.getAllpedidos();
    
  }

  get f() { return this.formEmpresa.controls; }

  ngAfterViewInit() {

    this.paginator.page
      .pipe(
        tap(() => this.onPageController())
      )
      .subscribe(
      );

  }

  RegisterUser() { }

  onPageController() {
    this.pageSize = this.pageEvent.pageSize;
    this.pageIndex = this.pageEvent.pageIndex;

    this.alertService.clear();


    //this.loading = true;

    this.origemService.getAllOrigem(this.empresaId, this.pageIndex, this.pageSize, "id", "asc")
      .pipe(first())
      .subscribe(
        data => {

          this.dataSource = new MatTableDataSource(data['content']);

          this.pageable = data['pageable'];
          //this.carregandoTabela = true;

          // this.length = this.pageable.totalRecordCount;
          this.dataSource.sort = this.sort;

        }, error => {
          this.alertService.error(error);
          // this.carregandoTabela = true;
          // this.loading = false;
        });

  }



  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

  }
  getAllpedidos() {

    this.origemService.getAllOrigem(this.empresaId, this.pageIndex, this.pageSize, "id", "asc").subscribe(response => {

      this.dataSource = new MatTableDataSource(response['content']);

    });
  }

  getAllEmpresas() {

    this.grupoEmpresaService.getAllGrupoEmpresa().subscribe(response => {

      this.empresas = response;

    });
  }
  setCurrentOrigem(origem){
   this.formEditOrigem.get("id").setValue(origem.id);
   this.formEditOrigem.get("descricao").setValue(origem.descricao);
   this.formEditOrigem.get("ativo").setValue(origem.ativo == "S" ? true: false);
   this.formEditOrigem.get("reqExterna").setValue(origem.reqExterna == "Y" ? true: false);
   this.formEditOrigem.get("dataCadastro").setValue(new Date(origem.dataCadastro));
   this.formEditOrigem.get("idEmpresa").setValue(origem.idEmpresa);
  }
}
