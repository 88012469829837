<p class="text-muted mt-4 mb-1">
  <span class="text-primary">Portal de Comissionamento</span>
</p>

<div class="row row-m">
  <div class="col-lg-6">
    <h1 class="text-muted m-0">Categorias das Dashboards</h1>
  </div>
  <div class="col-lg-6 text-end">
    <button
      class="btn btn-sm btn-primary"
      (click)="redirect('dashboards/criar-categoria')"
    >
      <div class="icon-flex-btn">
        <mat-icon class="material-symbols-outlined">add_circle</mat-icon>
        Nova Categoria
      </div>
    </button>
  </div>
</div>

<div class="banner">
  <img src="../../../assets/imagens/powerbi.svg" alt="" class="h-24" />
</div>

<div class="row mt-4">
  <div class="col-lg-4 mb-4" *ngFor="let element of dashboardCategories">
    <div class="bg-white h-100 p-4 card-dashboard" style="position: relative">
      <div>
        <img [src]="getImage(element.anexo.id)" class="bg-thumb" alt="" />
      </div>
      <div>
        <h2 class="m-0">{{ element.name }}</h2>
        <p class="mb-2 text-muted">{{ element.description }}</p>
        <div style="display: flex; align-items: center; gap: 0.5rem">
          <a
            [routerLink]="'/dashboards/categorias/' + element.id"
            class="text-primary"
          >
            Ver Dashboards
          </a>
          <mat-icon class="material-symbols-outlined" class="text-primary"
            >arrow_right_alt</mat-icon
          >
        </div>
      </div>
    </div>
  </div>
</div>
