<p class="text-muted mt-4 mb-1">
    <span class="text-primary">Portal de Comissionamento.</span>
</p>

<div class="d-flex align-items-center">
    <h1 class="mt-0 mb-0 text-muted">
        Mapeamento Documental
    </h1>
    <button class="btn btn-primary float-right ml-auto" routerLink="/monitoramento">
        <mat-icon>arrow_back</mat-icon>
    </button>
</div>
<form [formGroup]="formMapeamentoDocumentalEdit">
    <div class="mt-4">

        <h1 class="text-primary">Captação de Informação</h1>

        <mat-accordion class="example-headers-align">
            <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Cabeçalho
                    </mat-panel-title>
                    <mat-panel-description>
                        <!-- Type your name and age -->
                        <mat-icon>connect_without_contact</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>


                <div class="form-group">
                    <mat-form-field class="inputs">
                        <mat-label>Área</mat-label>
                        <input matInput placeholder="Ex. arquivo" value="" formControlName="area">
                    </mat-form-field>
                </div>

                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <mat-form-field class="inputs">
                                <mat-label>Entrevistado</mat-label>
                                <input matInput placeholder="Ex. arquivo" value="" formControlName="entrevistado">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <mat-form-field class="inputs">
                                <mat-label>E-mail</mat-label>
                                <input matInput placeholder="Ex. arquivo" value="" formControlName="email">
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <mat-form-field class="inputs">
                                <mat-label>Cargo</mat-label>
                                <input matInput placeholder="Ex. arquivo" value="" formControlName="cargo">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <mat-form-field class="inputs">
                                <mat-label>Telefone</mat-label>
                                <input matInput placeholder="Ex. arquivo" value="" formControlName="phone">
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <!-- <label for="area">Data:</label> <br> -->

                    <mat-form-field appearance="fill">
                        <mat-label>Data Escolhida:</mat-label>
                        <input matInput [matDatepicker]="picker" formControlName="dtCreation">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>

                </div>




                <mat-action-row>
                    <button mat-button color="primary" (click)="nextStep()">Próximo</button>
                </mat-action-row>


            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Dificuldades e Riscos
                    </mat-panel-title>
                    <mat-panel-description>
                        <!-- Type the country name -->
                        <mat-icon>how_to_reg</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>


                <div class="form-group">
                    <mat-form-field appearance="fill" class="inputs">
                        <mat-label>Quais as principais dificuldades na operação da sua área?</mat-label>
                        <textarea matInput formControlName="qCaptacao1"></textarea>
                    </mat-form-field>
                </div>

                <div class="form-group">
                    <mat-form-field appearance="fill" class="inputs">
                        <mat-label> Quais as principais dificuldades no que se refere à gestão de documentos e
                            informações?
                        </mat-label>
                        <textarea matInput formControlName="qCaptacao2"></textarea>
                    </mat-form-field>
                </div>



                <mat-action-row>
                    <button mat-button color="warn" (click)="prevStep()">Anterior</button>
                    <button mat-button color="primary" (click)="nextStep()">Próximo</button>
                </mat-action-row>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Melhorias
                    </mat-panel-title>
                    <mat-panel-description>
                        <!-- Inform the date you wish to travel -->
                        <mat-icon>app_registration</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>


                <div class="form-group">
                    <mat-form-field appearance="fill" class="inputs">
                        <mat-label>
                            Quais melhorias que você considera necessárias na operação de gestão de documentos no
                            dia-a-dia?
                        </mat-label>
                        <textarea matInput formControlName="qCaptacao3"></textarea>
                    </mat-form-field>
                </div>

                <div class="form-group">
                    <mat-form-field appearance="fill" class="inputs">
                        <mat-label>
                            As atividades exercidas em home office e o advento da pandemia Covid-19 dificultam o acesso
                            físico e/ou eletrônico de documentos para apoiar os processos da sua área? Caso positivo,
                            indicar quais dão esses processos, os tipos documentais e o nome da plataforma onde
                            encontra-se
                            armazenado o documento.
                        </mat-label>
                        <textarea matInput formControlName="qCaptacao4"></textarea>
                    </mat-form-field>

                </div>

                <div class="form-group">
                    <mat-form-field appearance="fill" class="inputs">
                        <mat-label>
                            Na sua opinião é possível efetivar 100% a transformação digital de seus processos e
                            atividades?
                            Caso negativo. Indique as principais dificuldades e pontos de melhorias.
                        </mat-label>
                        <textarea matInput formControlName="qCaptacao5"></textarea>
                    </mat-form-field>
                </div>




                <mat-action-row>
                    <button mat-button color="warn" (click)="prevStep()">Anterior</button>
                    <button mat-button color="primary" (click)="updateCaptacao()">Enviar</button>
                </mat-action-row>
            </mat-expansion-panel>

        </mat-accordion>

    </div>
    <div class="mt-4">
        <h1 class="text-primary">
            Data de Agendamento
        </h1>
        <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)" hideToggle>

            <mat-expansion-panel-header>

                <mat-panel-title>
                    Data marcada
                </mat-panel-title>

                <mat-panel-description>

                    <mat-icon>event</mat-icon>

                </mat-panel-description>

            </mat-expansion-panel-header>


            <!-- <mat-form-field appearance="fill">
                <mat-label>Escolha um período entre datas</mat-label>
                <mat-date-range-input [rangePicker]="pickerAgendamento">
                  <input matStartDate placeholder="Data Inicial">
                  <input matEndDate placeholder="Data Final">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="pickerAgendamento"></mat-datepicker-toggle>
                <mat-date-range-picker  #pickerAgendamento></mat-date-range-picker>
              </mat-form-field> -->
            <!--               
              <mat-form-field appearance="fill" class="ml-2">
                  <mat-label>Horarios disponiveis nesse periodo</mat-label>
                  <mat-select matNativeControl required>
                    <mat-option *ngFor="let horario of horarios" [value]="horario">{{horario}}
                    </mat-option>
                </mat-select>
              </mat-form-field> -->

            <div class="row">
                <div class="col-lg-12">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label> Data marcada para entrevista:</mat-label>
                        <input matInput placeholder="Escolha a data" [ngxMatDatetimePicker]="picker2"
                            formControlName="dataEscolhida">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #picker2 [showSeconds]="true"></ngx-mat-datetime-picker>
                    </mat-form-field>
                </div>
                <div class="col-lg-12">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>Link de reunião do Teams</mat-label>
                        <input matInput formControlName="link">
                    </mat-form-field>
                </div>
            </div>

            <mat-action-row>
                <button mat-button color="primary" (click)="updateAgendamento()"
                    [disabled]="dataEscolhidaButtonDisabled">Enviar</button>
            </mat-action-row>

        </mat-expansion-panel>
    </div>

    <div class="mt-4">

        <h1 class="text-primary">
            Matriz de Governança Documental - Entrevista
        </h1>

        <mat-card class="mt-5 mb-5">


            <mat-card-title class="text-center d-flex mt-3 mb-3 text-primary">
                <label class="text-primary mr-3" style="font-size: 1.5rem;">Preenchimento da Entrevista</label>
                <mat-card-subtitle>
                    <mat-icon class="text-primary">how_to_reg</mat-icon>
                </mat-card-subtitle>
            </mat-card-title>





            <mat-accordion>
                <mat-expansion-panel class="mt-1">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Produção documental
                        </mat-panel-title>
                        <mat-panel-title>
                            <mat-icon>assignment</mat-icon>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="form-group">
                        <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('AREA')">
                            <mat-label>Área:</mat-label>
                            <input type="text" matInput placeholder="Digite para buscar opções"
                                autocomplete="new-responsavel" [matAutocomplete]="area" [formControl]="search"
                                autocomplete="off" (keyup)="setVar('q42')" [value]="q42">
                            <mat-autocomplete #area="matAutocomplete">
                                <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                                <ng-container *ngIf="!isLoading">
                                    <mat-option (click)="formMapeamentoDocumentalEdit.get('q42').setValue(area.name)"
                                        *ngFor="let area of filtered" [value]="area.name">
                                        <span>{{area.name}}</span>
                                    </mat-option>
                                </ng-container>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('MACROPROCESSO')">
                            <mat-label>Macroprocessos:</mat-label>
                            <input type="text" matInput placeholder="Digite para buscar opções"
                                autocomplete="new-responsavel" [matAutocomplete]="macroProcesso" [formControl]="search"
                                autocomplete="off" (keyup)="setVar('q43')" [value]="q43">
                            <mat-autocomplete #macroProcesso="matAutocomplete">
                                <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                                <ng-container *ngIf="!isLoading">
                                    <mat-option
                                        (click)="formMapeamentoDocumentalEdit.get('q43').setValue(macroProcesso.name)"
                                        *ngFor="let macroProcesso of filtered" [value]="macroProcesso.name">
                                        <span>{{macroProcesso.name}}</span>
                                    </mat-option>
                                </ng-container>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('PROCESSOS')">
                            <mat-label>Processos:</mat-label>
                            <input type="text" matInput placeholder="Digite para buscar opções"
                                autocomplete="new-responsavel" [matAutocomplete]="processo" [formControl]="search"
                                autocomplete="off" (keyup)="setVar('q2')" [value]="q2">
                            <mat-autocomplete #processo="matAutocomplete">
                                <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                                <ng-container *ngIf="!isLoading">
                                    <mat-option (click)="formMapeamentoDocumentalEdit.get('q2').setValue(processo.name)"
                                        *ngFor="let processo of filtered" [value]="processo.name">
                                        <span>{{processo.name}}</span>
                                    </mat-option>
                                </ng-container>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>

                    <div class="form-group">
                        <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('ESPECIE')">
                            <mat-label>Espécie documental:</mat-label>
                            <input type="text" matInput placeholder="Digite para buscar opções"
                                autocomplete="new-responsavel" [matAutocomplete]="especie" [formControl]="search"
                                autocomplete="off" (keyup)="setVar('q4')" [value]="q4">
                            <mat-autocomplete #especie="matAutocomplete">
                                <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                                <ng-container *ngIf="!isLoading">
                                    <mat-option (click)="formMapeamentoDocumentalEdit.get('q4').setValue(processo.name)"
                                        (click)="setTipologia()" *ngFor="let processo of filtered"
                                        [value]="processo.name">
                                        <span>{{processo.name}}</span>
                                    </mat-option>
                                </ng-container>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>

                    <div class="form-group">
                        <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('ATIVIDADE')">
                            <mat-label>Atividade:</mat-label>
                            <input type="text" matInput placeholder="Digite para buscar opções"
                                autocomplete="new-responsavel" [matAutocomplete]="atividade" [formControl]="search"
                                autocomplete="off" (keyup)="setVar('q3')" [value]="q3">
                            <mat-autocomplete #atividade="matAutocomplete">
                                <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                                <ng-container *ngIf="!isLoading">
                                    <mat-option
                                        (click)="formMapeamentoDocumentalEdit.get('q3').setValue(atividade.name)"
                                        (click)="setTipologia()" *ngFor="let atividade of filtered"
                                        [value]="atividade.name">
                                        <span>{{atividade.name}}</span>
                                    </mat-option>
                                </ng-container>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>


                    <div class="form-group" style="pointer-events: none;opacity: 0.6;">
                        <mat-form-field appearance="fill" class="selects100vw">
                            <mat-label>Tipologia Documental (Espécie + Atividade)</mat-label>
                            <input matInput placeholder="Ex. arquivo" value="" formControlName="q1" readonly="readonly">
                        </mat-form-field>

                    </div>

                    <div class="form-group">
                        <mat-form-field class="inputs">
                            <mat-label>Documentos Vinculados/Anexos</mat-label>
                            <input matInput placeholder="Ex. arquivo" value="" formControlName="q35">
                        </mat-form-field>
                    </div>

                </mat-expansion-panel>
            </mat-accordion>

            <mat-accordion>
                <mat-expansion-panel class="mt-1">
                    <mat-expansion-panel-header>
                        <mat-panel-title>Caraceristicas do documento</mat-panel-title>
                        <mat-panel-description>
                            <mat-icon>description</mat-icon>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="form-group">
                        <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('SUPORTE')">
                            <mat-label>Suporte mídia:</mat-label>
                            <input type="text" matInput placeholder="Digite para buscar opções"
                                autocomplete="new-responsavel" [matAutocomplete]="suporte" [formControl]="search"
                                autocomplete="off" (keyup)="setVar('q8')" [value]="q8">
                            <mat-autocomplete #suporte="matAutocomplete">
                                <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                                <ng-container *ngIf="!isLoading">
                                    <mat-option (click)="formMapeamentoDocumentalEdit.get('q8').setValue(suporte.name)"
                                        *ngFor="let suporte of filtered" [value]="suporte.name">
                                        <span>{{suporte.name}}</span>
                                    </mat-option>
                                </ng-container>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>

                    <div class="form-group">
                        <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('VIA')">
                            <mat-label>Via:</mat-label>
                            <input type="text" matInput placeholder="Digite para buscar opções"
                                autocomplete="new-responsavel" [matAutocomplete]="via" [formControl]="search"
                                autocomplete="off" (keyup)="setVar('q9')" [value]="q9">
                            <mat-autocomplete #via="matAutocomplete">
                                <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                                <ng-container *ngIf="!isLoading">
                                    <mat-option (click)="formMapeamentoDocumentalEdit.get('q9').setValue(via.name)"
                                        *ngFor="let via of filtered" [value]="via.name">
                                        <span>{{via.name}}</span>
                                    </mat-option>
                                </ng-container>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>

                    <div class="form-group">
                        <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('VALOR')">
                            <mat-label>Valor do documento:</mat-label>
                            <input type="text" matInput placeholder="Digite para buscar opções"
                                autocomplete="new-responsavel" [matAutocomplete]="valor" [formControl]="search"
                                autocomplete="off" (keyup)="setVar('q6')" [value]="q6">
                            <mat-autocomplete #valor="matAutocomplete">
                                <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                                <ng-container *ngIf="!isLoading">
                                    <mat-option (click)="formMapeamentoDocumentalEdit.get('q6').setValue(valor.name)"
                                        *ngFor="let valor of filtered" [value]="valor.name">
                                        <span>{{valor.name}}</span>
                                    </mat-option>
                                </ng-container>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>


                </mat-expansion-panel>
            </mat-accordion>


            <mat-accordion>
                <mat-expansion-panel class="mt-1">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Fluxo, Compartilhamento & Consultas
                        </mat-panel-title>
                        <mat-panel-title>
                            <mat-icon>share</mat-icon>
                        </mat-panel-title>
                    </mat-expansion-panel-header>


                    <div class="form-group">
                        <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('CONSULTAS')">
                            <mat-label>Consultas:</mat-label>
                            <input type="text" matInput placeholder="Digite para buscar opções"
                                autocomplete="new-responsavel" [matAutocomplete]="consultas" [formControl]="search"
                                autocomplete="off" (keyup)="setVar('q14')" [value]="q14">
                            <mat-autocomplete #consultas="matAutocomplete">
                                <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                                <ng-container *ngIf="!isLoading">
                                    <mat-option
                                        (click)="formMapeamentoDocumentalEdit.get('q14').setValue(consultas.name)"
                                        *ngFor="let consultas of filtered" [value]="consultas.name">
                                        <span>{{consultas.name}}</span>
                                    </mat-option>
                                </ng-container>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>

                    <div class="form-group">
                        <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('QUANTIDADE_PESSOAS')">
                            <mat-label>Quantidade de pessoas que compartilham o documento:</mat-label>
                            <input type="text" matInput placeholder="Digite para buscar opções"
                                autocomplete="new-responsavel" [matAutocomplete]="qPessoas" [formControl]="search"
                                autocomplete="off" (keyup)="setVar('q11')" [value]="q11">
                            <mat-autocomplete #qPessoas="matAutocomplete">
                                <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                                <ng-container *ngIf="!isLoading">
                                    <mat-option
                                        (click)="formMapeamentoDocumentalEdit.get('q11').setValue(qPessoas.name)"
                                        *ngFor="let qPessoas of filtered" [value]="qPessoas.name">
                                        <span>{{qPessoas.name}}</span>
                                    </mat-option>
                                </ng-container>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>

                    <div class="form-group">
                        <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('FORMAS_ENVIO')">
                            <mat-label>Formas de envio do documento:</mat-label>
                            <input type="text" matInput placeholder="Digite para buscar opções"
                                autocomplete="new-responsavel" [matAutocomplete]="formasEnvio" [formControl]="search"
                                autocomplete="off" (keyup)="setVar('q12')" [value]="q12">
                            <mat-autocomplete #formasEnvio="matAutocomplete">
                                <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                                <ng-container *ngIf="!isLoading">
                                    <mat-option
                                        (click)="formMapeamentoDocumentalEdit.get('q12').setValue(formasEnvio.name)"
                                        *ngFor="let formasEnvio of filtered" [value]="formasEnvio.name">
                                        <span>{{formasEnvio.name}}</span>
                                    </mat-option>
                                </ng-container>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>

                    <div class="form-group">
                        <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('ASSINATURA')">
                            <mat-label>Tipo de assinatura:</mat-label>
                            <input type="text" matInput placeholder="Digite para buscar opções"
                                autocomplete="new-responsavel" [matAutocomplete]="assinatura" [formControl]="search"
                                autocomplete="off" (keyup)="setVar('q13')" [value]="q13">
                            <mat-autocomplete #assinatura="matAutocomplete">
                                <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                                <ng-container *ngIf="!isLoading">
                                    <mat-option
                                        (click)="formMapeamentoDocumentalEdit.get('q13').setValue(assinatura.name)"
                                        *ngFor="let assinatura of filtered" [value]="assinatura.name">
                                        <span>{{assinatura.name}}</span>
                                    </mat-option>
                                </ng-container>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>

                    <div class="form-group">
                        <mat-form-field class="inputs">
                            <mat-label>Compartilhado com qual Área(as)</mat-label>
                            <input matInput placeholder="Ex. arquivo" value="" formControlName="q37">
                        </mat-form-field>
                    </div>
                    <div class="form-group d-flex">
                        <mat-checkbox formControlName="q30" color="warn" class="mr-2"></mat-checkbox>
                        <mat-label>Possui emails em anexo?</mat-label>
                    </div>
                    <div class="form-group">
                        <mat-label>Nome da área/Setor/Departamento</mat-label> <br>
                        <mat-slide-toggle formControlName="q24" color="warn">Serviços Recursos Humanos Regionais
                        </mat-slide-toggle>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>

            <mat-accordion>
                <mat-expansion-panel class="mt-1">
                    <mat-expansion-panel-header>
                        <mat-panel-title>ROI - Retorno e Investimento</mat-panel-title>
                        <mat-panel-description>
                            <mat-icon>sell</mat-icon>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="form-group">
                        <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('ATUALIZACOES_DOCUMENTO')">
                            <mat-label>Atualizações do documento:</mat-label>
                            <input type="text" matInput placeholder="Digite para buscar opções"
                                autocomplete="new-responsavel" [matAutocomplete]="atDocumento" [formControl]="search"
                                autocomplete="off" (keyup)="setVar('q15')" [value]="q15">
                            <mat-autocomplete #atDocumento="matAutocomplete">
                                <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                                <ng-container *ngIf="!isLoading">
                                    <mat-option
                                        (click)="formMapeamentoDocumentalEdit.get('q15').setValue(atDocumento.name)"
                                        *ngFor="let atDocumento of filtered" [value]="atDocumento.name">
                                        <span>{{atDocumento.name}}</span>
                                    </mat-option>
                                </ng-container>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>

                    <div class="form-group">
                        <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('TEMPO_GASTO')">
                            <mat-label>Tempo mensal gasto nesta atividade em horas:</mat-label>
                            <input type="text" matInput placeholder="Digite para buscar opções"
                                autocomplete="new-responsavel" [matAutocomplete]="tempoGasto" [formControl]="search"
                                autocomplete="off" (keyup)="setVar('q16')" [value]="q16">
                            <mat-autocomplete #tempoGasto="matAutocomplete">
                                <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                                <ng-container *ngIf="!isLoading">
                                    <mat-option
                                        (click)="formMapeamentoDocumentalEdit.get('q16').setValue(tempoGasto.name)"
                                        *ngFor="let tempoGasto of filtered" [value]="tempoGasto.name">
                                        <span>{{tempoGasto.name}}</span>
                                    </mat-option>
                                </ng-container>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>

                </mat-expansion-panel>
            </mat-accordion>

            <mat-accordion>
                <mat-expansion-panel class="mt-1">
                    <mat-expansion-panel-header>
                        <mat-panel-title>Atendimento ao regulatório e auditorias</mat-panel-title>
                        <mat-panel-description>
                            <mat-icon>face</mat-icon>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="form-group d-flex">
                        <mat-checkbox class="mr-2" color="warn" formControlName="q31"></mat-checkbox>
                        <mat-label>É utilizado para Auditoria Interna/Externa?</mat-label>
                    </div>

                </mat-expansion-panel>

            </mat-accordion>

            <mat-accordion>
                <mat-expansion-panel class="mt-1">
                    <mat-expansion-panel-header>
                        <mat-panel-title>Armazenamento Digital & Não Digital</mat-panel-title>
                        <mat-panel-description>
                            <mat-icon>folder_shared</mat-icon>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="form-group">
                        <mat-form-field class="inputs">
                            <mat-label>Nome da Plataforma de Produção e/ou Armazenamento final do Documento</mat-label>
                            <input matInput placeholder="Ex. arquivo" value="" formControlName="q39">
                        </mat-form-field>
                    </div>


                    <div class="form-group">
                        <mat-form-field class="inputs">
                            <mat-label>Nome do Diretório</mat-label>
                            <input matInput placeholder="Ex. arquivo" value="" formControlName="q40">
                        </mat-form-field>
                    </div>

                    <div class="form-group">
                        <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('FORMATOS')">
                            <mat-label>Formatos:</mat-label>
                            <input type="text" matInput placeholder="Digite para buscar opções"
                                autocomplete="new-responsavel" [matAutocomplete]="formatos" [formControl]="search"
                                autocomplete="off" (keyup)="setVar('q10')" [value]="q10">
                            <mat-autocomplete #formatos="matAutocomplete">
                                <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                                <ng-container *ngIf="!isLoading">
                                    <mat-option
                                        (click)="formMapeamentoDocumentalEdit.get('q10').setValue(formatos.name)"
                                        *ngFor="let formatos of filtered" [value]="formatos.name">
                                        <span>{{formatos.name}}</span>
                                    </mat-option>
                                </ng-container>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>

                    <div class="form-group">
                        <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('QUANTIDADE_FOLHAS')">
                            <mat-label>Estimativa de quantidade de folhas a digitalizar:</mat-label>
                            <input type="text" matInput placeholder="Digite para buscar opções"
                                autocomplete="new-responsavel" [matAutocomplete]="qFolhas" [formControl]="search"
                                autocomplete="off" (keyup)="setVar('q23')" [value]="q23">
                            <mat-autocomplete #qFolhas="matAutocomplete">
                                <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                                <ng-container *ngIf="!isLoading">
                                    <mat-option (click)="formMapeamentoDocumentalEdit.get('q23').setValue(qFolhas.name)"
                                        *ngFor="let qFolhas of filtered" [value]="qFolhas.name">
                                        <span>{{qFolhas.name}}</span>
                                    </mat-option>
                                </ng-container>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field class="inputs">
                            <mat-label>Outras Formas de Nomear o Arquivo</mat-label>
                            <input matInput placeholder="Ex. arquivo" value="" formControlName="q34">
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field class="inputs">
                            <mat-label>Documentos Vinculados/Anexos</mat-label>
                            <input matInput placeholder="Ex. arquivo" value="" formControlName="q35">
                        </mat-form-field>
                    </div>


                    <div class="form-group">
                        <mat-form-field class="inputs">
                            <mat-label>Área que Criou o Documento</mat-label>
                            <input matInput placeholder="Ex. arquivo" value="" formControlName="q36">
                        </mat-form-field>
                    </div>
                    <div class="form-group d-flex">
                        <mat-checkbox color="warn" formControlName="q25" class="mr-2"></mat-checkbox>
                        <mat-label>Existe Documentos Arquivados na Área?</mat-label>
                    </div>

                    <div class="form-group">
                        <mat-form-field class="inputs">
                            <mat-label>Quantidade de Documentos Armazenados no Diretório</mat-label>
                            <input matInput placeholder="Ex. arquivo" value="" formControlName="q41">
                        </mat-form-field>
                    </div>
                    <div class="form-group d-flex">
                        <mat-checkbox color="warn" formControlName="q28" class="mr-2"></mat-checkbox>
                        <mat-label>Aplicar cor?</mat-label>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>

            <mat-accordion>
                <mat-expansion-panel class="mt-1">
                    <mat-expansion-panel-header>
                        <mat-panel-title>Paperless & Impressão de Documentos</mat-panel-title>
                        <mat-panel-description>
                            <mat-icon>local_printshop</mat-icon>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="form-group d-flex">
                        <mat-checkbox color="warn" formControlName="q26" class="mr-2"></mat-checkbox>
                        <mat-label>Esse Documento é Impresso?</mat-label>
                    </div>


                    <div class="form-group">
                        <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('QUANTIDADE_COPIAS')">
                            <mat-label>Quantidade de Impressões/Cópias Mensal:</mat-label>
                            <input type="text" matInput placeholder="Digite para buscar opções"
                                autocomplete="new-responsavel" [matAutocomplete]="qCopias" [formControl]="search"
                                autocomplete="off" (keyup)="setVar('q20')" [value]="q20">
                            <mat-autocomplete #qCopias="matAutocomplete">
                                <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                                <ng-container *ngIf="!isLoading">
                                    <mat-option (click)="formMapeamentoDocumentalEdit.get('q20').setValue(qCopias.name)"
                                        *ngFor="let qCopias of filtered" [value]="qCopias.name">
                                        <span>{{qCopias.name}}</span>
                                    </mat-option>
                                </ng-container>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>

                    <div class="form-group">
                        <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('PERIODO_DIGITALIZACAO')">
                            <mat-label>O documento foi digitalizado a partir de qual período?:</mat-label>
                            <input type="text" matInput placeholder="Digite para buscar opções"
                                autocomplete="new-responsavel" [matAutocomplete]="periodo" [formControl]="search"
                                autocomplete="off" (keyup)="setVar('q22')" [value]="q22">
                            <mat-autocomplete #periodo="matAutocomplete">
                                <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                                <ng-container *ngIf="!isLoading">
                                    <mat-option (click)="formMapeamentoDocumentalEdit.get('q22').setValue(periodo.name)"
                                        *ngFor="let periodo of filtered" [value]="periodo.name">
                                        <span>{{periodo.name}}</span>
                                    </mat-option>
                                </ng-container>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="form-group d-flex">
                        <mat-checkbox color="warn" formControlName="q27" class="mr-2"></mat-checkbox>
                        <mat-label>Sugere Digitalizar?</mat-label>
                    </div>


                </mat-expansion-panel>
            </mat-accordion>


            <mat-accordion>
                <mat-expansion-panel class="mt-1">
                    <mat-expansion-panel-header>
                        <mat-panel-title>Forma de Pesquisa/Recuperação</mat-panel-title>
                        <mat-panel-description>
                            <mat-icon>find_replace</mat-icon>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="form-group">
                        <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('INDEXADORES')">
                            <mat-label>Metadados/Indexadores:</mat-label>
                            <input type="text" matInput placeholder="Digite para buscar opções"
                                autocomplete="new-responsavel" [matAutocomplete]="indexadores" [formControl]="search"
                                autocomplete="off" (keyup)="setVar('q21')" [value]="q21">
                            <mat-autocomplete #indexadores="matAutocomplete">
                                <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                                <ng-container *ngIf="!isLoading">
                                    <mat-option
                                        (click)="formMapeamentoDocumentalEdit.get('q21').setValue(indexadores.name)"
                                        *ngFor="let indexadores of filtered" [value]="indexadores.name">
                                        <span>{{indexadores.name}}</span>
                                    </mat-option>
                                </ng-container>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>

                </mat-expansion-panel>
            </mat-accordion>

            <mat-accordion>
                <mat-expansion-panel class="mt-1">
                    <mat-expansion-panel-header>
                        <mat-panel-title>Tempo de Guarda, Descarte e Destinação Final</mat-panel-title>
                        <mat-panel-description>
                            <mat-icon>inventory_2</mat-icon>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="form-group">
                        <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('TEMPO_AREA')">
                            <mat-label>Tempo de arquivamento na área:</mat-label>
                            <input type="text" matInput placeholder="Digite para buscar opções"
                                autocomplete="new-responsavel" [matAutocomplete]="tempoArea" [formControl]="search"
                                autocomplete="off" (keyup)="setVar('q17')" [value]="q17">
                            <mat-autocomplete #tempoArea="matAutocomplete">
                                <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                                <ng-container *ngIf="!isLoading">
                                    <mat-option
                                        (click)="formMapeamentoDocumentalEdit.get('q17').setValue(tempoArea.name)"
                                        *ngFor="let tempoArea of filtered" [value]="tempoArea.name">
                                        <span>{{tempoArea.name}}</span>
                                    </mat-option>
                                </ng-container>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>


                    <div class="form-group">
                        <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('TEMPO_GUARDA')">
                            <mat-label>Tempo de arquivamento na empresa de guarda:</mat-label>
                            <input type="text" matInput placeholder="Digite para buscar opções"
                                autocomplete="new-responsavel" [matAutocomplete]="tempoGuarda" [formControl]="search"
                                autocomplete="off" (keyup)="setVar('q18')" [value]="q18">
                            <mat-autocomplete #tempoGuarda="matAutocomplete">
                                <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                                <ng-container *ngIf="!isLoading">
                                    <mat-option
                                        (click)="formMapeamentoDocumentalEdit.get('q18').setValue(tempoGuarda.name)"
                                        *ngFor="let tempoGuarda of filtered" [value]="tempoGuarda.name">
                                        <span>{{tempoGuarda.name}}</span>
                                    </mat-option>
                                </ng-container>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>

                    <div class="form-group d-flex">
                        <mat-checkbox color="warn" formControlName="q29" class="mr-2"></mat-checkbox>
                        <mat-label>Possui planilha de Controle do Conteúdo das Caixas Enviadas para Empresa de
                            Guarda?</mat-label>
                    </div>

                    <div class="form-group">
                        <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('DESTINACAO')">
                            <mat-label>Destinação final:</mat-label>
                            <input type="text" matInput placeholder="Digite para buscar opções"
                                autocomplete="new-responsavel" [matAutocomplete]="destinacao" [formControl]="search"
                                autocomplete="off" (keyup)="setVar('q19')" [value]="q19">
                            <mat-autocomplete #destinacao="matAutocomplete">
                                <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                                <ng-container *ngIf="!isLoading">
                                    <mat-option
                                        (click)="formMapeamentoDocumentalEdit.get('q19').setValue(destinacao.name)"
                                        *ngFor="let destinacao of filtered" [value]="destinacao.name">
                                        <span>{{destinacao.name}}</span>
                                    </mat-option>
                                </ng-container>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>

                    <div class="form-group">
                        <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('DESCARTE')">
                            <mat-label>Se houver midias anexos dos documentos (legado) arquivadas na empresa de guarda
                                pode descartar?:</mat-label>
                            <input type="text" matInput placeholder="Digite para buscar opções"
                                autocomplete="new-responsavel" [matAutocomplete]="descarte" [formControl]="search"
                                autocomplete="off" (keyup)="setVar('q5')" [value]="q5">
                            <mat-autocomplete #descarte="matAutocomplete">
                                <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                                <ng-container *ngIf="!isLoading">
                                    <mat-option (click)="formMapeamentoDocumentalEdit.get('q5').setValue(descarte.name)"
                                        *ngFor="let descarte of filtered" [value]="descarte.name">
                                        <span>{{descarte.name}}</span>
                                    </mat-option>
                                </ng-container>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field class="inputs">
                            <mat-label>Legislação Federal, Estadual e Municipal</mat-label>
                            <input matInput placeholder="Ex. arquivo" value="" formControlName="q38">
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>


            <mat-accordion>
                <mat-expansion-panel class="mt-1">
                    <mat-expansion-panel-header>
                        <mat-panel-title>Compliance LGPD e Segurança da Informação</mat-panel-title>
                        <mat-panel-description>
                            <mat-icon>vpn_lock</mat-icon>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="form-group">
                        <mat-form-field appearance="fill" class=" w-100" (click)="filtrar('ACESSO')">
                            <mat-label>Acesso:</mat-label>
                            <input type="text" matInput placeholder="Digite para buscar opções"
                                autocomplete="new-responsavel" [matAutocomplete]="acesso" [formControl]="search"
                                autocomplete="off" (keyup)="setVar('q7')" [value]="q7">
                            <mat-autocomplete #acesso="matAutocomplete">
                                <mat-option *ngIf="isLoading" class="is-loading">Carregando...</mat-option>
                                <ng-container *ngIf="!isLoading">
                                    <mat-option (click)="formMapeamentoDocumentalEdit.get('q7').setValue(acesso.name)"
                                        *ngFor="let acesso of filtered" [value]="acesso.name">
                                        <span>{{acesso.name}}</span>
                                    </mat-option>
                                </ng-container>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>

                    <div class="form-group d-flex">
                        <mat-checkbox color="warn" formControlName="q33" class="mr-2"></mat-checkbox>
                        <mat-label>É Utilizado como prova para Apoiar Contencioso?</mat-label>
                    </div>
                    <div class="form-group d-flex">
                        <mat-checkbox color="warn" formControlName="q32" class="mr-2"></mat-checkbox>
                        <mat-label>Atende Normativa de Agências regulatórias</mat-label>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>

            <div class="modal-footer mt-5">
                <button mat-button color="primary" (click)="updateMatriz()">Enviar</button>
            </div>


        </mat-card>


    </div>

</form>