import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { AllChart } from "../_models/tickets";

@Injectable({
  providedIn: "root",
})
export class TicketService {
  constructor(private http: HttpClient) {}

  getTicketsPaged(
    page,
    size,
    sort,
    order,
    email,
    startDate,
    endDate,
    subCategory
  ) {
    return this.http.get<any>(
      `${environment.apiUrl}/ticket/search/${page}/${size}?sort=${sort}&order=${order}&email=${email}&startDate=${startDate}&endDate=${endDate}&subCategory=${subCategory}`
    );
  }

  getAllTicketsFiltered(
    page,
    size,
    sort,
    order,
    userId,
    ticketId,
    formName,
    segment,
    email,
    subCategory,
    status,
    startDate,
    endDate,
    userType
  ) {
    return this.http.get<any>(
      `${environment.apiUrl}/ticket/search/filtered/${page}/${size}?sort=${sort}&order=${order}&userId=${userId}&ticketId=${ticketId}&email=${email}&formName=${formName}&segment=${segment}&subCategory=${subCategory}&status=${status}&startDate=${startDate}&endDate=${endDate}&userType=${userType}`
    );
  }

  getFilterTickets(
    page: number,
    size: number,
    sort: string,
    order: string,
    userId: number,
    ticketId: number,
    formName: string,
    segment: string,
    email: string,
    subCategory: string,
    status: string,
    startDate: string,
    endDate: string,
    userType: string
  ) {
    return this.http.get<any>(
      `${environment.apiUrl}/ticket/search/filtered/${page}/${size}`,
      {
        params: new HttpParams()
          .set("sort", sort)
          .set("order", order)
          .set("userId", userId)
          .set("ticketId", ticketId)
          .set("formName", formName)
          .set("segment", segment)
          .set("email", email)
          .set("subCategory", subCategory)
          .set("status", status)
          .set("startDate", startDate)
          .set("endDate", endDate)
          .set("userType", userType),
      }
    );
  }

  getFilterTicketsMyTickets(
    userId: number,
    page: number,
    size: number,
    sort: string,
    order: string,
    ticketId: number,
    formName: string,
    segment: string,
    email: string,
    subCategory: string,
    status: string,
    startDate: string,
    endDate: string,
    userType: string
  ) {
    return this.http.get<any>(
      `${environment.apiUrl}/ticket/search/filtered/my-tickets/${userId}/${page}/${size}`,
      {
        params: new HttpParams()
          .set("sort", sort)
          .set("order", order)
          .set("ticketId", ticketId)
          .set("formName", formName)
          .set("segment", segment)
          .set("email", email)
          .set("subCategory", subCategory)
          .set("status", status)
          .set("startDate", startDate)
          .set("endDate", endDate)
          .set("userType", userType),
      }
    );
  }

  getAllTicketsFilteredMyTickets(
    userId,
    page,
    size,
    sort,
    order,
    ticketId,
    formName,
    segment,
    email,
    subCategory,
    status,
    startDate,
    endDate,
    userType
  ) {
    return this.http.get<any>(
      `${environment.apiUrl}/ticket/search/filtered/my-tickets/${userId}/${page}/${size}?sort=${sort}&order=${order}&ticketId=${ticketId}&formName=${formName}&segment=${segment}&email=${email}&subCategory=${subCategory}&status=${status}&startDate=${startDate}&endDate=${endDate}&userType=${userType}`
    );
  }

  getByIdPaged(ticketId, page, size, sort, order) {
    return this.http.get<any>(
      `${environment.apiUrl}/ticket/search/id/${ticketId}/${page}/${size}?sort=${sort}&order=${order}`
    );
  }

  getTicketsPagedFilterdForm(
    userId,
    status,
    page,
    size,
    sort,
    order,
    startDate,
    endDate,
    creationDate,
    userTicketId,
    category,
    subCategory
  ) {
    return this.http.get<any>(
      `${environment.apiUrl}/ticket/search/form/filter/${userId}/${status}/${page}/${size}?sort=${sort}&order=${order}&startDate=${startDate}&endDate=${endDate}&creationDate=${creationDate}&userTicketId=${userTicketId}&category=${category}&subCategory=${subCategory}`
    );
  }

  getTicketById(id) {
    return this.http.get(`${environment.apiUrl}/ticket/${id}`);
  }

  acceptTicket(acceptTicketDto) {
    return this.http.put(
      `${environment.apiUrl}/ticket/accept-ticket`,
      acceptTicketDto
    );
  }

  ticketAttributor(ticketAttributorDto) {
    return this.http.put(
      `${environment.apiUrl}/ticket/ticket-attributor`,
      ticketAttributorDto
    );
  }

  acceptStep(acceptStepDto) {
    return this.http.put(
      `${environment.apiUrl}/ticket/accept-step`,
      acceptStepDto
    );
  }

  reOpenTicket(acceptTicketDto) {
    return this.http.put(
      `${environment.apiUrl}/ticket/reopen-ticket`,
      acceptTicketDto
    );
  }

  getTicketsByUser(
    userId,
    filter,
    page,
    size,
    sort,
    order,
    startDate,
    endDate,
    creationDate,
    userTicketId,
    category,
    subCategory
  ) {
    return this.http.get<any>(
      `${environment.apiUrl}/ticket/search/${userId}/${filter}/${page}/${size}?sort=${sort}&order=${order}&startDate=${startDate}&endDate=${endDate}&creationDate=${creationDate}&userTicketId=${userTicketId}&category=${category}&subCategory=${subCategory}`
    );
  }

  getAllTickets() {
    return this.http.get<any>(`${environment.apiUrl}/ticket/all`);
  }

  saveOrUpdate(ticketDto) {
    return this.http.post(`${environment.apiUrl}/ticket`, ticketDto);
  }

  updateTicket(ticketDto) {
    return this.http.put(`${environment.apiUrl}/ticket`, ticketDto);
  }

  updateTicketItem(ticketItemDto) {
    return this.http.put(
      `${environment.apiUrl}/ticket/update-ticket-item`,
      ticketItemDto
    );
  }

  addComment(ticketId, commentDto) {
    return this.http.put(
      `${environment.apiUrl}/ticket/add-comment/${ticketId}`,
      commentDto
    );
  }

  changeTicketStatus(changeTicketStatusDto, userId) {
    return this.http.put(
      `${environment.apiUrl}/ticket/change-ticket-status/${userId}`,
      changeTicketStatusDto
    );
  }

  changeTicketHolder(ticketAttendantUserDto) {
    return this.http.put(
      `${environment.apiUrl}/ticket/change-ticket-holder`,
      ticketAttendantUserDto
    );
  }

  changeTicketReleaseDate(ticketReleaseDateDto) {
    return this.http.put(
      `${environment.apiUrl}/ticket/change-ticket-release`,
      ticketReleaseDateDto
    );
  }

  changeTicketReleaseParametrization(ticketReleaseParametrizationDto) {
    return this.http.put(
      `${environment.apiUrl}/ticket/change-ticket-release-parametrization`,
      ticketReleaseParametrizationDto
    )
  }

  changeVigenciaPeriodo(ticketVigenciaPeriodoDto) {
    return this.http.put(
      `${environment.apiUrl}/ticket/change-ticket-vigencia-periodo`,
      ticketVigenciaPeriodoDto
    );
  }

  changeNomeDemanda(ticketNomeDemandaDto) {
    return this.http.put(
      `${environment.apiUrl}/ticket/change-nome-demanda`,
      ticketNomeDemandaDto
    );
  }

  changeTicketCategory(ticketCategoryDto) {
    return this.http.put(
      `${environment.apiUrl}/ticket/change-ticket-category`,
      ticketCategoryDto
    );
  }

  removeAnexoTicket(ticketRemoveAnexoDto) {
    return this.http.put(
      `${environment.apiUrl}/ticket/remove-anexo-ticket`,
      ticketRemoveAnexoDto
    );
  }

  addAnexoHistoryTicket(ticketAddAnexoDto) {
    return this.http.put(
      `${environment.apiUrl}/ticket/add-anexo-ticket`,
      ticketAddAnexoDto
    );
  }

  getAllTicketChart() {
    return this.http.get<AllChart>(`${environment.apiUrl}/ticket/all-chart`);
  }

  getAllTicketChartByUserId(userId: number | string) {
    return this.http.get<AllChart>(
      `${environment.apiUrl}/ticket/all-chart-by-user/${userId}`
    );
  }
  changeTicketIdRecalculoEsforcoTotal(ticketIdRecalculoEsforcoTotalDto) {
    return this.http.put(
      `${environment.apiUrl}/ticket/change-ticket-id-recalculo-esforco-total`,
      ticketIdRecalculoEsforcoTotalDto
    );
  }
  setAnexoStepForm(setAnexoStepFormDto) {
    return this.http.put(
      `${environment.apiUrl}/ticket/anexo-step`,
      setAnexoStepFormDto
    );
  }
  setUserDelegate(setUserDelegate) {
    return this.http.put(
      `${environment.apiUrl}/ticket/add-delegate`,
      setUserDelegate
    );
  }
  cancelUserDelegate(ticketId) {
    return this.http.put(
      `${environment.apiUrl}/ticket/remove-delegate/${ticketId}`,
      {}
    );
  }
}
