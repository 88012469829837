import { MatPaginator } from "@angular/material/paginator";
import { FormBuilder, FormGroup } from "@angular/forms";
import { FormService } from "src/app/_services/form.service";
import { AccountService } from "src/app/_services";
import { User } from "src/app/_models";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Component, OnInit, ViewChild } from "@angular/core";

@Component({
  selector: "app-abrir-ticket",
  templateUrl: "./abrir-ticket.component.html",
  styleUrls: ["./abrir-ticket.component.css"],
})
export class AbrirTicketComponent implements OnInit {
  user: User;
  path: string;
  constructor(
    private accountService: AccountService,
    private formService: FormService,
    private formBuilder: FormBuilder
  ) {
    this.user = this.accountService.userValue.user;
  }

  getUrl() {
    this.path =
      location.href.split("/")[0] + "//" + location.href.split("/")[2] + "/";
  }

  filters: FormGroup;
  forms: Form[];
  visualization: boolean;

  @ViewChild(MatSort) sort: MatSort;

  private paginator: MatPaginator;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
  }

  displayedColumns: string[] = [
    "name",
    "category",
    "subcategory",
    "description",
    "action",
  ];
  dataSource = new MatTableDataSource<Form>(ELEMENT_DATA);

  ngOnInit(): void {
    this.visualization = true;
    this.filters = this.formBuilder.group({
      category: [""],
    });
    this.getUrl();
    console.log(this.path);
  }

  ngAfterViewInit() {
    this.getAllForms();
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getAllForms() {
    this.formService
      .getFormByTicketSubcategoryEnum(this.user.id, "TODOS")
      .subscribe((data) => {
        this.dataSource = new MatTableDataSource(data);
        this.setDataSourceAttributes();
        this.forms = data;
      });
  }

  getFormsFiltered(subCategory) {
    this.formService
      .getFormByTicketSubcategoryEnum(this.user.id, subCategory)
      .subscribe((data) => {
        this.dataSource = new MatTableDataSource(data);
        this.setDataSourceAttributes();
        this.forms = data;
      });
  }
  switchVisualization(state: boolean) {
    this.visualization = state;
  }
}

export interface Form {
  name: string;
  description: string;
  area: string;
  categoria: string;
  action: string;
}

const ELEMENT_DATA: Form[] = [
  {
    name: "Formulário 1",
    area: "OPERACAO_BPO",
    categoria: "PROJETOS_E_DEMANDAS",
    description: "Descrição do formulario",
    action: "",
  },
  {
    name: "Formulário 2",
    area: "OPERACAO_BPO",
    categoria: "PROJETOS_E_DEMANDAS",
    description: "Descrição do formulario",
    action: "",
  },
  {
    name: "Formulário 3",
    area: "OPERACAO_BPO",
    categoria: "PROJETOS_E_DEMANDAS",
    description: "Descrição do formulario",
    action: "",
  },
];
