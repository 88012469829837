// workaround environment config to get example working in stackblitz
export const environment = {
  /**
   * Por favor lembrar de não commitar esse arquivo.
   * Ao commitar esse arquivo, tem grande chance de quebrar a produção.
   * Indicado colocar arquivo dentro do .gitignore
   */

  production: false,

  apiUrl: "https://app.elevasign.com.br/portaldecomissionamentoapi",
  //apiUrl: "http://localhost:9904",
};
