import { first, tap } from "rxjs/operators";

import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";

import { AlertService } from "../_services";
import { GrupoEmpresaService } from "../_services/grupo-empresa.service";
import { PedidoPortalService } from "../_services/pedido-portal.service";

export interface PeriodicElement {
  id: string;
  campo: string;
  conteudo: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    id: "1",
    campo: "Nome da Empresa",
    conteudo: "Maria Raiane dos Santos Silva",
  },
  { id: "2", campo: "Estado", conteudo: "Sergipe" },
  { id: "3", campo: "CNPJ da Empresa", conteudo: "06.833.186/596" },
  { id: "4", campo: "Número Documento", conteudo: "1" },
  { id: "5", campo: "Ano", conteudo: "Todo" },
];

export interface StateGroup {
  letter: string;
  names: string[];
}

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().indexOf(filterValue) === 0);
};

@Component({
  selector: "app-pedidos-online",
  templateUrl: "./pedidos-online.component.html",
  styleUrls: ["./pedidos-online.component.css"],
})
export class PedidosOnlineComponent implements OnInit {
  // displayedColumns2: string[] = ['id', 'campo', 'conteudo'];
  // dataSource2 = ELEMENT_DATA;

  formEmpresaValue = "";
  empresas: any[] = [];
  empresaId: number = 0;
  tipoSolicitacao = "Todas";

  pedidos: any[] = [];
  dataSource: MatTableDataSource<any>;
  tableFilter = 0;
  displayedColumns: string[] = [
    "id",
    "criadoEm",
    "razaoSocial",
    "solicitante",
    "tipoSolicitacao",
    "urgente",
    "status",
    "acoes",
  ];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  length = 100;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  pageable: any;
  token: string;
  // MatPaginator Output
  pageEvent: PageEvent;
  formEmpresa: FormGroup;

  constructor(
    private pedidoPortalservice: PedidoPortalService,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private grupoEmpresaService: GrupoEmpresaService
  ) {}
  ngOnInit() {
    this.formEmpresa = this.formBuilder.group({
      id: [""],
      idEmpresa: [""],
      tipoSolicitacao: ["Todas"],
    });

    $("#firstButton").click();

    this.getAllEmpresas();
    this.getAllpedidos();
  }

  filtrar(number) {
    this.tableFilter = number;
    console.log(this.tableFilter);
    this.getAllpedidos();
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.onPageController())).subscribe();
  }

  onPageController() {
    this.pageSize = this.pageEvent.pageSize;
    this.pageIndex = this.pageEvent.pageIndex;

    this.alertService.clear();

    //this.loading = true;

    this.pedidoPortalservice
      .getAllPedidoPortalFilter(
        this.tableFilter,
        this.empresaId,
        this.tipoSolicitacao,
        this.pageIndex,
        this.pageSize,
        "id",
        "asc"
      )
      .pipe(first())
      .subscribe(
        (response) => {
          this.dataSource = new MatTableDataSource(response["content"]);

          this.pageable = response["pageable"];
          //this.carregandoTabela = true;

          this.pageable.length = response["totalElements"];
          // this.length = this.pageable.totalRecordCount;
        },
        (error) => {
          this.alertService.error(error);
          // this.carregandoTabela = true;
          // this.loading = false;
        }
      );
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  getAllpedidos() {
    this.pedidoPortalservice
      .getAllPedidoPortalFilter(
        this.tableFilter,
        this.empresaId,
        this.tipoSolicitacao,
        this.pageIndex,
        this.pageSize,
        "id",
        "asc"
      )
      .subscribe((response) => {
        this.dataSource = new MatTableDataSource(response["content"]);

        this.pageable = response["pageable"];

        this.length = response["totalElements"];
        //this.carregandoTabela = true;

        // this.length = this.pageable.totalRecordCount;
        //this.dataSource.sort = this.sort;
      });
  }

  recepcinarPedidos(id) {
    this.router.navigate(["/recepcionar-pedidos", id], {
      relativeTo: this.route,
    });
  }

  concluirPedidos(id) {
    this.router.navigate(["/concluir-pedido", id], { relativeTo: this.route });
  }

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  // }

  getAllEmpresas() {
    this.grupoEmpresaService.getAllGrupoEmpresa().subscribe((response) => {
      this.empresas = response;
    });
  }

  selectFilter() {
    this.formEmpresaValue = this.formEmpresa.get("idEmpresa").value;
    this.empresaId = Number(this.formEmpresaValue);

    this.tipoSolicitacao = this.formEmpresa.get("tipoSolicitacao").value;
    console.log(this.tipoSolicitacao);
    this.getAllpedidos();
  }

  visualizarPedidoConcluido(id) {
    this.router.navigate(["/visualizar-pedido", id], {
      relativeTo: this.route,
    });
  }

  filterByIdSubmit() {
    var id = this.formEmpresa.get("id").value;

    console.log(id);

    if (id !== "") {
      this.pedidoPortalservice.getPedidoById(id).subscribe((response) => {
        var templist = [];
        templist.push(response["pedidoOnlineDto"]);

        this.dataSource = new MatTableDataSource(templist);
        // this.dataSource.sort = this.sort;
        // this.dataSource.paginator = this.paginator;
      });
    }
  }

  get f() {
    return this.formEmpresa.controls;
  }
}
