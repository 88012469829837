import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { EscolhaAgendamento } from '../_models/escolha-agendamento';
import { EscolhaAgendamentoService } from '../_services/escolha-agendamento.service';
import { MapeamentodocumentalService } from '../_services/mapeamentodocumental.service';
import { OpcoesAgendamentoService } from '../_services/opcoes-agendamento.service';

@Component({
    selector: 'app-escolha-agendamento',
    templateUrl: './escolha-agendamento.component.html',
    styleUrls: ['./escolha-agendamento.component.css']
})

export class EscolhaAgendamentoComponent implements OnInit{

    formEscolhaAgendamento: FormGroup;
    mapeamento: any;
    id: any;
    dataEscolhida: Date;
    datasDisponiveis: any[]=[];
    
    // datasDisponiveis: any[] = ["8:00 - 14/09/2021", "8:30 - 14/09/2021", "9:00 - 14/09/2021", "9:30 - 14/09/2021", "10:00 - 14/09/2021", "10:30 - 14/09/2021", "11:00 - 14/09/2021", "11:30 - 14/09/2021", "12:00 - 14/09/2021", "12:30 - 14/09/2021", "13:00 - 14/09/2021", "13:30 - 14/09/2021", "14:00 - 14/09/2021", "14:30 - 14/09/2021", ];
    constructor (private formBuilder:FormBuilder, private escolhaAgendamentoService: EscolhaAgendamentoService,
        private mapeamentodocumentalService: MapeamentodocumentalService,
        private opcoesAgendamentoService: OpcoesAgendamentoService,
        private route: ActivatedRoute,
        private router: Router){

            this.route.queryParams.subscribe(params => {
                var id = this.route.snapshot.paramMap.get('id');
                console.log(id);
            
                if(id){
                  this.id = id;
                  this.getMapeamentoById(this.id);
                }
                  
              });
        }
    
    ngOnInit(){
        this.formEscolhaAgendamento = this.formBuilder.group({
            dataEscolhida: ["", Validators.required]
        })
    }
    getMapeamentoById(id){
        this.mapeamentodocumentalService.getMapeamentoById(id).subscribe(response=>{
    
         this.mapeamento = response;
         this.datasDisponiveis = this.mapeamento.agendamentoEntrevista.opcoesDeDatas;
         console.log(this.mapeamento);
         console.log(this.datasDisponiveis);
        });
      }
    enviarDataEscolhida(){
        this.dataEscolhida = new Date(this.formEscolhaAgendamento.value.dataEscolhida);
        this.opcoesAgendamentoService.sendDataEscolhida(this.dataEscolhida, this.id).subscribe(response=>{
            this.router.navigate(['/monitoramento'], { relativeTo: this.route });
        });
    }
}