<!-- <p class="text-muted mt-4 mb-1">
  <span class="text-primary">Portal de Comissionamento</span>
</p> -->

<ul class="breadcrumb">
  <li>
    <a routerLink="" class="button-flex">
      <mat-icon class="material-symbols-outlined">home</mat-icon>
    </a>
  </li>

  <li>
    <a routerLink="../"> Projetos e Demandas </a>
  </li>

  <li>
    <a> Prorrogação de Campanha </a>
  </li>
</ul>

<h1 class="text-muted">Prorrogação de Campanha</h1>

<div class="row">
  <div class="col-sm-6 mb-4">
    <div class="d-flex mb-2">
      <p class="text-muted mr-auto">Adicionar uma Prorrogação de Campanha</p>

      <!-- <button matTooltip="Editar Produto" type="button" class="btn btn-success btn-sm button-flex"
                data-toggle="modal" data-target="#editar">
                <mat-icon class="mr-0">edit</mat-icon>
            </button> -->
    </div>

    <form [formGroup]="form">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Nome da Demanda:</mat-label>
        <input
          matInput
          type="text"
          formControlName="nameDemand"
          name="nameDemand"
          id="nameDemand"
          [ngClass]="{ 'is-invalid': submitted && f.nameDemand.errors }"
          maxlength="300"
          required
        />

        <div *ngIf="submitted && f.nameDemand.errors" class="invalid-feedback">
          <div *ngIf="f.nameDemand.errors.required">
            É necessário adicionar Nome da Demanda
          </div>
          <div *ngIf="f.nameDemand.errors.minlength">
            O Nome da Demanda deve ter pelo menos 3 caracteres
          </div>
          <div *ngIf="f.nameDemand.errors.maxlength">
            O Nome da Demanda não deve exceder 300 caracteres
          </div>
        </div>
      </mat-form-field>

      <mat-label class="text-muted mr-auto">Segmento Parceiro:</mat-label>
      <div style="margin: 10px 0">
        <mat-checkbox class="w-100" formControlName="check1">B2B</mat-checkbox>
        <mat-checkbox class="w-100" formControlName="check2">B2C</mat-checkbox>
        <mat-checkbox class="w-100" formControlName="check3"
          >PRICE</mat-checkbox
        >
      </div>

      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Id. Anterior:</mat-label>
        <input
          matInput
          type="text"
          formControlName="lastId"
          name="lastId"
          id="lastId"
          [ngClass]="{ 'is-invalid': submitted && f.lastId.errors }"
          maxlength="300"
          required
        />

        <div *ngIf="submitted && f.lastId.errors" class="invalid-feedback">
          <div *ngIf="f.lastId.errors.required">
            É necessário adicionar Id. Anterior
          </div>
          <div *ngIf="f.lastId.errors.minlength">
            O Id. Anterior deve ter pelo menos 3 caracteres
          </div>
          <div *ngIf="f.lastId.errors.maxlength">
            O Id. Anterior não deve exceder 300 caracteres
          </div>
        </div>
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Data de Início de Vigência: </mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          formControlName="effectiveDate"
          [ngClass]="{ 'is-invalid': submitted && f.effectiveDate.errors }"
          required
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <div
          *ngIf="submitted && f.effectiveDate.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.effectiveDate.errors.required">
            É necessário adicionar a Data de Início de Vigência
          </div>
          <div *ngIf="f.effectiveDate.errors.minlength">
            A Data de Início de Vigência deve ter pelo menos 7 caracteres
          </div>
        </div>
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Detalhes da Solicitação:</mat-label>
        <textarea
          matInput
          type="text"
          formControlName="detailsSolicitation"
          name="detailsSolicitation"
          placeholder="Espaço para detalhar o que deseja"
          id="detailsSolicitation"
          [ngClass]="{
            'is-invalid': submitted && f.detailsSolicitation.errors
          }"
          required
        ></textarea>

        <div
          *ngIf="submitted && f.detailsSolicitation.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.detailsSolicitation.errors.required">
            É necessário adicionar Detalhes da Solicitação
          </div>
          <div *ngIf="f.detailsSolicitation.errors.minlength">
            O Detalhes da Solicitação deve ter pelo menos 3 caracteres
          </div>
        </div>
      </mat-form-field>

      <div class="model-file">
        <div class="model-file-heading">
          <h4>Arquivo Modelo</h4>
          <small
            >Este Tipo de Solicitação possui um arquivo modelo que deverá ser
            utilizado na abertura da solicitação.</small
          >
        </div>
        <div class="model-file-body">
          <table
            mat-table
            [dataSource]="dataSource"
            class="mat-elevation-z8"
            style="box-shadow: none"
          >
            <ng-container matColumnDef="fileName">
              <th mat-header-cell *matHeaderCellDef>Nome do Arquivo</th>
              <td mat-cell *matCellDef="let element">
                {{ element.originalFileName }}
              </td>
            </ng-container>

            <ng-container matColumnDef="inclusionDate">
              <th mat-header-cell *matHeaderCellDef>Data da Inclusão</th>
              <td mat-cell *matCellDef="let element">
                {{ element.creationDt }}
              </td>
            </ng-container>

            <ng-container matColumnDef="download">
              <th mat-header-cell *matHeaderCellDef>Download</th>

              <td mat-cell *matCellDef="let element">
                <!-- <button class="btn btn-sm btn-primary download"> -->
                <button
                  type="button"
                  class="btn btn-sm btn-primary mr-1"
                  (click)="downloadAnexoForm(element.id)"
                >
                  <mat-icon color="link" matListIcon>cloud_download</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
        <div class="bg-white col-12 py-3 my-4" *ngIf="formPermissions()">
          <div class="col-12">
            <h4 class="text-primary">Área Administrativa</h4>
            <p class="text-muted">
              Área para alteração de arquivos modelo de preenchimento do
              formulário
            </p>
            <button
              type="button"
              data-target="#anexosForm"
              data-toggle="modal"
              class="btn button-flex btn-sm btn-primary text-white mt-2"
              matTooltip="Editar Arquivos Modelo"
            >
              <mat-icon>edit</mat-icon>
              Editar Arquivos Modelos
            </button>
          </div>
        </div>
      </div>
      <div class="mb-2 mt-2">
        <!-- <ul>
              <li *ngFor="let attachment of attachments">
                  {{attachment.originalFileName}}
              </li>
          </ul> -->
        <mat-card-content>
          <div class="row">
            <div class="col-lg-6" style="align-self: left">
              <ul>
                <li
                  *ngFor="let attachment of attachments"
                  style="
                    line-height: 16px;
                    padding: 20px 10px;
                    white-space: nowrap;
                    overflow: hidden;
                    height: 60px;
                  "
                >
                  {{ attachment.originalFileName }}
                </li>
              </ul>
            </div>
            <div class="col-lg-3" style="align-self: right">
              <ul>
                <li
                  *ngFor="let file of files"
                  style="padding: 20px 10px; line-height: 16px; height: 60px"
                >
                  {{ file.progress }}
                </li>
              </ul>
            </div>
            <div class="col-lg-3" style="align-self: right">
              <ul>
                <li
                  *ngFor="let attachment of attachments"
                  style="padding: 13px 10px; height: 60px"
                >
                  <span
                    class="btn btn-sm mr-2 btn-danger"
                    (click)="removeFile(attachment)"
                  >
                    <mat-icon>close</mat-icon>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-12" style="align-self: center">
            <ul>
              <li *ngIf="files.length > 0" style="margin: 30xp 0">
                <mat-progress-bar [value]="somArray(files)"></mat-progress-bar>
                <span id="file-label"></span>
              </li>
            </ul>
          </div>
        </mat-card-content>

        <div class="form-group">
          <div class="input-group">
            <div class="custom-file">
              <input
                type="file"
                #fileUpload
                id="fileUpload"
                name="fileUpload"
                multiple="multiple"
                accept="image/*,
                            application/pdf,
                            application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                            application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                            application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation,
                            .txt"
                class="custom-file-input"
                (click)="onUploadButtonClick()"
              />
              <label class="custom-file-label" for="anexarDocumento"
                >Upload</label
              >
            </div>
            <!-- <div class="input-group-append">
                  <button class="btn btn-outline-secondary" type="button" >Enviar</button>
              </div> -->
          </div>
        </div>
      </div>

      <div class="d-flex">
        <button
          routerLink="../"
          type="button"
          class="btn btn-dark btn-sm button-flex mr-2"
          data-dismiss="modal"
        >
          <mat-icon>highlight_off</mat-icon>
          Cancelar
        </button>

        <button
          type="button"
          (click)="onReset()"
          class="btn btn-secondary button-flex btn-sm mr-2"
        >
          <mat-icon>backspace</mat-icon>
          Limpar
        </button>

        <button
          type="button"
          class="btn btn-success btn-sm button-flex"
          data-toggle="modal"
          data-target="#confirmDoc"
          (click)="setFalse()"
        >
          <mat-icon>task_alt</mat-icon>
          Enviar
        </button>
      </div>

      <div class="modal fade" id="confirmDoc">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <p
                class="modal-title mb-0 text-muted"
                id="TituloModalCentralizado"
              >
                Confirmação de Envio de Documentos Modelo
              </p>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Fechar"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
              <div class="mt-4 pb-4">
                <div class="w-100 d-flex justify-content-center">
                  <mat-icon
                    class="text-muted"
                    style="font-size: 30pt !important"
                    >info</mat-icon
                  >
                </div>
                <h3 class="text-center text-muted mt-4">
                  Para efetuar a criação do Ticket é necessário o envio dos
                  Arquivos Modelos presente no Formulário.
                </h3>
              </div>
            </div>

            <div
              class="modal-footer d-flex align-items-center justify-content-between"
            >
              <mat-checkbox
                class="d-flex align-items-center"
                formControlName="confirmation"
                >Declaro ter enviado os documentos modelos
              </mat-checkbox>
              <button
                type="submit"
                class="btn btn-success btn-sm button-flex"
                [disabled]="!check()"
                data-dismiss="modal"
                (click)="onSubmit()"
              >
                <mat-icon>task_alt</mat-icon>
                Enviar
              </button>
              <button
                #closeModalEditar
                [hidden]="true"
                type="button"
                data-dismiss="modal"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<button
  type="button"
  #openModal
  data-toggle="modal"
  data-target="#confirmed"
  [hidden]="true"
></button>

<div class="modal fade" id="confirmed">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title mb-0 text-muted" id="TituloModalCentralizado">
          Criação de Ticket
        </p>
        <button
          type="button"
          class="close"
          aria-label="Fechar"
          data-dismiss="modal"
          (click)="navigateToMain()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="mt-4 pb-4">
          <div class="w-100 d-flex justify-content-center">
            <mat-icon class="text-success" style="font-size: 30pt !important"
              >check_circle</mat-icon
            >
          </div>
          <h3 class="text-center text-muted mt-4">
            Solicitação criada com sucesso!
          </h3>
          <p class="text-center text-muted mt-4">
            O ID da sua solicitação é {{ ticketId }}
          </p>
        </div>
      </div>
      <div class="modal-footer">
        <button
          data-dismiss="modal"
          type="button"
          class="btn-sm btn btn-secondary mr-2 btn-mat-icon"
          (click)="navigateToMain()"
        >
          <mat-icon class="modal-icon">arrow_back</mat-icon>
          Ir para o Menu
        </button>
        <button
          data-dismiss="modal"
          type="button"
          class="btn-sm btn btn-success mr-2 btn-mat-icon"
          (click)="navigateToTickets()"
        >
          <mat-icon class="modal-icon">arrow_outward</mat-icon>
          Meus Tickets
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="anexosForm">
  <form>
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title mb-0 text-muted" id="TituloModalCentralizado">
            Edição dos Arquivos Modelos
          </p>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Fechar"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <table
            mat-table
            [dataSource]="dataSource"
            class="mat-elevation-z8"
            style="box-shadow: none"
          >
            <ng-container matColumnDef="fileName">
              <th mat-header-cell *matHeaderCellDef>Nome do Arquivo</th>
              <td mat-cell *matCellDef="let element">
                {{ element.originalFileName }}
              </td>
            </ng-container>

            <ng-container matColumnDef="inclusionDate">
              <th mat-header-cell *matHeaderCellDef>Data da Inclusão</th>
              <td mat-cell *matCellDef="let element">
                {{ element.creationDt }}
              </td>
            </ng-container>

            <ng-container matColumnDef="download">
              <th mat-header-cell *matHeaderCellDef>Download</th>

              <td mat-cell *matCellDef="let element">
                <!-- <button class="btn btn-sm btn-primary download"> -->
                <button
                  type="button"
                  class="btn btn-sm btn-primary mr-1"
                  (click)="downloadAnexoForm(element.id)"
                >
                  <mat-icon color="link" matListIcon>cloud_download</mat-icon>
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger"
                  (click)="removeAnexoForm(element.id)"
                >
                  <mat-icon color="link" matListIcon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <!-- <a href="">
                    <mat-icon color="link" matListIcon>cloud_upload</mat-icon>
                  </a> -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>

          <div
            class="mt-4 d-flex justify-content-end"
            *ngIf="formsolicitation"
          ></div>
        </div>

        <mat-card-content>
          <div class="row">
            <div class="col-lg-6" style="align-self: left">
              <ul>
                <li
                  *ngFor="let anexo of anexos"
                  style="
                    line-height: 16px;
                    padding: 20px 10px;
                    white-space: nowrap;
                    overflow: hidden;
                    height: 60px;
                  "
                >
                  {{ anexo.originalFileName }}
                </li>
              </ul>
            </div>
            <div class="col-lg-3" style="align-self: right">
              <ul>
                <li
                  *ngFor="let arquivo of arquivos"
                  style="padding: 20px 10px; line-height: 16px; height: 60px"
                >
                  {{ arquivo.progress }}
                </li>
              </ul>
            </div>
            <div class="col-lg-3" style="align-self: right">
              <ul>
                <li
                  *ngFor="let anexo of anexos"
                  style="padding: 13px 10px; height: 60px"
                >
                  <span
                    class="btn btn-sm mr-2 btn-danger"
                    (click)="removeAnexoFormPreUpload(anexo)"
                  >
                    <mat-icon>close</mat-icon>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-12" style="align-self: center">
            <ul>
              <li *ngIf="arquivos.length > 0" style="margin: 30xp 0">
                <mat-progress-bar
                  [value]="somArray(arquivos)"
                ></mat-progress-bar>
                <span id="file-label"></span>
              </li>
            </ul>
          </div>
        </mat-card-content>

        <div class="d-flex flex-column">
          <div class="mt-4 pb-4 pl-3 pr-3">
            <div class="form-group">
              <div class="input-group">
                <div class="custom-file">
                  <input
                    type="file"
                    #anexoUpload
                    id="anexoUpload"
                    name="anexoUpload"
                    multiple="multiple"
                    accept="image/*,
                            application/pdf,
                            application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                            application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                            application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation,
                            .txt"
                    class="custom-file-input"
                    (click)="onUploadAnexoButtonClick()"
                  />
                  <label class="custom-file-label" for="anexoUpload"
                    >Adicionar Arquivos Modelo</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            *ngIf="formsolicitation.attachments.length > 0"
            (click)="removeAllAnexosForm()"
            class="btn button-flex btn-sm btn-danger"
          >
            <mat-icon>delete_forever</mat-icon>
            Remover todos os Arquivos Modelo
          </button>
          <button
            type="submit"
            (click)="onSubmitAnexoForm()"
            class="btn btn-success btn-sm button-flex"
            [disabled]="anexos.length === 0"
          >
            <mat-icon>task_alt</mat-icon>
            Salvar
          </button>
          <button
            #closeConfirmDoc
            [hidden]="true"
            type="button"
            data-dismiss="modal"
          ></button>
        </div>
      </div>
    </div>
  </form>
</div>
