<div id="chart" class="mt-4 pb-4">
    <p class="text-center pt-4">Insumos</p>
    <apx-chart
      [series]="chartOptions.series"
      [stroke]="chartOptions.stroke"
      [fill]="chartOptions.fill"
      [dataLabels]="chartOptions.dataLabels"
      [legend]="chartOptions.legend"
      [chart]="chartOptions.chart"
      [labels]="chartOptions.labels"
      [title]="chartOptions.title"
      [theme]="chartOptions.theme"
      [responsive]="chartOptions.responsive"
      [plotOptions]="chartOptions.plotOptions"
    ></apx-chart>
  </div>
