import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import { TooltipPosition } from "@angular/material/tooltip";
import * as Jquery from "../../assets/jquery-3.5.1.min";
import { User2AF } from "../_models";
import { AccountService } from "../_services";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.css"],
})
export class MenuComponent implements OnInit {
  @Input() data: User2AF;

  notifications: string[];
  totalNotifications: any;
  imageUrl: any;

  positionOptions: TooltipPosition[] = [
    "after",
    "before",
    "above",
    "below",
    "left",
    "right",
  ];
  position = new FormControl(this.positionOptions[0]);

  constructor(
    private dateAdapter: DateAdapter<Date>,
    private accountService: AccountService
  ) {
    this.dateAdapter.setLocale("pt-BR");
  }

  ngOnInit(): void {
    Jquery("#menu-toggle").click(function (e) {
      e.preventDefault();
      Jquery("#wrapper").toggleClass("toggled");

      function hasClass(element, cls) {
        return (" " + element.className + " ").indexOf(" " + cls + " ") > -1;
      }

      var el = document.getElementById("wrapper");
      if (screen.width >= 768 && hasClass(el, "toggled") == true) {
        $("#page-content-wrapper").removeClass("pcw-16");
        $("#page-content-wrapper").addClass("pcw-0");
      } else if (screen.width >= 768 && hasClass(el, "toggled") == false) {
        $("#page-content-wrapper").removeClass("pcw-0");
        $("#page-content-wrapper").addClass("pcw-16");
      }

      if (screen.width < 768 && hasClass(el, "toggled") == false) {
        $("#page-content-wrapper").removeClass("pcw-16");
        $("#page-content-wrapper").addClass("pcw-0");
      } else if (screen.width < 768 && hasClass(el, "toggled") == true) {
        $("#page-content-wrapper").removeClass("pcw-0");
        $("#page-content-wrapper").addClass("pcw-16");
      }
    });
  }

  logoVivo(el) {
    var display = document.getElementById(el).style.display;
    if (display == "none") document.getElementById(el).style.display = "block";
    else document.getElementById(el).style.display = "none";
  }

  // logout
  logout() {
    this.accountService.logout();
  }
}
