import { Component } from '@angular/core';

@Component({
    template: `
    <h1>olá</h1>
    router-outlet/router-outlet
    `
})

export class CadastroUserComponent {

}