import { User } from "src/app/_models";
import { AccountService } from "src/app/_services";
import { WikiService } from "src/app/_services/wiki.service";

import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { combineLatest } from "rxjs";
import { ProgressSpinnerMode } from "@angular/material/progress-spinner";

@Component({
  selector: "app-criar-arquivo",
  templateUrl: "./criar-arquivo.component.html",
  styleUrls: ["./criar-arquivo.component.css"],
})
export class CriarArquivoComponent implements OnInit {
  user: User;
  form: FormGroup;
  submitted: boolean = false;
  categories: any[] = [];
  selectedCategory: any = null;
  subcategories: any[] = [];
  isLoading: boolean = false;
  color: "white";
  mode: ProgressSpinnerMode = "indeterminate";

  thumbFiles: File[] = [];
  thumbImage: any;

  @ViewChild("fileUpload") fileUpload: any;
  @ViewChild("thumbUpload") thumbUpload: any;

  constructor(
    private router: Router,
    private wikiService: WikiService,

    private formBuilder: FormBuilder,
    private accountService: AccountService
  ) {
    this.user = this.accountService.userValue.user;
  }

  ngOnInit(): void {
    this.getCategories();

    this.form = this.formBuilder.group({
      title: ["", Validators.required],
      description: ["", Validators.required],
      categoryId: ["", Validators.required],
      subcategoryId: ["", Validators.required],
      fileType: ["", Validators.required],
      link: [""],
      area: [""],
      wikiBody: [""],
      areaPermissions: new FormGroup({
        EQUIPE_INTERNA: new FormControl(false, Validators.required),
        OPERACAO_VIVO: new FormControl(false, Validators.required),
        OPERACAO_BPO: new FormControl(false, Validators.required),
        TODOS: new FormControl(false, Validators.required),
      }),
    });
  }

  getCategories() {
    this.wikiService.getCategories().subscribe((res) => {
      this.categories = res;
    });
  }

  setSubcategories(category) {
    this.subcategories = category.subcategories;
  }

  getThumbFile() {
    this.thumbFiles = Array.from(this.thumbUpload.nativeElement.files);
    this.imagePreview(this.thumbFiles);
  }

  formatFileSize(fileSizeInBytes: number) {
    if (fileSizeInBytes < 1024) {
      return fileSizeInBytes + " bytes";
    } else if (fileSizeInBytes < 1024 * 1024) {
      return (fileSizeInBytes / 1024).toFixed(2) + " KB";
    } else {
      return (fileSizeInBytes / (1024 * 1024)).toFixed(2) + " MB";
    }
  }

  imagePreview(thumbFiles) {
    const selectedFile = thumbFiles[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const dataURL = e.target.result;
        this.thumbImage = dataURL;
      };
      reader.readAsDataURL(selectedFile);
    }
  }

  createWiki() {
    this.isLoading = true;

    const thumbNail = new FormData();
    const anexos = new FormData();

    const formValues = this.form.value;
    const areaPermissions = [];

    for (const chave in this.form.value.areaPermissions) {
      if (this.form.value.areaPermissions[chave] === true) {
        areaPermissions.push(chave);
      }
    }
    const stringAreaPermissions = areaPermissions.join(",");

    const data = {
      ...formValues,
      areaPermissions: stringAreaPermissions,
      userId: this.user.id,
    };

    if (this.fileUpload) {
      const files = this.fileUpload.nativeElement.files;
      for (let i = 0; i < files.length; i++) {
        anexos.append("files", files[i]);
      }
    }

    if (this.thumbUpload) {
      const files = this.thumbUpload.nativeElement.files;
      for (let i = 0; i < files.length; i++) {
        thumbNail.append("file", files[i]);
      }
    }

    combineLatest([
      this.wikiService.uploadMultipleAnexos(anexos),
      this.wikiService.uploadThumbnail(thumbNail),
    ]).subscribe(([anexos, thumbNail]) => {
      data.anexosIds = anexos.map((anexo) => anexo.id);
      data.thumbnailId = thumbNail.id;

      this.wikiService.create(data).subscribe((res) => {
        this.router.navigate(["/arquivos"]);
      });
    });
  }
}
