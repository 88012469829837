import { ChartOptions, BarChartOptions } from "../_models/tickets";

export const chartPartialOptionsBar: Partial<BarChartOptions> = {
  series: [
    {
        name: "Vigente ",
        data: [44, 55, 57, 56]
    },
    {
        name: "Encerrado",
        data: [76, 85, 101, 98]
    },
    {
        name: "Inativo",
        data: [48, 52, 53, 41]
    }
    ],
    chart: {
        type: "bar",
        height: 300
    },
    xaxis: {
        categories: [
          "Feb",
          "Mar",
          "Abr",
          "Mai",
        ]
      },
  legend: {
    fontWeight: 500,
    show: true,
    labels: {
      colors: ['#ffc107','#5cb85c', '#dc3545'],
    },
    markers: {
      fillColors: ['#ffc107','#5cb85c', '#dc3545'],
    }
  },
  tooltip: {
    fillSeriesColor: true,
    style: {
      fontSize: '12px',
      fontFamily: undefined,
      
    },
    
  },
  /* dataLabels: {
    dropShadow: {
      enabled: false,
      top: 1,
      left: 1,
      blur: 1,
      opacity: 0.45,
    },
    style: {
      colors: ["#f5f5f5"],
    },
  }, */
  dataLabels: {
    enabled: false
  },
  fill: {
    colors: ['#ffc107','#5cb85c', '#dc3545']
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "30%",
    }
  },
};
