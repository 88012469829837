import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { User } from "src/app/_models";
import { AccountService } from "src/app/_services";
import { DashboardService } from "src/app/_services/dashboard.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-listar-categorias-dashboard",
  templateUrl: "./listar-categorias-dashboard.component.html",
  styleUrls: ["./listar-categorias-dashboard.component.css"],
})
export class ListarCategoriasDashboardComponent implements OnInit {
  user: User;
  dashboardCategories;
  token;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dashboardService: DashboardService,
    private accountService: AccountService
  ) {
    this.user = this.accountService.userValue.user;
    this.token = this.accountService.userValue.token;
  }

  ngOnInit(): void {
    this.getDashboard();
  }

  getImage(imageId: string) {
    return `${environment.apiUrl}/download/anexo/${imageId}?token=${this.token}`;
  }

  getDashboard() {
    this.dashboardService
      .getDashboardCategories()
      .subscribe((res) => (this.dashboardCategories = res));
  }
  redirect(nextRouter) {
    this.router.navigate([`/${nextRouter}`], { relativeTo: this.route });
  }
}
