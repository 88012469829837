import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DepartamentosService {

  constructor(private http: HttpClient) { }


  getAllDepartamentos(empresaId) {
    return this.http.get<any>(`${environment.apiUrl}/departamentos/search/${empresaId}`).pipe(tap(console.log));
  }
}
