import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-consultar-tickets',
  templateUrl: './consultar-tickets.component.html',
  styleUrls: ['./consultar-tickets.component.css']
})
export class ConsultarTicketsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
