import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CaptacaoInformacao } from '../_models/captacao-informacao';
import { CaptacaoDeInformacao } from '../_models/captacaoDeInformacao';

@Injectable({
    providedIn: 'root'
  })

 export class CaptacaoInformacaoService{
    constructor(private http: HttpClient) { }

    insertCaptacao(captacaoDeInformacao: CaptacaoDeInformacao){
        return this.http.post<CaptacaoInformacao>(`${environment.apiUrl}/mapeamento-documental/captacao`, captacaoDeInformacao);
    }
    editCaptacao(formularioId, respostas){
        return this.http.put<any>(`${environment.apiUrl}/mapeamento-documental/captacao/${formularioId}`,respostas);
    }
    deleteCaptacao(){}
    getAllCaptacao(){
        return this.http.get<any>(`${environment.apiUrl}/mapeamento-documental`);
    }

    getAllPaged(page, count,sort,order) {
        return this.http.get<any>(`${environment.apiUrl}/mapeamento-documental/${page}/${count}?sort=${sort}&order=${order}`).pipe(tap(console.log));
      }

    getAllPagedByStatus(page, count, status, sort,order) {
    return this.http.get<any>(`${environment.apiUrl}/mapeamento-documental/${page}/${count}/${status}?sort=${sort}&order=${order}`).pipe(tap(console.log));
    }

    getMapeamentoById(id){
        return this.http.get<any>(`${environment.apiUrl}/mapeamento-documental/${id}`);
    }

}