<h1 class="text-muted mt-4">Abrir Ticket</h1>
<p class="mb-2 text-secondary">Filtrar por:</p>
<div class="row">
  <div class="col-sm-4">
    <mat-form-field class="w-100" appearance="fill">
      <mat-label>Buscar</mat-label>
      <input
        matInput
        type="text"
        [disabled]="visualization"
        (keyup)="applyFilter($event.target.value)"
      />
    </mat-form-field>
  </div>
  <div class="col-sm-4">
    <mat-form-field [formGroup]="filters" appearance="fill" class="w-100">
      <mat-label>Selecione a Categoria</mat-label>
      <mat-select
        formControlName="category"
        (selectionChange)="getFormsFiltered(filters.value.category)"
      >
        <mat-option value="AUDITORIA">Auditoria</mat-option>
        <mat-option value="PROJETOS_DEMANDAS">Projetos e Demandas</mat-option>
        <mat-option value="SUPORTE_PORTAIS_SISTEMAS"
          >Suporte Portais / Sistemas</mat-option
        >
        <mat-option value="CONSULTA_JURIDICA">Consulta Jurídica</mat-option>
        <mat-option value="SOLICITACOES_INTERNAS"
          >Solicitações Internas</mat-option
        >
        <mat-option value="COMISSAO_CONTESTACAO"
          >Comissão / Contestação</mat-option
        >
        <mat-option value="COMISSIONAMENTO">Comissionamento</mat-option>
        <mat-option value="TODOS">Todas</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-sm-4">
    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Visualização</mat-label>
      <mat-select>
        <mat-option (click)="switchVisualization(true)" value="one"
          >Cards</mat-option
        >
        <mat-option (click)="switchVisualization(false)" value="two"
          >Lista</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div class="row d-flex justify-content-between align-items-center px-3 my-3">
  <!-- <mat-form-field appearance="outline">
    <mat-label>Visualização</mat-label>
    <mat-select>
      <mat-option (click)="switchVisualization(true)" value="one"
        >Cards</mat-option
      >
      <mat-option (click)="switchVisualization(false)" value="two"
        >Lista</mat-option
      >
    </mat-select>
  </mat-form-field> -->
  <div class="row-reverse">
    <p class="text-muted">Tipo de Visualização</p>
    <button
      class="btn btn-sm btn-primary text-white mr-1"
      (click)="switchVisualization(true)"
      matTooltip="Visualizar em Cards"
    >
      <mat-icon color="link">dashboard</mat-icon>
    </button>
    <button
      class="btn btn-sm btn-primary text-white"
      (click)="switchVisualization(false)"
      matTooltip="Visualizar em Lista"
    >
      <mat-icon color="link">list</mat-icon>
    </button>
  </div>

  <!-- <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
    <mat-button-toggle (click)="switchVisualization(true)">
      Cards
    </mat-button-toggle>
    <mat-button-toggle (click)="switchVisualization(false)" value="teste">
      Lista
    </mat-button-toggle>
  </mat-button-toggle-group> -->
</div>

<div class="row">
  <div class="col-sm-12">
    <div class="mat-elevation-z8" *ngIf="!visualization">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        class="mat-elevation-z8"
      >
        <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

        <!-- Name Column -->
        <ng-container matColumnDef="category">
          <th class="name" mat-sort-header mat-header-cell *matHeaderCellDef>
            Área
          </th>
          <td class="name" mat-cell *matCellDef="let element">
            {{ element.ticketCategoryEnum | cap }}
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th class="name" mat-sort-header mat-header-cell *matHeaderCellDef>
            Nome do Formulário
          </th>
          <td class="name" mat-cell *matCellDef="let element">
            {{ element.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="subcategory">
          <th class="name" mat-sort-header mat-header-cell *matHeaderCellDef>
            Categoria
          </th>
          <td class="name" mat-cell *matCellDef="let element">
            {{ element.ticketSubCategoryEnum | cap }}
          </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="description">
          <th
            class="description"
            mat-sort-header
            mat-header-cell
            *matHeaderCellDef
          >
            Descrição
          </th>
          <td class="description" mat-cell *matCellDef="let element">
            <span
              class="limiteCaracter"
              #tooltip="matTooltip"
              matTooltip="{{ element.description }}"
            >
              {{ element.description }}
            </span>
          </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="action">
          <th class="actions" mat-header-cell *matHeaderCellDef>Ação</th>
          <td class="actions" mat-cell *matCellDef="let element">
            <a
              href="{{ path + element.slug }}"
              class="btn btn-sm btn-primary text-white mr-2 button-flex-table"
              matTooltip="Abrir Formulário"
            >
              <mat-icon color="link">arrow_outward</mat-icon>
            </a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator
        [pageSizeOptions]="[5, 15, 25, 50]"
        showFirstLastButtons
        aria-label="Select page of periodic elements"
      >
      </mat-paginator>
    </div>

    <div class="row" *ngIf="visualization">
      <div class="col-md-3 col-sm-6 mb-4" *ngFor="let form of forms">
        <div class="card card-mod h-100">
          <div class="card-body">
            <mat-icon color="link">post_add</mat-icon>
            <h2 class="card-title mb-1 text-primary">
              <!-- <div class="mat-icon-flex-titulo"> -->
              {{ form.name }}
              <!-- </div> -->
            </h2>
            <p class="text-muted">
              {{ form.description }}
            </p>
          </div>
          <div class="card-footer">
            <a href="{{ path + form.slug }}" class="btn btn-primary btn-sm">
              <div class="mat-icon-flex">
                Prosseguir
                <mat-icon color="link">trending_flat</mat-icon>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
