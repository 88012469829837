import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-projeto-e-demanda',
  templateUrl: './projeto-e-demanda.component.html',
  styleUrls: ['./projeto-e-demanda.component.css']
})
export class ProjetoEDemandaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
