import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-meus-tickets',
  templateUrl: './meus-tickets.component.html',
  styleUrls: ['./meus-tickets.component.css']
})
export class MeusTicketsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
