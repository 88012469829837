import { Component, OnInit } from '@angular/core';

@Component({
  selector: "app-solicitacoes-internas",
  templateUrl: "./solicitacoes-internas.component.html",
  styleUrls: ["./solicitacoes-internas.component.css"],
})
export class SolicitacoesInternasComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
