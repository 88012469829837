<p class="text-muted mt-4 mb-1">
    <span class="text-primary">Portal de Comissionamento.</span>
</p>

<div class="d-flex align-items-center">
    <h1 class="mt-0 mb-0 text-muted">
        Definição de agendamento
    </h1>
    <button class="btn btn-primary float-right ml-auto" routerLink="/monitoramento">
        <mat-icon>arrow_back</mat-icon>
    </button>
</div>

<div class="mt-5 mb-3">

    <mat-card>
        <mat-card-header class="mt-3">
            <mat-card-title class="d-flex text-center text-muted">
                Definir data e hora
                <mat-icon class="ml-4">event</mat-icon>
            </mat-card-title>
        </mat-card-header>


        <mat-card-content>
            <!-- <form [formGroup]="formDateRange">
    <div class="modal-body">
        <h3 class="text-primary">Periodo entre datas</h3>
        <mat-form-field appearance="fill" class="w-75">
            <mat-label>Escolha um período entre datas</mat-label>
            <mat-date-range-input [rangePicker]="pickerAgendamento">
              <input matStartDate placeholder="Data Inicial" formControlName="start">
              <input matEndDate placeholder="Data Final"  formControlName="end">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="pickerAgendamento"></mat-datepicker-toggle>
            <mat-date-range-picker  #pickerAgendamento></mat-date-range-picker>
          </mat-form-field>
          <button class="btn btn-primary ml-3 mb-3" (click)="getAllhorarioDisponivel()" [disabled]="formDateRange.invalid">Carregar (Backend)</button>
          <button class="btn btn-primary ml-3 mb-3" (click)="areaHorario = true">Carregar (Mockado)</button>      
    </div>
</form> -->
            <form [formGroup]="formHorario">

                <div class="row">
                    <div class="col-lg-3">
                        <mat-form-field appearance="fill" class="w-100 ml-3">
                            <mat-label> Data selecionada:</mat-label>
                            <input matInput placeholder="Escolha a data" [ngxMatDatetimePicker]="picker2"
                                formControlName="dataEscolhida" required>
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <ngx-mat-datetime-picker #picker2 [showSeconds]="true" [touchUi]="true">
                            </ngx-mat-datetime-picker>

                        </mat-form-field>
                    </div>
                    <div class="col-lg-9">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Link do Teams:</mat-label>
                            <input matInput placeholder="Cole o link" formControlName="link" required>
                        </mat-form-field>
                    </div>
                </div>





                <!-- <button class="btn btn-primary ml-3 mb-3" (click)="adicionarData()" >Adicionar</button> -->



                <!-- <div class="modal-body ">
        <h3 class="text-primary">Datas e horarios adicionados</h3>
        <ul class="mt-1 overflow-auto" style="max-height: 9.375rem !important;">
            <li  *ngFor="let horarios of opcaoDataAgendamento" [value]="horarios" class="borda-horario mb-1 mt-1" >
                {{horarios | date: 'dd/MM/yyyy HH:mm:ss'}}
            </li>
        </ul>
    </div> -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary mr-3" (click)="enviarDataList()"
                        [disabled]="formHorario.invalid">Enviar</button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>