import { environment } from "src/environments/environment";

import { Router, ActivatedRoute } from "@angular/router";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { ViewChild, OnInit, Component, AfterViewInit } from "@angular/core";

import { PedidoPortalService } from "../_services/pedido-portal.service";
import { AccountService } from "../_services";
import { User } from "../_models";

@Component({
  selector: "app-recepcionar-pedidos",
  templateUrl: "./recepcionar-pedidos.component.html",
  styleUrls: ["./recepcionar-pedidos.component.css"],
})
export class RecepcionarPedidosComponent implements OnInit {
  id: any;
  pedidoOnline: any;
  criadoEm: any;
  items: any[];
  responsavel: any = {};
  token: any;
  currentAnexo: any = {};
  anexos: any[] = [];
  user: User;
  razaoSocial: any = {};
  solicitante: any = {};
  departamento: any = {};
  observacao: any = {};
  isChecked: any = {};
  motivoCancelamento: string = "";
  showAnexos = false;
  diabledCancelarPedido = true;
  anexoDownload: string = `${environment.apiUrl}/download/portal/anexo/`;

  porDepartamentos2: string[] = [
    "Departamento X",
    "Departamento Y",
    "Departamento Z",
  ];
  selectedPorDepartamento2: any;

  displayedColumns: string[] = ["id", "campo", "conteudo"];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  displayedColumnsAnexos: string[] = ["anexo", "download"];
  dataSourceAnexos = new MatTableDataSource<any>();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  constructor(
    private route: ActivatedRoute,
    private accountService: AccountService,
    private router: Router,
    private pedidoPortalService: PedidoPortalService
  ) {
    this.accountService.userObservable.subscribe((x) => (this.user = x.user));
    this.accountService.userObservable.subscribe((x) => (this.token = x.token));

    this.route.queryParams.subscribe((params) => {
      var id = this.route.snapshot.paramMap.get("id");

      if (id) {
        this.id = id;
        this.getPedidoPortalById(this.id);
      }
    });
  }

  ngOnInit(): void {}

  getPedidoPortalById(id) {
    this.pedidoPortalService
      .getPedidoPortalById(id, 0)
      .subscribe((response) => {
        this.pedidoOnline = response.pedidoOnlineDto;
        this.criadoEm = new Date(this.pedidoOnline.criadoEm);
        this.razaoSocial = this.pedidoOnline.razaoSocial;
        this.solicitante = this.pedidoOnline.solicitante;
        this.departamento = this.pedidoOnline.departamento;
        this.observacao = this.pedidoOnline.observacao;
        this.isChecked = this.pedidoOnline.urgente === "Y";
        this.showAnexos = "anexoBase64" !== this.pedidoOnline.anexo;
        this.currentAnexo = this.pedidoOnline.anexo.substring(20);

        this.anexos.push(this.currentAnexo);
        this.dataSourceAnexos = new MatTableDataSource(this.anexos);
        this.items = response.pedidoPortalItemDtos;
        this.responsavel = response.responsavelPedidoOnlineDto;
        this.dataSource = new MatTableDataSource(this.items);
        this.dataSource.paginator = this.paginator;
      });
  }

  recepcionarPedido() {
    this.pedidoPortalService
      .recepcionarPedido(this.id, this.user.id)
      .subscribe((response) => {
        if (response) {
          this.router.navigate(["/pedidos-online"], { relativeTo: this.route });
        }
      });
  }

  cancelarPedido() {
    this.pedidoPortalService
      .cancelarPedido(this.id, this.motivoCancelamento)
      .subscribe((response) => {
        if (response) {
          this.router.navigate(["/pedidos-online"], { relativeTo: this.route });
        }
      });
  }

  checkMotivo() {
    if (this.motivoCancelamento.length > 5) {
      this.diabledCancelarPedido = false;
    } else {
      this.diabledCancelarPedido = true;
    }
  }

  baixarAnexo(filename) {
    this.accountService.getTokenWithLowExpiration().subscribe((data) => {
      const urlToDownloadFile: string = `${this.anexoDownload}${filename}?token=${data.token}`;

      window.open(urlToDownloadFile);
    });
  }
}

export interface PeriodicElement {
  id: string;
  campo: string;
  conteudo: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { id: "1", campo: "Quantidade de Caixas", conteudo: "10" },
  { id: "2", campo: "Quantidade de Caixas", conteudo: "10" },
  { id: "3", campo: "Quantidade de Caixas", conteudo: "10" },
  { id: "4", campo: "Tamanho das Caixas", conteudo: "20k" },
  { id: "5", campo: "Tamanho das Caixas", conteudo: "20k" },
  { id: "6", campo: "Tamanho das Caixas", conteudo: "20k" },
];
