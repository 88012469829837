<!-- editar.component.html -->

<p class="text-muted mt-4 mb-1">
  <span class="text-primary">Portal de Comissionamento</span>
</p>

<h1 class="text-muted">Editar Dashboard</h1>

<div class="row mb-5">
  <div class="col-sm-6">
    <form [formGroup]="form" (submit)="updateDashboard()">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Nome do Dashboard:</mat-label>
        <input matInput type="text" formControlName="title" required />
      </mat-form-field>

      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Link do Dashboard:</mat-label>
        <input matInput type="text" formControlName="link" required />
      </mat-form-field>

      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Área do Dashboard</mat-label>
        <mat-select formControlName="areaDashboard" required>
          <mat-option value="Cadastro">Cadastro</mat-option>
          <mat-option value="Projetos_e_regras">Projetos e regras</mat-option>
          <mat-option value="Atendimento">Atendimento</mat-option>
          <mat-option value="Comissão">Comissão</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-label class="text-muted mr-auto">Compartilhar com: </mat-label>
      <div style="margin: 10px 0" formGroupName="areaPermissions">
        <mat-checkbox class="w-100" formControlName="EQUIPE_INTERNA"
          >Equipe Interna</mat-checkbox
        >
        <mat-checkbox class="w-100" formControlName="OPERACAO_VIVO"
          >Operação Vivo</mat-checkbox
        >
        <mat-checkbox class="w-100" formControlName="OPERACAO_BPO"
          >Operação BPO</mat-checkbox
        >
        <mat-checkbox class="w-100" formControlName="TODOS">Todos</mat-checkbox>
      </div>

      <button
        type="submit"
        class="btn btn-sm btn-primary"
        [disabled]="form.invalid"
        #openModal
        data-toggle="modal"
        data-target="#confirmed"
      >
        <div class="button-flex">
          <mat-icon>post_add</mat-icon>
          Editar Dashboard
        </div>
      </button>
    </form>
  </div>
</div>

<div class="modal fade" id="confirmed">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title mb-0 text-muted" id="TituloModalCentralizado">
          Edição de Dashboard
        </p>
        <button
          type="button"
          class="close"
          aria-label="Fechar"
          data-dismiss="modal"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="mt-4 pb-4">
          <div class="w-100 d-flex justify-content-center">
            <mat-icon class="text-success" style="font-size: 30pt !important"
              >check_circle</mat-icon
            >
          </div>
          <h3 class="text-center text-muted mt-4">
            Dashboard editado com sucesso!
          </h3>
        </div>
      </div>
      <div class="modal-footer">
        <button
          data-dismiss="modal"
          type="button"
          class="btn-sm btn btn-secondary mr-2 btn-mat-icon"
          (click)="redirectToMain()"
        >
          <mat-icon class="modal-icon">arrow_back</mat-icon>
          Ir para a Pagina Inicial
        </button>
        <button
          data-dismiss="modal"
          type="button"
          class="btn-sm btn btn-success mr-2 btn-mat-icon"
          (click)="redirectToDashs()"
        >
          <mat-icon class="modal-icon">arrow_outward</mat-icon>
          Dashboards
        </button>
      </div>
    </div>
  </div>
</div>
