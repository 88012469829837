import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comissao-contestacao',
  templateUrl: 'comissao-contestacao.component.html',
  styleUrls: ['comissao-contestacao.component.css']
})
export class ComissaoContestacaoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
