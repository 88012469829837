<p class="text-muted mt-4 mb-1">
  <span class="text-primary">Portal de Comissionamento</span>
</p>

<div class="pageTitle">
  <h1 class="text-muted mb-0">Parametrização das Categorias da Wiki</h1>

  <button
    type="button"
    class="btn btn-success btn-sm mb-2"
    data-toggle="modal"
    data-target="#addCategory"
  >
    <div class="button-flex">
      <mat-icon> add_box </mat-icon>
      Nova Categoria
    </div>
  </button>
</div>
<div class="row mt-2">
  <div class="col-sm-12">
    <mat-accordion multi>
      <mat-expansion-panel hideToggle *ngFor="let element of categories">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ element.name }}
          </mat-panel-title>
          <mat-panel-description
            style="display: flex; justify-content: space-between"
          >
            <div></div>
            <div style="display: flex; gap: 2px">
              <mat-icon
                matTooltip="Adicionar Subcategorias"
                class="btn btn-sm btn-success"
                data-toggle="modal"
                data-target="#addSubcategory"
                (click)="addSubcategoryIdCategory = element.id"
                >add</mat-icon
              >
              <mat-icon
                data-toggle="modal"
                data-target="#editCategory"
                matTooltip="Editar Categoria"
                class="btn btn-sm btn-info"
                (click)="populateEditCategoryForm(element.id)"
                >edit</mat-icon
              >

              <mat-icon
                data-toggle="modal"
                data-target="#confirmExclusion"
                matTooltip="Excluir Categoria"
                class="btn btn-sm btn-danger"
                (click)="setCategoryIdToExclude(element.id)"
                >delete</mat-icon
              >
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <small>Descrição</small>
        <p>{{ element.description }}</p>
        <hr />
        <p class="text-muted">Subcategorias</p>
        <hr />
        <div *ngFor="let subcategory of element.subcategories">
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div class="subcategory_container">
              <p>{{ subcategory.name }}</p>
              <span>{{ subcategory.description }}</span>
            </div>
            <div style="display: flex; gap: 2px">
              <mat-icon
                data-toggle="modal"
                data-target="#editSubcategory"
                (click)="populateEditSubcategoryForm(subcategory.id)"
                matTooltip="Editar Subcategoria"
                class="btn btn-sm btn-info"
                >edit</mat-icon
              >
              <mat-icon
                data-toggle="modal"
                data-target="#confirmSubcategoryExclusion"
                matTooltip="Excluir Subcategoria"
                class="btn btn-sm btn-danger"
                (click)="setSubcategoryIdToExclude(subcategory.id)"
                >delete</mat-icon
              >
            </div>
          </div>
          <hr />
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<form [formGroup]="formAddCategory" (ngSubmit)="submitAddCategory()">
  <div class="modal fade" id="addCategory">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title mb-0" id="TituloModalCentralizado">
            Criar Categoria
          </p>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Fechar"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Nome:</mat-label>
            <input
              matInput
              placeholder="Nome da Categoria"
              formControlName="name"
              [ngClass]="{
                'is-invalid': submittedAddCategory && addCategory.name.errors
              }"
              required
            />
            <div
              *ngIf="submittedAddCategory && addCategory.name.errors"
              class="invalid-feedback"
            >
              <div *ngIf="addCategory.name.errors.required">
                É necessário adicionar o nome da Categoria
              </div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Descrição:</mat-label>
            <input
              matInput
              placeholder="Descrição da Categoria"
              formControlName="description"
              [ngClass]="{
                'is-invalid':
                  submittedAddCategory && addCategory.description.errors
              }"
              required
            />
            <div
              *ngIf="submittedAddCategory && addCategory.description.errors"
              class="invalid-feedback"
            >
              <div *ngIf="addCategory.description.errors.required">
                É necessário adicionar a Descrição da Categoria
              </div>
            </div>
          </mat-form-field>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-success btn-sm button-flex">
            <mat-icon>task_alt</mat-icon>
            Enviar
          </button>
          <button
            #closeModalAdd
            [hidden]="true"
            type="button"
            data-dismiss="modal"
          ></button>
        </div>
      </div>
    </div>
  </div>
</form>

<form [formGroup]="formAddSubcategory" (ngSubmit)="submitAddSubcategory()">
  <div class="modal fade" id="addSubcategory">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title mb-0" id="TituloModalCentralizado">
            Criar Subcategoria
          </p>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Fechar"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Nome:</mat-label>
            <input
              matInput
              placeholder="Nome da Subcategoria"
              formControlName="name"
              [ngClass]="{
                'is-invalid': submittedAddCategory && addCategory.name.errors
              }"
              required
            />
            <div
              *ngIf="submittedAddCategory && addCategory.name.errors"
              class="invalid-feedback"
            >
              <div *ngIf="addCategory.name.errors.required">
                É necessário adicionar o nome da Subcategoria
              </div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Descrição:</mat-label>
            <input
              matInput
              placeholder="Descrição da Subcategoria"
              formControlName="description"
              [ngClass]="{
                'is-invalid':
                  submittedAddCategory && addCategory.description.errors
              }"
              required
            />
            <div
              *ngIf="submittedAddCategory && addCategory.description.errors"
              class="invalid-feedback"
            >
              <div *ngIf="addCategory.description.errors.required">
                É necessário adicionar a Descrição da Subcategoria
              </div>
            </div>
          </mat-form-field>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-success btn-sm button-flex">
            <mat-icon>task_alt</mat-icon>
            Enviar
          </button>
          <button
            #closeModalAdd
            [hidden]="true"
            type="button"
            data-dismiss="modal"
          ></button>
        </div>
      </div>
    </div>
  </div>
</form>

<form [formGroup]="formEditCategory" (ngSubmit)="submitEditCategory()">
  <div class="modal fade" id="editCategory">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title mb-0" id="TituloModalCentralizado">
            Editar Categoria
          </p>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Fechar"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Nome:</mat-label>
            <input
              matInput
              placeholder="Insira o Nome"
              formControlName="name"
              [ngClass]="{
                'is-invalid': submittedEdit && editCategory.name.errors
              }"
              required
            />
            <div
              *ngIf="submittedEdit && editCategory.name.errors"
              class="invalid-feedback"
            >
              <div *ngIf="editCategory.name.errors.required">
                É necessário adicionar o Nome
              </div>
            </div>
          </mat-form-field>

          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Descrição:</mat-label>
            <input
              matInput
              placeholder="Insira a Descrição"
              formControlName="description"
              [ngClass]="{
                'is-invalid': submittedEdit && editCategory.description.errors
              }"
              required
            />
            <div
              *ngIf="submittedEdit && editCategory.description.errors"
              class="invalid-feedback"
            >
              <div *ngIf="editCategory.description.errors.required">
                É necessário adicionar a Descrição
              </div>
            </div>
          </mat-form-field>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-success btn-sm button-flex">
            <mat-icon>task_alt</mat-icon>
            Enviar
          </button>
          <button
            #closeModalEdit
            [hidden]="true"
            type="button"
            data-dismiss="modal"
          ></button>
        </div>
      </div>
    </div>
  </div>
</form>

<form [formGroup]="formEditSubcategory" (ngSubmit)="submitEditSubcategory()">
  <div class="modal fade" id="editSubcategory">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title mb-0" id="TituloModalCentralizado">
            Editar Subcategoria
          </p>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Fechar"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Nome:</mat-label>
            <input
              matInput
              placeholder="Insira o Nome"
              formControlName="name"
              [ngClass]="{
                'is-invalid': submittedEdit && editSubcategory.name.errors
              }"
              required
            />
            <div
              *ngIf="submittedEdit && editSubcategory.name.errors"
              class="invalid-feedback"
            >
              <div *ngIf="editSubcategory.name.errors.required">
                É necessário adicionar o Nome
              </div>
            </div>
          </mat-form-field>

          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Descrição:</mat-label>
            <input
              matInput
              placeholder="Insira a Descrição"
              formControlName="description"
              [ngClass]="{
                'is-invalid':
                  submittedEdit && editSubcategory.description.errors
              }"
              required
            />
            <div
              *ngIf="submittedEdit && editSubcategory.description.errors"
              class="invalid-feedback"
            >
              <div *ngIf="editSubcategory.description.errors.required">
                É necessário adicionar a Descrição
              </div>
            </div>
          </mat-form-field>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-success btn-sm button-flex">
            <mat-icon>task_alt</mat-icon>
            Enviar
          </button>
          <button
            #closeModalEdit
            [hidden]="true"
            type="button"
            data-dismiss="modal"
          ></button>
        </div>
      </div>
    </div>
  </div>
</form>

<div class="modal fade" id="confirmExclusion">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title mb-0" id="TituloModalCentralizado">
          Excluir Categoria
        </p>
        <button
          #closeModalDelete
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Fechar"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="mt-4 pb-4">
          <div class="w-100 d-flex justify-content-center">
            <mat-icon class="text-muted" style="font-size: 30pt !important">
              cancel
            </mat-icon>
          </div>
          <h3 class="text-center text-muted mt-4">
            Confirmar exclusão da Categoria
          </h3>
          <p class="text-center text-muted">
            Ao excluir a categoria todos os Arquivos Wiki e Subcategorias
            associadas serão permanentemente apagados. Não é possível reverter
            essa ação.
          </p>
        </div>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          class="btn-sm btn btn-secondary mr-2 btn-mat-icon"
          data-dismiss="modal"
        >
          <mat-icon class="modal-icon">arrow_back</mat-icon>
          Voltar
        </button>
        <button
          type="button"
          class="btn-sm btn btn-danger mr-2 btn-mat-icon"
          (click)="deleteCategory()"
        >
          <mat-icon class="modal-icon">close</mat-icon>
          Excluir Categoria
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="confirmSubcategoryExclusion">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title mb-0" id="TituloModalCentralizado">
          Excluir Subcategoria
        </p>
        <button
          #closeModalSubcategoryExclusion
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Fechar"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="mt-4 pb-4">
          <div class="w-100 d-flex justify-content-center">
            <mat-icon class="text-muted" style="font-size: 30pt !important">
              cancel
            </mat-icon>
          </div>
          <h3 class="text-center text-muted mt-4">
            Confirmar exclusão da Subcategoria
          </h3>
          <p class="text-center text-muted">
            Ao excluir a categoria todos os Arquivos Wiki associados serão
            permanentemente apagados. Não é possível reverter essa ação.
          </p>
        </div>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          class="btn-sm btn btn-secondary mr-2 btn-mat-icon"
          data-dismiss="modal"
        >
          <mat-icon class="modal-icon">arrow_back</mat-icon>
          Voltar
        </button>
        <button
          type="button"
          class="btn-sm btn btn-danger mr-2 btn-mat-icon"
          (click)="deleteSubcategory()"
        >
          <mat-icon class="modal-icon">close</mat-icon>
          Excluir Subcategoria
        </button>
      </div>
    </div>
  </div>
</div>
