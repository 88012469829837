import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup } from "@angular/forms";

import { MatPaginator } from "@angular/material/paginator";

import { WikiService } from "src/app/_services/wiki.service";

@Component({
  selector: "app-parametros",
  templateUrl: "./parametros.component.html",
  styleUrls: ["./parametros.component.css"],
})
export class ParametrosComponent implements OnInit {
  @ViewChild("areaPaginator") areaPaginator: MatPaginator;
  @ViewChild("segmentoPaginator") segmentoPaginator: MatPaginator;
  @ViewChild("tipoDeInsumoPaginator") tipoDeInsumoPaginator: MatPaginator;
  @ViewChild("periodicidadePaginator") periodicidadePaginator: MatPaginator;
  @ViewChild("cadastroPublicoPaginator") cadastroPublicoPaginator: MatPaginator;
  @ViewChild("closeModalAdd") closeModalAdd: ElementRef;
  @ViewChild("closeModalEdit") closeModalEdit: ElementRef;
  @ViewChild("closeModalCategoryExclusion")
  closeModalCategoryExclusion: ElementRef;
  @ViewChild("closeModalSubcategoryExclusion")
  closeModalSubcategoryExclusion: ElementRef;

  constructor(
    private wikiService: WikiService,
    private formBuilder: FormBuilder
  ) {}

  panelOpenState = false;

  categories: Categories[] = [];

  modalAddSelectedType: string;

  formEditCategoryId: number = 0;
  formEditSubcategoryId: number = 0;
  addSubcategoryIdCategory: number = 0;

  formAddCategory: FormGroup;
  formEditCategory: FormGroup;
  formAddSubcategory: FormGroup;
  formEditSubcategory: FormGroup;

  submittedAddCategory: boolean = false;
  submittedEdit: boolean = false;

  categoryIdToExclude: number = 0;
  subcategoryIdToExclude: number = 0;

  ngOnInit(): void {
    this.formAddCategory = this.formBuilder.group({
      name: [""],
      description: [""],
    });
    this.formEditCategory = this.formBuilder.group({
      name: [""],
      description: [""],
    });
    this.formAddSubcategory = this.formBuilder.group({
      name: [""],
      description: [""],
    });
    this.formEditSubcategory = this.formBuilder.group({
      name: [""],
      description: [""],
    });

    this.getCategories();
  }

  get addCategory(): { [key: string]: AbstractControl } {
    return this.formAddCategory.controls;
  }

  get editCategory(): { [key: string]: AbstractControl } {
    return this.formEditCategory.controls;
  }

  get editSubcategory(): { [key: string]: AbstractControl } {
    return this.formEditSubcategory.controls;
  }

  submitAddCategory() {
    const newCategory = {
      name: this.formAddCategory.value.name,
      description: this.formAddCategory.value.description,
    };

    this.wikiService.newWikiCategory(newCategory).subscribe((res) => {
      this.getCategories();
    });
  }

  submitEditCategory() {
    const updateCategory = {
      name: this.formEditCategory.value.name,
      description: this.formEditCategory.value.description,
    };

    this.wikiService
      .updateWikiCategory(updateCategory, this.formEditCategoryId)
      .subscribe((res) => {
        this.closeModalEdit.nativeElement.click();
        this.getCategories();
      });
  }

  submitEditSubcategory() {
    const updateSubcategory = {
      name: this.formEditSubcategory.value.name,
      description: this.formEditSubcategory.value.description,
    };

    this.wikiService
      .updateWikiSubcategory(updateSubcategory, this.formEditSubcategoryId)
      .subscribe((res) => {
        this.getCategories();
      });
  }

  submitAddSubcategory() {
    const newSubcategory = {
      wikiCategoryId: this.addSubcategoryIdCategory,
      name: this.formAddSubcategory.value.name,
      description: this.formAddSubcategory.value.description,
    };

    this.wikiService.newWikiSubcategory(newSubcategory).subscribe((res) => {
      this.getCategories();
      console.log(res);
    });
  }

  populateEditCategoryForm(id: number) {
    this.formEditCategoryId = id;
    this.wikiService.getCategoryById(id).subscribe((data) => {
      this.formEditCategory.get("name").setValue(data.name);
      this.formEditCategory.get("description").setValue(data.description);
    });
  }

  populateEditSubcategoryForm(id: number) {
    this.formEditSubcategoryId = id;
    this.wikiService.getSubcategoryById(id).subscribe((data) => {
      this.formEditSubcategory.get("name").setValue(data.name);
      this.formEditSubcategory.get("description").setValue(data.description);
    });
  }

  getCategories() {
    this.wikiService.getCategories().subscribe((data: Categories[]) => {
      this.categories = data.reverse();
    });
  }

  setCategoryIdToExclude(categoryId: number) {
    this.categoryIdToExclude = categoryId;
  }

  deleteCategory() {
    this.wikiService
      .deleteCategoryById(this.categoryIdToExclude)
      .subscribe((_) => {
        this.closeModalCategoryExclusion.nativeElement.click();
        this.getCategories();
      });
  }

  setSubcategoryIdToExclude(subcategoryId: number) {
    this.subcategoryIdToExclude = subcategoryId;
  }

  deleteSubcategory() {
    this.wikiService
      .deleteSubcategoryById(this.subcategoryIdToExclude)
      .subscribe((_) => {
        this.closeModalSubcategoryExclusion.nativeElement.click();
        this.getCategories();
      });
  }
}

export interface Subcategory {
  id: number;
  name: string;
  description: string;
  wikiFiles: any[];
}

export interface Categories {
  id: number;
  name: string;
  description: string;
  wikiFiles: any[];
  subcategories: Subcategory[];
}
