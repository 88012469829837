<p class="text-muted mt-4 mb-1">
  <span class="text-primary">Portal de Comissionamento</span>
</p>

<h1 class="text-muted">Editar Arquivo</h1>

<div class="row mb-5">
  <div class="col-sm-12 col-md-8 col-lg-6">
    <form [formGroup]="form" (ngSubmit)="editWiki()">
      <label for="fileUplaod" class="text-muted">Imagem de Capa</label>

      <div *ngIf="thumbFiles.length > 0">
        <ul class="files">
          <li class="files__file" *ngFor="let file of thumbFiles">
            <img src="{{ thumbImage }}" alt="" class="files__file__thumb" />
            <div>
              <p class="text-muted">{{ file.name }}</p>
              <span>{{ formatFileSize(file.size) }}</span>
            </div>
          </li>
        </ul>
      </div>

      <div class="form-group">
        <div class="input-group">
          <div class="custom-file">
            <input
              type="file"
              #thumbUpload
              name="thumbUpload"
              accept="image/*"
              class="custom-file-input"
              (change)="getThumbFile()"
            />
            <label class="custom-file-label" for="thumbUpload">Upload</label>
          </div>
        </div>
      </div>
      <p class="text-muted" style="margin-top: -8px; font-weight: 400">
        É recomendado inserir imagens com no mínimo 1080px de largura e
        resolução 16:9
      </p>

      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Título do Arquivo:</mat-label>
        <input matInput type="text" formControlName="title" required />
      </mat-form-field>

      <div class="mb-4">
        <label for="wikiBody">Corpo do Arquivo</label>
        <quill-editor
          name="wikiBody"
          id="wikiBody"
          formControlName="wikiBody"
          [styles]="{ height: '400px' }"
        ></quill-editor>
      </div>

      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Descrição:</mat-label>
        <textarea
          matInput
          type="text"
          formControlName="description"
          required
        ></textarea>
      </mat-form-field>

      <div *ngIf="categories.length > 0" class="row">
        <div class="col-md-6">
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Categoria:</mat-label>
            <mat-select formControlName="categoryId" required>
              <mat-option
                *ngFor="let category of categories"
                [value]="category.id"
                (onSelectionChange)="setSubcategories(category)"
              >
                {{ category.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Subcategoria:</mat-label>
            <mat-select
              formControlName="subcategoryId"
              [disabled]="subcategories.length <= 0"
              required
            >
              <mat-option
                *ngFor="let subcategory of subcategories"
                [value]="subcategory.id"
              >
                {{ subcategory.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Tipo de Arquivo:</mat-label>
        <mat-select formControlName="fileType" required>
          <mat-option value="video"> Vídeo </mat-option>
          <mat-option value="link"> Link </mat-option>
          <mat-option value="documento"> Documento </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Área Responsável</mat-label>
        <mat-select formControlName="area" required>
          <mat-option value="projetos-gestao-regras">
            Projetos e Gestão de Regras
          </mat-option>
          <mat-option value="apuracao-resultados">
            Apuração de Resultados
          </mat-option>
          <mat-option value="estrategia-comissionamento">
            Estratégia de Comissionamento
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        class="w-100"
        appearance="fill"
        *ngIf="
          form.value.fileType === 'link' || form.value.fileType === 'video'
        "
      >
        <mat-label>Link do conteúdo:</mat-label>
        <input matInput type="text" formControlName="link" />
      </mat-form-field>

      <mat-label class="text-muted mr-auto">Compartilhar com: </mat-label>
      <div style="margin: 10px 0" formGroupName="areaPermissions">
        <mat-checkbox class="w-100" formControlName="EQUIPE_INTERNA">
          Equipe Interna
        </mat-checkbox>
        <mat-checkbox class="w-100" formControlName="OPERACAO_VIVO">
          Operação Vivo
        </mat-checkbox>
        <mat-checkbox class="w-100" formControlName="OPERACAO_BPO">
          Operação BPO
        </mat-checkbox>
        <mat-checkbox class="w-100" formControlName="TODOS">
          Todos
        </mat-checkbox>
      </div>

      <button
        type="submit"
        class="btn btn-sm btn-primary"
        [disabled]="form.invalid"
      >
        <div class="button-flex">
          <mat-icon>post_add</mat-icon>
          Editar Arquivo
        </div>
      </button>
    </form>
  </div>
</div>
