import { Component } from '@angular/core';

@Component({
    template: `
        <router-outlet></router-outlet>
    `
})

export class LogarComponent {

}


// export class Logar.Component {
// }
