import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { MatSnackBar } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { Login } from '../../models';
import { LoginService } from '../../services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  hide = true;
  checked = false;

  formLogin: FormGroup;


  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private router: Router,
    private loginService: LoginService) { }

  ngOnInit(): void {
    this.gerarForm();
  }

  gerarForm() {
    this.formLogin = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  logar() {
    if (this.formLogin.invalid) {
      return;
    }
    const login: Login = this.formLogin.value;
    this.loginService.logar(login).subscribe(
      data => {
        console.log(JSON.stringify(data));
        localStorage['token'] = data['data']['token'];



        const usuarioData = JSON.parse(
          atob(data['data']['token'].split('.')[1]));
        console.log(JSON.stringify(usuarioData));
        if (usuarioData['role'] == 'ROLE_ADMIN') {
          alert('Deve redirecionar para a página de admin');
          //this.router.navigate([/'admin']);
        } else {
          alert('Deve redirecionar para a página de funcionario');
          //this.router.navigate([/'funcionario']);
        }
      },

      err => {
        console.log(JSON.stringify(err));
        let msg: string = "tente novamente em instantes.";
        if (err['status'] == 401) {
          msg = "Email/password inválido(s)";
        }
        this.snackBar.open(msg, "Erro", { duration: 5000 });
      }
    );

  }
}
