import { environment } from "src/environments/environment";

import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class WikiService {
  constructor(private http: HttpClient) {}

  getWikiCount(userType: string) {
    return this.http.get<any>(`${environment.apiUrl}/wiki/wiki-count`, {
      params: {
        userType,
      },
    });
  }

  getWikis(userId: string | undefined = "") {
    return this.http.get<any>(`${environment.apiUrl}/wiki?user_id=${userId}`);
  }

  getWikiById(wikiId) {
    return this.http.get<any>(`${environment.apiUrl}/wiki/search`, {
      params: {
        wikiId,
      },
    });
  }

  findOne(id: string | number) {
    return this.http.get<any>(`${environment.apiUrl}/wiki/${id}`);
  }

  create(dto: any) {
    return this.http.post<any>(`${environment.apiUrl}/wiki/save`, dto);
  }

  edit(id: string | number, dto: any) {
    return this.http.put<any>(`${environment.apiUrl}/wiki/${id}`, dto);
  }

  uploadThumbnail(formData: FormData) {
    return this.http.post<any>(`${environment.apiUrl}/wiki/thumb`, formData);
  }

  uploadMultipleAnexos(formData: FormData) {
    return this.http.post<any>(`${environment.apiUrl}/wiki/anexos`, formData);
  }

  newWiki(formData) {
    return this.http.post<any>(`${environment.apiUrl}/wiki`, formData, {
      reportProgress: true,
      observe: "events",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  newWikiCategory(formData) {
    return this.http.post<any>(
      `${environment.apiUrl}/wiki/categories`,
      formData
    );
  }

  newWikiSubcategory(formData) {
    return this.http.post<any>(
      `${environment.apiUrl}/wiki/subcategories`,
      formData
    );
  }

  updateWikiCategory(formData, categoryId) {
    return this.http.put<any>(
      `${environment.apiUrl}/wiki/categories/${categoryId}`,
      formData
    );
  }

  updateWikiSubcategory(formData, subcategoryId) {
    return this.http.put<any>(
      `${environment.apiUrl}/wiki/subcategories/${subcategoryId}`,
      formData
    );
  }
  getCategories(categoryId: number = 0) {
    return this.http.get<any>(`${environment.apiUrl}/wiki/categories`, {
      params: new HttpParams().set("categoryId", categoryId),
    });
  }

  getCategoryById(categoryId: number = 0) {
    return this.http.get<any>(
      `${environment.apiUrl}/wiki/categories/${categoryId}`
    );
  }
  getSubcategoryById(subategoryId: number = 0) {
    return this.http.get<any>(
      `${environment.apiUrl}/wiki/subcategorias/${subategoryId}`
    );
  }

  deleteCategoryById(categoryId: number = 0) {
    return this.http.delete<any>(
      `${environment.apiUrl}/wiki/categories/${categoryId}`
    );
  }
  deleteSubcategoryById(subcategoryId: number = 0) {
    return this.http.delete<any>(
      `${environment.apiUrl}/wiki/subcategories/${subcategoryId}`
    );
  }
  getSubcategories(subcategoryId: number = 0) {
    return this.http.get<any>(`${environment.apiUrl}/wiki/subcategorias`, {
      params: new HttpParams().set("subcategoryId", subcategoryId),
    });
  }

  newWikiFeedback(formData) {
    return this.http.post<any>(`${environment.apiUrl}/wiki/feedback`, formData);
  }
  getWikiFiltered(
    title?: string,
    categoryId?: number,
    area?: string,
    areaPermissions?: string
  ): Observable<any> {
    let params = new HttpParams();

    if (title === undefined) {
      params = params.set("title", "");
    } else if (title !== undefined) {
      params = params.set("title", title);
    }
    if (categoryId !== undefined) {
      params = params.set("categoryId", categoryId.toString());
    }
    if (area !== undefined) {
      params = params.set("area", area);
    }
    if (areaPermissions !== undefined) {
      params = params.set("areaPermissions", areaPermissions);
    }

    return this.http.get<any>(
      `${environment.apiUrl}/wiki/search/filter/0/100`,
      { params }
    );
  }

  getLastTree(categoryId: number = 0) {
    return this.http.get<any>(
      `${environment.apiUrl}/wiki/last-tree/${categoryId}`
    );
  }
}
