import { Component, Input, OnInit } from "@angular/core";
import * as $ from "../../assets/jquery-3.5.1.min";
import * as Jquery from "../../assets/jquery-3.5.1.min";
import { User2AF } from "../_models";

const userType = {
  OPERACAO_VIVO: 0,
  EQUIPE_INTERNA: 1,
  OPERACAO_BPO: 2,
  OPERADOR_VIVO_JURIDICO: 3,
  OPERADOR_VIVO_AUDITORIA: 4,
  DESENVOLVIMENTO: 5,
};

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  @Input() data: User2AF;

  constructor() {}

  ngOnInit(): void {
    $("aside").delegate("a", "click", function () {
      $("html, body").animate({ scrollTop: 0 }, "slow");
    });

    Jquery("#menu-toggle-sidebar").click(function (e) {
      e.preventDefault();
      Jquery("#wrapper").toggleClass("toggled");
    });
  }

  logoVivo2(el) {
    var display = document.getElementById(el).style.display;
    if (display == "none") document.getElementById(el).style.display = "block";
    else document.getElementById(el).style.display = "none";
  }

  openHelpDesk(): void {
    var token = btoa(this.data.token);
    var url = `https://helpdesk.plataformadeassinaturas.com.br/auth/PORTAL_DE_COMISSIONAMENTO/${token}`;
    window.open(url, "_blank");
  }

  authorization(selectedUserTypes: number[]): boolean {
    const validUserTypes: string[] = [];

    selectedUserTypes.forEach((element) =>
      validUserTypes.push(Object.keys(userType)[element].toString())
    );

    return validUserTypes.includes(this.data.user.userType);
  }

  changeFieldsPermissions(profile: string): boolean {
    if (profile == "ROLE_ADMIN") {
      return true;
    } else {
      return false;
    }
  }

  showSearchPage() {
    $(".inputSearch").css("display", "flex");
    $(".topic-help").css("display", "flex");
    $(".divReturnSearh").css("display", "hidden");
    $(".returnSearh").css("display", "hidden");
    $(".aside").css("display", "hidden");
  }
}
