import { User } from "src/app/_models";
import { AccountService } from "src/app/_services";
import { WikiService } from "src/app/_services/wiki.service";
import { environment } from "src/environments/environment";

import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as $ from "../../../assets/jquery-3.5.1.min";

export interface WikiFile {
  id: number;
  userUpload: UserUpload;
  anexo: Anexo;
  thumbnail?: string;
  title: string;
  description: string;
  wikiCategory: WikiCategory;
  wikiSubcategory: WikiSubcategory;
  fileType: string;
  areaPermissions: string;
  link: any;
  wikiBody: string;
  creationDate: number;
  anexos: Anexo[];
  wikiFeedbacks: any[];
  wikiCategoryId: number;
  wikiSubcategoryId: number;
}

export interface UserUpload {
  id: number;
  area: string;
  email: string;
  lastName: string;
  enabled: boolean;
  fullName: string;
  firstName: string;
  tempPassword: boolean;
  profile: string;
  userType: string;
}

export interface Anexo {
  id: number;
  originalFileName: string;
  fileName: string;
  creationDt: number;
  pedidoId: any;
}

export interface WikiCategory {
  id: number;
  name: string;
  description: string;
  wikiSubcategoryDto: WikiSubcategoryDto[];
}

export interface WikiSubcategoryDto {
  id: number;
  name: string;
  description: string;
}

export interface WikiSubcategory {
  id: number;
  name: string;
  description: string;
}

@Component({
  selector: "app-visualizar",
  templateUrl: "./visualizar.component.html",
  styleUrls: ["./visualizar.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class VisualizarArquivoComponent implements OnInit {
  user: User;
  wiki: WikiFile;
  wikiId: number = 0;
  token: string;
  addFeedback: FormGroup;

  positivesFeedbacks: number = 0;
  negativesFeedbacks: number = 0;
  wikis: any[];
  searchInput: string = "";
  display = false;
  categoryId: number;
  wikiRelacionate: any[];

  categories: any[] = [];

  displayedColumns: string[] = ["originalFileName", "creationDt", "actions"];

  anexoDownload: string = environment.apiUrl + "/download/anexo/";
  dataSource = new MatTableDataSource<Anexo>(ELEMENT_DATA);

  // @Input() selectedRevisarOutro: boolean;
  // @Output() selectedRevisarOutroChange = new EventEmitter<boolean>();

  selectedResponse: boolean = false;
  responseStatus: boolean = false;

  constructor(
    private accountService: AccountService,
    private wikiService: WikiService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.user = this.accountService.userValue.user;
    this.token = this.accountService.userValue.token;
  }

  @ViewChild("afterPaginator") paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => (this.wikiId = params["id"]));

    this.getWikiById(this.wikiId);

    // full screen initial

    this.addFeedback = this.formBuilder.group({
      comment: ["", [Validators.required]],
    });

    function hasClass(element, cls) {
      return (" " + element.className + " ").indexOf(" " + cls + " ") > -1;
    }

    var el = document.getElementById("wrapper");
    console.log(hasClass(el, "toggled"));
    // console.log(hasClass(el, 'class'));

    // desktop
    if (screen.width > 768 && hasClass(el, "toggled") == false) {
      $("#page-content-wrapper").removeClass("pcw-16");
      $("#page-content-wrapper").addClass("pcw-0");
    } else if (screen.width > 768 && hasClass(el, "toggled") == true) {
      $("#page-content-wrapper").removeClass("pcw-0");
      $("#page-content-wrapper").addClass("pcw-16");
    }

    // mobile
    if (screen.width < 768 && hasClass(el, "toggled") == false) {
      $("#wrapper").toggleClass("toggled");

      $("#page-content-wrapper").removeClass("pcw-16");
      $("#page-content-wrapper").addClass("pcw-0");
    } else if (screen.width < 768 && hasClass(el, "toggled") == true) {
      $("#page-content-wrapper").removeClass("pcw-0");
      $("#page-content-wrapper").addClass("pcw-16");
    }

    // desktop
    if (screen.width == 768 && hasClass(el, "toggled") == false) {
      $("#page-content-wrapper").removeClass("pcw-16");
      $("#page-content-wrapper").addClass("pcw-0");
    } else if (screen.width == 768 && hasClass(el, "toggled") == true) {
      $("#page-content-wrapper").removeClass("pcw-0");
      $("#page-content-wrapper").addClass("pcw-16");
    }

    // $("#page-content-wrapper").addClass("pcw-0");
    $("#wrapper").toggleClass("toggled");
  }

  getWikiById(wikiId) {
    this.wikiService.getWikiById(wikiId).subscribe((res: WikiFile) => {
      try {
        this.wiki = res;

        this.positivesFeedbacks = res.wikiFeedbacks.reduce(
          (accumulator, item) => (accumulator += item.positiveFeedback ? 1 : 0),
          0
        );
        this.negativesFeedbacks = res.wikiFeedbacks.reduce(
          (accumulator, item) => accumulator + !item.positiveFeedback,
          0
        );

        this.getLastTree(this.wiki.wikiCategoryId);

        const filterAnexos = res.anexos.filter(
          (anexo) => anexo.id !== this.wiki.anexo.id
        );

        this.dataSource = new MatTableDataSource(filterAnexos);

        if (this.wiki.anexo == null) {
          this.wiki.thumbnail = "0";
        } else {
          this.wiki.thumbnail = this.getImage(this.wiki.anexo.id);
        }
      } catch (err) {
        console.log(err);
      }
    });
  }

  getImage(imageId: number) {
    console.log(
      `${environment.apiUrl}/download/anexo/${imageId}?token=${this.token}`
    );
    return `${environment.apiUrl}/download/anexo/${imageId}?token=${this.token}`;
  }

  downloadFile(fileId: any) {
    this.accountService.getTokenWithLowExpiration().subscribe((data) => {
      const urlToDownloadFile: string = `${this.anexoDownload}${fileId}?token=${data.token}`;

      window.open(urlToDownloadFile);
    });
  }

  getFileType(fileType: string) {
    switch (fileType) {
      case "link":
        return "image";
      case "documento":
        return "description";
      case "video":
        return "videocam";
    }
  }

  togleResponse(response: boolean) {
    if (response === this.responseStatus) {
      this.selectedResponse = !this.selectedResponse;
    } else {
      this.selectedResponse = true;
    }
    this.responseStatus = response;
  }

  public toggleSelectedRevisarOutro() {
    $(".selectedRevisarOutro").css("display", "block");
    $(".enviar").css("display", "none");
    // this.selectedRevisarOutro = !this.selectedRevisarOutro;
    // this.selectedRevisarOutroChange.emit(this.selectedRevisarOutro);
  }

  public toggleSelectedMotivo() {
    $(".selectedRevisarOutro").css("display", "none");
    $(".enviar").css("display", "block");
    // this.selectedRevisarOutro = !this.selectedRevisarOutro;
    // this.selectedRevisarOutroChange.emit(this.selectedRevisarOutro);
    // this.selectedMotivo = !this.selectedMotivo;
    // this.selectedMotivoChange.emit(this.selectedMotivo);
  }

  createFeedback() {
    const body = {
      comment: this.addFeedback.value.comment,
      isPositiveFeedback: this.responseStatus,
      userId: this.user.id,
      wikiFileId: this.wiki.id,
    };

    console.log(this.responseStatus);
    console.log(body);
    this.wikiService.newWikiFeedback(body).subscribe((res) => {
      this.addFeedback.reset();
      this.getWikiById(this.wiki.id);
    });
  }

  enviar() {
    $(".section-feedback").css("display", "none");
    $(".section-feedback-return").css("display", "block");
  }

  setValue(event) {
    this.searchInput = event.target.value;
  }

  setValueCategory(event) {
    this.categoryId = event.target.value;
  }

  searchView(categoryId?: number) {
    console.log(categoryId);
    this.router.navigate(["/arquivos"], {
      state: { searchInput: this.searchInput, categoryId },
    });
  }

  getLastTree(categoryId: number) {
    this.wikiService.getLastTree(categoryId).subscribe((res) => {
      this.wikiRelacionate = res.filter((wiki) => wiki.id != this.wikiId);
      this.wikiRelacionate = this.wikiRelacionate.map((wiki) => {
        if (wiki.anexo == null) {
          wiki.thumbnail = "0";
        } else {
          wiki.thumbnail = this.getImage(wiki.anexo.id);
        }
        return wiki;
      });
    });
  }
}

const ELEMENT_DATA: Anexo[] = [
  {
    id: 57,
    originalFileName: "teste",
    fileName: "teste",
    creationDt: 1699929063000,
    pedidoId: null,
  },
];
