<div class="example-loading-shade" *ngIf="isLoadingData; else dataAfterLoading">
  <mat-spinner></mat-spinner>
</div>

<ng-template #dataAfterLoading>
  <div>
    <h1 class="mt-4 text-muted">Solicitações de Acesso</h1>
  </div>

  <div class="row">
    <div class="col-sm">
      <button
        type="button"
        class="btn btn-rose btn-sm mr-2 mb-2"
        (click)="downloadSolicitations()"
      >
        <div class="button-flex">
          <mat-icon>download</mat-icon>
          Exportar
        </div>
      </button>
    </div>
  </div>

  <div class="mat-elevation-z8 mt-4 pb-4">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>Id</th>
        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Nome</th>
        <td mat-cell *matCellDef="let element">
          {{ element.firstName }} {{ element.lastName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>

      <ng-container matColumnDef="area">
        <th mat-header-cell *matHeaderCellDef>Area</th>
        <td mat-cell *matCellDef="let element">{{ element.area }}</td>
      </ng-container>

      <ng-container matColumnDef="userType">
        <th mat-header-cell *matHeaderCellDef>Tipo de Usuário</th>
        <td mat-cell *matCellDef="let element">
          {{ element.userTypeEnum | cap }}
        </td>
      </ng-container>

      <ng-container matColumnDef="solicitacaoAcessoStatusEnum">
        <th mat-header-cell *matHeaderCellDef>Status da Solicitação</th>
        <td mat-cell *matCellDef="let element">
          {{ element.solicitacaoAcessoStatusEnum | cap }}
        </td>
      </ng-container>

      <ng-container matColumnDef="acoes">
        <th class="acoes" mat-header-cell *matHeaderCellDef>Ações</th>
        <td class="acoes" mat-cell *matCellDef="let element">
          <button
            [disabled]="
              element.solicitacaoAcessoStatusEnum === 'NEGADO' ||
              element.solicitacaoAcessoStatusEnum === 'APROVADO'
            "
            matSuffix
            matTooltip="Aprovar Acesso"
            class="btn btn-sm btn-success text-white mr-2 button-flex-table"
            data-toggle="modal"
            data-target="#add"
            (click)="populateEdit(element)"
          >
            <mat-icon>task_alt</mat-icon>
          </button>
          <button
            [disabled]="
              element.solicitacaoAcessoStatusEnum === 'NEGADO' ||
              element.solicitacaoAcessoStatusEnum === 'APROVADO'
            "
            matSuffix
            matTooltip="Negar Acesso"
            class="btn btn-danger btn-sm text-white mr-2 button-flex-table"
            data-toggle="modal"
            data-target="#justificativa"
            (click)="negarDtoPopulate(element.id)"
          >
            <mat-icon>clear</mat-icon>
          </button>
          <button
            matSuffix
            matTooltip="info Acesso"
            class="btn btn-warning btn-sm text-white mr-2 button-flex-table"
            data-toggle="modal"
            data-target="#view"
            (click)="populateView(element)"
          >
            <mat-icon>info</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      #paginator
      [pageSizeOptions]="[5, 10, 15, 20]"
      showFirstLastButtons
      aria-label="Select page of periodic elements"
    >
    </mat-paginator>
  </div>
</ng-template>

<form [formGroup]="formNegar">
  <div class="modal fade" id="justificativa">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title mb-0" id="TituloModalCentralizado">
            Adicionar Justificativa
          </p>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Fechar"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Justificativa:</mat-label>
            <textarea
              matInput
              type="text"
              name="justificativa"
              id="justificativa"
              placeholder="Insira a Justificativa"
              formControlName="justificativa"
              required
            ></textarea>
          </mat-form-field>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn-sm btn btn-dark mr-2 btn-mat-icon"
            data-dismiss="modal"
          >
            <mat-icon class="">close</mat-icon>
            Cancelar
          </button>

          <button
            type="submit"
            class="btn-sm btn btn-success btn-mat-icon"
            data-dismiss="modal"
            (click)="onSubmit()"
          >
            <mat-icon class="">done</mat-icon>
            Negar Solicitação
          </button>
        </div>
      </div>
    </div>
  </div>
</form>

<form [formGroup]="formView">
  <div class="modal fade" id="view">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title mb-0" id="TituloModalCentralizado">
            Informações da Solicitação
          </p>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Fechar"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Id: </mat-label>
            <input matInput formControlName="id" [readonly]="true" />
          </mat-form-field>
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Primeiro Nome: </mat-label>
            <input matInput [readonly]="true" formControlName="firstName" />
          </mat-form-field>
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Ultimo Nome: </mat-label>
            <input matInput [readonly]="true" formControlName="lastName" />
          </mat-form-field>
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Email: </mat-label>
            <input matInput [readonly]="true" formControlName="email" />
          </mat-form-field>
          <mat-form-field appearance="fill" class="w-100">
            <mat-label> Área:</mat-label>
            <mat-select formControlName="area">
              <mat-option value="Auditoria"> Auditoria </mat-option>
              <mat-option value="Canal/Comercial"> Canal/Comercial </mat-option>
              <mat-option value="Comissionamento"> Comissionamento </mat-option>
              <mat-option value="Jurídico"> Jurídico </mat-option>
              <mat-option value="Regional"> Regional </mat-option>
              <mat-option value="Operação BPO"> Operação BPO </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Justificativa: </mat-label>
            <input matInput [readonly]="true" formControlName="justificativa" />
          </mat-form-field>
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Status da Solicitação: </mat-label>
            <input
              matInput
              [readonly]="true"
              formControlName="solicitacaoAcessoStatusEnum"
            />
          </mat-form-field>
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Tipo de Usuário:</mat-label>
            <mat-select formControlName="userTypeEnum">
              <mat-option value="OPERACAO_VIVO">Operação Vivo</mat-option>
              <mat-option value="EQUIPE_INTERNA">Equipe Interna</mat-option>
              <mat-option value="OPERACAO_BPO">Operação BPO</mat-option>
              <mat-option value="OPERADOR_VIVO_JURIDICO">
                Operador Vivo Jurídico
              </mat-option>
              <mat-option value="OPERADOR_VIVO_AUDITORIA">
                Operador Vivo Auditoria
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn-sm btn btn-dark mr-2 btn-mat-icon"
            data-dismiss="modal"
          >
            <mat-icon class="">close</mat-icon>
            Voltar
          </button>
        </div>
      </div>
    </div>
  </div>
</form>

<form [formGroup]="formEdit">
  <div class="modal fade" id="add">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title mb-0" id="TituloModalCentralizado">
            Informações da Solicitação
          </p>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Fechar"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Id: </mat-label>
            <input matInput formControlName="id" [readonly]="true" />
          </mat-form-field>
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Primeiro Nome: </mat-label>
            <input matInput formControlName="firstName" />
          </mat-form-field>
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Ultimo Nome: </mat-label>
            <input matInput formControlName="lastName" />
          </mat-form-field>
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Email: </mat-label>
            <input matInput formControlName="email" />
          </mat-form-field>
          <mat-form-field appearance="fill" class="w-100">
            <mat-label> Área:</mat-label>
            <mat-select formControlName="area">
              <mat-option value="Auditoria"> Auditoria </mat-option>
              <mat-option value="Canal/Comercial"> Canal/Comercial </mat-option>
              <mat-option value="Comissionamento"> Comissionamento </mat-option>
              <mat-option value="Jurídico"> Jurídico </mat-option>
              <mat-option value="Regional"> Regional </mat-option>
              <mat-option value="Operação BPO"> Operação BPO </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Justificativa: </mat-label>
            <input matInput formControlName="justificativa" />
          </mat-form-field>

          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Tipo de Usuário:</mat-label>
            <mat-select formControlName="userTypeEnum">
              <mat-option value="OPERACAO_VIVO">Operação Vivo</mat-option>
              <mat-option value="EQUIPE_INTERNA">Equipe Interna</mat-option>
              <mat-option value="OPERACAO_BPO">Operação BPO</mat-option>
              <mat-option value="OPERADOR_VIVO_JURIDICO">
                Operador Vivo Jurídico
              </mat-option>
              <mat-option value="OPERADOR_VIVO_AUDITORIA">
                Operador Vivo Auditoria
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn-sm btn btn-dark mr-2 btn-mat-icon"
            data-dismiss="modal"
          >
            <mat-icon class="">close</mat-icon>
            Voltar
          </button>
          <button
            type="button"
            class="btn-sm btnbtn-dark mr-2 btn-mat-icon btn-success text-white"
            data-dismiss="modal"
            (click)="aproveRequest()"
          >
            <mat-icon>check_circle_outline</mat-icon>
            Aprovar
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
