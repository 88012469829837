import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Pendencia } from '../_models/pendencia';

@Injectable({
  providedIn: 'root'
})
export class PendenciaService {

  constructor(private http: HttpClient) { }


  insertPendencia(pendencia: Pendencia){
    return this.http.post<any>(`${environment.apiUrl}/pendencia`, pendencia);
  }
}
