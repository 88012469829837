<!-- <p class="text-muted mt-4 mb-1">
    <span class="text-primary">Portal de Comissionamento.</span>
</p> -->


<h1 class="mt-4 mb-0 text-muted">
    Relatórios
</h1>


<form action="" class="mt-4">
    <div class="row">



        <div class="col-sm-12 mb-2">

            <div class="form-group">
                <mat-form-field appearance="fill" class="selects100vw">
                    <mat-label> Por Empresa:</mat-label>
                    <mat-select [(value)]="selectedPorEmpresa" #selectPorEmpresa>
                        <!-- <mat-option>Selecione</mat-option>  -->
                        <mat-option *ngFor="let porempresa of porEmpresas" [value]="porempresa">{{porempresa}}
                        </mat-option>
                        <mat-option value="outraOpcaoPorEmpresa">Outra opção</mat-option>
                    </mat-select>
                </mat-form-field>

                <ng-container *ngIf="selectPorEmpresa.value == 'outraOpcaoPorEmpresa'">
                    <mat-form-field class="inputs">
                        <mat-label>Adicione uma nova opção</mat-label>
                        <input matInput placeholder="Ex. arquivo" value="">
                    </mat-form-field>
                </ng-container>
            </div>


        </div>



        <div class="col-sm-3 mb-4">
            <p>
                Exportar para:
            </p>

            <div class="inputGroup">
                <input id="radio1" name="exportar" type="radio" />
                <label for="radio1">PDF</label>
            </div>
            <div class="inputGroup">
                <input id="radio2" name="exportar" type="radio" />
                <label for="radio2">Excel</label>
            </div>

        </div>

        <div class="col-sm-3 mb-4">
            <p>
                Tipo de Relatório:
            </p>
            <div class="inputGroup">
                <input class="" type="radio" name="tipoRelatorio" id="analitico" value="analitico">
                <label class="" for="analitico">Analitico</label>
            </div>
            <div class="inputGroup">
                <input class="" type="radio" name="tipoRelatorio" id="sintetico/quantitativos"
                    value="sintetico/quantitativos">
                <label class="" for="sintetico/quantitativos">Sintetico/Quantitativos</label>
            </div>

        </div>

        <div class="col-sm-3 mb-4">
            <p>
                Listagem Documentos:
            </p>
            <div class="inputGroup">
                <input class="" type="radio" name="listagemDocumentos" id="triados" value="triados">
                <label class="" for="triados">Triados</label>
            </div>
        </div>

        <div class="col-sm-3 mb-4">
            <p>
                Listagem Requisição Externa:
            </p>
            <div class="inputGroup">
                <input type="radio" name="listagemRequisicaoExterna" id="listagem" value="listagem">
                <label for="listagem">Listagem</label>
            </div>
        </div>




        <div class="col-sm-3 mb-4">
            <p>
                Acondicionamento:
            </p>
            <div class="inputGroup">
                <input class="" type="radio" name="acondicionamentos" id="aparas" value="aparas">
                <label class="" for="aparas">Aparas</label>
            </div>
            <div class="inputGroup">
                <input class="" type="radio" name="acondicionamentos" id="armazenadas" value="armazenadas">
                <label class="" for="armazenadas">Armazenadas</label>
            </div>
            <div class="inputGroup">
                <input class="" type="radio" name="acondicionamentos" id="descartadas" value="descartadas">
                <label class="" for="descartadas">Descartadas</label>
            </div>
            <div class="inputGroup">
                <input class="" type="radio" name="acondicionamentos" id="otimizadas" value="otimizadas">
                <label class="" for="otimizadas">Otimizadas</label>
            </div>
            <div class="inputGroup">
                <input class="" type="radio" name="acondicionamentos" id="triadas" value="triadas">
                <label class="" for="triadas">Triadas</label>
            </div>
            <div class="inputGroup">
                <input class="" type="radio" name="acondicionamentos" id="triada/otimizado/novo/descartado"
                    value="triada/otimizado/novo/descartado">
                <label class="" for="triada/otimizado/novo/descartado">Triada/Otimizado/Novo/Descartado</label>
            </div>
            <div class="inputGroup">
                <input class="" type="radio" name="acondicionamentos" id="aguardandoDefinicao"
                    value="aguardandoDefinicao">
                <label class="" for="aguardandoDefinicao">Aguardando Definição</label>
            </div>
            <div class="inputGroup">
                <input class="" type="radio" name="acondicionamentos" id="SKPOtimizados" value="SKPOtimizados">
                <label class="" for="SKPOtimizados">SKP Otimizados</label>
            </div>


        </div>






        <div class="col-sm-3 mb-4">
            <p>
                Listagem Pedidos Online:
            </p>
            <div class="inputGroup">
                <input class="" type="radio" name="listagemPedidosOnline" id="todos" value="todos">
                <label class="" for="todos">Todos</label>
            </div>
            <div class="inputGroup">
                <input class="" type="radio" name="listagemPedidosOnline" id="aguardandoRecepcao"
                    value="aguardandoRecepcao">
                <label class="" for="aguardandoRecepcao">Aguardando Recepção</label>
            </div>
            <div class="inputGroup">
                <input class="" type="radio" name="listagemPedidosOnline" id="emAndamento" value="emAndamento">
                <label class="" for="emAndamento">Em Andamento</label>
            </div>
            <div class="inputGroup">
                <input class="" type="radio" name="listagemPedidosOnline" id="concluido" value="concluido">
                <label class="" for="concluido">Concluidos</label>
            </div>
            <div class="inputGroup">
                <input class="" type="radio" name="listagemPedidosOnline" id="cancelados" value="cancelados">
                <label class="" for="cancelados">Cancelados</label>
            </div>

        </div>






        <!-- <div class="col-sm-3 mb-4">

            <mat-form-field appearance="fill">
                <mat-label>Enter a date range</mat-label>
                <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                    <input matStartDate formControlName="start" placeholder="Start date">
                    <input matEndDate formControlName="end" placeholder="End date">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>

                <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
            </mat-form-field>

            <p>Selected range: {{range.value | json}}</p>


        </div> -->


        <div class="col-sm-12 mb-5">
            <input type="submit" class="btn btn-primary btn-block" value="Imprimir">
        </div>





    </div>
</form>



<!-- <form class="form">

    <h2>Checkboxes</h2>
    <div class="inputGroup">
        <input id="option1" name="option1" type="checkbox" />
        <label for="option1">Option One</label>
    </div>

    <div class="inputGroup">
        <input id="option2" name="option2" type="checkbox" />
        <label for="option2">Option Two</label>
    </div>

    <h2>Radio Buttons</h2>
    <div class="inputGroup">
        <input id="radio1" name="radio" type="radio" />
        <label for="radio1">Yes</label>
    </div>
    <div class="inputGroup">
        <input id="radio2" name="radio" type="radio" />
        <label for="radio2">No</label>
    </div>
</form> -->