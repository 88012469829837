<!-- <p class="text-muted mt-4 mb-1">
    <span class="text-primary">Portal de Comissionamento.</span>
</p> -->

<h1 class="mt-4 mb-0 text-muted">
    Pedidos Online
</h1>



<p class="text-muted mt-4">
    Filtrar Pedidos Online por:
</p>

<form [formGroup]="formEmpresa">
    <div class="row">
        <div class="col-lg-4">
            <div class="form-group">
                <mat-form-field appearance="fill" class="selects100vw">
                    <mat-label> Empresa:</mat-label>
                    <mat-select formControlName="idEmpresa">
                        <mat-option value="0" (click)="selectFilter()">
                            TODAS
                        </mat-option>
                        <mat-option *ngFor="let empresa of empresas" [value]="empresa.codigo" (click)="selectFilter()">
                            {{empresa.descricao}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="col-lg-4">
            <mat-form-field appearance="fill" class="selects100vw">
                <mat-label>Tipo de Solicitação</mat-label>
                <mat-select matNativeControl formControlName="tipoSolicitacao">
                    <mat-option value="Todas" (click)="selectFilter()">
                        TODAS
                    </mat-option>
                    <mat-option value="SOLICITAÇÃO DE CARTONAGEM" (click)="selectFilter()">
                        Solicitação de Cartonagem
                    </mat-option>
                    <mat-option value="SOLICITACAO DE CAIXAS PARA PESQUISA" (click)="selectFilter()">
                        Solicitação de Caixas para Pesquisa
                    </mat-option>
                    <mat-option value="SOLICITACAO DE RETIRADA DE CAIXAS DESCARTE DIRETO" (click)="selectFilter()">
                        Solicitação de Retirada de Caixas Descarte Direto
                    </mat-option>
                    <mat-option value="SOLICITAÇÃO DE DOCUMENTOS DESMOBILIZAÇÃO" (click)="selectFilter()">
                        Solicitação de Documentos Desmobilização
                    </mat-option>
                    <mat-option value="SOLICITAÇÃO DE COLETA DE CAIXAS PARA GUARDA" (click)="selectFilter()">
                        Solicitação de Coleta de Caixas para Guarda
                    </mat-option>
                    <mat-option value="SOLICITAÇÃO DE PESQUISA DE DOCUMENTOS FÍSICOS" (click)="selectFilter()">
                        Solicitação de Pesquisa de Documentos Físicos
                    </mat-option>
                    <mat-option value="SOLICITAÇÃO DE PESQUISA DE DOCUMENTOS ELETRÔNICOS" (click)="selectFilter()">
                        Solicitação de Pesquisa de Documentos Eletrônicos
                    </mat-option>
                    <mat-option value="SOLICITAÇÃO DE ANÁLISE DOCUMENTAL" (click)="selectFilter()">
                        Solicitação de Análise Documental
                    </mat-option>
                    <mat-option value="SOLICITAÇÃO DE ASSINATURA DIGITAL DE DOCUMENTOS" (click)="selectFilter()">
                        Solicitação de Assinatura Digital de Documentos
                    </mat-option>
                    <mat-option value="SOLICITAÇÃO DE PROJETO DE TRANSFORMAÇÃO DIGITAL" (click)="selectFilter()">
                        Solicitação de Projeto de Transformação Digital
                    </mat-option>
                    <mat-option value="SOLICITAÇÃO DE TREINAMENTO E CAPACITAÇÃO" (click)="selectFilter()">
                        Solicitação de Treinamento e Capacitação
                    </mat-option>
                    <mat-option value="SOLICITAÇÃO DE DIGITALIZAÇÃO SEGURA DE DOCUMENTOS" (click)="selectFilter()">
                        Solicitação de Digitalização Segura de Documentos
                    </mat-option>
                    <mat-option value="SOLICITAÇÃO DE DEVOLUÇÃO DE CAIXAS/DOCUMENTOS" (click)="selectFilter()">
                        Solicitação de Devolução de Caixas/Documentos
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-lg-4">

            <mat-form-field appearance="fill" class="selects100vw">
                <input type="text" matInput placeholder="Id da Solicitação" aria-label="Id da Solicitação"
                    formControlName="id" (keyup)="filterByIdSubmit()">
            </mat-form-field>

        </div>

    </div>


</form>

<div class="row">

    <div class="col">

        <div class="btn-group btn-group-toggle" data-toggle="buttons">
            <label class="btn btn-secondary">
                <input id="firstButton" type="radio" name="aguardando" (click)="filtrar(0)" checked>
                Aguardando recepção
            </label>
            <label class="btn btn-secondary">
                <input type="radio" name="andamento" (click)="filtrar(1)">
                Em andamento
            </label>
            <label class="btn btn-secondary">
                <input type="radio" name="concluidos" (click)="filtrar(2)">
                Concluidos
            </label>
            <label class="btn btn-secondary">
                <input type="radio" name="cancelados" (click)="filtrar(3)">
                Cancelados
            </label>
            <label class="btn btn-secondary">
                <input type="radio" name="pendentes" (click)="filtrar(4)">
                Pendentes
            </label>
        </div>
    </div>
</div>

<div class="mat-elevation-z8 mt-4">
    <table class="table-striped" mat-table [dataSource]="dataSource">

        <!-- Id. Column -->
        <ng-container matColumnDef="id">
            <th class="id" mat-header-cell *matHeaderCellDef> Id. </th>
            <td class="id" mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <!-- Criado emnto Column -->
        <ng-container matColumnDef="criadoEm">
            <th mat-header-cell *matHeaderCellDef> Criado em </th>
            <td class="criadoEm" mat-cell *matCellDef="let element"> {{element.criadoEm | date: 'dd/MM/yyyy HH:mm:ss'}}
            </td>
        </ng-container>

        <!-- Razão Social Column -->
        <ng-container matColumnDef="razaoSocial">
            <th mat-header-cell *matHeaderCellDef> Razão Social </th>
            <td class="razaoSocial" mat-cell *matCellDef="let element"> {{element.razaoSocial}} </td>
        </ng-container>

        <!-- Origem Column -->
        <ng-container matColumnDef="solicitante">
            <th mat-header-cell *matHeaderCellDef> Solicitante </th>
            <td class="solicitante" mat-cell *matCellDef="let element"> {{element.solicitante}} </td>
        </ng-container>

        <!-- Quantidade Column -->
        <ng-container matColumnDef="tipoSolicitacao">
            <th mat-header-cell *matHeaderCellDef> Tipo de Solicitação </th>
            <td class="tipoSolicitacao" mat-cell *matCellDef="let element"> {{element.tipoDeSolicitacao}} </td>
        </ng-container>

        <!-- Urgente Column -->
        <ng-container matColumnDef="urgente">
            <th mat-header-cell *matHeaderCellDef> Urgente </th>
            <td class="urgente" mat-cell *matCellDef="let element"> {{(element.urgente === 'Y') ? 'Sim':'Não'}} </td>
        </ng-container>

        <!-- Importação Column -->
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td class="status" mat-cell *matCellDef="let element"> {{element.status}} </td>
        </ng-container>

        <!-- ações -->
        <ng-container matColumnDef="acoes">
            <th mat-header-cell *matHeaderCellDef> Ações </th>
            <td class="acoes" mat-cell *matCellDef="let element">

                <a *ngIf="element.status == 'Aguardando Recepção'" matSuffix matTooltip="Recepcionar Pedido"
                    class="btn btn-sm btn-primary text-white mr-2 a-acoes" (click)="recepcinarPedidos(element.id)">
                    <div class="mat-icon-flex-button">
                        <mat-icon>play_arrow </mat-icon>
                    </div>
                </a>

                <a routerLink="/concluir-pedido" *ngIf="element.status == 'Em Andamento'" matSuffix
                    matTooltip="Concluir Pedido" class="btn btn-sm btn-primary text-white mr-2 a-acoes"
                    (click)="concluirPedidos(element.id)">
                    <div class="mat-icon-flex-button">
                        <mat-icon>verified </mat-icon>
                    </div>
                </a>

                <a *ngIf="element.status == 'Concluído'" matSuffix matTooltip="Download"
                    class="btn btn-sm btn-primary text-white mr-2 a-acoes">
                    <div class="mat-icon-flex-button">
                        <mat-icon>download </mat-icon>
                    </div>
                </a>

                <a *ngIf="element.status == 'Concluído'" matSuffix matTooltip="Visualizar Concluído"
                    class="btn btn-sm btn-success text-white mr-2 a-acoes"
                    (click)="visualizarPedidoConcluido(element.id)">
                    <div class="mat-icon-flex-button">
                        <mat-icon>search </mat-icon>
                    </div>
                </a>

            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="pageEvent = $event"></mat-paginator>
</div>