<h1 class="text-primary mt-4">Sobre o Portal de Governança Documental</h1>
<!-- <h1 class="mt-2">Sobre o Portal de Governança Documental</h1> -->

<p>
    O Portal de Governança Documental da tem como finalidade contribuir para institucionalizar a cultura da gestão
    documental da Telefônica Brasil. Na prática tem como objetivos racionalizar e otimizar as atividades de produção,
    organização, armazenamento, acesso, uso e preservação de documentos em papel e natodigitais em conformidade com as
    melhores práticas procedimentais e jurídicas e normativas.


</p>

<p>
    Em sua essência, o Portal tem como finalidade:
</p>

<p>
    <strong>Integrar</strong> os sistemas de informações atuais, gerenciando em uma única plataforma todas as
    solicitações de
    serviços referentes a Gestão Documental Analógica ou Digital.
</p>

<p>
    <strong>Disponibilizar</strong> aos colaboradores o acesso rápido e facilitado das políticas, normativas e
    procedimentos de
    gestão documental, plano de classificação e tabela de temporalidade de documentos, materiais informativos,
    tutoriais e FAQs sobre gestão documental.
</p>

<!-- <h1 class="text-primary mt-4">Objetivos do Portal de Governança Documental</h1> -->
<!-- <h1 class="mt-2">Objetivos do Portal de Governança Documental</h1> -->

<!-- <video width="320" height="240" controls="controls" autoplay="autoplay"> -->
<div class="container">
    <div class="row">
        <div class="col-sm-6 offset-sm-3">
            <!-- <video controls="controls" class="img-fluid" autoplay="autoplay">
                <source src="../../assets/vídeo/portal-governança-documental-mod.mp4" type="video/mp4">
                <object data="" width="320" height="240">
                    <embed width="320" height="240" src="portal-governança-documental-mod.mp4">
                </object>
            </video> -->
            <video controlsList="nodownload" oncontextmenu="return false;" class="img-fluid" controls autoplay
                preload="auto" loop #myVideo tabindex="0">
                <source src="../../assets/vídeo/portal-governança-documental-mod.mp4" type="video/mp4">
                <object data="">
                    <embed src="portal-governança-documental-mod.mp4">
                </object>
            </video>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col-lg-12">
            <img src="../assets/imagens/sobre.jpg" class="img-fluid" alt="">
        </div>
    </div>
</div>

<!-- <div class="row row-cols-1 row-cols-md-3 text-center">
    <div class="col mb-3">
        <div class="card bg-laranja text-white">
            <div class="card-body">
                <p class="card-text">
                    Contribuir para a Continuidade e Crescimento Sustentável da Telefônica Brasil
                </p>
            </div>
        </div>
    </div>
</div>

<div class="row row-cols-1 row-cols-md-3 text-center">
    <div class="col mb-3">
        <div class="card bg-vermelho text-white">
            <div class="card-body">
                <p class="card-text">
                    Estabelecer a Governança Documental alinhada com a Governança Corporativa - Normas e Leis
                </p>
            </div>
        </div>
    </div>
</div>

<div class="row row-cols-1 row-cols-md-3 text-center">
    <div class="col mb-3">
        <div class="card bg-azul-escuro text-white">
            <div class="card-body">
                <p class="card-text">
                    Implantação de Política de Gestão Documental alinhada com a Gestão de Processos
                </p>
            </div>
        </div>
    </div>
</div>

<div class="row row-cols-1 row-cols-md-3 text-center">
    <div class="col mb-3">
        <div class="card bg-azul-claro text-white">
            <div class="card-body">
                <p class="card-text">
                    Acessibilidade
                </p>
            </div>
        </div>
    </div>

    <div class="col mb-3">
        <div class="card bg-azul-claro text-white">
            <div class="card-body">
                <p class="card-text">
                    Preservação
                </p>
            </div>
        </div>
    </div>

    <div class="col mb-3">
        <div class="card bg-azul-claro text-white">
            <div class="card-body">
                <p class="card-text">
                    Integridade
                </p>
            </div>
        </div>
    </div>

    <div class="col mb-3">
        <div class="card bg-azul-claro text-white">
            <div class="card-body">
                <p class="card-text">
                    Conformidade
                </p>
            </div>
        </div>
    </div>

    <div class="col mb-3">
        <div class="card bg-azul-claro text-white">
            <div class="card-body">
                <p class="card-text">
                    Confiabilidade
                </p>
            </div>
        </div>
    </div>

    <div class="col mb-3">
        <div class="card bg-azul-claro text-white">
            <div class="card-body">
                <p class="card-text">
                    Segurança
                </p>
            </div>
        </div>
    </div>

</div>


<div class="row row-cols-1 row-cols-md-3 text-center">
    <div class="col mb-3">
        <div class="card bg-verde text-white">
            <div class="card-body">
                <p class="card-text">
                    Transformação Digital
                </p>
            </div>
        </div>
    </div>
</div>


<div class="row row-cols-1 row-cols-md-3 text-center">
    <div class="col mb-3">
        <div class="card bg-azul-claro-2 text-white">
            <div class="card-body">
                <p class="card-text">
                    Requisitos de Infraestrutura Tecnológica
                </p>
            </div>
        </div>
    </div>

    <div class="col mb-3">
        <div class="card bg-azul-claro-2 text-white">
            <div class="card-body">
                <p class="card-text">
                    Soluções e Plataformas de GED/ECM
                </p>
            </div>
        </div>
    </div>

    <div class="col mb-3">
        <div class="card bg-azul-claro-2 text-white">
            <div class="card-body">
                <p class="card-text">
                    Digitalização
                </p>
            </div>
        </div>
    </div>

    <div class="col mb-3">
        <div class="card bg-azul-claro-2 text-white">
            <div class="card-body">
                <p class="card-text">
                    Assinatura eletrônica/digital e serviços notariais digitais
                </p>
            </div>
        </div>
    </div>

    <div class="col mb-3">
        <div class="card bg-azul-claro-2 text-white">
            <div class="card-body">
                <p class="card-text">
                    Capacitação de Recursos Humanos
                </p>
            </div>
        </div>
    </div>


</div> -->