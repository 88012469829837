import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { LocalGuarda } from '../_models/local-guarda';

@Injectable({
  providedIn: 'root'
})
export class LocalGuardaService {

  constructor(private http: HttpClient) { }

  insertLocalGuarda(localGuarda: LocalGuarda){
    return this.http.post<LocalGuarda>(`${environment.apiUrl}/local-guarda`, localGuarda);
  }
  editLocalGuarda(localGuarda: LocalGuarda){
    return this.http.put<LocalGuarda>(`${environment.apiUrl}/local-guarda`, localGuarda);
  }
  deleteLocalGuarda(localGuarda: LocalGuarda){
    return this.http.delete<LocalGuarda>(`${environment.apiUrl}/local-guarda/${localGuarda.id}`, localGuarda.id);
  }
  getAllLocalGuarda(empresaId, page, count,sort,order) {
    return this.http.get<any>(`${environment.apiUrl}/local-guarda/search/${empresaId}/${page}/${count}?sort=${sort}&order=${order}`).pipe(tap(console.log));
  }
}
