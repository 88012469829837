<mat-form-field appearance="fill" class="w-100">
  <mat-label>Competência:</mat-label>
  <input
    matInput
    [formControl]="competenceDate"
    [matDatepicker]="pickerCompetencia"
    required
  />
  <mat-datepicker-toggle
    matSuffix
    [for]="pickerCompetencia"
  ></mat-datepicker-toggle>
  <mat-datepicker
    #pickerCompetencia
    startView="multi-year"
    (monthSelected)="setMonthAndYear($event, pickerCompetencia)"
  ></mat-datepicker>
</mat-form-field>
