import { Component, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { TooltipPosition } from "@angular/material/tooltip";
import { NavigationEnd, Router } from "@angular/router";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import {
  BsModalRef,
  BsModalService,
  ModalDirective,
} from "ngx-bootstrap/modal";
import { Observable } from "rxjs";
import { filter } from "rxjs/operators";
import * as $ from "../assets/jquery-3.5.1.min";
import { User2AF } from "./_models";
import { AccountService } from "./_services";

@Component({
  selector: "app",
  templateUrl: "app.component.html",
})
export class AppComponent {
  public href: string = "";
  public modalRef: BsModalRef;

  user$: Observable<User2AF>;
  url$: Observable<string> = new Observable<string>();

  actualYear = new Date().getFullYear();
  idleState = "Not started.";
  timedOut = false;
  lastPing?: Date = null;
  title = "angular-idle-timeout";

  positionOptions: TooltipPosition[] = [
    "after",
    "before",
    "above",
    "below",
    "left",
    "right",
  ];

  position = new FormControl(this.positionOptions[0]);

  @ViewChild("childModal", { static: false }) childModal: ModalDirective;

  constructor(
    private accountService: AccountService,
    private idle: Idle,
    private keepalive: Keepalive,
    private modalService: BsModalService,
    private router: Router
  ) {
    idle.setIdle(50000);
    idle.setTimeout(60);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState =
        "Clique em <strong>'Continuar Logado'</strong> para continuar ou em <strong>'Logout'</strong> para sair.";
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = "Logout!";
      this.timedOut = true;
      this.logout();
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = "Inativo!";

      this.childModal.show();
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState =
        "Você será desconectado em <strong>" +
        countdown +
        "</strong> segundos!";
    });

    keepalive.interval(1);

    keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    this.user$ = this.accountService.userObservable;
  }

  reset() {
    this.idle.watch();
    //xthis.idleState = 'Started.';
    this.timedOut = false;
  }

  hideChildModal(): void {
    this.childModal.hide();
  }

  stay() {
    this.childModal.hide();
    this.user$.subscribe((user) => {
      this.accountService.refreshToken(user).subscribe((response) => {});
    });
    this.reset();
  }

  logout() {
    this.childModal.hide();
    this.accountService.logout();
  }

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (this.href == "/arquivos") {
          $("#criar-artigo").css("display", "block");
        } else {
          $("#criar-artigo").css("display", "none");
        }
      });

    $(document).ready(function () {
      $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
          $('a[href="#top"]').fadeIn();
        } else {
          $('a[href="#top"]').fadeOut();
        }
      });

      $('a[href="#top"]').click(function () {
        $("html, body").animate({ scrollTop: 0 }, 800);
        return false;
      });
    });
  }
}
