import { HttpEventType } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { merge, of as observableOf } from "rxjs";
import {
  catchError,
  debounceTime,
  filter,
  finalize,
  map,
  startWith,
  switchMap,
  tap,
} from "rxjs/operators";

import { TableUtil } from "../_helpers/tableUtil";
import { User, UserFilterParams, UserList } from "../_models";
import { CaptalizePipe } from "../_pipes/captalize.pipe";
import { AccountService, AlertService } from "../_services";
import { GestaoUsuarioService } from "../_services/gestao-usuario.service";
import { SolicitacaoService } from "../_services/solicitacao.service";
import { UploadService } from "../_services/upload.service";
import { MatSort } from "@angular/material/sort";

@Component({
  selector: "app-gestao-usuario",
  templateUrl: "./gestao-usuario.component.html",
  styleUrls: ["./gestao-usuario.component.css"],
  providers: [CaptalizePipe],
})
export class GestaoUsuarioComponent implements OnInit {
  formAdicionar: FormGroup;
  formEditar: FormGroup;
  formFilter: FormGroup;
  formData: MatTableDataSource<any>;
  EditedUser: User;
  submitted = false;
  start = null;
  solicitationData: any[] = [];
  usersDataAll: UserDto[] = [];
  user: User;
  allEmails: any[];
  userAccountEnableTemp: User;

  @ViewChild("fileUpload", { static: false }) fileUpload: ElementRef;
  files = [];
  attachments: any[] = [];

  nameSearch: string;
  areaSearch: string;
  userTypeSearch: string;

  userFilterParams: UserFilterParams;

  dataSource = new MatTableDataSource<UserList>();
  dataSourceLength: number;

  @ViewChild("closeModalAdicionar") closeModalAdicionar: ElementRef;
  @ViewChild("closeModalEditar") closeModalEditar: ElementRef;
  @ViewChild("closeModalStatus") closeModalStatus: ElementRef;
  @ViewChild("closeModalImportar") closeModalImportar: ElementRef;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private gestaoUsuarioService: GestaoUsuarioService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private accountService: AccountService,
    private uploadService: UploadService,
    private captalizePipe: CaptalizePipe,
    private solicitacaoService: SolicitacaoService
  ) {
    this.user = this.accountService.userValue.user;
  }

  ngOnInit(): void {
    this.formFilter = this.formBuilder.group({
      userType: [""],
      name: [""],
      area: [""],
      status: [""],
      startDate: [""],
      endDate: [""],
      keyword: [""],
    });

    this.formAdicionar = this.formBuilder.group({
      firstName: ["", [Validators.required, Validators.minLength(3)]],
      lastName: ["", [Validators.required, Validators.minLength(3)]],
      email: [
        "",
        [Validators.required, Validators.minLength(3), Validators.pattern],
      ],
      area: ["", [Validators.required, Validators.minLength(3)]],
      userType: ["", [Validators.required]],
      profile: ["", [Validators.required]],
    });

    this.formEditar = this.formBuilder.group({
      id: [""],
      firstName: ["", [Validators.required, Validators.minLength(3)]],
      lastName: ["", [Validators.required, Validators.minLength(3)]],
      email: [
        "",
        [Validators.required, Validators.minLength(3), Validators.pattern],
      ],
      area: ["", [Validators.required, Validators.minLength(3)]],
      userType: ["", [Validators.required]],
      profile: ["", [Validators.required]],
      enabled: [""],
    });

    this.solicitacaoService.getAccessSolicitationAll().subscribe((res) => {
      this.solicitationData = res;
    });

    this.userFilterParams = {
      userType: "TODOS",
      name: "TODOS",
      area: "TODOS",
      status: "TODOS",
      startDate: "0",
      endDate: "0",
    };

    this.resetFilterParams();
    this.OnInitializeDataSource();
    this.accountService.getAll().subscribe((data) => {
      this.usersDataAll = data["data"];
    });
  }

  ngAfterViewInit() {
    this.paginator.pageIndex = 0;
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.filter()))
      .subscribe();
  }

  get formAdd(): { [key: string]: AbstractControl } {
    return this.formAdicionar.controls;
  }

  get formEdit(): { [key: string]: AbstractControl } {
    return this.formEditar.controls;
  }

  getAllUsers(): void {
    this.gestaoUsuarioService.getAllUsuarios().subscribe((users) => {
      this.dataSource = new MatTableDataSource(users.data.reverse());
      this.formData = this.dataSource;
      this.allEmails = users.data.map((user) => user.email);
      this.start = true;
    });
  }

  applyFilter(filterValue: string) {
    if (filterValue.length > 2) {
      this.formFilter.get("keyword").setValue(filterValue.trim().toLowerCase());
      this.filter();
    }
  }

  validateFilterParams() {
    const filtro = this.formFilter.value;

    if (filtro.name === "" || filtro.name === null) {
      filtro.name = "TODOS";

      if (filtro.keyword !== "" && filtro.keyword !== null) {
        filtro.name = filtro.keyword;
      }
    }

    if (filtro.status === "" || filtro.status === null) {
      filtro.status = "TODOS";
    }

    if (filtro.area === "" || filtro.area === null) {
      filtro.area = "TODOS";
    }

    if (filtro.userType === "" || filtro.userType === null) {
      filtro.userType = "TODOS";
    }

    if (
      filtro.startDate === "" ||
      filtro.startDate === null ||
      filtro.startDate === "0"
    ) {
      filtro.startDate = "0";
    } else {
      filtro.startDate = String(new Date(filtro.startDate).getTime());
    }

    if (
      filtro.endDate === "" ||
      filtro.endDate === null ||
      filtro.endDate === "0"
    ) {
      filtro.endDate = "0";
    } else {
      filtro.endDate = String(new Date(filtro.endDate).getTime());
    }

    this.userFilterParams = {
      userType: filtro.userType,
      name: filtro.name,
      area: filtro.area,
      status: filtro.status,
      startDate: filtro.startDate,
      endDate: filtro.endDate,
    };
  }

  OnInitializeDataSource() {
    this.gestaoUsuarioService
      .getUsersFiltered(
        0,
        5,
        "id",
        "desc",
        this.userFilterParams.name,
        this.userFilterParams.status,
        this.userFilterParams.area,
        this.userFilterParams.userType,
        this.userFilterParams.startDate,
        this.userFilterParams.endDate
      )
      .subscribe((data) => {
        this.dataSource = new MatTableDataSource(data["content"]);
        this.dataSourceLength = data.totalElements;
        this.formData = this.dataSource;
      });
  }

  filter() {
    this.validateFilterParams();
    this.gestaoUsuarioService
      .getUsersFiltered(
        this.paginator.pageIndex,
        this.paginator.pageSize,
        this.sort.active,
        this.sort.direction,
        this.userFilterParams.name,
        this.userFilterParams.status,
        this.userFilterParams.area,
        this.userFilterParams.userType,
        this.userFilterParams.startDate,
        this.userFilterParams.endDate
      )
      .subscribe((data) => {
        this.dataSource = new MatTableDataSource(data["content"]);
        this.dataSourceLength = data.totalElements;
        this.formData = this.dataSource;
      });
  }

  resetFilterParams() {
    this.userFilterParams = {
      userType: "TODOS",
      name: "TODOS",
      area: "TODOS",
      status: "TODOS",
      startDate: "0",
      endDate: "0",
    };
  }

  clean(): void {
    this.formFilter.reset();
    this.submitted = false;
    this.resetFilterParams();
    this.paginator.pageIndex = 0;
    this.OnInitializeDataSource();
  }

  getAllUsersPagined(data: any): void {
    this.gestaoUsuarioService
      .getAllUsuariosPaged(
        0,
        100,
        "id",
        "DESC",
        data.name,
        data.area,
        data.userType
      )
      .subscribe((users) => {
        console.log(users);
        this.dataSource = new MatTableDataSource(users.content);
        // this.allEmails = users.data.map((user) => user.email);
      });
  }

  addNewUser(element: any): void {
    this.gestaoUsuarioService.insertUsuario(element).subscribe(() => {
      this.formAdicionar.reset();
      this.clean();
    });
  }

  updateUser(element: any): void {
    this.gestaoUsuarioService.userUpdate(element).subscribe(() => {
      this.EditedUser = {} as User;
      this.clean();
    });
  }

  updateUserStatus(): void {
    let tempUser: any = {
      ...this.userAccountEnableTemp,
      enabled: !this.userAccountEnableTemp.enabled,
    };

    this.gestaoUsuarioService.userUpdate(tempUser).subscribe(() => {
      this.alertService.success(
        `Status da Conta Modificado para ${this.checkStatus(
          !this.userAccountEnableTemp.enabled
        )}`,
        {
          keepAfterRouteChange: true,
          autoClose: true,
        }
      );
      this.userAccountEnableTemp = null;
      this.closeModalStatus.nativeElement.click();
      this.clean();
    });
  }

  reenviarAcesso(element: any): void {
    let result = confirm(
      `Deseja resetar a senha do ${element.email} e Reenviar o Acesso?`
    );
    if (result) {
      this.gestaoUsuarioService
        .tempPasswordUsuario(element.id)
        .subscribe(() => {
          this.alertService.success(`Email Enviado para ${element.email}`, {
            keepAfterRouteChange: true,
            autoClose: true,
          });
        });
    }
  }

  checkStatus(status: boolean): string {
    if (status) return "Habilitado";
    else return "Desabilitado";
  }

  populateEditForm(element: any): void {
    this.EditedUser = element;

    this.formEditar.setValue({
      id: element.id,
      firstName: element.firstName,
      lastName: element.lastName,
      email: element.email,
      area: element.area,
      userType: element.userType,
      profile: element.profile,
      enabled: element.enabled,
    });
  }

  convertDate(date: number) {
    function formatZero(numero) {
      return numero <= 9 ? "0" + numero : numero;
    }

    let timeStamp_date = new Date(date);
    let year = timeStamp_date.getFullYear();
    if (year < 2000) return "Não cadastrado";

    let month = formatZero(timeStamp_date.getMonth() + 1);
    let day = formatZero(timeStamp_date.getDate());
    let hour = formatZero(timeStamp_date.getHours());
    let min = formatZero(timeStamp_date.getMinutes());

    return `${day}/${month}/${year} | ${hour}:${min}`;
  }

  onSubmitAdd(): void {
    this.submitted = true;

    if (this.formAdicionar.invalid) {
      return;
    }

    let tempUser: any = {
      firstName: this.formAdicionar.get("firstName").value,
      lastName: this.formAdicionar.get("lastName").value,
      email: this.formAdicionar.get("email").value,
      area: this.formAdicionar.get("area").value,
      userType: this.formAdicionar.get("userType").value,
      profile: this.formAdicionar.get("profile").value,
    };

    this.submitted = false;
    this.addNewUser(tempUser);
    this.closeModalAdicionar.nativeElement.click();
  }

  onSubmitUpdate(): void {
    this.submitted = true;

    if (this.formEditar.invalid) {
      return;
    }

    let tempUser: any = {
      ...this.EditedUser,
      id: this.formEditar.get("id").value,
      area: this.formEditar.get("area").value,
      email: this.formEditar.get("email").value,
      lastName: this.formEditar.get("lastName").value,
      enabled: this.formEditar.get("enabled").value,
      fullName:
        this.formEditar.get("firstName").value +
        " " +
        this.formEditar.get("lastName").value,
      firstName: this.formEditar.get("firstName").value,
      profile: this.formEditar.get("profile").value,
      userType: this.formEditar.get("userType").value,
    };

    if (
      tempUser.email === this.user.email &&
      tempUser.userType === this.user.userType
    ) {
      let result = confirm(
        `Se você alterar o seu tipo de Usuario, você sera deslogado, Deseja Alterar o Perfil do Usuário?`
      );
      if (result) {
        this.accountService.logout();
      } else {
        return;
      }
    }

    this.closeModalEditar.nativeElement.click();

    this.updateUser(tempUser);
  }

  //uploads

  removeFile(file: any) {
    this.attachments = this.attachments.filter(
      (item) => item.originalFileName !== file.originalFileName
    );
    this.files = this.files.filter(
      (item) => item.data.name !== file.originalFileName
    );
  }

  uploadFile(file) {
    const formData = new FormData();
    formData.append("file", file.data);
    file.inProgress = true;
    this.uploadService
      .uploadAnexoCsv(formData)
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round((event.loaded * 100) / event.total);
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        catchError(() => {
          file.inProgress = false;
          return observableOf(`${file.data.name} upload failed.`);
        })
      )
      .subscribe((event: any) => {
        if (typeof event === "object") {
          this.attachments.push(event.body);
          console.log(this.attachments);
        }
      });
    this.user.userType;
  }

  private uploadFiles() {
    this.fileUpload.nativeElement.value = "";
    this.files.forEach((file) => {
      this.uploadFile(file);
    });
  }

  onUploadButtonClick() {
    this.files = [];
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({ data: file, inProgress: false, progress: 0 });
      }
      this.uploadFiles();
    };
  }

  onSubmit() {
    this.clean();
    this.closeModalImportar.nativeElement.click();
  }

  exportTable() {
    TableUtil.exportJsonToExcel(this.createJsonForXls());
  }

  createJsonForXls() {
    const content = [];
    let solicitationData = [];
    let data = new Object();

    this.columnsToDisplay.forEach((element) => {
      data[element] = [] as any[];
    });

    let clean = data;

    this.usersDataAll.forEach((element) => {
      data["id"] = element.id;
      data["area"] = element.area;
      data["email"] = element.email;
      data["status"] = validateStatus(element.enabled);
      data["firstName"] = this.captalizePipe.transform(element.firstName);
      data["lastName"] = this.captalizePipe.transform(element.lastName);
      data["fullName"] = this.captalizePipe.transform(element.fullName);
      data["profile"] = this.captalizePipe.transform(element.profile);
      data["userType"] = this.captalizePipe.transform(element.userTypeEnum);
      data["firstAccess"] = transform(
        new Date(element.firstAccessDate).getTime()
      );
      data["lastAccess"] = transform(
        new Date(element.lastAccessDate).getTime()
      );

      const solicitationUser = this.solicitationData.find(
        (solicitation) => solicitation.email == element.email
      );

      if (solicitationUser) {
        let fullName = solicitationUser.userAceite.fullName;
        if (fullName == null || fullName == undefined || fullName == "") {
          fullName = `${this.captalizePipe.transform(
            solicitationUser.userAceite.firstName
          )} ${this.captalizePipe.transform(
            solicitationUser.userAceite.lastName
          )}`;
        } else fullName = this.captalizePipe.transform(fullName);

        data["solicitationDate"] = transform(
          new Date(solicitationUser.creationDate).getTime()
        );

        data["solicitationUserAproveEmail"] = solicitationUser.userAceite.email;
        data["solicitationUserAproveFullName"] = fullName;

        data["solicitationDateAprove"] = transform(
          new Date(solicitationUser.approvalDate).getTime()
        );
      } else {
        data["solicitationDate"] = "";
        data["solicitationUserAproveEmail"] = "";
        data["solicitationUserAproveFullName"] = "";
        data["solicitationDateAprove"] = "";
      }

      clean = { ...data };

      content.push(clean);

      Object.keys(data).forEach((key) => {
        data[key] = [];
      });
    });

    return content;

    function validateStatus(status: boolean) {
      if (status) {
        return "Habilitado";
      }
      return "Desabilitado";
    }

    function transform(date: number): string {
      function formatZero(numero) {
        return numero <= 9 ? "0" + numero : numero;
      }

      let timeStamp_date = new Date(date);
      let year = timeStamp_date.getFullYear();
      let month = formatZero(timeStamp_date.getMonth() + 1);
      let day = formatZero(timeStamp_date.getDate());
      let hour = formatZero(timeStamp_date.getHours());
      let min = formatZero(timeStamp_date.getMinutes());
      let exit = `${day}/${month}/${year} - ${hour}:${min}`;

      if (exit == "31/12/1969 - 21:00") {
        return "";
      }

      return exit;
    }
  }

  displayedColumns: string[] = [
    "id",
    "nomeUsuario",
    "tipoUsuario",
    "area",
    "dataCadastro",
    "ultimoAcesso",
    "userStatus",
    "acoes",
  ];

  columnsToDisplay = [
    "id",
    "area",
    "email",
    "status",
    "firstName",
    "lastName",
    "fullName",
    "profile",
    "userType",
    "firstAccess",
    "lastAccess",
    "solicitationDate",
    "solicitationUserAproveEmail",
    "solicitationUserAproveFullName",
    "solicitationDateAprove",
  ];
}

export interface UserDto {
  id: number;
  userTypeEnum: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  password: string;
  profile: string;
  empresa: null;
  area: string;
  cargo: null;
  areaNaTelefonica: null;
  token: null;
  requestedAccess: null;
  phone: string;
  unidadeOrganizacional: null;
  gestorImediato: null;
  matricula: null;
  autorizadoId: null;
  twoFactorCode: null;
  firstAccess: boolean;
  enabled: boolean;
  confirmed: boolean;
  photo: null;
  firstAccessDate: number;
  lastAccessDate: number;
  tempPassword: boolean;
  ferias: null;
  historyPasswordsUsed: string;
}
