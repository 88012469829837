import { BarChartOptions, ChartOptions } from "../../_models/tickets";
import { ChartComponent } from "ng-apexcharts";
import { InsumoService } from "src/app/_services/insumos.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { chartPartialOptionsBar } from "src/app/_helpers/chartPartialOptionsBAR";

@Component({
  selector: "app-todos-insumos-tempo",
  templateUrl: "./todos-insumos-tempo.component.html",
  styleUrls: ["./todos-insumos-tempo.component.css"],
})
export class TodosInsumosTempoComponent implements OnInit {
  chartOptions: Partial<BarChartOptions>;
  @ViewChild("chart") chart: ChartComponent;

  months = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];

  constructor(private insumoService: InsumoService) {
    this.chartOptions = {
      ...chartPartialOptionsBar,
      xaxis: { categories: [""] },
      legend: {
        labels: {
          colors: ["#660099", "#e83e8c"],
        },
        markers: {
          fillColors: ["#660099", "#e83e8c"],
        },
      },
      fill: {
        colors: ["#660099", "#e83e8c"],
      },
    };
  }

  ngOnInit(): void {
    this.getAllInsumosBarChart();
  }

  getAllInsumosBarChart() {
    this.insumoService
      .getAllInsumosBarChart()
      .subscribe(({ vigentesEntregues, vigentesNaoEntregues, mesAtual }) => {
        this.chartOptions.xaxis = {
          categories: [
            this.months[mesAtual - 4],
            this.months[mesAtual - 3],
            this.months[mesAtual - 2],
            this.months[mesAtual - 1],
          ],
        };
        this.chartOptions.series = [
          {
            name: "Vigentes Entregues",
            data: vigentesEntregues,
          },
          {
            name: "Vigentes Não Entregues",
            data: vigentesNaoEntregues,
          },
        ];
      });
  }
}
