<!-- <h1 class="mt-4 text-primary">Área do usuário</h1> -->


<div class="row mt-5">
  <div class="col-lg-8 offset-lg-2">
    <form action="" method="">


      <!-- <div class="text-center">
          <img src="../../assets/perfil/zac.jpg" class="img-fluid perfil-usuario" alt="perfil usuario">
      </div> -->

      <div class="row">
        <div class="col-lg-12 text-center">

          <div class="avatar-upload mb-3">
            <!-- <div class="avatar-edit">
              <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" #fileInput (change)="uploadFile($event)" />
            </div> -->
            <div class="avatar-preview">
              <div id="imagePreview" [style.backgroundImage]="'url('+ imageUrl +')'">
              </div>
            </div>
          </div>

          <!-- <div class="col-lg-6"> -->
          <!-- <mat-card-content>
            <ul>
              <li *ngFor="let file of files">
                <mat-progress-bar [value]="file.progress"></mat-progress-bar>
                <span id="file-label"></span>
              </li>
            </ul>
          </mat-card-content> -->
          <!-- <mat-card-actions>
          </mat-card-actions> -->
          <!-- <input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" accept="*/*"
            style="display:none;" /> -->
          <!-- </div> -->


          <div class="row mb-3">
            <div class="col">
              <p class="usuario-nome m-0 text-primary">{{currentUser.firstName}} {{currentUser.lastName}}</p>
              <span class="usuario-cargo m-0">{{currentUser.cargo}}</span>
            </div>
          </div>

          <!-- <p class="usuario-funcao m-0">{{currentUser.area}}</p> -->

          <!-- <div class="menu-flex">
            <mat-icon color="link" matListIcon>stop_circle</mat-icon>
            <p>Online</p>
          </div> -->

          <!-- Submit Button -->
          <!-- <a type="submit" class="btn btn-sm btn-success text-white upload-perfil" (click)="fileInput.click()">Alterar
            foto do perfil</a> -->

          <!-- <a href="#" data-toggle="modal" data-target="#alterarSenha"
            class="btn btn-sm btn-primary text-white upload-perfil">Alterar senha</a>
          -->

          <!-- <button mat-raised-button class="btn btn-sm btn-primary" (click)="onClick()"> -->
          <!-- <a class="btn btn-sm btn-success text-white anexar-arquivo ml-2" (click)="onClick()">
            Alterar Foto
           
            <span>
              <mat-icon>file_upload</mat-icon>
            </span>
       
          </a>    -->

          <div class="mt-2">
            <mat-card-content>
              <ul>
                <li *ngFor="let file of files">
                  <mat-progress-bar [value]="file.progress"></mat-progress-bar>
                  <span id="file-label"></span>
                </li>
              </ul>
            </mat-card-content>
          </div>

          <input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" accept="*/*"
            style="display:none;" />
        </div>

      </div>


      <div class="container-fluid mt-2">
        <div class="row">
          <div class="col-lg-12 card-detalhes">

            <!-- 
            <div class="row cabecalho">
              <div class="col-lg-6">
                <div class="menu-flex text-primary">
                  <mat-icon color="link" matListIcon>person_pin</mat-icon>
                  <p>Detalhes do perfil</p>
                </div>
              </div>
              <div class="col-lg-6 flex-end">
                  <a href="">
                      <div class="menu-flex">
                          <mat-icon color="link" matListIcon>edit</mat-icon>
                          <p>Editar</p>
                      </div>
                  </a>
              </div> 
            </div>-->

            <div class="corpo">
              <form [formGroup]="formProfile">
                <div class="row row-flex">
                  <div class="col-lg-3 titulo-informacao text-muted">
                    Nome completo:
                  </div>
                  <div class="col-lg-9">
                    <!-- <mat-form-field hintLabel="Max 40 characters"> -->
                    <mat-form-field>
                      <!-- <mat-label>Nome completo</mat-label> -->
                      <input disabled matInput #nomeCompleto maxlength="40"
                        value="{{currentUser.firstName}} {{currentUser.lastName}}" required>
                      <!-- <mat-hint align="end">{{nomeCompleto.value?.length || 0}}/40</mat-hint> -->
                      <mat-icon class="text-muted" matSuffix>person_pin</mat-icon>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row row-flex">
                  <div class="col-lg-3 titulo-informacao text-muted">
                    Cargo:
                  </div>
                  <div class="col-lg-9">
                    <!-- <mat-form-field hintLabel="Max 40 characters"> -->
                    <mat-form-field>
                      <!-- <mat-label>Nome completo</mat-label> -->
                      <input disabled matInput #cargo maxlength="40" value="{{currentUser.cargo}}" required>
                      <!-- <mat-hint align="end">{{cargo.value?.length || 0}}/40</mat-hint> -->
                      <mat-icon class="text-muted" matSuffix>assignment_ind</mat-icon>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row row-flex">
                  <div class="col-lg-3 titulo-informacao text-muted">
                    E-mail:
                  </div>
                  <div class="col-lg-9">
                    <mat-form-field class="">
                      <input disabled matInput value="{{currentUser.email}}" required>
                      <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                        Por favor insira um endereço de e-mail válido
                      </mat-error>
                      <mat-error *ngIf="emailFormControl.hasError('required')">
                        É necessário <strong>um e-mail</strong>
                      </mat-error>
                      <mat-icon class="text-muted" matSuffix>email</mat-icon>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row row-flex">
                  <div class="col-lg-3 titulo-informacao text-muted">
                    Telefone:
                  </div>
                  <div class="col-lg-9">
                    <mat-form-field class="example-full-width">
                      <!-- <mat-label>Telefone para contato</mat-label> -->
                      <span matPrefix>+55 &nbsp;</span>
                      <input type="tel" matInput formControlName="phone" mask="(00) 00000-0000">
                      <mat-icon class="text-muted" matSuffix>phone</mat-icon>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row row-flex">
                  <div class="col-lg-3 titulo-informacao text-muted">
                    Unidade Organizacional:
                  </div>
                  <div class="col-lg-9">
                    <!-- <mat-form-field hintLabel="Max 40 characters"> -->
                    <mat-form-field>
                      <!-- <mat-label>Nome completo</mat-label> -->
                      <input disabled matInput value="{{currentUser.unidadeOrganizacional}}" #unidadeOrganizacional
                        maxlength="40" required>
                      <!-- <mat-hint align="end">{{unidadeOrganizacional.value?.length || 0}}/40</mat-hint> -->
                      <mat-icon class="text-muted" matSuffix>business</mat-icon>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row row-flex">
                  <div class="col-lg-3 titulo-informacao text-muted">
                    Gestor Imediato:
                  </div>
                  <div class="col-lg-9">
                    <!-- <mat-form-field hintLabel="Max 40 characters"> -->
                    <mat-form-field>
                      <!-- <mat-label>Nome completo</mat-label> -->
                      <input disabled matInput value="{{currentUser.gestorImediato}}" #gestorImediato maxlength="40"
                        required>
                      <!-- <mat-hint align="end">{{gestorImediato.value?.length || 0}}/40</mat-hint> -->
                      <mat-icon class="text-muted" matSuffix>how_to_reg</mat-icon>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row row-flex">
                  <div class="col-lg-3 titulo-informacao text-muted">
                    Matrícula (R.E):
                  </div>
                  <div class="col-lg-9">
                    <!-- <mat-form-field hintLabel="Max 40 characters"> -->
                    <mat-form-field>
                      <!-- <mat-label>Nome completo</mat-label> -->
                      <input disabled matInput value="{{currentUser.matricula}}" #matricula maxlength="40" required>
                      <!-- <mat-hint align="end">{{matricula.value?.length || 0}}/40</mat-hint> -->
                      <mat-icon class="text-muted" matSuffix>post_add</mat-icon>
                    </mat-form-field>
                  </div>
                </div>


                <div class="d-flex mt-4">
                  
                  <button routerLink="../" type="button" class="btn btn-dark btn-sm button-flex mr-2"
                    data-dismiss="modal">
                    <mat-icon>highlight_off</mat-icon>
                    Cancelar
                  </button>

                  <!-- <button type="button" (click)="onReset()" class="btn btn-secondary button-flex btn-sm mr-2">
                    <mat-icon>backspace</mat-icon>
                    Limpar
                  </button> -->

                  <button (click)="updateProfile()" [disabled]="loading" class="btn btn-sm btn-success button-flex">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    <mat-icon>task_alt</mat-icon>
                    Atualizar
                  </button>

                </div>


              </form>

            </div>


          </div>
        </div>
      </div>



      <!-- <mat-form-field hintLabel="Max 40 characters">
          <mat-label>Nome completo</mat-label>
          <input matInput #nomeCompleto maxlength="40" placeholder="Ex. Joseph Martins" required>
          <mat-hint align="end">{{nomeCompleto.value?.length || 0}}/40</mat-hint>
          <mat-icon class="text-muted" matSuffix>person_pin</mat-icon>
      </mat-form-field> -->

      <!-- <mat-form-field>
          <mat-label>CPF</mat-label>
          <input matInput #cpf maxlength="14" placeholder="Ex. 123.456.789-11" required>
          <mat-hint align="end">{{cpf.value?.length || 0}}/14</mat-hint>
          <mat-icon class="text-muted" matSuffix>lock</mat-icon>
      </mat-form-field> -->

      <!-- <mat-form-field class="">
          <mat-label>E-mail</mat-label>
          <input matInput [formControl]="emailFormControl" placeholder="Digite seu melhor e-mail" required>
          <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
              Please enter a valid email address
          </mat-error>
          <mat-error *ngIf="emailFormControl.hasError('required')">
              É necessário <strong>um e-mail</strong>
          </mat-error>
          <mat-icon class="text-muted" matSuffix>email</mat-icon>
      </mat-form-field> -->

      <!-- <mat-form-field class="example-full-width">
          <mat-label>Telefone para contato</mat-label>
          <span matPrefix>+55 &nbsp;</span>
          <input type="tel" matInput maxlength="12" placeholder="xx xxxxxxxxx">
          <mat-icon class="text-muted" matSuffix>phone</mat-icon>
      </mat-form-field> -->

      <!-- <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <mat-form-field>
                  <mat-label>Digite sua atual senha</mat-label>
                  <input matInput [type]="hide ? 'password' : 'text'" placeholder="*********">
                  <span mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                      [attr.aria-pressed]="hide">
                      <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </span>
              </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <mat-form-field>
                  <mat-label>Digite sua nova senha</mat-label>
                  <input matInput [type]="hide ? 'password' : 'text'" placeholder="*********">
                  <span mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                      [attr.aria-pressed]="hide">
                      <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </span>
              </mat-form-field>
          </div>
      </div> -->



      <!-- <mat-form-field>
          <mat-label>Segmento de mercado</mat-label>
          <mat-select>
              <mat-option value="option">Option</mat-option>
              <mat-option value="option">Option</mat-option>
              <mat-option value="option">Option</mat-option>
              <mat-option value="option">Option</mat-option>
          </mat-select> -->
      <!-- <mat-hint align="end">Aqui está a seta suspensa ^</mat-hint> -->
      <!-- </mat-form-field> -->

      <!-- <mat-form-field>
          <mat-label>Digite seu melhor e-mail </mat-label>
          <input matInput placeholder="contato@email.com" [formControl]="email" required>
          <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
      </mat-form-field> -->

      <!-- <div class="row">

          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 order-2">
              <button mat-flat-button class="btn btn-danger mb-1 btn-block">Cancelar</button>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 order-1">
              <button mat-flat-button class="btn btn-success mb-1 btn-block">Atualizar Cadastro</button>
          </div>

      </div> -->


    </form>
  </div>



  <!-- <div class="col-lg-4">
      <div class="text-center mb-3">
          <img src="../../assets/perfil-empresa/1280px-Telefónica.svg.png" class="img-fluid perfil-usuario" alt="">
      </div>
      <p>Empresa: Vivo</p>
      <p>Funcionário desde: 1 de Marcço de 2020</p>
      <p>Segmento: Ténico em analise </p>
      <p>Horário de expediente: 08h30 às 17h30</p>
      <p>Avaliação: 5 estrelas</p>
  </div> -->


</div>


<!-- Modal Documento Eficiente-->
<div class="modal fade" id="alterarSenha" #alterarSenha tabindex="-1" role="dialog" aria-hidden="true"
  data-backdrop="static" data-keyboard=false>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title mt-4 text-primary" id="exampleModalLabel">Alterar senha</h1>
        <button (click)="clean()" *ngIf="!user.tempPassword" type="button" class="close" data-dismiss="modal"
          aria-label="Close" [disabled]="user.tempPassword">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <!--<p class="text-center">
          <img src="../../assets/imagens/vivo.svg" class="img-fluid" style="max-width: 15vh;" alt="">
        </p>-->

        <!--<h1 class="mb-0 mt-3 text-center">Alterar senha</h1>-->
        <!--<p class="mb-4 mt-0 text-center">Chegará um e-mail de confirmação, siga os passos.</p>-->

        <form class="mb-4" [formGroup]="formResetPassword" (ngSubmit)="onSubmit()">

          <div class="container">
            <div class="row">

              <div class="col-lg-6 offset-lg-3">
                <div class="form-group" appearance="fill">
                  <!-- <mat-label>Digite a sua nova senha</mat-label>
                  <input formControlName="password" matInput [type]="hide ? 'password' : 'text'"
                    placeholder="*********">
                  <span mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </span> -->
                  <label for="password"
                    [ngClass]="formResetPassword.controls['password'].invalid ? 'text-danger' : ''">Senha:</label>
                  <input id="password" formControlName="password" type="password" class="form-control"
                    [ngClass]="formResetPassword.controls['password'].invalid ? 'is-invalid' : ''">
                  <!-- <label class="text-danger col" *ngIf="formResetPassword.controls['password'].hasError('required')">
                    Password is Required!
                  </label> -->
                  <label class="col"
                    [ngClass]="formResetPassword.controls['password'].hasError('required') || formResetPassword.controls['password'].hasError('hasCapitalCase')  ? 'text-danger' : 'text-success'">
                    <i class="material-icons">{{ formResetPassword.controls['password'].hasError('required') ||
                      formResetPassword.controls['password'].hasError('hasCapitalCase') ? 'cancel' :
                      'check_circle' }}</i>
                    Uma letra maiúscula
                  </label>
                  <label class="col"
                    [ngClass]="formResetPassword.controls['password'].hasError('required') || formResetPassword.controls['password'].hasError('hasSmallCase')  ? 'text-danger' : 'text-success'">
                    <i class="material-icons">{{ formResetPassword.controls['password'].hasError('required') ||
                      formResetPassword.controls['password'].hasError('hasSmallCase') ? 'cancel' :
                      'check_circle' }}</i>
                    Uma letra minúscula
                  </label>
                  <label class="col"
                    [ngClass]="formResetPassword.controls['password'].hasError('required') || formResetPassword.controls['password'].hasError('hasNumber')  ? 'text-danger' : 'text-success'">
                    <i class="material-icons">{{ formResetPassword.controls['password'].hasError('required') ||
                      formResetPassword.controls['password'].hasError('hasNumber') ? 'cancel' :
                      'check_circle' }}</i>
                    Um número
                  </label>
                  <label class="col"
                    [ngClass]="formResetPassword.controls['password'].hasError('required') || formResetPassword.controls['password'].hasError('minlength')  ? 'text-danger' : 'text-success'">
                    <i class="material-icons">{{ formResetPassword.controls['password'].hasError('required') ||
                      formResetPassword.controls['password'].hasError('minlength') ? 'cancel' :
                      'check_circle' }}</i>
                    8 a 60 caracteres
                  </label>
                  <label class="col"
                    [ngClass]="formResetPassword.controls['password'].hasError('required') || formResetPassword.controls['password'].hasError('hasSpecialCharacters') ? 'text-danger' : 'text-success'">
                    <i class="material-icons">{{ formResetPassword.controls['password'].hasError('required') ||
                      formResetPassword.controls['password'].hasError('hasSpecialCharacters') ? 'cancel' :
                      'check_circle' }}</i>
                    Lista de caracteres especiais aceitos:
                    &lt;&gt;&#64;&#33;&#35;&#36;&#37;&#94;&amp;&#42;&#40;&#41;&#95;&#43;&#91;&#93;&#123;&#125;&#63;&#58;&#59;&#124;&apos;&#92;&quot;&#92;&#92;&#44;.&frasl;&#126;&lsquo;&minus;&#61;
                  </label>
                </div>
              </div>

              <div class="col-lg-6 offset-lg-3">
                <div class="form-group" appearance="fill">
                  <!-- <mat-label>Confirme a sua nova senha</mat-label>
                  <input formControlName="confirmPassword" matInput [type]="hide ? 'password' : 'text'"
                    placeholder="*********">
                  <span mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </span> -->


                  <label for="confirmPassword"
                    [ngClass]="formResetPassword.controls['confirmPassword'].invalid ? 'text-danger' : ''">Confirmar
                    Senha:</label>
                  <input id="confirmPassword" formControlName="confirmPassword" type="password" class="form-control"
                    [ngClass]="formResetPassword.controls['confirmPassword'].invalid ? 'is-invalid' : ''">
                  <label class="text-danger" *ngIf="formResetPassword.controls['confirmPassword'].hasError('required')">
                    A Senha é obrigatória!
                  </label>
                  <label class="text-danger"
                    *ngIf="formResetPassword.controls['confirmPassword'].hasError('NoPassswordMatch')">
                    A confirmação de senha não coincide com a senha digitada!
                  </label>
                </div>
              </div>

              <div class="col-lg-6 offset-lg-3">
                <div *ngIf="response.length > 0" class="alert alert-danger" role="alert">
                  {{response}}
                </div>
                <button [disabled]="loading || formResetPassword.invalid" class="btn btn-sm btn-success btn-block">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  Alterar
                </button>

                <button *ngIf="!user.tempPassword" (click)="clean()" class="btn btn-sm btn-danger btn-block"
                  [disabled]="user.tempPassword" data-dismiss="modal">Cancelar</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>