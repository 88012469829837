import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FormService {
  constructor(private http: HttpClient) {}

  getAllForms() {
    return this.http.get<any>(`${environment.apiUrl}/form`);
  }

  getFormById(id) {
    return this.http.get<any>(`${environment.apiUrl}/form/${id}`);
  }
  updateAnexos(formAnexoUploadDto) {
    return this.http.put<any>(
      `${environment.apiUrl}/form/anexos-update`,
      formAnexoUploadDto
    );
  }
  removeAnexoForm(formRemoveAnexoDto) {
    return this.http.put(
      `${environment.apiUrl}/form/remove-anexo-form`,
      formRemoveAnexoDto
    );
  }
  removeAllAnexos(formId) {
    return this.http.put(
      `${environment.apiUrl}/form/remove-all-anexos`,
      formId
    );
  }
  getFormByTicketSubcategoryEnum(userId, subcategory) {
    return this.http.get<any>(
      `${environment.apiUrl}/form/search/${userId}/${subcategory}`
    );
  }
}
