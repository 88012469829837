import { Pipe, PipeTransform } from "@angular/core";

import { User } from "../_models";

@Pipe({
  name: "fullName",
})
export class FullNamePipe implements PipeTransform {
  transform(insumoUser: User): string {
    if (
      insumoUser.fullName == null ||
      insumoUser.fullName == undefined ||
      insumoUser.fullName == ""
    ) {
      return insumoUser.firstName + " " + insumoUser.lastName;
    }

    return insumoUser.fullName;
  }
}
