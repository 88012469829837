import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class SolicitacaoService {
  constructor(private http: HttpClient) {}

  getCamposById(id) {
    return this.http.get<any>(`${environment.apiUrl}/campo/list/${id}`);
  }

  getSolicitacaoByID(id) {
    return this.http.get<any>(`${environment.apiUrl}/pedido/id/${id}`);
  }

  getCamposCartonagemById(id) {
    return this.http.get<any>(`${environment.apiUrl}/material/list/${id}`);
  }

  getPedidosById(id, filter) {
    return this.http.get<any>(
      `${environment.apiUrl}/pedido/list/${id}/${filter}`
    );
  }

  getPedidosBySituacaoAndTipo(id, filter, tipo) {
    return this.http.get<any>(
      `${environment.apiUrl}/pedido/list/${id}/${tipo}/${filter}`
    );
  }

  getPedidosBySituacaoAndTipoPaged(id, filter, tipo, page, count) {
    return this.http.get<any>(
      `${environment.apiUrl}/pedido/list/${id}/${tipo}/${filter}?page=${page}&size=${count}`
    );
  }

  getAnexos(id) {
    return this.http.get<any>(`${environment.apiUrl}/pedido/anexos/id/${id}`);
  }

  getSolicitacoesDeAcesso(userid, situacao) {
    return this.http.get<any>(
      `${environment.apiUrl}/solicitacoes-de-acesso/list/${userid}?situacao=${situacao}`
    );
  }

  getPedidosBySituacoes(userId, situacoes) {
    return this.http.get<any>(
      `${environment.apiUrl}/notifications/list/situacao/${userId}?situacao=${situacoes}`
    );
  }

  getPrioridadeById(id) {
    return this.http.get<any>(`${environment.apiUrl}/prioridade/list/${id}`);
  }

  saveOrUpdate(solicitacao, userid) {
    return this.http.post(
      `${environment.apiUrl}/pedido/save/${userid}`,
      solicitacao
    );
  }

  addResponsavel(autorizado) {
    return this.http.post(
      `${environment.apiUrl}/api/user/add-responsavel`,
      autorizado
    );
  }

  saveAccessSolicitation(solicitacao) {
    return this.http.post(
      `${environment.apiUrl}/api/user/allow-access`,
      solicitacao
    );
  }

  getResponsavelEmailLike(text) {
    return this.http.get<any>(
      `${environment.apiUrl}/api/user/search?email=${text}`
    );
  }

  //New Code Solicitação de Acesso

  getAccessSolicitationEmEspera() {
    return this.http.get<any[]>(`${environment.apiUrl}/solicitacao-acesso`);
  }

  getAccessSolicitationAll() {
    return this.http.get<any[]>(`${environment.apiUrl}/solicitacao-acesso/all`);
  }

  saveSolicitationAccess(solicitacaoAcessoDto) {
    return this.http.post(
      `${environment.apiUrl}/solicitacao-acesso/pedido`,
      solicitacaoAcessoDto
    );
  }

  negarSolicitacaoAcesso(solicitacaoAcessoNegadoDto) {
    return this.http.put(
      `${environment.apiUrl}/solicitacao-acesso/negado`,
      solicitacaoAcessoNegadoDto
    );
  }

  aprovarSolicitacaoAcesso(solicitacaoAcessoAceitoDto) {
    return this.http.put(
      `${environment.apiUrl}/solicitacao-acesso/aprovado`,
      solicitacaoAcessoAceitoDto
    );
  }
}
