import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "cap",
})
export class CaptalizePipe implements PipeTransform {
  transform(text: string): string {
    if (text) {
      text = text.replace(/_/g, " ");
      text = text.toLowerCase();
      const words = text.split(" ");

      for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
        if (words[i] === "Operacao") {
          words[i] = "Operação";
        }
        if (words[i] === "Area") {
          words[i] = "Área";
        }
        if (words[i] === "Adicao") {
          words[i] = "Adição";
        }
        if (words[i] === "Ativacao") {
          words[i] = "Ativação";
        }
        if (words[i] === "Inativacao") {
          words[i] = "Inativação";
        }
        if (words[i] === "Mudanca") {
          words[i] = "Mudança";
        }
        if (words[i] === "Responsavel") {
          words[i] = "Responsável";
        }
        if (words[i] === "Criacao") {
          words[i] = "Criação";
        }
        if (words[i] === "Edicao") {
          words[i] = "Edição";
        }
        if (words[i] === "Remocao") {
          words[i] = "Remoção";
        }
        if (words[i] === "Comentario") {
          words[i] = "Comentário";
        }
        if (words[i] === "Versao") {
          words[i] = "Versão";
        }
        if (words[i] === "Concluido") {
          words[i] = "Concluído";
        }
        if (words[i] === "Solicitacoes") {
          words[i] = "Solicitações";
        }
        if (words[i] === "Bpo") {
          words[i] = "BPO";
        }
        if (words[i] === "Juridico") {
          words[i] = "Jurídico";
        }
        if (words[i] === "Declaracao") {
          words[i] = "Declaração";
        }
        if (words[i] === "Nao") {
          words[i] = "Não";
        }
        if (words[i] === "Ha") {
          words[i] = "Há";
        }
        if (words[i] === "Juridica") {
          words[i] = "Jurídica";
        }
        if (words[i] === "De" || words[i] === "Do" || words[i] === "Da") {
          words[i] = words[i].replace("D", "d");
        }
        if (words[i + 1] === "demandas") {
          return "Projeto e Demandas";
        }
        if (words[i + 1] === "portais") {
          return "Suporte / Portais Sistemas";
        }
        if (words[i + 1] === "contestacao") {
          return "Comissão / Contestação";
        }
      }

      text = words.join(" ");
      return text;
    }
    return "";
  }
}
